import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 16, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6675 1.99923C10.6675 1.63182 10.966 1.33398 11.3342 1.33398L14.0009 1.33398C14.1777 1.33398 14.3473 1.4041 14.4723 1.5289C14.5974 1.65371 14.6676 1.82297 14.6675 1.99944L14.6667 4.68897C14.6665 5.05637 14.368 5.35412 13.9998 5.354C13.6316 5.35389 13.3332 5.05595 13.3333 4.68855L13.3337 3.61255L10.4728 6.48681C10.2133 6.74738 9.79117 6.74863 9.53004 6.48961C9.26892 6.23059 9.26767 5.80939 9.52724 5.54882L12.3981 2.66447H11.3342C10.966 2.66447 10.6675 2.36663 10.6675 1.99923ZM6.47085 9.51169C6.73197 9.77071 6.73323 10.1919 6.47365 10.4525L3.60278 13.3368H4.66672C5.03491 13.3368 5.33339 13.6347 5.33339 14.0021C5.33339 14.3695 5.03491 14.6673 4.66672 14.6673H2.00004C1.82319 14.6673 1.65359 14.5972 1.52856 14.4724C1.40353 14.3476 1.33332 14.1783 1.33337 14.0019L1.33423 11.3123C1.33435 10.9449 1.63292 10.6472 2.00111 10.6473C2.3693 10.6474 2.66768 10.9453 2.66756 11.3128L2.66722 12.3888L5.52804 9.51449C5.78762 9.25392 6.20973 9.25267 6.47085 9.51169Z"
      fill={color}
    />
  </svg>
);

export default Icon;
