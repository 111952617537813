import {
  IProjectChecklist,
  ISystemConfig,
  ITransloaditSignature,
  ITransloaditTemplate,
  PlatformPoliciesTemplateParam,
} from '@interfaces';
import { dataProvider } from '@api';
import {
  platformPoliciesTemplate,
  platformPoliciesTemplates,
  systemConfig,
  transloaditSignature,
  transloaditTemplate,
} from '../paths';

export const getTransloaditTemplate = async (): Promise<ITransloaditTemplate[]> =>
  dataProvider.get(transloaditTemplate()).json();

export const getTransloaditSiganture = async (
  templateId: string,
  queryParams: string,
): Promise<ITransloaditSignature> =>
  dataProvider.get(transloaditSignature(templateId, queryParams)).json();

export const getSystemConfig = async (): Promise<{ results: ISystemConfig[] }> =>
  dataProvider.get(systemConfig()).json();

export const getPlatformPoliciesTemplates = async (): Promise<IProjectChecklist[]> =>
  dataProvider.get(platformPoliciesTemplates()).json();

export const updatePlatformPoliciesTemplate = async ({
  templateId,
  templateData,
}: PlatformPoliciesTemplateParam): Promise<Response> =>
  dataProvider.put(platformPoliciesTemplate(templateId), { json: templateData }).json();
