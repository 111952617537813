import React, { FC } from 'react';
import { LabelAndValue, Popup } from '@components';
import { IInspection, IServiceOrder } from '@interfaces';
import { EditIcon } from '@svgAsComponents';
import { colors, typography } from '@theme';
import { Button, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import * as Controller from './controller';
import LoadingButton from '@mui/lab/LoadingButton';

const LinkRequestToServicePicker: FC<{
  serviceOrder: IInspection | IServiceOrder;
  isEditable?: boolean;
}> = ({ serviceOrder, isEditable = true }) => {
  const {
    drawRequests,
    saveInspectionRequest,
    unlinkInspectionRequest,
    handleChange,
    drawRequestId,
    renderValueFn,
    open,
    setOpen,
    labelText,
    isMutating,
  } = Controller.useLinkRequestToServicePicker(serviceOrder);

  return (
    <>
      <LabelAndValue
        label="Linked request"
        icon={
          Boolean(isEditable && drawRequests.length) && (
            <IconButton
              onClick={() => setOpen(true)}
              sx={{ p: 0, pl: 1 }}
              data-cy="inspection_results__linked_request__edit__icon"
            >
              <EditIcon size={20} />
            </IconButton>
          )
        }
        color={serviceOrder?.draw_request?.id ? colors.text.dark : colors.status.error.medium}
        text={labelText}
      />
      <Popup open={open} maxWidth="md" title="Edit request">
        <Stack sx={{ width: '100%' }}>
          <InputLabel
            id="inspection-request-select-label"
            sx={{ ...typography.label, color: colors.text.medium }}
          >
            Linked request
          </InputLabel>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Select
              labelId="inspection-request-select-label"
              id="inspection-request-select"
              value={drawRequestId || ''}
              renderValue={renderValueFn}
              displayEmpty
              onChange={handleChange}
              fullWidth
              size="small"
              data-cy="inspection_results__link_request_modal__select"
            >
              <MenuItem value="">Not linked</MenuItem>
              {drawRequests.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {`Draw ${item.counter_per_request_type}`}
                </MenuItem>
              ))}
            </Select>
            <LoadingButton
              variant="new"
              color="secondary"
              onClick={unlinkInspectionRequest}
              loading={!!isMutating}
              data-cy="inspection_results__link_request_modal__unlink__icon"
            >
              Unlink
            </LoadingButton>
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: '3.5rem' }}
          >
            <Button
              variant="text"
              onClick={() => setOpen(false)}
              data-cy="inspection_results__link_request_modal__cancel__button"
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{ ml: '0.5rem' }}
              onClick={saveInspectionRequest}
              loading={!!isMutating}
              data-cy="inspection_results__link_request_modal__save__button"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Popup>
    </>
  );
};

export default LinkRequestToServicePicker;
