import React, { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HookState } from '@interfaces';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BarChart, LabelAndValue, LoadingSkeleton, ServiceMessage } from '@components';
import * as Controller from './controller';
import { colors } from '@theme';
import { currencyFormatter, percentFormatter } from '@utils';
import NodeCard from '../NodeCard';
import { NoChartIcon } from '@svgAsComponents';

const Uses: FC<{ onLoad?: () => void }> = ({ onLoad }) => {
  const { projectId } = useParams();
  const controller = Controller.useProjectUses(projectId);
  const { total } = controller;

  const formatValue = useCallback(
    (value) => {
      if (!total) return `${currencyFormatter(value)}`;
      return `${currencyFormatter(value)} / ${percentFormatter({
        value: (value / total) * 100,
        roundTo: 0,
      })}`;
    },
    [total],
  );

  useEffect(() => {
    if (controller.state === HookState.SUCCESS && onLoad) onLoad();
  }, [controller.state]);

  switch (controller.state) {
    case HookState.FETCHING: {
      return (
        <NodeCard
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyItems: 'flex-start',
          }}
          title="Uses"
        >
          <LoadingSkeleton type="overviewBlock" />
        </NodeCard>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="uses" />;
    }
    case HookState.SUCCESS: {
      return (
        <NodeCard
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyItems: 'flex-start',
          }}
          title="Uses"
        >
          {controller.keys.length && controller.values.length ? (
            <>
              <LabelAndValue
                label="Total construction budget"
                text={currencyFormatter(total, '-')}
              />
              <BarChart
                {...controller}
                stacked
                borderRadius="2px"
                orientation="horizontal"
                barWidth={16}
                paletteSrc={[colors.status.information.dark, colors.status.information.light]}
                legendPosition="bottom"
                format={formatValue}
                legendStyle={{ width: '100%', marginLeft: 0 }}
              />
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexFlow: 'column nowrap',
              }}
            >
              <NoChartIcon />
              <Typography variant="body1SemiBold">No data</Typography>
            </Box>
          )}
        </NodeCard>
      );
    }

    default:
      return null;
  }
};

export default Uses;
