import React, { FC, useContext } from 'react';
import { SettingsContext } from '@context';
import { ButtonWithTooltip } from '@components';
import { getTooltipText } from '@utils';

const UploadButton: FC<{
  onClick: () => void;
  source: string;
}> = ({ onClick, source }) => {
  const { isCurrentProjectArchived } = useContext(SettingsContext);

  return (
    <ButtonWithTooltip
      variant="new"
      color="secondary"
      onClick={onClick}
      disabled={isCurrentProjectArchived}
      tooltipText={getTooltipText({ isCurrentProjectArchived })}
      sx={{ ml: 1 }}
      dataTestName={`${source}__upload__button`}
    >
      Upload doc(s)
    </ButtonWithTooltip>
  );
};

export default UploadButton;
