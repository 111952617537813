import { dataProvider } from '@api';
import {
  ChangeProjectDocumentParam,
  IProjectDocument,
  UpdateDrawRequestListItemParam,
} from '@interfaces';

import {
  projectDocuments,
  projectDocument,
  projectDocumentRestore,
  inspectionsDocumentItem,
  inspectionsDocuments,
  serviceOrderDocuments,
  serviceOrderDocumentItem,
} from '../paths';

export const getProjectDocuments = async ({
  projectId,
  stringQueryParams,
}: {
  projectId: string;
  stringQueryParams?: string;
}): Promise<{ results: IProjectDocument[]; count: number }> =>
  dataProvider.get(projectDocuments(projectId, stringQueryParams)).json();

export const deleteProjectDocument = async ({
  projectId,
  documentId,
}: ChangeProjectDocumentParam): Promise<Response> =>
  dataProvider.delete(projectDocument(projectId, documentId));

export const patchProjectDocument = async ({
  projectId,
  documentId,
  scope,
}: ChangeProjectDocumentParam & { scope: string }): Promise<Response> =>
  dataProvider.patch(projectDocument(projectId, documentId), {
    json: { scope },
  });

export const updateProjectDocument = async ({
  projectId,
  documentId,
  json,
}: UpdateDrawRequestListItemParam): Promise<Response> =>
  dataProvider.put(projectDocument(projectId, documentId), {
    json,
  });

export const restoreProjectDocument = async ({
  projectId,
  documentId,
}: ChangeProjectDocumentParam): Promise<Response> =>
  dataProvider.post(projectDocumentRestore(projectId, documentId));

export const getProjectDocumentById = async ({
  projectId,
  documentId,
  query = '',
}): Promise<IProjectDocument> =>
  dataProvider.get(projectDocument(projectId, documentId, query)).json();

export const deleteInspectionDocument = async ({
  projectId,
  inspectionId,
  documentId,
}): Promise<Response> =>
  dataProvider.delete(inspectionsDocumentItem(projectId, inspectionId, documentId)).json();

export const getInspectionDocuments = async ({
  projectId,
  inspectionId,
  query = '',
  stringQueryParams = '',
}: {
  projectId: string;
  inspectionId: string;
  query?: string;
  stringQueryParams?: string;
}): Promise<{ results: IProjectDocument[]; count: number }> =>
  dataProvider.get(inspectionsDocuments(projectId, inspectionId, query, stringQueryParams)).json();

export const getServiceOrderDocuments = async ({
  projectId,
  serviceOrderId,
  query = '',
  stringQueryParams = '',
}: {
  projectId: string;
  serviceOrderId: string;
  query?: string;
  stringQueryParams: string;
}): Promise<{ results: IProjectDocument[]; count: number }> =>
  dataProvider
    .get(serviceOrderDocuments(projectId, serviceOrderId, query, stringQueryParams))
    .json();

export const deleteServiceOrderDocument = async ({
  projectId,
  serviceOrderId,
  documentId,
}): Promise<Response> =>
  dataProvider.delete(serviceOrderDocumentItem(projectId, serviceOrderId, documentId)).json();
