import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { ComponentProps } from './interface';

const TruncatedTextWithTooltip = ({
  value,
  variant,
  style = {},
  ellipsisActive,
}: ComponentProps) => {
  const labelRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    if (labelRef?.current?.offsetWidth < labelRef?.current?.scrollWidth) {
      setIsEllipsisActive(true);
    }
  }, [labelRef?.current]);

  return (
    <Tooltip title={isEllipsisActive ? value : ''} placement="top-start">
      <Typography
        ref={labelRef}
        variant={variant}
        sx={[
          { ...style },
          ellipsisActive
            ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
            : {},
        ]}
      >
        {value}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedTextWithTooltip;
