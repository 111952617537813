import React from 'react';
import { CheckboxIconChecked, CheckboxIconDefault } from '@svgAsComponents';

export default function Checkbox() {
  return {
    MuiCheckbox: {
      styleOverrides: {},
      defaultProps: {
        icon: <CheckboxIconDefault />,
        checkedIcon: <CheckboxIconChecked />,
      },
    },
  };
}
