import React, { useState } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { useField } from 'formik';
import { FormFieldError, PasswordVisibilityIcon } from '@components';

const FormikInput = ({ validate = undefined, ...props }) => {
  const [field, meta] = useField({ ...props, validate });
  const { touched, error } = meta;
  const { servererror, type, options } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const getInputProps = () => {
    if (type === 'password')
      return {
        endAdornment: (
          <PasswordVisibilityIcon
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
          />
        ),
      };
    return null;
  };

  return (
    <TextField
      {...field}
      value={field.value || ''}
      type={showPassword ? 'text' : type}
      error={(touched && Boolean(error)) || Boolean(servererror)}
      InputProps={getInputProps()}
      margin="normal"
      variant="outlined"
      fullWidth
      helperText={<FormFieldError text={(touched && error) || servererror} />}
      {...props}
    >
      {options?.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default FormikInput;
