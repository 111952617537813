import React from 'react';
import { Grid2, Stack, Typography } from '@mui/material';

import {
  CustomAutocomplete,
  CustomCurrencyTextField,
  CustomDatePickerInput,
  CustomSelect,
  CustomTextField,
  FundingSourcesContainer,
  PaymentConfigurationEdit,
  StyledBox,
  TabWrapperWithLeaveWarning,
} from '@components';
import { currencyFormatter, getNextDay, getPrevDay } from '@utils';
import * as Controller from './controller';
import { colors } from '@theme';
import { useLaunchDarklyFlags } from '@context';

const LoanDetails = ({ project, isAllProjectDetailsDisabled, isLimitedProjectDetailsDisabled }) => {
  const controller = Controller.useLoanDetails({ project, isAllProjectDetailsDisabled });
  const {
    external_id,
    loc_commitment,
    prefunding_cost,
    post_funding_construction_budget,
    construction_holdback,
    construction_holdback_fraction,
    borrower_equity,
    borrower_equity_fraction,
    handleSubmitClick,
    isSubmitting,
    isEditable,
    isUpdated,
    exitPath,
    isDisabled,
    completionDateReasonsList,
    projectDates,
    handleCompletionDateReasonChange,
    isCompletionDateReasonsValid,
    completionDateReasons,
    loan_status,
    loanStatusesList,
    loan,
    showBudgetValues,
    loan_type,
    loanTypesList,
    legal_entity,
    configurationType,
    setConfigurationType,
    configurationTypeOptions,
  } = controller;
  const {
    funding_date,
    estimated_start_date,
    original_completion_date,
    estimated_completion_date,
    maturity_date,
    extended_maturity_date,
  } = projectDates;
  const flags = useLaunchDarklyFlags();

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      isUpdated={isUpdated}
      isDisabled={isDisabled}
      tabValue="loan"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/loan'}
    >
      <StyledBox>
        <Typography variant="h3">General</Typography>

        <Grid2 container spacing={2} sx={{ mt: 3 }}>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomTextField
              field={external_id}
              label="Loan ID (External identifier)"
              required={false}
              inputProps={{
                'data-cy': 'project__settings__loan_id__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
            />
          </Grid2>
          {flags?.['ENG_8716_introduce_funding_sources'] && (
            <Grid2 size={{ xs: 12, lg: 4 }}>
              <Stack sx={{ width: '100%' }}>
                <CustomCurrencyTextField
                  label="Total loan commitment"
                  field={loc_commitment}
                  disabled={isAllProjectDetailsDisabled || !isEditable}
                />
                <Typography variant="label" sx={{ display: 'inline-block' }}>
                  Costs towards land acquisition, construction, associated expenses
                </Typography>
              </Stack>
            </Grid2>
          )}
        </Grid2>
        <Grid2 container spacing={2} sx={{ mt: 2 }}>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomAutocomplete
              label="Loan type"
              field={loan_type}
              options={loanTypesList}
              inputProps={{
                'data-cy': 'project__settings__loan__type__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomAutocomplete
              label="Loan servicing status"
              field={loan_status}
              options={loanStatusesList}
              inputProps={{
                'data-cy': 'project__settings__loan__servicing_status__input',
              }}
              disabled={isAllProjectDetailsDisabled}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomDatePickerInput
              label="Loan funding date"
              field={funding_date}
              required={false}
              inputProps={{
                'data-cy': 'project__settings__loan__funding_date__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
              maxDate={getPrevDay(maturity_date.value)}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} sx={{ mt: 2 }}>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomDatePickerInput
              label="Original maturity date"
              field={maturity_date}
              minDate={getNextDay(funding_date.value)}
              maxDate={extended_maturity_date.value}
              required={false}
              inputProps={{
                'data-cy': 'project__settings__loan__maturity_date__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomDatePickerInput
              label="Extended maturity date"
              field={extended_maturity_date}
              minDate={maturity_date.value}
              inputProps={{
                'data-cy': 'project__settings__loan__extended_maturity_date__input',
              }}
              disabled={isAllProjectDetailsDisabled}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomDatePickerInput
              label="Start date"
              field={estimated_start_date}
              inputProps={{
                'data-cy': 'project__settings__loan__estimated_start_date__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
              maxDate={getPrevDay(original_completion_date.value)}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} sx={{ mt: 2 }}>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomDatePickerInput
              label="Original completion date"
              field={original_completion_date}
              minDate={getNextDay(estimated_start_date.value)}
              inputProps={{
                'data-cy': 'project__settings__loan__original_completion_date__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <Stack>
              <CustomDatePickerInput
                label="Estimated completion date"
                field={estimated_completion_date}
                minDate={getNextDay(estimated_start_date.value)}
                inputProps={{
                  'data-cy': 'project__settings__loan__estimated_completion_date__input',
                }}
                disabled={isLimitedProjectDetailsDisabled}
              />
              {project.estimated_completion_date_change_reason && (
                <Typography variant="label">
                  Reason:{' '}
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: project.estimated_completion_date_change_reason,
                    }}
                    variant="labelSemiBold"
                    data-cy="project__overview__estimated_completion_date__change_reason__value"
                  />
                </Typography>
              )}
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomSelect
              label="Reason for updating estimated completion date"
              optionsList={completionDateReasonsList}
              selectedOptions={completionDateReasons}
              handleSelectChange={handleCompletionDateReasonChange}
              required={estimated_completion_date.isChanged}
              error={!isCompletionDateReasonsValid}
              dataTestName="project__overview__completion_date__reasons__select"
              disabled={isLimitedProjectDetailsDisabled}
              errorText="A reason is required when updating the estimated completion date"
            />
          </Grid2>
        </Grid2>
      </StyledBox>

      {flags?.['ENG_8716_introduce_funding_sources'] ? (
        <StyledBox>
          <FundingSourcesContainer
            prefunding_cost={prefunding_cost}
            construction_holdback={construction_holdback}
            constructionHoldbackFraction={construction_holdback_fraction}
            borrower_equity={borrower_equity}
            borrowerEquityFraction={borrower_equity_fraction}
            configurationType={configurationType}
            setConfigurationType={setConfigurationType}
            constructionBudget={post_funding_construction_budget}
            source="project__settings"
            isEditable={!isAllProjectDetailsDisabled && isEditable}
            configurationTypeOptions={configurationTypeOptions}
            budgetPrefundingCost={project.loan?.budget_prefunding_cost}
            budgetScheduleOfValues={project.loan?.budget_post_funding_construction_budget}
          />
        </StyledBox>
      ) : (
        <StyledBox>
          <Typography variant="h3" sx={{ mb: 3 }}>
            Financials
          </Typography>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, sm: 4 }}>
              <Stack sx={{ width: '100%' }}>
                <CustomCurrencyTextField
                  label="Total loan commitment"
                  field={loc_commitment}
                  disabled={isAllProjectDetailsDisabled || !isEditable}
                />
                <Typography variant="label" sx={{ display: 'inline-block' }}>
                  Costs towards land acquisition, construction, associated expenses
                </Typography>
              </Stack>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 4 }}>
              <CustomCurrencyTextField
                label="Pre-funding costs"
                field={prefunding_cost}
                inputProps={{
                  'data-cy': 'project__settings__loan__prefunding_cost__input',
                }}
                disabled={isAllProjectDetailsDisabled || !isEditable}
              />
              {showBudgetValues && (
                <Typography
                  variant="label"
                  sx={{ display: 'inline-block', color: colors.status.information.medium }}
                >
                  Budget: Prefunding cost = {currencyFormatter(loan?.budget_prefunding_cost)}
                </Typography>
              )}
            </Grid2>
          </Grid2>
          <Stack mt={3}>
            <PaymentConfigurationEdit
              postFundingConstructionBudget={post_funding_construction_budget}
              constructionHoldback={construction_holdback}
              constructionHoldbackFraction={construction_holdback_fraction}
              borrowerEquity={borrower_equity}
              borrowerEquityFraction={borrower_equity_fraction}
              source="project__settings__loan"
              isEditable={!isAllProjectDetailsDisabled && isEditable}
              showBudgetValues={showBudgetValues}
              loan={loan}
            />
          </Stack>
        </StyledBox>
      )}

      <StyledBox>
        <Stack direction="row" alignItems="flex-end" spacing={0.5} sx={{ mb: 1 }}>
          <Typography variant="h3">Legal entity</Typography>
          <Typography variant="labelSemiBold" sx={{ pb: 0.2 }}>
            (Optional)
          </Typography>
        </Stack>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Legal entity responsible for the loan. It may differ from the company name.
        </Typography>
        <CustomTextField
          field={legal_entity}
          label="Company name"
          inputProps={{
            'data-cy': 'project__settings__loan__legal_entity__input',
          }}
          disabled={isAllProjectDetailsDisabled || !isEditable}
        />
      </StyledBox>
    </TabWrapperWithLeaveWarning>
  );
};

export default LoanDetails;
