import { useCallback, useContext, useReducer } from 'react';
import useWebSocket from 'react-use-websocket';
import { AuthContext } from '@context';
import { handleWebSocketMessage } from './webSocketHelpers';
import { useEventInvalidationHandlers } from './invalidationHandlers';
import { IWSMessageData } from '@interfaces';

const eventReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return [...state, action.payload];
    case 'CONNECTIONS':
      return action.payload;
    default:
      return state;
  }
};

export const useWebSocketsEvents = (socketUrl = '') => {
  const { user } = useContext(AuthContext);
  const [eventMessages, dispatch] = useReducer(eventReducer, []);
  const { invalidateQueriesByEventType } = useEventInvalidationHandlers();

  const invalidateData = useCallback(
    (params: IWSMessageData) => {
      const handler = invalidateQueriesByEventType[params.event_type];
      if (handler) {
        handler(params);
      }
    },
    [invalidateQueriesByEventType],
  );

  useWebSocket(socketUrl, {
    onOpen: () => console.log('WebSocket connection established.', ` Url:${socketUrl}`),
    onMessage: (message) => handleWebSocketMessage(message, user, dispatch, invalidateData),
    onClose: () => console.log('WebSocket connection closed.', ` Url:${socketUrl}`),
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) => Math.min(5000 * Math.pow(2, attemptNumber), 300000),
  });

  return { eventMessages };
};
