import React from 'react';
import { CircularProgress, Typography, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { emailVerify } from '@globalService';

function EmailConfirmation() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('confirmationToken');

  const { isLoading, isError, isSuccess } = useQuery<Response, Error>(
    ['VERIFY_EMAIL', { token }],
    () => emailVerify(token),
  );

  if (isLoading) {
    return (
      <>
        <Typography variant="body1" sx={{ pb: '3rem', margin: 'auto' }}>
          Waiting till email will be confirmed
        </Typography>
        <CircularProgress size={100} sx={{ margin: 'auto' }} />
      </>
    );
  }

  if (isSuccess || isError) {
    return (
      <Stack alignItems="center">
        <Typography variant="h1" sx={{ pb: '3rem' }}>
          {isSuccess ? 'You’re all set!' : 'Link is expired'}
        </Typography>
        <Typography variant="body1">{isSuccess ? successMessage : errorMessage}</Typography>
      </Stack>
    );
  }

  return null;
}

const successMessage =
  'Thank you for verifying your email address! We can’t wait to help you maximize your business potential with faster approvals and faster payments for you and your subcontractors. Let’s get building!';

const errorMessage =
  'Your email confirmation token is no longer valid or already has been expired.';

export default EmailConfirmation;
