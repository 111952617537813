import React from 'react';
import { useParams, Link } from 'react-router-dom';

import { IconButton } from '@mui/material';
import { PermissionIcon } from '@svgAsComponents';

const PermissionIconWithLink = ({
  teamId,
  dataTestName,
}: {
  teamId: string;
  dataTestName: string;
}) => {
  const { companyId } = useParams();
  const pathBase = `/company/${companyId}/teams`;

  return (
    <Link to={`${pathBase}/${teamId}/permissions`}>
      <IconButton data-cy={dataTestName}>
        <PermissionIcon />
      </IconButton>
    </Link>
  );
};

export default PermissionIconWithLink;
