import { colors } from '@theme';

export default function IconButton() {
  return {
    MuiIconButton: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: colors.main.primary.lighter,
            color: colors.main.primary.main,
            borderRadius: 0,
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: colors.status.information.light,
            color: colors.status.information.medium,
            borderRadius: 0,
          },
        },
      ],
      styleOverrides: {
        root: {
          '& > svg > path: hover': {
            fill: colors.main.primary.darker,
          },
          '&.Mui-disabled > svg > path': {
            fill: colors.neutral.medium,
          },
        },
      },
      defaultProps: {},
    },
  };
}
