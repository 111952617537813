import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6585 3.74743C15.0741 4.11111 15.1163 4.74287 14.7526 5.15851C14.3889 5.57414 13.7571 5.61626 13.3415 5.25258L11 3.20377V13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13V3.20377L6.6585 5.25258C6.24287 5.61626 5.61111 5.57414 5.24742 5.15851C4.88374 4.74287 4.92586 4.11111 5.3415 3.74743L9.34149 0.247423C9.71852 -0.0824745 10.2815 -0.0824745 10.6585 0.247423L14.6585 3.74743ZM2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13V16C0 17.1046 0.895431 18 2 18H18C19.1046 18 20 17.1046 20 16V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V16H2V13Z"
      fill={color}
    />
  </svg>
);

export default Icon;
