import React, { FC } from 'react';
import { Typography, Stack, Button } from '@mui/material';
import { useLogout } from '@hooks';
import { NoAvailableContentImage, LogoWithName } from '@svgAsComponents';
import { useNavigate } from 'react-router-dom';

const NoAvailableContentPage: FC = () => {
  const { logout } = useLogout();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh', padding: '2rem' }}>
      <LogoWithName />
      <NoAvailableContentImage />
      <Typography variant="h2" sx={{ pb: '2rem', textAlign: 'center' }}>
        No available content.
      </Typography>
      <Button onClick={handleLogout}>Logout</Button>
    </Stack>
  );
};

export default NoAvailableContentPage;
