import React, { FC } from 'react';
import {
  IDrawRequest,
  PopupTypeEnum,
  PostDrawRequestReviewParam,
  QueryNamesEnums,
  RequestReviewStatusEnum,
} from '@interfaces';
import { postDrawRequestReview } from '@globalService';
import { useMutation, useQueryClient } from 'react-query';
import { useSafeSnackbar } from '@hooks';
import { ButtonWithTooltip, ConfirmationModal } from '@components';
import { Typography } from '@mui/material';

const CompleteDRButton: FC<{
  drawRequest: IDrawRequest;
  isApproveDisabled: boolean;
  projectId: string;
  tooltipText: string;
}> = ({ drawRequest, isApproveDisabled, projectId, tooltipText }) => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const completeDR = useMutation<Response, Error, PostDrawRequestReviewParam>(
    postDrawRequestReview,
    {
      onSuccess: () => {
        //TODO refactor when adding invalidating groups
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_PROGRESS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_FUNDS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES_COLUMNS,
          { projectId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const completeRequest = () => {
    completeDR.mutateAsync({
      id: projectId,
      drawRequest: drawRequest?.id,
      status: RequestReviewStatusEnum.APPROVE,
    });
  };

  return (
    <>
      <ButtonWithTooltip
        variant="contained"
        color="primary"
        disabled={isApproveDisabled}
        onClick={() => {
          if (!drawRequest?.totals?.current?.approved_amount && !drawRequest?.approved_reallocation)
            return setIsModalOpen(true);
          completeRequest();
        }}
        tooltipText={tooltipText}
        dataTestName="draw_request_approval_buttons_complete_historical"
        loading={completeDR.isLoading}
      >
        Submit
      </ButtonWithTooltip>
      <ConfirmationModal
        open={isModalOpen}
        title="Confirmation"
        text={
          <>
            <Typography variant="body2">You are approving the request with</Typography>
            <Typography variant="body2SemiBold">Draw amount = $0.00.</Typography>
          </>
        }
        onClose={() => setIsModalOpen(false)}
        confirmCallback={() => {
          completeRequest();
          setIsModalOpen(false);
        }}
        type={PopupTypeEnum.CONFIRMATION}
      />
    </>
  );
};

export default CompleteDRButton;
