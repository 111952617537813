import React, { useCallback } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { useStringFieldModel } from '@models';

const PoliciesItemInput = ({ addItem, source }) => {
  const policiesItemInput = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()),
  });

  const handleSubmitNewItem = useCallback(() => {
    policiesItemInput.validate();
    policiesItemInput.value?.trim() && addItem(policiesItemInput.value);
    policiesItemInput.setValue('');
  }, [policiesItemInput.value]);

  return (
    <Stack justifyContent="space-between" alignItems="baseline" flexDirection="row">
      <TextField
        fullWidth
        variant="outlined"
        label="New requirement"
        onChange={policiesItemInput.handleChange}
        error={!policiesItemInput.isValid}
        value={policiesItemInput.value}
        helperText={policiesItemInput.isValid ? '' : 'Requirement could not be blank'}
        multiline
        maxRows={3}
        size="small"
        inputProps={{
          'data-cy': `${source}_policy__list__new_item__input`,
        }}
      />
      <Button
        type="submit"
        variant="new"
        color="secondary"
        onClick={handleSubmitNewItem}
        sx={{ ml: 2, minWidth: 68 }}
        data-cy={`${source}_policy__list__add_item__button`}
      >
        Add
      </Button>
    </Stack>
  );
};

export default PoliciesItemInput;
