import { useMemo, useState } from 'react';
import { useQueries } from 'react-query';
import { SelectChangeEvent } from '@mui/material';

import { DateValidationEnum, IProject, QueryNamesEnums, WorkflowReasonEnum } from '@interfaces';
import {
  compareDates,
  createDateAsLocal,
  getDateValidationRule,
  getNextDay,
  getReasonsList,
  isCreatedProject,
} from '@utils';
import { DateFieldModel, useDateFieldModel } from '@models';
import { getWorkflowReasons } from '@globalService';

interface HookInterface {
  projectDates: {
    [key: string]: DateFieldModel;
  };
  completionDateReasonsList: string[];
  handleCompletionDateReasonChange: (event: SelectChangeEvent<string[]>) => void;
  isCompletionDateReasonsValid: boolean;
  completionDateReasons: string[];
  isDatesUpdated: boolean;
  isDatesValid: boolean;
}

export const useProjectAndLoanDates = ({ project }: { project?: IProject }): HookInterface => {
  const [completionDateReasons, setCompletionDateReasons] = useState([]);

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_WORKFLOW_REASONS],
      queryFn: getWorkflowReasons.bind(this),
    },
  ]);

  const reasonsQuery = requestedDataQueries[0].data;

  // Funding date should be less than maturity, no comparison to project completion date
  const funding_date = useDateFieldModel({
    initValue: project?.loan?.funding_date
      ? createDateAsLocal(project?.loan?.funding_date as string)
      : null,
    validationRule: (value) =>
      getDateValidationRule({
        required: Boolean(project) && !isCreatedProject(project?.status),
        value,
        rule: DateValidationEnum.LESS,
        maxDate: maturity_date.value,
        dateDescription: 'maturity date',
      }),
  });

  // Start date should be less than completion date. no comparison to maturity date.
  const estimated_start_date = useDateFieldModel({
    initValue: project?.estimated_start_date
      ? createDateAsLocal(project.estimated_start_date as string)
      : null,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.LESS,
        maxDate: original_completion_date.value,
        dateDescription: 'original completion date',
        required: false,
      }),
  });

  // completion dates
  const initEstimatedCompletionDate = useMemo(
    () =>
      project?.estimated_completion_date
        ? createDateAsLocal(project?.estimated_completion_date as string)
        : null,
    [project?.estimated_completion_date],
  );

  // Original completion date should be greater than start date. No comparison to funding date.
  const original_completion_date = useDateFieldModel({
    initValue: project?.original_completion_date
      ? createDateAsLocal(project.original_completion_date as string)
      : null,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.MORE,
        minDate: estimated_start_date.value,
        dateDescription: 'start date',
        required: false,
      }),
  });

  // Estimated completion date should be greater than start date. No comparison to any other dates
  const estimated_completion_date = useDateFieldModel({
    initValue: initEstimatedCompletionDate,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.MORE,
        minDate: estimated_start_date.value,
        dateDescription: 'start date',
        required: false,
      }),
  });

  const completionDateReasonsList = useMemo(
    () => getReasonsList(reasonsQuery, WorkflowReasonEnum.COMPLETION_DATE_CHANGED),
    [reasonsQuery],
  );

  const showCompletionDateReasons = useMemo(
    () => compareDates(initEstimatedCompletionDate, estimated_completion_date.value) !== 0,
    [estimated_completion_date.value, initEstimatedCompletionDate],
  );

  const isCompletionDateReasonsValid = useMemo(
    () => !showCompletionDateReasons || Boolean(completionDateReasons.length),
    [completionDateReasons, showCompletionDateReasons],
  );

  const handleCompletionDateReasonChange = (event) => {
    const {
      target: { value },
    } = event;
    setCompletionDateReasons(value);
  };

  // Original maturity date should be greater than funding date. No comparison to start date.
  const maturity_date = useDateFieldModel({
    initValue: project?.loan?.maturity_date
      ? createDateAsLocal(project.loan.maturity_date as string)
      : null,
    validationRule: (value) =>
      getDateValidationRule({
        required: Boolean(project) && !isCreatedProject(project?.status),
        value,
        minDate: getNextDay(funding_date.value),
        maxDate: extended_maturity_date.value,
        rule: DateValidationEnum.BETWEEN_INCLUDING,
      }),
  });

  const initExtendedMaturityDate = useMemo(
    () =>
      (project?.loan?.extended_maturity_date &&
        createDateAsLocal(project?.loan?.extended_maturity_date as string)) ||
      null,
    [project?.loan?.extended_maturity_date],
  );

  // If extended maturity date exists: Extended maturity date should be greater than original maturity date.
  const extended_maturity_date = useDateFieldModel({
    initValue: initExtendedMaturityDate,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        minDate: maturity_date.value,
        rule: DateValidationEnum.MORE_OR_EQUAL,
        dateDescription: 'original maturity date',
        required: false,
      }),
  });

  const isDatesUpdated = useMemo(
    () =>
      [
        funding_date,
        estimated_start_date,
        maturity_date,
        extended_maturity_date,
        original_completion_date,
        estimated_completion_date,
      ].some((field) => field.isChanged),
    [
      funding_date.isChanged,
      estimated_start_date.isChanged,
      maturity_date.isChanged,
      extended_maturity_date.isChanged,
      original_completion_date.isChanged,
      estimated_completion_date.isChanged,
    ],
  );

  const isDatesValid = useMemo(
    () =>
      [
        funding_date,
        estimated_start_date,
        maturity_date,
        extended_maturity_date,
        original_completion_date,
        estimated_completion_date,
      ].every((field) => field.isValid) && isCompletionDateReasonsValid,
    [
      funding_date.isValid,
      estimated_start_date.isValid,
      maturity_date.isValid,
      extended_maturity_date.isValid,
      original_completion_date.isValid,
      estimated_completion_date.isValid,
      isCompletionDateReasonsValid,
    ],
  );

  return {
    projectDates: {
      funding_date,
      estimated_start_date,
      original_completion_date,
      estimated_completion_date,
      maturity_date,
      extended_maturity_date,
    },
    completionDateReasonsList,
    handleCompletionDateReasonChange,
    isCompletionDateReasonsValid,
    completionDateReasons,
    isDatesUpdated,
    isDatesValid,
  };
};
