import React, { FC } from 'react';
import { MessagePanel, PhotosPanel, ProjectNameLabel, RequestChecklist } from '@components';
import { IRightDrawerParams, MessagePanelTabsEnum } from '@interfaces';
import { Stack, Tab, Tabs } from '@mui/material';
import { Documents } from '../../sections/project/components';
import * as Controller from './controller';

interface TableItemRightDrawerProps extends IRightDrawerParams {
  requestDocuments?: boolean;
  messagePanelTab?: MessagePanelTabsEnum;
  showMessagePanelTabs?: boolean;
  source: string;
}
const TableItemRightDrawer: FC<TableItemRightDrawerProps> = ({
  projectId,
  requestId,
  serviceOrderId,
  inspectionId,
  activeTab,
  requestDocuments = true,
  messagePanelTab,
  showMessagePanelTabs = true,
  source,
}) => {
  const { project, tab, tabs, handleChange, TABS } = Controller.useRequestQueueRightModal({
    projectId,
    activeTab,
    requestId,
    serviceOrderId,
    inspectionId,
  });

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ mb: 1 }}>
        <ProjectNameLabel project={project} />
      </Stack>
      <Tabs
        TabIndicatorProps={{
          style: { transition: 'none' },
          children: <span />,
        }}
        value={tab}
        onChange={handleChange}
        sx={{ mb: 4 }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            value={tab.value}
            sx={{ textTransform: 'none' }}
            data-cy={`${source}__tab__${tab.value}`}
          />
        ))}
      </Tabs>

      {tab === TABS.policies.value && (
        <RequestChecklist projectId={projectId} requestId={requestId} project={project} />
      )}
      {tab === TABS.documents.value && (
        <Documents
          prId={projectId}
          requestId={requestId}
          inspectionId={inspectionId}
          serviceOrderId={serviceOrderId}
          requestDocuments={requestDocuments}
          source={`${source}__documents`}
        />
      )}
      {tab === TABS.photos.value && (
        <PhotosPanel
          prId={projectId}
          drawRequestId={requestId}
          inspectionId={inspectionId}
          source={`${source}__photos`}
          hideUploadButton
        />
      )}
      {tab === TABS.comments.value && (
        <MessagePanel
          projectId={projectId}
          requestId={requestId}
          inspectionId={inspectionId}
          serviceOrderId={serviceOrderId}
          source={`${source}__comments`}
          tab={messagePanelTab}
          showTabs={showMessagePanelTabs}
        />
      )}
    </>
  );
};

export default TableItemRightDrawer;
