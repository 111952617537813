import React from 'react';
import { Box } from '@mui/material';
import { colors } from '@theme';

const Card = ({ children, sx }) => {
  return (
    <Box
      sx={{
        '&:hover': {
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
          border: `1px solid ${colors.main.primary.light}`,
        },
        borderRadius: '2px',
        padding: '24px',
        backgroundColor: colors.background.white,
        cursor: 'pointer',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Card;
