import React, { useCallback } from 'react';
import { Box, IconButton, Stack } from '@mui/material';

import { colors } from '@theme';
import { ComponentProps } from './interface';
import PoliciesListItem from './PoliciesListItem';
import { DeleteIcon } from '@svgAsComponents';
import { LoadingSkeleton, PoliciesItemInput } from '@components';

const PoliciesList = ({
  items,
  isEditable = false,
  policiesId,
  addPoliciesItem,
  removePoliciesItem,
  isTemplate,
  showActionIcons = false,
  source,
  isLoading = false,
}: ComponentProps) => {
  const addItem = useCallback((value: string) => addPoliciesItem(value, policiesId), []);

  if (isLoading) return <LoadingSkeleton type="overviewBlock" />;

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap' }}>
      {items.map((item, index) => (
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          key={item.id}
          sx={{ width: '100%', mb: 2 }}
        >
          <Stack sx={{ flex: 1 }}>
            <PoliciesListItem
              key={item.id}
              isTemplate={isTemplate}
              showActionIcons={showActionIcons}
              item={item}
              index={index}
              source={source}
            />
          </Stack>
          {isEditable && !item.checked && item.canBeDeleted && (
            <IconButton
              onClick={() => removePoliciesItem(item.id.toString(), policiesId)}
              sx={{ p: 0, pl: 1 }}
            >
              <DeleteIcon size={20} color={colors.icons.gray} />
            </IconButton>
          )}
        </Stack>
      ))}
      {isEditable && <PoliciesItemInput addItem={addItem} source={source} />}
    </Box>
  );
};

export default PoliciesList;
