import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useSafeSnackbar } from '@hooks';
import { cancelInvitation, patchCompanyUser, sendInvitation, deleteTeamUser } from '@globalService';
import {
  PatchCompanyUserPayload,
  QueryNamesEnums,
  UserActionPayload,
  UserListModalTypeEnums,
  UserStatusEnum,
  DeleteTeamUserPayload,
} from '@interfaces';

type ConfirmModalType = UserListModalTypeEnums | '';

interface ControllerInterface {
  activateUserHandler: () => void;
  deactivateUserHandler: () => void;
  sendInvitationHandler: () => void;
  cancelInvitationHandler: () => void;
  deleteMemberFromTeamHandler: (team: string) => void;
  confirmModalType: string;
  setConfirmModalType: Dispatch<SetStateAction<ConfirmModalType>>;
  closeConfirmModal: () => void;
  isDeleteMemberFromTeamLoading: boolean;
}

export const useUsersListOptionsMenu = ({
  companyId,
  userId,
  memberId,
}: {
  companyId: string;
  userId: string;
  memberId: string;
}): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [confirmModalType, setConfirmModalType] = useState<ConfirmModalType>('');

  const activateUserHandler = () =>
    activateUserMutation.mutateAsync({ companyId, userId, status: UserStatusEnum.ACTIVE });

  const activateUserMutation = useMutation<Response, Error, PatchCompanyUserPayload>(
    patchCompanyUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMPANIES]);
        setConfirmModalType(UserListModalTypeEnums.USER_ACTIVATED);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const deactivateUserHandler = () =>
    deactivateUserMutation.mutateAsync({ companyId, userId, status: UserStatusEnum.INACTIVE });

  const deactivateUserMutation = useMutation<Response, Error, PatchCompanyUserPayload>(
    patchCompanyUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMPANIES]);
        setConfirmModalType(UserListModalTypeEnums.USER_DEACTIVATED);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const sendInvitationHandler = async () => sendInvitationMutation.mutateAsync({ userId });

  const sendInvitationMutation = useMutation<Response, Error, UserActionPayload>(sendInvitation, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMPANIES]);
      setConfirmModalType(UserListModalTypeEnums.INVITE_SENT);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const cancelInvitationHandler = async () => {
    cancelInvitationMutation.mutateAsync({ userId });
    setConfirmModalType('');
  };

  const cancelInvitationMutation = useMutation<Response, Error, UserActionPayload>(
    cancelInvitation,
    {
      onSuccess: () => {
        setConfirmModalType(UserListModalTypeEnums.INVITE_CANCELLED);

        queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_USERS, { companyId }]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMPANIES);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );
  const deleteMemberFromTeamHandler = useCallback(
    (teamId) => {
      deleteUserFromTeamMutation.mutateAsync({ teamId, userId });
      setConfirmModalType('');
    },
    [companyId, memberId, userId],
  );

  const deleteUserFromTeamMutation = useMutation<Response, Error, DeleteTeamUserPayload>(
    deleteTeamUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_USERS, { companyId }]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMPANIES);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const closeConfirmModal = () => {
    setConfirmModalType('');
    queryClient.invalidateQueries(QueryNamesEnums.GET_COMPANY_USERS);
  };

  return {
    activateUserHandler,
    deactivateUserHandler,
    sendInvitationHandler,
    cancelInvitationHandler,
    confirmModalType,
    setConfirmModalType,
    closeConfirmModal,
    deleteMemberFromTeamHandler,
    isDeleteMemberFromTeamLoading: deleteUserFromTeamMutation.isLoading,
  };
};
