import { colors } from '../colors';
import typography from '../typography';

export default function Tooltip() {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.background.white,
          borderRadius: '4px',
          padding: '8px 16px',
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
          maxWidth: '400px',
          ...typography.label,
        },
      },
      defaultProps: {
        placement: 'bottom-end',
      },
    },
  };
}
