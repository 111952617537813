import React from 'react';
import { isMilestoneIncomplete, percentFormatter } from '@utils';
import { PercentsInput } from '@components';
import { MilestoneListColumnTypeV2 } from './common';
import { GridEditInputCell } from '@mui/x-data-grid';

const lenderAllowanceRate: MilestoneListColumnTypeV2 = {
  field: 'lender_allowance_rate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Lender allowance (%)',
  description: 'Cumulative % approved to fund per Lender',
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  renderEditCell: (props) => <GridEditInputCell {...props} inputComponent={PercentsInput} />,
  minWidth: 120,
  editableByMilestone: (row) =>
    row.isEditableV2 && isMilestoneIncomplete(row, 'approved_adjustments'),
};

export default lenderAllowanceRate;
