import React from 'react';
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { PoliciesList, PoliciesListEditMode, StyledBox } from '@components';
import { PermissionNamesEnums, PoliciesTypeEnum } from '@interfaces';
import { WithPermission } from '@utils';
import {
  EditIcon,
  NoDataImage,
  NoPoliciesLists,
  PlusIcon,
  ProjectListIcon,
} from '@svgAsComponents';
import * as Controller from './controller';
import PolicyProjectsListModal from '../PolicyProjectsListModal';
import { colors } from '@theme';
import { PoliciesTypeMap } from '@constants';
import { PoliciesBoxProps } from './interface';

const PoliciesBox = ({
  policyLists,
  title,
  templatesList,
  assigneeRole,
  type,
  source,
  editMode,
  handleCloseEdit,
  handleEditMode,
  isLoading = false,
}: PoliciesBoxProps) => {
  const controller = Controller.usePoliciesBox({
    handleEditMode,
    handleCloseEdit,
    policyLists,
    templatesList,
    assigneeRole,
    type,
    source,
  });
  const {
    activeTemplate,
    handleSelectChange,
    handleSave,
    handleDelete,
    handleClose,
    onCreateBtnClicked,
    handleTemplateSelected,
    openProjectsListModal,
    setOpenProjectsListModal,
    isMutating,
  } = controller;
  const isEditing = editMode === source;

  return (
    <StyledBox
      flex={1}
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'inherit' }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h3">{title}</Typography>
        <Stack direction="row">
          {!isEditing && Boolean(policyLists.length) && (
            <WithPermission permissionKey={PermissionNamesEnums.POLICIES_EDIT}>
              <FormControl sx={{ mr: 2 }}>
                <Select
                  id="policy-template"
                  value={activeTemplate?.id || ''}
                  onChange={(event: SelectChangeEvent) => handleSelectChange(event.target.value)}
                  sx={{ height: '40px', minWidth: '300px' }}
                  data-cy={`${source}_policy__list__template__select`}
                >
                  {policyLists?.map((type) => (
                    <MenuItem value={type.id} key={type.id}>
                      {type.name}
                      {type?.is_default && ' (default)'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </WithPermission>
          )}
          {!isEditing && (
            <WithPermission permissionKey={PermissionNamesEnums.POLICIES_EDIT}>
              <>
                {activeTemplate?.id && (
                  <IconButton
                    onClick={() => handleEditMode(source)}
                    data-cy={`${source}_policy__list__edit__button`}
                  >
                    <EditIcon size={20} color={colors.icons.green} />
                  </IconButton>
                )}
                {activeTemplate?.id && (
                  <IconButton
                    onClick={() => setOpenProjectsListModal(true)}
                    data-cy={`${source}_policy__list__open_projects_list__button`}
                  >
                    <ProjectListIcon size={20} />
                  </IconButton>
                )}
                <IconButton
                  onClick={onCreateBtnClicked}
                  data-cy={`${source}_policy__list__create__button`}
                >
                  <PlusIcon size={20} />
                </IconButton>
              </>
            </WithPermission>
          )}
        </Stack>
      </Stack>

      {!isEditing &&
        (activeTemplate?.items?.length ? (
          <PoliciesList
            items={activeTemplate?.items}
            policiesId={activeTemplate?.id}
            isTemplate={true}
            source={source}
            isLoading={isMutating || isLoading}
          />
        ) : (
          <Stack sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
            {(type === PoliciesTypeMap[PoliciesTypeEnum.FIRST_DRAW] && (
              <>
                <NoPoliciesLists />
                <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
                  Future draws policy is used
                </Typography>
                <Typography sx={{ textAlign: 'center' }} variant="label">
                  You can make the policy unique by clicking on “Add policy”
                </Typography>
              </>
            )) || (
              <>
                <NoDataImage size={200} />
                <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
                  No policy set up
                </Typography>
              </>
            )}
          </Stack>
        ))}
      {isEditing && (
        <PoliciesListEditMode
          isCompanyPolicyTemplate
          policiesList={activeTemplate}
          handleCloseEdit={handleClose}
          handleSave={handleSave(activeTemplate?.id)}
          handleDelete={handleDelete(activeTemplate?.id)}
          templatesList={templatesList}
          handleTemplateSelected={handleTemplateSelected}
          source={source}
          isProjectActivation={type === PoliciesTypeMap[PoliciesTypeEnum.PROJECT]}
        />
      )}
      {openProjectsListModal && (
        <PolicyProjectsListModal
          title={`${activeTemplate?.name} policy projects`}
          open={openProjectsListModal}
          handleClose={() => setOpenProjectsListModal(false)}
          template={activeTemplate}
        />
      )}
    </StyledBox>
  );
};

export default PoliciesBox;
