import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.status.orange.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0003 16.304C11.2801 16.304 10.6963 15.7202 10.6963 15V5.80401C10.6963 5.08383 11.2801 4.5 12.0003 4.5C12.7205 4.5 13.3043 5.08383 13.3043 5.80401V15C13.3043 15.7202 12.7205 16.304 12.0003 16.304ZM12.0003 20.2161C12.7205 20.2161 13.3043 19.6322 13.3043 18.912C13.3043 18.1919 12.7205 17.608 12.0003 17.608C11.2801 17.608 10.6963 18.1919 10.6963 18.912C10.6963 19.6322 11.2801 20.2161 12.0003 20.2161Z"
      fill={color}
    />
  </svg>
);

export default Icon;
