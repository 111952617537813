import React, { FC } from 'react';
import { Button, Checkbox, Stack, Typography } from '@mui/material';
import { useEmailNotifications } from './controller';

const EmailNotifications: FC<{
  showTitle?: boolean;
  showSetupButtons?: boolean;
  saveDataCategoryClick?: boolean;
  onClose?: () => void;
}> = ({ showTitle = true, showSetupButtons = false, onClose, saveDataCategoryClick = true }) => {
  const { categories, updateNotifications, onSetupClick } = useEmailNotifications(
    saveDataCategoryClick,
    onClose,
  );

  return (
    <Stack>
      {showTitle && <Typography variant="h3">Notifications</Typography>}
      {categories?.map((category, index) => (
        <Stack sx={{ mt: 3 }} key={category.name}>
          <Stack key={index} direction="row" alignItems="center">
            <Checkbox
              checked={category.isChecked}
              onChange={(_e, value) => updateNotifications(category.value, value)}
              sx={{ pl: 0 }}
            />
            <Typography variant="body1SemiBold">{category.name}</Typography>
          </Stack>
          <Stack sx={{ ml: '34px' }}>
            {category.subText.map((text, idx) => (
              <Typography variant="body3" key={idx}>
                {text}
              </Typography>
            ))}
          </Stack>
        </Stack>
      ))}
      {showSetupButtons && (
        <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 7.5 }}>
          <Button
            variant="text"
            onClick={onClose}
            data-cy="setup_notifications__confirmation_dialog__cancel__button"
          >
            Cancel
          </Button>
          <Button
            onClick={onSetupClick}
            data-cy="setup_notifications__confirmation_dialog__setup__button"
          >
            Setup
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default EmailNotifications;
