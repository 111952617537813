import { FilterObject } from '../interface';

export const textSearchFilter: FilterObject = {
  noNeedToRender: true,
  title: 'Text Search',
  filterKey: 'q',
  needToUpdateSetting: false,
  needToUpdateUrl: false,
  cypressSelector: 'filters__q',
};
