import React, { FC } from 'react';
import UserListItems from './columns';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { colors } from '@theme';
import { ITeamMember, IUser, ITeam } from '@interfaces';
import { ColumnLabel } from './columns/common';

interface ComponentProps {
  columns: string[];
  users: ITeamMember[] | IUser[];
  isActivationAllowed?: boolean;
  fixedWidth?: boolean;
  team?: ITeam;
  coordinator?: IUser;
  showCoordinator?: boolean;
  showRemoveFromTeamOption?: boolean;
}

const UsersList: FC<ComponentProps> = ({
  columns,
  users,
  isActivationAllowed,
  fixedWidth,
  team,
  coordinator,
  showCoordinator,
  showRemoveFromTeamOption,
}) => {
  return (
    <Box sx={{ flex: 1, height: '100%' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((item, index) => {
                const column = UserListItems[item];
                return (
                  <TableCell
                    key={index}
                    sx={{
                      borderBottom: `1px solid ${colors.neutral.lighter}`,
                      width: fixedWidth && column.width ? column.width : 'auto',
                      minWidth: column.minWidth ? column.minWidth : 'auto',
                      backgroundColor: colors.background.white,
                      [`&.${tableCellClasses.head}`]: { padding: '0.5rem 1rem' },
                    }}
                  >
                    {(showCoordinator || column.columnText !== 'Coordinator') && (
                      <ColumnLabel>{column.columnText}</ColumnLabel>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row: ITeamMember | IUser, index: number) => (
              <TableRow key={index} sx={{ '&:last-of-type': { '& td': { borderBottom: 'none' } } }}>
                {columns.map((item: string, index: number) => {
                  return (
                    <TableCell key={index}>
                      {UserListItems[item].renderCell({
                        row,
                        isActivationAllowed,
                        team,
                        coordinator,
                        showCoordinator,
                        showRemoveFromTeamOption,
                      })}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UsersList;
