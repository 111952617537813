import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Button, IconButton, Stack } from '@mui/material';

import { ComponentProps } from './interface';
import { UploaderWithForm } from '@components';
import { useFilesUploader } from '@hooks';
import { TransloaditTemplateEnum } from '@interfaces';
import { getPhotoContentType } from '@utils';

function AddPhotoBox({
  projectId,
  drawRequestId,
  milestoneId,
  dataTestName,
  icon,
  iconBackgroundColor,
  buttonProps = { sx: { mt: 4 } },
}: ComponentProps) {
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
  } = useFilesUploader();

  const uploadClick = useCallback(() => {
    uploadMedia({
      fields: {
        ...(drawRequestId && { draw_request_id: drawRequestId }),
        project_id: projectId,
        ...(milestoneId && { milestone_id: milestoneId }),
        object_id: milestoneId || drawRequestId || projectId,
        content_type: getPhotoContentType({ milestoneId, drawRequestId }),
      },
      templateType: TransloaditTemplateEnum.PROOFPOINTS,
    });
  }, [projectId, drawRequestId, milestoneId]);

  const uploader = () => {
    return (
      <UploaderWithForm
        isOpen={isFilesUploaderOpened}
        closeUploader={filesUploaderClose}
        transloaditSignature={transloaditSignature}
        restrictions={restrictions}
        source={dataTestName}
        showLineItems
        projectId={projectId}
        drawRequestId={drawRequestId}
        milestoneId={milestoneId}
      />
    );
  };

  return (
    <>
      {icon ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            ...(iconBackgroundColor && { backgroundColor: iconBackgroundColor }),
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            borderRadius: 1,
          }}
          onClick={() => uploadClick()}
        >
          <IconButton data-cy={dataTestName}>{icon}</IconButton>
        </Stack>
      ) : (
        <Button onClick={() => uploadClick()} data-cy={dataTestName} {...buttonProps}>
          Upload progress photo(s)
        </Button>
      )}
      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(uploader(), document.body)}
    </>
  );
}

export default AddPhotoBox;
