import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.neutral.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM4.8 12C4.8 8.02355 8.02355 4.8 12 4.8C15.9764 4.8 19.2 8.02355 19.2 12C19.2 15.9764 15.9764 19.2 12 19.2C8.02355 19.2 4.8 15.9764 4.8 12Z"
      fill={color}
    />
  </svg>
);

export default Icon;
