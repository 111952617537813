import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 16, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4877 2.21145C14.6738 2.41055 14.72 2.70288 14.6043 2.94965L9.60429 13.6163C9.50215 13.8342 9.29048 13.9802 9.05049 13.9982C8.8105 14.0162 8.57945 13.9034 8.44595 13.7032L7.20492 11.8416L5.33141 12.9122C5.12508 13.0301 4.87158 13.0292 4.66603 12.91C4.46049 12.7907 4.33399 12.571 4.33399 12.3334V9.09994L1.72989 7.94257C1.4839 7.83324 1.3278 7.5867 1.33417 7.31758C1.34054 7.04847 1.50813 6.80959 1.75902 6.71202L13.759 2.04535C14.013 1.94658 14.3016 2.01235 14.4877 2.21145ZM5.66732 9.74538L5.87749 9.85047L6.46356 10.7296L5.66732 11.1846V9.74538ZM7.21403 9.45157L8.89913 11.9792L12.0587 5.23881L7.21403 9.45157ZM10.6746 4.67547L3.73447 7.3744L5.27141 8.05748C5.28064 8.06158 5.28977 8.06589 5.29879 8.07041L6.23314 8.53758L10.6746 4.67547Z"
      fill={color}
    />
  </svg>
);

export default Icon;
