import React from 'react';
import { Grid2, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { LoadingSkeleton, ProjectCard, ServiceMessage } from '@components';
import { ComponentProps } from './interface';
import { NoProjectsImage } from '@svgAsComponents';

const ProjectCardsList = ({ projects, isLoading, isError }: ComponentProps) => {
  const largeMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  return (
    <>
      {isError && <ServiceMessage text="projects" />}
      {isLoading && (
        <Grid2 container spacing={2}>
          {[...Array(largeMediaQuery ? 3 : 2)].map((_, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
              <LoadingSkeleton type="overviewBlock" />
            </Grid2>
          ))}
        </Grid2>
      )}
      {!isLoading && !isError && !projects?.length && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ flexGrow: 1, backgroundColor: 'white' }}
        >
          <NoProjectsImage />
          <Typography>There are no projects yet in this category</Typography>
        </Stack>
      )}
      {!isLoading && projects?.length && (
        <Grid2 container spacing={2}>
          {projects.map((item) => (
            <Grid2 key={item.id} size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
              <ProjectCard item={item} link={'/projects'} />
            </Grid2>
          ))}
        </Grid2>
      )}
    </>
  );
};

export default ProjectCardsList;
