import React, { FC, useState } from 'react';
import { UserInfoModal } from '@components';
import { Box, Typography } from '@mui/material';
import { colors } from '@theme';

const UserInfoLink: FC<{ name: string; companyId: string; userId: string }> = ({
  name,
  companyId,
  userId,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
        <Typography variant="body2SemiBold" color={colors.text.link}>
          {name}
        </Typography>
      </Box>
      {open && (
        <UserInfoModal
          open={open}
          handleClose={() => setOpen(false)}
          companyId={companyId}
          userId={userId}
        />
      )}
    </>
  );
};

export default UserInfoLink;
