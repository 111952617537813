import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { postAgreedToTerms } from '@globalService';
import { useLogout, useSafeSnackbar, useUser } from '@hooks';
import { AuthContext } from '@context';

export interface ControllerInterface {
  open: boolean;
  handleLogout: () => void;
  acceptTerms: () => void;
  isTermsRead: boolean;
  setTermsRead: Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  handleHideLoader: () => void;
  isAccepting: boolean;
}

export const useToSModal = (): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const { user } = useContext(AuthContext);
  const { refetch } = useUser();

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
  };

  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(!user?.agreed_to_terms);
  }, [user]);

  const [isTermsRead, setTermsRead] = useState(false);
  const acceptTermsMutation = useMutation<Response, Error>(postAgreedToTerms, {
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const acceptTerms = async () => {
    await acceptTermsMutation.mutateAsync();
  };

  // show loader while iframe content is loading
  const [isLoading, setIsLoading] = useState(true);
  const handleHideLoader = () => setIsLoading(false);

  return {
    open,
    handleLogout,
    acceptTerms,
    isTermsRead,
    setTermsRead,
    isLoading,
    handleHideLoader,
    isAccepting: acceptTermsMutation.isLoading,
  };
};
