import { useQuery } from 'react-query';
import {
  HookState,
  IPieChartData,
  IProjectProgress,
  IProjectProgressItem,
  QueryNamesEnums,
} from '@interfaces';
import { getProjectProgress } from '@globalService';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { calculateFraction, getHookState, percentFormatter } from '@utils';
import { colors } from '@theme';

export interface ControllerInterface {
  state: HookState;
  getProjectProgressItems: () => ProgressParams;
  getOriginalValuePieChartData: () => IPieChartData[];
}

export interface ProgressParams {
  originalBorrowerEquity: Partial<IProjectProgressItem>;
  constructionHoldback: Partial<IProjectProgressItem>;
  originalScheduledValue: Partial<IProjectProgressItem>;
}

export const useProjectPaymentGraph = (): ControllerInterface => {
  const { projectId } = useParams();

  const progressQuery = useQuery<IProjectProgress, Error>(
    [QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }],
    getProjectProgress.bind(this, projectId),
  );

  const projectProgress = useMemo(() => progressQuery.data, [progressQuery.data]);

  const { constructionHoldback, originalBorrowerEquity, originalScheduledValue } = useMemo(
    () => ({
      constructionHoldback: projectProgress?.construction_holdback,
      originalBorrowerEquity: projectProgress?.original_borrower_equity,
      originalScheduledValue: projectProgress?.total_original_construction_budget,
    }),
    [projectProgress],
  );

  const scheduledValueTotal = useMemo(
    () => originalBorrowerEquity?.total + constructionHoldback?.total,
    [constructionHoldback?.total, originalBorrowerEquity?.total],
  );

  const getProjectProgressItems = useCallback(
    () => ({
      constructionHoldback,
      originalBorrowerEquity,
      originalScheduledValue,
    }),
    [constructionHoldback, originalBorrowerEquity, originalScheduledValue],
  );

  const getOriginalValuePieChartData = useCallback(
    () => [
      {
        label: `Construction holdback (${percentFormatter({
          value: calculateFraction(constructionHoldback?.total, scheduledValueTotal),
        })})`,
        color: colors.status.information.medium,
        value: constructionHoldback?.total,
      },
      {
        label: `Original borrower equity (${percentFormatter({
          value: calculateFraction(originalBorrowerEquity?.total, scheduledValueTotal),
        })})`,
        color: colors.status.orange.medium,
        value: originalBorrowerEquity?.total,
      },
    ],
    [constructionHoldback?.total, originalBorrowerEquity?.total, scheduledValueTotal],
  );

  return {
    state: getHookState(progressQuery),
    getProjectProgressItems,
    getOriginalValuePieChartData,
  };
};
