import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { AuthContext, PermissionsContext } from '@context';
import { PoliciesListItemIconsComponentProps } from '../../interface';
import {
  getUserFullName,
  isCreatedByUser,
  isHistoryActionCreate,
  isPolicyItemCompleted,
  isPolicyItemSkipped,
  isRestricted,
} from '@utils';
import {
  ChangeProjectDocumentParam,
  IDocument,
  IProjectDocument,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { useConfirmationModal, useSafeSnackbar, useDateFormatter } from '@hooks';
import { useMutation, useQueryClient } from 'react-query';
import { deleteProjectDocument } from '@globalService';

const TABS = {
  documents: { label: 'Documents', value: 'documents' },
  photos: { label: 'Progress photos', value: 'photos' },
};

interface PoliciesListItemIconsController {
  activeDocument: IDocument | IProjectDocument | null;
  setActiveDocument: Dispatch<SetStateAction<IDocument | IProjectDocument | null>>;
  deleteDocument: (documentId: string, name: string) => void;
  addedBy: string | undefined;
  canBeEdited: (creatorId: string) => boolean;
  checkedByUser: string | undefined;
  isAdditionalRequirement: boolean;
  skippedBy: string | undefined;
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
  TABS: { [key: string]: { label: string; value: string } };
  modalInfo: string;
  closeConfirmModal: () => void;
  confirmCallback: () => void;
  isConfirmModalOpened: boolean;
}

export const usePoliciesListItemIcons = ({
  item,
  showActionIcons,
}: PoliciesListItemIconsComponentProps): PoliciesListItemIconsController => {
  const { dateFormatter } = useDateFormatter();
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const drawRequestId = match?.params['*'].split('/')[1];
  const [tab, setTab] = useState(TABS.documents.value);
  const [activeDocument, setActiveDocument] = useState(null);
  const [modalInfo, setModalInfo] = useState('');
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { isConfirmModalOpened, askConfirm, closeConfirmModal, confirmCallback } =
    useConfirmationModal();

  const { created_by_team, historyEvents } = item;
  const isAdditionalRequirement = created_by_team && showActionIcons;
  const addedBy = useMemo(() => {
    if (!created_by_team) return;
    const addedEvent = historyEvents?.find((event) => isHistoryActionCreate(event.action));
    if (!addedEvent?.updated_by_user) return;
    return `by ${getUserFullName(addedEvent?.updated_by_user)}`;
  }, [created_by_team, historyEvents]);

  const skippedBy = useMemo(() => {
    const skippedEvent = historyEvents?.find((event) => isPolicyItemSkipped(event.status));
    if (!skippedEvent?.updated_by_user) return;
    return `by ${getUserFullName(skippedEvent?.updated_by_user)}`;
  }, [historyEvents]);

  const checkedByUser = useMemo(() => {
    const checkedEvent = historyEvents?.find((event) => isPolicyItemCompleted(event.status));
    if (!checkedEvent?.updated_by_user) return;
    return `${getUserFullName(checkedEvent?.updated_by_user)}, ${dateFormatter({
      date: checkedEvent?.updated_at,
      withTime: true,
    })}`;
  }, [historyEvents]);

  const canBeEdited = (creatorId: string) =>
    !isRestricted(PermissionNamesEnums.DOCUMENTS_EDIT, permissions) ||
    isCreatedByUser(creatorId, user.id);

  const deleteDocumentMutation = useMutation<Response, Error, ChangeProjectDocumentParam>(
    deleteProjectDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_CHECKLIST, { projectId }]);

        if (drawRequestId) {
          queryClient.invalidateQueries([
            QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
            { projectId, drawRequestId },
          ]);
        }
        setActiveDocument(null);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const getConfirmCallback = async ({ documentId }) => {
    await deleteDocumentMutation.mutateAsync({
      projectId,
      documentId,
    });

    closeConfirmModal();
  };

  const deleteDocument = (documentId: string, name: string) => {
    setModalInfo(`Are you sure you want to delete the following file - ${name}?`);
    askConfirm({ documentId });
  };

  return {
    activeDocument,
    setActiveDocument,
    deleteDocument,
    addedBy,
    canBeEdited,
    checkedByUser,
    isAdditionalRequirement,
    skippedBy,
    tab,
    setTab,
    TABS,
    modalInfo,
    closeConfirmModal,
    confirmCallback: () => confirmCallback({ action: getConfirmCallback }),
    isConfirmModalOpened,
  };
};
