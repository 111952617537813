import React from 'react';
import { PermissionsProvider, SettingsProvider } from '@context';
import { ComponentProps } from './interface';
import PageLayout from './PageLayout';

import 'intro.js/introjs.css';

const WrappedPageLayout = ({ screensACL, updateAvailable }: ComponentProps) => (
  <SettingsProvider>
    <PermissionsProvider>
      <PageLayout screensACL={screensACL} updateAvailable={updateAvailable} />
    </PermissionsProvider>
  </SettingsProvider>
);

export default WrappedPageLayout;
