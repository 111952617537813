import { useContext, useMemo } from 'react';
import { useMutation } from 'react-query';
import {
  isServiceOrderCancellableByPermission,
  isOrderedService,
  isServiceProviderManual,
  isInspectionService,
  isInactiveProject,
} from '@utils';
import {
  IServiceOrder,
  CommonInspectionPayload,
  CommonServiceOrderPayload,
  IInspection,
} from '@interfaces';
import {
  useSafeSnackbar,
  useInspectionStatusInvalidation,
  useServiceOrderStatusInvalidation,
  useConfirmationModal,
} from '@hooks';
import { cancelInspection, cancelServiceOrder } from '@globalService';
import { PermissionsContext } from '@context';
import { ControllerInterface } from './interface';

export const useServiceOrderDocuments = (
  serviceOrder: IServiceOrder | IInspection,
): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);

  const handleInspectionStatusChangeInvalidation = useInspectionStatusInvalidation();
  const handleServiceOrderChangeInvalidation = useServiceOrderStatusInvalidation();
  const confirmModal = useConfirmationModal();

  const { enqueueSnackbar } = useSafeSnackbar();

  const showCancelButton = useMemo(
    () =>
      isServiceOrderCancellableByPermission(permissions) &&
      isOrderedService(serviceOrder?.status) &&
      !isInactiveProject(serviceOrder?.project?.status),
    [permissions, serviceOrder?.status, serviceOrder?.project?.status],
  );

  const isServiceManual = isServiceProviderManual(
    (serviceOrder as IServiceOrder)?.service_agency?.service_provider ||
      (serviceOrder as IInspection)?.inspection_agency?.service,
  );

  const cancelInspectionMutation = useMutation<Response, Error, CommonInspectionPayload>(
    cancelInspection,
    {
      onSuccess: () => {
        handleInspectionStatusChangeInvalidation({
          projectId: serviceOrder?.project?.id,
          drawRequestId: serviceOrder?.draw_request?.id,
          inspectionId: serviceOrder?.id,
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onSettled: () => {
        confirmModal.closeConfirmModal();
      },
    },
  );

  const cancelServiceOrderMutation = useMutation<Response, Error, CommonServiceOrderPayload>(
    cancelServiceOrder,
    {
      onSuccess: () => {
        handleServiceOrderChangeInvalidation({
          projectId: serviceOrder?.project?.id,
          drawRequestId: serviceOrder?.draw_request?.id,
          serviceOrderId: serviceOrder?.id,
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onSettled: () => {
        confirmModal.closeConfirmModal();
      },
    },
  );

  const handleCancelService = async () => {
    if (isInspectionService(serviceOrder?.service_type)) {
      await cancelInspectionMutation.mutateAsync({
        projectId: serviceOrder?.project?.id,
        inspectionId: serviceOrder?.id,
      });
    } else {
      await cancelServiceOrderMutation.mutateAsync({
        projectId: serviceOrder?.project?.id,
        serviceOrderId: serviceOrder?.id,
      });
    }
  };

  return {
    showCancelButton,
    confirmModal,
    isServiceManual,
    handleCancelService,
  };
};
