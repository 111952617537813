import React, { useCallback, useMemo, useState } from 'react';
import { DrawRequestTypeEnum, IProject, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import { useQuery } from 'react-query';
import { getProject } from '@globalService';
import {
  getHiddenColumnsForDR,
  isReallocationEnabledByProject,
  mergeColumnVisibilityModel,
} from '@utils';
import { useColumnFilterV2, useUpdateUiSettings } from '@hooks';
import { useLaunchDarklyFlags } from '@context';

export interface ControllerInterface {
  retainageRate: boolean;
  isReallocationAvailable?: boolean;
  createType?: Array<DrawRequestTypeEnum>;
  setCreateType: React.Dispatch<React.SetStateAction<DrawRequestTypeEnum[]>>;
  shortCreate: (withOverWrite: boolean) => void;
}

export const useRequestCreateDialog = (
  onClose: () => void,
  createRequest: (type: DrawRequestTypeEnum) => void,
  projectId: string,
): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const [createType, setCreateType] = useState<DrawRequestTypeEnum[]>([]);
  const { updateUserColumns } = useColumnFilterV2(TableKeyEnum.SUBMISSION);
  const { userSettings, updateTableSettings } = useUpdateUiSettings();

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const isReallocationAvailable = useMemo(
    () => isReallocationEnabledByProject(projectQuery.data),
    [projectQuery.data],
  );
  const retainageRate = useMemo(() => !!projectQuery.data?.retainage_rate, [projectQuery.data]);

  const shortCreate = useCallback(
    (withOverWrite: boolean) => {
      if (withOverWrite) {
        const hiddenColumns = getHiddenColumnsForDR(createType);
        if (flags?.[`ENG_7895_table_v3__${TableKeyEnum.SUBMISSION}`]) {
          updateTableSettings(
            TableKeyEnum.SUBMISSION,
            mergeColumnVisibilityModel(
              hiddenColumns,
              userSettings.tableV3?.[TableKeyEnum.SUBMISSION],
            ),
          );
        }

        updateUserColumns(hiddenColumns);
      }

      onClose();
      if (createType[0] === DrawRequestTypeEnum.CHANGE_REQUEST && createType.length === 1) {
        createRequest(DrawRequestTypeEnum.CHANGE_REQUEST);
      } else {
        createRequest(DrawRequestTypeEnum.DRAW_REQUEST);
      }
    },
    [createType, createRequest, userSettings],
  );

  return {
    retainageRate,
    isReallocationAvailable,
    createType,
    setCreateType,
    shortCreate,
  };
};
