import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M6.85327 22L18.8533 22C20.5101 22 21.8533 20.6569 21.8533 19V12L21.8533 8.37939C21.8533 7.64611 21.5847 6.93822 21.0983 6.38947L19.4502 4.53001L18.1675 3.04161C17.5976 2.38028 16.7679 2 15.8949 2H12.8533H6.85327C5.19642 2 3.85327 3.34314 3.85327 5V19C3.85327 20.6569 5.19642 22 6.85327 22ZM16.8533 4.58022L17.9442 5.84616L18.9669 7H16.8533V4.58022ZM14.8533 4V8C14.8533 8.55229 15.301 9 15.8533 9H19.8533V12L19.8533 19C19.8533 19.5523 19.4056 20 18.8533 20L6.85327 20C6.30099 20 5.85327 19.5523 5.85327 19L5.85327 5C5.85327 4.44772 6.30099 4 6.85327 4L12.8533 4L14.8533 4ZM8.85327 12C8.85327 11.4477 9.30099 11 9.85327 11H15.8533C16.4056 11 16.8533 11.4477 16.8533 12C16.8533 12.5523 16.4056 13 15.8533 13H9.85327C9.30099 13 8.85327 12.5523 8.85327 12ZM9.85327 15C9.30099 15 8.85327 15.4477 8.85327 16C8.85327 16.5523 9.30099 17 9.85327 17H15.8533C16.4056 17 16.8533 16.5523 16.8533 16C16.8533 15.4477 16.4056 15 15.8533 15H9.85327Z"
    />
  </svg>
);

export default Icon;
