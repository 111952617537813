import React from 'react';
import { Stack, Typography } from '@mui/material';
import {
  InfoIconWithTooltip,
  LabelAndValue,
  LoadingSkeleton,
  ServiceMessage,
  StyledBox,
} from '@components';
import { colors } from '@theme';
import { useDrawPaymentInfo } from './controller';
import { currencyFormatter } from '@utils';
import { HookState } from '@interfaces';

const DrawPaymentInfo = () => {
  const {
    borrowerEquityWithFees,
    netFundsForDisbursement,
    state,
    showRetainage,
    totalAmount,
    retainageHeldback,
    retainageReleased,
    fees,
    borrowerPortionText,
  } = useDrawPaymentInfo();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="draw summary" />;
    }

    case HookState.SUCCESS: {
      return (
        <>
          <StyledBox sx={{ mt: 1 }}>
            <Typography variant="h3" sx={{ mb: 3 }}>
              Draw summary
            </Typography>
            <LabelAndValue
              label="Total draw amount"
              text={totalAmount}
              textDataTestName="payments_tab__total_amount__value"
            />
            {showRetainage && (
              <>
                <LabelAndValue
                  label="Retainage heldback"
                  text={currencyFormatter(retainageHeldback * -1)}
                  textDataTestName="payments_tab__retainage_heldback__value"
                />
                <LabelAndValue
                  label="Retainage released"
                  text={retainageReleased}
                  textDataTestName="payments_tab__retainage_released__value"
                />
              </>
            )}
            <LabelAndValue
              label="Borrower portion"
              text={currencyFormatter(borrowerEquityWithFees * -1)}
              textDataTestName="payments_tab__borrower_portion__value"
              icon={
                <InfoIconWithTooltip
                  color={
                    borrowerEquityWithFees
                      ? colors.status.information.medium
                      : colors.neutral.medium
                  }
                  tooltipText={
                    borrowerEquityWithFees ? (
                      <Stack>
                        <Typography
                          variant="label"
                          data-cy="payments_tab__borrower_portion__tooltip__label"
                        >
                          {borrowerPortionText.title}
                        </Typography>
                        <Typography
                          variant="labelSemiBold"
                          sx={{ color: colors.status.information.medium }}
                          data-cy="payments_tab__borrower_portion__tooltip__value"
                        >
                          {borrowerPortionText.text}
                        </Typography>
                      </Stack>
                    ) : null
                  }
                />
              }
            />
            <LabelAndValue
              label="Draw fees"
              text={fees}
              textDataTestName="payments_tab__draw_fees__value"
            />
          </StyledBox>
          <StyledBox sx={{ mt: 1, justifyContent: 'space-between', display: 'flex' }}>
            <Typography variant="h4" sx={{ color: colors.text.medium }}>
              Net funds for disbursement
            </Typography>
            <Typography variant="h3" data-cy="payments_tab__net_funds__value">
              {netFundsForDisbursement}
            </Typography>
          </StyledBox>
        </>
      );
    }
    default:
      return null;
  }
};

export default DrawPaymentInfo;
