import React, { ReactElement, useCallback, useRef } from 'react';
import { IconButton } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';

import { DragItemIcon } from '@svgAsComponents';
import { colors } from '@theme';

interface HookInterface {
  opacity: number;
  boxShadow: string;
  getDragItemIcon: () => ReactElement;
  removeItem: () => void;
  dropRef: React.RefObject<any>;
  updateRecord: (rowIndex: number, value: string) => void;
}
interface ComponentProps {
  dndItemType: string;
  setItems: any;
  index: number;
  onEnd?: () => void;
}

export const useDragAndDrop = ({
  dndItemType,
  setItems,
  index,
  onEnd,
}: ComponentProps): HookInterface => {
  const dropRef = useRef(null);
  const dragRef = useRef(null);

  const updateRecord = useCallback((rowIndex, value) => {
    setItems((old) =>
      old.map((row, index) => {
        if (index === rowIndex) return { ...old[rowIndex], label: value };
        return row;
      }),
    );
  }, []);

  const removeItem = useCallback(() => setItems((old) => old.filter((_, i) => i !== index)), []);

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    setItems((old) => {
      const newItems = old.filter((_, i) => i !== dragIndex);
      newItems.splice(hoverIndex, 0, old[dragIndex]);
      return newItems;
    });
  }, []);

  const [, drop] = useDrop({
    accept: dndItemType,
    hover(item: ReactElement & { index: number }, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: dndItemType,
    item: { type: dndItemType, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      // Handle drag end here
      if (onEnd) onEnd();
    },
  });

  const opacity = isDragging ? 0 : 1;
  const boxShadow = isDragging
    ? '0px 1px 4px rgb(0 58 83 / 12%), 0px 8px 16px rgb(0 58 83 / 16%)'
    : 'none';

  preview(drop(dropRef));
  drag(dragRef);

  const getDragItemIcon = useCallback(
    () => (
      <IconButton ref={dragRef} sx={{ cursor: 'move' }}>
        <DragItemIcon color={colors.icons.gray} />
      </IconButton>
    ),
    [dragRef],
  );

  return {
    opacity,
    boxShadow,
    getDragItemIcon,
    removeItem,
    dropRef,
    updateRecord,
  };
};
