import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 14, color = colors.icons.gray }: ComponentProps) => (
  <svg width={size} height="8" fill="none" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 7.70711C-0.0976311 7.31658 -0.0976312 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976317 7.31658 -0.0976318 7.70711 0.292893L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L7 2.41421L1.70711 7.70711C1.31658 8.09763 0.683418 8.09763 0.292893 7.70711Z"
      fill={color}
    />
  </svg>
);

export default Icon;
