import { useQuery } from 'react-query';
import { getCompanyProjects } from '@globalService';
import { HookState, IProject, QueryNamesEnums } from '@interfaces';
import { getHookState } from '@utils';

export interface ControllerInterface {
  state: HookState;
  projects: IProject[];
  showEllipsis: boolean;
  count: number;
}

export const useCompanyProjectsInfoModal = (companyId: string): ControllerInterface => {
  const companyProjectsQuery = useQuery<{ results: IProject[]; count: number }, Error>(
    [QueryNamesEnums.GET_COMPANY_PROJECTS, { companyId }],
    getCompanyProjects.bind(this, companyId),
    { enabled: Boolean(companyId) },
  );

  return {
    state: getHookState(companyProjectsQuery),
    projects: companyProjectsQuery.data?.results || [],
    showEllipsis: companyProjectsQuery.data?.count > 20,
    count: companyProjectsQuery.data?.count || 0,
  };
};
