import React, { FC, useContext, useState } from 'react';
import { Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { InfoIcon } from '@svgAsComponents';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { IProject, QueryNamesEnums } from '@interfaces';
import { updateProjectFields } from '@globalService';
import { useSafeSnackbar } from '@hooks';
import { colors } from '@theme';
import { SettingsContext } from '@context';

const IsProductionBuildCheckbox: FC<{
  isDefaultProdBuildProject: boolean;
  wrapperPadding?: number;
  onChange?: (isProductionBuildProject: boolean) => void;
}> = ({ isDefaultProdBuildProject, wrapperPadding = 2, onChange }) => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { setPHBProject } = useContext(SettingsContext);
  const [isProductionBuildProject, setIsProductionBuildProject] =
    useState(isDefaultProdBuildProject);

  const updateInfoProject = useMutation<
    Response,
    Error,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Stack
      direction="row"
      spacing={0.5}
      padding={wrapperPadding}
      alignItems="center"
      sx={{ flexGrow: 1, backgroundColor: colors.background.white }}
    >
      <Checkbox
        checked={isProductionBuildProject}
        onChange={(_e, value) => {
          if (projectId) {
            updateInfoProject.mutateAsync({
              projectId,
              json: { is_advanced_budget_tracking_enabled: value },
            });
          }
          if (onChange) {
            onChange(value);
          }
          setPHBProject(value);
          setIsProductionBuildProject(value);
        }}
        data-cy="project__is_phb__checkbox"
      />
      <Typography variant="body3">
        Enable advanced project tracking for multi-dimensional budgets
      </Typography>
      <Tooltip
        title={
          <>
            This option allows tracking across different dimensions such as models and lots in
            addition to the standard line item level.
            <br /> <br /> You can update this setting when you enter in the budget.
          </>
        }
      >
        <Stack>
          <InfoIcon size={16} />
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default IsProductionBuildCheckbox;
