import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useVerifyBorrowerModal } from './controller';
import { CustomTextField, InternationalPhoneField } from '@components';
import { EditProfileImage } from '@svgAsComponents';
import { colors } from '@theme';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';

const VerifyBorrowerModal: FC = () => {
  const {
    open,
    firstNameField,
    lastNameField,
    emailField,
    phoneNumberField,
    onConfirm,
    companyField,
    hasCompanyTeamsEditPermission,
    urlField,
  } = useVerifyBorrowerModal();

  const body = () => (
    <Stack
      sx={{
        backgroundColor: colors.neutral.darker,
        position: 'fixed',
        top: 0,
        height:
          isMobile && document.documentElement.style.getPropertyValue('--app-height')
            ? 'var(--app-height)'
            : '100vh',
        width: '100%',
        left: 0,
        margin: 0,
        zIndex: 1200,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          flex: 1,
        }}
      >
        <Stack
          sx={{
            backgroundColor: colors.background.white,
            p: 3,
            minWidth: { lg: 950, md: 800, xs: '100%' },
            minHeight: { md: 'auto', xs: '100%' },
          }}
        >
          <Typography variant="h1">Before we start, confirm your personal information.</Typography>
          <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', pt: 8 }}>
            <Stack spacing={2.5} marginRight={8} sx={{ width: '60%' }}>
              <Stack direction="row" spacing={2.5}>
                <CustomTextField
                  field={firstNameField}
                  label="First name"
                  inputProps={{
                    'data-cy': 'verify_borrower_modal__first_name__input',
                  }}
                />
                <CustomTextField
                  field={lastNameField}
                  label="Last name"
                  inputProps={{
                    'data-cy': 'verify_borrower_modal__last_name__input',
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={2.5}>
                <InternationalPhoneField
                  field={phoneNumberField}
                  inputProps={{
                    'data-cy': 'verify_borrower_modal__phone__input',
                  }}
                />
                <CustomTextField field={emailField} label="Email" disabled />
              </Stack>
              <Stack direction="row" spacing={2.5}>
                <CustomTextField
                  field={companyField}
                  label="Company"
                  disabled={!hasCompanyTeamsEditPermission}
                />
                <CustomTextField
                  field={urlField}
                  label="Web site"
                  disabled={!hasCompanyTeamsEditPermission}
                  helperText={urlField.isValid ? '' : 'Invalid url'}
                />
              </Stack>
            </Stack>
            <EditProfileImage />
          </Stack>
          <LoadingButton
            onClick={onConfirm}
            data-cy="verify_borrower_modal__confirm__button"
            sx={{ alignSelf: 'flex-end', width: '95px', mt: 7.5 }}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
  if (!open) return null;
  return <>{ReactDOM.createPortal(body(), document.body)}</>;
};

export default VerifyBorrowerModal;
