import { checkIsOwner, getArrayFromObject } from '@utils';
import { FilterObject } from '../interface';
import { DrawRequestListStatusMap } from '@constants';
import { DrawRequestStatus } from '@interfaces';

export const requestStatusFilter: FilterObject = {
  title: 'Request status',
  filterKey: 'draw_request_status',
  needToUpdateSetting: true,
  needToUpdateUrl: true,
  defaultFilters: (role) =>
    checkIsOwner(role)
      ? [DrawRequestStatus.IN_REVIEW, DrawRequestStatus.DRAFT]
      : [DrawRequestStatus.IN_REVIEW],
  getStaticValues: () =>
    getArrayFromObject(DrawRequestListStatusMap, 'value', 'label') as unknown as Array<{
      label: string;
      value: string;
    }>,
  cypressSelector: 'filters__draw_request_status',
};
