import React, { FC, PropsWithChildren } from 'react';
import { Button, ClickAwayListener } from '@mui/material';
import { ComponentProps } from './interface';

import { Popper, PopperPlacementType } from '@mui/base/Popper';
import { styled } from '@mui/system';
import { colors } from '@theme';

const StyledPopperDiv = styled('div')(
  () => `
  background-color: ${colors.background.white};
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
`,
);

const ButtonWithPopper: FC<PropsWithChildren<ComponentProps>> = ({
  children,
  buttonText,
  maxWidth = '300px',
  placement = 'bottom-end' as PopperPlacementType,
  anchorEl,
  setAnchorEl,
  dataTestName,
  buttonClickCallback,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (buttonClickCallback) buttonClickCallback();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Button aria-describedby={id} type="button" onClick={handleClick} data-cy={dataTestName}>
          {buttonText}
        </Button>
        <Popper id={id} open={open} anchorEl={anchorEl} placement={placement}>
          <StyledPopperDiv sx={{ maxWidth }}>{children}</StyledPopperDiv>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default ButtonWithPopper;
