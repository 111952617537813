import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.03108 7C6.08664 7 4.85647 7.30465 3.83915 8.08238C2.78126 8.89111 2 10.1805 2 12C2 14.4686 3.8792 17 7.03108 17C9.0873 17 10.4457 15.8764 11.1049 15H11.6454L13.0447 16.3907C13.5885 16.9311 14.4543 16.9788 15.0548 16.5013L16.5901 15.2806L18.0178 16.4157C18.7415 16.9911 19.8113 16.7884 20.2711 15.9886L21.8011 13.3277C22.1388 12.7404 22.0392 12.0012 21.5579 11.5229L19.4614 9.43934C19.1784 9.15804 18.7945 9 18.3942 9H14.0746H11.0968C10.5466 8.27388 9.26006 7 7.03108 7ZM4.01243 12C4.01243 10.8195 4.48895 10.1089 5.06615 9.66762C5.68393 9.19535 6.4662 9 7.03108 9C8.5 9 9.27623 9.88841 9.56053 10.3017C9.82418 10.6849 10.2889 11 10.8638 11H14.0746H18.1858L19.8632 12.667L18.8157 14.4888L17.533 13.469C16.9818 13.0307 16.1985 13.0307 15.6473 13.469L14.1493 14.66L12.921 13.4393C12.638 13.158 12.2541 13 11.8538 13H10.8638C10.2769 13 9.81544 13.329 9.55907 13.71C9.21538 14.2207 8.37329 15 7.03108 15C5.15188 15 4.01243 13.5314 4.01243 12ZM7.03108 11C6.47536 11 6.02487 11.4477 6.02487 12C6.02487 12.5523 6.47536 13 7.03108 13C7.5868 13 8.0373 12.5523 8.0373 12C8.0373 11.4477 7.5868 11 7.03108 11Z"
      fill={color}
    />
  </svg>
);

export default Icon;
