import React, { useContext } from 'react';
import { SettingsContext } from '@context';
import { statusMap } from '@constants';
import { isRequestDraft, isProjectPaused } from '@utils';
import { StatusChip } from '@components';
import { Stack, Tooltip, Typography } from '@mui/material';
import { colors } from '@theme';

export const getStatusChip = (
  drawRequest,
  size: 'small' | 'large' = 'small',
  prefilledText = '',
  direction: 'row' | 'row-reverse' | 'column' | 'column-reverse' = 'row',
) => {
  if (!drawRequest?.status) return null;
  const { settings } = useContext(SettingsContext);

  const {
    status,
    is_resubmit,
    is_resubmit_change_reason,
    is_on_hold,
    project,
    is_on_hold_change_reason,
  } = drawRequest;
  const statusValue = statusMap(status, settings.display, 'draw_request');
  const text = statusValue.text;

  statusValue.text = text;

  const getAdditionalChipText = () => {
    if (is_resubmit && isRequestDraft(status)) return 'Returned';
    if (is_on_hold) return 'On hold';
    if (isProjectPaused(project?.status)) return 'Project paused';
    return null;
  };

  return (
    <Stack direction={direction} spacing={0.5}>
      <StatusChip
        color={statusValue.color}
        backgroundColor={statusValue.backgroundColor}
        label={`${prefilledText}${text}`}
        size={size}
        dataTestName="draw_status"
      />
      {getAdditionalChipText() && (
        <Tooltip
          title={
            <Typography
              variant="label"
              dangerouslySetInnerHTML={{
                __html: is_on_hold
                  ? is_on_hold_change_reason
                  : is_resubmit
                    ? is_resubmit_change_reason
                    : '',
              }}
            />
          }
        >
          <>
            <StatusChip
              color={colors.status.error.medium}
              backgroundColor={colors.status.error.lighter}
              label={getAdditionalChipText()}
              size="small"
            />
          </>
        </Tooltip>
      )}
    </Stack>
  );
};
