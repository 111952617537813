import React from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { colors, typography } from '@theme';

interface CustomSelectProps {
  label: string;
  optionsList: string[];
  selectedOptions: string[];
  handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
  required?: boolean;
  error?: boolean;
  dataTestName?: string;
  disableMultiple?: boolean;
  disabled?: boolean;
  errorText?: string;
}

const CustomSelect = ({
  label,
  handleSelectChange,
  optionsList,
  selectedOptions,
  required,
  error,
  dataTestName,
  disableMultiple,
  disabled,
  errorText = 'The reason is required',
  ...props
}: CustomSelectProps) => {
  return (
    <FormControl
      fullWidth
      sx={{
        width: '100%',
        "& .MuiInputLabel-root[data-shrink='false']": { top: '-6px' },
      }}
      required={required}
      error={error}
      {...props}
    >
      <InputLabel id="reason-select-label" sx={{ ...typography.label, color: colors.text.medium }}>
        {label}
      </InputLabel>

      <Select
        multiple={!disableMultiple}
        labelId="reason-select-label"
        id="reason-select"
        value={selectedOptions}
        onChange={handleSelectChange}
        renderValue={(selected) => (disableMultiple ? selected : selected.join(', '))}
        size="small"
        data-cy={dataTestName}
        disabled={disabled}
      >
        {optionsList?.map((item, index) => (
          <MenuItem key={index} value={item}>
            {!disableMultiple && <Checkbox checked={selectedOptions.includes(item)} />}
            <Typography variant="body2">{item}</Typography>
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelect;
