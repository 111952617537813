import { AuthContext } from '@context';
import { getUserMe } from '@globalService';
import { IUser, QueryNamesEnums } from '@interfaces';
import { getUserForStorage } from '@utils';
import { useContext } from 'react';
import { useQuery } from 'react-query';

export const useUser = () => {
  const { setUser } = useContext(AuthContext);

  const userQuery = useQuery<IUser, Error>([QueryNamesEnums.GET_USER], getUserMe.bind(this), {
    enabled: false,
    onSuccess: (data) => {
      if (!data) return;
      const user = getUserForStorage(data);
      setUser(user);
    },
  });

  return userQuery;
};
