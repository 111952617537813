import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { FormControlLabel, Switch, TableCell, TableRow, Typography } from '@mui/material';
import { IRoleNew, RolePayload } from '@interfaces';
import { useStringFieldModel } from '@models';
import { RoleIconWithLink, TextInputWithTooltip } from '@components';

interface ComponentProps {
  role: IRoleNew;
  setItems?: Dispatch<SetStateAction<IRoleNew[]>>;
  updateRole: (data: RolePayload) => void;
}

const RoleRow = ({ role, updateRole }: ComponentProps) => {
  const companyRole = useStringFieldModel({
    initValue: role.company_role,
    withProgressCheck: true,
  });

  const reviewLever = useStringFieldModel({
    initValue: role.review_level.toString(),
    withProgressCheck: true,
  });

  const updateApi = useCallback(() => {
    const payload: RolePayload = {
      roleId: role.id,
      data: {},
    };
    if (companyRole.value !== role.company_role) {
      payload.data.company_role = companyRole.value;
    }

    if (+reviewLever.value !== role.review_level) {
      payload.data.review_level = +reviewLever.value;
    }
    if (Object.keys(payload.data).length) {
      updateRole(payload);
    }
  }, [companyRole, reviewLever]);

  return (
    <TableRow key={role.id}>
      <TableCell>
        <TextInputWithTooltip
          type="number"
          onBlur={updateApi}
          value={reviewLever.value}
          onChange={reviewLever.handleChange}
        />
      </TableCell>
      <TableCell>
        <TextInputWithTooltip
          type="string"
          onBlur={updateApi}
          value={companyRole.value}
          onChange={companyRole.handleChange}
          error={!companyRole.value}
        />
      </TableCell>
      <TableCell>
        <Typography>
          {role.platform_role === 'LENDER' ? 'all projects' : 'by invite only'}
        </Typography>
      </TableCell>
      <TableCell>
        <FormControlLabel
          value={!!(role.review_level > 0)}
          control={
            <Switch
              onChange={() =>
                updateRole({
                  roleId: role.id,
                  data: {
                    review_level: role.review_level > 0 ? 0 : 1,
                  },
                })
              }
              checked={role.review_level > 0}
              data-cy="company_teams__workflow__include_in_approval_flow__switch"
            />
          }
          componentsProps={{ typography: { variant: 'body3' } }}
          name="includeInApprovalFlow"
          label="Include in approval flow"
        />
      </TableCell>
      <TableCell>
        <RoleIconWithLink roleId={role.id} dataTestName="test_" />
      </TableCell>
    </TableRow>
  );
};

export default RoleRow;
