import { useMemo } from 'react';
import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import { getHookState } from '@utils';
import { HookState, IProjectFunds, QueryNamesEnums } from '@interfaces';
import { getProjectFunds } from '@globalService';

const requiredKeys = ['uses'] as const;
type ProjectUses = Pick<IProjectFunds, (typeof requiredKeys)[number]>;

export interface ControllerInterface {
  keys: string[];
  values: number[];
  total: number;
  state: HookState;
}

const getUsesFromQuery = (query: ProjectUses) => {
  const { items, total } = query?.uses || {};

  if (isEmpty(items)) {
    return {
      keys: [],
      values: [],
      total: 0,
    };
  }
  const { keys, values } = items.reduce(
    (acc, { name, amount }) => ({
      keys: [...acc.keys, name.display],
      values: [...acc.values, amount],
    }),
    { keys: [], values: [] },
  );

  return {
    keys,
    values,
    total,
  };
};

export const useProjectUses = (projectId: string): ControllerInterface => {
  const projectUsesQuery = useQuery<ProjectUses, Error>(
    [QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }],
    getProjectFunds.bind(this, projectId),
  );

  const uses = useMemo(() => getUsesFromQuery(projectUsesQuery.data), [projectUsesQuery.data]);

  return {
    ...uses,
    state: getHookState(projectUsesQuery),
  };
};
