import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Divider, Link, Stack, Theme, useMediaQuery } from '@mui/material';
import { Logo as LogoIcon, PoweredWithELText } from '@svgAsComponents';

const Logo = styled('img')(({ theme }) => ({
  height: 32,
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('md')]: { height: 24 },
}));

const LogoBox = ({ customerLogo }) => {
  const middleMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box sx={{ mr: { xs: 1, lg: 10 }, display: 'flex' }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {customerLogo && <Logo alt="Customer logo" src={customerLogo} />}
        {customerLogo && <Divider orientation="vertical" />}
        <Link href="/">
          <LogoIcon size={middleMediaQuery ? 32 : 24} />
        </Link>
        {customerLogo && <PoweredWithELText />}
      </Stack>
    </Box>
  );
};

export default LogoBox;
