import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { compareVersions } from 'compare-versions';

import { AppUpdateContext } from '@context';

export const useCacheBoost = ({ version }) => {
  const location = useLocation();
  const { setUpdateAvailable } = useContext(AppUpdateContext);

  useEffect(() => {
    fetch(`/meta.json?v=${+new Date()}`, { cache: 'no-cache' })
      .then((response) => response.json())
      .then((meta) => {
        if (!meta?.version || compareVersions(meta.version, version?.toString()) === 1)
          setUpdateAvailable(true);
      })
      .catch((error) => {
        console.error('something went wrong fetching meta.json', error);
      });
  }, [location]);

  return null;
};
