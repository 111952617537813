export const companyTeams = ({
  companyId,
  is_on_shared_project = false,
}: {
  companyId: string;
  is_on_shared_project?: boolean;
}) => `companies/${companyId}/teams/${is_on_shared_project ? '?is_on_shared_project=true' : ''}`;

export const companyTeamById = (company_pk = 'company_pk', team_pk = 'team_pk') =>
  `companies/${company_pk}/teams/${team_pk}/`;

export const teamMembers = (company_pk = 'company_pk', team_pk = 'team_pk') =>
  `companies/${company_pk}/teams/${team_pk}/members/`;

export const teamUserById = (team_pk = 'team_pk', user_pk = 'user_pk') =>
  `teams/${team_pk}/users/${user_pk}/`;

export const bulkTeamsUpdate = (company_pk = 'company_pk') => `companies/${company_pk}/teams/bulk/`;

export const bulkTeamsMembersUpdate = (company_pk = 'company_pk') =>
  `companies/${company_pk}/members/teams/bulk/`;
