import React, { FC, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Dashboard } from '@uppy/react';
import Uppy, { Meta } from '@uppy/core';
import { colors } from '@theme';

const StyledDashboard = styled(Dashboard)(() => ({
  '.uppy-Dashboard-note, .uppy-size--md .uppy-Dashboard-note': {
    maxWidth: '700px',
  },
  '.uppy-Dashboard-inner': {
    maxHeight: '450px',
  },
  '.uppy-size--md .uppy-Dashboard-AddFiles-info': {
    padding: 0,
  },
  '.uppy-Dashboard-AddFiles-title, .uppy-size--md .uppy-Dashboard-AddFiles-title': {
    fontFamily: 'Inter',
    fontSize: '26px',
    fontWeight: '600',
    lineHeight: '30px',
    color: colors.main.primary.main,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '700px',
  },
  '.uppy-Dashboard-browse, .uppy-size--md .uppy-Dashboard-browse': {
    color: colors.text.link,
    fontWeight: '600',
    textDecoration: 'none',
  },
  '#uppy-DashboardContent-panel--Webcam': {
    zIndex: 9999,
  },
}));

const StyledDashboardOld = styled(Dashboard)(() => ({
  '.uppy-Dashboard-note': {
    maxWidth: '500px',
  },
  '#uppy-DashboardContent-panel--Webcam': {
    zIndex: 9999,
  },
}));

interface ComponentProps {
  uppy: Uppy<Meta, any>;
  metaFields?: { id: string; name: string; placeholder: string }[];
  hideUploadButton?: boolean;
  isDocumentUploader?: boolean;
  source: string;
  dropPasteFilesText?: string;
  enabledExtensions?: string[];
}

const StyledDashboardComponent: FC<ComponentProps> = ({
  uppy,
  metaFields = [],
  hideUploadButton = false,
  isDocumentUploader = false,
  source,
  dropPasteFilesText = 'Drag & Drop or %{browse} to upload multiple documents',
  enabledExtensions = [
    'png',
    'heic',
    'jpg',
    'jpeg',
    'svg',
    'svgz',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'msg',
    'eml',
    'csv',
  ],
}) => {
  useEffect(() => {
    setTimeout(() => {
      const myDeviceIcon = document.querySelector(
        '[data-uppy-acquirer-id="MyDevice"] .uppy-DashboardTab-btn',
      );
      if (myDeviceIcon) {
        myDeviceIcon.setAttribute('data-cy', `${source}__upload__modal__my_device__button`);
      }

      const cameraIcon = document.querySelector(
        '[data-uppy-acquirer-id="Webcam"] .uppy-DashboardTab-btn',
      );
      if (cameraIcon) {
        cameraIcon.setAttribute('data-cy', `${source}__upload__modal__camera__button`);
      }

      const browseFilesButton = document.querySelector('.uppy-Dashboard-browse');
      if (browseFilesButton) {
        browseFilesButton.setAttribute('data-cy', `${source}__upload__modal__browse_files__button`);
      }
    }, 300);
  }, []);

  return isDocumentUploader ? (
    <StyledDashboard
      uppy={uppy}
      proudlyDisplayPoweredByUppy={false}
      width={968}
      height="60vh"
      plugins={['Webcam']}
      note={`Max size: 200mb\nEnabled file extensions: ${enabledExtensions.join(', ')}`}
      metaFields={metaFields}
      showProgressDetails
      hideUploadButton={hideUploadButton}
      locale={{
        strings: {
          browseFiles: 'Browse',
          dropPasteFiles: dropPasteFilesText,
          dropPasteImportFiles: dropPasteFilesText,
        },
      }}
    />
  ) : (
    <StyledDashboardOld
      uppy={uppy}
      proudlyDisplayPoweredByUppy={false}
      width={600}
      height={405}
      plugins={['Webcam']}
      note={
        'Max size: 200mb\nEnabled file extensions: png, heic, jpg, jpeg, svg, svgz, pdf, doc, docx, xls, xlsx, msg, eml, csv'
      }
      metaFields={metaFields}
      showProgressDetails
      hideUploadButton={hideUploadButton}
    />
  );
};

export default StyledDashboardComponent;
