import { useQuery } from 'react-query';
import { IPieChartData, IProjectStats, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { getProjectGroupsStats } from '@globalService';
import { useContext, useMemo } from 'react';
import {
  ProjectStatsStatusMap,
  ProjectStatusColorMap,
  scoreColorMap,
  scoreLabelsMap,
} from '@constants';
import { sequentialMultiHuePalette } from '@theme';
import { isRestricted } from '@utils';
import { PermissionsContext } from '@context';
import { LegendPerItemProps } from '@mui/x-charts/ChartsLegend/LegendPerItem';

export interface ControllerInterface {
  statuses: IPieChartData[];
  scores: IPieChartData[];
  states: IPieChartData[];
  borrowers: IPieChartData[];
  largeSize: 6 | 3;
  borrowerLabels: LegendPerItemProps['itemsToDisplay'];
}

const defaultColorMap = {
  0: sequentialMultiHuePalette[0],
  1: sequentialMultiHuePalette[2],
  2: sequentialMultiHuePalette[5],
  3: sequentialMultiHuePalette[6],
};

export const useProjectsStats = ({
  filterStringQuery,
  filtersReady,
}: {
  filterStringQuery: string;
  filtersReady: boolean;
}): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);

  const projectsStatsQuery = useQuery<IProjectStats, Error>(
    [QueryNamesEnums.GET_PROJECTS_STATS, { filterStringQuery }],
    getProjectGroupsStats.bind(this, { filterStringQuery }),
    {
      enabled: filtersReady,
    },
  );

  const createCustomLabel = (inputString: string, inputValue) => {
    if (inputString.length > 16) {
      return inputString.slice(0, 13) + '...' + '(' + inputValue + ')';
    }
    return inputString;
  };

  const convertObjectToArray = ({ items, colorsMap, labelsMap, useDefault = false }) => {
    return Object.keys(items)
      ?.map((key, index) => {
        const color = useDefault ? defaultColorMap[index] : colorsMap[key];
        const label = useDefault ? key : labelsMap[key];

        if (!label) return;
        return {
          label: `${label} (${items[key]})`,
          value: items[key],
          color,
        };
      })
      ?.filter((item) => item);
  };

  const statuses = useMemo(
    () =>
      convertObjectToArray({
        items: projectsStatsQuery.data?.statuses || {},
        colorsMap: ProjectStatusColorMap,
        labelsMap: ProjectStatsStatusMap,
      }),
    [projectsStatsQuery.data?.statuses],
  );

  const scores = useMemo(
    () =>
      convertObjectToArray({
        items: projectsStatsQuery.data?.scores || {},
        colorsMap: scoreColorMap,
        labelsMap: scoreLabelsMap,
      }),
    [projectsStatsQuery.data?.scores],
  );

  const states = useMemo(
    () =>
      convertObjectToArray({
        items: projectsStatsQuery.data?.states || {},
        colorsMap: scoreColorMap,
        labelsMap: scoreLabelsMap,
        useDefault: true,
      }),
    [projectsStatsQuery.data?.states],
  );
  const borrowers = useMemo(
    () =>
      convertObjectToArray({
        items: projectsStatsQuery.data?.borrowers || {},
        colorsMap: scoreColorMap,
        labelsMap: scoreLabelsMap,
        useDefault: true,
      }),
    [projectsStatsQuery.data?.borrowers],
  );
  const borrowerLabels = useMemo(
    () =>
      borrowers.map((item) => ({
        color: item.color,
        label: `${createCustomLabel(item.label, item.value)} `,
        id: item.label,
      })),
    [borrowers],
  );

  const isScoresAndBorrowersHidden = useMemo(
    () => isRestricted(PermissionNamesEnums.SCORES_VIEW, permissions),
    [permissions],
  );

  const largeSize = useMemo(
    () => (isScoresAndBorrowersHidden ? 6 : 3),
    [isScoresAndBorrowersHidden],
  );

  return {
    statuses,
    scores,
    states,
    borrowers,
    largeSize,
    borrowerLabels,
  };
};
