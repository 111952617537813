import React, { FC } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { ConfirmationModal, Popup, WysiwygEditor } from '@components';
import { useDeactivationReasons } from './controller';
import { PopupTypeEnum, ProjectStatusEnum } from '@interfaces';

interface IReasonsModal {
  onClose: () => void;
  onApprove: ({ value, changeReasons }) => void;
  open: boolean;
  confirmText: { value: string; text: string };
  newStatus: ProjectStatusEnum;
}

const ProjectDeactivationReasonsModal: FC<IReasonsModal> = ({
  onClose,
  onApprove,
  confirmText,
  open,
  newStatus,
}) => {
  const {
    comment,
    reasons,
    handleSelectChange,
    reasonsList,
    isSelectedChanged,
    clearInputs,
    onConfirm,
  } = useDeactivationReasons(newStatus, onApprove);

  if (!reasonsList.length)
    return (
      <ConfirmationModal
        open={open}
        title="Confirmation"
        text=""
        maxWidth="xs"
        onClose={onClose}
        confirmCallback={() => onApprove({ value: newStatus, changeReasons: '' })}
        source="project__overview__status_change__deactivation_reasons_modal"
      >
        <Typography variant="body2" textAlign="center">
          You selected{' '}
          <Typography variant="body2SemiBold" component="span">
            {confirmText.value}
          </Typography>
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }} align="center">
          {confirmText.text}
        </Typography>
      </ConfirmationModal>
    );

  return (
    <Popup open={open} type={PopupTypeEnum.CONFIRMATION} title="Confirmation">
      <Stack sx={{ width: '100%' }}>
        <Typography variant="body2" textAlign="center">
          You selected{' '}
          <Typography variant="body2SemiBold" component="span">
            {confirmText.value}
          </Typography>
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }} align="center">
          {confirmText.text}
        </Typography>
        <FormControl
          sx={{ mt: '2rem', width: '100%' }}
          required
          error={isSelectedChanged && reasons.length < 1}
        >
          <InputLabel>Reason(s) for changing status</InputLabel>
          <Select
            multiple
            value={reasons}
            onChange={handleSelectChange}
            renderValue={(selected) => selected.join(', ')}
            input={<OutlinedInput label="Select reason(s)" />}
          >
            {reasonsList?.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox checked={reasons.includes(item)} />
                <Typography variant="body2">{item}</Typography>
              </MenuItem>
            ))}
          </Select>
          {reasons.length < 1 && isSelectedChanged && (
            <FormHelperText>Please, select reason</FormHelperText>
          )}
        </FormControl>
        <WysiwygEditor
          editField={comment}
          source="project__overview__status_change__deactivation_reasons_modal__comment"
        />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" mt="3.75rem">
          <Button
            variant="text"
            onClick={() => {
              onClose();
              clearInputs();
            }}
          >
            Cancel
          </Button>
          <Button onClick={onConfirm} disabled={!reasons.length} sx={{ ml: '0.5rem' }}>
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default ProjectDeactivationReasonsModal;
