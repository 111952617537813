import React, { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { useLineItemDataWrapper } from './controller';

const LineItemDataWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { name } = useLineItemDataWrapper();
  return (
    <Stack flex={1}>
      <Typography variant="body2">
        Project:
        <Typography variant="body2SemiBold"> {name}</Typography>
      </Typography>
      {children}
    </Stack>
  );
};

export default LineItemDataWrapper;
