import { StringFieldModel } from '@models';

//we need to use 12 decimal digits for shares to calculate amount correctly
export const calculateFraction = (amount, total) =>
  total ? Number(((amount * 100) / total).toFixed(12)) : 0;
export const calculateAmount = (fraction, total) => Number((fraction * (total / 100)).toFixed(2));

// workaround for negative 0
export const roundToTwoDigits = (value: number) => (value ? +value.toFixed(2) + 0 : 0);

export const updateFSValues = ({
  value,
  amountField,
  otherAmountField,
  fieldFraction,
  otherFieldFraction,
  constructionBudget,
}: {
  value: string;
  amountField: StringFieldModel;
  otherAmountField: StringFieldModel;
  fieldFraction: StringFieldModel;
  otherFieldFraction: StringFieldModel;
  constructionBudget: StringFieldModel;
}) => {
  amountField.setValue(value);
  const postFundingConstructionBudget = +otherAmountField.value + +value;
  constructionBudget.setAsFloat(postFundingConstructionBudget);

  fieldFraction.setValue(calculateFraction(value, postFundingConstructionBudget)?.toString());

  otherFieldFraction.setValue(
    calculateFraction(
      roundToTwoDigits(+otherAmountField.value),
      postFundingConstructionBudget,
    )?.toString(),
  );
};

export const updateFSFractionValues = ({
  value,
  fractionField,
  otherFractionField,
  amountField,
  otherAmountField,
  constructionBudget,
}: {
  value: string;
  fractionField: StringFieldModel;
  otherFractionField: StringFieldModel;
  amountField: StringFieldModel;
  otherAmountField: StringFieldModel;
  constructionBudget: StringFieldModel;
}) => {
  fractionField.setValue(value);
  otherFractionField.setValue((100 - +value)?.toString());
  const amount = calculateAmount(value, constructionBudget.value);
  amountField.setAsFloat(amount);
  otherAmountField.setAsFloat(calculateAmount(100 - +value, constructionBudget.value));
};
