import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const FundingIconWithIndicator = ({
  size = 24,
  color = colors.icons.gray,
  hasCredit = false,
}: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4223_1935)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.777 2H5.77698C4.12012 2 2.77698 3.34315 2.77698 5V19C2.77698 20.6569 4.12012 22 5.77698 22H19.777C21.4338 22 22.777 20.6569 22.777 19V5C22.777 3.34315 21.4338 2 19.777 2ZM4.77698 5C4.77698 4.44772 5.22469 4 5.77698 4H19.777C20.3293 4 20.777 4.44772 20.777 5V19C20.777 19.5523 20.3293 20 19.777 20H5.77698C5.22469 20 4.77698 19.5523 4.77698 19V5ZM11.777 7C10.1201 7 8.77698 8.34315 8.77698 10C8.77698 11.6569 10.1201 13 11.777 13H13.777C14.3293 13 14.777 13.4477 14.777 14C14.777 14.5523 14.3293 15 13.777 15H10.277C9.72469 15 9.27698 15.4477 9.27698 16C9.27698 16.5523 9.72469 17 10.277 17H11.777V18C11.777 18.5523 12.2247 19 12.777 19C13.3293 19 13.777 18.5523 13.777 18V17C15.4338 17 16.777 15.6569 16.777 14C16.777 12.3431 15.4338 11 13.777 11H11.777C11.2247 11 10.777 10.5523 10.777 10C10.777 9.44772 11.2247 9 11.777 9H15.277C15.8293 9 16.277 8.55228 16.277 8C16.277 7.44772 15.8293 7 15.277 7H13.777V6C13.777 5.44772 13.3293 5 12.777 5C12.2247 5 11.777 5.44772 11.777 6V7Z"
        fill={color}
      />
      {hasCredit && <circle cx="20.8533" cy="4" r="4" fill="#2778C4" />}
    </g>
    <defs>
      <clipPath id="clip0_4223_1935">
        <rect width="24" height="24" fill="white" transform="translate(0.853271)" />
      </clipPath>
    </defs>
  </svg>
);

export default FundingIconWithIndicator;
