import React from 'react';
import { Stack } from '@mui/material';

import { colors } from '@theme';
import { DrawChangeRequestTab } from './components';

const RequestsQueue = () => {
  return (
    <Stack sx={{ height: '100%' }}>
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          flexGrow: 1,
          p: { md: 3, xs: 2 },
        }}
      >
        <DrawChangeRequestTab />
      </Stack>
    </Stack>
  );
};

export default RequestsQueue;
