import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.main.primary.main }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8314 17.6082C12.2175 17.2133 12.2104 16.5802 11.8155 16.1941C11.4206 15.808 10.7875 15.8151 10.4014 16.21L8.11639 18.5469V3C8.11639 2.44772 7.66868 2 7.11639 2C6.56411 2 6.11639 2.44772 6.11639 3L6.11639 18.5469L3.8314 16.21C3.44529 15.8151 2.81216 15.808 2.41728 16.1941C2.02239 16.5802 2.01528 17.2133 2.40139 17.6082L6.40139 21.6991C6.58953 21.8915 6.84728 22 7.11639 22C7.38551 22 7.64326 21.8915 7.8314 21.6991L11.8314 17.6082ZM21.8314 6.39179C22.2175 6.78668 22.2104 7.41981 21.8155 7.80592C21.4206 8.19203 20.7875 8.18492 20.4014 7.79003L18.1164 5.4531V21C18.1164 21.5523 17.6687 22 17.1164 22C16.5641 22 16.1164 21.5523 16.1164 21V5.4531L13.8314 7.79003C13.4453 8.18492 12.8122 8.19203 12.4173 7.80592C12.0224 7.41981 12.0153 6.78668 12.4014 6.39179L16.4014 2.30088C16.5895 2.10847 16.8473 2 17.1164 2C17.3855 2 17.6433 2.10847 17.8314 2.30088L21.8314 6.39179Z"
      fill={color}
    />
  </svg>
);

export default Icon;
