import React, { FC, useContext } from 'react';
import { IMenuItem, IMilestoneTotal } from '@interfaces';
import { CommonRowType, MilestoneListColumnType } from '../MilestoneList/columns';
import { SettingsContext } from '@context';
import { DotsVerticalMenu, ExportMilestoneListToCSV } from '@components';
import { DocumentsIconWithIndicator } from '@svgAsComponents';
import { getTooltipText } from '@utils';

const MilestoneListOptionsMenu: FC<{
  menuItems: IMenuItem[];
  columns: MilestoneListColumnType[];
  sortedMilestones: CommonRowType[];
  totals: IMilestoneTotal;
  exportToCSV: boolean;
}> = ({ menuItems, columns, sortedMilestones, totals, exportToCSV }) => {
  const { isCurrentProjectArchived } = useContext(SettingsContext);

  const items = [
    ...(exportToCSV
      ? [
          {
            text: (
              <ExportMilestoneListToCSV
                columns={columns}
                milestones={sortedMilestones}
                totals={totals}
              />
            ),
            icon: <DocumentsIconWithIndicator size={16} />,
            disabled: isCurrentProjectArchived,
            disabledTooltipText: getTooltipText({ isCurrentProjectArchived }),
          },
        ]
      : []),
    ...menuItems,
  ];

  if (!items.length) return null;

  return (
    <DotsVerticalMenu
      menuItems={items}
      anchorOriginHorizontal="right"
      transformOriginHorizontal="right"
      dataTestName="milestone_list__options__menu"
    />
  );
};

export default MilestoneListOptionsMenu;
