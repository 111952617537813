import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS } from 'date-fns/locale/en-US';

import { ICompanyFull, QueryNamesEnums } from '@interfaces';
import { useLaunchDarklyFlags } from '@context';
import { getMyCompany } from '@globalService';

interface DateFormatterOptions {
  date: string | Date | null;
  withTime?: boolean;
}

export const useDateFormatter = () => {
  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );
  const flags = useLaunchDarklyFlags();

  const timezone = companyQuery?.data?.timezone || 'US/Pacific';

  const dateFormatter = ({ date, withTime = false }: DateFormatterOptions) => {
    if (!date) return null;

    // date regex for 'yyyy-mm-dd'
    const singleDateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])$/;

    if (flags?.['ENG_7651_customer_setting_for_local_time_zone']) {
      // New approach with timezones
      if (singleDateRegex.exec(date.toString())) {
        const dateArray = date.toString().split('-');
        const toJSMonth = (value: string) => +(value.startsWith('0') ? value.charAt(1) : value) - 1;
        const formattedDate = new Date(+dateArray[0], toJSMonth(dateArray[1]), +dateArray[2]);
        return format(formattedDate, 'MMM dd, yyyy');
      }

      if (withTime)
        return formatInTimeZone(new Date(date), timezone, 'MMM dd, yyyy HH:mm zzz', {
          locale: enUS,
        });

      return formatInTimeZone(new Date(date), timezone, 'MMM dd, yyyy');
    } else {
      // Old approach without timezones
      if (date.toString().match(singleDateRegex)) {
        const dateArray = date.toString().split('-');
        const toJSMonth = (value: string) =>
          +(value.charAt(0) === '0' ? value.charAt(1) : value) - 1;
        const formattedDate = new Date(+dateArray[0], toJSMonth(dateArray[1]), +dateArray[2]);

        return format(formattedDate, 'MMM dd, yyyy');
      }

      if (withTime) return format(new Date(date), 'MMM dd, yyyy h:mm aa zzz');

      return format(new Date(date), 'MMM dd, yyyy');
    }
  };

  return { dateFormatter };
};
