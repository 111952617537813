import React from 'react';
import { Grid2 } from '@mui/material';

import { EnumTypeForList } from '@interfaces';
import {
  CustomAutocomplete,
  CustomDatePickerInput,
  CustomTextField,
  InternationalPhoneField,
  ToolTipLine,
} from '@components';
import { DateFieldModel, DropdownFieldModel, PhoneFieldModel, StringFieldModel } from '@models';

interface ComponentProps {
  additionalContactName: StringFieldModel;
  primaryContactPhone: PhoneFieldModel;
  additionalContactPhone: PhoneFieldModel;
  accessCode: StringFieldModel;
  primaryContactUser?: DropdownFieldModel;
  primaryContactUserNotRequired?: DropdownFieldModel;
  primaryContactUserList: EnumTypeForList[];
  isBorrowerUsersLoading: boolean;
  inspectionRequestedAt?: DateFieldModel;
  inputWidth?: number;
  source: string;
  disabled?: boolean;
  showRequestedDate?: boolean;
  showContactsTooltip?: boolean;
  showAccessCode?: boolean;
  showAdditionalContact?: boolean;
}

const InspectionInputsBlock = ({
  inspectionRequestedAt,
  additionalContactName,
  additionalContactPhone,
  accessCode,
  primaryContactUserNotRequired,
  primaryContactPhone,
  primaryContactUserList,
  isBorrowerUsersLoading,
  inputWidth = 3,
  source,
  disabled = false,
  showRequestedDate = false,
  showContactsTooltip = true,
  showAccessCode = true,
  showAdditionalContact = true,
}: ComponentProps) => {
  return (
    <Grid2 container rowSpacing={4} columnSpacing={6}>
      {showContactsTooltip && (
        <Grid2 size={{ xs: 12 }} sx={{ mb: -2 }}>
          <ToolTipLine
            typographyVariant="body2"
            text="At least one contact with phone number (primary or additional) is required."
            sx={{ justifyContent: 'flex-start' }}
          />
        </Grid2>
      )}
      {showRequestedDate && (
        <Grid2 size={{ xs: 12, sm: inputWidth }}>
          <CustomDatePickerInput
            label="No earlier than this date"
            minDate={new Date()}
            field={inspectionRequestedAt}
            onChange={(e) => inspectionRequestedAt.handleChangePicker(e)}
            inputProps={{
              'data-cy': `${source}__inspection_availability__date_picker`,
            }}
            disabled={disabled}
          />
        </Grid2>
      )}
      {showAccessCode && (
        <Grid2 size={{ xs: 12, sm: inputWidth }}>
          <CustomTextField
            label="Entry access code (i.e. lockbox)"
            field={accessCode}
            inputProps={{
              'data-cy': `${source}__inspections__access_code__input`,
            }}
            disabled={disabled}
          />
        </Grid2>
      )}

      <Grid2 size={{ xs: 12, sm: inputWidth }}>
        <CustomAutocomplete
          field={primaryContactUserNotRequired}
          label="Primary contact name"
          options={primaryContactUserList}
          optionsLoading={isBorrowerUsersLoading}
          inputProps={{
            'data-cy': `${source}__inspections__primary_contact_name__input`,
          }}
          disabled={disabled}
          clearIcon={null}
          disabledTooltipText="No phone number available. Please, select another user."
        />
      </Grid2>

      <Grid2 size={{ xs: 12, sm: inputWidth }}>
        <InternationalPhoneField
          disabled
          field={primaryContactPhone}
          label="Primary contact number"
        />
      </Grid2>

      {showAdditionalContact && (
        <Grid2 size={{ xs: 12, sm: inputWidth }}>
          <CustomTextField
            field={additionalContactName}
            label="Additional contact name"
            inputProps={{
              'data-cy': `${source}__inspections__additional_contact_name__input`,
            }}
            disabled={disabled}
            required={!primaryContactUserNotRequired?.value}
          />
        </Grid2>
      )}
      {showAdditionalContact && (
        <Grid2 size={{ xs: 12, sm: inputWidth }}>
          <InternationalPhoneField
            label="Additional contact number"
            field={additionalContactPhone}
            inputProps={{
              'data-cy': `${source}__inspections__additional_contact_phone__input`,
            }}
            disabled={disabled}
            required={!primaryContactUserNotRequired?.value}
          />
        </Grid2>
      )}
    </Grid2>
  );
};

export default InspectionInputsBlock;
