import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon / check-small">
      <path
        id="Icon"
        d="M17.1241 10.2568C17.4226 9.95146 17.4006 9.47703 17.0749 9.19714C16.7492 8.91724 16.2432 8.93787 15.9446 9.24321L12.1631 13.1107L10.7489 11.5199C10.4661 11.2017 9.96164 11.1587 9.62222 11.4238C9.2828 11.689 9.23694 12.1619 9.51979 12.4801L11.5198 14.7301C11.6676 14.8964 11.8847 14.9947 12.1155 14.9998C12.3462 15.0049 12.5681 14.9163 12.7241 14.7568L17.1241 10.2568Z"
        fill={color}
      />
    </g>
  </svg>
);

export default Icon;
