import { PermissionNamesEnums, MessagePanelTabsEnum } from '@interfaces';

export const TABS = {
  ALL: {
    label: 'All',
    value: MessagePanelTabsEnum.ALL,
    permissionKey: PermissionNamesEnums.COMMENTS_ALL_VIEW,
    dataTestName: 'tabs__all',
  },
  INTERNAL: {
    label: 'Internal',
    value: MessagePanelTabsEnum.INTERNAL,
    permissionKey: PermissionNamesEnums.COMMENTS_ALL_VIEW,
    dataTestName: 'tabs__internal',
  },
  BORROWER: {
    label: 'Borrower',
    value: MessagePanelTabsEnum.BORROWER,
    permissionKey: null,
    dataTestName: 'tabs__borrower',
  },
  SERVICES: {
    label: 'Services',
    value: MessagePanelTabsEnum.SERVICES,
    permissionKey: PermissionNamesEnums.INSPECTIONS_EDIT,
    dataTestName: 'tabs__services',
  },
};

export const getFilterParams = ({
  activeTab,
  received_by_company_id,
  isInspectionMilestoneComments,
}: {
  activeTab: string;
  received_by_company_id: string;
  isInspectionMilestoneComments: boolean;
}) => {
  switch (activeTab) {
    case TABS.BORROWER.value:
      return { received_by_company_id };
    case TABS.SERVICES.value:
      return isInspectionMilestoneComments
        ? {}
        : {
            services_only: true,
          };
    case TABS.INTERNAL.value:
      return { received_by_company_id };
    default:
      return {};
  }
};

export const getReceivedByCompanyId = ({
  activeTab,
  borrowerCompanyId,
  userCompanyId,
}: {
  activeTab: string;
  borrowerCompanyId: string;
  userCompanyId: string;
}) => {
  switch (activeTab) {
    case TABS.BORROWER.value:
      return borrowerCompanyId;
    case TABS.INTERNAL.value:
      return userCompanyId;
    default:
      return null;
  }
};
