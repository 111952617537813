import React, { FC } from 'react';
import { Popup, WysiwygEditor } from '@components';
import {
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { IDrawRequest } from '@interfaces';
import { useSwitchApproverPopup } from './controller';
import { colors, typography } from '@theme';
import { SendIcon } from '@svgAsComponents';

const SwitchApproverPopup: FC<{
  open: boolean;
  onClose: () => void;
  request: IDrawRequest;
  title?: string;
  confirmButtonLabel?: string;
  projectId: string;
  icon?: React.ComponentType<{ size: number; color: string }>;
  source;
}> = ({
  open,
  onClose,
  request,
  title = 'Switch reviewer',
  confirmButtonLabel = 'Switch',
  projectId,
  icon = SendIcon,
  source,
}) => {
  const { teams, onSwitchApprover, handleChange, approverId, teamsIsLoading, notes, isMutating } =
    useSwitchApproverPopup(request?.project?.id || projectId, request, onClose);

  const noTeams = !teams.length;
  return (
    <Popup open={open} icon={icon} maxWidth="md" title={title}>
      {teamsIsLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Stack sx={{ width: '100%' }}>
          {noTeams ? (
            <Typography variant="body2" sx={{ color: colors.text.medium }}>
              No available teams to switch to.
            </Typography>
          ) : (
            <Stack spacing={2}>
              <Stack>
                <InputLabel
                  id="approver-select-label"
                  sx={{ ...typography.label, color: colors.text.medium }}
                >
                  Approving team
                </InputLabel>

                <Select
                  id="approver-select"
                  value={approverId}
                  onChange={handleChange}
                  size="small"
                  data-cy={`${source}__switch_reviewer_team_popup__team_name__input`}
                >
                  {teams.map((team) => (
                    <MenuItem value={team.id} key={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>

              <Stack>
                <InputLabel id="comment" sx={{ ...typography.label, color: colors.text.medium }}>
                  Comment
                </InputLabel>
                <WysiwygEditor
                  editField={notes}
                  source={`${source}__switch_reviewer_team_popup__comment`}
                />
              </Stack>
            </Stack>
          )}

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: '3.5rem' }}
          >
            <Button variant="text" onClick={onClose}>
              {noTeams ? 'Close' : 'Cancel'}
            </Button>
            {!noTeams && (
              <LoadingButton
                sx={{ ml: '0.5rem' }}
                onClick={onSwitchApprover}
                loading={isMutating}
                disabled={isMutating}
                data-cy={`${source}__switch_reviewer_team_popup__send__button`}
              >
                {confirmButtonLabel}
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      )}
    </Popup>
  );
};

export default SwitchApproverPopup;
