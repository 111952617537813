import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 25) * 24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.11639 9V8C8.11639 5.79086 9.90726 4 12.1164 4C14.3255 4 16.1164 5.79086 16.1164 8V9L8.11639 9ZM6.11639 9.34505V8C6.11639 4.68629 8.80269 2 12.1164 2C15.4301 2 18.1164 4.68629 18.1164 8V9.34505C18.718 9.7323 19.1164 10.4079 19.1164 11.1765V15C19.1164 18.866 15.9824 22 12.1164 22C8.2504 22 5.11639 18.866 5.11639 15V11.1765C5.11639 10.4079 5.51481 9.7323 6.11639 9.34505ZM7.29286 11C7.1954 11 7.11639 11.079 7.11639 11.1765L7.11639 15C7.11639 17.7614 9.35497 20 12.1164 20C14.8778 20 17.1164 17.7614 17.1164 15V11.1765C17.1164 11.079 17.0374 11 16.9399 11H16.1164H8.11639H7.29286ZM12.1164 13C13.221 13 14.1164 13.8954 14.1164 15C14.1164 16.1046 13.221 17 12.1164 17C11.0118 17 10.1164 16.1046 10.1164 15C10.1164 13.8954 11.0118 13 12.1164 13Z"
      fill={color}
    />
  </svg>
);

export default Icon;
