import {
  DataProviderResultType,
  FilteredData,
  FunctionArguments,
  FunctionReturnType,
} from '../types';
import { dataProviders } from '../provider';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import pick from 'lodash/pick';

export function useGetData<
  P extends keyof typeof dataProviders,
  K extends keyof DataProviderResultType<FunctionReturnType<(typeof dataProviders)[P]>>,
>({
  type,
  keys,
  args,
  options,
}: {
  type: P;
  keys?: K[];
  args: FunctionArguments<(typeof dataProviders)[P]>;
  options?: {
    skip?: boolean;
  };
}) {
  const queryFnName = useMemo(() => [type, args], [type, args]);
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<FunctionReturnType<(typeof dataProviders)[P]>>(queryFnName);

  const nonExistingFields = useMemo(() => {
    if (!data) {
      return keys;
    }
    return keys.filter((key) => !(key in data));
  }, [data, keys]);

  const [isLoading, setIsLoading] = useState<boolean>(!!nonExistingFields.length);

  const fetchMissingFields = useCallback(
    async (fields: K[], refetchKey?: string) => {
      setIsLoading(true);
      const argWithFields: FunctionArguments<(typeof dataProviders)[P]> = {
        ...args,
        restQlParams: `{${keys.join(',')}}`,
      };
      const fetchedData = await queryClient.fetchQuery(
        refetchKey ? [...queryFnName, fields, refetchKey] : [...queryFnName, fields],
        () =>
          dataProviders[type]?.(argWithFields as any) as Promise<
            FunctionReturnType<(typeof dataProviders)[P]>
          >,
      );

      queryClient.setQueryData(queryFnName, { ...(data || {}), ...fetchedData });
      setIsLoading(false);
    },
    [queryClient, type, args, data],
  );

  const filterData = useMemo(() => {
    if (!data) return undefined;

    return pick(data, keys) as FilteredData<
      DataProviderResultType<FunctionReturnType<(typeof dataProviders)[P]>>,
      K
    >;
  }, [data, keys]);

  useEffect(() => {
    if (nonExistingFields.length && !options?.skip) fetchMissingFields(nonExistingFields);
  }, [nonExistingFields, options]);

  const manualUpdate = useCallback(
    (
      update: Partial<
        FilteredData<DataProviderResultType<FunctionReturnType<(typeof dataProviders)[P]>>, K>
      >,
    ) => {
      queryClient.setQueryData(queryFnName, (oldData: Record<string, any>) => {
        return {
          ...(oldData || {}),
          ...update,
        };
      });
    },
    [queryFnName],
  );

  const refetch = () => fetchMissingFields(keys, Date.now().toLocaleString());
  return {
    data: filterData,
    isLoading,
    queryFnName,
    manualUpdate,
    refetch,
  };
}
