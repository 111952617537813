import React, { useCallback } from 'react';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material/styles';

import { colors, typography } from '@theme';
import { SnackbarCloseButton } from '@components';
import { SuccessIcon, ErrorIcon, InfoIcon, Warning2Icon } from '@svgAsComponents';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    ...typography.body2SemiBold,
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: colors.status.success.lighter,
    color: colors.status.success.medium,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: colors.status.error.lighter,
    color: colors.status.error.medium,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: colors.status.warning.lighter,
    color: colors.status.orange.medium,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: colors.status.information.lighter,
    color: colors.status.information.medium,
  },
  '& svg': {
    marginRight: 8,
  },
}));

export const NotistackProvider = ({ children }) => {
  const snackbarAction = useCallback((key) => <SnackbarCloseButton snackbarKey={key} />, []);

  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
      action={snackbarAction}
      maxSnack={5}
      iconVariant={{
        success: <SuccessIcon />,
        error: <ErrorIcon />,
        warning: <Warning2Icon color={colors.status.orange.medium} />,
        info: <InfoIcon />,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
