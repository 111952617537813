import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { LabelAndValue, LoadingSkeleton, StyledBox } from '@components';
import * as Controller from './controller';
import { colors } from '@theme';

const BudgetSummary = () => {
  const { projectId } = useParams();
  const controller = Controller.useBudgetSummary(projectId);

  if (controller.isLoading) {
    return <LoadingSkeleton type="twoBlocks" />;
  }

  return <BudgetSummaryBody controller={controller} />;
};

const BudgetSummaryBody: FC<{
  controller: Controller.ControllerInterface;
}> = ({ controller }) => {
  const { budget, loan } = controller;

  return (
    <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
      <StyledBox sx={{ flex: 1 }}>
        <Stack spacing={1}>
          <Typography variant="labelSemiBold" lineHeight={2}>
            Budget
          </Typography>
          <LabelAndValue
            label="Prefunding costs"
            text={budget.prefundingCost}
            textDataTestName="budget_summary_prefunding_costs"
            {...(budget.prefundingCost !== loan.prefundingCost && {
              color: colors.status.error.medium,
            })}
          />
          <LabelAndValue
            label="Revised scheduled value"
            text={budget.scheduleOfValues}
            textDataTestName="budget_summary_revised_scheduled_value"
            {...(budget.scheduleOfValues !== loan.scheduleOfValues && {
              color: colors.status.error.medium,
            })}
          />
        </Stack>
      </StyledBox>

      <StyledBox sx={{ flex: 1 }}>
        <Stack spacing={1}>
          <Typography variant="labelSemiBold" lineHeight={2}>
            Loan
          </Typography>
          <LabelAndValue
            label="Total loan commitment"
            text={loan.totalLoanCommitment}
            textDataTestName="budget_summary_loan_total_loan_commitment"
          />
          <LabelAndValue
            label="Schedule of values"
            text={loan.scheduleOfValues}
            textDataTestName="budget_summary_loan_schedule_of_values"
            {...(budget.scheduleOfValues !== loan.scheduleOfValues && {
              color: colors.status.error.medium,
            })}
          />
        </Stack>
      </StyledBox>

      <StyledBox sx={{ flex: 1 }}>
        <Stack spacing={1}>
          <LabelAndValue
            label="Prefunding costs"
            text={loan.prefundingCost}
            textDataTestName="budget_summary_loan_prefunding_costs"
            {...(budget.prefundingCost !== loan.prefundingCost && {
              color: colors.status.error.medium,
            })}
          />
          <LabelAndValue
            label="Construction holdback"
            text={loan.constructionHoldback}
            textDataTestName="budget_summary_loan_construction_holdback"
          />
          <LabelAndValue
            label="Borrower equity"
            text={loan.borrowerEquity}
            textDataTestName="budget_summary_loan_borrower_equity"
          />
        </Stack>
      </StyledBox>
    </Stack>
  );
};

export default BudgetSummary;
