import React, { useContext } from 'react';
import { useIsMutating } from 'react-query';
import { Stack, Typography } from '@mui/material';

import {
  ButtonWithTooltip,
  CustomRadioGroup,
  Fees,
  StyledBox,
  TabWrapperWithLeaveWarning,
  WysiwygEditor,
} from '@components';
import * as Controller from './controller';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { PaymentConfiguration } from '@interfaces';
import { getTooltipText } from '@utils';

const Payments = ({ project }) => {
  const flags = useLaunchDarklyFlags();
  const isMutating = useIsMutating();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const controller = Controller.usePayments({ project });
  const {
    configurationType,
    configurationTypeOptions,
    setConfigurationType,
    isEditable,
    comment,
    exitPath,
    isSubmitting,
    handleSubmitClick,
    feesController,
    isUpdated,
    canEditFees,
  } = controller;

  const { addFee, fees, deleteFee, updateFee } = feesController;

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      tabValue="payments"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/payments'}
      isUpdated={isUpdated}
    >
      <Stack spacing={2}>
        {!flags?.['ENG_8716_introduce_funding_sources'] && (
          <Stack>
            <StyledBox>
              <Typography variant="h3">Payment model</Typography>
              <Stack spacing={2} mt={3}>
                <CustomRadioGroup
                  dataTestName="project__settings__payments__configuration_type__radio_group"
                  value={configurationType}
                  onChange={(e) => setConfigurationType(e.target.value as PaymentConfiguration)}
                  options={configurationTypeOptions}
                  direction="column"
                  disabled={!isEditable}
                  disabledTooltipText="You do not have permission to perform this action."
                />
              </Stack>

              <Stack sx={{ maxWidth: '800px', mt: 2 }}>
                <WysiwygEditor
                  editField={comment}
                  source="project__settings__payments__comment"
                  readOnly={!isEditable}
                />
              </Stack>
            </StyledBox>
          </Stack>
        )}
        <Stack sx={{ pb: 3 }}>
          <StyledBox>
            <Stack direction="row" justifyContent="space-between" align-items="center">
              <Typography variant="h3">Fees</Typography>
              {canEditFees && (
                <ButtonWithTooltip
                  tooltipText={getTooltipText({ isCurrentProjectArchived })}
                  disabled={isCurrentProjectArchived || Boolean(isMutating)}
                  onClick={addFee}
                  size="small"
                  dataTestName="project__settings__fees__add__button"
                >
                  Add fee
                </ButtonWithTooltip>
              )}
            </Stack>
            <Stack
              spacing={2}
              sx={{
                width: { md: '50%', xs: '100%' },
                pt: 3,
              }}
            >
              <Fees
                fees={fees}
                deleteFee={deleteFee}
                updateFee={updateFee}
                canEdit={canEditFees}
                source="project__settings"
              />
            </Stack>
          </StyledBox>
        </Stack>
      </Stack>
    </TabWrapperWithLeaveWarning>
  );
};

export default Payments;
