import React from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { percentFormatter } from '@utils';

import { PercentsInput } from '@components';
import { GridEditInputCell } from '@mui/x-data-grid-premium';

const retainageRateBudget: MilestoneListColumnTypeV2 = {
  field: 'retainage_rate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Retainage (%)',
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  valueParser: (value) => +value,
  renderEditCell: (props) => <GridEditInputCell {...props} inputComponent={PercentsInput} />,
  minWidth: 120,
  editableByMilestone: (row) =>
    row.isEditableV2 && row.retainage_rate && row.is_retainage_release_approved_editable,
};

export default retainageRateBudget;
