import { percentFormatter } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const inspectorAllowanceRateIncremental: MilestoneListColumnTypeV2 = {
  field: 'inspector_allowance_rate_incremental',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Inspection recommendation (%)',
  description: 'Onsite completion percentage',
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  minWidth: 120,
  editable: false,
};

export default inspectorAllowanceRateIncremental;
