import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';
import { hasCommentsColor, hasUnreadCommentsColor } from '../CommentIconWithIndicator/interface';

const DocumentsIconWithIndicator = ({
  size = 24,
  color = colors.icons.green,
  hasDocuments = false,
  hasUnreadDocuments = false,
}: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 19L7 19C7 20.6569 8.34315 22 10 22H18C19.6569 22 21 20.6569 21 19V8C21 6.34314 19.6569 5 18 5C18 3.34315 16.6569 2 15 2H6C4.34315 2 3 3.34315 3 5L3 16C3 17.6569 4.34315 19 6 19ZM8 17L8.00154 17L15 17C15.5523 17 16 16.5523 16 16L16 6V5C16 4.44771 15.5523 4 15 4L6 4C5.44772 4 5 4.44771 5 5L5 16C5 16.5523 5.44772 17 6 17H7.99846L8 17ZM19 8C19 7.44771 18.5523 7 18 7L18 16C18 17.6569 16.6569 19 15 19L9 19C9 19.5523 9.44772 20 10 20H18C18.5523 20 19 19.5523 19 19V8ZM13 6C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8C7.44772 8 7 7.55228 7 7C7 6.44772 7.44772 6 8 6H13ZM14 10.5C14 9.94772 13.5523 9.5 13 9.5H8C7.44772 9.5 7 9.94772 7 10.5C7 11.0523 7.44772 11.5 8 11.5H13C13.5523 11.5 14 11.0523 14 10.5ZM13 13C13.5523 13 14 13.4477 14 14C14 14.5523 13.5523 15 13 15H8C7.44772 15 7 14.5523 7 14C7 13.4477 7.44772 13 8 13H13Z"
      fill={color}
    />
    {hasDocuments && (
      <circle
        cx="20"
        cy="4"
        r="4"
        fill={hasUnreadDocuments ? hasUnreadCommentsColor : hasCommentsColor}
      />
    )}
  </svg>
);

export default DocumentsIconWithIndicator;
