import React, { useContext } from 'react';
import { TextField } from '@mui/material';
import { EmailNotifications, StyledBox } from '@components';
import { AuthContext } from '@context';

const SettingsTab = () => {
  const { user } = useContext(AuthContext);

  return (
    <StyledBox sx={{ flex: 1 }}>
      {user.time_zone && (
        <TextField
          id="time-zone"
          label="Time zone"
          defaultValue={user.time_zone}
          InputProps={{ readOnly: true }}
          sx={{
            mb: 4,
            width: '50%',
            '& .MuiInputBase-root': { height: '40px' },
          }}
        />
      )}
      <EmailNotifications />
    </StyledBox>
  );
};

export default SettingsTab;
