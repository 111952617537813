import { useQuery } from 'react-query';
import { useMemo } from 'react';

import { getDrawRequestListStats } from '@globalService';
import {
  IDrawRequestsStats,
  QueryNamesEnums,
  RequestCombinedStatus,
  DrawRequestStatus,
} from '@interfaces';
import { useLaunchDarklyFlags } from '@context';

export interface StatusItem {
  label: string;
  value: number;
}

export interface ControllerInterface {
  stats: StatusItem[];
}

const sortStatuses = (statuses: IDrawRequestsStats['statuses']): StatusItem[] => {
  const priorityOrder = [
    DrawRequestStatus.DRAFT,
    DrawRequestStatus.IN_REVIEW,
    RequestCombinedStatus.ON_HOLD,
    DrawRequestStatus.APPROVED,
    DrawRequestStatus.COMPLETED,
  ];

  const statusArray = Object.keys(statuses).map((key) => ({
    label: key,
    value: statuses[key],
  }));

  return statusArray.sort(
    (a, b) =>
      priorityOrder.indexOf(a.label as DrawRequestStatus) -
      priorityOrder.indexOf(b.label as DrawRequestStatus),
  );
};

export const useRequestsSummaryBlock = (): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const { data: statsData } = useQuery<IDrawRequestsStats, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_LIST_STATS],
    getDrawRequestListStats.bind(this, ''),
  );

  const processedStatuses = useMemo(() => {
    if (!statsData?.statuses) {
      return [];
    }

    return sortStatuses(statsData.statuses);
  }, [flags, statsData]);

  return {
    stats: processedStatuses,
  };
};
