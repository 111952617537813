import React from 'react';
import { Typography } from '@mui/material';
import { ComponentProps } from './interface';

const ServiceMessage = ({ text, children }: ComponentProps) => {
  return (
    <Typography variant="body2SemiBold" sx={{ p: '1rem' }}>
      {text ? `Something went wrong while fetching ${text}` : children}
    </Typography>
  );
};

export default ServiceMessage;
