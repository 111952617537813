import React, { FC, useMemo, PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import {
  SwipeableDrawer,
  SwipeableDrawerProps,
  Stack,
  IconButton,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { CloseIcon } from '@svgAsComponents';
import { ComponentProps as RightDrawerProps } from './interface';
import { colors, sizes } from '@theme';

interface StyledSwipeableDrawerProps extends SwipeableDrawerProps {
  width?: string;
  right?: string;
}

const StyledSwipeableDrawer = styled(SwipeableDrawer)<StyledSwipeableDrawerProps>(
  ({ width, right }) => ({
    '& .MuiPaper-root': {
      top: sizes.headerHeight,
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
      width,
      height: 'calc(100% - 64px)',
      right,
    },
  }),
);

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
}));

const RightDrawer: FC<PropsWithChildren<RightDrawerProps>> = ({
  open,
  setOpen,
  children,
  title,
  variant,
  drawerWidth = '600px',
  right,
}) => {
  const smallMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const params = useMemo(
    () => ({
      width: smallMediaQuery ? '100%' : drawerWidth,
      ...(variant ? { variant } : {}),
      right: right ?? '0',
    }),
    [smallMediaQuery, variant],
  );
  const toggleDrawer = (open) => (event) => {
    event?.stopPropagation();
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  if (!open) return null;

  return (
    <StyledSwipeableDrawer
      open={open}
      onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}
      anchor="right"
      slotProps={{
        backdrop: {
          onClick: toggleDrawer(false),
          sx: { opacity: '0 !important' },
        },
      }}
      data-cy="right_drawer"
      sx={{ height: '100vh', overflowY: 'hidden' }}
      {...params}
    >
      <Stack sx={{ p: 3, flex: 1 }}>
        <Header>
          <Typography variant="h2" sx={{ color: colors.text.heading }}>
            {title}
          </Typography>
          <IconButton
            onClick={toggleDrawer(false)}
            size="large"
            sx={{ right: -12 }}
            data-cy="right_drawer__close__button"
          >
            <CloseIcon />
          </IconButton>
        </Header>
        {children}
      </Stack>
    </StyledSwipeableDrawer>
  );
};

export default RightDrawer;
