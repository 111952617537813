import { colors } from '../colors';
import { fonts } from '../fonts';
import { fontSizes } from '../sizes';

/* 10px = 0.625rem
  12px = 0.75rem
  14px = 0.875rem
  16px = 1rem (base)
  18px = 1.125rem
  20px = 1.25rem
  22px = 1.375rem
  24px = 1.5rem
  30px = 1.875rem
  32px = 2rem
  34px = 2.125rem
  40px = 2.5rem
  44px = 2.75rem */

export default function Typography() {
  return {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: fonts.primary,
          fontSize: fontSizes.xxxLarge,
          ['@media (max-width: 600px)']: {
            fontSize: fontSizes.xxLarge,
          },
          color: colors.text.heading,
          fontWeight: 700,
        },
        h2: {
          fontFamily: fonts.primary,
          fontSize: fontSizes.xxLarge,
          ['@media (max-width: 600px)']: {
            fontSize: fontSizes.xLarge,
          },
          color: colors.text.heading,
          fontWeight: 600,
        },
        h3: {
          fontFamily: fonts.primary,
          fontSize: fontSizes.xLarge,
          ['@media (max-width: 600px)']: {
            fontSize: fontSizes.large,
          },
          color: colors.text.heading,
          fontWeight: 600,
        },
        h4: {
          fontFamily: fonts.primary,
          fontSize: fontSizes.large,
          ['@media (max-width: 600px)']: {
            fontSize: fontSizes.medium,
          },
          color: colors.text.heading,
          fontWeight: 700,
        },
        body1: {
          fontFamily: fonts.primary,
          fontSize: fontSizes.large,
          ['@media (max-width: 600px)']: {
            fontSize: fontSizes.medium,
          },
          color: colors.text.dark,
          fontWeight: 400,
        },
        body2: {
          fontFamily: fonts.primary,
          fontSize: fontSizes.medium,
          ['@media (max-width: 600px)']: {
            fontSize: fontSizes.small,
          },
          color: colors.text.dark,
          fontWeight: 400,
        },
        root: {
          '& p': {
            margin: 0,
          },
          '& p a, & a': {
            fontFamily: fonts.primary,
            fontSize: fontSizes.medium,
            ['@media (maxWidth: 600px)']: {
              fontSize: fontSizes.small,
            },
            fontWeight: 600,
            color: colors.text.link,
            textDecoration: 'none',
          },
        },
      },
    },
  };
}
