import React, { FC } from 'react';
import { Stack } from '@mui/material';

import { ChecklistItemLocal as IChecklistItem, IProject } from '@interfaces';
import * as Controller from './controller';
import {
  ApprovalButtons,
  ApprovalModals,
  ApprovalTeams,
  ApproveSummary,
  SubmitProjectButtons,
} from './components';

interface ComponentProps {
  checklistItems?: IChecklistItem[];
  projectId: string;
  drawRequestId: string;
  hideActonButtons?: boolean;
  project: Partial<IProject>;
}

const DrawRequestOperationButtons: FC<ComponentProps> = ({
  checklistItems,
  projectId,
  drawRequestId,
  hideActonButtons,
  project,
}) => {
  const controller = Controller.useApprovalButtonsAndModals({
    checklistItems,
    projectId,
    drawRequestId,
  });

  return (
    <Stack spacing={2}>
      {Boolean(projectId && drawRequestId) && (
        <>
          <ApproveSummary projectId={projectId} drawRequestId={drawRequestId} />

          {!controller.isRequestForDisbursement && (
            <ApprovalTeams
              projectId={projectId}
              request={controller?.drawRequest}
              approveActionType={controller?.approveActionType}
            />
          )}
        </>
      )}

      {!hideActonButtons && Boolean(drawRequestId) && (
        <>
          <ApprovalButtons controller={controller} projectId={projectId} />
          <ApprovalModals
            controller={controller}
            checklistItems={checklistItems}
            projectId={projectId}
            drawRequestId={drawRequestId}
          />
        </>
      )}
      {!hideActonButtons && !drawRequestId && (
        <Stack direction="row" justifyContent="flex-end">
          <SubmitProjectButtons project={project} />
        </Stack>
      )}
    </Stack>
  );
};

export default DrawRequestOperationButtons;
