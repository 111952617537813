import React, { FC } from 'react';
import { ComponentProps } from './interface';
import { Stack, Typography } from '@mui/material';

import { ArrowRightIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { getValueColor } from '@utils';

const ProgressWithArrow: FC<ComponentProps> = ({
  prevValue,
  nextValue,
  showProgress = true,
  getTextStyleForActive = false,
  prevValueType,
  nextValueType,
  prevValueDataTestName,
  nextValueDataTestName,
}) => {
  if (prevValue === '-' && nextValue === '-')
    return (
      <Typography variant="body3SemiBold" component="span">
        -
      </Typography>
    );

  if (!showProgress)
    return (
      <Typography
        variant="body3SemiBold"
        component="span"
        sx={{
          ...(prevValueType && { color: getValueColor(prevValueType) }),
        }}
        data-cy={prevValueDataTestName}
      >
        {prevValue}
      </Typography>
    );

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography
        variant="body3SemiBold"
        component="span"
        sx={{
          ...(prevValueType && { color: getValueColor(prevValueType) }),
        }}
        data-cy={prevValueDataTestName}
      >
        {prevValue}
      </Typography>

      <ArrowRightIcon size={13} color={colors.text.dark} />

      <Typography
        variant="body3SemiBold"
        component="span"
        sx={{
          ...(getTextStyleForActive && { color: colors.status.information.medium }),
          ...(nextValueType && { color: getValueColor(nextValueType) }),
        }}
        data-cy={nextValueDataTestName}
      >
        {nextValue}
      </Typography>
    </Stack>
  );
};

export default ProgressWithArrow;
