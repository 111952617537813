import React, { useState, useMemo } from 'react';
import { Button, Typography, CircularProgress, Stack, Box } from '@mui/material';
import { useFormik } from 'formik';
import { initialValues, emailValidationSchema, getErrorText } from '@utils';
import { InputField, LoginWireframe } from '@components';
import { BackToLoginButton, ThirdPartyLoginButtons } from '../components';
import * as Controller from './controller';
import { ErrorDual } from '@interfaces';

const ForgotPasswordComponent = () => {
  const { handleForgotPassword } = Controller.useForgotPassword();
  const [emailSent, setEmailSent] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: emailValidationSchema,
    onSubmit: async (values) => {
      try {
        await handleForgotPassword(values.email.trim());
        setEmailSent(true);
      } catch (error) {
        formik.setFieldError('email', getErrorText(error as ErrorDual));
      }
    },
  });

  const getTitle = useMemo(() => {
    if (emailSent) {
      return <Typography variant="h1">All set!</Typography>;
    }

    return <Typography variant="h1">Forgot your password?</Typography>;
  }, [emailSent]);

  const getFormContent = useMemo(() => {
    if (emailSent) {
      return (
        <Typography variant="body2" sx={{ mt: '16px' }}>
          If your email is associated with an account on file, you will receive an email with
          instructions on your next steps. See you soon!
        </Typography>
      );
    }

    return (
      <>
        <Typography variant="body2" sx={{ mt: '16px' }}>
          Enter the email address associated with your account.
        </Typography>
        <InputField name="email" label="Email" type="email" variant="outlined" size="small" />
      </>
    );
  }, [emailSent]);

  const getFormButtons = useMemo(() => {
    if (emailSent) {
      return (
        <>
          <BackToLoginButton variant="contained" />
          <Box mt={2} />
          <ThirdPartyLoginButtons />
        </>
      );
    }

    return (
      <Stack spacing={2}>
        <Button type="submit" fullWidth disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            'Request reset link'
          )}
        </Button>
        <BackToLoginButton />
        <Box mt={2} />
        <ThirdPartyLoginButtons />
      </Stack>
    );
  }, [emailSent, formik]);

  return (
    <LoginWireframe
      formik={formik}
      getTitle={getTitle}
      getFormContent={getFormContent}
      getFormButtons={getFormButtons}
    />
  );
};

export default ForgotPasswordComponent;
