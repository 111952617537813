import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const CompanyIcon = ({ size = 16, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33203 6L6.66536 6H9.33203H10.6654L11.9987 6H13.332V7.33333V9.33333L8.66536 9.33333V8C8.66536 7.63181 8.36689 7.33333 7.9987 7.33333C7.63051 7.33333 7.33203 7.63181 7.33203 8V9.33333L2.66536 9.33333L2.66536 7.33333L2.66536 6H3.9987L5.33203 6ZM2.66536 11.3333V10.6667L13.332 10.6667V11.3333V12.6667H11.9987L3.9987 12.6667H2.66537L2.66536 11.3333ZM5.33203 4.66667V4C5.33203 2.89543 6.22746 2 7.33203 2L8.66536 2C9.76993 2 10.6654 2.89543 10.6654 4V4.66667L13.332 4.66667C14.0684 4.66667 14.6654 5.26362 14.6654 6V12.6667C14.6654 13.403 14.0684 14 13.332 14L2.66537 14C1.92899 14 1.33203 13.403 1.33203 12.6667L1.33203 6C1.33203 5.26362 1.92898 4.66667 2.66536 4.66667H5.33203ZM9.33203 4.66667V4C9.33203 3.63196 9.03381 3.33358 8.66583 3.33333H8.66536H7.33203L7.33157 3.33333C6.96359 3.33358 6.66536 3.63197 6.66536 4V4.66667H7.33203L7.9987 4.66667H8.66536H9.33203Z"
      fill={color}
    />
  </svg>
);

export default CompanyIcon;
