import React, { ReactElement, useCallback } from 'react';
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';

import { CheckboxIconChecked, CheckboxIconDefault } from '@svgAsComponents';

export type ColumnChecktProps = {
  id: string;
  label: string | ReactElement;
  checked: boolean;
  onClick?: (...args: any) => void;
  disabled?: boolean;
  index: number;
  source?: string;
  description?: string;
  tooltipText?: string;
};

function ColumnCheck({
  id,
  label,
  checked,
  onClick,
  disabled,
  source,
  description,
  tooltipText,
}: ColumnChecktProps) {
  const handleClick = useCallback(() => {
    if (disabled) return;
    onClick({ id, label, checked: !checked });
  }, [id, label, checked, disabled]);
  const withDescriptions = !!description;

  return (
    <Box
      onClick={handleClick}
      sx={{ cursor: 'pointer', '&:not(:last-child)': { mb: '.3rem' } }}
      data-cy={`${source}__checklist__item__checkbox`}
    >
      <>
        <Tooltip title={tooltipText || ''}>
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row nowrap',
              alignItems: withDescriptions ? 'flex-start' : 'center',
              opacity: disabled ? 0.5 : 1,
            }}
          >
            <Box
              sx={withDescriptions ? {} : { height: 24 }}
              data-cy={`${source}__checklist__item__checked_${checked}__icon`}
            >
              {checked ? <CheckboxIconChecked /> : <CheckboxIconDefault />}
            </Box>
            <Stack maxWidth={230}>
              <Typography
                variant={withDescriptions ? 'body2SemiBold' : 'body3'}
                sx={withDescriptions ? {} : { ml: 0.5 }}
                data-cy={`${source}__checklist__item__label`}
              >
                {label}
              </Typography>
              {withDescriptions && (
                <Typography variant="label" data-cy={`${source}__checklist__item__label`}>
                  {description}
                </Typography>
              )}
            </Stack>
          </Box>
        </Tooltip>
        {withDescriptions && <Divider sx={{ mt: 1, mb: 1 }} />}
      </>
    </Box>
  );
}

export default ColumnCheck;
