import React, { FC, useContext, useEffect } from 'react';
import { MilestoneListColumnType } from '../common';
import { isRestricted } from '@utils';
import {
  ColumnV2Width,
  ExpandedIconPositionEnum,
  PermissionNamesEnums,
  ProductionBuildCommonRowType,
} from '@interfaces';
import { TableContext } from '../../controller';
import { useExpandedState } from '@hooks';

const Expand: FC<{ row: ProductionBuildCommonRowType }> = ({ row }) => {
  const { onExpandTable, source } = useContext(TableContext);
  const { expanded, getExpandButton, setExpanded } = useExpandedState({
    initialState: row.isExpanded,
    expandedIconPosition: ExpandedIconPositionEnum.RIGHT,
    dataCy: `${source}__body__expand__icon__index_${row.index}`,
  });

  useEffect(() => {
    if (expanded === row.isExpanded) return;
    onExpandTable(row.id, expanded);
  }, [expanded]);

  useEffect(() => {
    if (expanded === row.isExpanded) return;
    setExpanded(row.isExpanded);
  }, [row.canBeExpanded, row.isExpanded]);

  if (!row.canBeExpanded) return null;

  return <>{getExpandButton()}</>;
};

const expand: MilestoneListColumnType = {
  name: 'canBeExpanded',
  columnText: '',
  noBorder: true,
  isEditable: (permissions) =>
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  renderCell: ({ row }) => <Expand row={row} />,
  justifyContent: 'flex-end',
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default expand;
