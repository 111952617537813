import { Box, Stack, Typography } from '@mui/material';
import {
  IButtonController,
  IChecklistController,
  IFieldsController,
  IValidationController,
} from '../../main/interfaces';
import { InspectionInputsBlock, WysiwygEditor } from '@components';
import React, { FC } from 'react';
import { getDefaultChecklistItem } from '@utils';
import { useDateFormatter } from '@hooks';
import { PolicyItemTypesEnum } from '@interfaces';
import SectionWrapper from '../sectionWrapper';
import { SubmissionSectionKeyMap } from '@constants';

const Inspection: FC<{
  fieldsController: IFieldsController;
  validationController: IValidationController;
  checklistController: IChecklistController;
  buttonsController: IButtonController;
}> = ({ fieldsController, validationController, checklistController, buttonsController }) => {
  const { inspectionComment, inspectionFields } = fieldsController;

  const { checklistItems, handleSkip } = checklistController;
  const { inspectionDone } = buttonsController;
  const { onSectionButtonsClick, validatedSection } = validationController;
  const checklistItem = getDefaultChecklistItem(
    checklistItems,
    PolicyItemTypesEnum.INSPECTION_ORDERING,
  );
  const sectionKey = SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING];
  const isSectionSkipped = validatedSection?.[sectionKey]?.isSkipped;
  const { dateFormatter } = useDateFormatter();

  return (
    <SectionWrapper
      title={checklistItem?.label || 'Provide information for inspection availability'}
      subtitle="This draw may require an inspection. At least one contact with phone number (primary or additional) is required."
      controller={validationController}
      sectionKey={sectionKey}
      collapsedSectionText={{
        value:
          inspectionFields.inspectionRequestedAt.value &&
          inspectionFields.inspectionRequestedAt.validate()
            ? dateFormatter({ date: inspectionFields.inspectionRequestedAt.value })
            : 'No data',
      }}
      handleSkip={() => {
        if (isSectionSkipped) onSectionButtonsClick({ sectionKey, key: 'isSkipped' });
        if (checklistItem) handleSkip(checklistItem.id, checklistItem.label, !isSectionSkipped);
      }}
      hideSkipButton={!checklistItem}
      handleDone={inspectionDone}
      innerRef={checklistItem?.itemRef}
      itemType={PolicyItemTypesEnum.INSPECTION_ORDERING}
    >
      <Box mx={8} mt={3}>
        <Stack sx={{ width: { xs: '100%', md: '50%' } }} spacing={3}>
          {!validationController.isAdditionalRequirementResubmit && (
            <Box ml={-2}>
              <InspectionInputsBlock
                source="request__submission"
                {...inspectionFields}
                showRequestedDate
                inputWidth={6}
                showContactsTooltip={false}
              />
            </Box>
          )}

          <Stack>
            <Typography variant="label">
              Comment (Feel free to provide additional info, i.e. inspect ADU, etc.)
            </Typography>

            <WysiwygEditor editField={inspectionComment} source="request__submission__comment" />
          </Stack>
        </Stack>
      </Box>
    </SectionWrapper>
  );
};

export default Inspection;
