import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const PanoramicPhotoIcon = ({ size = 24, color = colors.neutral.white }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={size} height={size} rx="2" fill={colors.status.information.medium} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09805 17.2544C6.09852 17.3993 6.24646 17.5697 6.30563 17.6018C6.36481 17.634 6.41475 17.6498 6.47998 17.6498H6.49458C6.49784 17.6498 6.50087 17.6489 6.50413 17.6486C6.51182 17.6484 6.52696 17.6463 6.52696 17.6463C6.55189 17.6442 6.57658 17.6402 6.60012 17.6335C6.60874 17.6309 6.61689 17.6286 6.62504 17.6256C6.65277 17.616 6.67863 17.6039 6.70332 17.5888C6.70565 17.5874 6.70822 17.5869 6.71055 17.5853C8.37283 16.5026 10.3058 15.9302 12.2996 15.9302C14.2953 15.9302 16.2302 16.5036 17.8948 17.5878C17.9602 17.6305 18.0355 17.6519 18.111 17.6519C18.1755 17.6519 18.2407 17.636 18.2999 17.6041C18.4274 17.5347 18.507 17.4012 18.507 17.2558V7.06292C18.507 6.91754 18.4274 6.78405 18.2999 6.71462C18.1715 6.64543 18.0164 6.65172 17.895 6.73093C16.2318 7.81427 14.2983 8.38692 12.3041 8.38692C10.3084 8.38692 8.37446 7.8138 6.71078 6.7293C6.69866 6.72138 6.70612 6.72743 6.70402 6.72604C6.70216 6.72464 6.7003 6.72277 6.69866 6.72138L6.68116 6.71276L6.65044 6.70041C6.43116 6.66666 6.41327 6.65428 6.30587 6.71276C6.17796 6.78195 6.09829 6.91568 6.09829 7.06106V17.2533L6.09805 17.2544ZM6.89017 7.76464C7.62241 8.17678 8.39729 8.49665 9.19803 8.73056V15.5854C8.39706 15.8193 7.62218 16.1387 6.89017 16.5506V7.76464ZM17.7144 16.5529C16.9822 16.1408 16.2069 15.8209 15.4061 15.5868V8.73149C16.2073 8.49758 16.9824 8.17794 17.7144 7.76581V16.5529ZM14.614 8.93232V15.3862C13.8582 15.2252 13.0838 15.1386 12.2996 15.1386C11.5171 15.1386 10.7443 15.2248 9.99038 15.3855V8.93162C10.7457 9.0926 11.5196 9.17927 12.3038 9.17927C13.0864 9.17927 13.8596 9.09284 14.614 8.93232Z"
      fill={color}
    />
  </svg>
);

export default PanoramicPhotoIcon;
