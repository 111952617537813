import React, { FC, useState } from 'react';
import { CompanyModal } from '@components';
import { Box, Typography } from '@mui/material';
import { colors } from '@theme';

const CompanyLink: FC<{ name: string; companyId: string; source: string }> = ({
  name,
  companyId,
  source,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        component="span"
        onClick={() => setOpen(true)}
        sx={{ cursor: 'pointer' }}
        data-cy={`${source}__link_to_company_details`}
      >
        <Typography variant="body1SemiBold" color={colors.text.link}>
          {name}
        </Typography>
      </Box>
      {open && (
        <CompanyModal
          open={open}
          handleClose={() => setOpen(false)}
          companyId={companyId}
          source={source}
        />
      )}
    </>
  );
};

export default CompanyLink;
