import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { MilestoneTag, MilestoneTagsTypesEnums, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import { getProjectMilestoneTags } from '@globalService';
import React, { Dispatch, useContext, useMemo } from 'react';
import { getTransformedMilestoneTags } from '@utils';
import { useUpdateUiSettings } from '@hooks';
import { SettingsContext } from '@context';

export type ControllerInterface = {
  budgetViewOptions: { label: string; mainLevel: string; nestedLevel: string }[];
  budgetView: string;
  setBudgetView: Dispatch<React.SetStateAction<string>>;
  updateBudgetView: () => void;
};

export const useChooseNamingModal = ({ isCreatedByModels, onClose }): ControllerInterface => {
  const { projectId } = useParams();

  const projectMilestoneTagsQuery = useQuery<{ results: MilestoneTag[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }],
    getProjectMilestoneTags.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const transformedMilestoneTags = useMemo(
    () => getTransformedMilestoneTags(projectMilestoneTagsQuery.data?.results),
    [projectMilestoneTagsQuery.data?.results],
  );

  const budgetViewOptions = useMemo(() => {
    if (!transformedMilestoneTags) return [];
    const lineItemName = transformedMilestoneTags[MilestoneTagsTypesEnums.LINE_ITEM]?.name;
    const unitName = transformedMilestoneTags[MilestoneTagsTypesEnums.UNIT]?.name;
    const modelName = transformedMilestoneTags[MilestoneTagsTypesEnums.MODEL]?.name;

    return [
      ...(isCreatedByModels
        ? [
            {
              label: `${lineItemName} -> ${modelName}`,
              mainLevel: `${lineItemName}`,
              nestedLevel: `${modelName}`,
            },
          ]
        : []),
      {
        label: `${lineItemName} -> ${unitName}`,
        mainLevel: `${lineItemName}`,
        nestedLevel: `${unitName}`,
      },
      ...(isCreatedByModels
        ? [
            {
              label: `${modelName} -> ${lineItemName}`,
              mainLevel: `${modelName}`,
              nestedLevel: `${lineItemName}`,
            },
            {
              label: `${modelName} -> ${unitName}`,
              mainLevel: `${modelName}`,
              nestedLevel: `${unitName}`,
            },
          ]
        : []),
      {
        label: `${unitName} -> ${lineItemName}`,
        mainLevel: `${unitName}`,
        nestedLevel: `${lineItemName}`,
      },
    ];
  }, [isCreatedByModels, transformedMilestoneTags]);

  const [budgetView, setBudgetView] = React.useState(budgetViewOptions[0].label);
  const { updateSettings } = useUpdateUiSettings();
  const { settings } = useContext(SettingsContext);

  const updateBudgetView = () => {
    updateSettings({
      personal_setting: {
        tables: {
          ...settings.personal_setting?.tables,
          [TableKeyEnum.PHB_LINE_ITEMS]: {
            ...settings.personal_setting?.tables?.[TableKeyEnum.PHB_LINE_ITEMS],
            groups: {
              line_items: budgetView,
            },
          },
        },
      },
    });
    onClose();
  };
  return { budgetViewOptions, budgetView, setBudgetView, updateBudgetView };
};
