import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useSafeSnackbar } from '@hooks';
import { isMobileOnly } from 'react-device-detect';
import { Stack, Button, Box } from '@mui/material';

import { GoogleIcon } from '@svgAsComponents';
import { colors, typography } from '@theme';

const GoogleLoginButton = ({ handleLogin, googleClientId }) => {
  const { enqueueSnackbar } = useSafeSnackbar();

  if (window['Cypress']) return null;

  return (
    <Button
      variant="outlined"
      sx={{
        backgroundColor: 'white',
        color: colors.neutral.darker,
        textTransform: 'none',
        justifyContent: 'flex-start',
        paddingLeft: '16px',
        '&:hover': {
          backgroundColor: colors.neutral.lightest,
          border: `0.5px solid ${colors.neutral.light}`,
        },
        minWidth: '100%',
        maxWidth: '100%',
        padding: 0,
        '& .MuiButton-startIcon': {
          margin: 0,
        },
        boxSizing: 'border-box',
        border: `0.5px solid ${colors.neutral.light}`,
      }}
      startIcon={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            width: '40px',
            height: '40px',
          }}
        >
          <GoogleIcon size={20} />
        </Box>
      }
    >
      <Box sx={{ flexGrow: 1, textAlign: 'center', ...typography.body2SemiBold }}>Google</Box>
      <Stack
        sx={{
          opacity: 0,

          maxWidth: '100%',
          position: 'absolute',
          top: 0,
          left: 0,

          '& [role="button"]': {
            width: 'unset!important',
          },
          '& .MuiButton-root': {
            border: `0.5px solid ${colors.neutral.lighter}`,
          },
        }}
      >
        <GoogleOAuthProvider clientId={googleClientId}>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              handleLogin({ id_token: credentialResponse.credential });
            }}
            onError={() => {
              enqueueSnackbar('something went wrong while trying to log in', {
                variant: 'error',
              });
            }}
            useOneTap={!isMobileOnly}
            locale="en_US"
            width="400px"
          />
        </GoogleOAuthProvider>
      </Stack>
    </Button>
  );
};

export default GoogleLoginButton;
