import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import {
  ButtonWithTooltip,
  CustomMultiAutocompleteWithTags,
  InternationalPhoneField,
  CustomTextField,
  Popup,
  ToolTipLine,
  ButtonWithTooltipOnClick,
} from '@components';
import * as Controller from './controller';
import { AddUserIcon } from '@svgAsComponents';
import { ToolTipLineVariantEnum } from '@interfaces';

interface ComponentProps {
  borrowerCompanyId?: string;
  title?: string;
  isProjectStatusCreated?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const InviteUsersButton: FC<ComponentProps> = ({
  borrowerCompanyId,
  title = 'Invite user',
  isProjectStatusCreated,
  size = 'medium',
}) => {
  const {
    selectedTeams,
    setSelectedTeams,
    email,
    firstName,
    lastName,
    phone,
    handleAddCompanyUser,
    isSubmitting,
    teams,
    optionsLoading,
    isOpened,
    setOpened,
    onCloseCallback,
    inviteDisabled,
    isEditUsersEnabled,
    isSearching,
    handleSearchEmailClick,
    foundUser,
    tooltipText,
    showAdditionalInputs,
  } = Controller.useAddCompanyUser({ borrowerCompanyId });

  if (!isEditUsersEnabled) return null;

  return (
    <>
      <ButtonWithTooltip
        onClick={() => setOpened(true)}
        dataTestName={
          borrowerCompanyId
            ? 'borrower_company_users__invite_team_member_button'
            : 'company_users__invite_team_member_button'
        }
        disabled={inviteDisabled}
        tooltipText={
          inviteDisabled ? "You can't invite the user because of the absence of team." : ''
        }
        size={size}
      >
        {title}
      </ButtonWithTooltip>

      {isOpened && (
        <Popup open={isOpened} icon={AddUserIcon} maxWidth="sm" title={title}>
          <Stack sx={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
            <Stack spacing={2}>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ pt: 3 }}
                  spacing={1}
                >
                  <CustomTextField
                    field={email}
                    label="Email"
                    required
                    validationText="Please enter a valid email"
                    inputProps={{
                      'data-cy': 'company_users__invite_user_email_input',
                    }}
                    sx={{ flex: 1, pt: '2px' }}
                  />
                  <ButtonWithTooltip
                    onClick={handleSearchEmailClick}
                    disabled={!email.isValid}
                    tooltipText={!email.isValid ? 'Please enter a valid email' : ''}
                    sx={{ minWidth: '150px' }}
                    loading={isSearching}
                    dataTestName="company_users__search_email__button"
                  >
                    Find user
                  </ButtonWithTooltip>
                </Stack>
                <Typography variant="label" sx={{ pt: 0.5 }}>
                  Enter the email address.
                </Typography>
              </Stack>

              {tooltipText && (
                <Stack mb={3} alignItems="flex-start">
                  <ToolTipLine
                    typographyVariant="body3"
                    variant={ToolTipLineVariantEnum.INFORMATION}
                    text={tooltipText}
                  />
                </Stack>
              )}

              {showAdditionalInputs && (
                <>
                  <CustomMultiAutocompleteWithTags
                    label="Team"
                    options={teams}
                    field={selectedTeams}
                    setField={setSelectedTeams}
                    optionsLoading={optionsLoading}
                    inputProps={{
                      'data-cy': 'company_users__invite_user_team_input',
                    }}
                    required
                    clearIcon={null}
                  />
                  <CustomTextField
                    field={firstName}
                    label="First name"
                    inputProps={{
                      'data-cy': 'company_users__invite_user_first_name_input',
                    }}
                    disabled={Boolean(foundUser)}
                  />
                  <CustomTextField
                    field={lastName}
                    label="Last name"
                    inputProps={{
                      'data-cy': 'company_users__invite_user_last_name_input',
                    }}
                    disabled={Boolean(foundUser)}
                  />
                  <InternationalPhoneField
                    field={phone}
                    label="Phone"
                    inputProps={{
                      'data-cy': 'company_users__invite_user_phone_input',
                    }}
                    disabled={Boolean(foundUser)}
                  />
                </>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 4 }}
              spacing={2}
            >
              <Button
                variant="text"
                onClick={onCloseCallback}
                data-cy="company_users__invite_user_cancel_button"
              >
                Cancel
              </Button>
              <ButtonWithTooltipOnClick
                loading={isSubmitting}
                onClick={() => handleAddCompanyUser()}
                data-cy="company_users__invite_user_invite_button"
                disabled={!showAdditionalInputs || (!foundUser && email.value === '')}
                conditionallyDisabled={!selectedTeams?.length}
                tooltipText={!selectedTeams?.length && 'Please select a team.'}
              >
                {borrowerCompanyId && isProjectStatusCreated ? 'Add' : 'Invite'}
              </ButtonWithTooltipOnClick>
            </Stack>
          </Stack>
        </Popup>
      )}
    </>
  );
};

export default InviteUsersButton;
