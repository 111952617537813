import React, { Dispatch, FC, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { Popup } from '@components';
import { ILoanError, PopupTypeEnum } from '@interfaces';

interface LoanErrorsPopupProps {
  projectId: string;
  errors: ILoanError[];
  setErrors: Dispatch<SetStateAction<ILoanError[]>>;
}

const LoanErrorsPopup: FC<LoanErrorsPopupProps> = ({ projectId, errors, setErrors }) => {
  const navigate = useNavigate();

  if (!errors?.length) return null;

  return (
    <Popup open type={PopupTypeEnum.ERROR} title="Project activation error">
      <Stack sx={{ width: '100%' }}>
        <Stack spacing={2}>
          {errors.map((error, index) => (
            <Typography key={index} variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
              {error.loan_fields ? (
                <>
                  <br />
                  <b>{error.message}:</b> {error.loan_fields}
                </>
              ) : (
                error.message
              )}
              {Boolean(error.loan && error.budget) && (
                <>
                  <br />
                  <b>Loan Details:</b> {error.loan}
                  <br />
                  <b>Budget:</b> {error.budget}
                </>
              )}
            </Typography>
          ))}
        </Stack>
        <Stack sx={{ mt: 1 }}>
          <Divider sx={{ my: '16px' }} />
          <Typography variant="body2">
            Please, make changes in budget or loan, so all the needed values match.
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 7.5 }}>
          <Button
            variant="text"
            onClick={() => setErrors(null)}
            data-cy="project_activation_error__confirmation_dialog__cancel__button"
          >
            Cancel
          </Button>
          <Button
            variant="new"
            color="secondary"
            onClick={() => navigate(`/projects/${projectId}/settings/loan`)}
            data-cy="project_activation_error__confirmation_dialog__edit_loan__button"
          >
            Edit loan
          </Button>
          <Button
            onClick={() => {
              setErrors(null);
              navigate(`/projects/${projectId}/budget`);
            }}
            data-cy="project_activation_error__confirmation_dialog__edit_budget__button"
          >
            Edit budget
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default LoanErrorsPopup;
