import { sizes } from '../index';

export default function FormHelperText() {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: sizes.fontSizes.xSmall,
          ['@media (max-width: 600px)']: {
            fontSize: sizes.fontSizes.small,
          },
        },
      },
    },
  };
}
