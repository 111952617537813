import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getHookState } from '@utils';
import {
  DocumentContentTypeEnum,
  DocumentTypeEnum,
  IDocumentType,
  IProjectDocument,
  QueryNamesEnums,
  TransloaditTemplateEnum,
  IServiceOrderDocumentPayload,
  ServiceOrderDocumentTypes,
} from '@interfaces';
import {
  useConfirmationModal,
  useFilesUploader,
  useSafeSnackbar,
  useTablePagination,
} from '@hooks';
import {
  getProjectDocumentTypes,
  getServiceOrderDocuments,
  deleteServiceOrderDocument,
  getServiceDocumentTypes,
} from '@globalService';
import { useLaunchDarklyFlags } from '@context';
import { ControllerInterface, ServiceOrderDocumentsParams } from './interface';

export const useServiceOrderDocuments = ({
  projectId,
  drawRequestId,
  serviceOrderId,
  isServiceReports,
}: ServiceOrderDocumentsParams): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [selectedFileName, setSelectedFileName] = useState<string>('');

  const deleteModal = useConfirmationModal();

  const tablePagination = useTablePagination({
    initialRowsPerPage: 10,
    rowsPerPageOptions: [],
  });

  const stringQueryParams = useMemo(() => {
    let string = `&offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}`;

    if (isServiceReports) {
      string += `&document_type=${ServiceOrderDocumentTypes.RESULT}`;
    } else {
      string += `&excluded_document_type=${ServiceOrderDocumentTypes.RESULT}`;
    }
    return string;
  }, [flags, tablePagination.page, tablePagination.rowsPerPage, isServiceReports]);

  const serviceOrderDocumentsQuery = useQuery<
    { results: IProjectDocument[]; count: number },
    Error
  >(
    [QueryNamesEnums.GET_SERVICE_ORDER_DOCUMENTS, { projectId, serviceOrderId, stringQueryParams }],
    getServiceOrderDocuments.bind(this, { projectId, serviceOrderId, stringQueryParams }),
    {
      enabled: Boolean(projectId && serviceOrderId),
    },
  );

  const projectDocumentTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TYPES],
    getProjectDocumentTypes.bind(this),
  );

  const serviceOrderDocumentTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_SERVICE_ORDER_DOCUMENT_TYPES],
    getServiceDocumentTypes.bind(this),
  );

  const resultDocumentTypeId = useMemo(
    () =>
      serviceOrderDocumentTypesQuery.data?.find(
        (type) => type.name === ServiceOrderDocumentTypes.RESULT,
      )?.id,
    [serviceOrderDocumentTypesQuery.data],
  );

  const {
    isFilesUploaderOpened,
    filesUploaderClose,
    transloaditSignature,
    uploadMedia,
    restrictions,
  } = useFilesUploader();

  const handleOpenDocumentUploader = useCallback(
    ({ isProjectLevel }) => {
      const content_type = isProjectLevel
        ? DocumentContentTypeEnum.PROJECT
        : DocumentContentTypeEnum.SERVICE_ORDER;
      const fields = isServiceReports
        ? { content_type, object_id: serviceOrderId }
        : { content_type, object_id: isProjectLevel ? projectId : serviceOrderId };
      const templateType = TransloaditTemplateEnum.DOCUMENTS;

      uploadMedia({
        fields,
        templateType,
      });
    },
    [projectId, drawRequestId, uploadMedia, serviceOrderId, isServiceReports],
  );

  const refetchDocuments = useMemo(
    () => serviceOrderDocumentsQuery.refetch,
    [serviceOrderDocumentsQuery],
  );

  const documentTypes = useMemo(
    () =>
      projectDocumentTypesQuery.data?.filter((type) => type.name !== DocumentTypeEnum.APPRAISAL),
    [projectDocumentTypesQuery.data],
  );

  const deleteServiceOrderDocumentMutation = useMutation<
    Response,
    Error,
    IServiceOrderDocumentPayload
  >(deleteServiceOrderDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_SERVICE_ORDER_DOCUMENTS,
        { projectId, serviceOrderId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
        { projectId, serviceOrderId },
      ]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleDelete = ({ documentId }) =>
    deleteServiceOrderDocumentMutation.mutateAsync({
      projectId,
      serviceOrderId,
      documentId,
    });

  const documents = useMemo(
    () => serviceOrderDocumentsQuery.data?.results,
    [serviceOrderDocumentsQuery.data?.results],
  );

  const handleDeleteIconClick = ({ fileName = '', documentId = '' }) => {
    setSelectedFileName(fileName);
    deleteModal.askConfirm({ documentId });
  };

  const documentsCount = useMemo(
    () => serviceOrderDocumentsQuery.data?.count,
    [serviceOrderDocumentsQuery.data?.count],
  );

  return {
    state: getHookState(serviceOrderDocumentsQuery),
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    refetchDocuments,
    documentTypes,
    restrictions,
    documents,
    isLoading: serviceOrderDocumentsQuery.isFetching,
    handleDelete,
    deleteModal,
    handleDeleteIconClick,
    selectedFileName,
    resultDocumentTypeId,
    tablePagination,
    documentsCount,
  };
};
