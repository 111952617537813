import { useQueries } from 'react-query';
import { CSVPHBBudgetSectionsKeysEnum, QueryNamesEnums } from '@interfaces';
import { getProjectMilestonesList } from '@globalService';
import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { LineItemFilterValues } from '@constants';

type budgetSectionProps = { title: string; isChecked: boolean };
type budgetSection = {
  all: budgetSectionProps;
  isHorizontal: budgetSectionProps;
  isVertical: budgetSectionProps;
};

export type ControllerInterface = {
  budgetSectionsState: budgetSection;
  handleCheckboxChange: (key: string, value: boolean) => void;
};

export const useDeleteBudget = (): ControllerInterface => {
  const { projectId } = useParams();

  const query = '{}';
  const limit = '1';
  const requestedDataQueries = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_MILESTONES,
        { projectId, query, filterKey: LineItemFilterValues.HORIZONTAL_SOFT_COST.filterKey },
      ],
      queryFn: getProjectMilestonesList.bind(this, {
        projectId,
        query,
        filterKey: LineItemFilterValues.HORIZONTAL_SOFT_COST.filterKey,
      }),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_MILESTONES,
        { projectId, query, filterKey: LineItemFilterValues.VERTICAL_COST.filterKey, limit },
      ],
      queryFn: getProjectMilestonesList.bind(this, {
        projectId,
        query,
        filterKey: LineItemFilterValues.VERTICAL_COST.filterKey,
        limit,
      }),
    },
  ]);

  const horizontalMilestonesCount = requestedDataQueries[0].data?.count;
  const verticalMilestonesCount = requestedDataQueries[1].data?.count;

  const budgetSections = useMemo(
    () => ({
      ...(horizontalMilestonesCount && {
        [CSVPHBBudgetSectionsKeysEnum.IS_HORIZONTAL]: {
          title: 'Horizontal & Soft costs',
          isChecked: false,
        },
      }),
      ...(verticalMilestonesCount && {
        [CSVPHBBudgetSectionsKeysEnum.IS_VERTICAL]: {
          title: 'Vertical costs',
          isChecked: false,
        },
      }),
      [CSVPHBBudgetSectionsKeysEnum.ALL]: {
        title: 'All (including budget naming & structure)',
        isChecked: false,
      },
    }),
    [horizontalMilestonesCount, verticalMilestonesCount],
  );

  const [budgetSectionsState, setBudgetSectionsState] = React.useState(budgetSections);
  useEffect(() => {
    setBudgetSectionsState(budgetSections);
  }, [budgetSections]);

  const handleCheckboxChange = (key: string, value: boolean) => {
    setBudgetSectionsState((prevState) => {
      const isAllCheckbox = key === CSVPHBBudgetSectionsKeysEnum.ALL;
      const newState = { ...prevState };

      newState[key] = { ...newState[key], isChecked: value };

      if (isAllCheckbox) {
        return updateAllCheckboxes(newState, value);
      } else {
        return uncheckAllCheckbox(newState);
      }
    });
  };

  const updateAllCheckboxes = (state, value) => {
    return Object.keys(state).reduce((newState, key) => {
      newState[key] = { ...state[key], isChecked: value };
      return newState;
    }, {});
  };

  const uncheckAllCheckbox = (state) => {
    state[CSVPHBBudgetSectionsKeysEnum.ALL].isChecked = false;
    return state;
  };

  return { budgetSectionsState, handleCheckboxChange };
};
