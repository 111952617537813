import * as React from 'react';
import { InputAttributes, NumericFormat, NumericFormatProps } from 'react-number-format';
import { fonts } from '@theme';

interface CustomProps {
  onChange: (event: { target: { value: string } }) => void;
  style?: React.CSSProperties;
  decimalScale?: number;
}

export const DigitInput = React.forwardRef<NumericFormatProps<InputAttributes>, CustomProps>(
  function DigitInput(props, ref) {
    const { onChange, style, ...other } = props;

    return (
      <NumericFormat
        getInputRef={ref}
        allowLeadingZeros
        onValueChange={(values, { source }) => {
          if (source !== 'event') return false;
          onChange({ target: { value: values.value } });
        }}
        valueIsNumericString
        style={{ fontFamily: fonts.primary, ...style }}
        {...other}
      />
    );
  },
);

export const CurrencyInput = React.forwardRef<NumericFormatProps<InputAttributes>, CustomProps>(
  function CurrencyInput(props, ref) {
    const { onChange, style, ...other } = props;
    return (
      <NumericFormat
        getInputRef={ref}
        onValueChange={(values, { source }) => {
          if (source !== 'event') return false;
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        fixedDecimalScale
        thousandSeparator
        decimalScale={2}
        valueIsNumericString
        prefix="$"
        style={{ fontFamily: fonts.primary, ...style }}
        {...other}
      />
    );
  },
);

export const PercentsInput = React.forwardRef<NumericFormatProps<InputAttributes>, CustomProps>(
  function PercentsInput(props, ref) {
    const { onChange, style, decimalScale = 2, ...other } = props;

    return (
      <NumericFormat
        getInputRef={ref}
        onValueChange={(values, { source }) => {
          if (source !== 'event') return false;
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={decimalScale}
        valueIsNumericString
        suffix="%"
        style={{ fontFamily: fonts.primary, ...style }}
        {...other}
      />
    );
  },
);
