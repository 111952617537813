import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const ForApprovalIcon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 14C15.8137 14 18.5 11.3137 18.5 8C18.5 4.68629 15.8137 2 12.5 2C9.18629 2 6.5 4.68629 6.5 8C6.5 11.3137 9.18629 14 12.5 14ZM13.5 15.9381C14.5819 15.8032 15.5968 15.4522 16.5 14.9297L16.5 20C16.5 20.5523 16.9477 21 17.5 21C18.0523 21 18.5 20.5523 18.5 20L18.5 13.2916C19.7447 11.8814 20.5 10.0289 20.5 8C20.5 3.58172 16.9183 0 12.5 0C8.08172 0 4.5 3.58172 4.5 8C4.5 10.0289 5.25527 11.8814 6.5 13.2916L6.5 20C6.5 20.5523 6.94772 21 7.5 21C8.05228 21 8.5 20.5523 8.5 20L8.5 14.9297C9.40321 15.4522 10.4181 15.8032 11.5 15.9381L11.5 23C11.5 23.5523 11.9477 24 12.5 24C13.0523 24 13.5 23.5523 13.5 23L13.5 15.9381ZM12.5 12C11.9477 12 11.5 11.5523 11.5 11V7.6301L11.2474 7.91427C10.8805 8.32704 10.2484 8.3642 9.83562 7.99727C9.42284 7.63034 9.38568 6.99827 9.75261 6.5855L11.7526 4.33562C11.9424 4.12214 12.2144 4 12.5 4C12.7856 4 13.0576 4.12214 13.2474 4.33562L15.2474 6.5855C15.6143 6.99827 15.5772 7.63034 15.1644 7.99727C14.7516 8.3642 14.1195 8.32704 13.7526 7.91427L13.5 7.6301V11C13.5 11.5523 13.0523 12 12.5 12Z"
      fill={color}
    />
  </svg>
);

export default ForApprovalIcon;
