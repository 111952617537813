import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { StyledBox } from '@components';
import SubmissionSummary from '../SubmissionSummary';
import { FillTableImage } from '@svgAsComponents';
import {
  IChecklistController,
  IMilestoneList,
  IRequestController,
  IValidationController,
} from '../../main/interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import SectionWrapper from '../sectionWrapper';
import { currencyFormatter, getDefaultChecklistItem } from '@utils';
import { colors } from '@theme';
import { PolicyItemTypesEnum } from '@interfaces';
import { SubmissionSectionKeyMap } from '@constants';

const DrawRequestForm: FC<{
  milestoneListController: IMilestoneList;
  validationController: IValidationController;
  requestController: IRequestController;
  checklistController: IChecklistController;
}> = ({
  validationController,
  milestoneListController: controller,
  requestController,
  checklistController,
}) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { project, drawRequest, isReallocationAllowed } = requestController;
  const { checklistItems, handleDone } = checklistController;
  const { isEditable, isRequestChanged } = controller;
  const { onSectionButtonsClick, validatedSection } = validationController;
  const checklistItem = getDefaultChecklistItem(
    checklistItems,
    PolicyItemTypesEnum.DRAW_REQUEST_FORM,
  );
  const sectionKey = SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM];

  return (
    <SectionWrapper
      title={checklistItem?.label || 'Complete request form'}
      subtitle="Enter the requested amounts for this current period."
      controller={validationController}
      sectionKey={sectionKey}
      collapsedSectionText={{
        label: 'Requested amount:',
        value: currencyFormatter(drawRequest?.totals?.all?.requested_amount),
      }}
      handleDone={
        checklistItem
          ? () => {
              handleDone({
                checklistItemId: checklistItem.id,
                shouldComplete: !validatedSection?.[sectionKey]?.isDone,
              });
            }
          : null
      }
      innerRef={checklistItem?.itemRef}
      sectionWrapperButton={(expanded: boolean) => {
        if (expanded && !isRequestChanged) return null;
        return (
          <Button
            variant="new"
            color="secondary"
            onClick={() => {
              if (checklistItem) {
                handleDone({
                  checklistItemId: checklistItem.id,
                  shouldComplete: false,
                });
              }
              if (validatedSection?.[sectionKey]?.isDone)
                onSectionButtonsClick({ sectionKey, key: 'isDone' });
              navigate(`/projects/${projectId}/requests/${drawRequest?.id}/table-edit/`);
            }}
            disabled={!isEditable}
            data-cy="request__submission__edit_request__button"
          >
            {isRequestChanged ? 'Edit request' : 'Enter amounts'}
          </Button>
        );
      }}
      itemType={PolicyItemTypesEnum.DRAW_REQUEST_FORM}
      hideSkipButton
      isEditable={isEditable}
    >
      <Stack sx={{ backgroundColor: colors.background.gray, pt: 1, flex: 1 }}>
        <Stack spacing={1} sx={{ flex: 1 }}>
          {isRequestChanged ? (
            <>
              <SubmissionSummary
                retainageRate={project.retainage_rate}
                projectLoan={project.loan}
                request={drawRequest}
                isDrawRequest={controller.isDrawRequest}
                isReallocateComplete={controller.isReallocateComplete}
                isReallocationAllowed={isReallocationAllowed}
              />
            </>
          ) : (
            <StyledBox sx={{ p: 0 }}>
              <Stack alignItems="center" padding={5}>
                <Box padding={5.5}>
                  <FillTableImage />
                </Box>
                <Typography variant="h2" padding={3} textAlign="center">
                  To get started, click the button below to enter your
                  <br /> request on your schedule of values.
                </Typography>
                <Button
                  onClick={() =>
                    navigate(`/projects/${projectId}/requests/${drawRequest?.id}/table-edit/`)
                  }
                  data-cy="edit_request_table_button"
                >
                  Enter amounts
                </Button>
              </Stack>
            </StyledBox>
          )}
        </Stack>
      </Stack>
    </SectionWrapper>
  );
};

export default DrawRequestForm;
