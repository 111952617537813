import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { IMilestone, IPieChartData, QueryNamesEnums } from '@interfaces';
import { calculateFraction, currencyFormatter, percentFormatter } from '@utils';
import { useGetData } from '@context';
import { colors } from '@theme';

interface ControllerInterface {
  valuePieChartData: IPieChartData[];
}

export interface ComponentProps {
  requestId: string;
  milestoneId: string;
}

export const useBreakdownGraph = ({
  requestId,
  milestoneId,
}: ComponentProps): ControllerInterface => {
  const { projectId } = useParams();

  const keys: (keyof IMilestone)[] = [
    'construction_holdback',
    'borrower_equity',
    'revised_estimate',
  ];

  const drLineItem = useGetData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys,
    args: { projectId, drawRequestId: requestId, milestoneId },
    options: {
      skip: !projectId || !requestId || !milestoneId,
    },
  });

  const projectLineItem = useGetData({
    type: QueryNamesEnums.GET_PROJECT_MILESTONE,
    keys,
    args: { projectId, milestoneId },
    options: {
      skip: !projectId || Boolean(requestId) || !milestoneId,
    },
  });

  const milestone = useMemo(
    () => (requestId ? drLineItem.data : projectLineItem.data),
    [requestId, drLineItem.data, projectLineItem.data],
  );

  const valuePieChartData = useMemo(
    () => [
      {
        label: `Construction holdback (${currencyFormatter(milestone?.construction_holdback)}, ${percentFormatter(
          {
            value: calculateFraction(milestone?.construction_holdback, milestone?.revised_estimate),
          },
        )})`,
        color: colors.status.information.medium,
        value: milestone?.construction_holdback,
      },
      {
        label: `Original borrower equity (${currencyFormatter(milestone?.borrower_equity)}, ${percentFormatter(
          {
            value: calculateFraction(milestone?.borrower_equity, milestone?.revised_estimate),
          },
        )})`,
        color: colors.status.orange.medium,
        value: milestone?.borrower_equity,
      },
    ],
    [milestone?.revised_estimate, milestone?.construction_holdback, milestone?.borrower_equity],
  );

  return {
    valuePieChartData,
  };
};
