import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 60 }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4307 41.405C15.476 41.405 16.3234 39.676 16.3234 37.5432C16.3234 35.4104 15.476 33.6815 14.4307 33.6815C13.3854 33.6815 12.5381 35.4104 12.5381 37.5432C12.5381 39.676 13.3854 41.405 14.4307 41.405Z"
      fill="#5E8376"
    />
    <path
      d="M14.6055 43.8643C13.8475 39.3163 14.5979 34.7848 14.6055 34.7396L14.753 34.7645C14.7453 34.8095 13.9993 39.3173 14.753 43.8398L14.6055 43.8643Z"
      fill="#BCBCBC"
    />
    <path
      d="M16.097 36.7129L14.3856 37.6283L14.4561 37.7602L16.1676 36.8448L16.097 36.7129Z"
      fill="#BCBCBC"
    />
    <path
      d="M12.6498 37.1714L12.5792 37.3033L14.2905 38.2192L14.3611 38.0874L12.6498 37.1714Z"
      fill="#BCBCBC"
    />
    <path d="M52.6018 16.4244H49.4998V27.1622H52.6018V16.4244Z" fill="#D2D2D2" />
    <path
      d="M54.6504 25.1422L47.1057 16.1666L31.9612 16.3051L22.7744 25.2533L22.96 25.3726H22.8937V43.9849H54.6301V25.3726L54.6504 25.1422Z"
      fill="#BCBCBC"
    />
    <path
      d="M47.1136 16.1858L37.6881 27.2361V43.9849H54.6301V25.134L47.1136 16.1858Z"
      fill="#D2D2D2"
    />
    <path d="M47.9487 34.7226H44.4888V37.7808H47.9487V34.7226Z" fill="#BCBCBC" />
    <path d="M47.9487 29.4291H44.4888V32.4389H47.9487V29.4291Z" fill="#BCBCBC" />
    <path d="M47.9487 34.7226H44.4888V37.7808H47.9487V34.7226Z" fill="white" />
    <path d="M47.9487 29.4291H44.4888V32.4389H47.9487V29.4291Z" fill="white" />
    <path
      d="M35.3385 43.4392C35.3385 43.4392 35.4127 41.885 36.9333 42.0656L35.3385 43.4392Z"
      fill="#5E8376"
    />
    <path
      d="M34.9089 41.9602C35.3292 41.9602 35.6699 41.6195 35.6699 41.1991C35.6699 40.7788 35.3292 40.4381 34.9089 40.4381C34.4886 40.4381 34.1478 40.7788 34.1478 41.1991C34.1478 41.6195 34.4886 41.9602 34.9089 41.9602Z"
      fill="#5E8376"
    />
    <path d="M34.9999 42.4807H34.785V43.9844H34.9999V42.4807Z" fill="#5E8376" />
    <path
      d="M9.88327 39.1407C11.9277 39.1407 13.585 35.759 13.585 31.5875C13.585 27.4161 11.9277 24.0344 9.88327 24.0344C7.83883 24.0344 6.18149 27.4161 6.18149 31.5875C6.18149 35.759 7.83883 39.1407 9.88327 39.1407Z"
      fill="#5E8376"
    />
    <path
      d="M10.2956 43.9391C8.81497 35.0555 10.2806 26.2042 10.2956 26.1158L10.4431 26.1408C10.4281 26.229 8.96676 35.0564 10.4431 43.9145L10.2956 43.9391Z"
      fill="#BCBCBC"
    />
    <path
      d="M13.1761 30.0264L9.8286 31.8169L9.89915 31.9488L13.2466 30.1583L13.1761 30.0264Z"
      fill="#BCBCBC"
    />
    <path
      d="M6.36634 30.9234L6.29576 31.0552L9.64276 32.8467L9.71334 32.7148L6.36634 30.9234Z"
      fill="#BCBCBC"
    />
    <path
      d="M18.6506 37.3773C21.4483 37.3773 23.7162 32.7498 23.7162 27.0415C23.7162 21.3331 21.4483 16.7056 18.6506 16.7056C15.853 16.7056 13.5851 21.3331 13.5851 27.0415C13.5851 32.7498 15.853 37.3773 18.6506 37.3773Z"
      fill="#5E8376"
    />
    <path
      d="M19.2421 43.9391C17.2167 31.787 19.2216 19.6794 19.2421 19.5585L19.3895 19.5835C19.3691 19.7042 17.3685 31.788 19.3896 43.9145L19.2421 43.9391Z"
      fill="#BCBCBC"
    />
    <path
      d="M23.1696 24.9295L18.5889 27.3796L18.6594 27.5115L23.2402 25.0614L23.1696 24.9295Z"
      fill="#BCBCBC"
    />
    <path
      d="M13.825 26.1569L13.7544 26.2888L18.3345 28.7401L18.4051 28.6083L13.825 26.1569Z"
      fill="#BCBCBC"
    />
    <path d="M57.3333 43.8618H2.66667V43.9919H57.3333V43.8618Z" fill="#BCBCBC" />
    <path d="M32.5723 33.9111H29.1124V36.9694H32.5723V33.9111Z" fill="#BCBCBC" />
    <path d="M32.5723 28.6177H29.1124V31.6274H32.5723V28.6177Z" fill="#BCBCBC" />
    <path d="M32.5723 33.9111H29.1124V36.9694H32.5723V33.9111Z" fill="white" />
    <path d="M32.5723 28.6177H29.1124V31.6274H32.5723V28.6177Z" fill="white" />
  </svg>
);

export default Icon;
