import React, { FC, useContext } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { Filter, MilestoneListWithPatch, ToggleButtonGroup } from '@components';
import { TableKeyEnum } from '@interfaces';
import { CommentIconWithIndicator, DeleteIcon } from '@svgAsComponents';
import { getTooltipText } from '@utils';
import { AutofillAllowanceV2 } from '../../project/components';
import { RequestTableControllerInterface } from '../RequestTableV2/controller';
import { SettingsContext } from '@context';
import { useParams } from 'react-router-dom';
import TypeFilter from '../TypeFilter';

const MilestoneListContainer: FC<{
  controller: RequestTableControllerInterface;
}> = ({ controller }) => {
  const { requestId } = useParams();
  const { isCurrentProjectArchived } = useContext(SettingsContext);

  const {
    initColumns,
    onExpandClick,
    filterOptions,
    filterValue,
    handleShowFilterClick,
    isLoading,
    canDeleteRequest,
    setShowDeleteModal,
    showAutofillButton,
    drawRequest,
    handleAutofillLenderAllowance,
    isAutofillLoading,
    totals,
    groupByKeys,
    updateRightDrawer,
    onMilestoneUpdate,
    activeView,
    setActiveView,
    isLineItemsView,
    tableItems,
    patchMsGroup,
    viewTypes,
    typeFilterValues,
    setTypeFilterValues,
  } = controller;

  return (
    <MilestoneListWithPatch
      withColumnIndication
      tableKey={TableKeyEnum.PHB_REQUEST_LINE_ITEMS}
      initColumns={initColumns}
      milestones={tableItems}
      key="listWithEditModels"
      exportToCSV
      onExpandTable={onExpandClick}
      requestId={requestId}
      totals={totals}
      headerLeftPart={[
        {
          Component: (
            <Stack sx={{ mr: 2 }}>
              <ToggleButtonGroup
                value={activeView}
                typesList={viewTypes}
                handleChange={(_e, value) => value && setActiveView(value)}
                size="small"
                source="requests_tab__line_items_table"
              />
            </Stack>
          ),
        },
        ...(isLineItemsView
          ? [
              {
                Component: (
                  <TypeFilter
                    handleTypeClick={setTypeFilterValues}
                    typesFilterValues={typeFilterValues}
                  />
                ),
              },
            ]
          : [
              {
                Component: (
                  <Filter
                    filterLabel="Show"
                    onChangeCallback={handleShowFilterClick}
                    options={filterOptions}
                    filterValue={filterValue}
                    source="requests_tab__line_items_table__filter"
                  />
                ),
              },
            ]),
      ]}
      headerRightPart={[
        ...(showAutofillButton
          ? [
              {
                Component: (
                  <AutofillAllowanceV2
                    drawRequest={drawRequest}
                    handleAutofillLender={handleAutofillLenderAllowance}
                    loading={isAutofillLoading}
                  />
                ),
              },
            ]
          : []),
        {
          Component: (
            <IconButton onClick={updateRightDrawer()} data-cy="requests_tab__comments__icon">
              <CommentIconWithIndicator
                hasComments={drawRequest?.comments_preview?.has_comments}
                hasUnreadComments={drawRequest?.comments_preview?.has_unread_comments}
              />
            </IconButton>
          ),
        },
      ]}
      menuItems={
        canDeleteRequest
          ? [
              {
                action: () => setShowDeleteModal(true),
                text: (
                  <Typography variant="body3SemiBold" sx={{ color: colors.status.error.medium }}>
                    Delete request
                  </Typography>
                ),
                icon: <DeleteIcon size={16} color={colors.status.error.medium} />,
                disabled: isCurrentProjectArchived,
                disabledTooltipText: getTooltipText({ isCurrentProjectArchived }),
                dataTestName: 'delete_request__menu_item',
              },
            ]
          : []
      }
      source="requests_tab__line_items_table"
      patchMilestone={patchMsGroup}
      isLoading={isLoading}
      groupByFilter={groupByKeys}
      requestStatus={drawRequest?.status}
      onCreditChangeCompleted={onMilestoneUpdate}
      lockSecondColumn
      withCredit
      withProgress
    />
  );
};

export default MilestoneListContainer;
