import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 18 }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 18) * 24}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14572 4.76837e-07H11.1728L17.9751 7.09007V20.8792C17.9751 22.5982 16.5733 24 14.8543 24H3.14572C1.41932 24 0.0249043 22.5982 0.0249043 20.8792V3.12082C0.0249043 1.39442 1.41932 4.76837e-07 3.14572 4.76837e-07V4.76837e-07Z"
      fill="#EBA800"
    />
    <path
      opacity="0.302"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1654 4.76837e-07V7.03106H17.9751L11.1654 4.76837e-07Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.2 12C5.53726 12 5 12.5037 5 13.125V16.875C5 17.4963 5.53726 18 6.2 18H11.8C12.4627 18 13 17.4963 13 16.875V13.125C13 12.5037 12.4627 12 11.8 12H6.2ZM5.83653 12.9682C5.89985 12.8394 6.03879 12.75 6.2 12.75H11.8C11.9612 12.75 12.1002 12.8394 12.1635 12.9682L9.24558 15.0959C9.10113 15.2012 8.89887 15.2012 8.75442 15.0959L5.83653 12.9682ZM5.8 13.8917V16.0744L7.24504 14.9454L5.8 13.8917ZM5.83316 17.0247C5.89482 17.1573 6.03586 17.25 6.2 17.25H11.8C11.9641 17.25 12.1052 17.1573 12.1668 17.0247L10.1086 15.4167L9.73673 15.6879C9.3034 16.0038 8.69661 16.0038 8.26327 15.6879L7.89141 15.4167L5.83316 17.0247ZM12.2 16.0744V13.8917L10.755 14.9454L12.2 16.0744Z"
      fill="white"
    />
  </svg>
);

export default Icon;
