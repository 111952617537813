import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import {
  ChecklistItemLocal as ChecklistItem,
  ChecklistItemReason,
  IChecklistReason,
  IProjectChecklist,
  ItemStatusesEnum,
  PatchChecklistItemParam,
  PolicyListReasonsTypeEnum,
  QueryNamesEnums,
  UpdateChecklistItemParam,
} from '@interfaces';
import {
  getChecklistReasons,
  getDrawRequestItemChecklist,
  patchChecklistItem,
  updateChecklistItem,
} from '@globalService';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSafeSnackbar } from '@hooks';
import { getChecklistIdByRole, getTeamRole } from '@utils';
import { AuthContext } from '@context';

export interface ControllerInterface {
  setChecklistReasons: Dispatch<SetStateAction<ChecklistItemReason[]>>;
  onApprove: () => void;
  reasonsList: IChecklistReason[];
  isReasonError: boolean;
}
export interface PropsInterface {
  checklistItems: ChecklistItem[];
  drawRequestId?: string;
  projectId: string;
  onApproveClose: () => void;
  isRequiredReasons: boolean;
  checklistId?: string;
  checklistType: PolicyListReasonsTypeEnum;
}

export const useChecklistApproveModal = ({
  checklistItems,
  drawRequestId,
  projectId,
  onApproveClose,
  isRequiredReasons,
  checklistId,
  checklistType,
}: PropsInterface): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);

  const [isReasonError, setIsReasonError] = useState(false);

  const [checklistReasons, setChecklistReasons] = useState(
    checklistItems?.map((item) => {
      return {
        checklistItemId: item.id,
        reasons: [],
        label: item.label,
      };
    }),
  );

  const reasonsListQuery = useQuery<IChecklistReason[], Error>(
    [QueryNamesEnums.GET_CHECKLIST_REASONS],
    getChecklistReasons,
  );

  const DRChecklist = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST, { projectId, drawRequestId }],
    getDrawRequestItemChecklist.bind(this, projectId, drawRequestId),
    { enabled: Boolean(drawRequestId) },
  );

  const updateChecklistItemMutation = useMutation<Response, Error, UpdateChecklistItemParam>(
    updateChecklistItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_CHECKLIST);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const patchDRChecklistItem = useMutation<Response, Error, PatchChecklistItemParam>(
    patchChecklistItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleApproveChecklistApproveModal = () => {
    if (isRequiredReasons) {
      const emptyReason = checklistReasons.find((item) => item.reasons.length === 0);
      if (emptyReason) {
        setIsReasonError(true);
        return;
      }
    }
    if (drawRequestId) {
      const checklistId = getChecklistIdByRole(DRChecklist.data, teamRole);
      checklistId &&
        checklistReasons
          .filter((item) => item.reasons.length)
          .map((item) =>
            patchDRChecklistItem.mutateAsync({
              projectId,
              drawRequestId,
              checklistId,
              checklistItemId: item.checklistItemId,
              value: { exception_reasons: item.reasons, status: ItemStatusesEnum.SKIPPED },
            }),
          );
    } else {
      checklistReasons
        .filter((item) => item.reasons.length)
        .map((item) =>
          updateChecklistItemMutation.mutateAsync({
            projectId,
            checklistId,
            checklistItemId: item.checklistItemId,
            status: ItemStatusesEnum.SKIPPED,
            exception_reasons: item.reasons,
          }),
        );
    }
    onApproveClose();
  };

  const reasonsList = useMemo(
    () => reasonsListQuery.data?.filter((item) => item.checklist_type === checklistType) || [],
    [reasonsListQuery.data, checklistType],
  );

  return {
    setChecklistReasons,
    onApprove: handleApproveChecklistApproveModal,
    reasonsList,
    isReasonError,
  };
};
