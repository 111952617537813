import { useIsFetching, useIsMutating, UseQueryResult } from 'react-query';
import { HookState, MutationKeyEnum, QueryNamesEnums } from '@interfaces';
import { getDeepIsEmpty } from './decorators';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import isEqual from 'lodash.isequal';

export function getHookState(result: UseQueryResult | UseQueryResult[]) {
  if (Array.isArray(result)) {
    if (result.some(({ isLoading }) => isLoading)) {
      return HookState.FETCHING;
    }
    if (result.some(({ isError }) => isError)) {
      return HookState.ERROR;
    }
    if (result.every(({ isSuccess }) => isSuccess)) {
      return HookState.SUCCESS;
    }
  } else {
    if (result.isLoading) {
      return HookState.FETCHING;
    }
    if (result.isError) {
      return HookState.ERROR;
    }
    if (result.isSuccess && getDeepIsEmpty(result.data)) {
      return HookState.EMPTY;
    }
    if (result.isIdle) {
      return HookState.IDLE;
    }
    if (result.isSuccess) {
      return HookState.SUCCESS;
    }
  }
}

export const useBlockerFooter = () => {
  const { projectId, requestId } = useParams();
  const isBlockedByFetchingTotals = useIsFetching([
    QueryNamesEnums.GET_DRAW_REQUEST_TOTALS,
    { projectId, drawRequestId: requestId },
  ]);
  const isBlockedByFetchingDr = useIsFetching([
    QueryNamesEnums.GET_DRAW_REQUEST,
    { projectId, drawRequestId: requestId },
  ]);

  return Boolean(isBlockedByFetchingTotals || isBlockedByFetchingDr);
};

export const useBlockerCell = ({
  milestoneId,
  key,
  tags,
}: {
  milestoneId: string;
  key?: string;
  tags?: string;
}) => {
  const checkMsBlock = (variables) => {
    const isCurrentMs =
      (variables.milestone && variables.milestone === milestoneId) ||
      (variables.tags && variables.tags === tags);
    const isCurrentField = key && variables.json?.[key];

    if (isCurrentMs) return !isCurrentField;

    const updatedTagsArr = variables.tags?.split(',');
    const msTagsArr = tags?.split(',');
    // nested milestones have 2 tags in variables
    const isNestedMsWasUpdated = updatedTagsArr?.length > 1;
    const isNestedMs = msTagsArr?.length > 1 && msTagsArr.includes(updatedTagsArr?.[0]);
    const isParentMs = updatedTagsArr?.includes(tags);

    return Boolean(isCurrentField && (isParentMs || (isNestedMs && !isNestedMsWasUpdated)));
  };

  const isBlockedByMutation = useIsMutating({
    predicate: ({ options }) => {
      return (
        options.mutationKey === MutationKeyEnum.MILESTONE_DELETE ||
        options.mutationKey === MutationKeyEnum.DRAW_REQUEST_ADD_ITEM ||
        options.mutationKey === MutationKeyEnum.MILESTONE_PATCH_BULK ||
        (options.mutationKey === MutationKeyEnum.MILESTONE_PATCH && checkMsBlock(options.variables))
      );
    },
  });

  return Boolean(isBlockedByMutation);
};

export const useCallbackByConditions = ({
  callback,
  conditions,
}: {
  callback: () => void;
  conditions: boolean;
}) => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  useEffect(() => {
    if (conditions && isWaiting) {
      setIsWaiting(false);
      callback();
    }
  }, [isWaiting, conditions]);
  return {
    isWaiting,
    run: () => setIsWaiting(true),
  };
};

function findDifferences(
  prev: any,
  current: any,
  path = '',
): Record<string, { previous: any; current: any }> {
  const changes: Record<string, { previous: any; current: any }> = {};

  if (
    typeof prev === 'object' &&
    typeof current === 'object' &&
    prev !== null &&
    current !== null
  ) {
    const keys = new Set([...Object.keys(prev), ...Object.keys(current)]);

    keys.forEach((key) => {
      const fullPath = path ? `${path}.${key}` : key;

      if (typeof prev[key] === 'function' && typeof current[key] === 'function') {
        if (prev[key].toString() !== current[key].toString()) {
          changes[fullPath] = { previous: prev[key].toString(), current: current[key].toString() };
        }
      } else if (!isEqual(prev[key], current[key])) {
        if (typeof prev[key] === 'object' && typeof current[key] === 'object') {
          Object.assign(changes, findDifferences(prev[key], current[key], fullPath));
        } else {
          changes[fullPath] = { previous: prev[key], current: current[key] };
        }
      }
    });
  } else if (!isEqual(prev, current)) {
    changes[path] = { previous: prev, current: current };
  }

  return changes;
}

export function useDeepTraceUpdates(props: Record<string, any>, name: string) {
  const prevProps = useRef(props);

  useEffect(() => {
    const changes = findDifferences(prevProps.current, props);
    if (Object.keys(changes).length > 0) {
      console.log(`Component ${name} updated with changes:`, changes);
    }

    prevProps.current = props;
  }, [props]);
}

export function useDidValueChange(value) {
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const previousValue = useRef(value);

  useEffect(() => {
    if (previousValue.current !== value) {
      setHasChanged(true);
      previousValue.current = value;
    } else {
      setHasChanged(false);
    }
  }, [value]);

  return { hasChanged, setHasChanged };
}
