import { positions, styled } from '@mui/system';
import { Box } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-premium';
import { colors } from '@theme';

interface TableStyleProps {
  dynamicHeight?: number;
  onFullScreen?: boolean;
}

export const TRIGGERHEIGHT = 60;

export const TableStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'onFullScreen' && prop !== 'dynamicHeight',
})<TableStyleProps>(({ dynamicHeight, onFullScreen }) => ({
  background: 'white',
  ...(onFullScreen
    ? {
        width: '100%',
        position: 'fixed',
        top: 60,
        left: 0,
        right: 0,
        bottom: 0,
        height: 'calc( 100vh - 60px)',
      }
    : {
        width: '100%',
        height: dynamicHeight ? `${dynamicHeight}px` : 'auto',
      }),
  [`.${gridClasses.columnHeaderTitle}`]: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  [`.${gridClasses.menuIcon}`]: {
    position: 'absolute',
    right: 5,
    top: 2,
    bottom: 2,
    marginLeft: 0,
    alignItems: 'center',
    backgroundColor: 'rgba(247, 247, 247, 0.6)',
    [' .MuiSvgIcon-root ']: {
      fill: colors.neutral.darkest,
    },
  },
  [`.${gridClasses.iconButtonContainer}`]: {
    position: 'absolute',
    right: 20,
    top: 2,
    bottom: 2,
    marginLeft: 0,
    alignItems: 'center',
    backgroundColor: 'rgba(247, 247, 247, 0.6)',
    [' .MuiSvgIcon-root ']: {
      fill: colors.neutral.darkest,
    },
  },
  [`.${gridClasses['columnHeader--alignRight']}`]: {
    [`.${gridClasses.menuIcon}`]: {
      left: 5,
      right: 'auto',
    },
    [`.${gridClasses.iconButtonContainer}`]: {
      left: 20,
      right: 'auto',
    },
  },
  [`.${gridClasses['scrollbar--horizontal']}`]: {
    position: 'fixed',
    left: 20,
    right: 20,
    zIndex: 10000000,
    width: 'auto',
  },
  [`.${gridClasses['cell--editable']}`]: {
    backgroundColor: 'white',
  },
  [`.${gridClasses.cell}.icon`]: {
    borderRight: 0,
    padding: 0,
    justifyContent: 'center',
    backgroundColor: 'rgba(247, 247, 247, 0.6)',
  },
  [`.${gridClasses.row}.totals`]: {
    fontWeight: 'bold',
    [`.${gridClasses.cell}.icon button`]: {
      display: 'none',
    },
    [`.${gridClasses.cell}.select::after`]: {
      display: 'none',
    },
  },
  [`.${gridClasses.columnHeader}.number`]: {
    textAlign: 'right',
  },
  [`.${gridClasses.columnHeader}.icon`]: {
    borderRight: 0,
    [`.${gridClasses.columnSeparator}`]: {
      display: 'none',
    },
  },
  [`.${gridClasses.cell}.text`]: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row',
    lineHeight: '1em',
    justifyContent: 'flex-end',
    [`&:not(.${gridClasses['cell--editable']})`]: {
      backgroundColor: 'rgba(247, 247, 247, 0.6)',
      color: colors.neutral.dark,
    },
  },
  [`.${gridClasses.cell}.select`]: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row',
    lineHeight: '1em',
    justifyContent: 'fex-start',
    position: 'relative',
    [`&:not(.${gridClasses['cell--editable']})`]: {
      backgroundColor: 'rgba(247, 247, 247, 0.6)',
      color: colors.neutral.dark,
    },
    [`&:not(.${gridClasses['cell--editing']})`]: {
      [`&:after`]: {
        position: 'absolute',
        right: 15,
        content: `""`,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 5px 5px 5px',
        borderColor: 'transparent transparent #585858 transparent',
        transform: 'rotate(180deg)',
      },
    },
  },
  [`.${gridClasses.cell}.number`]: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row',
    lineHeight: '1em',
    justifyContent: 'flex-end',
    [`&:not(.${gridClasses['cell--editable']})`]: {
      backgroundColor: 'rgba(247, 247, 247, 0.6)',
      color: colors.neutral.dark,
    },
  },
  [`.${gridClasses.root}`]: {
    [`.${gridClasses.row}:hover`]: {
      backgroundColor: 'transparent',
    },
    [`.${gridClasses.row}.Mui-selected`]: {
      backgroundColor: 'transparent',
    },
    [`.${gridClasses.row}.Mui-selected:hover`]: {
      backgroundColor: 'transparent',
    },
  },
  [`.${gridClasses['root--densityCompact']}`]: {
    [`.${gridClasses.cell}`]: {
      fontSize: '0.8rem',
      ['.MuiTypography-root']: {
        fontSize: '0.8rem',
      },
    },
    [`.${gridClasses.columnHeaderTitle}`]: {
      fontSize: '0.75rem',
    },
  },
}));
