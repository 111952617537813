import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import * as Controller from './controller';
import { ConfirmationModal } from '@components';
import { useConfirmationModal } from '@hooks';
import { PopupTypeEnum } from '@interfaces';

const PoliciesTemplateSelect = ({ policies, assigneeRole, source }) => {
  const controller = Controller.usePoliciesTemplateSelect(assigneeRole);
  const { policiesList, applyTemplate, modalText } = controller;

  const { isConfirmModalOpened, askConfirm, closeConfirmModal, confirmCallback } =
    useConfirmationModal();

  if (!policiesList?.length) return null;

  return (
    <>
      <FormControl>
        <InputLabel
          id="select-policy-template-label"
          sx={{ '&:not(.MuiFormLabel-filled)': { transform: 'translate(12px, 10px) scale(1)' } }}
        >
          Choose policy
        </InputLabel>
        <Select
          labelId="select-policy-template-label"
          id="select-policy-template"
          value={policies?.template || ''}
          onChange={(event: SelectChangeEvent) =>
            askConfirm({ templateId: event.target.value, policiesId: policies?.id })
          }
          size="small"
          sx={{ minWidth: '300px' }}
          data-cy={`${source}_policy__section__policies_list__select`}
        >
          {policiesList.map((type) => (
            <MenuItem value={type.id} key={type.id}>
              {type.name}
              {type?.is_default && ' (default)'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ConfirmationModal
        open={isConfirmModalOpened}
        title="Confirm changes"
        text={modalText}
        onClose={closeConfirmModal}
        confirmCallback={() => confirmCallback({ action: applyTemplate })}
        type={PopupTypeEnum.CONFIRMATION}
        cancelButtonLabel="Close"
      />
    </>
  );
};

export default PoliciesTemplateSelect;
