import { useState, useCallback } from 'react';

export const useSorting = () => {
  const [value, setValue] = useState([]);

  return {
    sortValue: value,
    handleSortClick: useCallback(
      (newValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(value)) {
          setValue(newValue);
        }
      },
      [value],
    ),
  };
};
