import React, { FC } from 'react';
import { Chip, Tooltip, Typography, Stack, Box } from '@mui/material';

import { IStatusChip } from '@interfaces';

const heights = {
  small: '24px',
  medium: '32px',
  large: '40px',
};

const fontSizes = {
  small: '12px',
  medium: '14px',
  large: '14px',
};

const StatusChip: FC<IStatusChip> = ({
  color,
  backgroundColor,
  label,
  size = 'small' as IStatusChip['size'],
  dataTestName,
}) => (
  <Chip
    sx={{
      backgroundColor,
      height: heights[size],
      borderRadius: '2px',
      '& .MuiChip-root': {
        backgroundColor,
        borderRadius: '2px',
      },
      '& .MuiChip-label': {
        color,
        fontSize: fontSizes[size],
        letterSpacing: 'unset',
      },
    }}
    label={label}
    data-cy={dataTestName}
  />
);

export const StatusChipWithIconAndTooltip: FC<IStatusChip> = ({ tooltipText, icon, ...props }) => (
  <Tooltip
    placement="bottom-end"
    title={tooltipText ? <Typography variant="body3">{tooltipText}</Typography> : ''}
  >
    <Stack flexDirection="row" alignItems="center">
      <StatusChip {...props} />
      {icon && (
        <>
          <Box ml={0.5} />
          {icon}
        </>
      )}
    </Stack>
  </Tooltip>
);

export default StatusChip;
