import React, { FC } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { HookState } from '@interfaces';
import NodeCard from '../NodeCard';
import { LabelAndValue, LoadingSkeleton, ServiceMessage } from '@components';
import { currencyFormatter, percentFormatter } from '@utils';
import * as Controller from './controller';
import { useLaunchDarklyFlags } from '@context';
import { useDateFormatter } from '@hooks';

const LoanDetails: FC<{ smallSize?: boolean }> = ({ smallSize }) => {
  const { projectId } = useParams();
  const controller = Controller.useProject(projectId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return (
        <NodeCard title="Loan details">
          <LoadingSkeleton type="overviewBlock" />
        </NodeCard>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="funds" />;
    }

    case HookState.SUCCESS: {
      return <DetailsBody controller={controller} smallSize={smallSize} />;
    }

    default:
      return null;
  }
};

export default LoanDetails;

const DetailsBody: FC<{ controller: Controller.ControllerInterface; smallSize?: boolean }> = ({
  controller,
  smallSize = false,
}) => {
  const { project: { loan, retainage_rate } = {}, servicingStatus, loanType } = controller;
  const flags = useLaunchDarklyFlags();
  const { dateFormatter } = useDateFormatter();
  return (
    <NodeCard title="Loan details">
      <Stack spacing={smallSize ? 0.5 : 1} sx={{ pt: 1 }}>
        <LabelAndValue
          label="Funding date"
          text={dateFormatter({ date: loan?.funding_date })}
          textDataTestName="project__loan_details__funding_date"
        />
        {loan?.servicing_status && (
          <LabelAndValue
            label="Servicing status"
            text={servicingStatus}
            textDataTestName="project__loan_details__servicing_status"
          />
        )}
        {loan?.type && (
          <LabelAndValue
            label="Loan type"
            text={loanType}
            textDataTestName="project__loan_details__type"
          />
        )}
        <Box sx={{ py: smallSize ? 0.5 : 1.5 }}>
          <Divider />
        </Box>
        <LabelAndValue
          label="Total loan commitment"
          text={currencyFormatter(loan?.loc_commitment, '-')}
          textDataTestName="project__loan_details__total_loan_commitment"
        />
        <LabelAndValue
          label="Construction holdback"
          text={currencyFormatter(loan?.construction_holdback, '-')}
          textDataTestName="project__loan_details__construction_holdback"
        />
        <LabelAndValue
          label="Total construction budget"
          text={currencyFormatter(loan?.total_construction_budget, '-')}
          textDataTestName="project__loan_details__total_construction_budget"
        />
        {flags?.['ENG_8716_introduce_funding_sources'] ? (
          <LabelAndValue
            label="Schedule of values"
            text={currencyFormatter(loan?.post_funding_construction_budget, '-')}
            textDataTestName="project__loan_details__construction_budget_post_funding"
          />
        ) : (
          <LabelAndValue
            label="Construction budget post-funding"
            text={currencyFormatter(loan?.post_funding_construction_budget, '-')}
            textDataTestName="project__loan_details__construction_budget_post_funding"
          />
        )}
        <LabelAndValue
          label="Borrower equity"
          text={currencyFormatter(loan?.borrower_equity, '-')}
          textDataTestName="project__loan_details__borrower_equity"
        />
        <LabelAndValue
          label="Contingency"
          text={currencyFormatter(loan?.contingency, '-')}
          textDataTestName="project__loan_details__contingency"
        />
        <LabelAndValue
          label="Original maturity date"
          text={dateFormatter({ date: loan?.maturity_date })}
          textDataTestName="project__loan_details__original_maturity_date"
        />
        <LabelAndValue
          label="Extended maturity date"
          text={dateFormatter({ date: loan?.extended_maturity_date || loan?.maturity_date })}
          textDataTestName="project__loan_details__extended_maturity_date"
        />
        {Boolean(retainage_rate) && (
          <LabelAndValue
            label="Retainage rate"
            text={percentFormatter({
              value: retainage_rate,
            })}
            textDataTestName="project__loan_details__retainage_rate"
          />
        )}
      </Stack>
    </NodeCard>
  );
};
