import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  checkIsInvestor,
  checkIsLender,
  getTeamRole,
  isRequestDraftOrInReview,
  tableHeaders as headers,
} from '@utils';
import { excludeCommentsWithTotalsAllQueryFields } from '@constants';
import { IDocument, IDrawRequest, IProjectDocument, QueryNamesEnums } from '@interfaces';
import { AuthContext, useLaunchDarklyFlags } from '@context';
import { useCommentsAndDocumentsPreview, useImagePicker, useRightMenu, useUrlParams } from '@hooks';
import { getDrawRequest } from '@globalService';
import { ControllerInterface, IDocumentsWrapperProps } from '../../interface';
import { useDocumentTableColumns } from '../../useDocumentTableColumns';

export const useDocumentsTable = ({
  prId: projectId,
  requestId: drawRequestId,
  requestDocuments,
  milestoneId,
  milestoneSubmitId,
  inspectionId,
  serviceOrderId,
  source,
  isAllDocumentsTab,
  restoreDocument,
  isLoading,
  documents,
}: IDocumentsWrapperProps & {
  isLoading: boolean;
  documents: IProjectDocument[] | IDocument[];
}): ControllerInterface & { isRightDrawerDocs: boolean } => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const flags = useLaunchDarklyFlags();

  const [activeDocId, setActiveDocId] = useUrlParams(
    null,
    'documentId',
    (s) => s.toString(),
    (s) => s.toString(),
  );

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
    { enabled: Boolean(drawRequestId) },
  );

  const isMilestoneDocs = useMemo(
    () => Boolean(milestoneId) || Boolean(milestoneSubmitId),
    [milestoneId, milestoneSubmitId],
  );

  const isRightDrawerDocs = useMemo(
    () => isMilestoneDocs || requestDocuments || Boolean(inspectionId) || Boolean(serviceOrderId),
    [isMilestoneDocs, requestDocuments, inspectionId, serviceOrderId],
  );

  const { pdf, gallery, open, close } = useImagePicker();

  const [activeDocument, setActiveDocument] = useState<IProjectDocument | IDocument | null>(null);

  const [rightDrawerParams, setRightDrawerParams] = useState<{
    projectId: string;
    documentId: string;
  }>({
    documentId: '',
    projectId,
  });

  const [docSummaryParams, setDocSummaryParams] = useState<{
    projectId: string;
    documentId?: string;
  }>({
    projectId,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    ...(drawRequestId ? { drawRequestId } : {}),
    ...(inspectionId ? { inspectionId } : {}),
    ...(serviceOrderId ? { serviceOrderId } : {}),
    documentId: rightDrawerParams.documentId,
  });

  const updateRightDrawer = (documentId) => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
    setRightDrawerParams({ projectId, documentId });
  };

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const { handleRightDrawerOpenerClick: handleDocSummaryOpenerClick, ...docSummaryMenu } =
    useRightMenu({});

  const openComments = useCallback((row) => {
    updateRightDrawer(row?.original?.id);
  }, []);

  const openDocument = (file) => {
    if (!file) return closeDocument();
    setActiveDocId(file.id);
    open([file]);
  };

  const closeDocument = () => {
    setActiveDocId(null);
    close();
  };

  useEffect(() => {
    if (!activeDocId) setActiveDocId(null);
    if (documents?.length && activeDocId) {
      const doc = documents.find((doc) => doc.id === activeDocId);
      if (doc && !pdf && !gallery) open([doc]);
    }
  }, [activeDocId, documents, pdf, gallery, open]);

  const {
    name,
    lineItem,
    companyPrivateSwitcher,
    processedStatus,
    uploadedBy,
    icons,
    openAllDocsSummary,
  } = useDocumentTableColumns({
    projectId,
    drawRequestId,
    isLoading,
    source,
    open: openDocument,
    setDocSummaryParams,
    handleDocSummaryOpenerClick,
    isMilestoneDocs,
    openComments,
    restoreDocument,
    setActiveDocument,
  });

  const columns = useMemo(
    () => [
      name,
      ...(isRightDrawerDocs
        ? []
        : [
            headers.documentType({
              isLoading,
              dataTestName: `${source}__document_type__value`,
            }),
            lineItem,
            ...(checkIsLender(teamRole) || checkIsInvestor(teamRole)
              ? [companyPrivateSwitcher]
              : []),
            ...(isAllDocumentsTab
              ? [
                  headers.drNumber({
                    isLoading,
                    projectId,
                    disableSortBy: false,
                  }),
                ]
              : []),
            headers.date({
              accessor: 'created_at',
              header: 'Date uploaded',
              isLoading,
              dataTestName: `${source}__date_uploaded__value`,
            }),
            uploadedBy,
            processedStatus,
          ]),
      icons(),
    ],
    [
      documents,
      isLoading,
      teamRole,
      isRightDrawerDocs,
      drawRequestQuery.data?.status,
      isAllDocumentsTab,
    ],
  );

  const closeDocumentEditModal = () => {
    setActiveDocument(null);
  };

  const isDocumentUploadAllowed = useMemo(
    () =>
      !isAllDocumentsTab &&
      (!flags?.['ENG_9182_lock_docs_upload_for_completed_requests'] ||
        !drawRequestId ||
        isRequestDraftOrInReview(drawRequestQuery.data?.status)),
    [drawRequestQuery.data?.status, drawRequestId, isAllDocumentsTab, flags],
  );

  return {
    columns,
    pdf,
    gallery,
    close: closeDocument,
    rightMenu,
    isRightDrawerDocs,
    rightDrawerParams,
    docSummaryMenu,
    docSummaryParams,
    openAllDocsSummary,
    openPdfViewer: (file) => openDocument(file),
    activeDocument,
    closeDocumentEditModal,
    isDocumentUploadAllowed,
  };
};
