import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({
  size = 32,
  color1 = colors.main.primary.dark,
  color2 = colors.main.primary.dark,
}: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 13.0857V16.7889L8.47259 32H10.8281L2.36869 12.1101C2.14338 12.3889 2 12.7074 2 13.0857Z"
      fill={color1}
    />
    <path
      d="M29.5084 11.9309L16.85 0.343443C16.3584 -0.114481 15.6415 -0.114481 15.1499 0.343443L10.787 4.34531L22.5647 32H28.6686C29.406 32 30 31.323 30 30.5067V13.0857C30 12.6278 29.8156 12.2097 29.5084 11.9309Z"
      fill={color2}
    />
    <path
      d="M7.40765 7.43117L17.8744 31.9998H20.2094L9.08725 5.8782L7.40765 7.43117Z"
      fill={color1}
    />
    <path d="M2 27.6396L3.84346 31.9998H6.11706L2 22.3037V27.6396Z" fill={color1} />
    <path
      d="M4.02832 10.5172L13.1637 31.9998H15.5192L5.7284 8.96423L4.02832 10.5172Z"
      fill={color1}
    />
  </svg>
);

export default Icon;
