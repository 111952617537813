import React, { FC, memo, useContext, useMemo } from 'react';
import { IconButton } from '@mui/material';

import { CommonRowType, MilestoneListColumnTypeV2 } from './common';
import { FundingIconWithIndicator, InfoIcon } from '@svgAsComponents';
import { RequestTableTabsEnum } from '@interfaces';
import { creditAvailableForMilestone, wasCreditItteraction } from '@utils';
import { DrawerContext } from '../drawer';

const ApproveCredit: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(DrawerContext);
  const showCreditInfo = wasCreditItteraction(row);
  const creditAvalible = creditAvailableForMilestone(row);
  const hasCredit = useMemo(() => row.requested_amount < 0, [row]);

  return row.activeToEdit && creditAvalible ? (
    <IconButton
      onClick={updateRightDrawer?.({
        milestoneId: row?.id,
        milestoneName: row?.name,
        tab: RequestTableTabsEnum.CREDIT_APPROVE,
      })}
      data-cy={`${source}__body__credit__icon__index_${row.project_milestone?.index}`}
    >
      <FundingIconWithIndicator hasCredit={hasCredit} />
    </IconButton>
  ) : showCreditInfo ? (
    <IconButton
      onClick={updateRightDrawer?.({
        milestoneId: row?.id,
        milestoneName: row?.name,
        tab: RequestTableTabsEnum.CREDIT_INFO,
      })}
      data-cy={`${source}__body__credit_info__icon__index_${row.project_milestone?.index}`}
    >
      <InfoIcon />
    </IconButton>
  ) : null;
};

const Memoized = memo(ApproveCredit);

const approveCredit: MilestoneListColumnTypeV2 = {
  field: 'credit_reason',
  headerAlign: 'center',
  headerClassName: 'icon',
  cellClassName: 'icon',
  editable: false,
  renderHeader: () => null,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default approveCredit;
