import { useQuery } from 'react-query';
import { useMemo } from 'react';
import {
  IDrawRequest,
  IRequestReview,
  QueryNamesEnums,
  RequestReviewStatusEnum,
} from '@interfaces';
import { getRequestReviews } from '@globalService';
import { TEAM_ROLES } from '@constants';

interface ControllerInterface {
  approvalReviews: IRequestReview[];
  fundReviews: IRequestReview[];
  isLoading: boolean;
}
export const useApprovalsSummary = (
  request: IDrawRequest,
  projectId: string,
): ControllerInterface => {
  const restQlParams = '{status,created_by_team{name,role},created_by{id,full_name},created_at}';
  const reviewsQuery = useQuery<{ results: IRequestReview[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_REVIEWS,
      { projectId, drawRequestId: request?.id, restQlParams },
    ],
    getRequestReviews.bind(this, { projectId, drawRequestId: request?.id, restQlParams }),
    { enabled: Boolean(projectId && request?.id) },
  );

  const approvalReviews = useMemo(
    () =>
      reviewsQuery.data?.results?.filter(
        (review) =>
          review.created_by_team?.role !== TEAM_ROLES.Owner &&
          review.status === RequestReviewStatusEnum.APPROVE,
      ),
    [reviewsQuery],
  );

  const fundReviews = useMemo(
    () =>
      reviewsQuery.data?.results?.filter(
        (review) =>
          review.created_by_team?.role !== TEAM_ROLES.Owner &&
          review.status === RequestReviewStatusEnum.FUND,
      ),
    [reviewsQuery],
  );

  return {
    approvalReviews,
    fundReviews,
    isLoading: reviewsQuery.isLoading,
  };
};
