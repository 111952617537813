import React from 'react';
import { ComponentProps } from './interface';

const Image = ({ size = '30rem' }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 420 330"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M132.991 262.546C135.901 256.262 138.808 249.884 140.071 243.075C141.334 236.265 140.804 228.875 137.187 222.969C133.57 217.063 126.45 213.058 119.654 214.393C114.073 215.489 109.591 219.885 106.967 224.932C104.343 229.979 103.29 235.671 102.269 241.267C101.939 234.909 101.59 228.45 99.4781 222.444C97.3663 216.439 93.2079 210.843 87.2814 208.519C81.3549 206.195 73.6454 208.023 70.5218 213.569C66.1212 221.384 71.9562 232.345 66.8961 239.75C66.0281 232.322 58.2611 226.454 50.8793 227.649C43.4974 228.843 37.9772 236.861 39.4958 244.183C40.3994 248.54 43.372 252.246 46.9259 254.923C50.4799 257.6 54.6133 260.703 58.7022 262.458L132.991 262.546Z"
      fill="#E8E8E8"
    />
    <path
      d="M43.3714 232.896C49.2352 234.946 55.1528 237.018 60.5061 240.223C65.3039 243.095 69.5763 246.961 72.2745 251.913C73.7529 254.57 74.6307 257.518 74.846 260.551C74.8849 261.185 75.8772 261.189 75.8379 260.551C75.491 254.903 72.7389 249.735 68.9279 245.648C64.7497 241.167 59.3351 238.106 53.7448 235.732C50.4302 234.324 47.0333 233.127 43.6352 231.94C43.0313 231.729 42.7717 232.687 43.3715 232.896H43.3714Z"
      fill="white"
    />
    <path
      d="M77.1943 209.504C80.7968 215.505 83.6817 221.91 85.7896 228.585C87.9017 235.258 89.2321 242.153 89.7541 249.132C90.043 253.039 90.0746 256.961 89.8486 260.873C89.8117 261.51 90.8039 261.508 90.8405 260.873C91.2407 253.821 90.8139 246.746 89.5689 239.794C88.3258 232.842 86.2827 226.058 83.4804 219.575C81.9074 215.935 80.0935 212.403 78.0508 209.003C77.9821 208.894 77.8738 208.816 77.7488 208.786C77.6237 208.755 77.4917 208.774 77.3806 208.839C77.2695 208.904 77.188 209.01 77.1532 209.134C77.1185 209.258 77.1332 209.39 77.1943 209.504V209.504Z"
      fill="white"
    />
    <path
      d="M125.745 215.202C120.65 226.651 117.064 238.714 115.078 251.088C114.52 254.563 114.088 258.056 113.783 261.566C113.727 262.202 114.719 262.198 114.774 261.566C115.855 249.22 118.523 237.065 122.713 225.401C123.891 222.122 125.187 218.889 126.602 215.702C126.859 215.124 126.004 214.619 125.745 215.202V215.202Z"
      fill="white"
    />
    <path
      d="M301.681 276.059L301.602 269.9L272.568 270.276L272.647 276.434L301.681 276.059Z"
      fill="#E8E8E8"
    />
    <path
      d="M311.998 277.433L307.852 277.421L305.289 272.848L308.059 268.29L311.911 268.301C310.946 267.074 309.622 266.18 308.123 265.743C306.624 265.307 305.026 265.35 303.553 265.868C302.08 266.385 300.806 267.35 299.91 268.628C299.013 269.907 298.539 271.433 298.554 272.994C298.569 274.555 299.072 276.072 299.992 277.333C300.913 278.594 302.205 279.535 303.688 280.025C305.17 280.514 306.768 280.527 308.259 280.062C309.749 279.598 311.056 278.678 311.998 277.433V277.433Z"
      fill="#E8E8E8"
    />
    <path
      d="M262.251 277.433L266.397 277.421L268.96 272.848L266.19 268.29L262.338 268.301C263.302 267.074 264.627 266.18 266.126 265.743C267.625 265.307 269.223 265.35 270.696 265.868C272.168 266.385 273.442 267.35 274.339 268.628C275.235 269.907 275.71 271.433 275.695 272.994C275.68 274.555 275.177 276.072 274.256 277.333C273.336 278.594 272.044 279.535 270.561 280.025C269.079 280.514 267.48 280.527 265.99 280.062C264.5 279.598 263.192 278.678 262.251 277.433V277.433Z"
      fill="#E8E8E8"
    />
    <path
      d="M430.399 263.081H1.60079C1.43549 263.081 1.27697 263.016 1.16009 262.899C1.0432 262.782 0.977539 262.624 0.977539 262.458C0.977539 262.293 1.0432 262.134 1.16009 262.017C1.27697 261.901 1.43549 261.835 1.60079 261.835H430.399C430.564 261.835 430.723 261.901 430.839 262.017C430.956 262.134 431.022 262.293 431.022 262.458C431.022 262.624 430.956 262.782 430.839 262.899C430.723 263.016 430.564 263.081 430.399 263.081Z"
      fill="#262626"
    />
    <path
      d="M337.837 144.663C349.196 144.663 358.404 135.455 358.404 124.096C358.404 112.737 349.196 103.529 337.837 103.529C326.478 103.529 317.27 112.737 317.27 124.096C317.27 135.455 326.478 144.663 337.837 144.663Z"
      fill="#262626"
    />
    <path
      d="M346.172 239.558L347.756 247.032L377.342 244.48L375.004 233.448L346.172 239.558Z"
      fill="#FAC9AD"
    />
    <path
      d="M343.87 262.919L338.892 239.427L347.968 237.503L351.023 251.919C351.534 254.327 351.066 256.838 349.725 258.901C348.383 260.964 346.277 262.409 343.87 262.919L343.87 262.919Z"
      fill="#262626"
    />
    <path
      d="M327.637 239.159L324.307 246.036L296.195 236.465L301.11 226.315L327.637 239.159Z"
      fill="#FAC9AD"
    />
    <path
      d="M319.964 249.997L326.386 236.734L334.737 240.777L324.272 262.392C323.175 261.861 322.194 261.119 321.384 260.209C320.574 259.298 319.951 258.237 319.551 257.086C319.151 255.936 318.982 254.717 319.053 253.501C319.124 252.284 319.433 251.094 319.964 249.997V249.997Z"
      fill="#262626"
    />
    <path
      d="M272.254 225.576C273.217 225.756 274.206 225.722 275.154 225.477C276.102 225.232 276.984 224.782 277.74 224.16C278.495 223.537 279.104 222.756 279.525 221.873C279.946 220.989 280.168 220.023 280.175 219.045L326.529 176.308L315.017 167.392L273.049 212.231C271.404 212.327 269.851 213.024 268.686 214.189C267.52 215.353 266.822 216.905 266.724 218.55C266.626 220.196 267.135 221.819 268.154 223.114C269.173 224.409 270.632 225.285 272.254 225.576H272.254Z"
      fill="#FAC9AD"
    />
    <path
      d="M335.256 145.592C343.71 145.592 350.564 138.739 350.564 130.284C350.564 121.83 343.71 114.977 335.256 114.977C326.802 114.977 319.948 121.83 319.948 130.284C319.948 138.739 326.802 145.592 335.256 145.592Z"
      fill="#FAC9AD"
    />
    <path
      d="M348.993 220.266C338.255 220.266 325.323 218.005 317.345 208.75L317.166 208.542L317.35 208.337C317.411 208.271 323.28 201.563 317.42 189.602L309.362 192.081L301.341 181.492L305.784 168.162L323.969 153.513C326.575 151.425 329.747 150.168 333.075 149.905C339.099 149.322 344.966 147.651 350.392 144.973C352.875 143.767 355.61 143.169 358.37 143.229L358.727 143.239C359.567 143.262 360.393 143.456 361.155 143.809C361.917 144.161 362.6 144.664 363.161 145.288C363.723 145.912 364.152 146.644 364.423 147.439C364.694 148.234 364.8 149.075 364.736 149.912C363.503 165.886 361.325 204.474 367.722 217.76L367.888 218.104L367.518 218.197C361.438 219.565 355.225 220.259 348.993 220.266V220.266Z"
      fill="#1F4739"
    />
    <path
      d="M319.451 208.547C319.451 208.547 278.94 204.807 274.577 216.649C270.214 228.491 275.2 234.1 282.679 236.593C290.158 239.086 308.233 242.202 308.233 242.202L316.335 232.23L337.525 233.477C337.525 233.477 361.137 247.172 367.666 261.818C369.066 264.988 371.3 267.719 374.129 269.721C376.958 271.722 380.276 272.92 383.732 273.186C388.966 273.55 393.618 271.573 393.618 263.393C393.618 244.695 367.442 217.895 367.442 217.895L319.451 208.547Z"
      fill="#262626"
    />
    <path d="M288.601 226.933C288.601 226.933 299.196 223.816 316.024 231.919Z" fill="#262626" />
    <path
      d="M319.985 120.522C325.964 124.172 332.703 126.392 339.68 127.011L337.604 124.524C339.115 125.083 340.706 125.399 342.316 125.46C343.122 125.483 343.923 125.317 344.654 124.975C345.384 124.633 346.024 124.125 346.523 123.491C346.93 122.832 347.172 122.085 347.228 121.313C347.284 120.541 347.152 119.767 346.844 119.056C346.212 117.64 345.227 116.408 343.984 115.48C341.781 113.737 339.18 112.566 336.415 112.072C333.649 111.578 330.805 111.775 328.134 112.647C326.302 113.195 324.662 114.247 323.4 115.684C322.78 116.408 322.349 117.274 322.147 118.205C321.944 119.136 321.976 120.103 322.239 121.019"
      fill="#262626"
    />
    <path
      d="M337.727 108.287C340.447 101.972 344.518 96.3294 349.654 91.7573C352.952 88.8263 356.804 86.3082 361.15 85.5481C365.497 84.7879 370.395 86.0902 373.061 89.6062C375.241 92.4812 375.649 96.3328 375.409 99.9328C375.169 103.533 374.364 107.098 374.441 110.705C374.52 114.386 375.516 117.989 377.34 121.186C379.165 124.384 381.758 127.076 384.887 129.017C388.015 130.958 391.578 132.087 395.254 132.301C398.929 132.516 402.599 131.809 405.932 130.245C402.179 132.32 399.255 135.603 395.77 138.103C392.286 140.602 387.691 142.316 383.734 140.665C379.547 138.917 377.626 134.171 376.126 129.889L369.438 110.792C368.301 107.546 367.107 104.204 364.787 101.665C362.467 99.1257 358.701 97.5781 355.507 98.8546C353.086 99.8221 351.51 102.131 350.128 104.342C348.746 106.552 347.288 108.904 344.953 110.063C342.618 111.222 339.158 110.508 338.395 108.016"
      fill="#262626"
    />
    <path
      d="M337.3 200.895L333.002 205.306L353.798 225.571L358.096 221.159L337.3 200.895Z"
      fill="#E8E8E8"
    />
    <path
      d="M354.057 221.523C353.206 222.395 352.634 223.5 352.412 224.698C352.19 225.896 352.328 227.132 352.808 228.252C353.289 229.371 354.091 230.323 355.112 230.986C356.134 231.65 357.329 231.996 358.547 231.98C359.766 231.964 360.952 231.588 361.956 230.898C362.96 230.208 363.737 229.236 364.188 228.105C364.64 226.973 364.746 225.733 364.493 224.542C364.24 223.35 363.639 222.26 362.766 221.41C361.596 220.27 360.022 219.641 358.388 219.663C356.755 219.684 355.197 220.353 354.057 221.523V221.523ZM360.358 227.664C359.994 228.037 359.527 228.295 359.016 228.403C358.506 228.512 357.974 228.466 357.489 228.273C357.004 228.079 356.588 227.746 356.292 227.316C355.997 226.886 355.835 226.377 355.828 225.855C355.822 225.333 355.97 224.821 356.254 224.383C356.539 223.946 356.946 223.602 357.426 223.396C357.906 223.19 358.436 223.131 358.949 223.226C359.463 223.321 359.936 223.566 360.31 223.931C360.811 224.419 361.098 225.087 361.107 225.787C361.116 226.487 360.847 227.162 360.358 227.664Z"
      fill="#E8E8E8"
    />
    <path
      d="M330.978 192.628L333.901 195.569L332.48 200.615L327.298 201.878L324.582 199.146C324.397 200.696 324.701 202.266 325.452 203.634C326.203 205.003 327.364 206.102 328.771 206.777C330.179 207.453 331.762 207.671 333.3 207.401C334.838 207.132 336.252 206.387 337.346 205.273C338.439 204.159 339.156 202.73 339.397 201.188C339.637 199.645 339.389 198.066 338.687 196.672C337.985 195.278 336.864 194.138 335.481 193.413C334.099 192.688 332.524 192.413 330.978 192.628V192.628Z"
      fill="#E8E8E8"
    />
    <path
      d="M342.979 220.32C342.715 220.32 342.451 220.305 342.189 220.276C340.65 220.098 339.217 219.404 338.122 218.309C337.027 217.214 336.334 215.781 336.155 214.242C336.046 213.282 336.141 212.31 336.433 211.389C336.725 210.469 337.209 209.62 337.852 208.899C338.496 208.178 339.284 207.602 340.165 207.207C341.047 206.812 342.002 206.607 342.968 206.607C343.274 206.609 343.579 206.632 343.882 206.675L364.81 184.397L362.625 177.115L373.598 172.196L376.673 179.713C377.703 182.259 377.885 185.07 377.192 187.727C376.498 190.385 374.966 192.748 372.823 194.467L349.787 212.791C349.811 213.014 349.823 213.238 349.824 213.463C349.823 214.427 349.62 215.38 349.226 216.261C348.833 217.141 348.259 217.928 347.541 218.572C346.29 219.701 344.664 220.324 342.979 220.32Z"
      fill="#FAC9AD"
    />
    <path
      d="M359.34 146.221C360.065 145.899 360.849 145.726 361.643 145.714C362.437 145.702 363.225 145.851 363.96 146.151C364.695 146.451 365.362 146.897 365.921 147.461C366.479 148.025 366.918 148.697 367.21 149.435L378.037 176.761L361.21 187.979L359.34 146.221Z"
      fill="#1F4739"
    />
    <path
      d="M280.498 152.602V16.456C280.492 12.282 278.831 8.28069 275.88 5.32925C272.929 2.37781 268.927 0.717087 264.753 0.711182H50.8092C46.6353 0.717089 42.634 2.37781 39.6825 5.32925C36.7311 8.28069 35.0704 12.282 35.0645 16.456V152.602C35.0704 156.776 36.7311 160.777 39.6825 163.729C42.634 166.68 46.6353 168.341 50.8092 168.347H264.753C268.927 168.341 272.929 166.68 275.88 163.729C278.831 160.777 280.492 156.776 280.498 152.602ZM50.8092 166.495C47.1261 166.49 43.5951 165.025 40.9907 162.421C38.3864 159.816 36.9213 156.285 36.9168 152.602V16.456C36.9213 12.7728 38.3864 9.24184 40.9907 6.63747C43.5951 4.03309 47.1261 2.56798 50.8092 2.5635H264.753C268.436 2.56798 271.967 4.03309 274.572 6.63747C277.176 9.24184 278.641 12.7728 278.646 16.456V152.602C278.641 156.285 277.176 159.816 274.572 162.421C271.967 165.025 268.436 166.49 264.753 166.495H50.8092Z"
      fill="#262626"
    />
    <path
      d="M278.645 25.207H36.9164C36.6708 25.207 36.4352 25.1095 36.2615 24.9358C36.0878 24.7621 35.9902 24.5265 35.9902 24.2809C35.9902 24.0353 36.0878 23.7997 36.2615 23.626C36.4352 23.4523 36.6708 23.3547 36.9164 23.3547H278.645C278.891 23.3547 279.127 23.4523 279.3 23.626C279.474 23.7997 279.571 24.0353 279.571 24.2809C279.571 24.5265 279.474 24.7621 279.3 24.9358C279.127 25.1095 278.891 25.207 278.645 25.207Z"
      fill="#262626"
    />
    <path
      d="M52.6611 18.3083C55.2186 18.3083 57.2919 16.235 57.2919 13.6775C57.2919 11.1199 55.2186 9.04663 52.6611 9.04663C50.1036 9.04663 48.0303 11.1199 48.0303 13.6775C48.0303 16.235 50.1036 18.3083 52.6611 18.3083Z"
      fill="#1F4739"
    />
    <path
      d="M68.4062 18.3083C70.9638 18.3083 73.0371 16.235 73.0371 13.6775C73.0371 11.1199 70.9638 9.04663 68.4062 9.04663C65.8487 9.04663 63.7754 11.1199 63.7754 13.6775C63.7754 16.235 65.8487 18.3083 68.4062 18.3083Z"
      fill="#1F4739"
    />
    <path
      d="M84.1513 18.3083C86.7089 18.3083 88.7822 16.235 88.7822 13.6775C88.7822 11.1199 86.7089 9.04663 84.1513 9.04663C81.5938 9.04663 79.5205 11.1199 79.5205 13.6775C79.5205 16.235 81.5938 18.3083 84.1513 18.3083Z"
      fill="#1F4739"
    />
    <path
      d="M118.759 124.883C118.677 124.883 118.596 124.867 118.521 124.835C118.445 124.804 118.376 124.758 118.318 124.7C118.261 124.642 118.215 124.574 118.183 124.498C118.152 124.422 118.136 124.341 118.136 124.259C118.136 117.761 115.287 114.136 110.691 110.394C110.627 110.342 110.574 110.279 110.535 110.207C110.496 110.135 110.472 110.055 110.463 109.974C110.455 109.892 110.463 109.81 110.486 109.731C110.509 109.653 110.548 109.58 110.6 109.516C110.652 109.453 110.715 109.4 110.788 109.361C110.86 109.322 110.939 109.298 111.021 109.29C111.102 109.281 111.184 109.289 111.263 109.313C111.341 109.337 111.415 109.375 111.478 109.427C116.358 113.401 119.382 117.268 119.382 124.259C119.382 124.341 119.366 124.422 119.335 124.498C119.304 124.574 119.258 124.642 119.2 124.7C119.142 124.758 119.073 124.804 118.998 124.835C118.922 124.867 118.841 124.883 118.759 124.883Z"
      fill="#042419"
    />
    <path
      d="M65.3703 124.883C65.2885 124.883 65.2074 124.867 65.1318 124.835C65.0562 124.804 64.9874 124.758 64.9296 124.7C64.8717 124.642 64.8258 124.574 64.7945 124.498C64.7632 124.422 64.747 124.341 64.7471 124.259C64.7471 117.268 67.7714 113.401 72.6516 109.427C72.715 109.375 72.788 109.337 72.8665 109.313C72.945 109.289 73.0274 109.281 73.109 109.29C73.1906 109.298 73.2697 109.322 73.3419 109.361C73.4141 109.4 73.4779 109.453 73.5296 109.516C73.5814 109.58 73.6201 109.653 73.6436 109.731C73.667 109.81 73.6747 109.892 73.6662 109.974C73.6578 110.055 73.6333 110.135 73.5942 110.207C73.5552 110.279 73.5022 110.342 73.4385 110.394C68.8423 114.136 65.9936 117.762 65.9936 124.259C65.9936 124.341 65.9775 124.422 65.9462 124.498C65.9149 124.574 65.869 124.642 65.8111 124.7C65.7532 124.758 65.6845 124.804 65.6089 124.835C65.5332 124.867 65.4522 124.883 65.3703 124.883Z"
      fill="#042419"
    />
    <path
      d="M113.421 82.171C113.293 82.1711 113.167 82.1313 113.062 82.0571C112.957 81.9829 112.877 81.878 112.834 81.7568C112.791 81.6355 112.786 81.5039 112.821 81.3799C112.856 81.256 112.928 81.1458 113.028 81.0645C117.752 77.2182 118.136 71.7541 118.136 65.531C118.136 65.3657 118.202 65.2072 118.319 65.0903C118.436 64.9734 118.594 64.9077 118.76 64.9077C118.925 64.9077 119.084 64.9734 119.2 65.0903C119.317 65.2072 119.383 65.3657 119.383 65.531C119.383 72.0782 118.964 77.8387 113.815 82.031C113.704 82.1218 113.565 82.1713 113.421 82.171V82.171Z"
      fill="#042419"
    />
    <path
      d="M70.7091 81.8375C70.5658 81.8377 70.4268 81.7883 70.3159 81.6975C65.1661 77.5049 64.7471 71.8956 64.7471 65.531C64.7471 65.3657 64.8127 65.2072 64.9296 65.0903C65.0465 64.9734 65.205 64.9077 65.3703 64.9077C65.5356 64.9077 65.6942 64.9734 65.811 65.0903C65.9279 65.2072 65.9936 65.3657 65.9936 65.531C65.9936 71.7863 66.3697 76.8776 71.1029 80.731C71.2027 80.8122 71.2748 80.9224 71.3095 81.0464C71.3442 81.1703 71.3396 81.302 71.2965 81.4232C71.2533 81.5445 71.1737 81.6494 71.0685 81.7236C70.9633 81.7977 70.8378 81.8375 70.7091 81.8375V81.8375Z"
      fill="#042419"
    />
    <path
      d="M126.767 95.5402H113.42C113.255 95.5402 113.096 95.4746 112.979 95.3577C112.863 95.2408 112.797 95.0823 112.797 94.917C112.797 94.7517 112.863 94.5931 112.979 94.4763C113.096 94.3594 113.255 94.2937 113.42 94.2937H126.767C126.933 94.2937 127.091 94.3594 127.208 94.4763C127.325 94.5931 127.391 94.7517 127.391 94.917C127.391 95.0823 127.325 95.2408 127.208 95.3577C127.091 95.4746 126.933 95.5402 126.767 95.5402Z"
      fill="#042419"
    />
    <path
      d="M70.7088 95.5402H57.3615C57.1962 95.5402 57.0377 95.4746 56.9208 95.3577C56.804 95.2408 56.7383 95.0823 56.7383 94.917C56.7383 94.7517 56.804 94.5931 56.9208 94.4763C57.0377 94.3594 57.1962 94.2937 57.3615 94.2937H70.7088C70.8741 94.2937 71.0327 94.3594 71.1496 94.4763C71.2664 94.5931 71.3321 94.7517 71.3321 94.917C71.3321 95.0823 71.2664 95.2408 71.1496 95.3577C71.0327 95.4746 70.8741 95.5402 70.7088 95.5402Z"
      fill="#042419"
    />
    <path
      d="M92.0647 122.213C91.9828 122.213 91.9018 122.197 91.8261 122.166C91.7505 122.135 91.6818 122.089 91.6239 122.031C91.566 121.973 91.5201 121.904 91.4888 121.829C91.4575 121.753 91.4414 121.672 91.4414 121.59V78.8786C91.4414 78.7133 91.5071 78.5548 91.624 78.4379C91.7408 78.321 91.8994 78.2554 92.0647 78.2554C92.23 78.2554 92.3885 78.321 92.5054 78.4379C92.6223 78.5548 92.6879 78.7133 92.6879 78.8786V121.59C92.6879 121.672 92.6718 121.753 92.6405 121.829C92.6092 121.904 92.5633 121.973 92.5054 122.031C92.4475 122.089 92.3788 122.135 92.3032 122.166C92.2276 122.197 92.1465 122.213 92.0647 122.213V122.213Z"
      fill="#042419"
    />
    <path
      d="M92.0647 122.213C86.2376 122.207 80.651 119.889 76.5307 115.768C72.4103 111.648 70.0925 106.061 70.0859 100.234V84.2057C70.0859 73.9418 78.5078 67.5657 92.0647 67.5657C106.238 67.5657 114.044 73.4753 114.044 84.2057V100.234C114.037 106.061 111.719 111.648 107.599 115.768C103.479 119.889 97.8919 122.207 92.0647 122.213V122.213ZM92.0647 68.8122C82.0421 68.8122 71.3324 72.856 71.3324 84.2057V100.234C71.3309 102.958 71.8661 105.655 72.9073 108.172C73.9486 110.688 75.4755 112.975 77.4008 114.902C79.3261 116.828 81.6122 118.356 84.1283 119.399C86.6444 120.441 89.3413 120.978 92.0649 120.978C94.7884 120.978 97.4853 120.441 100.001 119.399C102.518 118.356 104.804 116.828 106.729 114.902C108.654 112.975 110.181 110.688 111.222 108.172C112.264 105.655 112.799 102.958 112.797 100.234V84.2057C112.797 70.8122 99.8076 68.8122 92.0647 68.8122V68.8122Z"
      fill="#042419"
    />
    <path
      d="M79.2898 71.4136C79.1651 71.4136 79.0432 71.3762 78.94 71.3062C78.8367 71.2362 78.7569 71.1368 78.7107 71.021C78.3507 70.12 78.1426 69.1656 78.0947 68.1966C78.1056 64.5015 79.576 60.9604 82.1856 58.3445C84.7953 55.7286 88.3328 54.2497 92.0278 54.23H92.0994C95.7896 54.2476 99.3237 55.7212 101.933 58.3305C104.543 60.9398 106.017 64.4737 106.035 68.164C106.003 69.0655 105.811 69.9541 105.468 70.7885C105.404 70.9402 105.283 71.0606 105.131 71.1234C104.979 71.1862 104.808 71.1863 104.656 71.1237C104.504 71.0611 104.382 70.9409 104.319 70.7893C104.255 70.6377 104.253 70.4669 104.315 70.3143C104.6 69.6234 104.76 68.8876 104.789 68.1409C104.767 64.7842 103.422 61.5717 101.046 59.2007C98.6699 56.8296 95.4545 55.4914 92.0978 55.4765H92.0294C88.6689 55.4932 85.4509 56.8357 83.0748 59.212C80.6987 61.5884 79.3566 64.8066 79.3403 68.167C79.3845 68.9878 79.5629 69.7959 79.8683 70.559C79.9059 70.6536 79.9198 70.7559 79.9088 70.857C79.8978 70.9582 79.8622 71.0551 79.8052 71.1394C79.7481 71.2236 79.6714 71.2927 79.5816 71.3404C79.4917 71.3882 79.3916 71.4133 79.2898 71.4136H79.2898Z"
      fill="#042419"
    />
    <path
      d="M155.364 68.0241H147.885C146.728 68.0241 145.618 67.5645 144.8 66.7463C143.982 65.9281 143.522 64.8184 143.522 63.6613C143.522 62.5043 143.982 61.3946 144.8 60.5764C145.618 59.7582 146.728 59.2986 147.885 59.2986H155.364C156.521 59.2986 157.631 59.7582 158.449 60.5764C159.267 61.3946 159.727 62.5043 159.727 63.6613C159.727 64.8184 159.267 65.9281 158.449 66.7463C157.631 67.5645 156.521 68.0241 155.364 68.0241V68.0241Z"
      fill="#D2D2D2"
    />
    <path
      d="M206.471 86.4101H198.992C197.835 86.4101 196.725 85.9505 195.907 85.1323C195.089 84.3141 194.629 83.2044 194.629 82.0473C194.629 80.8903 195.089 79.7806 195.907 78.9624C196.725 78.1442 197.835 77.6846 198.992 77.6846H206.471C207.628 77.6846 208.737 78.1442 209.556 78.9624C210.374 79.7806 210.833 80.8903 210.833 82.0473C210.833 83.2044 210.374 84.3141 209.556 85.1323C208.737 85.9505 207.628 86.4101 206.471 86.4101V86.4101Z"
      fill="#D2D2D2"
    />
    <path
      d="M230.154 86.4101H222.675C221.518 86.4101 220.408 85.9505 219.59 85.1323C218.772 84.3141 218.312 83.2044 218.312 82.0473C218.312 80.8903 218.772 79.7806 219.59 78.9624C220.408 78.1442 221.518 77.6846 222.675 77.6846H230.154C231.311 77.6846 232.421 78.1442 233.239 78.9624C234.057 79.7806 234.517 80.8903 234.517 82.0473C234.517 83.2044 234.057 84.3141 233.239 85.1323C232.421 85.9505 231.311 86.4101 230.154 86.4101V86.4101Z"
      fill="#D2D2D2"
    />
    <path
      d="M253.838 86.4101H246.359C245.202 86.4101 244.092 85.9505 243.274 85.1323C242.456 84.3141 241.996 83.2044 241.996 82.0473C241.996 80.8903 242.456 79.7806 243.274 78.9624C244.092 78.1442 245.202 77.6846 246.359 77.6846H253.838C254.995 77.6846 256.105 78.1442 256.923 78.9624C257.741 79.7806 258.201 80.8903 258.201 82.0473C258.201 83.2044 257.741 84.3141 256.923 85.1323C256.105 85.9505 254.995 86.4101 253.838 86.4101V86.4101Z"
      fill="#D2D2D2"
    />
    <path
      d="M207.094 68.3356H172.192C171.035 68.3356 169.925 67.876 169.107 67.0578C168.289 66.2396 167.829 65.13 167.829 63.9729C167.829 62.8158 168.289 61.7061 169.107 60.8879C169.925 60.0698 171.035 59.6101 172.192 59.6101H207.094C208.251 59.6101 209.361 60.0698 210.179 60.8879C210.997 61.7061 211.457 62.8158 211.457 63.9729C211.457 65.13 210.997 66.2396 210.179 67.0578C209.361 67.876 208.251 68.3356 207.094 68.3356Z"
      fill="#D2D2D2"
    />
    <path
      d="M182.787 85.7868H147.885C146.728 85.7868 145.618 85.3272 144.8 84.509C143.982 83.6908 143.522 82.5811 143.522 81.424C143.522 80.267 143.982 79.1573 144.8 78.3391C145.618 77.5209 146.728 77.0613 147.885 77.0613H182.787C183.944 77.0613 185.054 77.5209 185.872 78.3391C186.691 79.1573 187.15 80.267 187.15 81.424C187.15 82.5811 186.691 83.6908 185.872 84.509C185.054 85.3272 183.944 85.7868 182.787 85.7868Z"
      fill="#D2D2D2"
    />
    <path
      d="M251.345 68.3356H224.545C223.388 68.3356 222.279 67.876 221.46 67.0578C220.642 66.2396 220.183 65.13 220.183 63.9729C220.183 62.8158 220.642 61.7061 221.46 60.8879C222.279 60.0698 223.388 59.6101 224.545 59.6101H251.345C252.502 59.6101 253.612 60.0698 254.43 60.8879C255.248 61.7061 255.708 62.8158 255.708 63.9729C255.708 65.13 255.248 66.2396 254.43 67.0578C253.612 67.876 252.502 68.3356 251.345 68.3356Z"
      fill="#D2D2D2"
    />
    <path
      d="M155.364 104.796H147.885C146.728 104.796 145.618 104.336 144.8 103.518C143.982 102.7 143.522 101.59 143.522 100.433C143.522 99.2763 143.982 98.1666 144.8 97.3484C145.618 96.5302 146.728 96.0706 147.885 96.0706H155.364C156.521 96.0706 157.631 96.5302 158.449 97.3484C159.267 98.1666 159.727 99.2763 159.727 100.433C159.727 101.59 159.267 102.7 158.449 103.518C157.631 104.336 156.521 104.796 155.364 104.796V104.796Z"
      fill="#D2D2D2"
    />
    <path
      d="M207.094 105.108H172.192C171.035 105.108 169.925 104.648 169.107 103.83C168.289 103.012 167.829 101.902 167.829 100.745C167.829 99.5878 168.289 98.4781 169.107 97.6599C169.925 96.8417 171.035 96.3821 172.192 96.3821H207.094C208.251 96.3821 209.361 96.8417 210.179 97.6599C210.997 98.4781 211.457 99.5878 211.457 100.745C211.457 101.902 210.997 103.012 210.179 103.83C209.361 104.648 208.251 105.108 207.094 105.108V105.108Z"
      fill="#D2D2D2"
    />
    <path
      d="M251.345 105.108H224.545C223.388 105.108 222.279 104.648 221.46 103.83C220.642 103.012 220.183 101.902 220.183 100.745C220.183 99.5878 220.642 98.4781 221.46 97.6599C222.279 96.8417 223.388 96.3821 224.545 96.3821H251.345C252.502 96.3821 253.612 96.8417 254.43 97.6599C255.248 98.4781 255.708 99.5878 255.708 100.745C255.708 101.902 255.248 103.012 254.43 103.83C253.612 104.648 252.502 105.108 251.345 105.108V105.108Z"
      fill="#D2D2D2"
    />
    <path
      d="M239.503 119.754C239.504 118.597 239.964 117.489 240.782 116.671C241.6 115.853 242.709 115.393 243.866 115.391H251.345C252.502 115.391 253.611 115.851 254.43 116.669C255.248 117.487 255.707 118.597 255.707 119.754C255.707 120.911 255.248 122.021 254.43 122.839C253.611 123.657 252.502 124.117 251.345 124.117H243.866C242.709 124.116 241.6 123.656 240.782 122.838C239.964 122.02 239.504 120.911 239.503 119.754V119.754Z"
      fill="#D2D2D2"
    />
    <path
      d="M187.773 120.066C187.775 118.909 188.235 117.8 189.053 116.982C189.871 116.164 190.98 115.704 192.136 115.703H227.038C228.195 115.703 229.305 116.163 230.123 116.981C230.941 117.799 231.401 118.909 231.401 120.066C231.401 121.223 230.941 122.332 230.123 123.151C229.305 123.969 228.195 124.428 227.038 124.428H192.136C190.98 124.427 189.871 123.967 189.053 123.149C188.235 122.331 187.775 121.222 187.773 120.066Z"
      fill="#D2D2D2"
    />
    <path
      d="M143.522 120.066C143.524 118.909 143.984 117.8 144.802 116.982C145.62 116.164 146.729 115.704 147.885 115.703H174.685C175.842 115.703 176.952 116.163 177.77 116.981C178.588 117.799 179.048 118.909 179.048 120.066C179.048 121.223 178.588 122.332 177.77 123.151C176.952 123.969 175.842 124.428 174.685 124.428H147.885C146.729 124.427 145.62 123.967 144.802 123.149C143.984 122.331 143.524 121.222 143.522 120.066Z"
      fill="#D2D2D2"
    />
  </svg>
);

export default Image;
