import React, { FC, useState } from 'react';
import { Typography, Box, Stack, Skeleton, Divider } from '@mui/material';

import { HookState } from '@interfaces';
import { InfoCard, StyledLink } from '@components';
import { colors } from '@theme';
import * as Controller from './controller';

const CompanyProjectsInfoLink: FC<{
  companyId: string;
}> = ({ companyId }) => {
  const { state, projects, showEllipsis, count } =
    Controller.useCompanyProjectsInfoModal(companyId);
  const [open, setOpen] = useState(false);

  return (
    <>
      {state === HookState.FETCHING && (
        <Stack spacing={2} direction="row">
          <Skeleton width={20} height={20} />
        </Stack>
      )}
      {state === HookState.SUCCESS && (
        <Box onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
          <Typography variant="body2SemiBold" color={colors.text.link}>
            {count}
          </Typography>
        </Box>
      )}
      {open && (
        <InfoCard open={open} handleClose={() => setOpen(false)} title="Company projects">
          <Stack sx={{ flexWrap: 'wrap', py: 2, lineHeight: 2 }}>
            {projects?.map((project, index) => (
              <Stack key={project.id}>
                <StyledLink to={`/projects/${project.id}/overview/`} sx={{ whiteSpace: 'nowrap' }}>
                  {project.name}
                </StyledLink>
                {index < projects?.length - 1 && (
                  <Box sx={{ my: 1 }}>
                    <Divider />
                  </Box>
                )}
              </Stack>
            ))}
            {showEllipsis && (
              <>
                <Box sx={{ my: 1 }}>
                  <Divider />
                </Box>
                <Typography variant="body2SemiBold" color={colors.text.link}>
                  &hellip;
                </Typography>
              </>
            )}
          </Stack>
        </InfoCard>
      )}
    </>
  );
};

export default CompanyProjectsInfoLink;
