import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { AuditLog, DotsVerticalMenu, FilterButton, RightDrawer } from '@components';
import { ActivityLogIcon, ChangeStatusIcon, SettingsIcon, SortIcon } from '@svgAsComponents';
import * as Controller from './controller';
import { UpdateProjectStatus } from './components';
import { colors } from '@theme';
import { AuditLogTags } from '@constants';
import { getArrayFromObject } from '@utils';

const ProjectSettingsOptionsMenu: FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const controller = Controller.useProjectOptions();
  const {
    changeStatusComponent,
    valuesBasedOnPermissions,
    rightMenu,
    handleRightDrawerOpenerClick,
    filters,
    handleFiltersChange,
    handleSortClick,
    sorting,
    showAuditLog,
  } = controller || {};

  const menuItems = [
    ...(!isEmpty(valuesBasedOnPermissions.statusesList)
      ? [
          {
            action() {
              changeStatusComponent.handleOpen(true);
            },
            dataTestName: 'project__options_menu__status__update__button',
            text: valuesBasedOnPermissions.labelText,
            icon: <ChangeStatusIcon size={24} />,
          },
          ...(showAuditLog
            ? [
                {
                  action() {
                    handleRightDrawerOpenerClick({ title: 'Audit log' });
                  },
                  dataTestName: 'project__options_menu__audit_log__button',
                  text: 'Audit log',
                  icon: <ActivityLogIcon size={24} />,
                },
              ]
            : []),
        ]
      : []),
    {
      action() {
        navigate(`/projects/${projectId}/settings`);
      },
      text: 'Settings',
      dataTestName: 'project__options_menu__settings__button',
      icon: <SettingsIcon size={24} />,
    },
  ];

  return (
    <>
      <DotsVerticalMenu
        menuItems={menuItems}
        anchorOriginHorizontal="right"
        transformOriginHorizontal="right"
        dataTestName="project__settings__options_menu"
        type="button"
      />

      <UpdateProjectStatus
        open={changeStatusComponent.open}
        handleOpen={changeStatusComponent.handleOpen}
        valuesBasedOnPermissions={valuesBasedOnPermissions}
      />
      <RightDrawer {...rightMenu}>
        <>
          <Stack direction="row" spacing={1}>
            <FilterButton
              fixedWidth={164}
              labels={getArrayFromObject(AuditLogTags, 'tag', 'name')}
              initialValue={filters.tags}
              title="Type"
              valueName="tags"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={(option, value) => option.tag === value?.tag}
              dataTestName={'project__right_drawer__switcher__audit_log__type_filter__input'}
            />
            <IconButton
              onClick={handleSortClick}
              sx={sorting ? { paddingRight: 0 } : { transform: ['scaleY(-1)'], paddingRight: 0 }}
              data-cy={'project__right_drawer__switcher__audit_log__sort__icon'}
              size="small"
            >
              <SortIcon color={colors.icons.gray} size={16} />
            </IconButton>
          </Stack>
          <AuditLog
            projectId={projectId}
            sorting={sorting}
            filters={filters.tags.map((o) => o.tag)}
            source="project__right_drawer__audit_log"
          />
        </>
      </RightDrawer>
    </>
  );
};

export default ProjectSettingsOptionsMenu;
