import React, { FC, useContext } from 'react';
import { ReactTable, ServiceMessage } from '@components';
import { IProjectsPortfolioItem, TableKeyEnum } from '@interfaces';
import { getLink, getTeamRole } from '@utils';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@context';

const ProjectsList: FC<{
  columns: any[];
  hiddenColumns: string[];
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  projects: IProjectsPortfolioItem[];
  handleSortClick: (column: string) => void;
  sortValue: { [key: string]: number }[];
}> = ({
  columns,
  hiddenColumns,
  isLoading,
  isFetching,
  isError,
  projects,
  handleSortClick,
  sortValue,
}) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);

  const handleRowClick = (row) => {
    const link = getLink({ row, tableKey: TableKeyEnum.RISK_RADAR, teamRole });
    link && navigate(link);
  };

  return (
    <>
      {isError && <ServiceMessage text="project portfolio" />}
      {!isLoading && !isError && !projects?.length && (
        <ServiceMessage>There are no projects yet in this category</ServiceMessage>
      )}
      {(isLoading || Boolean(projects?.length)) && (
        <Table
          initialData={projects}
          columns={columns}
          onRowClick={handleRowClick}
          initialSortBy={sortValue}
          handleSortClick={handleSortClick}
          isUpdating={isFetching}
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
        />
      )}
    </>
  );
};

const Table = ({
  initialData,
  columns,
  onRowClick,
  initialSortBy,
  handleSortClick,
  isUpdating,
  isLoading,
  hiddenColumns,
}) => {
  const data = React.useMemo(
    () => (isLoading ? Array(10).fill({}) : initialData),
    [initialData, isLoading],
  );
  const sortData = React.useMemo(() => initialSortBy, [initialSortBy]);

  return (
    <div style={{ opacity: isUpdating ? 0.3 : 1 }}>
      <ReactTable
        data={data}
        columns={columns}
        onRowClick={onRowClick}
        footer={false}
        maxHeight={'calc(100vh - 338px)'}
        minHeight={'calc(100vh - 338px)'}
        hiddenColumns={hiddenColumns}
        initialSortBy={sortData}
        handleSortClick={handleSortClick}
        manualSortBy
        source="projects__table"
        tableKey={TableKeyEnum.RISK_RADAR}
        showContextMenu
      />
    </div>
  );
};

export default ProjectsList;
