import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.status.error.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.15851 3.24744C6.74287 2.88376 6.11111 2.92587 5.74743 3.34151L2.24742 7.34151C1.91753 7.71853 1.91753 8.28149 2.24742 8.65852L5.74743 12.6585C6.11111 13.0742 6.74287 13.1163 7.15851 12.7526C7.57414 12.3889 7.61626 11.7571 7.25258 11.3415L5.20377 9.00001L15 9.00001C17.7614 9.00001 20 11.2386 20 14C20 16.7614 17.7614 19 15 19L6.5 19C5.94772 19 5.5 19.4477 5.5 20C5.5 20.5523 5.94772 21 6.5 21L15 21C18.866 21 22 17.866 22 14C22 10.134 18.866 7.00001 15 7.00001L5.20377 7.00001L7.25258 4.65852C7.61626 4.24288 7.57414 3.61112 7.15851 3.24744Z"
      fill={color}
    />
  </svg>
);

export default Icon;
