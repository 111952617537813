import { useContext, useMemo } from 'react';
import { useQueries } from 'react-query';
import {
  HookState,
  PaymentConfiguration,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { getProject, getProjectFunds } from '@globalService';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAmountFromSource,
  getHookState,
  isCreatedProject,
  isRestricted,
  percentFormatter,
} from '@utils';
import { PermissionsContext } from '@context';

export interface ControllerInterface {
  state: HookState;
  originalBorrowerEquity: number;
  constructionHoldback: number;
  originalEstimate: number;
  revisedEstimate: number;
  feesAmount: number;
  paymentModelText: string;
  navigateToPaymentSettings: () => void;
  canEditFees: boolean;
  canEditProjectModel: boolean;
}

export const useProjectPaymentSummary = (): ControllerInterface => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { permissions } = useContext(PermissionsContext);

  const requestedProjectQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
      enabled: Boolean(projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }],
      queryFn: getProjectFunds.bind(this, projectId),
      enabled: Boolean(projectId),
    },
  ]);

  const project = useMemo(
    () => requestedProjectQueries[0]?.data,
    [requestedProjectQueries[0]?.data],
  );

  const sources = useMemo(
    () => requestedProjectQueries[1]?.data?.sources?.items,
    [requestedProjectQueries[1]?.data?.sources?.items],
  );

  const originalBorrowerEquity = useMemo(
    () => getAmountFromSource(sources, 'original_borrower_equity'),
    [sources],
  );

  const constructionHoldback = useMemo(
    () => getAmountFromSource(sources, 'construction_holdback'),
    [sources],
  );

  const originalEstimate = useMemo(() => project?.original_estimate, [project?.original_estimate]);

  const revisedEstimate = useMemo(() => project?.revised_estimate, [project?.revised_estimate]);

  const feesAmount = useMemo(
    () =>
      project?.fees?.reduce((prev, curr) => {
        return prev + curr.amount;
      }, 0),
    [project?.fees],
  );

  const paymentModelText = useMemo(() => {
    const configurationType =
      project?.payment_configuration_type === PaymentConfiguration.PER_DRAW_REQUEST
        ? 'Partial'
        : 'Sequential';
    return `${percentFormatter({ value: project?.construction_holdback_rate })} / ${percentFormatter({ value: project?.borrower_equity_rate })} Loan to equity, ${configurationType}`;
  }, [project]);

  const canEditFees = useMemo(
    () => !isRestricted(PermissionNamesEnums.PAYMENTS_MARK_AS_PAID, permissions),
    [permissions],
  );

  const canEditProjectModel = useMemo(
    () =>
      isCreatedProject(project.status) &&
      !isRestricted(PermissionNamesEnums.PROJECT_ONBOARDING, permissions),
    [permissions, project.status],
  );

  const navigateToPaymentSettings = () => {
    navigate(`/projects/${projectId}/settings/loan`, {
      state: { prevUrl: `/projects/${projectId}/payments/all` },
    });
  };

  return {
    state: getHookState(requestedProjectQueries),
    originalBorrowerEquity,
    constructionHoldback,
    originalEstimate,
    revisedEstimate,
    feesAmount,
    paymentModelText,
    navigateToPaymentSettings,
    canEditFees,
    canEditProjectModel,
  };
};
