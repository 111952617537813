import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 16, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.775 13.2526C17.1906 12.8889 17.2327 12.2571 16.869 11.8415C16.5053 11.4259 15.8736 11.3837 15.4579 11.7474L13.1165 13.7962V4C13.1165 3.44772 12.6687 3 12.1165 3C11.5642 3 11.1165 3.44772 11.1165 4V13.7962L8.77496 11.7474C8.35932 11.3837 7.72756 11.4259 7.36388 11.8415C7.0002 12.2571 7.04231 12.8889 7.45795 13.2526L11.4579 16.7526C11.835 17.0825 12.3979 17.0825 12.775 16.7526L16.775 13.2526ZM4.11646 16C4.11646 15.4477 3.66874 15 3.11646 15C2.56417 15 2.11646 15.4477 2.11646 16V19C2.11646 20.1046 3.01189 21 4.11646 21H20.1165C21.221 21 22.1165 20.1046 22.1165 19V16C22.1165 15.4477 21.6687 15 21.1165 15C20.5642 15 20.1165 15.4477 20.1165 16V19H4.11646V16Z"
      fill={color}
    />
  </svg>
);

export default Icon;
