import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { SxProps } from '@mui/system';

import { GoogleMap, UploaderWithForm } from '@components';
import { IProject } from '@interfaces';
import { HomeIcon } from '@svgAsComponents';

import * as Controller from './controller';
import { colors } from '@theme';
import { isProduction } from '@utils';

const ProjectImage: FC<{
  disabled?: boolean;
  project: Partial<IProject>;
  source: string;
  sx?: SxProps;
}> = ({ disabled, project, source, sx = {} }) => {
  const {
    fileUploader,
    startUploading,
    thumbTypeId,
    closeFileUploader,
    projectLocation,
    projectImage,
  } = Controller.useProjectImage({ project });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '222px',
          width: '440px',
          backgroundColor: colors.background.gray,
          borderRadius: '2px',
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          },
          opacity: !disabled && 0.7,
          position: 'relative',
          ...sx,
        }}
      >
        {!disabled && (
          <Button
            onClick={() => startUploading()}
            variant="new"
            color="secondary"
            size="small"
            sx={{
              position: 'absolute',
              top: '95px',
              left: '0px',
              right: '0px',
              width: '170px',
              margin: 'auto',
              zIndex: 7,
            }}
          >
            Edit project image
          </Button>
        )}
        {projectImage ? (
          <img src={projectImage} alt="project image" />
        ) : !isEmpty(projectLocation) && isProduction ? (
          <GoogleMap location={projectLocation} />
        ) : (
          <HomeIcon />
        )}
      </Box>
      <Box sx={{ pb: 1 }} />

      {fileUploader.transloaditSignature && (
        <UploaderWithForm
          isOpen={fileUploader.isFilesUploaderOpened}
          closeUploader={closeFileUploader}
          transloaditSignature={fileUploader.transloaditSignature}
          restrictions={{
            ...fileUploader.restrictions,
            maxNumberOfFiles: 1,
            allowedFileTypes: ['.png', '.heic', '.jpg', '.jpeg', '.svg'],
          }}
          source={source}
          projectId={project.id}
          isProjectImage
          documentTypeId={thumbTypeId}
        />
      )}
    </>
  );
};

export default ProjectImage;
