import React, { FC } from 'react';
import { FormikProvider, Form, FormikContextType } from 'formik';
import { Stack, useMediaQuery } from '@mui/material';

interface ComponentProps {
  formik?: FormikContextType<any>;
  getTitle: React.ReactNode;
  getFormContent: React.ReactNode;
  getFormButtons: React.ReactNode;
}

const LoginWireframe: FC<ComponentProps> = ({
  formik,
  getTitle,
  getFormContent,
  getFormButtons,
}) => {
  const smallMediaQuery = useMediaQuery('(max-width:600px)');

  return (
    <FormikProvider value={formik}>
      <Form data-cy="login" style={smallMediaQuery ? { overflow: 'hidden', height: '100%' } : {}}>
        <Stack sx={{ height: '100%' }}>
          <Stack sx={{ mb: { md: '24px', xs: '8px' } }}>{getTitle}</Stack>
          <Stack sx={{ height: '100%', direction: 'flex', justifyContent: 'space-between' }}>
            <Stack sx={{ mb: { lg: '84px', xs: 0 } }}>{getFormContent}</Stack>
            <Stack sx={{ mb: { md: '40px', xs: '16px' } }}>{getFormButtons}</Stack>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default React.memo(LoginWireframe);
