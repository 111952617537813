import { FilterObject } from '../interface';

export const typesValues = {
  ['Horizontal & Soft costs']: 'is_horizontal=true',
  ['Vertical']: 'is_vertical=true',
};

export const typeFilter: FilterObject = {
  title: 'Type',
  filterKey: 'type',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getStaticValues: () =>
    Object.keys(typesValues).map((label) => ({ label, value: typesValues[label] })),
  cypressSelector: 'filters__type',
};
