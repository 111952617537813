export const inspectionsDocumentItem = (
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  documentId = 'document_pk',
) => `projects/${projectId}/inspections/${inspectionId}/documents/${documentId}/`;

export const inspectionsDocuments = (
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  query = '',
  stringQueryParams = '',
) => `projects/${projectId}/inspections/${inspectionId}/documents/?${query}${stringQueryParams}`;

export const projectDocuments = (projectId = 'project_pk', stringQueryParams = '') =>
  `projects/${projectId}/documents/?${stringQueryParams}`;

export const projectDocument = (projectId, documentId, query = '') =>
  `projects/${projectId}/documents/${documentId}/${query && `?query=${query}`}`;

export const projectDocumentRestore = (
  projectId = 'project_pk',
  documentId = 'project_document_pk',
) => `projects/${projectId}/documents/${documentId}/restore/`;

export const serviceOrderDocuments = (
  projectId = 'project_pk',
  serviceOrderId = 'service_order_pk',
  query = '',
  stringQueryParams = '',
) =>
  `projects/${projectId}/service_orders/${serviceOrderId}/documents/?${query}${stringQueryParams}`;

export const serviceOrderDocumentItem = (
  projectId = 'project_pk',
  serviceOrderId = 'service_order_pk',
  documentId = 'document_pk',
) => `projects/${projectId}/service_orders/${serviceOrderId}/documents/${documentId}/`;
