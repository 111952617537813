import React, { FC, ReactElement } from 'react';
import { Stack, Tooltip } from '@mui/material';
import { InfoIcon } from '@svgAsComponents';

interface ComponentWithTooltipProps {
  tooltipText?: string | ReactElement;
  color?: string;
  size?: number;
}

const InfoIconWithTooltip: FC<ComponentWithTooltipProps> = ({
  tooltipText,
  color,
  size,
  ...props
}) => {
  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <Stack {...props}>
        <InfoIcon color={color} size={size} />
      </Stack>
    </Tooltip>
  ) : (
    <InfoIcon color={color} size={size} />
  );
};

export default InfoIconWithTooltip;
