import { useEffect, useState, useMemo, useCallback } from 'react';
import { useQuery } from 'react-query';

import { QueryNamesEnums, ITeam, IUser } from '@interfaces';
import { getCompanyTeams, getProjectsUsers } from '@globalService';
import { TEAM_ROLES } from '@constants';
import { checkIsOwner } from '@utils';

export type ControllerInterface = {
  isSearching: boolean;
  handleSearchEmailClick: () => void;
  borrowerTeam: ITeam;
  tooltipText: string;
};

export const useBorrowerStep = ({
  borrowerDetailsFields,
  borrowerUser,
  setBorrowerUser,
  setIsNewBorrower,
}): ControllerInterface => {
  const { borrower_email, borrower_company_state } = borrowerDetailsFields;

  // handle borrower company
  const [tooltipText, setTooltipText] = useState<string>('');

  useEffect(() => {
    if (borrower_email.validate()) {
      // if user change something in the input we clear shown company info before he will click on search button
      setBorrowerUser(null);
      setTooltipText('');
      setIsNewBorrower(false);
      const fieldsToReset = [
        'first_name',
        'last_name',
        'phone',
        'borrower_company_name',
        'borrower_company_address_1',
        'borrower_company_city',
        'borrower_company_zip_code',
      ];
      fieldsToReset.forEach((fieldName) => {
        borrowerDetailsFields[fieldName]?.setValue('');
        borrowerDetailsFields[fieldName]?.setValid(true);
      });
      borrower_company_state.setValue(null);
      borrower_company_state.setValid(true);
    }
  }, [borrower_email?.value]);

  const query = '{*,company{id,name,address}}';
  const projectsUserByEmailQuery = useQuery<{ results: IUser[] }, Error>(
    [QueryNamesEnums.GET_PROJECTS_USERS, { email: borrower_email.value?.email, query }],
    getProjectsUsers.bind(
      this,
      `?${new URLSearchParams({
        email: borrower_email.value,
        team_role: TEAM_ROLES.Owner,
        query,
      }).toString()}`,
    ),
    {
      enabled: false,
      onSuccess: ({ results }) => {
        if (results.length) {
          setTooltipText('Email already exists. The company will be added to the project.');
          setBorrowerUser(results[0]);
        } else {
          setBorrowerUser(null);
          setIsNewBorrower(true);
          setTooltipText(
            'Borrower not found. Please enter the details below to create a new borrower company profile.',
          );
        }
      },
    },
  );

  const handleSearchEmailClick = useCallback(() => {
    if (!borrower_email.value?.trim()) return;
    projectsUserByEmailQuery.refetch();
  }, [borrower_email.value]);

  const companyTeamsQuery = useQuery<{ results: ITeam[] }, Error>(
    // is_on_shared_project is used to limit borrower users only from teams that are assigned to lender's projects
    [
      QueryNamesEnums.GET_COMPANY_TEAMS,
      { companyId: borrowerUser?.company?.id, is_on_shared_project: true },
    ],
    getCompanyTeams.bind(this, borrowerUser?.company?.id, true),
    { enabled: Boolean(borrowerUser?.company?.id) },
  );

  const borrowerTeam = useMemo(() => {
    // Find the team where a member is the owner
    const team = companyTeamsQuery.data?.results?.find((team) => checkIsOwner(team?.role));

    // If no team is found, return null
    if (!team) {
      return null;
    }

    return team;
  }, [companyTeamsQuery.data?.results]);

  return {
    isSearching: projectsUserByEmailQuery.isLoading,
    handleSearchEmailClick,
    borrowerTeam,
    tooltipText,
  };
};
