import React, { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSafeSnackbar } from '@hooks';
import { useParams } from 'react-router-dom';

import { FormControlLabel, Stack, Switch } from '@mui/material';
import { updateProjectCoordinator } from '@globalService';
import { IProjectCoordinator, IUser, IUsersListRow, QueryNamesEnums } from '@interfaces';

const CoordinatorSwitch: FC<{
  row: IUsersListRow;
  coordinator: IUser;
  showCoordinator: boolean;
}> = ({ row, coordinator, showCoordinator }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId } = useParams();

  const updateCoordinatorMutation = useMutation<Response, Error, IProjectCoordinator>(
    updateProjectCoordinator,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMPANIES);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateCoordinator = () => {
    updateCoordinatorMutation.mutateAsync({ projectId, coordinatorId: row.id });
  };

  if (!showCoordinator) return null;

  return (
    <Stack direction="row" justifyContent="center">
      <FormControlLabel
        sx={{ margin: 0 }}
        control={<Switch checked={row.id === coordinator?.id} onChange={updateCoordinator} />}
        label=""
      />
    </Stack>
  );
};

export default CoordinatorSwitch;
