import { useMemo, useContext } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import {
  IProject,
  QueryNamesEnums,
  IDrawRequest,
  IInspection,
  PermissionNamesEnums,
  ServiceTypeEnum,
} from '@interfaces';
import {
  isRestricted,
  getActiveDrawRequestFromList,
  getCurrentInspection,
  isCancelRequestedService,
  getServiceTypeDisplayName,
} from '@utils';
import { PermissionsContext, useGetData, SettingsContext } from '@context';
import { getProjectDrawRequestsList, getProjectInspectionsList } from '@globalService';
import { TOOLTIP_TEXTS } from '@constants';
interface ControllerInterface {
  goBack: () => void;
  project: Partial<IProject>;
  drawRequestInProgress: IDrawRequest;
  isRequestsListLoaded: boolean;
  inspectionDisabledTooltipText: string;
  serviceTypeDisplayName: string;
  service_type: ServiceTypeEnum;
}

export const useOrderService = (): ControllerInterface => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const { settings } = useContext(SettingsContext);
  const [searchParams] = useSearchParams();
  const service_type = searchParams.get('service_type') as ServiceTypeEnum;
  const serviceTypeDisplayName = useMemo(() => {
    return getServiceTypeDisplayName({
      serviceTypesMap: settings?.display?.service_types,
      serviceType: service_type,
    });
  }, [settings?.display?.service_types, service_type]);

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const projectInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    {
      enabled: !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    },
  );

  const drawRequestInProgress = useMemo(
    () => getActiveDrawRequestFromList(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const inProgressInspection = useMemo(
    () => getCurrentInspection(projectInspectionsQuery.data?.results),
    [projectInspectionsQuery.data?.results],
  );
  const isInspectionCancelRequested = useMemo(
    () => isCancelRequestedService(inProgressInspection?.status),
    [inProgressInspection],
  );
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'status', 'status_change_reason', 'id'],
    args: { projectId },
  });

  const inspectionDisabledTooltipText = useMemo(() => {
    if (!inProgressInspection) return '';
    if (isInspectionCancelRequested) {
      return TOOLTIP_TEXTS.inspectionCancelRequested;
    }
    return TOOLTIP_TEXTS.inspectionOrdered;
  }, [inProgressInspection, isInspectionCancelRequested]);

  return {
    goBack: () => navigate(`/projects/${projectId}/services/`),
    project: project.data,
    drawRequestInProgress,
    isRequestsListLoaded: drawRequestsQuery.isSuccess,
    inspectionDisabledTooltipText,
    serviceTypeDisplayName,
    service_type,
  };
};
