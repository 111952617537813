import React, { FC } from 'react';
import { TextField } from '@mui/material';
import * as Controller from './controller';

const CompanyInfoField: FC<{
  label: string;
  isEditAllowed: boolean;
  item: string;
  value: string;
  helperText: string;
}> = ({ label, isEditAllowed, item, value, helperText }) => {
  const { stringField } = Controller.useCompanyInfoField(item, value);

  return (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      value={stringField.value}
      label={label}
      onChange={stringField.handleChange}
      disabled={!stringField.value && !isEditAllowed}
      InputProps={{ readOnly: !isEditAllowed }}
      error={!stringField.isValid}
      helperText={stringField.isValid ? '' : helperText}
    />
  );
};

export default CompanyInfoField;
