import { useMutation } from 'react-query';

import { forgotPassword } from '@globalService';

export interface ControllerInterface {
  showProgress: boolean;
  handleForgotPassword: (email: string) => void;
}

export const useForgotPassword = (): ControllerInterface => {
  const forgotPasswordPost = useMutation<Response, Error, { email }>(forgotPassword);

  const handleForgotPassword = async (email) => {
    await forgotPasswordPost.mutateAsync(email);
  };

  return {
    showProgress: forgotPasswordPost.isLoading,
    handleForgotPassword,
  };
};
