import React, { ReactNode } from 'react';
import { Button, Typography, Stack } from '@mui/material';
import { datadogRum } from '@datadog/browser-rum';

import { AppCrashImage, LogoWithName } from '@svgAsComponents';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const renderingError = new Error(error.message);
    console.log('renderingError', renderingError);

    renderingError.name = `ReactRenderingError`;
    renderingError.stack = info.componentStack;
    renderingError.cause = error;

    datadogRum.addError(renderingError);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100vh', padding: '2rem' }}
        >
          <Stack sx={{ position: 'absolute', top: '3rem' }}>
            <LogoWithName />
          </Stack>
          <AppCrashImage />
          <Typography variant="h2" sx={{ pb: '2rem', textAlign: 'center' }}>
            Oops.. Something went wrong ;(
          </Typography>
          <Button onClick={() => (window.location.href = '/')}>Home</Button>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
