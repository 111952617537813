import React from 'react';
import { UserListColumnType } from './common';
import { Typography } from '@mui/material';

const email: UserListColumnType = {
  name: 'email',
  columnText: 'Email',
  width: '20%',
  renderCell: ({ row }) => <Typography variant="body2">{row.email}</Typography>,
};

export default email;
