import React, { Dispatch, useMemo, useState } from 'react';
import { ILocalFee, IProject, PatchDrawRequestParam, QueryNamesEnums } from '@interfaces';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { getDrawRequest, patchDrawRequest, updateProjectFields } from '@globalService';
import { useParams } from 'react-router-dom';
import { isRequestActive } from '@utils';
import { useSafeSnackbar } from '@hooks';

export interface ControllerInterface {
  isActiveDrawRequest: boolean;
  onSubmit: (fees: ILocalFee[]) => void;
  isSubmitting: boolean;
  addFeeToProject: boolean;
  setAddFeeToProject: Dispatch<React.SetStateAction<boolean>>;
}

export const useFeesEditPopup = (closePopup): ControllerInterface => {
  const { projectId, drawRequestId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [addFeeToProject, setAddFeeToProject] = useState(true);

  const requestedDRQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId }),
      enabled: Boolean(projectId && drawRequestId),
    },
  ]);

  const drawRequest = requestedDRQueries[0].data;

  const updateDrawFees = useMutation<Response, Error, PatchDrawRequestParam>(patchDrawRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onSettled: () => closePopup(),
  });

  const updateProjectPaymentConfig = useMutation<
    Response,
    Error,
    {
      projectId: string;
      json: Partial<IProject>;
    }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const isActiveDrawRequest = useMemo(
    () => isRequestActive(drawRequest?.status),
    [drawRequest?.status],
  );

  const onSubmit = (fees: ILocalFee[]) => {
    const validFees = fees.filter((fee) => fee.amount && fee.name.trim());
    updateDrawFees.mutateAsync({
      id: projectId,
      drawRequest: drawRequestId,
      fees: validFees,
    });

    if (addFeeToProject) {
      const feesWithoutId = validFees.map((fee) => {
        const { id: _, ...rest } = fee;
        return rest;
      });

      updateProjectPaymentConfig.mutateAsync({
        projectId,
        json: {
          fees: feesWithoutId,
        },
      });
    }
  };

  return {
    isActiveDrawRequest,
    onSubmit,
    addFeeToProject,
    setAddFeeToProject,
    isSubmitting: updateDrawFees.isLoading,
  };
};
