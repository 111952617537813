import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.697 7.35025C21.0177 7.65843 22 8.82296 22 10.2125V18.0579C22 19.6828 20.6569 21 19 21L5 21C3.34315 21 2 19.6828 2 18.058L2 12.1738L2 10.2125C2 8.58763 3.34315 7.27043 5 7.27043H5.47642L14.5207 3.26182C16.0305 2.59263 17.8076 3.25046 18.49 4.73111L18.9533 5.73647L19.5968 7.13281L19.697 7.35025ZM4 13.1545L4 18.058C4 18.5996 4.44772 19.0386 5 19.0386L19 19.0386C19.5523 19.0386 20 18.5996 20 18.0579V13.1545H4ZM20 11.1932H4V10.2125C4 9.67086 4.44771 9.23179 5 9.23179L19 9.23179C19.5523 9.23179 20 9.67086 20 10.2125V11.1932ZM17.4655 7.27043L17.1308 6.54425L16.6675 5.53889C16.44 5.04534 15.8477 4.82606 15.3444 5.04912L10.3327 7.27043L17.4655 7.27043ZM5 16.0966C5 15.555 5.44772 15.1159 6 15.1159H10C10.5523 15.1159 11 15.555 11 16.0966C11 16.6382 10.5523 17.0773 10 17.0773H6C5.44772 17.0773 5 16.6382 5 16.0966Z"
      fill={color}
    />
  </svg>
);

export default Icon;
