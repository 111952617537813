import { FilterObject } from '../interface';
import { ProjectScoresDisplayValues } from '@constants';
import { PermissionNamesEnums, ScoreValuesEnum } from '@interfaces';

export const projectHealthFilter: FilterObject = {
  title: 'Project health',
  filterKey: ScoreValuesEnum.PROJECT_HEALTH,
  permissionKey: PermissionNamesEnums.SCORES_VIEW,
  getStaticValues: () =>
    ProjectScoresDisplayValues.map((item) => ({
      label: item.name,
      value: item.value,
    })),
  cypressSelector: 'filters__overall_score',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
};
