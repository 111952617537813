import { GridCellParams, GridColDef, GridRowsProp, GridState } from '@mui/x-data-grid-premium';
import { useState, useEffect, useMemo, useCallback } from 'react';
import React from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ExtendGridStatePremium, IMilestone } from '@interfaces';

const useTableState = (
  apiRef: React.MutableRefObject<GridApiPremium>,
  columns: GridColDef[],
  rows: GridRowsProp,
  maxRowCount: number,
  errors?: {
    rowErrors: any;
    columnErrors: any;
  },
  totals?: Partial<IMilestone>,
) => {
  const [tableLoadingBlock, setTableLoading] = useState(false);
  const memoColumns = useMemo(() => columns, []);

  const renderRows = useMemo(() => {
    const rowsToRedner = totals ? [...rows, totals] : rows;
    return maxRowCount ? rowsToRedner.slice(0, maxRowCount) : rowsToRedner;
  }, [rows, totals, maxRowCount]);

  const isCellEditable = useCallback(
    (params: GridCellParams) => {
      if (params.row.id === 'totals') return false;
      const state = apiRef.current.state as ExtendGridStatePremium;
      const errorForCurrentCell = state.additionalData.rowErrors?.[params.field]?.[params.row.id];
      const byMilestone = params.colDef['editableByMilestone']?.(params.row);
      return !!errorForCurrentCell || !!byMilestone;
    },
    [apiRef.current],
  );

  useEffect(() => {
    if (errors)
      apiRef.current.setState((state: GridState) => ({
        ...state,
        additionalData: errors,
      }));
    apiRef.current.forceUpdate();
  }, [errors]);

  const defaultColumnOrder = useMemo(() => memoColumns.map((item) => item.field), [memoColumns]);

  return {
    tableLoadingBlock,
    setTableLoading,
    renderRows,
    memoColumns,
    isCellEditable,
    defaultColumnOrder,
  };
};

export default useTableState;
