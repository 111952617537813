import React, { FC, memo, useContext } from 'react';
import { IconButton } from '@mui/material';

import { CommonRowType, MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { FundingIconWithIndicator } from '@svgAsComponents';
import { ColumnV2Width, PermissionNamesEnums, RequestTableTabsEnum } from '@interfaces';
import { creditAvailableForMilestone } from '@utils';
import { colors } from '@theme';

const SubmitCredit: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(TableContext);
  const creditAvailable = creditAvailableForMilestone(row);
  return creditAvailable ? (
    <IconButton
      onClick={
        updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.CREDIT_SUBMIT,
            })
          : row?.updateRightDrawer?.()
      }
      data-cy={`${source}__body__credit__icon__index_${row.project_milestone?.index}`}
    >
      <FundingIconWithIndicator color={colors.icons.green} />
    </IconButton>
  ) : null;
};

const Memoized = memo(SubmitCredit);

const credit: MilestoneListColumnType = {
  name: 'credit_reason',
  columnText: '',
  permissionKey: PermissionNamesEnums.DRAWREQUESTS_CREDIT_REQUEST,
  Header: '',
  justifyContent: 'center',
  noBorder: true,
  noMargin: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default credit;
