import React, { FC } from 'react';
import { Divider, Stack } from '@mui/material';
import { useInfoBreakdown } from './controller';
import { BreakdownGraphs, LabelAndValue } from '@components';

const InfoBreakdownPanel: FC<{
  milestoneId?: string;
  requestId?: string;
  source?: string;
}> = ({ milestoneId, requestId, source }) => {
  const { milestone } = useInfoBreakdown({
    requestId,
    milestoneId,
  });
  return (
    <Stack mt={1} flex={1} spacing={4}>
      <Stack>
        <BreakdownGraphs requestId={requestId} milestoneId={milestoneId} />
      </Stack>
      <Divider />
      <Stack spacing={1}>
        {milestone?.project_milestone?.change_request_number && (
          <LabelAndValue
            label="Created"
            text={`Request #${milestone?.project_milestone?.change_request_number}`}
            textDataTestName={`${source}__change_request_number`}
          />
        )}
        <LabelAndValue
          label="Cost type"
          text={milestone?.cost_type?.['display']}
          textDataTestName={`${source}__cost_type`}
        />
      </Stack>
    </Stack>
  );
};

export default InfoBreakdownPanel;
