import { useMutation, useQueryClient } from 'react-query';
import { updateUiSettings } from '@globalService';
import { IUISettings, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import { SettingsContext } from '@context';
import { useCallback, useContext, useMemo } from 'react';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export const useUpdateUiSettings = () => {
  const queryClient = useQueryClient();
  const { settings } = useContext(SettingsContext);

  const userSettings = useMemo(() => settings.personal_setting, [settings]);
  const updateSettingsMutation = useMutation<IUISettings, Error, Partial<IUISettings>>(
    updateUiSettings,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_UI_SETTINGS);
      },
      onError: (error) => {
        console.log(error);
      },
    },
  );

  const updateTableSettings = useCallback(
    async (key: TableKeyEnum, settings: GridInitialStatePremium) =>
      await updateSettingsMutation.mutateAsync({
        personal_setting: {
          ...userSettings,
          tableV3: {
            ...userSettings.tableV3,
            [key]: settings,
          },
        },
      }),
    [userSettings],
  );
  const updateSettings = async (settings) => await updateSettingsMutation.mutateAsync(settings);

  return {
    userSettings,
    updateSettings,
    updateTableSettings,
    isColumnFilterUpdating: updateSettingsMutation.isLoading,
  };
};
