import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 18 }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 18) * 24}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14455 0H11.1767L17.9737 7.09489V20.873C17.9737 22.5985 16.5811 24 14.8555 24H3.14455C1.42776 24 0.0263062 22.5985 0.0263062 20.873V3.11824C0.0262758 1.40145 1.42776 0 3.14455 0V0Z"
      fill="#28C366"
    />
    <path
      opacity="0.302"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1679 0V7.03358H17.9738L11.1679 0Z"
      fill="white"
    />
    <path
      d="M7.48905 17.4745H6.32409L5.52703 16.1343L4.72993 17.4745H3.55621L4.94017 15.1445L3.72266 13.1212H4.89635L5.527 14.1722L6.14888 13.1212H7.3226L6.11383 15.1533L7.48905 17.4745ZM7.90072 17.4745V13.1212H9.01313V16.5284H10.9051V17.4744H7.90072V17.4745ZM12.7445 17.527C12.3065 17.527 11.9212 17.3869 11.5883 17.1153C11.2642 16.8525 11.0803 16.5197 11.054 16.1256L12.0088 15.8453C12.0351 16.0555 12.1226 16.2394 12.2716 16.3796C12.4292 16.5197 12.6044 16.5898 12.7971 16.5898C12.9548 16.5898 13.0861 16.5548 13.1912 16.4847C13.2876 16.4146 13.3401 16.3183 13.3401 16.2044C13.3401 16.1081 13.2964 16.0292 13.2175 15.9591C13.1387 15.8978 13.0336 15.8453 12.9109 15.8015C12.7883 15.7577 12.6394 15.7139 12.4905 15.6789C12.3328 15.6351 12.1752 15.5825 12.0263 15.5124C11.8686 15.4511 11.7285 15.3723 11.6058 15.2759C11.4744 15.1883 11.3781 15.057 11.2992 14.8905C11.2204 14.7329 11.1766 14.5402 11.1766 14.3212C11.1766 13.9621 11.3255 13.6643 11.6321 13.4278C11.9387 13.1825 12.3066 13.0686 12.7357 13.0686C13.1649 13.0686 13.5416 13.1737 13.8569 13.3752C14.1722 13.5854 14.3737 13.857 14.4525 14.1898L13.454 14.6102C13.4102 14.4263 13.3226 14.2774 13.2 14.1635C13.0773 14.0584 12.9197 13.9971 12.7357 13.9971C12.5956 13.9971 12.4905 14.0321 12.4029 14.0847C12.324 14.1373 12.289 14.2161 12.289 14.3125C12.289 14.4001 12.3416 14.4789 12.4467 14.5402C12.5518 14.5927 12.6919 14.6365 12.8496 14.6628C13.0072 14.6891 13.1824 14.7329 13.3664 14.7942C13.5591 14.8643 13.7255 14.9344 13.8919 15.0307C14.0496 15.1183 14.181 15.2672 14.2861 15.4599C14.3999 15.6614 14.4525 15.8979 14.4525 16.1694C14.4525 16.5723 14.2948 16.9052 13.9795 17.1504C13.6642 17.3956 13.2526 17.527 12.7445 17.527Z"
      fill="white"
    />
  </svg>
);

export default Icon;
