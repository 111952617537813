import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 16, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3C15.8162 3 18.1109 4.34664 19.4071 5.64289C20.7098 6.94562 22 9.19161 22 12C22 14.8162 20.6534 17.1109 19.3571 18.4071C18.0544 19.7098 15.8084 21 13 21C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19C15.1916 19 16.9494 17.9864 17.9429 16.9929C18.9428 15.9929 20 14.1838 20 12C20 9.80838 18.9864 8.05057 17.9929 7.05711C16.9929 6.05716 15.1838 5 13 5C10.8084 5 9.05057 6.01364 8.05711 7.00711C7.24044 7.82377 6.38561 9.18017 6.09968 10.8396L6.87531 10.2191C7.30657 9.87412 7.93586 9.94404 8.28087 10.3753C8.62588 10.8066 8.55596 11.4359 8.12469 11.7809L5.6247 13.7809C5.2268 14.0992 4.6532 14.0674 4.29289 13.7071L2.29289 11.7071C1.90237 11.3166 1.90237 10.6834 2.29289 10.2929C2.68342 9.90237 3.31658 9.90237 3.70711 10.2929L4.09858 10.6844C4.43162 8.47193 5.555 6.68078 6.64289 5.59289C7.94562 4.29016 10.1916 3 13 3Z"
      fill={color}
    />
  </svg>
);

export default Icon;
