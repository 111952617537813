import { dataProvider } from '@api';
import {
  bulkTeamsMembersUpdate,
  bulkTeamsUpdate,
  companyRolesByID,
  companyTeamById,
  companyTeams,
  teamMembers,
  teamUserById,
} from '@globalService';
import {
  AddTeamPayload,
  AddUserToTeamsPayload,
  BulkUpdateTeamsPayload,
  DeleteTeamPayload,
  DeleteTeamUserPayload,
  IRoleNew,
  ITeam,
  ITeamMember,
  RolePayload,
  UpdateTeamPayload,
} from '@interfaces';

export const getCompanyTeams = async (...args): Promise<{ results: ITeam[] }> =>
  dataProvider
    .get(
      companyTeams({
        companyId: args[0],
        is_on_shared_project: typeof args[1] === 'boolean' ? args[1] : false,
      }),
    )
    .json();

export const getCompanyTeamById = async (companyId, teamId): Promise<ITeam> =>
  dataProvider.get(companyTeamById(companyId, teamId)).json();

export const addTeamMember = async ({
  companyId,
  teamId,
  data,
}: {
  companyId: string;
  teamId: string;
  data: {
    user: string;
  };
}): Promise<ITeamMember> =>
  dataProvider.post(teamMembers(companyId, teamId), { json: data }).json();

export const addUserToTeams = async ({
  companyId,
  data,
}: AddUserToTeamsPayload): Promise<Response> =>
  dataProvider.post(bulkTeamsMembersUpdate(companyId), { json: data }).json();

export const addCompanyTeam = async (data: AddTeamPayload): Promise<ITeam> =>
  dataProvider.post(companyTeams({ companyId: data.company }), { json: data }).json();

export const deleteCompanyTeam = async ({
  companyId,
  teamId,
}: DeleteTeamPayload): Promise<Response> =>
  dataProvider.delete(companyTeamById(companyId, teamId)).json();

export const updateCompanyTeam = async ({
  companyId,
  teamId,
  data,
}: UpdateTeamPayload): Promise<Response> =>
  dataProvider.patch(companyTeamById(companyId, teamId), { json: data }).json();

export const deleteTeamUser = async ({
  teamId,
  userId,
}: DeleteTeamUserPayload): Promise<Response> =>
  dataProvider.delete(teamUserById(teamId, userId)).json();

export const patchBulkTeamsUpdate = async ({
  companyId,
  data,
}: BulkUpdateTeamsPayload): Promise<Response> =>
  dataProvider.put(bulkTeamsUpdate(companyId), { json: data }).json();

export const patchCompanyRole = async ({ roleId, data }: RolePayload): Promise<Response> =>
  dataProvider.patch(companyRolesByID(roleId), { json: data }).json();

export const getCompanyRole = async ({ roleId }: { roleId: string }): Promise<IRoleNew> =>
  dataProvider.get(companyRolesByID(roleId)).json();
