import React, { createContext, FC, useMemo } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import MilestoneColumns from '../MilestoneList/columns';
import { GeneralRowTablInterface } from './interface';
import { LineItemRightDrawer, RightDrawer } from '@components';

export const GeneralRowContext = createContext<{ refetch?: () => void }>({});

const GeneralRowTable: FC<GeneralRowTablInterface> = ({
  canAddPhotos,
  photos,
  refetch,
  rightMenu,
  rightDrawerParams,
  updateRightDrawer,
  comments_preview,
  documents_preview,
}) => {
  const tableContext = useMemo(
    () => ({
      ...(refetch ? { refetch } : {}),
    }),
    [refetch],
  );

  return (
    <GeneralRowContext.Provider value={tableContext}>
      <Table
        sx={{
          mt: 1,
        }}
      >
        <TableBody>
          <TableRow sx={{ paddingX: '16px' }}>
            {['name', 'inspectionPhotoUploader', 'inspectionPhotosV2', 'comments']
              .map((item) => MilestoneColumns[item])
              .map((column) => (
                <TableCell
                  key={column.name}
                  sx={{
                    padding: '0',
                    width: '100%',
                    '& first-of-type': { width: '100%' },
                    ':first-of-type': {
                      paddingLeft: '16px',
                    },
                    ':last-child': {
                      paddingRight: '16px',
                    },
                  }}
                >
                  {column.renderCell({
                    row: {
                      name: 'General photos (not tagged to a line item)',
                      canAddPhotos,
                      inspectionPhotos: photos,
                      refetch,
                      updateRightDrawer,
                      comments_preview,
                      documents_preview,
                      generalRowDataSource: 'inspections__result__general_row',
                    },
                  })}
                </TableCell>
              ))}
          </TableRow>
        </TableBody>
      </Table>
      <RightDrawer {...rightMenu}>
        <LineItemRightDrawer
          setOpen={rightMenu.setOpen}
          rightDrawerParams={{
            ...rightDrawerParams,
            tab: rightDrawerParams.tab,
            includeChildren: false,
          }}
          source="inspections__result__general_row__right_drawer"
        />
      </RightDrawer>
    </GeneralRowContext.Provider>
  );
};

export default GeneralRowTable;
