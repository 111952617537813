import { QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const propertyExistingTypeFilter: FilterObject = {
  title: 'Existing property type',
  filterKey: 'property_existing_type',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParamsPaginated: (pagination, q) => ({
    type: QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES,
    keys: ['id', 'name', 'name_display'],
    args: {
      query: 'has_current_customer=true',
      pagination,
      q,
    },
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.name,
          label: item.name_display,
        })),
    },
  }),
  cypressSelector: 'filters__property_existing_type',
  withPagination: true,
};
