import React, { FC } from 'react';
import { Button, Stack, Box } from '@mui/material';
import { Popup, ButtonWithTooltip, VerticalMenu } from '@components';
import { popupText, title, useSubmissionAutofill } from './controller';
import { DocumentsIconWithIndicator } from '@svgAsComponents';

const SubmissionAutofill: FC<{
  drawRequestId: string;
  retainageRate: number;
}> = ({ drawRequestId, retainageRate }) => {
  const { setCurrentMode, autofillMenuItems, currentMode, handleSubmissionAutofill, loading } =
    useSubmissionAutofill({ drawRequestId, retainageRate });

  return (
    <Box sx={{ ml: '0.5rem' }}>
      <VerticalMenu menuItems={autofillMenuItems}>
        <ButtonWithTooltip
          sx={{ mr: 2 }}
          size="small"
          loading={loading}
          variant="new"
          color="secondary"
          dataTestName="submission__autofill__button"
        >
          Autofill values
        </ButtonWithTooltip>
      </VerticalMenu>

      <Popup
        open={Boolean(currentMode)}
        title={title[currentMode]}
        text={popupText[currentMode]}
        maxWidth="xs"
        icon={DocumentsIconWithIndicator}
      >
        <Stack sx={{ width: '100%' }}>
          <Box sx={{ mt: 7 }} />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button variant="text" onClick={() => setCurrentMode(null)}>
              Cancel
            </Button>
            <Button onClick={handleSubmissionAutofill}>Confirm</Button>
          </Stack>
        </Stack>
      </Popup>
    </Box>
  );
};

export default SubmissionAutofill;
