import React, { useCallback } from 'react';

import { ITablePagination } from '@interfaces';

export const useTablePagination = ({
  initialRowsPerPage = 50,
  rowsPerPageOptions = [50, 75, 100],
}: {
  initialRowsPerPage?: number;
  rowsPerPageOptions?: number[];
  maxCount?: number;
}): ITablePagination => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

  const nextPage = () => setPage((i) => i + 1);

  return {
    onPageChange: useCallback((event, newPage) => {
      setPage(newPage);
    }, []),
    onRowsPerPageChange: useCallback((event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }, []),
    page,
    rowsPerPage,
    rowsPerPageOptions,
    setPage,
    nextPage,
  };
};
