import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.59141 5.75746C9.7027 5.3123 10.1027 5 10.5616 5H13.4384C13.8973 5 14.2973 5.3123 14.4086 5.75746L15.0299 8.24254C15.1411 8.6877 15.5411 9 16 9H19C19.5523 9 20 9.44772 20 10V18C20 18.5523 19.5523 19 19 19H5C4.44772 19 4 18.5523 4 18V10C4 9.44772 4.44772 9 5 9H8C8.45887 9 8.85885 8.6877 8.97014 8.24254L9.59141 5.75746ZM10.5616 3C9.18495 3 7.985 3.93689 7.65113 5.27239L7.21922 7H5C3.34315 7 2 8.34315 2 10V18C2 19.6569 3.34315 21 5 21H19C20.6569 21 22 19.6569 22 18V10C22 8.34315 20.6569 7 19 7H16.7808L16.3489 5.27239C16.015 3.93689 14.8151 3 13.4384 3H10.5616ZM9 12C8.44771 12 8 12.4477 8 13C8 13.5523 8.44771 14 9 14H11V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13V10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10V12H9Z"
      fill={color}
    />
  </svg>
);

export default Icon;
