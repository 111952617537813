import React from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { CloseIcon } from '@svgAsComponents';
import { colors } from '@theme';

const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const onClickDismiss = () => {
    closeSnackbar(snackbarKey);
  };

  return (
    <IconButton aria-label="close" onClick={onClickDismiss}>
      <CloseIcon size={13} color={colors.icons.gray} />
    </IconButton>
  );
};

export default SnackbarCloseButton;
