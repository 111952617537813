import React from 'react';
import { Typography, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { LoginWireframe, FormikInput, CustomAutocomplete, ToolTipLine } from '@components';
import * as Controller from './controller';
import { BackToLoginButton, AnimatedRedirect } from '../components';
import { colors } from '@theme';

const LoginWithSSO = () => {
  const { inProgress, formik, sso_access_token, fromRedirect, connectorsList, connector, ssoType } =
    Controller.useSSOIntegration();

  const getFormButtons = () => (
    <Stack spacing={2}>
      <LoadingButton
        fullWidth
        type="submit"
        disabled={!formik.isValid}
        loading={formik.isSubmitting || inProgress}
      >
        Continue
      </LoadingButton>

      <BackToLoginButton withLogout />
    </Stack>
  );

  if (fromRedirect || sso_access_token || ssoType)
    return (
      <Stack alignItems="center" sx={{ minHeight: '100vh' }}>
        <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }} spacing={6}>
          <AnimatedRedirect />

          {formik.errors?.email ? (
            <>
              <Typography variant="body3SemiBold" sx={{ color: colors.status.error.medium }}>
                {formik.errors.email}
              </Typography>
              <BackToLoginButton withLogout />
            </>
          ) : (
            <Typography variant="h2">Redirecting...</Typography>
          )}
        </Stack>
      </Stack>
    );

  return (
    <LoginWireframe
      formik={formik}
      getTitle={<Typography variant="h1">Sign in with SSO</Typography>}
      getFormContent={
        <>
          <ToolTipLine text="Please enter your work email." withBackground />
          <FormikInput
            name="email"
            label="Enter your work email"
            type="text"
            variant="outlined"
            size="small"
            disabled={formik.isSubmitting || inProgress}
            servererror={formik?.errors?.email}
          />
          {!!connectorsList?.length && (
            <CustomAutocomplete
              field={connector}
              label="Company domain"
              options={connectorsList}
              required
            />
          )}
        </>
      }
      getFormButtons={getFormButtons()}
    />
  );
};

export default LoginWithSSO;
