import React, { forwardRef } from 'react';
import { ReactPhotoSphereViewer, ViewerAPI } from 'react-photo-sphere-viewer';

interface PanoramaViewerProps {
  height?: string;
  width?: string;
}

const PanoramaViewer = forwardRef<ViewerAPI, PanoramaViewerProps>(
  ({ height = '0', width = '0' }, ref) => (
    <ReactPhotoSphereViewer src="" height={height} width={width} ref={ref} />
  ),
);

export default PanoramaViewer;
