import React, { FC, ReactElement, useContext, useMemo } from 'react';
import { CSVBoxButton } from '@csvbox/react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { AuthContext } from '@context';

import * as Controller from './controller';
import { CSVBoxSheetsEnum, IDynamicColumns } from '@interfaces';

const CSVUploader: FC<{
  buttonTitle?: string;
  sheetKey: CSVBoxSheetsEnum;
  renderItem?: (launch, isLoading) => ReactElement;
  templateUrl?: string;
  templateName?: string;
  dynamicColumns?: IDynamicColumns[];
  phbTagKey?: 'unit_name' | 'model_name';
  source?: string;
}> = ({
  buttonTitle,
  sheetKey,
  renderItem,
  templateUrl = '',
  templateName = '',
  dynamicColumns,
  phbTagKey,
  source = 'csv_uploader_button',
}) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { refetch, csvBoxSheetId } = Controller.useCSVUploader({
    projectId,
    sheetKey,
  });
  const { user } = useContext(AuthContext);

  const options = useMemo(
    () => ({
      sample_template_url: templateUrl,
      sample_template_button_text: templateName,
    }),
    [templateUrl, templateName],
  );

  if (!csvBoxSheetId) return null;
  return (
    <CSVBoxButton
      licenseKey={csvBoxSheetId}
      options={options}
      dynamicColumns={dynamicColumns}
      user={{
        user_id: user?.id,
        ...(projectId ? { project_id: projectId } : {}),
        ...(phbTagKey ? { phb_tag_key: phbTagKey } : {}),
      }}
      onImport={(result, data) => {
        // We check for ev_failed because if external validation fails result can be true
        if (result && !data?.ev_failed) {
          refetch();
          if (!projectId) {
            navigate('/projects?status=created');
          }
        } else {
          console.log('upload failed');
        }
      }}
      render={(launch, isLoading) => {
        return (
          <>
            {renderItem ? (
              renderItem(launch, isLoading)
            ) : (
              <Button
                color="secondary"
                variant="new"
                disabled={isLoading}
                onClick={launch}
                data-cy={source}
                size="small"
              >
                {buttonTitle}
              </Button>
            )}
          </>
        );
      }}
    >
      Import
    </CSVBoxButton>
  );
};

export default CSVUploader;
