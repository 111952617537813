import { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useSafeSnackbar } from '@hooks';

import {
  HookState,
  QueryNamesEnums,
  ITeam,
  UpdateCompanySettingsPayload,
  ICompanySettings,
  PermissionNamesEnums,
  IRoleNew,
  RolePayload,
} from '@interfaces';
import { getHookState, isRestricted } from '@utils';
import {
  getCompanyTeams,
  updateCompanySettings,
  getCompanySettings,
  getCompanyRoles,
  patchCompanyRole,
} from '@globalService';
import { PermissionsContext } from '@context';

export type ControllerInterface = {
  state: HookState;
  rolesInApprovalFlow: IRoleNew[];
  reviewsQuantity: number;
  fundingApproveRequired: boolean;
  isSettingsMutating: boolean;
  handleQuantityClick: (item: number) => () => void;
  requeredReviewsValuesArray: number[];
  handleFundingReviewClick: (value: boolean) => void;
  hasCompanyTeamsEditPermission: boolean;
  updateRole: (data: RolePayload) => void;
};

export const useCompanyWorkflow = (): ControllerInterface => {
  const { companyId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);

  const [reviewsQuantity, setReviewsQuantity] = useState<number | null>(null);
  const [fundingApproveRequired, setFundingApproveRequired] = useState<boolean | null>(null);
  const companyTeamsQuery = useQuery<{ results: ITeam[] }, Error>(
    [QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }],
    getCompanyTeams.bind(this, companyId),
  );

  const companyRoles = useQuery<IRoleNew[], Error>(
    [QueryNamesEnums.GET_COMPANY_ROLES, { companyId }],
    getCompanyRoles.bind(this),
  );

  const companySettingsQuery = useQuery<ICompanySettings, Error>(
    [QueryNamesEnums.GET_COMPANY_SETTINGS, { companyId }],
    getCompanySettings.bind(this, companyId),
  );

  useEffect(() => {
    const { data } = companySettingsQuery;
    if (!data) return;
    setReviewsQuantity(data.reviews_required_quantity || null);
    setFundingApproveRequired(data.funding_approve_required || null);
  }, [companySettingsQuery.data]);

  const updateRoleMutation = useMutation<Response, Error, RolePayload>(patchCompanyRole, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_ROLES]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onSettled: () => {},
  });

  const updateRole = useCallback(
    (data: RolePayload) => {
      updateRoleMutation.mutateAsync(data);
    },
    [updateRoleMutation],
  );

  const updateCompanySettingsMutation = useMutation<Response, Error, UpdateCompanySettingsPayload>(
    updateCompanySettings,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_SETTINGS, { companyId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleQuantityClick = (item: number) => () => {
    if (!updateCompanySettingsMutation.isLoading) {
      updateCompanySettingsMutation.mutateAsync({
        companyId,
        data: {
          reviews_required_quantity: item,
        },
      });
      setReviewsQuantity(item);
    }
  };

  const handleFundingReviewClick = (value: boolean) => {
    if (!updateCompanySettingsMutation.isLoading) {
      updateCompanySettingsMutation.mutateAsync({
        companyId,
        data: {
          funding_approve_required: value,
        },
      });
      setFundingApproveRequired(value);
    }
  };

  const requeredReviewsValuesArray = useMemo(() => {
    if (!companyTeamsQuery.data) return [];
    const maxLength =
      companyTeamsQuery.data?.results.length < 5 ? companyTeamsQuery.data?.results.length : 5;
    return Array.from({ length: maxLength }, (_, index) => index + 1);
  }, [companyTeamsQuery.data]);

  const hasCompanyTeamsEditPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.COMPANY_TEAMS_EDIT, permissions),
    [permissions],
  );

  return {
    state: getHookState(companyTeamsQuery),
    rolesInApprovalFlow: companyRoles.data,
    reviewsQuantity,
    fundingApproveRequired,
    isSettingsMutating: updateCompanySettingsMutation.isLoading,
    handleQuantityClick,
    requeredReviewsValuesArray,
    handleFundingReviewClick,
    hasCompanyTeamsEditPermission,
    updateRole,
  };
};
