import { useMutation, useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { ErrorDual, ICompanyOverview, QueryNamesEnums } from '@interfaces';
import { updateCompany } from '@globalService';
import { parsePathErrorDual } from '@utils';

export type ControllerInterface = {
  companyUpdate: (value: boolean) => void;
};

export const useMFASettings = (): ControllerInterface => {
  const queryClient = useQueryClient();

  const companyUpdate = (value: boolean) => {
    updateCompanyMutation.mutateAsync({ company: { is_mfa_enabled: value } });
  };

  const updateCompanyMutation = useMutation<Response, ErrorDual, ICompanyOverview>(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_MY_COMPANY);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  return { companyUpdate };
};
