import { useQuery } from 'react-query';
import { getCompanyProjects, getCompanyUser } from '@globalService';
import { HookState, IProject, IUser, QueryNamesEnums } from '@interfaces';
import { getHookState } from '@utils';

export interface ControllerInterface {
  state: HookState;
  user: IUser;
  projectsQueryState: HookState;
  projects: IProject[];
  showEllipsis: boolean;
  companyUrl: string;
}

export const useUserInfoModal = (companyId: string, userId: string): ControllerInterface => {
  const userQuery = useQuery<IUser, Error>(
    [QueryNamesEnums.GET_COMPANY_USER, { companyId, userId }],
    getCompanyUser.bind(this, companyId, userId),
    { enabled: Boolean(userId) && Boolean(companyId) },
  );

  const companyProjectsQuery = useQuery<{ results: IProject[]; count: number }, Error>(
    [QueryNamesEnums.GET_COMPANY_PROJECTS, { companyId }],
    getCompanyProjects.bind(this, companyId),
    { enabled: Boolean(companyId) },
  );

  return {
    state: getHookState(userQuery),
    user: userQuery.data,
    companyUrl: userQuery.data?.teams?.[0]?.company?.url || '',
    projectsQueryState: getHookState(companyProjectsQuery),
    projects: companyProjectsQuery.data?.results || [],
    showEllipsis: companyProjectsQuery.data?.count > 20,
  };
};
