import React, { useContext } from 'react';
import { Routes, Route, Link, matchPath, useLocation, Navigate } from 'react-router-dom';

import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { colors } from '@theme';
import { AuthContext } from '@context';
import { SettingsTab } from './components';
import UserTab from './UserTab';

const TABS = {
  USER: { label: 'Personal', value: 'personal' },
  SETTINGS: { label: 'Settings', value: 'settings' },
};

const Profile = () => {
  const { pathname } = useLocation();
  const match = matchPath('/profile/:tab', pathname);
  const { user } = useContext(AuthContext);

  return match?.params?.tab ? (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
      <Typography variant="h1" sx={{ px: { md: 3, xs: 2 }, pt: { md: 3, xs: 2 } }}>
        {`Hi, ${user.first_name}`}
      </Typography>

      <Box sx={{ mt: { md: 1.5, xs: 0 }, position: 'relative' }}>
        <Tabs value={match?.params.tab || 'personal'} TabIndicatorProps={{ children: <span /> }}>
          <Tab
            label={TABS.USER.label}
            value={TABS.USER.value}
            component={Link}
            to={`/profile/${TABS.USER.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
          />
          <Tab
            label={TABS.SETTINGS.label}
            value={TABS.SETTINGS.value}
            component={Link}
            to={`/profile/${TABS.SETTINGS.value}`}
          />
        </Tabs>
      </Box>
      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: { md: 3, xs: 2 } }}>
        <Routes>
          <Route path={TABS.USER.value} element={<UserTab />} />
          <Route path={TABS.SETTINGS.value} element={<SettingsTab />} />
        </Routes>
      </Stack>
    </Box>
  ) : (
    <Navigate to={`/profile/${TABS.USER.value}`} replace />
  );
};

export default Profile;
