import { useIsFetching, useIsMutating, useQuery } from 'react-query';
import { useMemo } from 'react';

import { getRequestRetainageRate, isReallocationEnabled, isRequestInReview } from '@utils';
import { IDrawRequest, IProject, MutationKeyEnum, QueryNamesEnums } from '@interfaces';
import { getDrawRequest, getProject } from '@globalService';

export const useRequestApproveSummary = ({ projectId, drawRequestId }) => {
  const isMutating = useIsMutating({
    predicate: ({ options }) =>
      options.mutationKey === MutationKeyEnum.MILESTONE_PATCH ||
      options.mutationKey === MutationKeyEnum.DRAW_REQUEST_PATCH,
  });

  const isFetchingRequest = useIsFetching({
    queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_TOTALS],
    exact: false,
  });

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId && projectId) },
  );

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const retainageRate = useMemo(
    () => getRequestRetainageRate(drawRequestQuery.data),
    [drawRequestQuery],
  );
  const drawRequest = useMemo(() => drawRequestQuery.data, [drawRequestQuery.data]);

  const isReallocationAllowed = useMemo(
    () => isReallocationEnabled(drawRequestQuery.data, projectQuery.data),
    [drawRequestQuery.data, projectQuery.data],
  );

  return {
    retainageRate,
    drawRequest,
    isInReview: isRequestInReview(drawRequest?.status),
    dataIsLoading: Boolean(isMutating || isFetchingRequest || drawRequestQuery.isFetching),
    isReallocationAllowed,
  };
};
