import React from 'react';
import { ComponentProps, hasCommentsColor, hasUnreadCommentsColor } from './interface';
import { colors } from '@theme';

const CommentIconWithIndicator = ({
  size = 24,
  color = colors.icons.gray,
  hasComments = false,
  hasUnreadComments = false,
}: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M2 15.065V6.01626C2 4.35042 3.34315 3 5 3H19C20.6569 3 22 4.35042 22 6.01626V15.065C22 16.7309 20.6569 18.0813 19 18.0813H14.7515L12.1463 20.3732C10.5298 21.7953 8 20.6411 8 18.4816V18.0813H5C3.34315 18.0813 2 16.7309 2 15.065ZM9.76118 17.1276C9.42328 16.4981 8.76132 16.0704 8 16.0704H5C4.44772 16.0704 4 15.6203 4 15.065V6.01626C4 5.46098 4.44772 5.01084 5 5.01084H19C19.5523 5.01084 20 5.46098 20 6.01626V15.065C20 15.6203 19.5523 16.0704 19 16.0704H14.7515C14.267 16.0704 13.799 16.2472 13.4345 16.568L10.8293 18.8599C10.506 19.1443 10 18.9135 10 18.4816V18.0813C10 18.0194 9.99722 17.9581 9.99177 17.8976C9.96792 17.6329 9.89306 17.383 9.77716 17.158L9.76118 17.1276ZM7 9C7 8.44772 7.44772 8 8 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10H8C7.44772 10 7 9.55228 7 9ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H13C13.5523 13 14 12.5523 14 12C14 11.4477 13.5523 11 13 11H8Z"
    />
    {hasComments && (
      <circle
        cx="19.8835"
        cy="5"
        r="4"
        fill={hasUnreadComments ? hasUnreadCommentsColor : hasCommentsColor}
      />
    )}
  </svg>
);

export default CommentIconWithIndicator;
