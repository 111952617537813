import React, { Dispatch, ReactElement, SetStateAction, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { ErrorDual, IDataFieldModel, IProject, IUser, QueryNamesEnums } from '@interfaces';
import { inviteProjectBorrowers, updateProjectFields } from '@globalService';
import { useLeavePageBlocker, useProjectFields, useSafeSnackbar } from '@hooks';
import { TEAM_ROLES } from '@constants';
import { useGetData } from '@context';
import { getErrorText } from '@utils';

interface ControllerInterface {
  borrowerDetailsFields: IDataFieldModel;
  borrowerUser: IUser;
  isNewBorrower: boolean;
  setIsNewBorrower: Dispatch<React.SetStateAction<boolean>>;
  setBorrowerUser: Dispatch<React.SetStateAction<IUser>>;
  handleAddBorrowerCompany: () => Promise<void>;
  goBack: () => void;
  project: Partial<IProject>;
  getLeavePageConfirmModal: () => ReactElement<string, string>;
  isUpdated: boolean;
  loading: boolean;
  error: string;
  setError: Dispatch<React.SetStateAction<string>>;
  inviteBorrowers: boolean;
  setInviteBorrowers: Dispatch<SetStateAction<boolean>>;
}

export const useAddBorrowerCompany = (): ControllerInterface => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'status', 'status_change_reason', 'id'],
    args: { projectId },
  });
  const { enqueueSnackbar } = useSafeSnackbar();
  const [inviteBorrowers, setInviteBorrowers] = useState(false);

  const [error, setError] = useState<string>('');

  const [isNewBorrower, setIsNewBorrower] = useState(false);
  const [borrowerUser, setBorrowerUser] = useState(null);
  const { CREATE_PROJECT_STEPS } = useProjectFields({ isNewBorrower });
  const borrowerDetailsFields = CREATE_PROJECT_STEPS[2].fields;
  const {
    borrower_email,
    borrower_company_name,
    borrower_company_state,
    borrower_company_address_1,
    borrower_company_city,
    borrower_company_zip_code,
    legal_entity,
    first_name,
    last_name,
    phone,
  } = borrowerDetailsFields;

  const isUpdated = useMemo(() => {
    return Object.values(borrowerDetailsFields).some((field) => field.isChanged);
  }, [borrowerDetailsFields]);

  const { getLeavePageConfirmModal, setTriggerExit } = useLeavePageBlocker({
    currentPagePathname: '/projects/*/add-borrower',
    confirmTitle: 'Exit adding borrower details',
    isUpdated,
  });

  const queryClient = useQueryClient();

  const sendInviteMutation = useMutation<Response, Error, { projectId: string }>(
    inviteProjectBorrowers,
    {
      onSuccess: () => {
        setTriggerExit(() => ({
          path: `/projects/${projectId}/overview`,
          isNavigationConfirmed: true,
        }));
        queryClient.invalidateQueries(QueryNamesEnums.GET_COMPANY_TEAMS);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const projectMutation = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMPANIES, { projectId }]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_TEAMS,
        { projectId, companyId: null },
      ]);
      if (inviteBorrowers) {
        sendInviteMutation.mutateAsync({ projectId });
      } else {
        setTriggerExit(() => ({
          path: `/projects/${projectId}/overview`,
          isNavigationConfirmed: true,
        }));
      }
    },
    onError: (error) => {
      projectMutation.reset();
      const errorText = getErrorText(error as ErrorDual);
      setError(errorText);
    },
  });

  const handleAddBorrowerCompany = async () => {
    const projectData = {
      companies:
        !borrowerUser?.id && !isNewBorrower
          ? []
          : [
              {
                role: TEAM_ROLES.Owner,
                ...(borrowerUser?.id
                  ? {
                      ...borrowerUser?.company,
                      members: [{ id: borrowerUser.id, email: borrowerUser.email }],
                    }
                  : {
                      name: borrower_company_name.value,
                      address: {
                        address_1: borrower_company_address_1.value,
                        city: borrower_company_city.value,
                        state: borrower_company_state.value?.name,
                        zip_code: borrower_company_zip_code.value,
                      },
                      members: [
                        {
                          email: borrower_email.value,
                          first_name: first_name.value,
                          last_name: last_name.value,
                          phone: phone.valueToSave,
                        },
                      ],
                    }),
              },
            ],
      legal_entity: legal_entity.value,
    } as Partial<IProject>;

    await projectMutation.mutateAsync({
      projectId,
      json: {
        ...projectData,
      },
    });
  };

  return {
    borrowerDetailsFields,
    borrowerUser,
    isNewBorrower,
    setIsNewBorrower,
    setBorrowerUser,
    handleAddBorrowerCompany,
    goBack: () => navigate(`/projects/${projectId}/people/`),
    project: project.data,
    getLeavePageConfirmModal,
    isUpdated,
    loading: projectMutation.isLoading || sendInviteMutation.isLoading,
    error,
    setError,
    inviteBorrowers,
    setInviteBorrowers,
  };
};
