import { currencyFormatter } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const inspectorAllowance: MilestoneListColumnTypeV2 = {
  field: 'inspector_allowance',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Inspection allowance ($)',
  description:
    'Implied cumulative amount eligible to be paid per the inspector across all line items',
  editable: false,
  valueFormatter: (value) => currencyFormatter(value, ''),
  minWidth: 120,
};

export default inspectorAllowance;
