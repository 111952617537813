import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.status.warning.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4879 5.88467L13.5121 2.40824C12.5764 1.86392 11.4236 1.86392 10.4879 2.40824L4.51206 5.88467C3.5764 6.42899 3 7.43493 3 8.52357V15.4764C3 16.5651 3.57639 17.571 4.51206 18.1153L10.4879 21.5918C11.4236 22.1361 12.5764 22.1361 13.5121 21.5918L19.4879 18.1153C20.4236 17.571 21 16.5651 21 15.4764V8.52357C21 7.43493 20.4236 6.42899 19.4879 5.88467ZM11.496 4.16751C11.8079 3.98607 12.1921 3.98607 12.504 4.16751L18.4799 7.64394C18.7918 7.82538 18.9839 8.16069 18.9839 8.52357V15.4764C18.9839 15.8393 18.7918 16.1746 18.4799 16.3561L12.504 19.8325C12.1921 20.0139 11.8079 20.0139 11.496 19.8325L5.5201 16.3561C5.20821 16.1746 5.01608 15.8393 5.01608 15.4764V8.52357C5.01608 8.16069 5.20821 7.82538 5.5201 7.64394L11.496 4.16751Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8115 13.8196C12.2682 13.8196 12.5822 13.4106 12.6415 12.9774L12.6547 12.8878C13.2621 12.7336 13.8292 12.4803 14.2552 12.0513C14.72 11.5832 15 10.9228 15 10.0156V9.98886C15 9.04068 14.6897 8.28351 14.1395 7.76764C13.594 7.25624 12.8324 7 11.9529 7C10.8698 7 10.036 7.39307 9.37693 8.01376C9.11851 8.25805 9 8.58889 9 8.90646C9 9.53726 9.46487 10.0646 10.0209 10.0646C10.2738 10.0646 10.5026 9.9477 10.6688 9.80624C11.0616 9.47211 11.4567 9.31626 11.9175 9.31626C12.2332 9.31626 12.4563 9.397 12.5959 9.51891C12.7285 9.63473 12.805 9.80386 12.805 10.0423V10.069C12.805 10.3217 12.7201 10.5072 12.5269 10.6568C12.3197 10.8173 11.983 10.9386 11.4807 11.0106C11.2511 11.0433 11.0497 11.1566 10.9163 11.3499C10.7821 11.5443 10.7332 11.7961 10.7692 12.0668L10.8869 12.9747C10.9459 13.4264 11.2628 13.8196 11.7173 13.8196H11.8115ZM10.4961 15.6147V15.6414C10.4961 16.4093 11.0229 17 11.7173 17C12.4117 17 12.9385 16.4093 12.9385 15.6414V15.6147C12.9385 14.8469 12.4117 14.2561 11.7173 14.2561C11.0229 14.2561 10.4961 14.8469 10.4961 15.6147Z"
      fill={color}
    />
  </svg>
);

export default Icon;
