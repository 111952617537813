import { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import {
  ErrorDual,
  QueryNamesEnums,
  IServiceAgency,
  IServiceOrderPayload,
  IServiceOrder,
  ServiceOrderStatusEnum,
  CommonServiceOrderPayload,
} from '@interfaces';
import {
  getServiceAgencies,
  postServiceOrderToProject,
  patchServiceOrderToProject,
  getProjectServiceOrdersList,
  deleteServiceOrder,
  scheduleServiceOrderToProject,
} from '@globalService';
import { AuthContext, useGetData } from '@context';
import { checkIsExternalUser, getTeamRole } from '@utils';
import { useSafeSnackbar, useServiceOrderStatusInvalidation } from '@hooks';

export const useServiceQueriesAndMutations = ({ projectId, onClose, serviceType }) => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isExternalUser = checkIsExternalUser(teamRole);
  const { enqueueSnackbar } = useSafeSnackbar();
  const handleServiceOrderChangeInvalidation = useServiceOrderStatusInvalidation();

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['property_existing_type', 'property_proposed_type'],
    args: { projectId },
  });

  const [serviceOrder, setServiceOrder] = useState(null);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const serviceAgenciesQuery = useQuery<{ results: IServiceAgency[] }, Error>(
    [QueryNamesEnums.GET_SERVICE_AGENCIES],
    getServiceAgencies.bind(this),
  );

  const serviceAgenciesList = useMemo(
    () =>
      serviceAgenciesQuery.data?.results
        ?.filter((agency) => agency?.service_types?.includes(serviceType.replace(/-/g, '_')))
        ?.map((agency) => ({
          value: agency.id,
          label: agency.display_name,
          logo: agency.logo,
          description: agency.description,
          isPremium: agency.is_premium,
        })) || [],
    [serviceAgenciesQuery.data?.results],
  );

  const deleteServiceOrderMutation = useMutation<
    Response,
    Error,
    { projectId: string; serviceOrderId: string }
  >(deleteServiceOrder, {
    onSuccess: () => {
      setServiceOrder(null);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  // we ask for all service orders with status CREATED for current service type
  // to delete them before creating a new one
  const stringQueryParams = useMemo(
    () =>
      serviceType
        ? `&service_type=${serviceType.replace(/-/g, '_')}&status=${ServiceOrderStatusEnum.CREATED}`
        : '',
    [serviceType],
  );
  const projectServicesQueryWithCreatedStatus = useQuery<{ results: IServiceOrder[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { projectId, stringQueryParams }],
    getProjectServiceOrdersList.bind(this, {
      projectId,
      stringQueryParams,
    }),
    {
      staleTime: 0,
    },
  );

  const createServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; json: IServiceOrderPayload }
  >(postServiceOrderToProject, {
    onSuccess: async (data) => {
      setServiceOrder(data);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const patchServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceOrderId: string; json: IServiceOrderPayload }
  >(patchServiceOrderToProject, {
    onSuccess: async (data: IServiceOrder) => {
      await handleSchedulingServiceOrder(data);
    },
    onError: (error, variables) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      handleServiceOrderChangeInvalidation({
        projectId,
        serviceOrderId: serviceOrder?.id,
        drawRequestId: variables?.json?.draw_request,
      });
    },
  });

  const scheduleServiceOrder = useMutation<Response, ErrorDual, CommonServiceOrderPayload>(
    scheduleServiceOrderToProject,
    {
      onSuccess: () => {
        setSuccessModalOpen(true);
      },
      onError: () => {
        const errorText = isExternalUser
          ? 'Project is not configured properly, please ask Customer Success for help'
          : 'Project is not configured properly, please check required fields';
        enqueueSnackbar(errorText, { variant: 'error' });
        onClose();
      },
      onSettled: () =>
        handleServiceOrderChangeInvalidation({ projectId, serviceOrderId: serviceOrder?.id }),
    },
  );

  const handleSchedulingServiceOrder = async (serviceOrder: IServiceOrder) => {
    await scheduleServiceOrder.mutateAsync({
      projectId,
      serviceOrderId: serviceOrder?.id,
    });
  };

  return {
    serviceAgenciesList,
    serviceAgenciesListIsLoading: serviceAgenciesQuery.isLoading,
    createServiceOrder,
    serviceOrder,
    patchServiceOrder,
    projectServicesQueryWithCreatedStatus,
    deleteServiceOrderMutation,
    isSuccessModalOpen,
    project: project.data,
  };
};
