import { useSnackbar } from 'notistack';
import { datadogRum } from '@datadog/browser-rum';

export const useSafeSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const safeEnqueueSnackbar = (message, options) => {
    if (message) {
      enqueueSnackbar(message, options);
    } else {
      // Provide a default message
      enqueueSnackbar('An unknown error occurred.', options);
      // Send an error to Datadog
      datadogRum.addError('Attempted to enqueue a snackbar without a message.');
    }
  };

  return { enqueueSnackbar: safeEnqueueSnackbar };
};
