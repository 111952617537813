import React, { useState } from 'react';
import {
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MfaImage from 'encirclelabs-assets/mfa.svg';

import { ButtonWithTooltip, DigitInput } from '@components';
import { colors } from '@theme';

interface MFAModalProps {
  open: boolean;
  logout: () => void;
  handleResendMfaRequest: () => void;
  sendMfaCode: (code: string, rememberMe: boolean) => void;
  isSendingMfaCode: boolean;
}

const MFAModal = ({
  open,
  logout,
  handleResendMfaRequest,
  sendMfaCode,
  isSendingMfaCode,
}: MFAModalProps) => {
  const [mfaCode, setMfaCode] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleMfaCodeChange = (event) => {
    setMfaCode(event.target.value);
  };

  const handleMfaCodeSubmit = () => {
    sendMfaCode(mfaCode, rememberMe);
    setMfaCode('');
    setRememberMe(false);
  };

  return (
    <Dialog aria-labelledby="mfa-modal" open={open} sx={{ zIndex: 999 }} data-cy="login__mfa_modal">
      <DialogContent sx={{ padding: 3, minWidth: 500 }}>
        <Stack spacing={4}>
          <Stack direction="column" alignItems="center" sx={{ p: 1 }}>
            <img src={MfaImage} style={{ maxWidth: '100%', width: '242px' }} alt="mfa" />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h2">Two-factor authentication</Typography>
            <Typography variant="body1">
              You have received an email which contains a two-factor login code. Enter it to
              continue.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <TextField
              label="6-digit code"
              variant="outlined"
              value={mfaCode}
              onChange={handleMfaCodeChange}
              inputProps={{ maxLength: 6, 'data-cy': 'login__mfa_modal__mfa_code__input' }}
              InputProps={{ inputComponent: DigitInput as any }}
              size="small"
            />
            <Stack>
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="flax-start">
                <Checkbox
                  sx={{ padding: 0 }}
                  checked={rememberMe}
                  onChange={(_e, value) => setRememberMe(value)}
                  data-cy="login__mfa_modal__remember_me__checkbox"
                />
                <Typography variant="body3">Don't ask again for 1 week</Typography>
              </Stack>
              <Stack ml={4} mt={0.5}>
                <Typography variant="label" color={colors.text.medium}>
                  We will not ask for verification code on your trusted device when you log in.
                  Select only if the device is not shared.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" pt={4}>
            <ButtonWithTooltip
              variant="text"
              onClick={handleResendMfaRequest}
              dataTestName="login__mfa_modal__resend_mfa_request__button"
              tooltipText={
                isSendingMfaCode
                  ? 'You are allowed to resend the code only once every 60 seconds'
                  : ''
              }
              disabled={isSendingMfaCode}
            >
              Didn't receive a code?
            </ButtonWithTooltip>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                variant="new"
                color="error"
                onClick={() => {
                  setMfaCode('');
                  setRememberMe(false);
                  logout();
                }}
                data-cy="login__mfa_modal__logout__button"
              >
                Logout
              </Button>
              <Button
                variant="contained"
                onClick={handleMfaCodeSubmit}
                disabled={mfaCode.length !== 6}
                data-cy="login__mfa_modal__verify_mfa__button"
              >
                Verify
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default MFAModal;
