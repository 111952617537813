import React, { FC, PropsWithChildren, useContext, useMemo } from 'react';
import { LDEvaluationDetail, LDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import { datadogRum } from '@datadog/browser-rum';

import { config } from '@config';
import { AuthContext } from '@context';

const { launchDarklyClientSideID } = config;
interface LaunchdarklyInterface {
  flag: string;
  flagRule?: string;
  withSubscribe: boolean;
}

export const Launchdarkly: FC<PropsWithChildren<LaunchdarklyInterface>> = ({
  flag,
  flagRule,
  children,
}) => {
  const flags = useFlags();
  const willRender = useMemo(
    () => (flagRule ? flags[flag] === flagRule : flags[flag]),
    [flags, flag, flagRule],
  );

  return willRender ? <>{children}</> : null;
};

export const LaunchdarklyProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { id, first_name, last_name, email, active_team } = user || {};

  const LDOptions = {
    clientSideID: launchDarklyClientSideID,
    ...(user && {
      context: {
        kind: 'user',
        key: id,
        email,
        name: `${first_name} ${last_name}`,
        company: active_team?.company?.name,
      },
    }),
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    options: {
      sendLDHeaders: false,
      logger: basicLogger({ level: 'warn' }),
      inspectors: [
        {
          type: 'flag-used' as const,
          name: 'dd-inspector',
          method: (key: string, detail: LDEvaluationDetail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  };

  return <LDProvider {...LDOptions}>{children}</LDProvider>;
};

export const useLaunchDarklyFlags = useFlags;
