import { ReactElement } from 'react';

import { useLeavePageBlocker } from '@hooks';

interface TabWithLeaveWarningProps {
  onSave: () => boolean;
  isUpdated: boolean;
  path: string;
  currentPagePathname: string;
}

interface ControllerInterface {
  handleSubmitClick: () => void;
  handleCloseClick: () => void;
  getLeavePageConfirmModal: () => ReactElement<string, string>;
}

export const useTabWithLeaveWarning = ({
  onSave,
  isUpdated,
  path,
  currentPagePathname,
}: TabWithLeaveWarningProps): ControllerInterface => {
  const { getLeavePageConfirmModal, setTriggerExit, setLeaveModalOpen } = useLeavePageBlocker({
    currentPagePathname,
    confirmText: 'Are you sure you want to leave? \n Unsaved changes will be lost.',
    isUpdated: isUpdated,
    confirmTitle: 'Warning',
  });

  const handleSubmitClick = async () => {
    const success = await onSave();
    if (success) setTriggerExit({ isNavigationConfirmed: true, path });
  };

  const handleCloseClick = () => {
    if (isUpdated) {
      setLeaveModalOpen(true);
      setTriggerExit({
        path,
        isNavigationConfirmed: false,
      });
    } else {
      setTriggerExit({ isNavigationConfirmed: true, path });
    }
  };

  return { handleSubmitClick, handleCloseClick, getLeavePageConfirmModal };
};
