import { useWebSocketsEvents } from '@hooks';
import { config } from '@config';
import { useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { AuthContext } from '@context';
import { IUser } from '@interfaces';

interface ControllerInterface {
  usersList: Partial<IUser>[];
}

export const useRealTimeUsers = (): ControllerInterface => {
  const { projectId } = useParams();
  const { user } = useContext(AuthContext);

  const { eventMessages: connectedUsersToProject } = useWebSocketsEvents(
    `${config.wssUrl}/${user.id}/project/${projectId}/`,
  );

  const usersList = useMemo(
    () => connectedUsersToProject.filter(({ id }) => id !== user.id),
    [connectedUsersToProject, user.id],
  );
  return { usersList };
};
