import { HookState, IPieChartData, IProjectProgressItem } from '@interfaces';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useRequestPaymentGraph } from './RequestGraphsController';
import { useProjectPaymentGraph } from './ProjectGraphsController';

export interface ControllerInterface {
  state: HookState;
  originalBorrowerEquity: Partial<IProjectProgressItem>;
  constructionHoldback: Partial<IProjectProgressItem>;
  originalScheduledValue: Partial<IProjectProgressItem>;
  originalValuePieChartData: IPieChartData[];
}

export interface ILabels {
  progress: string;
  scheduledValue: string;
  budget: string;
  contributed: string;
  remaining: string;
}

export const getLabels = (isRequestTab: boolean): ILabels => ({
  progress: isRequestTab ? 'Progress' : 'Budget progress',
  scheduledValue: 'Current schedule of values',
  budget: isRequestTab ? 'Draw breakdown' : 'Total budget breakdown',
  contributed: isRequestTab ? 'Contributed to date' : 'Contributed',
  remaining: isRequestTab ? 'Remaining after draw' : 'Remaining',
});

export const usePaymentGraph = (): ControllerInterface => {
  const { drawRequestId: isRequestTab } = useParams();
  const {
    getProjectProgressItems,
    state: projectState,
    getOriginalValuePieChartData,
  } = useProjectPaymentGraph();
  const {
    getRequestProgressItems,
    state: requestState,
    getRequestOriginalValuePieChartData,
  } = useRequestPaymentGraph();

  const { constructionHoldback, originalBorrowerEquity, originalScheduledValue } = useMemo(
    () => (isRequestTab ? getRequestProgressItems() : getProjectProgressItems()),
    [isRequestTab, getProjectProgressItems, getRequestProgressItems],
  );

  const originalValuePieChartData = useMemo(
    () => (isRequestTab ? getRequestOriginalValuePieChartData() : getOriginalValuePieChartData()),
    [isRequestTab, getOriginalValuePieChartData, getRequestOriginalValuePieChartData],
  );

  return {
    state: isRequestTab ? requestState : projectState,
    originalBorrowerEquity,
    constructionHoldback,
    originalScheduledValue,
    originalValuePieChartData,
  };
};
