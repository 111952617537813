export const sortAdditionalRequirementsFirst = (first, next) => {
  if (!first.is_submitted && next.is_submitted) {
    return -1;
  } else if (first.is_submitted && !next.is_submitted) {
    return 1;
  } else {
    return 0;
  }
};

export function refetchPooling({
  refetch,
  timeout = 2000,
  refetchCallback,
}: {
  refetch: CallableFunction;
  timeout?: number;
  refetchCallback?: CallableFunction;
}) {
  let refetchCounter = 0;

  setTimeout(function refetchRequest() {
    refetchCounter++;

    try {
      if (refetchCallback) refetch().then((query) => refetchCallback(query));
      else refetch();
    } catch (error) {
      console.log('error while fetching files:', error);
    }

    // eslint-disable-next-line no-undef
    if (refetchCounter < 5) setTimeout(refetchRequest, timeout);
  }, 2000);
}
