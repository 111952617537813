import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ChevronUpIcon } from '@svgAsComponents';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import { useUpdateUiSettings } from '@hooks';
import { SettingsContext } from '@context';
import { ExpandedIconPositionEnum } from '@interfaces';

interface HookInterface {
  expanded: boolean;
  getExpandButton: () => ReactElement;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

interface PropsInterface {
  tableKey?: string;
  initialState?: boolean;
  dataCy?: string;
  expandedIconPosition?: ExpandedIconPositionEnum;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { rotateIcon, ...other } = props;
  return <IconButton {...other} sx={{ p: 0 }} />;
})(({ theme, rotateIcon }) => ({
  transform: rotateIcon,
  marginLeft: '1rem',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ExpandMoreProps extends IconButtonProps {
  rotateIcon: string;
}

export const useExpandedState = ({
  tableKey,
  initialState,
  expandedIconPosition = ExpandedIconPositionEnum.TOP,
  dataCy,
}: PropsInterface): HookInterface => {
  const { updateSettings } = useUpdateUiSettings();
  const { settings } = useContext(SettingsContext);
  const [expanded, setExpanded] = useState<boolean>(initialState);

  const rotateIcon = useMemo(() => {
    const isDefaultRightPosition = expandedIconPosition === ExpandedIconPositionEnum.RIGHT;
    const expandedRotateDegree = isDefaultRightPosition ? 'rotate(180deg)' : 'rotate(0deg)';
    const changedRotateDegree = isDefaultRightPosition ? 'rotate(90deg)' : 'rotate(180deg)';
    return expanded ? expandedRotateDegree : changedRotateDegree;
  }, [expanded, expandedIconPosition]);

  useEffect(() => {
    if (!tableKey) return;
    const savedState = settings.personal_setting?.tables?.[tableKey]?.summary_expand_state;
    if (savedState !== undefined) setExpanded(savedState);
  }, [tableKey, settings]);

  const handleExpandClick = useCallback(() => {
    if (tableKey)
      updateSettings({
        personal_setting: {
          tables: {
            ...settings.personal_setting?.tables,
            [tableKey]: {
              ...settings.personal_setting?.tables?.[tableKey],
              summary_expand_state: !expanded,
            },
          },
        },
      });
    setExpanded(!expanded);
  }, [settings, tableKey, expanded]);

  const getExpandButton = useCallback(
    () => (
      <Stack sx={{ minWidth: '30px' }}>
        <ExpandMore
          rotateIcon={rotateIcon}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          data-cy={dataCy}
        >
          <ChevronUpIcon />
        </ExpandMore>
      </Stack>
    ),
    [expanded],
  );

  return { expanded, setExpanded, getExpandButton };
};
