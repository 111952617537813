import { useSafeSnackbar } from '@hooks';
import { useMutation, useQueryClient } from 'react-query';
import { updateCompany } from '@globalService';
import { ErrorDual, ICustomLink, ICompanyOverview, QueryNamesEnums } from '@interfaces';
import { checkIsOwner, getTeamRole, parsePathErrorDual } from '@utils';
import { useContext } from 'react';
import { AuthContext } from '@context';

interface ControllerInterface {
  linksToShow: ICustomLink[];
  saveCustomLink: (link: ICustomLink) => void;
  removeCustomLink: (link: ICustomLink) => void;
  isSubmitting: boolean;
}

export const useCompanyCustomLinks = (links: ICustomLink[]): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const queryClient = useQueryClient();
  const MAX_LINKS_COUNT = 2;

  const linksToShow = Array(MAX_LINKS_COUNT)
    .fill({ url: '', label: '' })
    .map((_item, index) => ({ url: '', label: '', ...links?.[index] }));

  const saveCustomLink = (link) => {
    let linksToUpdate = [];
    if (!links?.length) {
      linksToUpdate = [link];
    } else {
      if (!link.id) {
        linksToUpdate = [...links, link];
      } else linksToUpdate = links.map((item) => (item.id === link.id ? link : item));
    }
    updateCompanyMutation.mutateAsync({
      company: {
        custom_navigation_links: linksToUpdate,
      },
    });
  };

  const removeCustomLink = (link) => {
    updateCompanyMutation.mutateAsync({
      company: {
        custom_navigation_links: links.filter((item) => item.id !== link.id),
      },
    });
  };

  const updateCompanyMutation = useMutation<Response, ErrorDual, ICompanyOverview>(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_MY_COMPANY);
      if (!user?.company_information_first_updated_at && checkIsOwner(teamRole))
        queryClient.invalidateQueries(QueryNamesEnums.GET_USER);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  return {
    linksToShow,
    saveCustomLink,
    removeCustomLink,
    isSubmitting: updateCompanyMutation.isLoading,
  };
};
