import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { colors, fonts } from '../index';

export default function Chip() {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          backgroundColor: colors.background.gray,
        },
        label: {
          color: colors.secondary,
          fontWeight: 600,
          letterSpacing: 1,
          fontSize: '0.75rem',
          fontFamily: fonts.primary,
        },
        deleteIcon: {
          width: 15,
          height: 15,
          color: '#113858',
        },
      },
      defaultProps: {
        deleteIcon: <CloseIcon />,
      },
    },
  };
}
