import React from 'react';
import { Stack } from '@mui/material';
import { ComponentProps, useBreakdownGraph } from './controller';
import { PieChartItem } from '@components';

const BreakdownGraphs = ({ requestId, milestoneId }: ComponentProps) => {
  const { valuePieChartData } = useBreakdownGraph({
    requestId,
    milestoneId,
  });

  return (
    <Stack direction="row">
      <Stack sx={{ width: '100%', pl: 2 }}>
        <PieChartItem data={valuePieChartData} legend="label" padding={0} height={96} />
      </Stack>
    </Stack>
  );
};

export default BreakdownGraphs;
