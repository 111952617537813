import React from 'react';

const FillTableImage = ({ width = 184, height = 112 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 184 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100.324 111C100.165 110.829 100.007 110.659 99.8533 110.486C98.4822 108.974 97.2902 107.31 96.3011 105.527C95.242 103.593 94.4946 101.505 94.0861 99.3402C93.2755 95.0337 93.8542 90.5175 96.2794 86.9305C96.3401 86.8397 96.4029 86.749 96.4679 86.6582C96.4983 86.7706 96.5308 86.8808 96.5655 86.9931C97.0071 88.5135 97.5717 89.9957 98.2538 91.425C98.3037 91.5374 98.3579 91.6476 98.4142 91.7578C100.088 95.1755 102.384 98.2536 105.185 100.836C105.82 101.421 106.478 101.978 107.159 102.506V102.508C107.686 102.912 108.225 103.3 108.776 103.671C111.091 105.222 113.828 106.709 114.632 109.371C114.742 109.734 114.811 110.108 114.838 110.486C114.852 110.657 114.857 110.829 114.853 111H100.324Z"
      fill="#5E8376"
    />
    <path
      d="M110.558 111.001H110.263C110.261 110.968 110.254 110.934 110.25 110.901C110.228 110.761 110.202 110.623 110.172 110.487C109.931 109.428 109.39 108.451 108.412 107.896C108.089 107.716 107.747 107.575 107.391 107.474C106.691 107.263 105.95 107.168 105.239 107.025C105.055 106.988 104.873 106.945 104.695 106.897C103.783 106.674 102.93 106.259 102.192 105.681C101.288 104.92 100.521 104.011 99.9226 102.993C99.5255 102.35 99.161 101.688 98.8303 101.009C98.7848 100.921 98.7414 100.834 98.7003 100.745C97.9332 99.1169 97.3341 97.4147 96.9122 95.6653C96.6932 94.7668 96.5224 93.8572 96.4007 92.9405C96.1317 90.9485 96.091 88.9324 96.2794 86.9312C96.2859 86.8404 96.2967 86.7497 96.3054 86.6589C96.3249 86.4709 96.611 86.5379 96.5915 86.7237C96.5828 86.8145 96.5741 86.9031 96.5654 86.9939C96.3855 88.9156 96.4204 90.8514 96.6695 92.7655C96.6803 92.8627 96.6933 92.9578 96.7085 93.055C97.0753 95.7106 97.8532 98.2933 99.0145 100.711C99.0254 100.737 99.0384 100.761 99.0492 100.787C99.0514 100.789 99.0514 100.789 99.0514 100.791C99.3245 101.353 99.6185 101.903 99.9335 102.442C100.506 103.476 101.238 104.415 102.103 105.223C102.834 105.868 103.706 106.334 104.65 106.584C104.743 106.61 104.838 106.636 104.933 106.658C105.009 106.677 105.085 106.694 105.163 106.709C105.887 106.863 106.637 106.96 107.354 107.159C107.68 107.248 107.998 107.365 108.304 107.509C108.802 107.759 109.243 108.111 109.596 108.542C109.948 108.973 110.206 109.474 110.352 110.011C110.399 110.169 110.439 110.327 110.471 110.487C110.509 110.656 110.537 110.828 110.558 111.001Z"
      fill="white"
    />
    <path
      d="M98.9595 100.961C98.0962 101.311 97.136 101.344 96.2505 101.054C95.365 100.764 94.6118 100.169 94.1255 99.3764C94.0276 99.2156 94.2735 99.0565 94.3715 99.2174C94.8231 99.9565 95.5247 100.51 96.3499 100.779C97.175 101.048 98.0693 101.013 98.8712 100.682C99.0456 100.61 99.1329 100.89 98.9595 100.961Z"
      fill="white"
    />
    <path
      d="M104.614 106.667C105.369 104.923 105.438 102.959 104.806 101.167C104.743 100.99 105.022 100.902 105.085 101.079C105.74 102.946 105.664 104.989 104.874 106.802C104.798 106.975 104.539 106.839 104.614 106.667Z"
      fill="white"
    />
    <path
      d="M96.5791 92.7851C96.9415 92.7189 97.2817 92.564 97.5692 92.3344C97.8567 92.1047 98.0825 91.8074 98.2263 91.4692C98.2995 91.2956 98.5591 91.4316 98.4864 91.6041C98.3257 91.977 98.076 92.305 97.7589 92.5594C97.4418 92.8139 97.067 92.987 96.6674 93.0638C96.6303 93.0742 96.5906 93.0701 96.5565 93.0524C96.5223 93.0347 96.4962 93.0046 96.4835 92.9684C96.472 92.9315 96.4755 92.8915 96.4935 92.8571C96.5114 92.8228 96.5421 92.7969 96.5791 92.7851Z"
      fill="white"
    />
    <path
      d="M119.983 111.001H108.039C107.998 110.831 107.959 110.66 107.922 110.487C107.703 109.489 107.523 108.484 107.391 107.475C107.378 107.371 107.363 107.265 107.354 107.159C107.162 105.617 107.096 104.062 107.159 102.509V102.507C107.196 101.657 107.275 100.81 107.398 99.968C107.719 97.7915 108.381 95.679 109.361 93.708L109.374 93.6821C111.329 89.7645 114.515 86.5145 118.611 85.1078C118.718 85.0711 118.82 85.0365 118.926 85.002C118.885 85.11 118.843 85.218 118.802 85.3282C118.802 85.3304 118.8 85.3326 118.8 85.3347C118.235 86.8121 117.791 88.3325 117.471 89.8812V89.8833C117.445 90.0022 117.422 90.1232 117.398 90.2442C116.672 93.986 116.648 97.8296 117.328 101.58C117.331 101.586 117.331 101.593 117.333 101.599C117.605 103.085 117.989 104.547 118.481 105.975C118.989 107.442 119.615 108.97 119.901 110.487C119.934 110.66 119.962 110.831 119.983 111.001Z"
      fill="#5E8376"
    />
    <path
      d="M118.986 85.1264C118.924 85.1934 118.863 85.2604 118.802 85.3274C117.498 86.7543 116.357 88.3211 115.4 89.9991C115.35 90.0812 115.302 90.1677 115.257 90.2519C113.946 92.592 113.007 95.1208 112.474 97.7479C112.467 97.776 112.461 97.8041 112.456 97.8344C112.335 98.4459 112.237 99.0617 112.164 99.6819C111.995 100.852 112.012 102.042 112.216 103.206C112.411 104.16 112.825 105.055 113.427 105.821C113.484 105.899 113.544 105.976 113.607 106.052C113.657 106.113 113.709 106.171 113.761 106.231C114.47 107.042 115.287 107.787 115.785 108.755C116.061 109.29 116.202 109.885 116.195 110.486C116.193 110.658 116.181 110.83 116.158 111H115.861C115.888 110.83 115.902 110.658 115.905 110.486C115.914 110.019 115.823 109.556 115.638 109.127C115.198 108.111 114.355 107.34 113.631 106.527C113.505 106.387 113.386 106.242 113.271 106.097C112.678 105.373 112.248 104.53 112.01 103.625C111.749 102.475 111.685 101.288 111.821 100.116C111.893 99.3664 112.001 98.6187 112.146 97.8776C112.164 97.7803 112.183 97.6831 112.203 97.588C113.185 92.9325 115.398 88.6235 118.612 85.1069C118.672 85.04 118.735 84.9708 118.798 84.9038C118.926 84.7677 119.114 84.9903 118.986 85.1264Z"
      fill="white"
    />
    <path
      d="M112.28 97.9164C111.379 97.678 110.592 97.128 110.061 96.3647C109.529 95.6014 109.287 94.6745 109.377 93.7496C109.396 93.5625 109.688 93.583 109.669 93.7704C109.584 94.6316 109.809 95.4949 110.306 96.2047C110.803 96.9145 111.537 97.4239 112.378 97.6409C112.56 97.6883 112.461 97.9636 112.28 97.9164Z"
      fill="white"
    />
    <path
      d="M113.348 105.868C115.004 104.929 116.245 103.402 116.822 101.591C116.879 101.412 117.155 101.51 117.098 101.689C116.494 103.572 115.2 105.158 113.474 106.131C113.309 106.224 113.184 105.96 113.348 105.868Z"
      fill="white"
    />
    <path
      d="M115.316 89.9609C115.645 90.1256 116.01 90.2062 116.379 90.1954C116.747 90.1846 117.107 90.0828 117.426 89.8991C117.589 89.8044 117.714 90.0688 117.552 90.1628C117.198 90.3642 116.801 90.4761 116.394 90.489C115.987 90.5018 115.584 90.4151 115.218 90.2364C115.182 90.2225 115.153 90.1954 115.136 90.1608C115.12 90.1262 115.117 90.0865 115.129 90.0499C115.142 90.0135 115.169 89.9837 115.204 89.9671C115.239 89.9504 115.279 89.9482 115.316 89.9609Z"
      fill="white"
    />
    <path
      d="M183.111 110.743C183.111 110.777 183.105 110.811 183.092 110.842C183.079 110.873 183.06 110.902 183.036 110.926C183.012 110.949 182.983 110.968 182.952 110.981C182.921 110.994 182.887 111.001 182.853 111.001H89.8099C89.7415 111.001 89.6759 110.974 89.6276 110.925C89.5792 110.877 89.552 110.812 89.552 110.743C89.552 110.675 89.5792 110.61 89.6276 110.562C89.6759 110.513 89.7415 110.486 89.8099 110.486H182.853C182.887 110.486 182.921 110.493 182.952 110.506C182.983 110.519 183.012 110.538 183.036 110.561C183.06 110.585 183.079 110.614 183.092 110.645C183.105 110.676 183.111 110.71 183.111 110.743Z"
      fill="#BCBCBC"
    />
    <path
      d="M112.941 13.6138H1.61931C1.56182 13.6138 1.5067 13.591 1.46605 13.5505C1.42541 13.51 1.40257 13.455 1.40257 13.3977C1.40257 13.3404 1.42541 13.2855 1.46605 13.2449C1.5067 13.2044 1.56182 13.1816 1.61931 13.1816H112.941C112.999 13.1816 113.054 13.2044 113.095 13.2449C113.135 13.2855 113.158 13.3404 113.158 13.3977C113.158 13.455 113.135 13.51 113.095 13.5505C113.054 13.591 112.999 13.6138 112.941 13.6138Z"
      fill="#D2D2D2"
    />
    <path
      d="M112.941 22.2583H1.61931C1.56182 22.2583 1.5067 22.2356 1.46605 22.195C1.42541 22.1545 1.40257 22.0996 1.40257 22.0423C1.40257 21.9849 1.42541 21.93 1.46605 21.8895C1.5067 21.8489 1.56182 21.8262 1.61931 21.8262H112.941C112.999 21.8262 113.054 21.8489 113.095 21.8895C113.135 21.93 113.158 21.9849 113.158 22.0423C113.158 22.0996 113.135 22.1545 113.095 22.195C113.054 22.2356 112.999 22.2583 112.941 22.2583Z"
      fill="#D2D2D2"
    />
    <path
      d="M112.941 30.9009H1.61931C1.56182 30.9009 1.5067 30.8781 1.46605 30.8376C1.42541 30.7971 1.40257 30.7421 1.40257 30.6848C1.40257 30.6275 1.42541 30.5726 1.46605 30.532C1.5067 30.4915 1.56182 30.4688 1.61931 30.4688H112.941C112.999 30.4688 113.054 30.4915 113.095 30.532C113.135 30.5726 113.158 30.6275 113.158 30.6848C113.158 30.7421 113.135 30.7971 113.095 30.8376C113.054 30.8781 112.999 30.9009 112.941 30.9009Z"
      fill="#D2D2D2"
    />
    <path
      d="M112.941 39.5455H1.61931C1.56182 39.5455 1.5067 39.5227 1.46605 39.4822C1.42541 39.4416 1.40257 39.3867 1.40257 39.3294C1.40257 39.2721 1.42541 39.2171 1.46605 39.1766C1.5067 39.136 1.56182 39.1133 1.61931 39.1133H112.941C112.999 39.1133 113.054 39.136 113.095 39.1766C113.135 39.2171 113.158 39.2721 113.158 39.3294C113.158 39.3867 113.135 39.4416 113.095 39.4822C113.054 39.5227 112.999 39.5455 112.941 39.5455Z"
      fill="#D2D2D2"
    />
    <path
      d="M112.941 48.188H1.61931C1.56182 48.188 1.5067 48.1653 1.46605 48.1247C1.42541 48.0842 1.40257 48.0293 1.40257 47.9719C1.40257 47.9146 1.42541 47.8597 1.46605 47.8191C1.5067 47.7786 1.56182 47.7559 1.61931 47.7559H112.941C112.999 47.7559 113.054 47.7786 113.095 47.8191C113.135 47.8597 113.158 47.9146 113.158 47.9719C113.158 48.0293 113.135 48.0842 113.095 48.1247C113.054 48.1653 112.999 48.188 112.941 48.188Z"
      fill="#D2D2D2"
    />
    <path
      d="M27.9805 48.5239C27.9232 48.5239 27.8683 48.5013 27.8277 48.461C27.7871 48.4208 27.7641 48.3661 27.7637 48.309L27.547 13.3034C27.5466 13.2461 27.5691 13.191 27.6095 13.1502C27.6499 13.1094 27.7049 13.0863 27.7624 13.0859H27.7637C27.821 13.086 27.8759 13.1085 27.9165 13.1488C27.9571 13.189 27.9801 13.2436 27.9805 13.3007L28.1972 48.3065C28.1975 48.3638 28.175 48.4189 28.1346 48.4596C28.0943 48.5004 28.0393 48.5235 27.9818 48.5239H27.9805Z"
      fill="#D2D2D2"
    />
    <path
      d="M58.1063 48.5239C58.049 48.5239 57.9941 48.5013 57.9535 48.461C57.9129 48.4208 57.8899 48.3661 57.8896 48.309L57.6728 13.3034C57.6725 13.2461 57.6949 13.191 57.7353 13.1502C57.7757 13.1094 57.8307 13.0863 57.8882 13.0859H57.8896C57.9468 13.086 58.0017 13.1085 58.0423 13.1488C58.0829 13.189 58.1059 13.2436 58.1063 13.3007L58.323 48.3065C58.3233 48.3638 58.3008 48.4189 58.2605 48.4596C58.2201 48.5004 58.1651 48.5235 58.1077 48.5239H58.1063Z"
      fill="#D2D2D2"
    />
    <path
      d="M88.2321 48.5239C88.1749 48.5239 88.1199 48.5013 88.0793 48.461C88.0387 48.4208 88.0157 48.3661 88.0154 48.309L87.7986 13.3034C87.7983 13.2461 87.8208 13.191 87.8612 13.1502C87.9016 13.1094 87.9565 13.0863 88.014 13.0859H88.0154C88.0726 13.086 88.1275 13.1085 88.1681 13.1488C88.2087 13.189 88.2317 13.2436 88.2321 13.3007L88.4488 48.3065C88.4492 48.3638 88.4267 48.4189 88.3863 48.4596C88.3459 48.5004 88.2909 48.5235 88.2335 48.5239H88.2321Z"
      fill="#D2D2D2"
    />
    <path
      d="M24.4044 6.27961H3.81477C3.03878 6.27961 2.29457 5.97227 1.74586 5.42519C1.19715 4.87812 0.888885 4.13613 0.888885 3.36246C0.888885 2.58878 1.19715 1.8468 1.74586 1.29972C2.29457 0.752653 3.03878 0.445312 3.81477 0.445312H24.4044C25.1804 0.445312 25.9246 0.752653 26.4733 1.29972C27.022 1.8468 27.3303 2.58878 27.3303 3.36246C27.3303 4.13613 27.022 4.87812 26.4733 5.42519C25.9246 5.97227 25.1804 6.27961 24.4044 6.27961Z"
      fill="#D2D2D2"
    />
    <path
      d="M76.5205 28.6446H68.2612C67.632 28.6446 67.0286 28.3954 66.5837 27.9518C66.1388 27.5082 65.8889 26.9066 65.8889 26.2793C65.8889 25.652 66.1388 25.0504 66.5837 24.6068C67.0286 24.1633 67.632 23.9141 68.2612 23.9141H76.5205C76.8322 23.9138 77.141 23.9747 77.4291 24.0935C77.7171 24.2122 77.9789 24.3864 78.1995 24.606C78.42 24.8257 78.5949 25.0865 78.7143 25.3736C78.8337 25.6608 78.8951 25.9685 78.8951 26.2793C78.8951 26.5901 78.8337 26.8979 78.7143 27.185C78.5949 27.4721 78.42 27.7329 78.1995 27.9526C77.9789 28.1723 77.7171 28.3464 77.4291 28.4652C77.141 28.5839 76.8322 28.6449 76.5205 28.6446Z"
      fill="#A6BFB6"
    />
    <path
      d="M76.5228 37.9356H68.2635C67.9518 37.9359 67.643 37.8749 67.3549 37.7562C67.0669 37.6375 66.8051 37.4633 66.5845 37.2436C66.364 37.024 66.189 36.7631 66.0697 36.476C65.9503 36.1889 65.8889 35.8811 65.8889 35.5703C65.8889 35.2595 65.9503 34.9518 66.0697 34.6647C66.189 34.3775 66.364 34.1167 66.5845 33.897C66.8051 33.6774 67.0669 33.5032 67.3549 33.3845C67.643 33.2657 67.9518 33.2048 68.2635 33.2051H76.5228C76.8345 33.2048 77.1432 33.2657 77.4313 33.3845C77.7194 33.5032 77.9812 33.6774 78.2017 33.897C78.4223 34.1167 78.5972 34.3775 78.7166 34.6647C78.8359 34.9518 78.8974 35.2595 78.8974 35.5703C78.8974 35.8811 78.8359 36.1889 78.7166 36.476C78.5972 36.7631 78.4223 37.024 78.2017 37.2436C77.9812 37.4633 77.7194 37.6375 77.4313 37.7562C77.1432 37.8749 76.8345 37.9359 76.5228 37.9356Z"
      fill="#A6BFB6"
    />
    <path
      d="M76.5228 46.2871H68.2635C67.9518 46.2874 67.643 46.2265 67.3549 46.1078C67.0669 45.989 66.8051 45.8148 66.5845 45.5952C66.364 45.3755 66.189 45.1147 66.0697 44.8276C65.9503 44.5404 65.8889 44.2327 65.8889 43.9219C65.8889 43.6111 65.9503 43.3033 66.0697 43.0162C66.189 42.7291 66.364 42.4683 66.5845 42.2486C66.8051 42.0289 67.0669 41.8548 67.3549 41.736C67.643 41.6173 67.9518 41.5563 68.2635 41.5566H76.5228C76.8345 41.5563 77.1432 41.6173 77.4313 41.736C77.7194 41.8548 77.9812 42.0289 78.2017 42.2486C78.4223 42.4683 78.5972 42.7291 78.7166 43.0162C78.8359 43.3033 78.8974 43.6111 78.8974 43.9219C78.8974 44.2327 78.8359 44.5404 78.7166 44.8276C78.5972 45.1147 78.4223 45.3755 78.2017 45.5952C77.9812 45.8148 77.7194 45.989 77.4313 46.1078C77.1432 46.2265 76.8345 46.2874 76.5228 46.2871Z"
      fill="#A6BFB6"
    />
    <path
      d="M76.5205 20.4336H68.2612C67.632 20.4336 67.0286 20.1844 66.5837 19.7409C66.1388 19.2973 65.8889 18.6957 65.8889 18.0684C65.8889 17.4411 66.1388 16.8395 66.5837 16.3959C67.0286 15.9523 67.632 15.7031 68.2612 15.7031H76.5205C76.8322 15.7028 77.1409 15.7638 77.429 15.8825C77.7171 16.0013 77.9789 16.1754 78.1994 16.3951C78.42 16.6148 78.5949 16.8756 78.7143 17.1627C78.8336 17.4498 78.8951 17.7576 78.8951 18.0684C78.8951 18.3792 78.8336 18.6869 78.7143 18.974C78.5949 19.2612 78.42 19.522 78.1994 19.7417C77.9789 19.9613 77.7171 20.1355 77.429 20.2542C77.1409 20.373 76.8322 20.4339 76.5205 20.4336Z"
      fill="#A6BFB6"
    />
    <path
      d="M124.655 64.0821H116.396C115.766 64.0821 115.163 63.8329 114.718 63.3893C114.273 62.9457 114.023 62.3441 114.023 61.7168C114.023 61.0895 114.273 60.4879 114.718 60.0443C115.163 59.6008 115.766 59.3516 116.396 59.3516H124.655C125.284 59.3516 125.887 59.6008 126.332 60.0443C126.777 60.4879 127.027 61.0895 127.027 61.7168C127.027 62.3441 126.777 62.9457 126.332 63.3893C125.887 63.8329 125.284 64.0821 124.655 64.0821Z"
      fill="#1F4739"
    />
    <path
      d="M53.6271 6.38703H33.0375C32.2615 6.38703 31.5173 6.07969 30.9686 5.53262C30.4199 4.98555 30.1116 4.24356 30.1116 3.46988C30.1116 2.69621 30.4199 1.95422 30.9686 1.40715C31.5173 0.860075 32.2615 0.552734 33.0375 0.552734H53.6271C54.4031 0.552734 55.1473 0.860075 55.696 1.40715C56.2447 1.95422 56.553 2.69621 56.553 3.46988C56.553 4.24356 56.2447 4.98555 55.696 5.53262C55.1473 6.07969 54.4031 6.38703 53.6271 6.38703Z"
      fill="#D2D2D2"
    />
    <path
      d="M82.7377 6.60383H62.1481C61.3721 6.60383 60.6279 6.29648 60.0792 5.74941C59.5305 5.20234 59.2222 4.46035 59.2222 3.68668C59.2222 2.913 59.5305 2.17101 60.0792 1.62394C60.6279 1.07687 61.3721 0.769531 62.1481 0.769531H82.7377C83.5137 0.769531 84.2579 1.07687 84.8066 1.62394C85.3553 2.17101 85.6636 2.913 85.6636 3.68668C85.6636 4.46035 85.3553 5.20234 84.8066 5.74941C84.2579 6.29648 83.5137 6.60383 82.7377 6.60383Z"
      fill="#D2D2D2"
    />
    <path
      d="M112.073 6.60383H91.4831C90.7071 6.60383 89.9629 6.29648 89.4142 5.74941C88.8655 5.20234 88.5572 4.46035 88.5572 3.68668C88.5572 2.913 88.8655 2.17101 89.4142 1.62394C89.9629 1.07687 90.7071 0.769531 91.4831 0.769531H112.073C112.849 0.769531 113.593 1.07687 114.142 1.62394C114.69 2.17101 114.999 2.913 114.999 3.68668C114.999 4.46035 114.69 5.20234 114.142 5.74941C113.593 6.29648 112.849 6.60383 112.073 6.60383Z"
      fill="#D2D2D2"
    />
    <path
      d="M12.8035 5.17305L14.7764 1.9671L14.1822 1.88967L15.3829 4.90181C15.408 4.98329 15.4634 5.05211 15.5377 5.0942C15.6121 5.13629 15.6998 5.14845 15.7828 5.1282C15.8656 5.10484 15.9359 5.04991 15.9784 4.97528C16.0209 4.90066 16.0322 4.81236 16.0099 4.72948L14.8091 1.71734C14.7904 1.65734 14.7548 1.60396 14.7065 1.56352C14.6583 1.52307 14.5994 1.49725 14.5369 1.4891C14.4744 1.48096 14.4108 1.49083 14.3538 1.51756C14.2967 1.54428 14.2486 1.58674 14.215 1.63991L12.2421 4.84586C12.0226 5.20242 12.5848 5.52844 12.8035 5.17305Z"
      fill="white"
    />
    <path
      d="M13.2494 4.09389L14.9697 4.06818C15.0559 4.06818 15.1386 4.03403 15.1996 3.97324C15.2605 3.91245 15.2948 3.83001 15.2948 3.74405C15.2948 3.65809 15.2605 3.57564 15.1996 3.51485C15.1386 3.45407 15.0559 3.41992 14.9697 3.41992L13.2494 3.44564C13.1632 3.44564 13.0805 3.47978 13.0195 3.54057C12.9586 3.60136 12.9243 3.6838 12.9243 3.76976C12.9243 3.85573 12.9586 3.93817 13.0195 3.99896C13.0805 4.05974 13.1632 4.09389 13.2494 4.09389Z"
      fill="white"
    />
    <path
      d="M44.4594 3.39487C44.4149 3.30645 44.3566 3.22563 44.2867 3.15541C44.3968 3.01841 44.4606 2.85026 44.4691 2.67495C44.4776 2.49965 44.4303 2.32614 44.3341 2.17919C44.1733 1.93997 43.9294 1.76856 43.6494 1.69801C43.3194 1.63336 42.9794 1.64049 42.6525 1.71893C42.6306 1.71382 42.6082 1.71117 42.5857 1.71102C42.5 1.71241 42.4181 1.74701 42.3574 1.80749C42.2967 1.86798 42.262 1.94961 42.2606 2.03514C42.2224 2.88685 42.1841 3.73855 42.1459 4.59024C42.1326 4.60992 42.1218 4.63112 42.1136 4.65339C42.0962 4.72804 42.104 4.80635 42.1358 4.87611C42.1429 4.95722 42.1799 5.03283 42.2396 5.08835C42.2993 5.14387 42.3776 5.17535 42.4592 5.17671C42.5404 5.17685 42.6185 5.14551 42.6769 5.08931C43.3188 5.13693 43.9916 5.03286 44.3758 4.45208C44.4795 4.29778 44.5416 4.1195 44.5562 3.93436C44.5709 3.74922 44.5375 3.56345 44.4594 3.39487ZM43.7558 4.19483C43.6259 4.30573 43.4731 4.38682 43.3083 4.4324C43.1435 4.47797 42.9707 4.48691 42.802 4.45858C42.8338 3.74968 42.8657 3.04079 42.8975 2.3319C43.1899 2.28393 43.523 2.2583 43.7252 2.45459C43.7583 2.48637 43.7858 2.52343 43.8067 2.56424C43.8089 2.57001 43.8247 2.65085 43.823 2.62857C43.8266 2.67572 43.8281 2.65163 43.8152 2.69483C43.8016 2.73888 43.7764 2.77849 43.7423 2.80955C43.7082 2.84062 43.6663 2.86202 43.6211 2.87151C43.5355 2.87354 43.454 2.90835 43.3934 2.9687C43.3329 3.02905 43.298 3.11031 43.296 3.19564C43.297 3.28128 43.3316 3.36311 43.3923 3.42367C43.4531 3.48423 43.5352 3.51872 43.6211 3.51976C43.688 3.52164 43.7524 3.54592 43.8038 3.5887C43.8552 3.63148 43.8906 3.69026 43.9045 3.75558C43.9165 3.8353 43.9093 3.91674 43.8835 3.99313C43.8576 4.06952 43.8139 4.13867 43.7558 4.19483Z"
      fill="white"
    />
    <path
      d="M73.136 1.88507C72.6647 1.97224 72.2375 2.21784 71.9258 2.58095C71.6141 2.94405 71.4366 3.40271 71.423 3.88047C71.4026 4.38756 71.5825 4.8824 71.924 5.25868C72.3117 5.65501 72.849 5.65927 73.3629 5.59063C73.4482 5.58792 73.5294 5.5529 73.5898 5.49269C73.6501 5.43248 73.6853 5.35161 73.688 5.2665C73.688 5.10945 73.5382 4.91896 73.3629 4.94237C73.0281 4.98709 72.622 5.05969 72.3672 4.78327C72.1489 4.51539 72.0441 4.17317 72.0752 3.82944C72.1003 3.50265 72.236 3.19406 72.4602 2.95434C72.6844 2.71462 72.9836 2.55799 73.3089 2.51017C73.3916 2.48672 73.4618 2.43177 73.5043 2.35718C73.5468 2.28258 73.5581 2.19433 73.5359 2.11146C73.5114 2.02963 73.4561 1.96041 73.3816 1.91823C73.307 1.87606 73.2191 1.86418 73.136 1.88507Z"
      fill="white"
    />
    <path
      d="M100.765 2.19074L100.848 5.31554C100.849 5.36515 100.861 5.41391 100.884 5.45816C100.906 5.50242 100.938 5.54103 100.978 5.57109C101.018 5.60116 101.063 5.6219 101.112 5.63177C101.161 5.64164 101.211 5.64039 101.26 5.62809C101.694 5.53793 102.083 5.30096 102.362 4.95729C102.642 4.61362 102.793 4.18436 102.791 3.74216C102.789 3.29997 102.634 2.87201 102.352 2.53071C102.07 2.18942 101.678 1.95576 101.243 1.86929C101.16 1.84859 101.073 1.86056 100.998 1.90271C100.924 1.94485 100.868 2.01395 100.844 2.09567C100.821 2.17854 100.833 2.26674 100.875 2.34131C100.918 2.41587 100.988 2.47084 101.071 2.49439C101.368 2.54286 101.639 2.69471 101.835 2.92305C102.03 3.15139 102.139 3.44148 102.141 3.74194C102.143 4.0424 102.038 4.33385 101.845 4.56466C101.652 4.79547 101.383 4.95074 101.087 5.00299L101.498 5.31554L101.415 2.19074C101.404 1.77439 100.754 1.77274 100.765 2.19074Z"
      fill="white"
    />
    <path
      d="M144.788 66.3045C144.584 66.0607 144.436 65.7752 144.355 65.4681C144.273 65.161 144.26 64.8399 144.316 64.5272C144.373 64.2146 144.497 63.918 144.681 63.6585C144.865 63.399 145.103 63.1828 145.38 63.0252L143.953 55.4355L147.664 56.9898L148.45 63.9588C148.684 64.4396 148.734 64.9897 148.589 65.5046C148.445 66.0196 148.115 66.4638 147.664 66.7529C147.213 67.042 146.671 67.1561 146.141 67.0735C145.611 66.9908 145.13 66.7172 144.788 66.3045Z"
      fill="#FAC9AD"
    />
    <path
      d="M140.449 108.031L137.791 108.031L136.527 97.8125L140.449 97.8127L140.449 108.031Z"
      fill="#FAC9AD"
    />
    <path
      d="M140.789 81.9079C140.789 81.9079 142.405 72.0385 143.922 69.4455C145.439 66.8525 144.954 60.7298 144.954 60.7298L143.475 60.6075C143.487 60.3609 143.454 60.1141 143.379 59.879C142.622 57.7769 132.074 58.5232 132.074 58.5232C132.074 58.5232 128.132 64.695 129.525 70.2035C130.918 75.712 132.435 86.3001 132.435 86.3001L136.997 103.626L140.898 103.41L140.483 93.6154L140.594 93.6513L140.789 81.9079Z"
      fill="#262626"
    />
    <path
      d="M146.848 31.0905L142.897 26.5059L136.556 26.3223L134.131 30.2096L133.529 34.4794L130.448 59.879C130.448 59.879 140.54 64.7019 145.97 62.3739L148.582 40.5982C149.665 36.2765 146.848 31.0905 146.848 31.0905Z"
      fill="#D2D2D2"
    />
    <path
      d="M140.027 24.8841C143.064 24.8841 145.525 22.4297 145.525 19.402C145.525 16.3743 143.064 13.9199 140.027 13.9199C136.99 13.9199 134.528 16.3743 134.528 19.402C134.528 22.4297 136.99 24.8841 140.027 24.8841Z"
      fill="#FAC9AD"
    />
    <path
      d="M146.652 18.1555C146.483 16.9519 145.666 15.8091 144.503 15.4445C144.041 14.1073 142.818 13.1227 141.466 12.6936C140.214 12.2957 138.866 12.3233 137.631 12.7721C136.397 13.2209 135.347 14.0656 134.646 15.1735C134.543 15.3357 134.225 15.734 134.234 15.9275C134.245 16.1733 134.622 16.427 134.811 16.582C135.281 16.9438 135.787 17.2554 136.322 17.5116C138.299 18.5336 137.641 20.1398 137.614 21.9598C137.601 22.8443 137.897 23.5995 138.654 24.1047C139.746 24.8338 141.308 24.5437 142.496 24.2511C143.844 23.919 144.964 22.7385 145.733 21.6002C146.414 20.5922 146.821 19.359 146.652 18.1555Z"
      fill="#262626"
    />
    <path d="M146.69 38.2266L148.83 61.6249L141.956 61.2573L146.69 38.2266Z" fill="#D2D2D2" />
    <path
      d="M123.372 61.1724C123.647 61.053 123.892 60.875 124.091 60.6508C124.29 60.4266 124.436 60.1618 124.521 59.8749C124.606 59.588 124.627 59.2861 124.582 58.9904C124.537 58.6947 124.427 58.4125 124.261 58.1635L128.672 52.3747L124.891 52.123L121.282 57.6587C120.881 57.9648 120.607 58.4069 120.511 58.9012C120.415 59.3956 120.504 59.9078 120.762 60.3409C121.019 60.774 121.427 61.0979 121.908 61.2511C122.39 61.4044 122.91 61.3764 123.372 61.1724Z"
      fill="#FAC9AD"
    />
    <path
      d="M135.864 30.0655L135.13 29.7812L134.422 30.0309C134.422 30.0309 132.206 32.136 131.835 34.343C131.669 35.3306 129.088 44.9863 129.088 44.9863L122.21 55.5031L125.931 57.9811L135.417 43.8108L135.864 30.0655Z"
      fill="#D2D2D2"
    />
    <path
      d="M140.992 107.254L140.226 107.254L137.234 106.041L135.63 107.254C134.724 107.254 133.855 107.613 133.214 108.252C132.574 108.89 132.214 109.757 132.214 110.66V110.771L140.992 110.771L140.992 107.254Z"
      fill="#262626"
    />
    <path
      d="M153.171 98.0681L151.289 99.9374L143.161 93.5871L145.939 90.8281L153.171 98.0681Z"
      fill="#FAC9AD"
    />
    <path
      d="M152.507 96.9434L152.055 97.5604L149.309 99.2545L149.346 101.261L149.346 101.261C148.812 101.991 148.59 102.902 148.73 103.795C148.87 104.687 149.36 105.488 150.091 106.021L150.181 106.086L155.356 99.0171L152.507 96.9434Z"
      fill="#262626"
    />
    <path
      d="M138.948 82.4492L151.301 95.1982L147.834 98.2234L138.125 90.6205L138.948 82.4492Z"
      fill="#262626"
    />
  </svg>
);

export default FillTableImage;
