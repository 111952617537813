import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { PaymentConfigurationEdit, Popup, WysiwygEditor } from '@components';
import * as Controller from './controller';
import { ComponentProps } from './interface';

const RequestPaymentConfigurationEditPopup: FC<ComponentProps> = ({ onClose, drawRequestId }) => {
  const controller = Controller.useEditRequestPaymentConfiguration({
    drawRequestId,
    onClose,
  });
  const {
    postFundingConstructionBudget,
    constructionHoldback,
    constructionHoldbackFraction,
    borrowerEquity,
    borrowerEquityFraction,
    handleSubmit,
    comment,
    disableSaveButton,
  } = controller;
  return (
    <Popup open maxWidth="md" title="Edit payment contribution for this draw">
      <Stack sx={{ width: '100%', mt: 3 }}>
        <Stack spacing={2}>
          <PaymentConfigurationEdit
            postFundingConstructionBudget={postFundingConstructionBudget}
            constructionHoldback={constructionHoldback}
            constructionHoldbackFraction={constructionHoldbackFraction}
            borrowerEquity={borrowerEquity}
            borrowerEquityFraction={borrowerEquityFraction}
            source="request__payment_configuration"
            budgetEditDisabled
            totalAmountLabel="Total approved amount ($)"
          />
          <WysiwygEditor editField={comment} source="request__payment_configuration__comment" />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7.5 }}>
          <Button
            variant="text"
            onClick={onClose}
            data-cy="request__payment_configuration__cancel__button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{ ml: '0.5rem' }}
            data-cy="request__payment_configuration__save__button"
            disabled={disableSaveButton}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default RequestPaymentConfigurationEditPopup;
