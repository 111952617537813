import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M6.05757 12.8927C3.17532 8.13846 6.52412 2 12 2C17.4759 2 20.8247 8.13846 17.9424 12.8927L12.6427 21.6344C12.3472 22.1219 11.6528 22.1219 11.3573 21.6344L6.05757 12.8927Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00003 6.75C8.00003 6.33579 8.3582 6 8.80003 6H15.2C15.6418 6 16 6.33579 16 6.75V11.25C16 11.6642 15.6418 12 15.2 12H8.80003C8.3582 12 8.00003 11.6642 8.00003 11.25V10.3175C7.99999 10.3144 7.99999 10.3113 8.00003 10.3081V6.75ZM8.80003 10.5055V11.25H9.60002H14.4H15.2V10.5V10.2624C15.1638 10.2428 15.1303 10.2175 15.101 10.1866L13.7584 8.77061L11.6561 10.4131C11.534 10.5085 11.3633 10.527 11.2211 10.4604L9.83655 9.81138L8.80003 10.5055ZM15.2 9.16211L14.099 8.00086C13.9555 7.84955 13.7098 7.83231 13.5439 7.96192L11.3449 9.67991L9.97891 9.03959C9.84639 8.97747 9.6881 8.98911 9.56753 9.06985L8.80003 9.5838V7.5V6.75H9.60002H14.4H15.2V7.5V9.16211ZM10.6 8.0625C10.6 7.75184 10.8686 7.5 11.2 7.5C11.5314 7.5 11.8 7.75184 11.8 8.0625C11.8 8.37316 11.5314 8.625 11.2 8.625C10.8686 8.625 10.6 8.37316 10.6 8.0625Z"
      fill="white"
    />
  </svg>
);

export default Icon;
