import React, { FC } from 'react';
import { Grid2 } from '@mui/material';

import { SearchBox } from '@components';

const DrawRequestsListFiltersV2: FC<{
  handleFiltersChange: (key: string, value: string[] | string) => void;
  filtersData: any;
}> = ({ handleFiltersChange, filtersData }) => {
  return (
    <>
      <Grid2>
        <SearchBox
          height="32px"
          search={filtersData?.q?.[0] || ''}
          onSubmit={(value) => handleFiltersChange('q', [value])}
          clearSearch={() => handleFiltersChange('q', [])}
        />
      </Grid2>
    </>
  );
};

export default DrawRequestsListFiltersV2;
