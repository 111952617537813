import React from 'react';
import { ComponentProps, defaultProps } from './interface';

const GalleryViewIcon = ({
  size = defaultProps.size,
  color = defaultProps.color,
}: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66732 1.33334H3.00065C2.08018 1.33334 1.33398 2.07954 1.33398 3.00001V5.66668C1.33398 6.58715 2.08018 7.33334 3.00065 7.33334H5.66732C6.58779 7.33334 7.33398 6.58715 7.33398 5.66668V3.00001C7.33398 2.07954 6.58779 1.33334 5.66732 1.33334ZM2.66732 3.00001C2.66732 2.81592 2.81656 2.66668 3.00065 2.66668H5.66732C5.85141 2.66668 6.00065 2.81592 6.00065 3.00001V5.66668C6.00065 5.85077 5.85141 6.00001 5.66732 6.00001H3.00065C2.81656 6.00001 2.66732 5.85077 2.66732 5.66668V3.00001ZM5.66732 8.66668H3.00065C2.08018 8.66668 1.33398 9.41287 1.33398 10.3333V13C1.33398 13.9205 2.08018 14.6667 3.00065 14.6667H5.66732C6.58779 14.6667 7.33398 13.9205 7.33398 13V10.3333C7.33398 9.41287 6.58779 8.66668 5.66732 8.66668ZM2.66732 10.3333C2.66732 10.1492 2.81656 10 3.00065 10H5.66732C5.85141 10 6.00065 10.1492 6.00065 10.3333V13C6.00065 13.1841 5.85141 13.3333 5.66732 13.3333H3.00065C2.81656 13.3333 2.66732 13.1841 2.66732 13V10.3333ZM10.334 8.66668H13.0007C13.9211 8.66668 14.6673 9.41287 14.6673 10.3333V13C14.6673 13.9205 13.9211 14.6667 13.0007 14.6667H10.334C9.41351 14.6667 8.66732 13.9205 8.66732 13V10.3333C8.66732 9.41287 9.41351 8.66668 10.334 8.66668ZM10.334 10C10.1499 10 10.0007 10.1492 10.0007 10.3333V13C10.0007 13.1841 10.1499 13.3333 10.334 13.3333H13.0007C13.1847 13.3333 13.334 13.1841 13.334 13V10.3333C13.334 10.1492 13.1847 10 13.0007 10H10.334ZM13.0007 1.33334H10.334C9.41351 1.33334 8.66732 2.07954 8.66732 3.00001V5.66668C8.66732 6.58715 9.41351 7.33334 10.334 7.33334H13.0007C13.9211 7.33334 14.6673 6.58715 14.6673 5.66668V3.00001C14.6673 2.07954 13.9211 1.33334 13.0007 1.33334ZM10.0007 3.00001C10.0007 2.81592 10.1499 2.66668 10.334 2.66668H13.0007C13.1847 2.66668 13.334 2.81592 13.334 3.00001V5.66668C13.334 5.85077 13.1847 6.00001 13.0007 6.00001H10.334C10.1499 6.00001 10.0007 5.85077 10.0007 5.66668V3.00001Z"
      fill={color}
    />
  </svg>
);

export default GalleryViewIcon;
