import { colors } from './index';

const palette = {
  primary: {
    main: colors.main.primary.main,
    light: colors.main.primary.light,
    dark: colors.main.primary.dark,
  },
  secondary: {
    main: colors.secondary,
    light: colors.secondary,
    dark: colors.secondary,
  },
  alert2: {
    main: colors.alert2,
  },
  primaryLight: {
    main: colors.primaryLight,
  },
};

export default palette;
