import { lineItemColumnsMap, lineItemColumnsToCSV, milestoneTotalsMap } from '@constants';
import { useMemo } from 'react';

export const useExportMilestoneListToCSV = ({ milestones, columns, totals }) => {
  const csvHeaders = useMemo(
    () =>
      columns
        .filter((column) => lineItemColumnsToCSV.includes(column.name))
        .map((column) => ({
          label: column.columnText,
          key: lineItemColumnsMap[column.name] || column.name,
        })),
    [columns],
  );

  const csvData = useMemo(
    () => [
      ...milestones,
      {
        ...totals,
        // TODO: remove this when naming in milestones and totals will be synced
        ...Object.entries(milestoneTotalsMap).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: totals?.[value] }),
          {},
        ),
        name: 'TOTAL',
      },
    ],
    [milestones, totals],
  );

  return {
    csvHeaders,
    csvData,
  };
};
