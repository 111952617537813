import React, { FC } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { percentFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { MilestoneListColumnType, ColumnLabel, CommonRowType, HeaderText } from './common';
import { ColumnV2Width, IMilestoneTotal } from '@interfaces';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  if (isBlocked) return <Skeleton width={'100%'} />;

  return (
    <Typography variant="body3" data-cy="previous_inspector_allowance_rate">
      {percentFormatter({ value: row.previous_inspector_allowance_rate })}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy="total_previous_inspector_allowance_rate">
      {percentFormatter({ value: row.previous_inspector_allowance_rate })}
    </Typography>
  );
};

const previousInspectorAllowanceRate: MilestoneListColumnType = {
  name: 'previous_inspector_allowance_rate',
  columnText: 'Previous inspection allowance (%)',
  renderCell: ({ row }) => <Cell row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText>
      <ColumnLabel>Previous inspection </ColumnLabel>
      <ColumnLabel> allowance (%)</ColumnLabel>
    </HeaderText>
  ),
  Footer: (row) => <Footer row={row} />,
};

export default previousInspectorAllowanceRate;
