import React from 'react';
import { Skeleton, Stack } from '@mui/material';

import { StyledBox } from '@components';
interface Props {
  type?: string;
}

const LoadingSkeleton = ({ type }: Props) => {
  if (type === 'overviewBlock') return <FiveInRow />;
  if (type === 'twoBlocks')
    return (
      <Stack direction={{ lg: 'row', md: 'column', sm: 'column', xs: 'column' }} spacing={2}>
        <StyledBox sx={{ flexGrow: 1 }}>
          <FiveInRow />
        </StyledBox>
        <StyledBox sx={{ flexGrow: 1 }}>
          <FiveInRow />
        </StyledBox>
      </Stack>
    );
  return <Skeleton sx={{ mb: 3 }} height={16} />;
};

export default LoadingSkeleton;

const FiveInRow = () => (
  <>
    <Skeleton sx={{ mt: 3, mb: 3 }} height={16} width="33%" />
    <Skeleton sx={{ mb: 3 }} height={16} width="66%" />
    <Skeleton sx={{ mb: 3 }} height={16} />
    <Skeleton sx={{ mb: 3 }} height={16} />
    <Skeleton sx={{ mb: 3 }} height={16} width="66%" />
  </>
);
