import React, { FC } from 'react';
import { CustomAutocomplete, Popup } from '@components';
import { Button, Stack } from '@mui/material';
import * as Controller from './controller';
import LoadingButton from '@mui/lab/LoadingButton';
import { ITeam } from '@interfaces';

const AddTeamMemberPopup: FC<{
  open: boolean;
  onClose: () => void;
  team: ITeam;
}> = ({ open, onClose, team }) => {
  const { member, handleAddTeamMember, isSubmitting, users, handleSearch, optionsLoading } =
    Controller.useAddTeamPopup(onClose, team);

  return (
    <Popup open={open} maxWidth="sm" title="Add member">
      <Stack sx={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
        <Stack spacing={2}>
          <CustomAutocomplete
            field={member}
            options={users}
            label="User email"
            required
            handleTextFieldChange={handleSearch}
            optionsLoading={optionsLoading}
            inputProps={{ 'data-cy': 'company_teams__add_team_member_email' }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 4 }}
          spacing={2}
        >
          <Button
            variant="text"
            onClick={() => onClose()}
            data-cy="company_teams__add_team_member_cancel_button"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            onClick={() => handleAddTeamMember()}
            data-cy="company_teams__add_team_member_confirm_button"
          >
            Add
          </LoadingButton>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default AddTeamMemberPopup;
