import React from 'react';
import { ProjectPaymentInfoV2 } from '../draw-request/components';
import { RequestPaymentInfoV2 } from '../';
import { Route, Routes } from 'react-router-dom';

const PaymentsTab = () => {
  return (
    <Routes>
      <Route path="all" element={<ProjectPaymentInfoV2 />} />
      <Route path="draw-requests/:drawRequestId" element={<RequestPaymentInfoV2 />} />
    </Routes>
  );
};

export default PaymentsTab;
