import { Link, LinkProps } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import includes from 'lodash/includes';
import { typography, colors } from '@theme';

interface StyledLinkProps extends LinkProps {
  variant?: string;
}

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => !includes(['variant'], prop),
})<StyledLinkProps>(({ variant }) => ({
  ...typography[variant || 'body2SemiBold'],
  color: colors.text.link,
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export default StyledLink;
