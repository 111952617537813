import React, { FC } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import { ConfirmationModal, DocumentUploaderWrapper } from '@components';
import { IDocumentProps, IDocumentsWrapperProps } from './interface';
import { DocumentsPanel, DocumentsTable } from './components';
import { useDocumentsTableWrapper } from './useDocumentsTableWrapper';

const Documents: FC<IDocumentProps> = (documentsProps) => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const { requestId, ...props } = documentsProps;
  const match = matchPath('/projects/:projectId/:tab/draw-requests/:drawRequestId', pathname);
  const drawRequestId = match?.params?.drawRequestId || requestId;
  const {
    isConfirmModalOpened,
    modalInfo,
    closeConfirmModal,
    confirmCallback,
    restoreDocument,
    deleteDocument,
  } = useDocumentsTableWrapper({
    prId: projectId || props.prId,
    requestId,
    milestoneId: props.milestoneId,
    inspectionId: props.inspectionId,
    serviceOrderId: props.serviceOrderId,
  });

  return (
    <>
      <DocumentsTableWrapper
        {...props}
        drawRequestId={drawRequestId}
        prId={projectId || props.prId}
        restoreDocument={restoreDocument}
        deleteDocument={deleteDocument}
      />
      <ConfirmationModal
        open={isConfirmModalOpened}
        title={modalInfo.title}
        text={modalInfo.text}
        onClose={closeConfirmModal}
        confirmCallback={confirmCallback}
        type={modalInfo.type}
        confirmButtonLabel={modalInfo.confirmButtonLabel}
      />
    </>
  );
};

export default Documents;

const DocumentsTableWrapper: FC<IDocumentsWrapperProps> = (documentsProps) => {
  return (
    <DocumentUploaderWrapper
      source={documentsProps.source}
      requestId={documentsProps.drawRequestId}
      milestoneId={documentsProps.milestoneId}
      milestoneSubmitId={documentsProps.milestoneSubmitId}
      inspectionId={documentsProps.inspectionId}
      serviceOrderId={documentsProps.serviceOrderId}
      prId={documentsProps.prId}
    >
      {({ handleOpenDocumentUploader }) => {
        return documentsProps.milestoneSubmitId || documentsProps.milestoneId ? (
          <DocumentsPanel
            handleOpenDocumentUploader={handleOpenDocumentUploader}
            {...documentsProps}
          />
        ) : (
          <DocumentsTable
            handleOpenDocumentUploader={handleOpenDocumentUploader}
            {...documentsProps}
          />
        );
      }}
    </DocumentUploaderWrapper>
  );
};
