import { menuClasses } from '@mui/material/Menu';
import { menuItemClasses } from '@mui/material/MenuItem';

import { colors } from '../index';

export default function Menu() {
  return {
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
      },
      defaultProps: {
        sx: {
          [`&&& .${menuClasses.paper}`]: {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '4px',
            border: `0.5px solid ${colors.neutral.lighter}`,
            marginTop: '2px',
          },
          [`&&& .${menuClasses.list} .${menuItemClasses.root}:hover`]: {
            backgroundColor: colors.neutral.lightest,
          },
          [`&&& .${menuClasses.list} .Mui-selected`]: {
            backgroundColor: colors.main.primary.lightest,
          },
          [`&&& .${menuClasses.list} .Mui-selected:hover`]: {
            backgroundColor: colors.main.primary.lighter,
          },
        },
      },
    },
  };
}
