import React, { memo, FC } from 'react';
import { useParams } from 'react-router-dom';
import { MilestoneListColumnType, CommonRowType, ColumnLabel, HeaderText } from './common';
import { StyledLink } from '@components';
import { ColumnV2Width } from '@interfaces';
import { Stack } from '@mui/material';

const LineItem: FC<{ row: CommonRowType }> = ({ row }) => {
  const { projectId } = useParams();
  return (
    <Stack justifyContent="flex-end" flexDirection="row" flexWrap="wrap">
      {Array.isArray(row.change_requests) &&
        row.change_requests.map((dr, index) => (
          <span key={index}>
            <StyledLink
              variant="body3SemiBold"
              to={`/projects/${projectId}/draws/draw-requests/${dr.id}`}
            >
              {dr.number}
            </StyledLink>
            {index !== row.change_requests.length - 1 && <span>, </span>}
          </span>
        ))}
    </Stack>
  );
};

const Memoized = memo(LineItem);

const changeRequestNumbers: MilestoneListColumnType = {
  name: 'change_requests',
  columnText: 'Change request #',
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.TEXT_MIN,
  maxWidth: () => ColumnV2Width.TEXT_MAX,
  Header: (
    <HeaderText tooltipText="Change requests associated with the line item">
      <ColumnLabel>Change</ColumnLabel>
      <ColumnLabel> request #</ColumnLabel>
    </HeaderText>
  ),
};

export default changeRequestNumbers;
