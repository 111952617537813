import React, { FC } from 'react';
import { Typography, Stack, Button } from '@mui/material';
import RestrictedAccessImage from 'encirclelabs-assets/restricted_access.svg';
import { useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

const PermissionDenied: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/projects');
  };

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100%', padding: '2rem' }}>
      <img
        src={RestrictedAccessImage}
        style={{ maxWidth: '100%', width: '350px' }}
        alt="rectricted access"
      />
      <Typography
        variant={isMobileOnly ? 'h4' : 'h2'}
        sx={{
          mb: { xs: 2, md: 4 },
          mt: { xs: 2, md: 8 },
          textAlign: 'center',
          whiteSpace: 'pre-wrap',
        }}
      >
        {
          'The page you are trying to access has restricted access. \nPlease, ask your account manager for help.'
        }
      </Typography>
      <Button onClick={handleNavigate}>Homepage</Button>
    </Stack>
  );
};

export default PermissionDenied;
