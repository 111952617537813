import React, { FC } from 'react';
import { InputField } from '@components';
import { Stack, Typography } from '@mui/material';
import { CheckboxIconRoundedChecked, CheckboxIconRoundedDefault } from '@svgAsComponents';
import { PasswordRule, ComponentProps } from './interface';

const PasswordRuleItem: FC<PasswordRule> = ({ text, validationError }) => {
  return (
    <Stack flexDirection="row" alignItems="center" mb={'8px'}>
      {(validationError && <CheckboxIconRoundedDefault />) || <CheckboxIconRoundedChecked />}
      <Typography variant="body3" ml={'8px'}>
        {text}
      </Typography>
    </Stack>
  );
};

const passwordValidationChecks = [
  { value: 'passwordLength', label: 'Min 8 characters' },
  { value: 'upperCase', label: 'Capital letter' },
  { value: 'number', label: 'Number' },
  { value: 'specialChar', label: 'Special symbol !@#$%^&*()_+ ' },
];

const CreatePasswordInputs: FC<ComponentProps> = ({ validationErrors }) => {
  const findError = (name: string) => {
    return validationErrors.find((e) => e.path === 'password' && e.message === name);
  };

  return (
    <>
      <InputField
        name="password"
        label="Password"
        type="password"
        variant="outlined"
        size="small"
      />
      {passwordValidationChecks.map((item, index) => (
        <PasswordRuleItem key={index} text={item.label} validationError={findError(item.value)} />
      ))}
      <InputField
        name="passwordConfirmation"
        label="Confirm password"
        type="password"
        variant="outlined"
        size="small"
        sx={{ mt: 4 }}
      />
    </>
  );
};

export default CreatePasswordInputs;
