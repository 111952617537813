import React, { Dispatch, FC, useState } from 'react';
import { IconButton, Popover, Stack, Typography } from '@mui/material';
import { CloseIcon, ExpandIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { FullScreenDialog, StyledBox } from '@components';

const ScopeOfWorkLongText: FC<{
  scopeOfWork: string;
  setAnchorEl: Dispatch<React.SetStateAction<EventTarget & HTMLSpanElement>>;
  anchorEl: Element;
}> = ({ scopeOfWork, anchorEl, setAnchorEl }) => {
  const [isOpenedModal, setIsOpenedModal] = useState(false);

  return (
    <>
      <Typography
        variant="body3SemiBold"
        component="span"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{
          color: colors.text.link,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        Read more
      </Typography>
      <Popover
        id="scope-of-work"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        marginThreshold={16}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '30rem',
            backgroundColor: colors.background.white,
            borderRadius: '2px',
            padding: '16px 16px 16px 8px',
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        <Stack>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={() => setIsOpenedModal(true)} sx={{ py: 0 }}>
              <ExpandIcon size={13} />
            </IconButton>
            <IconButton onClick={() => setAnchorEl(null)} sx={{ py: 0 }}>
              <CloseIcon size={12} />
            </IconButton>
          </Stack>
          <Typography variant="body3" sx={{ color: colors.text.medium }}>
            Scope of work
          </Typography>
          {scopeOfWork}
        </Stack>
      </Popover>
      <FullScreenDialog
        open={isOpenedModal}
        title="Scope of work"
        actionIcons={() => (
          <IconButton onClick={() => setIsOpenedModal(false)}>
            <ExpandIcon size={20} />
          </IconButton>
        )}
        content={() => (
          <StyledBox
            sx={{
              minWidth: '300px',
              maxWidth: '750px',
              height: '100%',
              overflow: 'scroll',
              whiteSpace: 'pre-line',
            }}
          >
            <Typography variant="body1"> {scopeOfWork}</Typography>
          </StyledBox>
        )}
        onClose={() => {
          setIsOpenedModal(false);
          setAnchorEl(null);
        }}
      />
    </>
  );
};

export default ScopeOfWorkLongText;
