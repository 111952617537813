import { MilestoneListColumnTypeV2 } from './common';
import { percentFormatter } from '@utils';

// WHY approved_amount_relative is percentFormatter ?
const approvedAmountRelative: MilestoneListColumnTypeV2 = {
  field: 'approved_amount_relative',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved for this draw (%)',
  description: '% of amount approved to fund for this draw to revised scheduled value',
  editable: false,
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  minWidth: 120,
};

export default approvedAmountRelative;
