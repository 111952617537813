import React, { useState, useEffect, useCallback, useContext } from 'react';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { Typography, Stack } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SSOContext } from '@context';
import { IdentityServiceTypeEnum } from '@interfaces';
import { AnimatedRedirect } from '../components';

const CustomLoginCallback = ({ errorComponent }) => {
  const { ssoType } = useContext(SSOContext);

  const navigate = useNavigate();
  const location = useLocation();
  // MICROSOFT SSO
  const { instance } = useMsal();
  const code = new URLSearchParams(location?.hash.slice(1)).get('code');
  // OKTA SSO
  const { authState } = useOktaAuth() || {};

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (ssoType === IdentityServiceTypeEnum.OKTA && authState?.isAuthenticated) {
      // Once the authentication is no longer pending, hide the loader
      setLoading(false);
    }
  }, [authState]);

  const handleResponse = useCallback(async () => {
    try {
      const response = await instance.handleRedirectPromise();
      if (response) {
        sessionStorage.setItem('ssoAccessToken', JSON.stringify(response?.accessToken));
        navigate('/login-with-sso', {
          state: { fromRedirect: true, ssoAccessToken: response?.accessToken },
        });
      } else {
        console.log('No response from handleRedirectPromise');
      }
    } catch (error) {
      console.error('Error handling redirect:', error);
    } finally {
      setLoading(false);
    }
  }, [instance]);

  useEffect(() => {
    if (code && ssoType === IdentityServiceTypeEnum.MICROSOFT) {
      handleResponse();
    }
  }, [code, ssoType]);

  return (
    <>
      {loading && (
        <Stack alignItems="center" sx={{ minHeight: '100vh' }}>
          <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }} spacing={6}>
            <AnimatedRedirect />

            <Typography variant="h2">Redirecting...</Typography>
          </Stack>
        </Stack>
      )}
      {ssoType === IdentityServiceTypeEnum.OKTA && (
        <LoginCallback errorComponent={errorComponent} />
      )}
    </>
  );
};

export default CustomLoginCallback;
