import React from 'react';

import SetPasswordForm from './SetupPassword';
import SimpleLogin from './SimpleLogin';

const LoginForm = () => {
  const accountToken = new URL(window.location.href).searchParams.get('accountToken');

  if (accountToken) return <SetPasswordForm />;

  return <SimpleLogin />;
};

export default LoginForm;
