import { MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter } from '@utils';

const originalConstructionBudget: MilestoneListColumnTypeV2 = {
  field: 'original_construction_budget',
  valueFormatter: (_value, row) => currencyFormatter(row.prefunding_cost + row.original_estimate),
  minWidth: 130,
  maxWidth: 200,
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Original construction budget($)',
  description: '% of cumulative changes to original scheduled value',
  editable: false,
};

export default originalConstructionBudget;
