import { FilterObject } from '../interface';
import { states } from '@constants';

export const statesFilter: FilterObject = {
  title: 'State',
  filterKey: 'state',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getStaticValues: () =>
    states.map((state) => ({
      label: state.name_display,
      value: state.name,
    })) as Array<{
      label: string;
      value: string;
    }>,
  cypressSelector: 'filters__states',
};
