import React from 'react';
import { Link, Typography } from '@mui/material';
import { colors } from '@theme';

export const StyledLinkExternal = ({ href, title }) => (
  <Link
    target="_blank"
    href={href}
    sx={{ textDecoration: 'none', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
  >
    <Typography variant="body3SemiBold" sx={{ color: colors.text.link }}>
      {title}
    </Typography>
  </Link>
);

export default StyledLinkExternal;
