import React, { FC } from 'react';
import TablePagination from '@mui/material/TablePagination';

import { ComponentProps } from './interface';
import { colors } from '@theme';

const TablePaginationComponent: FC<ComponentProps> = ({
  page,
  rowsPerPage,
  rowsPerPageOptions,
  itemsCount,
  onPageChange,
  onRowsPerPageChange,
  showFirstButton = false,
  showLastButton = false,
}) => {
  if (!itemsCount) return null;

  return (
    <TablePagination
      component="div"
      rowsPerPage={rowsPerPage}
      count={itemsCount}
      page={page}
      onPageChange={onPageChange}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowsPerPageChange={onRowsPerPageChange}
      showFirstButton={showFirstButton}
      showLastButton={showLastButton}
      labelRowsPerPage="Per page:"
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} records`}
      sx={{
        '.MuiToolbar-root': { paddingRight: 1 },
        '.MuiTablePagination-actions': {
          '& .MuiSvgIcon-root': {
            backgroundColor: colors.main.primary.lightest,
            color: colors.icons.green,
          },
          '& .Mui-disabled': {
            '& .MuiSvgIcon-root': {
              backgroundColor: colors.neutral.lightest,
              color: colors.neutral.medium,
            },
          },
        },
      }}
    />
  );
};

export default TablePaginationComponent;
