import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import {
  ChecklistApproveModal,
  ConfirmationModal,
  DocumentUploaderWithForm,
  StyledBox,
  UploaderWithForm,
} from '@components';
import { ChecklistItemLocal, PolicyListReasonsTypeEnum, PopupTypeEnum } from '@interfaces';
import { useConfirmationModal } from '@hooks';
import {
  AttestationItem,
  ChecklistItem,
  CompletionDateV2,
  DrawRequestForm,
  Inspection,
} from '../index';
import {
  IButtonController,
  IChecklistController,
  IFieldsController,
  IMilestoneList,
  IRequestController,
  IValidationController,
} from '../../main/interfaces';
import { isAttestationPolicyItem, isDrawRequest } from '@utils';
import snakeCase from 'lodash/snakeCase';
import { InfoIcon } from '@svgAsComponents';
import { colors } from '@theme';

const Checklist: FC<{
  buttonsController: IButtonController;
  checklistController: IChecklistController;
  fieldsController: IFieldsController;
  milestoneListController: IMilestoneList;
  requestController: IRequestController;
  validationController: IValidationController;
}> = ({
  buttonsController,
  checklistController,
  fieldsController,
  milestoneListController,
  requestController,
  validationController,
}) => {
  const { isConfirmModalOpened, askConfirm, closeConfirmModal, confirmCallback } =
    useConfirmationModal();
  const {
    fileUploader,
    photoUploader,
    handleDelete,
    checklistItems,
    isChecklistModalShow,
    setChecklistModalShow,
    drawRequest,
    selectedItem,
    documentTypes,
    projectId,
    selectedFileName,
    setSelectedFileName,
    progressPhotoTypeId,
  } = checklistController;

  const renderChecklistItem = (item: ChecklistItemLocal) => {
    if (isAttestationPolicyItem(item.type)) {
      return (
        <AttestationItem
          checklistController={checklistController}
          validationController={validationController}
          checklistItem={item}
          key={item.id}
          index={item.index}
          innerRef={item.itemRef}
        />
      );
    }
    return (
      <ChecklistItem
        checklistController={checklistController}
        validationController={validationController}
        checklistItem={item}
        askConfirm={({ fileName, documentId, checklistItemId }) => {
          setSelectedFileName(fileName);
          askConfirm({ documentId, checklistItemId });
        }}
        key={item.id}
        index={item.index}
        innerRef={item.itemRef}
      />
    );
  };

  return (
    <>
      {requestController.returnedRequestNotes && (
        <StyledBox sx={{ mb: 2 }}>
          <Stack direction="row" spacing={1} mb={1}>
            <InfoIcon />
            <Typography variant="h3" color={colors.status.information.medium}>
              Return request note
            </Typography>
          </Stack>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: requestController.returnedRequestNotes }}
            data-cy="request__submission__returned_request__reason__notes"
          />
        </StyledBox>
      )}
      {checklistItems
        .filter((item) => item.is_custom && item.created_by_team)
        .map((item) => renderChecklistItem(item))}
      <DrawRequestForm
        {...{
          checklistController,
          milestoneListController,
          requestController,
          validationController,
        }}
      />
      {isDrawRequest(drawRequest) && (
        <>
          <Box sx={{ mb: 2 }} />
          <Inspection
            {...{
              buttonsController,
              checklistController,
              fieldsController,
              validationController,
            }}
          />
          <Box sx={{ mb: 2 }} />
          <CompletionDateV2
            {...{
              buttonsController,
              checklistController,
              fieldsController,
              validationController,
            }}
          />
        </>
      )}
      <Box sx={{ mb: 2 }} />
      {checklistItems
        .filter((item) => item.is_custom && !item.created_by_team)
        .map((item) => renderChecklistItem(item))}
      {fileUploader.transloaditSignature && (
        <DocumentUploaderWithForm
          isOpen={fileUploader.isFilesUploaderOpened}
          documentTypes={documentTypes}
          closeUploader={() => {
            fileUploader.filesUploaderClose();
          }}
          transloaditSignature={fileUploader.transloaditSignature}
          restrictions={fileUploader.restrictions}
          source="request__submission__policy_items__documents"
          milestones={requestController.allMilestones}
          drawRequestId={drawRequest?.id}
          onUploadComplete={() =>
            validationController.onUploadComplete(
              fileUploader.transloaditSignature?.params?.fields?.object_id,
            )
          }
        />
      )}
      {photoUploader.transloaditSignature && (
        <UploaderWithForm
          isOpen={photoUploader.isFilesUploaderOpened}
          closeUploader={photoUploader.filesUploaderClose}
          transloaditSignature={photoUploader.transloaditSignature}
          restrictions={photoUploader.restrictions}
          refetchCallback={(refetch) => refetch?.()}
          refetch={[
            () =>
              validationController.onUploadComplete(
                photoUploader.transloaditSignature?.params?.fields?.object_id,
              ),
          ]}
          source="request__submission__policy_items__documents"
          projectId={projectId}
          drawRequestId={drawRequest?.id}
          documentTypeId={progressPhotoTypeId}
          showLineItems
        />
      )}

      <ConfirmationModal
        open={isConfirmModalOpened}
        title="Delete file"
        text={`You are about to delete the file "${selectedFileName}".`}
        onClose={closeConfirmModal}
        confirmCallback={() =>
          confirmCallback({
            action: async (args) => {
              await handleDelete(args);
              validationController.onDocumentDelete(args.checklistItemId);
            },
          })
        }
        type={PopupTypeEnum.ERROR}
        source="request__submission__policy_items__documents__delete"
        confirmButtonLabel="Delete"
      />
      {isChecklistModalShow && (
        <ChecklistApproveModal
          open={isChecklistModalShow}
          onClose={() => setChecklistModalShow(false)}
          onApproveClose={() => {
            setChecklistModalShow(false);
            validationController.onSectionButtonsClick({
              sectionKey: snakeCase(selectedItem?.label),
              key: 'isSkipped',
              isChecklist: true,
            });
          }}
          checklistItems={[selectedItem]}
          drawRequestId={drawRequest?.id?.toString()}
          projectId={projectId}
          isRequiredReasons
          isMultiSelect={false}
          source="request__submission__policy_items__exception_reasons_modal"
          checklistType={PolicyListReasonsTypeEnum.REQUEST}
          popupText="Are you sure you want to submit with the exceptions to the following requirement?"
          defaultReasons={['Requirement does not apply']}
        />
      )}
    </>
  );
};

export default Checklist;
