import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Box } from '@mui/material';

import { colors, typography } from '@theme';
import { LockIcon } from '@svgAsComponents';

const SsoLoginButton: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleNavigate = () => navigate('/login-with-sso', { state });

  return (
    <Button
      onClick={handleNavigate}
      variant="contained"
      sx={{
        backgroundColor: '#0279BB',

        textTransform: 'none',
        justifyContent: 'flex-start',
        paddingLeft: '16px',
        '&:hover': {
          backgroundColor: '#0A82C1',
        },
        minWidth: '100%',
        padding: 0,
        '& .MuiButton-startIcon': {
          margin: 0,
        },
      }}
      startIcon={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#0A82C1',
            color: 'white',
            width: '40px',
            height: '40px',
          }}
        >
          <LockIcon color={colors.neutral.white} />
        </Box>
      }
    >
      <Box sx={{ flexGrow: 1, textAlign: 'center', ...typography.body2SemiBold, color: 'white' }}>
        SSO
      </Box>
    </Button>
  );
};

export default SsoLoginButton;
