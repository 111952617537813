import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const customerFilter: FilterObject = {
  title: 'Customer',
  filterKey: 'customer',
  permissionKey: PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParamsPaginated: (pagination, q) => ({
    type: QueryNamesEnums.GET_COMPANIES,
    keys: ['id', 'name'],
    args: {
      query: '?is_customer=true&sorting=name',
      pagination,
      q,
    },
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
    },
  }),
  cypressSelector: 'filters__customer',
  withPagination: true,
};
