import React, { FC } from 'react';
import { PopupTypeEnum } from '@interfaces';
import { CustomAutocomplete, Popup, UserFocusRequestCreateDialog } from '@components';
import { DrawRequestIcon } from '@svgAsComponents';
import { Button, Stack } from '@mui/material';
import { useAddRequestPopup } from './controller';

const AddRequestPopup: FC<{ onClose: () => void }> = ({ onClose }) => {
  const {
    projectField,
    projectOptions,
    createModalVisible,
    setCreateModalVisible,
    createRequest,
    onAddRequestClick,
    projectId,
    isLoading,
  } = useAddRequestPopup(onClose);

  return (
    <Popup
      open
      type={PopupTypeEnum.GENERAL}
      title="Add request"
      text="Choose the project you want to add request for."
      icon={DrawRequestIcon}
    >
      <Stack sx={{ width: '100%' }}>
        <CustomAutocomplete
          field={projectField}
          options={projectOptions}
          label="Project"
          optionsLoading={isLoading}
          inputProps={{
            'data-cy': 'requests__add__popup__project_input',
          }}
        />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7.5 }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={onAddRequestClick}
            sx={{ ml: 1 }}
            data-cy="requests__add__popup__add__button"
          >
            Add
          </Button>
        </Stack>
      </Stack>
      {createModalVisible && (
        <UserFocusRequestCreateDialog
          onClose={() => setCreateModalVisible(false)}
          createRequest={createRequest}
          projectId={projectId}
          source="requests"
        />
      )}
    </Popup>
  );
};

export default AddRequestPopup;
