import React from 'react';
import { Box, Divider, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import {
  AdditionalRequirements,
  DocumentsIconWithIndicator,
  DownloadIcon,
  EditIcon,
  NoDataImage,
  NoTypeIcon,
  PhotoGalleryIconWithIndicator,
} from '@svgAsComponents';

import { PoliciesListItemIconsComponentProps } from '../../interface';
import { colors, typography } from '@theme';
import { useImagePicker, useRightMenu } from '@hooks';
import { ENABLED_MIME_TYPES, fileTypesMap } from '@constants';
import {
  ConfirmationModal,
  DocumentEditModal,
  Gallery,
  InfoIconWithTooltip,
  LabelAndValue,
  PDFViewerNew,
  RightDrawer,
} from '@components';
import { isDocumentPolicyItem, isRequiredPhotoPolicyItem } from '@utils';
import { useParams } from 'react-router';
import * as Controller from './controller';
import { PopupTypeEnum } from '@interfaces';

const PoliciesListItemIcons = ({
  item,
  index,
  source,
  showActionIcons,
}: PoliciesListItemIconsComponentProps) => {
  const { projectId } = useParams();
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu();
  const {
    activeDocument,
    setActiveDocument,
    deleteDocument,
    addedBy,
    canBeEdited,
    checkedByUser,
    isAdditionalRequirement,
    skippedBy,
    tab,
    setTab,
    TABS,
    modalInfo,
    closeConfirmModal,
    confirmCallback,
    isConfirmModalOpened,
  } = Controller.usePoliciesListItemIcons({
    item,
    index,
    source,
    showActionIcons,
  });
  const { label, documents, description, type } = item;
  const title = label?.toString();

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        {isAdditionalRequirement && (
          <Tooltip
            title="Additional Borrower requirement"
            data-cy={`${source}_policy__items__list__additional_requirement__tooltip`}
          >
            <Stack>
              <AdditionalRequirements size={24} />
            </Stack>
          </Tooltip>
        )}

        {showActionIcons && (
          <>
            {isDocumentPolicyItem(type) && (
              <Stack>
                <IconButton
                  onClick={() => {
                    setTab(
                      isRequiredPhotoPolicyItem(type) ? TABS.photos.value : TABS.documents.value,
                    );
                    handleRightDrawerOpenerClick({ title });
                  }}
                  disabled={!documents?.length}
                  sx={{ p: 0 }}
                  data-cy={`${source}_policy__items__list__documents__icon`}
                >
                  {isRequiredPhotoPolicyItem(type) ? (
                    <PhotoGalleryIconWithIndicator color={colors.icons.green} size={20} />
                  ) : (
                    <DocumentsIconWithIndicator size={22} />
                  )}
                </IconButton>
              </Stack>
            )}
          </>
        )}

        <Stack>
          <InfoIconWithTooltip
            data-cy={`${source}_policy__items__list__info_tooltip__icon`}
            color={
              description || checkedByUser || addedBy || showActionIcons
                ? colors.status.information.medium
                : colors.neutral.medium
            }
            tooltipText={
              (description || checkedByUser || addedBy || showActionIcons) && (
                <Stack spacing={1}>
                  {description && (
                    <Stack>
                      <Typography variant="labelSemiBold">Description:</Typography>
                      <Typography
                        variant="label"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </Stack>
                  )}
                  {(checkedByUser || addedBy || showActionIcons) && (
                    <>
                      {description && <Divider />}
                      {(addedBy || showActionIcons) && (
                        <LabelAndValue
                          label="Added"
                          text={addedBy || 'From template'}
                          labelStyle={{ ...typography.label }}
                          textStyle={{ ...typography.labelSemiBold }}
                        />
                      )}
                      {checkedByUser && (
                        <LabelAndValue
                          label="Checked"
                          text={checkedByUser}
                          labelStyle={{ ...typography.label }}
                          textStyle={{ ...typography.labelSemiBold }}
                        />
                      )}
                      {skippedBy && (
                        <LabelAndValue
                          label="Skipped"
                          text={skippedBy}
                          labelStyle={{ ...typography.label }}
                          textStyle={{ ...typography.labelSemiBold }}
                        />
                      )}
                    </>
                  )}
                </Stack>
              )
            }
          />
        </Stack>
      </Stack>
      {showActionIcons && (
        <RightDrawer {...rightMenu}>
          <Tabs
            TabIndicatorProps={{
              style: { transition: 'none' },
              children: <span />,
            }}
            value={tab}
            onChange={(_event: React.SyntheticEvent, value: string) => setTab(value)}
            sx={{ mb: 4 }}
          >
            <Tab
              label={tab === TABS.documents.value ? TABS.documents.label : TABS.photos.label}
              value={tab === TABS.documents.value ? TABS.documents.value : TABS.photos.value}
              sx={{ textTransform: 'none' }}
            />
          </Tabs>
          {(tab === TABS.documents.value || tab === TABS.photos.value) && (
            <>
              <Stack>
                {!documents?.length && <EmptyState text="No documents yet" />}
                {documents?.map((document, index) => (
                  <Stack key={document.id}>
                    <Stack direction="row" justifyContent="space-between">
                      <DocumentsName
                        mime_type={document.mime_type}
                        name={document.name || `Document #${++index}`}
                        file={document}
                      />
                      <Stack direction="row" spacing={1}>
                        {!document.deleted && (
                          <IconButton
                            onClick={() => setActiveDocument(document)}
                            data-cy={`${source}__edit__icon`}
                            disabled={!canBeEdited(document.created_by?.user_id)}
                          >
                            <EditIcon size={20} />
                          </IconButton>
                        )}
                        {document.link && (
                          <Tooltip title="Download">
                            <a href={document.link} download>
                              <IconButton
                                data-cy={`${source}__documents__download__icon`}
                                data-documentname={document?.name}
                              >
                                <DownloadIcon size={20} />
                              </IconButton>
                            </a>
                          </Tooltip>
                        )}
                      </Stack>
                    </Stack>
                    <Divider sx={{ my: 2 }} />
                  </Stack>
                ))}
              </Stack>
              {activeDocument && (
                <DocumentEditModal
                  document={activeDocument}
                  closeDocumentEditModal={() => setActiveDocument(null)}
                  deleteDocument={deleteDocument}
                  prId={projectId}
                />
              )}
              <ConfirmationModal
                open={isConfirmModalOpened}
                title="Delete file"
                text={modalInfo}
                onClose={closeConfirmModal}
                confirmCallback={confirmCallback}
                type={PopupTypeEnum.DELETE}
                confirmButtonLabel="Delete"
              />
            </>
          )}
        </RightDrawer>
      )}
    </>
  );
};

const DocumentsName = ({ mime_type, name, file }) => {
  const { pdf, gallery, open, close, startIndex } = useImagePicker();
  const Icon = fileTypesMap[mime_type] || NoTypeIcon;

  const isCanBeOpen = ENABLED_MIME_TYPES.includes(mime_type);
  return (
    <>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          ...typography.body2SemiBold,
          ...(isCanBeOpen && {
            color: colors.text.link,
            cursor: 'pointer',
          }),
        }}
        onClick={() => (isCanBeOpen ? open([file]) : {})}
      >
        <Icon />
        <Box sx={{ pr: 1 }} />
        {name}
      </Box>
      {gallery && <Gallery startIndex={startIndex} close={close} files={gallery} />}
      {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
    </>
  );
};

const EmptyState = ({ text }: { text: string }) => (
  <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
    <NoDataImage size={200} />
    <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
      {text}
    </Typography>
  </Stack>
);

export default PoliciesListItemIcons;
