import React from 'react';
import { ComponentProps, defaultProps } from './interface';

const MenuItemIcon = ({ size = defaultProps.size, color = defaultProps.color }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00065 2.66666C1.63246 2.66666 1.33398 2.96513 1.33398 3.33332V4.66666C1.33398 5.03485 1.63246 5.33332 2.00065 5.33332H3.33398C3.70217 5.33332 4.00065 5.03485 4.00065 4.66666V3.33332C4.00065 2.96513 3.70217 2.66666 3.33398 2.66666H2.00065ZM6.00065 3.33332C5.63246 3.33332 5.33398 3.6318 5.33398 3.99999C5.33398 4.36818 5.63246 4.66666 6.00065 4.66666H14.0007C14.3688 4.66666 14.6673 4.36818 14.6673 3.99999C14.6673 3.6318 14.3688 3.33332 14.0007 3.33332H6.00065ZM5.33398 7.99999C5.33398 7.6318 5.63246 7.33332 6.00065 7.33332H14.0007C14.3688 7.33332 14.6673 7.6318 14.6673 7.99999C14.6673 8.36818 14.3688 8.66666 14.0007 8.66666H6.00065C5.63246 8.66666 5.33398 8.36818 5.33398 7.99999ZM2.00065 6.66666C1.63246 6.66666 1.33398 6.96513 1.33398 7.33332V8.66666C1.33398 9.03485 1.63246 9.33332 2.00065 9.33332H3.33398C3.70217 9.33332 4.00065 9.03485 4.00065 8.66666V7.33332C4.00065 6.96513 3.70217 6.66666 3.33398 6.66666H2.00065ZM5.33398 12C5.33398 11.6318 5.63246 11.3333 6.00065 11.3333H14.0007C14.3688 11.3333 14.6673 11.6318 14.6673 12C14.6673 12.3682 14.3688 12.6667 14.0007 12.6667H6.00065C5.63246 12.6667 5.33398 12.3682 5.33398 12ZM2.00065 10.6667C1.63246 10.6667 1.33398 10.9651 1.33398 11.3333V12.6667C1.33398 13.0348 1.63246 13.3333 2.00065 13.3333H3.33398C3.70217 13.3333 4.00065 13.0348 4.00065 12.6667V11.3333C4.00065 10.9651 3.70217 10.6667 3.33398 10.6667H2.00065Z"
      fill={color}
    />
  </svg>
);

export default MenuItemIcon;
