import { colors } from '@theme';
import { ProjectStatusEnum, UserStatusDisplayEnum, ServiceOrderStatusEnum } from '@interfaces';
import { ProjectStatusMap, serviceOrderStatusMap, DrawRequestListStatusMap } from '@constants';

// statuses for draw requests, payments, users, project
const statusMapV2 = (value, staticStatuses, type) => {
  if (!staticStatuses) return {};
  const map = {
    draw_request: {
      IN_REVIEW: {
        text: DrawRequestListStatusMap.IN_REVIEW,
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
      },
      APPROVED: {
        text: DrawRequestListStatusMap.APPROVED,
        color: colors.status.success.medium,
        backgroundColor: colors.status.success.lighter,
      },
      COMPLETED: {
        text: DrawRequestListStatusMap.COMPLETED,
        color: colors.status.violet.medium,
        backgroundColor: colors.status.violet.lighter,
      },
      DRAFT: {
        text: DrawRequestListStatusMap.DRAFT,
        color: colors.neutral.dark,
        backgroundColor: colors.neutral.lightest,
      },
      // syntetic statuses
      ON_HOLD: {
        text: 'On hold',
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
      },
    },
    users: {
      [UserStatusDisplayEnum.ACTIVE]: {
        color: colors.status.success.medium,
        backgroundColor: colors.status.success.lighter,
      },
      [UserStatusDisplayEnum.AWAITING_INVITE]: {
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
      },
      [UserStatusDisplayEnum.INVITED]: {
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
      },
      [UserStatusDisplayEnum.INACTIVE]: {
        color: colors.text.medium,
        backgroundColor: colors.background.gray,
      },
    },
    project: {
      [ProjectStatusEnum.ACTIVE]: {
        color: colors.status.success.medium,
        backgroundColor: colors.status.success.lighter,
        text: ProjectStatusMap[value],
      },
      [ProjectStatusEnum.CREATED]: {
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
        text: ProjectStatusMap[value],
      },
      [ProjectStatusEnum.INACTIVE_COMPLETE]: {
        color: colors.text.medium,
        backgroundColor: colors.neutral.lightest,
        text: ProjectStatusMap[value],
      },
      [ProjectStatusEnum.INACTIVE_INCOMPLETE]: {
        color: colors.text.medium,
        backgroundColor: colors.neutral.lightest,
        text: ProjectStatusMap[value],
      },
      [ProjectStatusEnum.PAUSED]: {
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
        text: ProjectStatusMap[value],
      },
      [ProjectStatusEnum.DISCARDED]: {
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
        text: ProjectStatusMap[value],
      },
    },
    inspection: {
      [ServiceOrderStatusEnum.CREATED]: {
        color: colors.text.medium,
        backgroundColor: colors.neutral.lightest,
        text: serviceOrderStatusMap[ServiceOrderStatusEnum.CREATED],
      },
      [ServiceOrderStatusEnum.PENDING]: {
        color: colors.text.medium,
        backgroundColor: colors.status.information.lightest,
        text: serviceOrderStatusMap[ServiceOrderStatusEnum.PENDING],
      },
      [ServiceOrderStatusEnum.ORDERED]: {
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
        text: serviceOrderStatusMap[value],
      },
      [ServiceOrderStatusEnum.COMPLETED]: {
        color: colors.status.violet.medium,
        backgroundColor: colors.status.violet.lighter,
        text: serviceOrderStatusMap[value],
      },
      [ServiceOrderStatusEnum.ERROR]: {
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
        text: serviceOrderStatusMap[value],
      },
      [ServiceOrderStatusEnum.CANCELLED]: {
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
        text: serviceOrderStatusMap[value],
      },
      [ServiceOrderStatusEnum.CANCEL_REQUESTED]: {
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
        text: serviceOrderStatusMap[value],
      },
      [ServiceOrderStatusEnum.READY_FOR_REVIEW]: {
        color: colors.status.success.medium,
        backgroundColor: colors.status.success.lighter,
        text: serviceOrderStatusMap[value],
      },
    },
  };

  if (!map[type])
    return {
      text: '',
      color: colors.secondary,
    };

  if (!map[type][value])
    return {
      text: value,
      color: colors.secondary,
    };

  return map[type][value];
};

export default statusMapV2;
