import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 100 }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62.2222 25.4862H37.7241C37.1658 25.4869 36.6306 25.7095 36.2358 26.1053C35.8411 26.5011 35.619 27.0377 35.6183 27.5974V82.1867L35.3376 82.2726L29.3278 84.1178C29.0429 84.2049 28.7353 84.1751 28.4724 84.035C28.2095 83.8948 28.0127 83.6558 27.9254 83.3704L10.049 24.8261C9.96201 24.5405 9.99166 24.232 10.1314 23.9683C10.2712 23.7047 10.5097 23.5074 10.7944 23.42L20.0555 20.5769L46.9037 12.3375L56.1647 9.49433C56.3057 9.45084 56.4538 9.43564 56.6006 9.44961C56.7474 9.46357 56.89 9.50643 57.0202 9.57571C57.1505 9.645 57.2659 9.73936 57.3597 9.8534C57.4536 9.96744 57.5241 10.0989 57.5672 10.2403L62.1366 25.2047L62.2222 25.4862Z"
      fill="#F0F5F3"
    />
    <path
      d="M67.6919 25.246L62.1685 7.24502C62.0766 6.94509 61.9264 6.66617 61.7264 6.42419C61.5265 6.18221 61.2807 5.98192 61.0031 5.83476C60.7255 5.68761 60.4216 5.59646 60.1087 5.56655C59.7958 5.53663 59.48 5.56853 59.1795 5.66042L46.1209 9.64862L19.1955 17.8737L6.13689 21.8633C5.53025 22.0491 5.02231 22.4676 4.72455 23.0267C4.42679 23.5859 4.36354 24.24 4.54869 24.8457L23.4263 86.3657C23.5767 86.8546 23.8802 87.2825 24.2922 87.5866C24.7043 87.8908 25.2031 88.0552 25.7157 88.0557C25.9529 88.0558 26.1888 88.0203 26.4154 87.9503L35.367 85.2166L35.6486 85.1295V84.8359L35.367 84.9216L26.3323 87.682C25.7969 87.8448 25.2185 87.7892 24.7242 87.5271C24.2298 87.2651 23.8598 86.8181 23.6953 86.2842L4.81907 24.7628C4.73757 24.4982 4.70916 24.2203 4.73548 23.9448C4.7618 23.6693 4.84232 23.4016 4.97244 23.1572C5.10257 22.9128 5.27974 22.6964 5.4938 22.5204C5.70786 22.3444 5.95461 22.2123 6.21993 22.1316L19.2785 18.142L46.204 9.91834L59.2626 5.92874C59.4638 5.86746 59.673 5.83621 59.8834 5.83602C60.335 5.83703 60.7743 5.98228 61.1372 6.25049C61.5 6.5187 61.7672 6.89579 61.8997 7.3265L67.3976 25.246L67.4849 25.527H67.7778L67.6919 25.246Z"
      fill="#585858"
    />
    <path
      d="M21.6976 23.6111C21.4258 23.6109 21.1612 23.5234 20.9427 23.3614C20.7241 23.1993 20.5631 22.9713 20.4832 22.7108L18.6668 16.7616C18.6179 16.6018 18.601 16.4339 18.6169 16.2675C18.6328 16.1012 18.6812 15.9396 18.7594 15.792C18.8376 15.6444 18.9441 15.5137 19.0727 15.4073C19.2012 15.301 19.3495 15.2211 19.5088 15.1722L44.3208 7.55547C44.6427 7.45698 44.9904 7.49057 45.2876 7.64886C45.5848 7.80715 45.8072 8.07721 45.9061 8.39976L47.7225 14.3491C47.8207 14.6718 47.7871 15.0204 47.6293 15.3184C47.4714 15.6164 47.2021 15.8394 46.8805 15.9386L22.0685 23.5553C21.9483 23.5922 21.8233 23.6111 21.6976 23.6111Z"
      fill="#1F4739"
    />
    <path
      d="M31.1111 11.9445C32.6452 11.9445 33.8889 10.7008 33.8889 9.16669C33.8889 7.63257 32.6452 6.38892 31.1111 6.38892C29.577 6.38892 28.3333 7.63257 28.3333 9.16669C28.3333 10.7008 29.577 11.9445 31.1111 11.9445Z"
      fill="#1F4739"
    />
    <path
      d="M31.25 10.8335C32.2472 10.8335 33.0556 10.0251 33.0556 9.02797C33.0556 8.03079 32.2472 7.22241 31.25 7.22241C30.2528 7.22241 29.4445 8.03079 29.4445 9.02797C29.4445 10.0251 30.2528 10.8335 31.25 10.8335Z"
      fill="white"
    />
    <path
      d="M89.3584 87.5001H41.7527C41.4353 87.4997 41.131 87.3735 40.9066 87.149C40.6822 86.9245 40.5559 86.6202 40.5555 86.3028V29.253C40.5559 28.9356 40.6821 28.6312 40.9066 28.4067C41.131 28.1823 41.4353 28.056 41.7527 28.0557H89.3584C89.6758 28.056 89.9801 28.1823 90.2045 28.4068C90.4289 28.6312 90.5552 28.9356 90.5555 29.253V86.3028C90.5552 86.6202 90.4289 86.9245 90.2045 87.149C89.98 87.3735 89.6758 87.4997 89.3584 87.5001Z"
      fill="#F0F5F3"
    />
    <path
      d="M67.3179 25.2778H37.672C37.0373 25.2787 36.4288 25.5308 35.98 25.9788C35.5312 26.4268 35.2787 27.0342 35.2778 27.6677V84.9973L35.5594 84.9116V27.6677C35.5601 27.1087 35.7829 26.5727 36.1789 26.1774C36.575 25.7821 37.1119 25.5597 37.672 25.559H67.4053L67.3179 25.2778ZM93.1613 25.2778H37.672C37.0373 25.2787 36.4288 25.5308 35.98 25.9788C35.5312 26.4268 35.2787 27.0342 35.2778 27.6677V92.0546C35.2787 92.6882 35.5312 93.2955 35.98 93.7435C36.4288 94.1915 37.0373 94.4436 37.672 94.4445H93.1613C93.796 94.4436 94.4045 94.1915 94.8533 93.7435C95.3021 93.2955 95.5546 92.6882 95.5555 92.0546V27.6677C95.5546 27.0342 95.3021 26.4268 94.8533 25.9788C94.4045 25.5308 93.796 25.2787 93.1613 25.2778ZM95.2739 92.0546C95.2732 92.6137 95.0504 93.1496 94.6544 93.5449C94.2583 93.9403 93.7214 94.1627 93.1613 94.1633H37.672C37.1119 94.1627 36.575 93.9403 36.1789 93.5449C35.7829 93.1496 35.5601 92.6137 35.5594 92.0546V27.6677C35.5601 27.1087 35.7829 26.5727 36.1789 26.1774C36.575 25.7821 37.1119 25.5597 37.672 25.559H93.1613C93.7214 25.5597 94.2583 25.7821 94.6544 26.1774C95.0504 26.5727 95.2732 27.1087 95.2739 27.6677V92.0546Z"
      fill="#585858"
    />
    <path
      d="M78.4475 31.3889H52.3859C52.0479 31.3886 51.7239 31.2568 51.4849 31.0224C51.2459 30.7881 51.1115 30.4703 51.1111 30.1389V24.0278C51.1115 23.6964 51.246 23.3787 51.4849 23.1444C51.7239 22.91 52.0479 22.7782 52.3859 22.7778H78.4475C78.7855 22.7782 79.1095 22.91 79.3485 23.1444C79.5874 23.3787 79.7219 23.6964 79.7223 24.0278V30.1389C79.7219 30.4703 79.5875 30.7881 79.3485 31.0224C79.1095 31.2568 78.7855 31.3886 78.4475 31.3889Z"
      fill="#1F4739"
    />
    <path
      d="M65.5555 23.0556C67.0897 23.0556 68.3333 21.8119 68.3333 20.2778C68.3333 18.7437 67.0897 17.5 65.5555 17.5C64.0214 17.5 62.7778 18.7437 62.7778 20.2778C62.7778 21.8119 64.0214 23.0556 65.5555 23.0556Z"
      fill="#1F4739"
    />
    <path
      d="M65.4167 21.9447C66.4139 21.9447 67.2223 21.1985 67.2223 20.278C67.2223 19.3575 66.4139 18.6113 65.4167 18.6113C64.4195 18.6113 63.6111 19.3575 63.6111 20.278C63.6111 21.1985 64.4195 21.9447 65.4167 21.9447Z"
      fill="white"
    />
  </svg>
);

export default Icon;
