import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.status.information.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8333 22C9.72876 22 8.83333 21.1046 8.83333 20V13.7341C6.87996 12.953 5.5 11.043 5.5 8.81078V6.5C5.5 4.01472 7.51472 2 10 2C11.1046 2 12 2.89543 12 4V7.5C12 7.77614 12.2239 8 12.5 8C12.7761 8 13 7.77614 13 7.5V4C13 2.89543 13.8954 2 15 2C17.4853 2 19.5 4.01472 19.5 6.5V8.81078C19.5 11.043 18.12 12.953 16.1667 13.7341V20C16.1667 21.1046 15.2712 22 14.1667 22H10.8333ZM14.1667 20H10.8333V12.1441C10.8333 12.1259 10.8186 12.1111 10.8003 12.1111C8.97761 12.1111 7.5 10.6335 7.5 8.81078V6.5C7.5 5.93709 7.68604 5.41763 8 4.99976C8.45611 4.39267 9.1822 4 10 4V7.5C10 8.88071 11.1193 10 12.5 10C13.8807 10 15 8.88071 15 7.5V4C15.8178 4 16.5439 4.39267 17 4.99976C17.314 5.41763 17.5 5.93709 17.5 6.5V8.81078C17.5 10.6335 16.0224 12.1111 14.1997 12.1111C14.1814 12.1111 14.1667 12.1259 14.1667 12.1441V20Z"
      fill={color}
    />
  </svg>
);

export default Icon;
