import React, { FC, ReactNode } from 'react';
import { Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LogoWithName } from '@svgAsComponents';
import { useLogout } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { sizes } from '@theme';

export interface IStatePage {
  text?: string | ReactNode;
  buttons?: Array<{
    text: string;
    onClick?: () => void;
  }>;
  image?: string;
  imageComponent?: ReactNode;
  showLogout?: boolean;
}
const StatePageLayout: FC<IStatePage> = ({ text, image, imageComponent, buttons, showLogout }) => {
  const { logout } = useLogout();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        height: '100vh',
        px: 3,
        pt: { md: '62px', xs: '24px' },
        pb: { md: '114px', xs: '24px' },
      }}
    >
      <LogoWithName />

      <Stack justifyContent="center" sx={{ flex: 1, width: { xs: '320px', md: '480px' } }}>
        {image && <img src={image} style={{ width: '100%', height: '100%' }} alt="State image" />}
        {imageComponent}
      </Stack>

      <StyledTypography variant="h2">{text}</StyledTypography>

      <Stack
        sx={{
          mt: 3,
          flexDirection: { xs: 'column', md: 'row' },
          minWidth: { xs: '100%', md: 'auto' },
        }}
      >
        {buttons?.map((button, index) => (
          <StyledButton key={index} onClick={button.onClick}>
            {button.text}
          </StyledButton>
        ))}
        {showLogout && <StyledButton onClick={handleLogout}>Logout</StyledButton>}
      </Stack>
    </Stack>
  );
};

export default StatePageLayout;

const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  ['@media (max-width: 600px)']: {
    fontSize: sizes.fontSizes.medium,
  },
}));
const StyledButton = styled(Button)(() => ({
  margin: '8px',
  ['@media (max-width: 600px)']: {
    width: '100%',
  },
}));
