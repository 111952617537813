import { useMemo, useContext } from 'react';
import { useQuery } from 'react-query';
import { useSafeSnackbar } from '@hooks';

import { getHookState } from '@utils';
import { HookState, QueryNamesEnums, ISupersetDashboard, EnumTypeForList } from '@interfaces';
import { getCompanyDashboards } from '@globalService';
import { AuthContext } from '@context';

export interface ControllerInterface {
  state: HookState;
  dashboardsList: EnumTypeForList[];
}

export const useSupersetDashboard = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const companyId = useMemo(() => user.company_id, [user]);

  const dashboardQuery = useQuery<ISupersetDashboard[], Error>(
    [QueryNamesEnums.GET_COMPANY_DASHBOARDS, { companyId }],
    getCompanyDashboards.bind(this, companyId),
    {
      enabled: Boolean(companyId),
      onError: (error) => {
        enqueueSnackbar(error?.message, { variant: 'error' });
      },
    },
  );

  const dashboardsList = useMemo(
    () =>
      dashboardQuery.data?.map(({ dashboard_id, dashboard_name, dashboard_description }) => ({
        id: dashboard_id,
        name: dashboard_name || `Dashboard ${dashboard_id}`,
        name_display: dashboard_name || `Dashboard ${dashboard_id}`,
        dashboard_description,
      })) || [],
    [dashboardQuery.data],
  );

  return {
    state: getHookState(dashboardQuery),
    dashboardsList,
  };
};
