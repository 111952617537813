import React, { FC } from 'react';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { ICompanyFull } from '@interfaces';
import * as Controller from './controller';

interface ComponentProps {
  company: ICompanyFull;
}

const MFASettings: FC<ComponentProps> = ({ company }) => {
  const { companyUpdate } = Controller.useMFASettings();

  return (
    <Stack spacing={2}>
      <Typography variant="h3">Two-factor authentication</Typography>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ p: 0, mr: 1 }}
            checked={company?.is_mfa_enabled}
            onChange={(_e, value) => companyUpdate(value)}
            data-cy="my_company__mfa_settings__checkbox"
          />
        }
        label={
          <Typography variant="body3">
            Activate email-based two-factor authentication for all members of your organization to
            bolster account security.
          </Typography>
        }
      />
    </Stack>
  );
};

export default MFASettings;
