import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0763 2H5.07629C3.41944 2 2.07629 3.34315 2.07629 5V19C2.07629 20.6569 3.41944 22 5.07629 22H19.0763C20.7331 22 22.0763 20.6569 22.0763 19V5C22.0763 3.34315 20.7331 2 19.0763 2ZM4.07629 5C4.07629 4.44772 4.52401 4 5.07629 4H19.0763C19.6286 4 20.0763 4.44772 20.0763 5V19C20.0763 19.5523 19.6286 20 19.0763 20H5.07629C4.52401 20 4.07629 19.5523 4.07629 19V5ZM11.8679 13.752C12.2739 13.752 12.5679 13.43 12.6239 13.066L12.6659 12.814C14.1919 12.506 15.4239 11.778 15.4239 10V9.972C15.4239 8.11 14.0519 7.074 12.0359 7.074C10.8039 7.074 9.86589 7.466 9.12389 8.082C8.87189 8.292 8.75989 8.572 8.75989 8.838C8.75989 9.37 9.20789 9.818 9.73989 9.818C9.97789 9.818 10.2019 9.72 10.3699 9.594C10.8739 9.216 11.3919 9.034 11.9939 9.034C12.8059 9.034 13.2819 9.398 13.2819 10.028V10.056C13.2819 10.742 12.7219 11.12 11.5039 11.274C11.0559 11.33 10.7899 11.652 10.8599 12.114L10.9999 13.066C11.0559 13.444 11.3499 13.752 11.7559 13.752H11.8679ZM10.5379 15.866V15.894C10.5379 16.566 11.0559 17.084 11.7559 17.084C12.4559 17.084 12.9739 16.566 12.9739 15.894V15.866C12.9739 15.194 12.4559 14.676 11.7559 14.676C11.0559 14.676 10.5379 15.194 10.5379 15.866Z"
      fill={color}
    />
  </svg>
);

export default Icon;
