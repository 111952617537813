import { dataProvider } from '@api';
import {
  ActiveDRPoliciesParam,
  ChecklistDocumentParam,
  ChecklistParam,
  IChecklistReason,
  IProjectChecklist,
  PatchChecklistItemParam,
  PostChecklistParam,
  PostProjectChecklistParam,
  PostTemplateParams,
  ProjectChecklistParam,
  TemplateChecklistParam,
  UpdateChecklistItemParam,
} from '@interfaces';
import {
  checklistsReasons,
  drawRequestChecklistItem,
  drawRequestChecklistItemDocument,
  drawRequestChecklistItems,
  drawRequestItemChecklist,
  drawRequestPolicies,
  projectChecklist,
  projectChecklistItem,
  projectChecklistItems,
  projectPoliciesTemplate,
  requestChecklistTemplate,
  requestChecklistTemplates,
} from '@globalService';

export const getChecklistReasons = async (): Promise<IChecklistReason[]> =>
  dataProvider.get(checklistsReasons()).json();

export const patchChecklistItem = async ({
  projectId,
  drawRequestId,
  checklistId,
  checklistItemId,
  value,
}: PatchChecklistItemParam): Promise<Response> =>
  dataProvider
    .patch(drawRequestChecklistItem(projectId, drawRequestId, checklistId, checklistItemId), {
      json: value,
    })
    .json();

export const getDrawRequestItemChecklist = async (
  projectId: string,
  drawRequestId: string,
): Promise<IProjectChecklist[]> =>
  dataProvider.get(drawRequestItemChecklist(projectId, drawRequestId)).json();

export const getProjectChecklist = async (projectId: string): Promise<IProjectChecklist[]> =>
  dataProvider.get(projectChecklist(projectId)).json();

export const updateChecklistItem = async ({
  projectId,
  drawRequestId,
  checklistId,
  checklistItemId,
  status,
  note,
  exception_reasons,
  metadata,
}: UpdateChecklistItemParam): Promise<Response> =>
  drawRequestId
    ? dataProvider.patch(
        drawRequestChecklistItem(projectId, drawRequestId, checklistId, checklistItemId),
        {
          json: {
            status,
            note,
            ...(exception_reasons && { exception_reasons }),
            ...(metadata && { metadata }),
          },
        },
      )
    : dataProvider.patch(projectChecklistItem(projectId, checklistId, checklistItemId), {
        json: { status, ...(exception_reasons && { exception_reasons }), ...(note && { note }) },
      });

export const postChecklistItem = async ({
  projectId,
  drawRequestId,
  checklistId,
  value,
}: PostChecklistParam): Promise<Response> =>
  dataProvider
    .post(drawRequestChecklistItems(projectId, drawRequestId, checklistId), { json: value })
    .json();

export const deleteDrawRequestChecklistItem = async ({
  projectId,
  drawRequestId,
  checklistId,
  checklistItemId,
  documentId,
}: ChecklistDocumentParam): Promise<Response> =>
  dataProvider
    .delete(
      drawRequestChecklistItemDocument(
        projectId,
        drawRequestId,
        checklistId,
        checklistItemId,
        documentId,
      ),
    )
    .json();

export const postProjectChecklistItem = async ({
  projectId,
  checklistId,
  value,
}: PostProjectChecklistParam): Promise<Response> =>
  dataProvider.post(projectChecklistItems(projectId, checklistId), { json: value }).json();

export const deleteChecklistItem = async ({
  projectId,
  drawRequestId,
  checklistId,
  itemId,
}: ChecklistParam & { itemId: string }): Promise<Response> =>
  dataProvider
    .delete(drawRequestChecklistItem(projectId, drawRequestId, checklistId, itemId))
    .json();

export const deleteProjectChecklistItem = async ({
  projectId,
  checklistId,
  itemId,
}: ProjectChecklistParam & { itemId: string }): Promise<Response> =>
  dataProvider.delete(projectChecklistItem(projectId, checklistId, itemId)).json();

export const getRequestChecklistTemplates = async (
  projectId: string,
): Promise<IProjectChecklist[]> => dataProvider.get(requestChecklistTemplates(projectId)).json();

export const updateChecklistTemplate = async ({
  projectId,
  checklistId,
  policiesParams,
}: TemplateChecklistParam): Promise<Response> =>
  dataProvider
    .put(requestChecklistTemplate(projectId, checklistId), { json: { ...policiesParams } })
    .json();

export const updateProjectPoliciesTemplate = async ({
  projectId,
  checklistId,
  policiesParams,
}: TemplateChecklistParam): Promise<Response> =>
  dataProvider
    .put(projectPoliciesTemplate(projectId, checklistId), { json: { ...policiesParams } })
    .json();

export const createProjectPoliciesTemplate = async ({
  projectId,
  policiesParams,
}: PostTemplateParams): Promise<Response> =>
  dataProvider.post(projectChecklist(projectId), { json: { ...policiesParams } }).json();

export const createRequestPoliciesTemplate = async ({
  projectId,
  policiesParams,
}: PostTemplateParams): Promise<Response> =>
  dataProvider.post(requestChecklistTemplates(projectId), { json: { ...policiesParams } }).json();

export const updateActiveRequestPolicies = async ({
  projectId,
  drawRequestId,
  checklistId,
  policiesParams,
}: ActiveDRPoliciesParam): Promise<Response> =>
  dataProvider
    .put(drawRequestPolicies(projectId, drawRequestId, checklistId), {
      json: { ...policiesParams },
    })
    .json();
