import React, { FC, PropsWithChildren } from 'react';
import { Button, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ComponentProps, ComponentWithTooltipProps } from './interface';

export const TooltipButton: FC<ComponentProps> = ({ tooltipText, dataTestName, ...props }) => {
  return (
    <Tooltip title={tooltipText}>
      <div>
        <Button {...props} data-cy={dataTestName} disabled />
      </div>
    </Tooltip>
  );
};

export const ButtonWithTooltip: FC<PropsWithChildren<ComponentWithTooltipProps>> = ({
  disabled,
  tooltipText,
  loading,
  dataTestName,
  ...props
}) => {
  return disabled ? (
    <TooltipButton tooltipText={tooltipText} dataTestName={dataTestName} {...props} />
  ) : (
    <LoadingButton data-cy={dataTestName} {...props} loading={loading} />
  );
};
