import { Box, Divider, Stack, styled, Typography } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { Logo as LogoIcon, PoweredWithELText } from '@svgAsComponents';
import { colors } from '@theme';
import { ControllerInterface } from './controller';

export const Logo = styled('img')(() => ({
  height: '35px',
}));

export const Footer: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { customerLogo, renderedPage } = controller;
  renderedPage.current = renderedPage.current + 1;

  return (
    <Box
      sx={{
        height: '70px',
        width: '100%',
        bottom: 0,
        paddingLeft: '25px',
        paddingRight: '25px',
      }}
    >
      <Divider />
      <Stack mt="15px" spacing="7px" direction="row" alignItems="center">
        {customerLogo && <Logo alt="Customer logo" src={customerLogo} />}
        {customerLogo && <Divider orientation="vertical" />}
        <LogoIcon />
        <PoweredWithELText />

        <Typography variant="h3" flex={1} textAlign="right" pb={1}>
          Page #{renderedPage.current}
        </Typography>
      </Stack>
    </Box>
  );
};

export const Header: FC<{ customerLogo?: string }> = ({ customerLogo }) => (
  <Box
    sx={{
      height: '70px',
      width: '100%',
      top: 0,
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '15px',
    }}
  >
    <Stack mb="15px" direction="row" alignItems="center">
      {customerLogo && <Logo alt="Customer logo" src={customerLogo} />}
    </Stack>
    <Divider />
  </Box>
);

export const Page: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      height: '100vh',
      width: '100vw',
      position: 'relative',
    }}
  >
    {children}
  </Box>
);

export const ItemImage = styled('div')({
  display: 'flex',
  flex: 1,
  minWidth: '49%',
  maxWidth: '49%',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
  backgroundColor: colors.background.gray,
  breakInside: 'avoid',
  border: '1px solid #D2D2D2',
  borderRadius: '4px',
  [':nth-of-type(2n)']: { marginLeft: '13px' },
  marginBottom: '50px',
  position: 'relative',
  ['& > img']: {
    height: '100%',
    width: 'auto',
  },
  ['& > div']: {
    position: 'absolute',
    bottom: '-30px',
    left: 0,
    display: 'flex',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export const BorderBox = styled('div')({
  width: '100%',
  padding: '10px',
  border: '1px solid #D2D2D2',
  borderRadius: '4px',
});

export const WrapperPolicy = styled(Box)(() => ({
  '& > .MuiBox-root > div': {
    marginBottom: '5px',
  },
  '.MuiBox-root > div': {
    alignItems: 'center',
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  ' .MuiTypography-printH2': {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  ' .MuiDivider-root': {
    marginTop: '5px',
    marginBottom: '8px',
  },
  ' .MuiTypography-body2': {
    fontSize: '12px',
    '-webkit-print-color-adjust': 'exact',
  },
  ' .MuiTypography-body3': {
    fontSize: '12px',
    '-webkit-print-color-adjust': 'exact',
  },
  ' .MuiTypography-body1': {
    fontSize: '12px',
    '-webkit-print-color-adjust': 'exact',
  },
  ' .MuiTypography-body3SemiBold': {
    fontSize: '12px',
    '-webkit-print-color-adjust': 'exact',
  },
  '.MuiTable-root': {
    borderBottom: `1px solid ${colors.neutral.lighter}`,
  },
  '.MuiTableFooter-root': {
    ' .MuiTableCell-root': {
      borderTop: `1px solid ${colors.neutral.lighter}`,
      borderBottom: 'none',
    },
  },
  '.MuiTableRow-root .MuiTableCell-root': {
    padding: '3px',
    textAlign: 'right',
    borderLeft: `1px solid ${colors.neutral.lighter} !important`,
    '&:first-child': {
      borderLeft: 'none',
      textAlign: 'left',
      paddingLeft: '10px',
    },
    '&:last-child': {
      paddingRight: '10px',
    },
  },
  '.MuiTableHead-root .MuiTableCell-root': {
    background: colors.background.gray,
    padding: '5px',
    borderLeft: 'none',
    '&:first-child': {
      '.MuiBox-root': {
        justifyContent: 'start',
      },
    },
    '.MuiBox-root': {
      justifyContent: 'flex-end',

      '.MuiTypography-root': {
        fontSize: 11,
        color: colors.text.dark,
        maxWidth: '110px',
        '&+ .MuiBox-root': {
          display: 'none',
        },
        '&.MuiTypography-labelSemiBold': {
          fontSize: 10,
        },
      },
    },
  },
}));
