import { GuideNameEnum } from '@interfaces';
import InputGifOne from 'encirclelabs-assets/gif/new_input_1.gif';
import InputGifTwo from 'encirclelabs-assets/gif/new_input_2.gif';

export const projectOverviewGuide = {
  name: GuideNameEnum.PROJECT_OVERVIEW,
  steps: [
    {
      title: 'Welcome',
      intro: 'Hey stranger! We are happy to see you',
    },
    {
      element: '[data-tour="comments_icon"]',
      intro:
        'You can leave commnents here. Blue circle means unread comments from your colleagues.',
    },
    {
      element: '[data-tour="add_request_icon"]',
      title: 'Create request',
      intro:
        'Here you can create draw and change request. Be informed that draw request can contain both requested changes and requested amounts at the same time.',
    },
    {
      element: '[data-tour="project_menu"]',
      title: 'Project menu',
      intro: 'Here you can edit project details or change project status.',
    },
  ],
};

export const newInput = {
  name: GuideNameEnum.NEW_INPUT,
  steps: [
    {
      title: 'New features available!',
      intro: `<div><img src="${InputGifOne}" /><p class="MuiTypography-root MuiTypography-body3"><b>Improved data entry</b> - after entering the value, simply click outside the input field or hit enter.</p></div>`,
      tooltipClass: 'fullscreen customTooltip',
    },
    {
      title: 'New features available!',
      intro: `<div><img src="${InputGifTwo}" /><p  class="MuiTypography-root MuiTypography-body3" ><b>Multi-Cell Copy-Paste</b> - allowing you to efficiently copy and insert up to 20 rows in a single action!</p></div>`,
      tooltipClass: 'fullscreen customTooltip',
    },
  ],
};
