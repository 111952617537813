import React from 'react';
import { Box, Stack, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { states } from '@constants';
import {
  ButtonWithTooltip,
  CenteredStyledBox,
  CustomAutocomplete,
  CustomTextField,
  LabelAndValue,
  ToolTipLine,
  CompanyProjectsInfoLink,
  UserInfoLink,
  InternationalPhoneField,
} from '@components';
import { ToolTipLineVariantEnum } from '@interfaces';

import { DotIcon } from '@svgAsComponents';

import * as Controller from './controller';
import { getAddress } from '@utils';

const BorrowerInformation = ({
  borrowerDetailsFields,
  borrowerUser,
  setBorrowerUser,
  isNewBorrower,
  setIsNewBorrower,
  showTitle = true,
  inviteBorrowers,
  setInviteBorrowers,
}) => {
  const { borrower_email, legal_entity } = borrowerDetailsFields;

  const { handleSearchEmailClick, borrowerTeam, isSearching, tooltipText } =
    Controller.useBorrowerStep({
      borrowerDetailsFields,
      borrowerUser,
      setBorrowerUser,
      setIsNewBorrower,
    });

  return (
    <Stack alignItems="center" spacing={2}>
      <CenteredStyledBox>
        <Stack>
          {showTitle && (
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-end">
              <Typography variant="h3">Add borrower</Typography>
              <Typography variant="labelSemiBold" sx={{ ml: '4px', mb: '2px' }}>
                (Optional)
              </Typography>
            </Stack>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: 3 }}
            spacing={1}
          >
            <CustomTextField
              field={borrower_email}
              label="Borrower email"
              inputProps={{
                'data-cy': 'project__create__borrower_email__input',
              }}
            />
            <ButtonWithTooltip
              onClick={handleSearchEmailClick}
              disabled={!borrower_email.isValid}
              tooltipText="Please enter a valid email"
              sx={{ minWidth: '150px' }}
              loading={isSearching}
              dataTestName="project__create__search_email__button"
            >
              Find borrower
            </ButtonWithTooltip>
          </Stack>
          <Typography variant="label" sx={{ pt: 0.5 }}>
            Enter the email address.
          </Typography>
        </Stack>
      </CenteredStyledBox>

      {(borrowerUser?.id || isNewBorrower) && (
        <CenteredStyledBox>
          {tooltipText && (
            <Stack mb={3} alignItems="flex-start">
              <ToolTipLine
                typographyVariant="body3"
                variant={ToolTipLineVariantEnum.INFORMATION}
                text={tooltipText}
              />
            </Stack>
          )}
          {borrowerUser?.id && (
            <Stack justifyContent="space-between" sx={{ mb: 2 }}>
              <Typography sx={{ mb: 2 }} variant="h3" data-cy="project__create__company__title">
                Borrower company
              </Typography>

              <Stack spacing={1.5}>
                <LabelAndValue
                  textDataTestName="project__create__company_name__value"
                  label="Company name"
                  text={borrowerUser?.company?.name}
                />
                <LabelAndValue
                  textDataTestName="project__create__company_address__value"
                  label="Company address"
                  text={
                    getAddress(borrowerUser?.company?.address) ||
                    borrowerUser?.company?.address?.full_address ||
                    ''
                  }
                />
                <LabelAndValue
                  textDataTestName="project__create__borrower_name__value"
                  label="Borrower name"
                  text={borrowerUser?.full_name}
                />
                <LabelAndValue
                  textDataTestName="project__create__borrower_phone__value"
                  label="Borrower phone number"
                  text={borrowerUser?.phone}
                />
                {/* borrowerTeam show us if lender has a common enrollment with this borrower */}
                {borrowerTeam && (
                  <LabelAndValue
                    textDataTestName="project__create__company_projects_count__value"
                    label="Company projects"
                  >
                    <CompanyProjectsInfoLink companyId={borrowerUser?.company?.id} />
                  </LabelAndValue>
                )}
              </Stack>
            </Stack>
          )}
          {isNewBorrower && (
            <NewBorrowerCompanyBlock
              borrowerDetailsFields={borrowerDetailsFields}
              inviteBorrowers={inviteBorrowers}
              setInviteBorrowers={setInviteBorrowers}
            />
          )}
        </CenteredStyledBox>
      )}

      {borrowerUser?.company?.id && borrowerTeam?.members?.length && (
        <CenteredStyledBox>
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Typography variant="h3" data-cy="project__create__borrower_users__title">
              Borrower users
            </Typography>
            <Typography variant="body1">{`These users are part of ${borrowerUser?.company?.name} and will have access to this project.`}</Typography>
          </Stack>

          <Stack direction="row" flexWrap="wrap">
            {borrowerTeam.members.map(({ first_name, last_name, email, user }, index) => {
              const borrowerName =
                first_name || last_name ? `${first_name || ''} ${last_name || ''}` : email;
              return (
                <Stack key={user} direction="row" mb={1}>
                  <UserInfoLink
                    name={borrowerName}
                    userId={user}
                    companyId={borrowerUser?.company?.id}
                  />

                  {index < borrowerTeam.members.length - 1 && (
                    <Box sx={{ mr: 2, ml: 2 }}>
                      <DotIcon />
                    </Box>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </CenteredStyledBox>
      )}

      {(borrowerUser?.id || isNewBorrower) && (
        <CenteredStyledBox>
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-end">
              <Typography variant="h3">Legal entity</Typography>
              <Typography variant="labelSemiBold" sx={{ ml: '4px', mb: '2px' }}>
                (Optional)
              </Typography>
            </Stack>
            <Typography variant="body1">
              Legal Borrowing Entity for this project if different from borrower's company name.
            </Typography>
          </Stack>

          <CustomTextField field={legal_entity} label="Company name" />
        </CenteredStyledBox>
      )}
    </Stack>
  );
};

export default BorrowerInformation;

const NewBorrowerCompanyBlock = ({
  borrowerDetailsFields,
  inviteBorrowers,
  setInviteBorrowers,
}) => {
  const {
    borrower_company_name,
    borrower_company_state,
    borrower_company_address_1,
    borrower_company_city,
    borrower_company_zip_code,
    first_name,
    last_name,
    phone,
  } = borrowerDetailsFields;
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="h4">Borrower company</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <CustomTextField
          field={first_name}
          label="First name"
          required
          inputProps={{
            'data-cy': 'project__create__borrower_email__first_name__input',
          }}
        />
        <CustomTextField
          field={last_name}
          label="Last name"
          required
          inputProps={{
            'data-cy': 'project__create__borrower_email__last_name__input',
          }}
        />
        <InternationalPhoneField
          field={phone}
          label="Phone number"
          required
          inputProps={{
            'data-cy': 'project__create__borrower_email__phone__input',
          }}
        />
      </Stack>

      <CustomTextField
        field={borrower_company_name}
        label="Borrower company name"
        required
        inputProps={{
          'data-cy': 'project__create__borrower_company_name_input',
        }}
      />
      <Stack>
        <CustomTextField
          field={borrower_company_address_1}
          label="Address"
          required
          inputProps={{
            'data-cy': 'project__create__borrower_company_address_1_input',
          }}
        />
        <Typography variant="label" sx={{ ml: '14px', display: 'inline-block' }}>
          Primary borrower's address is required for KYC
        </Typography>
      </Stack>

      <CustomTextField
        field={borrower_company_city}
        label="City"
        required
        inputProps={{
          'data-cy': 'project__create__borrower_company_city_input',
        }}
      />

      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <CustomAutocomplete
          field={borrower_company_state}
          label="State"
          options={states}
          required
          inputProps={{
            'data-cy': 'project__create__borrower_company_state_input',
          }}
        />

        <CustomTextField
          field={borrower_company_zip_code}
          label="Zip code"
          validationText="Please enter a valid 5-digit zip code"
          required
          inputProps={{
            'data-cy': 'project__create__borrower_company_zip_code_input',
          }}
        />
      </Stack>
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Checkbox
            sx={{ ml: '-2px' }}
            checked={inviteBorrowers}
            onChange={(_e, value) => setInviteBorrowers(value)}
          />
        }
        label={
          <Typography variant="body2">Invite not active borrower to use the platform.</Typography>
        }
      />
    </Stack>
  );
};
