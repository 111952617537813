import React from 'react';

import { UserListColumnType } from './common';
import { CoordinatorSwitch } from '@components';

const coordinator: UserListColumnType = {
  name: 'coordinator',
  columnText: 'Coordinator',
  width: '6%',
  renderCell: ({ row, coordinator, showCoordinator }) => (
    <CoordinatorSwitch row={row} coordinator={coordinator} showCoordinator={showCoordinator} />
  ),
};

export default coordinator;
