import { colors, typography } from '@theme';

export default function DataGrid() {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          minWidth: '100%',
        },
        columnHeaderTitle: {
          whiteSpace: 'normal',
          lineHeight: 'normal',
          ...typography.labelSemiBold,
          color: colors.text.medium,
        },
        cell: {
          borderRight: `1px solid ${colors.neutral.lighter}`,
          borderBottom: 'none',
          borderTop: 'none',
          display: 'flex',
          alignItems: 'center',
        },
        columnHeader: {
          borderRight: `1px solid ${colors.neutral.lighter}`,
          backgroundColor: colors.neutral.lightest,
        },
        columnHeaders: {
          maxHeight: '168px !important',
        },
        row: {
          borderBottom: `1px solid ${colors.neutral.lighter}`,
        },
        menu: {
          ['.MuiMenuItem-root']: {
            ['.MuiTypography-root']: {
              ...typography.labelSemiBold,
              color: colors.text.medium,
            },
          },
        },
      },
      defaultProps: {},
    },
  };
}
