import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { StringFieldModel, useStringFieldModel } from '@models';
import {
  IWorkflowReason,
  PopupTypeEnum,
  QueryNamesEnums,
  RequestOperationsModalTypeEnum,
  WorkflowReasonEnum,
} from '@interfaces';
import { getWorkflowReasons } from '@globalService';
import { checkEmptyHTMLTags, getReasonsList } from '@utils';
import { ReturnIcon } from '@svgAsComponents';

interface ControllerInterface {
  notes: StringFieldModel;
  reasons: string[];
  reasonsList: string[];
  handleConfirmClick: () => void;
  text: string;
  title: string;
  type: PopupTypeEnum;
  handleSelectChange: (event) => void;
  isSelectedChanged: boolean;
  buttonText?: string;
  reasonsText: string;
  commentText: string;
  confirmDisabled?: boolean;
  icon: React.ComponentType<{ size: number; color: string }>;
}

export const useRequestTerminationModal = ({
  confirmCallback,
  terminationType,
  drNumber,
  drType,
}): ControllerInterface => {
  const reasonsQuery = useQuery<IWorkflowReason[], Error>(
    [QueryNamesEnums.GET_WORKFLOW_REASONS],
    getWorkflowReasons.bind(this),
  );

  const modalValues = useMemo(
    () => ({
      [RequestOperationsModalTypeEnum.REJECT]: {
        title: `Return ${drType.toLowerCase()} request #${drNumber}`,
        text: 'Are you sure you want to return this request to the borrower?',
        type: PopupTypeEnum.ERROR,
        buttonText: 'Return',
        reasonsList: getReasonsList(reasonsQuery.data, WorkflowReasonEnum.REJECT),
        icon: ReturnIcon,
        reasonsText: 'Reason(s) of return',
        commentText: 'Comment (So borrower knows how to correct the problem and resubmit.)',
      },
      [RequestOperationsModalTypeEnum.PUT_ON_HOLD]: {
        title: 'Confirmation',
        text: `You put ${drType} #${drNumber} on hold.`,
        type: PopupTypeEnum.CONFIRMATION,
        buttonText: 'Confirm',
        reasonsList: getReasonsList(reasonsQuery.data, WorkflowReasonEnum.PUT_ON_HOLD),
      },
      [RequestOperationsModalTypeEnum.RELEASE_FROM_HOLD]: {
        title: 'Confirmation',
        text: `You release ${drType} #${drNumber} on hold.`,
        type: PopupTypeEnum.CONFIRMATION,
        buttonText: 'Confirm',
        reasonsList: getReasonsList(reasonsQuery.data, WorkflowReasonEnum.RELEASE_FROM_HOLD),
      },
    }),
    [reasonsQuery.data, drNumber, drType],
  );

  const notes = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()),
    validateOnChange: true,
  });
  const [reasons, setReasons] = useState([]);
  const [isSelectedChanged, setIsSelectedChanged] = useState(false);

  const handleSelectChange = (event) => {
    if (!isSelectedChanged) setIsSelectedChanged(true);
    setReasons(event.target.value);
  };

  const isOtherChoosen = useMemo(() => reasons.includes('Other'), [reasons]);

  const handleConfirmClick = () => {
    if (isOtherChoosen && !notes.value) {
      notes.validate();
      return;
    }
    confirmCallback({ reasons, notes, type: terminationType });
  };

  return {
    notes,
    reasons,
    reasonsList: modalValues[terminationType].reasonsList,
    text: modalValues[terminationType].text,
    title: modalValues[terminationType].title,
    type: modalValues[terminationType].type,
    icon: modalValues[terminationType].icon,
    reasonsText: modalValues[terminationType].reasonsText || 'Reason(s)',
    commentText: modalValues[terminationType].commentText || 'Comment',
    handleConfirmClick,
    handleSelectChange,
    isSelectedChanged,
    buttonText: modalValues[terminationType].buttonText,
    confirmDisabled: reasons.length < 1 || (isOtherChoosen && checkEmptyHTMLTags(notes.value)),
  };
};
