import { tabClasses } from '@mui/material/Tab';
import { tabsClasses } from '@mui/material/Tabs';

import { colors, typography } from '../index';

export default function Tabs() {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.neutral.lighter}`,
        },
        indicator: {
          height: 2,
          borderRadius: 6,
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          '& > span': {
            width: '100%',
            backgroundColor: colors.main.primary.main,
            marginLeft: 16,
            marginRight: 16,
            borderRadius: 6,
          },
        },
      },
      defaultProps: {
        sx: {
          [`& .${tabClasses.root}`]: {
            ...typography.body2SemiBold,
            color: colors.text.medium,
            textTransform: 'none',
          },

          [`& .${tabClasses.root}.${tabClasses.selected}`]: {
            color: colors.text.heading,
          },
          [`&&& .${tabsClasses.scroller}`]: {
            position: 'unset',
          },
        },
      },
    },
  };
}
