import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8533 19.5003C20.8533 19.7762 20.6297 20 20.3533 20H13.8533H10.8533H8.68257C8.79312 19.6872 8.85327 19.3506 8.85327 19V4.33333C8.85327 4.14924 9.00251 4 9.1866 4H20.3533C20.6294 4 20.8533 4.22386 20.8533 4.5V12.2286V19.5003ZM20.3533 22H13.8533H10.8533H5.85327C4.19642 22 2.85327 20.6569 2.85327 19V7.13861C2.85327 6.50977 3.36305 6 3.99189 6L5.85327 6H6.85327V4.33333C6.85327 3.04467 7.89794 2 9.1866 2H20.3533C21.734 2 22.8533 3.11929 22.8533 4.5V12.2286V19.5003C22.8533 20.8813 21.7337 22 20.3533 22ZM6.85327 8L6.35327 8H4.85327V19C4.85327 19.5523 5.30099 20 5.85327 20C6.40556 20 6.85327 19.5523 6.85327 19V8ZM17.8533 11C18.4056 11 18.8533 11.4477 18.8533 12C18.8533 12.5523 18.4056 13 17.8533 13H11.8533C11.301 13 10.8533 12.5523 10.8533 12C10.8533 11.4477 11.301 11 11.8533 11H17.8533ZM18.8533 16C18.8533 15.4477 18.4056 15 17.8533 15H11.8533C11.301 15 10.8533 15.4477 10.8533 16C10.8533 16.5523 11.301 17 11.8533 17H17.8533C18.4056 17 18.8533 16.5523 18.8533 16ZM17.8533 7C18.4056 7 18.8533 7.44771 18.8533 8C18.8533 8.55228 18.4056 9 17.8533 9H11.8533C11.301 9 10.8533 8.55228 10.8533 8C10.8533 7.44771 11.301 7 11.8533 7H17.8533Z"
      fill={color}
    />
  </svg>
);

export default Icon;
