import { currencyFormatter } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const disbursementAmountRequested: MilestoneListColumnTypeV2 = {
  field: 'disbursement_amount_requested',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Requested net disbursement ($)',
  description: 'Amount retainage heldback for this draw ($)',
  editable: false,
  valueFormatter: (value) => currencyFormatter(value, ''),
  minWidth: 120,
};

export default disbursementAmountRequested;
