import { inspectionListQueryFields } from '@constants';

export const drawRequestInspectionsList = ({
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  query = `{${inspectionListQueryFields.join(',')}}`,
  stringQueryParams = '',
}) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/inspections/?limit=100&query=${query}&${stringQueryParams}`;

export const projectInspection = ({
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  query = '{*,draw_request{id,status,number},-milestones}',
}) => `projects/${projectId}/inspections/${inspectionId}/?query=${query}`;

export const projectInspectionsMilestones = (
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  queryFiltersParams = '',
  filterParams = '',
  milestoneTagsParams = '',
  typeKeysParams = '',
) =>
  `projects/${projectId}/inspections/${inspectionId}/milestones/?${filterParams}${typeKeysParams}${milestoneTagsParams}${queryFiltersParams}`;

export const projectInspectionsMilestoneGroups = (
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  queryFiltersParams = '',
  filterParams = '',
  milestoneTagsParams = '',
  typeKeysParams = '',
) =>
  `projects/${projectId}/inspections/${inspectionId}/milestone_groups/?${filterParams}${typeKeysParams}${milestoneTagsParams}${queryFiltersParams}`;

export const projectInspectionsList = (
  projectId = 'project_pk',
  query = `{${inspectionListQueryFields.join(',')}}`,
  stringQueryParams = '',
) => `projects/${projectId}/inspections/?limit=100&query=${query}&${stringQueryParams}`;

export const projectInspectionMilestone = (
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  milestoneId = 'milestone_id',
) => `projects/${projectId}/inspections/${inspectionId}/milestones/${milestoneId}/`;

export const inspectionMilestoneDocuments = (
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  milestoneId = 'milestone_id',
  query = '',
  stringQueryParams = '',
) =>
  `projects/${projectId}/inspections/${inspectionId}/milestones/${milestoneId}/documents/?${stringQueryParams}${query}`;

export const inspectionMilestoneGroupDocuments = (
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  milestoneId = 'milestone_id',
  query = '',
) =>
  `projects/${projectId}/inspections/${inspectionId}/milestone_groups/${milestoneId}/documents/?${query}`;

export const projectInspectionGroupById = ({
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  group_by = '',
  milestoneId = '',
}) =>
  `projects/${projectId}/inspections/${inspectionId}/milestone_groups/${milestoneId}/?group_by=${group_by}`;

export const scheduleProjectInspection = ({
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
}) => `projects/${projectId}/inspections/${inspectionId}/schedule/`;

export const cancelProjectInspection = ({
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
}) => `projects/${projectId}/inspections/${inspectionId}/cancel/`;

export const inspectionAgencies = () => `projects/inspections/agencies/?limit=100`;

export const inspectionAgenciesWithQuery = ({ restQlParams }: { restQlParams: string }) =>
  `projects/inspections/agencies/?limit=100${restQlParams ? `&query=${restQlParams}` : ''}`;

export const inspectionAgenciesByProject = (projectId = 'project_pk') =>
  `projects/${projectId}/inspections/agencies/?limit=100`;

export const inspectionsList = (stringQueryParams) => `projects/inspections/?${stringQueryParams}`;
