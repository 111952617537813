import React, { FC } from 'react';
import { FilterButtonV2, typeFilter } from '@components';

const TypeFilter: FC<{
  handleTypeClick: (value: string[]) => void;
  typesFilterValues: string[];
}> = ({ handleTypeClick, typesFilterValues }) => {
  return (
    <FilterButtonV2
      handleFiltersChange={(_key, value) => handleTypeClick(value)}
      initValues={typesFilterValues}
      maxWidth="300px"
      minWidth="150px"
      closeOnChange
      defaultFilterLabel="Not selected"
      {...typeFilter}
    />
  );
};
export default TypeFilter;
