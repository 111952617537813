import { colors } from '@theme';

export interface ComponentProps {
  size?: number;
  color?: string;
}

export const defaultProps = {
  size: 16,
  color: colors.neutral.darker,
};
