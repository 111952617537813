import { default as email } from './email';
import { default as icon } from './icon';
import { default as lastOnline } from './lastOnline';
import { default as name } from './name';
import { default as optionsMenu } from './optionsMenu';
import { default as status } from './status';
import { default as teams } from './teams';
import { default as phone } from './phone';
import { default as coordinator } from './coordinator';

export default {
  email,
  icon,
  lastOnline,
  name,
  status,
  teams,
  phone,
  optionsMenu,
  coordinator,
};
