import {
  DocIcon,
  ImageIcon,
  MessageIcon,
  PdfIcon,
  PptIcon,
  TxtIcon,
  XlsIcon,
  ZipIcon,
} from '@svgAsComponents';

export const fileTypesMap = {
  'application/pdf': PdfIcon,
  'image/png': ImageIcon,
  'application/msword': DocIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DocIcon,
  'image/jpeg': ImageIcon,
  'application/mspowerpoint': PptIcon,
  'application/powerpoint': PptIcon,
  'application/vnd.ms-powerpoint': PptIcon,
  'application/x-mspowerpoint': PptIcon,
  'application/excel': XlsIcon,
  'application/vnd.ms-excel': XlsIcon,
  'application/x-excel': XlsIcon,
  'application/x-msexcel': XlsIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': XlsIcon,
  'text/plain': TxtIcon,
  'application/zip': ZipIcon,
  'image/vnd.fpx': MessageIcon,
  'message/rfc822': MessageIcon,
  'application/octet-stream': MessageIcon,
};
