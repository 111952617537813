import {
  DataProviderResultType,
  FilteredData,
  FunctionArguments,
  FunctionReturnType,
} from '../types';
import { dataProvidersLists } from '../provider';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import pick from 'lodash/pick';

export function useGetListData<
  P extends keyof typeof dataProvidersLists,
  K extends keyof DataProviderResultType<FunctionReturnType<(typeof dataProvidersLists)[P]>>,
>({
  type,
  keys,
  args,
  options,
}: {
  type: P;
  keys?: K[];
  args: FunctionArguments<(typeof dataProvidersLists)[P]>;
  options?: {
    paginate?: boolean;
    skip?: boolean;
    strictSerialize?: (
      values: Array<
        FilteredData<DataProviderResultType<FunctionReturnType<(typeof dataProvidersLists)[P]>>, K>
      >,
    ) => any;
  };
}) {
  const queryClient = useQueryClient();
  const state = queryClient.getQueryState<FunctionReturnType<(typeof dataProvidersLists)[P]>>([
    type,
    args,
  ]);
  const data = state ? state.data : undefined;
  const { ...other } = state || {};
  const dataToCheck = data?.results;
  const queryFnName = useMemo(() => [type, args], [type, args]);
  const nonExistingFields = useMemo(() => {
    if (!dataToCheck) {
      return keys;
    }
    if (Array.isArray(dataToCheck)) {
      return dataToCheck.length ? keys.filter((key) => !(key in dataToCheck[0])) : [];
    } else {
      return keys.filter((key) => !(key in dataToCheck));
    }
  }, [dataToCheck, keys]);

  const prevState = useMemo(
    () =>
      options.paginate && args?.['pagination']?.['page'] > 0
        ? queryClient.getQueryState<FunctionReturnType<(typeof dataProvidersLists)[P]>>([
            type,
            {
              ...args,
              pagination: {
                ...(args?.['pagination'] || {}),
                page: args?.['pagination']?.['page'] - 1,
              },
            },
          ])
        : undefined,
    [args, type, options],
  );

  const [isLoading, setIsLoading] = useState<boolean>(!!nonExistingFields.length && !options?.skip);

  const fetchMissingFields = useCallback(
    async (fields: K[]) => {
      setIsLoading(true);
      const argWithFields: FunctionArguments<(typeof dataProvidersLists)[P]> = {
        ...args,
        restQlParams: `{${keys.join(',')}}`,
      };
      const fetchedData = await queryClient.fetchQuery(
        [...queryFnName, fields],
        () =>
          dataProvidersLists[type]?.(argWithFields as any) as Promise<
            FunctionReturnType<(typeof dataProvidersLists)[P]>
          >,
      );

      queryClient.setQueryData(queryFnName, {
        ...fetchedData,
        results: [
          ...(prevState?.data?.results || []),
          ...fetchedData.results.map((item, index) => ({
            ...(data?.['results']?.[index] || {}),
            ...item,
          })),
        ],
      });

      setIsLoading(false);
    },
    [queryClient, type, args, data, prevState],
  );

  const filterData = useMemo<{
    results: Array<
      FilteredData<DataProviderResultType<FunctionReturnType<(typeof dataProvidersLists)[P]>>, K>
    >;
  }>(() => {
    if (!data) {
      return prevState?.data
        ? {
            ...prevState.data,
            results: prevState.data?.results.map((item) => pick(item, keys)),
          }
        : undefined;
    }

    return {
      ...data,
      results: data?.results.map((item) => pick(item, keys)),
    };
  }, [data, keys, prevState]);

  useEffect(() => {
    if (nonExistingFields.length && !options?.skip) fetchMissingFields(nonExistingFields);
  }, [nonExistingFields, options]);

  useEffect(() => {
    if (state?.isInvalidated && !isLoading) {
      fetchMissingFields(keys);
    }
  }, [state?.isInvalidated, keys, isLoading]);

  const manualUpdate = useCallback(
    (update: {
      results: Array<
        FilteredData<DataProviderResultType<FunctionReturnType<(typeof dataProvidersLists)[P]>>, K>
      >;
    }) => queryClient.setQueryData(queryFnName, { ...(data || {}), ...update }),
    [queryFnName, data],
  );

  return {
    data: filterData,
    ...other,
    isLoading,
    manualUpdate,
    serialized:
      filterData?.results && options.strictSerialize
        ? options.strictSerialize(filterData.results)
        : [],
  };
}
