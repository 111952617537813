import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.status.error.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.76992 9.78701L10.6403 2.91665C11.8625 1.69445 13.8441 1.69445 15.0663 2.91665L21.9366 9.78701C23.1588 11.0092 23.1588 12.9908 21.9366 14.213L15.0663 21.0834C13.8441 22.3056 11.8625 22.3055 10.6403 21.0834L3.76992 14.213C2.54772 12.9908 2.54772 11.0092 3.76992 9.78701ZM5.24524 11.2623C4.83784 11.6697 4.83784 12.3303 5.24524 12.7377L12.1156 19.608C12.523 20.0154 13.1835 20.0154 13.5909 19.608L20.4613 12.7377C20.8687 12.3303 20.8687 11.6697 20.4613 11.2623L13.5909 4.39197C13.1835 3.98457 12.523 3.98457 12.1156 4.39197L5.24524 11.2623ZM12.8533 13.304C12.1331 13.304 11.5493 12.7202 11.5493 12V7.82716C11.5493 7.10697 12.1331 6.52314 12.8533 6.52314C13.5735 6.52314 14.1573 7.10697 14.1573 7.82716V12C14.1573 12.7202 13.5735 13.304 12.8533 13.304ZM12.8533 17.2161C13.5735 17.2161 14.1573 16.6322 14.1573 15.912C14.1573 15.1919 13.5735 14.608 12.8533 14.608C12.1331 14.608 11.5493 15.1919 11.5493 15.912C11.5493 16.6322 12.1331 17.2161 12.8533 17.2161Z"
      fill={color}
    />
  </svg>
);

export default Icon;
