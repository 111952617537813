import React from 'react';
import { Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { StyledBox } from '@components';

const MobileInfoBox = ({ text, style }) => (
  <StyledBox
    sx={[
      {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 0,
      },
      style,
    ]}
  >
    <InfoOutlinedIcon
      sx={{
        fontSize: '13.33px',
        color: colors.status.information.medium,
        mx: 1,
        mr: '9.33px',
      }}
    />
    <Stack sx={{ my: 1 }}>
      <Typography variant="label">{text}</Typography>
      <Typography variant="label">Log onto your desktop to get full experience.</Typography>
    </Stack>
  </StyledBox>
);

export default MobileInfoBox;
