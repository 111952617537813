import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M5.35327 4C5.07676 4 4.85327 4.2238 4.85327 4.49958V12.2286V14V16V17C4.85327 18.6569 6.19642 20 7.85327 20C9.70222 20 11.2011 18.5011 11.2011 16.6522V16.0495C11.2011 15.4699 11.671 15 12.2506 15H15.8533V5C15.8533 4.44772 15.4056 4 14.8533 4H5.35327ZM2.85327 12.2286V14V16V17V17.1143H2.85455C2.9153 19.8229 5.13004 22 7.85327 22H16.8533C20.1267 22 22.8533 19.3488 22.8533 16.0495C22.8533 15.4699 22.3834 15 21.8038 15H17.8533V5C17.8533 3.34315 16.5101 2 14.8533 2H5.35327C3.97293 2 2.85327 3.1185 2.85327 4.49958V12.2286ZM12.0238 20H16.8533C18.7169 20 20.2997 18.7155 20.7348 17H17.8533H17.3533H13.19C13.1173 18.132 12.6924 19.1681 12.0238 20ZM11.353 6C11.353 5.44772 10.9053 5 10.353 5C9.80074 5 9.35303 5.44772 9.35303 6V6.35632C8.22242 6.5179 7.35327 7.49018 7.35327 8.66546C7.35327 9.95379 8.39767 10.9982 9.686 10.9982H11.0224C11.2061 10.9982 11.3551 11.1472 11.3551 11.3309C11.3551 11.5147 11.2061 11.6637 11.0224 11.6637H8.68676C8.13447 11.6637 7.68676 12.1114 7.68676 12.6637C7.68676 13.2159 8.13447 13.6637 8.68676 13.6637H9.35303V13.9964C9.35303 14.5487 9.80074 14.9964 10.353 14.9964C10.9053 14.9964 11.353 14.5487 11.353 13.9964V13.6404C12.4848 13.4798 13.3551 12.507 13.3551 11.3309C13.3551 10.0426 12.3107 8.99819 11.0224 8.99819H9.686C9.50224 8.99819 9.35327 8.84923 9.35327 8.66546C9.35327 8.4817 9.50224 8.33273 9.686 8.33273H12.0216C12.5739 8.33273 13.0216 7.88502 13.0216 7.33273C13.0216 6.78045 12.5739 6.33273 12.0216 6.33273H11.353V6Z"
    />
  </svg>
);

export default Icon;
