import React, { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ToggleButtonProps } from '@mui/material/ToggleButton';

interface ViewType {
  label: string;
  value: string;
}

const ToggleButtonGroupComponent: FC<{
  value: string;
  handleChange: (event, value) => void;
  typesList: ViewType[] | string[];
  size?: ToggleButtonProps['size'];
  source?: string;
}> = ({ value, typesList, handleChange, size = 'medium', source }) => (
  <ToggleButtonGroup exclusive value={value} onChange={handleChange} size={size}>
    {typesList.map((item) => (
      <ToggleButton
        key={item?.label}
        value={item?.value}
        data-cy={`${source}__${item.value}__button`}
      >
        {item?.label || item}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);

export default ToggleButtonGroupComponent;
