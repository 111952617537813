export const fonts = {
  primary: 'Inter',
  secondary: 'Roboto',
  poppins: 'Poppins',
  inter: 'Inter',
  serifThin: 'sans-serif-light',
  robotoThin: 'Roboto-Light',
  robotoRegular: 'Roboto-Regular',
  robotoMeduim: 'Roboto-Medium',
  robotoBold: 'Roboto-Bold',
  handWritten: 'BrownBagLunch',
  satisfyRegular: 'Satisfy',
};
