import { useState, useCallback } from 'react';

export interface ConfirmModalHookInterface {
  closeConfirmModal: () => void;
  askConfirm: (info?: any) => void;
  confirmCallback: ({ action }) => void;
  isConfirmModalOpened: boolean;
}

export const useConfirmationModal = (): ConfirmModalHookInterface => {
  const [isConfirmModalOpened, setOpened] = useState(false);

  // here we will save info about clicked item like id or index
  const [args, setAgrs] = useState(null);

  return {
    closeConfirmModal: useCallback(() => {
      setOpened(false);
    }, []),
    askConfirm: useCallback((info) => {
      setOpened(true);
      setAgrs(info || null);
    }, []),
    confirmCallback: useCallback(
      ({ action }) => {
        action(args);
        setAgrs(null);
        setOpened(false);
      },
      [args],
    ),
    isConfirmModalOpened,
  };
};
