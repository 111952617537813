import { currencyFormatter } from '@utils';
import { MilestoneListColumnTypeV2, isFooterRow } from './common';

const previousChanges: MilestoneListColumnTypeV2 = {
  field: 'previous_changes',
  valueFormatter: (value, row) =>
    currencyFormatter(isFooterRow(row) ? row.previous_estimate_changes : value, ''),
  minWidth: 130,
  maxWidth: 200,
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Total approved changes ($)',
  description: 'Cumulative approved changes to original scheduled value',
  editable: false,
};

export default previousChanges;
