import React from 'react';
import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import snakeCase from 'lodash/snakeCase';

import { Popup } from '@components';
import * as Controller from './controller';
import { ServiceOrderStatusEnum } from '@interfaces';
import { UpdateIcon } from '@svgAsComponents';

export default function UpdateServiceOrderStatus({
  open,
  handleOpen,
  handleUpdateStatus,
  initialValue,
  source,
}) {
  const { options, value, setValue } = Controller.useServiceOrderStatus(initialValue);

  return (
    <Popup open={open} title="Update service status" maxWidth="xs" icon={UpdateIcon}>
      <Stack sx={{ width: '100%' }}>
        <RadioGroup
          aria-label="inputType"
          name="inputType"
          value={value}
          onChange={(e) => setValue(e.target.value as ServiceOrderStatusEnum)}
          data-cy={`${source}__radio__group`}
        >
          {options.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio data-cy={`${source}__${snakeCase(value)}__option`} />}
              label={<Typography variant="body2">{label}</Typography>}
              sx={{ m: 0 }}
            />
          ))}
        </RadioGroup>
        <Box sx={{ mt: 7 }} />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            data-cy={`${source}__cancel__button`}
            variant="text"
            onClick={() => handleOpen(false)}
          >
            Cancel
          </Button>
          <Button
            data-cy={`${source}__confirm__button`}
            disabled={value === initialValue}
            onClick={() => handleUpdateStatus(value)}
          >
            Update
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
}
