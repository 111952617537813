import React, { FC } from 'react';
import { StatePageLayout } from '../../layouts';
import { TeamEnrollmentImage } from '@svgAsComponents';

const NoTeamEnrollmentPage: FC = () => {
  return (
    <StatePageLayout
      text="Seems like you don’t have team enrollment. Please, ask your account manager for help."
      imageComponent={<TeamEnrollmentImage />}
      showLogout
    />
  );
};

export default NoTeamEnrollmentPage;
