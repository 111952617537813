import { Dispatch, SetStateAction, useState } from 'react';

import { getArrayFromObject } from '@utils';
import { serviceOrderStatusMap } from '@constants';

export interface ControllerInterface {
  options: { value: string; label: string }[];
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

export const useServiceOrderStatus = (initialValue): ControllerInterface => {
  const [value, setValue] = useState(initialValue);

  const options = getArrayFromObject(serviceOrderStatusMap, 'value', 'label') as {
    value: string;
    label: string;
  }[];

  return {
    options,
    value,
    setValue,
  };
};
