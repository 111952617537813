import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const EditIcon = ({ size = 16, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.8135 0.72308C10.3333 0.202984 11.1759 0.202984 11.6957 0.72308L13.277 2.30546C13.7968 2.82556 13.7968 3.6688 13.277 4.1889L6.71895 10.7513C6.53395 10.9364 6.29852 11.0629 6.0421 11.1149L4.05905 11.5168C3.62178 11.6055 3.1692 11.469 2.85372 11.1533C2.53824 10.8376 2.40182 10.3847 2.4904 9.94716L2.89211 7.9628C2.94406 7.70621 3.07043 7.47062 3.25543 7.2855L9.8135 0.72308ZM4.08167 8.79459L4.15309 8.44177L5.56344 9.85306L5.21086 9.92453L3.79482 10.2116L4.08167 8.79459ZM6.59003 8.99689L5.00869 7.41451L9.8135 2.60651L10.7546 1.6648L11.3948 2.30546L11.6957 2.60651L12.3359 3.24718L11.3948 4.1889L6.59003 8.99689ZM0.998949 12.3346C0.631429 12.3346 0.333496 12.6327 0.333496 13.0004C0.333496 13.3682 0.63143 13.6663 0.998949 13.6663L12.9771 13.6663C13.3446 13.6663 13.6426 13.3682 13.6426 13.0004C13.6426 12.6327 13.3446 12.3346 12.9771 12.3346L0.998949 12.3346Z"
      fill={color}
    />
  </svg>
);

export default EditIcon;
