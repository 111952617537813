import React, { useEffect, useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { colors } from '@theme';

const Loader3DBoxes = () => {
  const textRef = useRef<HTMLDivElement>(null);
  const words = ['structure', 'models', 'units', 'line items'];
  let index = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (textRef.current) {
        textRef.current.textContent = `Loading ${words[index]}...`;
        index = (index + 1) % words.length;
      }
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Stack alignItems="center" justifyContent="center" spacing={3} sx={{ flex: 1 }}>
      <Box pt={4} />
      <div className="loader3D"></div>
      <Typography ref={textRef} sx={{ pb: 3, color: colors.main.primary.light }} variant="h4">
        Loading...
      </Typography>
    </Stack>
  );
};

export default Loader3DBoxes;
