import React from 'react';

const EditProfileImage = () => (
  <svg
    width="328"
    height="210"
    viewBox="0 0 328 270"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_25438_117)">
      <path
        d="M261.989 28.0575C261.278 30.6868 262.095 34.8267 262.403 37.281C262.731 39.905 258.181 42.2533 255.578 42.4017C255.642 41.5332 255.398 40.6698 254.888 39.9633C254.882 40.8035 254.709 41.6342 254.379 42.407H232.653C229.917 37.2598 227.589 30.2256 230.102 22.9528C230.23 21.4921 230.786 20.1016 231.701 18.9558C232.616 17.81 233.849 16.9599 235.246 16.5122L247.093 12.7221C251.765 12.7221 257.497 13.3847 260.08 16.8621C261.223 18.463 261.988 20.302 262.319 22.2409C262.65 24.1798 262.537 26.1684 261.989 28.0575Z"
        fill="#262626"
      />
      <path
        d="M282.791 255.507C283.178 255.522 283.56 255.416 283.886 255.206C284.211 254.996 284.464 254.69 284.61 254.332C284.756 253.973 284.788 253.578 284.702 253.2C284.616 252.823 284.416 252.48 284.129 252.22C284.08 252.031 284.044 251.896 283.995 251.707C284.356 250.763 284.992 249.948 285.819 249.367C286.647 248.786 287.63 248.465 288.641 248.446C289.652 248.427 290.646 248.71 291.495 249.259C292.344 249.809 293.01 250.599 293.407 251.528C295.696 256.748 298.647 262.145 297.26 267.931C306.59 247.667 303.113 222.604 288.876 205.486C284.966 203.381 281.777 199.606 280.914 195.195C282.845 195.829 285.231 194.241 283.764 192.228C283.129 191.459 282.488 190.696 281.854 189.927C289.092 182.073 297.922 194.225 289.973 204.809C292.637 208.084 294.952 211.629 296.879 215.385C296.404 211.381 296.887 207.322 298.287 203.542C299.71 200.017 302.41 197.035 304.784 193.977C307.671 190.251 313.7 191.9 314.138 196.589C311.45 197.5 311.137 201.397 314.226 201.9C313.502 210.638 307.873 218.715 300.051 222.619C303.03 230.73 304.246 239.384 303.617 248.002C304.908 237.925 315.692 235.379 323.208 231.307C323.686 230.995 324.245 230.828 324.816 230.827C325.387 230.826 325.946 230.991 326.425 231.303C326.904 231.614 327.281 232.058 327.512 232.58C327.743 233.102 327.817 233.681 327.724 234.244C326.686 234.678 325.693 235.213 324.76 235.843C324.323 236.143 323.99 236.571 323.805 237.067C323.621 237.564 323.595 238.105 323.73 238.618C323.866 239.13 324.157 239.587 324.563 239.928C324.969 240.268 325.47 240.475 325.998 240.519C322.28 249.976 312.496 256.395 302.385 256.456C301.453 260.686 300.085 264.807 298.301 268.754L283.294 268.897C283.239 268.731 283.189 268.559 283.139 268.393C284.529 268.467 285.923 268.371 287.289 268.106C283.573 263.792 280.709 261.6 282.79 255.508L282.791 255.507Z"
        fill="#E8E8E8"
      />
      <path d="M251.819 258.524H245.966L243.18 235.956H251.819V258.524Z" fill="#FAC9AD" />
      <path
        d="M254.909 268.662H250.711L249.962 264.7L248.043 268.662H236.91C236.376 268.662 235.856 268.491 235.427 268.175C234.997 267.859 234.679 267.414 234.52 266.905C234.362 266.395 234.37 265.849 234.544 265.344C234.718 264.84 235.049 264.405 235.488 264.102L244.378 257.964V253.959L253.729 254.517L254.909 268.662Z"
        fill="#262626"
      />
      <path
        d="M261.007 217.649L256.897 221.816L238.865 207.954L244.93 201.805L261.007 217.649Z"
        fill="#FAC9AD"
      />
      <path
        d="M270.398 222.567L267.451 225.555L264.103 223.307L265.577 227.455L257.761 235.379C257.387 235.759 256.9 236.01 256.373 236.094C255.846 236.178 255.306 236.091 254.832 235.847C254.358 235.602 253.974 235.212 253.737 234.734C253.5 234.257 253.422 233.715 253.514 233.19L255.384 222.553L252.531 219.741L259.493 213.476L270.398 222.567Z"
        fill="#262626"
      />
      <path
        d="M214.185 95.5573L231.89 67.3997C232.636 66.2046 232.883 64.7639 232.576 63.3887C232.269 62.0134 231.434 60.8138 230.25 60.0488C229.638 59.653 228.952 59.386 228.233 59.2641C227.514 59.1422 226.777 59.168 226.069 59.3398C225.36 59.5116 224.694 59.8259 224.111 60.2635C223.528 60.7011 223.04 61.2528 222.677 61.885L205.183 91.6975L193.789 109.365C192.841 109.199 191.865 109.329 190.994 109.738C190.122 110.146 189.398 110.812 188.919 111.647C188.44 112.481 188.229 113.442 188.315 114.4C188.402 115.358 188.781 116.266 189.402 117.001C190.023 117.736 190.855 118.262 191.785 118.508C192.716 118.754 193.699 118.708 194.602 118.375C195.505 118.043 196.284 117.441 196.833 116.65C197.381 115.86 197.673 114.92 197.669 113.958L214.185 95.5573Z"
        fill="#FAC9AD"
      />
      <path
        d="M230.87 74.7637L232.363 74.4822C232.162 74.1161 232.078 73.6971 232.122 73.2818C232.167 72.8665 232.337 72.4748 232.611 72.1593C232.885 71.8439 233.249 71.6198 233.654 71.5174C234.059 71.415 234.486 71.4391 234.877 71.5864C233.078 69.7113 233.952 66.6242 235.109 64.2981C235.945 62.6168 236.885 60.9555 237.294 59.123C237.916 56.341 237.115 52.9739 233.403 52.3078C231.886 52.0705 230.333 52.3847 229.028 53.1933C226.695 54.5712 226.17 53.9713 224.699 56.1893L223.86 57.7475C222.783 59.4345 221.497 60.979 220.033 62.3446C218.987 63.3865 218.143 64.6141 217.546 65.9646C217.467 66.1318 217.351 66.2785 217.207 66.3934C216.632 66.8371 215.852 66.8591 215.133 66.9537C214.226 67.0729 213.189 67.4567 213.212 68.5811C213.225 68.8498 213.294 69.113 213.414 69.354C213.533 69.595 213.702 69.8087 213.908 69.9815C217.737 73.3309 222.217 75.8525 227.068 77.388C228.145 77.7284 229.356 78.0101 230.37 77.5108C231.383 77.0114 231.818 75.3787 230.87 74.7637Z"
        fill="#5E8376"
      />
      <path
        d="M253.334 128.281L218.865 127.751L217.805 119.8L220.986 101.777H248.031L253.334 128.281Z"
        fill="#FAC9AD"
      />
      <path
        d="M179.624 149.485H45.4591C42.2254 149.481 39.1252 148.195 36.8387 145.91C34.5521 143.624 33.266 140.525 33.2623 137.293V60.43C33.266 57.1976 34.5522 54.0986 36.8387 51.813C39.1253 49.5273 42.2254 48.2416 45.4591 48.238H179.624C182.857 48.2416 185.957 49.5273 188.244 51.813C190.531 54.0986 191.817 57.1976 191.82 60.43V137.293C191.817 140.525 190.531 143.624 188.244 145.91C185.957 148.195 182.857 149.481 179.624 149.485Z"
        fill="white"
      />
      <path
        d="M179.624 149.485H45.4591C42.2254 149.481 39.1252 148.195 36.8387 145.91C34.5521 143.624 33.266 140.525 33.2623 137.293V60.43C33.266 57.1976 34.5522 54.0986 36.8387 51.813C39.1253 49.5273 42.2254 48.2416 45.4591 48.238H179.624C182.857 48.2416 185.957 49.5273 188.244 51.813C190.531 54.0986 191.817 57.1976 191.82 60.43V137.293C191.817 140.525 190.531 143.624 188.244 145.91C185.957 148.195 182.857 149.481 179.624 149.485ZM45.4591 49.2981C42.5066 49.3014 39.676 50.4753 37.5883 52.5622C35.5006 54.6491 34.3262 57.4786 34.3229 60.43V137.293C34.3263 140.244 35.5006 143.073 37.5883 145.16C39.676 147.247 42.5066 148.421 45.4591 148.424H179.624C182.576 148.421 185.407 147.247 187.494 145.16C189.582 143.073 190.756 140.244 190.76 137.293V60.43C190.756 57.4786 189.582 54.6491 187.494 52.5622C185.407 50.4753 182.576 49.3014 179.624 49.2981H45.4591Z"
        fill="#D2D2D2"
      />
      <path
        d="M190.76 104.077H33.7923C33.6516 104.077 33.5167 104.021 33.4173 103.922C33.3178 103.822 33.262 103.687 33.262 103.547C33.262 103.406 33.3178 103.272 33.4173 103.172C33.5167 103.073 33.6516 103.017 33.7923 103.017H190.76C190.901 103.017 191.036 103.073 191.135 103.172C191.235 103.272 191.291 103.406 191.291 103.547C191.291 103.687 191.235 103.822 191.135 103.922C191.036 104.021 190.901 104.077 190.76 104.077Z"
        fill="#D2D2D2"
      />
      <path
        d="M179.093 24.9141C181.729 24.9141 183.866 22.7781 183.866 20.1433C183.866 17.5085 181.729 15.3725 179.093 15.3725C176.457 15.3725 174.321 17.5085 174.321 20.1433C174.321 22.7781 176.457 24.9141 179.093 24.9141Z"
        fill="#E8E8E8"
      />
      <path
        d="M4.77265 100.876C7.40851 100.876 9.5453 98.7397 9.5453 96.1048C9.5453 93.47 7.40851 91.334 4.77265 91.334C2.13679 91.334 0 93.47 0 96.1048C0 98.7397 2.13679 100.876 4.77265 100.876Z"
        fill="#E8E8E8"
      />
      <path
        d="M102.877 182.509C105.513 182.509 107.65 180.373 107.65 177.738C107.65 175.103 105.513 172.967 102.877 172.967C100.241 172.967 98.1045 175.103 98.1045 177.738C98.1045 180.373 100.241 182.509 102.877 182.509Z"
        fill="#E8E8E8"
      />
      <path
        d="M74.0532 127.38H57.4846C57.344 127.38 57.2091 127.324 57.1097 127.225C57.0102 127.125 56.9543 126.99 56.9543 126.85C56.9543 126.709 57.0102 126.574 57.1097 126.475C57.2091 126.376 57.344 126.32 57.4846 126.32H74.0532C74.1939 126.32 74.3288 126.376 74.4282 126.475C74.5277 126.574 74.5835 126.709 74.5835 126.85C74.5835 126.99 74.5277 127.125 74.4282 127.225C74.3288 127.324 74.1939 127.38 74.0532 127.38Z"
        fill="#BCBCBC"
      />
      <path
        d="M74.0532 135.861H57.4846C57.344 135.861 57.2091 135.806 57.1097 135.706C57.0102 135.607 56.9543 135.472 56.9543 135.331C56.9543 135.191 57.0102 135.056 57.1097 134.956C57.2091 134.857 57.344 134.801 57.4846 134.801H74.0532C74.1939 134.801 74.3288 134.857 74.4282 134.956C74.5277 135.056 74.5835 135.191 74.5835 135.331C74.5835 135.472 74.5277 135.607 74.4282 135.706C74.3288 135.806 74.1939 135.861 74.0532 135.861Z"
        fill="#BCBCBC"
      />
      <path
        d="M164.734 127.38H148.165C148.024 127.38 147.89 127.324 147.79 127.225C147.691 127.125 147.635 126.99 147.635 126.85C147.635 126.709 147.691 126.574 147.79 126.475C147.89 126.376 148.024 126.32 148.165 126.32H164.734C164.874 126.32 165.009 126.376 165.109 126.475C165.208 126.574 165.264 126.709 165.264 126.85C165.264 126.99 165.208 127.125 165.109 127.225C165.009 127.324 164.874 127.38 164.734 127.38Z"
        fill="#BCBCBC"
      />
      <path
        d="M164.734 135.861H148.165C148.024 135.861 147.89 135.806 147.79 135.706C147.691 135.607 147.635 135.472 147.635 135.331C147.635 135.191 147.691 135.056 147.79 134.956C147.89 134.857 148.024 134.801 148.165 134.801H164.734C164.874 134.801 165.009 134.857 165.109 134.956C165.208 135.056 165.264 135.191 165.264 135.331C165.264 135.472 165.208 135.607 165.109 135.706C165.009 135.806 164.874 135.861 164.734 135.861Z"
        fill="#BCBCBC"
      />
      <path
        d="M134.507 127.38H117.938C117.798 127.38 117.663 127.324 117.563 127.225C117.464 127.125 117.408 126.99 117.408 126.85C117.408 126.709 117.464 126.574 117.563 126.475C117.663 126.376 117.798 126.32 117.938 126.32H134.507C134.647 126.32 134.782 126.376 134.882 126.475C134.981 126.574 135.037 126.709 135.037 126.85C135.037 126.99 134.981 127.125 134.882 127.225C134.782 127.324 134.647 127.38 134.507 127.38Z"
        fill="#BCBCBC"
      />
      <path
        d="M134.507 135.861H117.938C117.798 135.861 117.663 135.806 117.563 135.706C117.464 135.607 117.408 135.472 117.408 135.331C117.408 135.191 117.464 135.056 117.563 134.956C117.663 134.857 117.798 134.801 117.938 134.801H134.507C134.647 134.801 134.782 134.857 134.882 134.956C134.981 135.056 135.037 135.191 135.037 135.331C135.037 135.472 134.981 135.607 134.882 135.706C134.782 135.806 134.647 135.861 134.507 135.861Z"
        fill="#BCBCBC"
      />
      <path
        d="M104.28 127.38H87.7114C87.5708 127.38 87.4359 127.324 87.3365 127.225C87.237 127.125 87.1812 126.99 87.1812 126.85C87.1812 126.709 87.237 126.574 87.3365 126.475C87.4359 126.376 87.5708 126.32 87.7114 126.32H104.28C104.421 126.32 104.556 126.376 104.655 126.475C104.754 126.574 104.81 126.709 104.81 126.85C104.81 126.99 104.754 127.125 104.655 127.225C104.556 127.324 104.421 127.38 104.28 127.38Z"
        fill="#BCBCBC"
      />
      <path
        d="M104.28 135.861H87.7114C87.5708 135.861 87.4359 135.806 87.3365 135.706C87.237 135.607 87.1812 135.472 87.1812 135.331C87.1812 135.191 87.237 135.056 87.3365 134.956C87.4359 134.857 87.5708 134.801 87.7114 134.801H104.28C104.421 134.801 104.556 134.857 104.655 134.956C104.754 135.056 104.81 135.191 104.81 135.331C104.81 135.472 104.754 135.607 104.655 135.706C104.556 135.806 104.421 135.861 104.28 135.861Z"
        fill="#BCBCBC"
      />
      <path
        d="M217.805 119.27L216.744 133.582L196.735 169.412C195.774 171.007 195.352 172.869 195.532 174.723C195.713 176.576 196.485 178.322 197.736 179.703L229.114 208.124C229.114 208.124 235.283 208.326 234.763 211.241C234.244 214.155 237.541 214.017 237.541 214.017L241.251 216.697L243.259 201.433C236.513 186.376 226.508 181.782 217.805 174.836C217.805 174.836 221.475 173.745 220.7 172.482C219.926 171.218 222.358 171.134 222.358 171.134C222.358 171.134 223.972 170.917 224.07 169.742C224.168 168.568 225.733 168.39 225.733 168.39L234.512 159.556L257.577 158.496C257.577 158.496 267.337 134.054 251.213 118.209L217.805 119.27Z"
        fill="#262626"
      />
      <path
        d="M234.564 152.919L242.198 243.84L253.443 243.16L257.577 158.496L234.564 152.919Z"
        fill="#262626"
      />
      <path
        d="M235.112 44.472L247.984 43.8092L253.963 53.2527C253.963 53.2527 265.212 55.1326 264.432 68.3831C263.652 81.6337 250.745 93.2611 250.745 93.2611L251.297 100.161L250.339 102.898L250.462 104.435L251.769 106.063L251.903 107.746L250.745 110.409L251.213 113.969C251.213 113.969 239.567 116.28 233.459 111.679C227.35 107.078 218.335 109.728 218.335 109.728L217.996 105.342L219.762 102.975L218.776 99.8863L220.259 96.9159L221.032 87.4803C221.032 87.4803 212.57 74.7725 222.284 65.2012L226.183 58.5759L230.545 53.2557L235.112 44.472Z"
        fill="#5E8376"
      />
      <path
        d="M266.676 101.938L265.729 68.6941C265.684 67.2858 265.088 65.9514 264.068 64.9788C263.048 64.0062 261.686 63.4734 260.277 63.4954C259.548 63.5068 258.829 63.6665 258.163 63.9648C257.498 64.2631 256.901 64.6937 256.407 65.2304C255.914 65.7671 255.535 66.3986 255.293 67.0865C255.052 67.7743 254.953 68.5041 255.004 69.2313L257.047 103.734L257.404 124.751C256.524 125.14 255.785 125.791 255.288 126.614C254.791 127.438 254.56 128.395 254.625 129.354C254.691 130.314 255.051 131.23 255.656 131.978C256.26 132.727 257.081 133.271 258.006 133.536C258.931 133.802 259.915 133.777 260.825 133.464C261.735 133.151 262.526 132.566 263.092 131.788C263.658 131.01 263.97 130.076 263.986 129.115C264.003 128.153 263.723 127.209 263.184 126.412L266.676 101.938Z"
        fill="#FAC9AD"
      />
      <path
        d="M268.978 75.3813C269.221 75.1343 269.376 74.8143 269.42 74.4704C269.464 74.1266 269.393 73.7779 269.22 73.4778C269.012 73.1036 268.927 72.674 268.976 72.2491C268.986 72.167 269.002 72.0857 269.023 72.0057C269.161 71.5267 269.182 71.0215 269.083 70.5328C268.985 70.0441 268.769 69.5865 268.456 69.1988C267.405 67.9378 266.898 66.0288 266.679 64.3269C266.439 62.4647 266.296 60.5615 265.617 58.8109C264.637 56.2856 262.296 54.035 259.1 55.1121C257.749 55.5913 256.603 56.5217 255.857 57.7465C254.264 60.2865 254.247 63.3576 254.258 66.2958L253.685 65.1348C253.754 68.3438 253.174 71.5336 251.979 74.5128C252.691 74.3747 253.195 75.3312 252.965 76.019C252.734 76.7067 252.098 77.1587 251.553 77.6371C250.866 78.2402 250.217 79.1359 250.862 80.0574C251.022 80.2734 251.226 80.454 251.459 80.5878C251.693 80.7215 251.952 80.8055 252.219 80.8345C257.265 81.4902 262.391 81.0958 267.277 79.676C268.362 79.36 269.525 78.9213 270.09 77.9428C270.654 76.9644 270.107 75.3656 268.978 75.3813Z"
        fill="#5E8376"
      />
      <path
        d="M242.198 41.8769C248.934 41.8769 254.395 36.4184 254.395 29.6849C254.395 22.9514 248.934 17.4929 242.198 17.4929C235.462 17.4929 230.002 22.9514 230.002 29.6849C230.002 36.4184 235.462 41.8769 242.198 41.8769Z"
        fill="#FAC9AD"
      />
      <path
        d="M115.771 28.4327C123.626 28.4327 129.993 22.0678 129.993 14.2164C129.993 6.36488 123.626 0 115.771 0C107.917 0 101.549 6.36488 101.549 14.2164C101.549 22.0678 107.917 28.4327 115.771 28.4327Z"
        fill="#5E8376"
      />
      <path
        d="M115.271 19.9191C115.08 19.9191 114.892 19.8747 114.721 19.7893C114.55 19.704 114.402 19.5801 114.287 19.4274L111.272 15.4083C111.175 15.2792 111.104 15.1322 111.064 14.9757C111.023 14.8193 111.014 14.6564 111.037 14.4964C111.06 14.3364 111.114 14.1825 111.196 14.0434C111.278 13.9043 111.387 13.7828 111.517 13.6859C111.646 13.5889 111.793 13.5184 111.95 13.4784C112.106 13.4384 112.269 13.4296 112.429 13.4526C112.589 13.4756 112.743 13.5299 112.882 13.6125C113.021 13.695 113.142 13.8041 113.239 13.9336L115.212 16.5628L120.279 8.96542C120.46 8.69488 120.741 8.50725 121.061 8.4437C121.38 8.38016 121.712 8.4459 121.983 8.62649C122.254 8.80707 122.442 9.08776 122.506 9.40694C122.57 9.72611 122.505 10.0577 122.325 10.3289L116.294 19.3718C116.185 19.5353 116.038 19.6704 115.866 19.7655C115.694 19.8607 115.502 19.9132 115.305 19.9187L115.271 19.9191Z"
        fill="white"
      />
      <path
        d="M143.005 54.824H82.0776C80.5934 54.824 79.1699 54.2346 78.1204 53.1855C77.0709 52.1364 76.4813 50.7135 76.4813 49.2299C76.4813 47.7462 77.0709 46.3233 78.1204 45.2742C79.1699 44.2251 80.5934 43.6357 82.0776 43.6357H143.005C143.74 43.635 144.469 43.7792 145.148 44.06C145.828 44.3408 146.446 44.7527 146.966 45.2723C147.486 45.7918 147.899 46.4087 148.18 47.0878C148.462 47.7669 148.607 48.4948 148.607 49.2299C148.607 49.9649 148.462 50.6928 148.18 51.3719C147.899 52.051 147.486 52.6679 146.966 53.1874C146.446 53.707 145.828 54.1189 145.148 54.3997C144.469 54.6805 143.74 54.8247 143.005 54.824Z"
        fill="#5E8376"
      />
      <path
        d="M327.372 269.481L191.957 269.644C191.79 269.643 191.631 269.576 191.513 269.458C191.395 269.34 191.329 269.18 191.329 269.013C191.329 268.846 191.395 268.686 191.513 268.568C191.631 268.45 191.79 268.383 191.957 268.382L327.372 268.219C327.539 268.22 327.699 268.287 327.816 268.405C327.934 268.523 328 268.683 328 268.85C328 269.017 327.934 269.177 327.816 269.295C327.699 269.414 327.539 269.48 327.372 269.481Z"
        fill="#D2D2D2"
      />
      <path
        d="M257.577 26.5044V29.1548H247.024C247.131 28.6431 247.131 28.1149 247.025 27.6031C246.918 27.0914 246.706 26.6072 246.404 26.181C246.395 27.2183 246.137 28.2383 245.651 29.1548C239.549 30.9585 234.427 30.9239 230.23 29.1548C229.607 29.1548 229.009 28.9073 228.569 28.4669C228.128 28.0264 227.88 27.429 227.88 26.8061C227.88 18.6799 234.288 11.8326 242.416 11.6651C244.392 11.6233 246.356 11.9762 248.194 12.7032C250.031 13.4302 251.705 14.5166 253.117 15.8987C254.529 17.2808 255.651 18.9308 256.417 20.752C257.183 22.5732 257.577 24.5289 257.577 26.5044Z"
        fill="#262626"
      />
      <path
        d="M117.579 72.0919H96.3674C95.8048 72.0919 95.2653 71.8685 94.8675 71.4708C94.4697 71.0732 94.2462 70.5339 94.2462 69.9715C94.2462 69.4092 94.4697 68.8699 94.8675 68.4722C95.2653 68.0746 95.8048 67.8512 96.3674 67.8512H117.579C118.142 67.8512 118.681 68.0746 119.079 68.4722C119.477 68.8699 119.7 69.4092 119.7 69.9715C119.7 70.5339 119.477 71.0732 119.079 71.4708C118.681 71.8685 118.142 72.0919 117.579 72.0919Z"
        fill="#BCBCBC"
      />
      <path
        d="M122.882 117.308H101.67C101.108 117.308 100.568 117.085 100.17 116.687C99.7727 116.29 99.5492 115.75 99.5492 115.188C99.5492 114.626 99.7727 114.086 100.17 113.689C100.568 113.291 101.108 113.068 101.67 113.068H122.882C123.445 113.068 123.984 113.291 124.382 113.689C124.78 114.086 125.003 114.626 125.003 115.188C125.003 115.75 124.78 116.29 124.382 116.687C123.984 117.085 123.445 117.308 122.882 117.308Z"
        fill="#BCBCBC"
      />
      <path
        d="M163.185 83.7538H95.8371C95.2745 83.7538 94.735 83.5304 94.3372 83.1328C93.9394 82.7351 93.7159 82.1958 93.7159 81.6334C93.7159 81.0711 93.9394 80.5318 94.3372 80.1341C94.735 79.7365 95.2745 79.5131 95.8371 79.5131H163.185C163.747 79.5131 164.287 79.7365 164.684 80.1341C165.082 80.5318 165.306 81.0711 165.306 81.6334C165.306 82.1958 165.082 82.7351 164.684 83.1328C164.287 83.5304 163.747 83.7538 163.185 83.7538Z"
        fill="#BCBCBC"
      />
      <path
        d="M71.1763 90.0189C79.0308 90.0189 85.3982 83.654 85.3982 75.8025C85.3982 67.951 79.0308 61.5861 71.1763 61.5861C63.3217 61.5861 56.9543 67.951 56.9543 75.8025C56.9543 83.654 63.3217 90.0189 71.1763 90.0189Z"
        fill="#D2D2D2"
      />
    </g>
    <defs>
      <clipPath id="clip0_25438_117">
        <rect width="328" height="269.644" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EditProfileImage;
