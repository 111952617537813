import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const AddProofpointIcon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 22.5L6 22.5C4.34315 22.5 3 21.1569 3 19.5V5.5C3 3.84314 4.34315 2.5 6 2.5H12H15.5H18C19.6568 2.5 21 3.84314 21 5.49999L21 8.5V12.5V19.5C21 21.1569 19.6569 22.5 18 22.5ZM18 20.5C18.5523 20.5 19 20.0523 19 19.5V12.5V8.5L19 5.5C19 4.94771 18.5523 4.5 18 4.5L15.5 4.5L12 4.5L6 4.5C5.44771 4.5 5 4.94772 5 5.5L5 19.5C5 20.0523 5.44772 20.5 6 20.5L18 20.5ZM8.50001 13C8.50001 12.4477 8.94772 12 9.50001 12H11V10.5C11 9.94772 11.4477 9.5 12 9.5C12.5523 9.5 13 9.94772 13 10.5V12H14.5C15.0523 12 15.5 12.4477 15.5 13C15.5 13.5523 15.0523 14 14.5 14H13V15.5C13 16.0523 12.5523 16.5 12 16.5C11.4477 16.5 11 16.0523 11 15.5V14H9.50001C8.94772 14 8.50001 13.5523 8.50001 13Z"
      fill={color}
    />
  </svg>
);

export default AddProofpointIcon;
