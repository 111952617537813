import React, { useState, useEffect, FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Stack, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NoMobileContentPage } from '@components';

interface IMobileWarning {
  MobileWarningComponent: React.FC;
}

export const useMobileWarning = (): IMobileWarning => {
  const [mobileWarning, setMobileWarning] = useState<boolean>(false);

  useEffect(() => {
    if (isMobileOnly) {
      const mobileWarningFromLocalStore = localStorage.getItem('mobileWarning');
      if (mobileWarningFromLocalStore) {
        setMobileWarning(JSON.parse(mobileWarningFromLocalStore));
      } else {
        setMobileWarning(true);
      }
    }
  }, []);

  if (!mobileWarning) return { MobileWarningComponent: null };

  const MobileWarningComponent: FC = () => (
    <Stack sx={{ height: '100vh' }}>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <CloseIcon
          fontSize="large"
          sx={{ cursor: 'pointer', mx: 2, my: 2, zIndex: 100 }}
          onClick={() => {
            localStorage.setItem('mobileWarning', JSON.stringify(false));
            setMobileWarning(false);
          }}
        />
      </Box>
      <NoMobileContentPage />
    </Stack>
  );

  return { MobileWarningComponent };
};
