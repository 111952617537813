import React from 'react';
import { Button, Stack, IconButton, Typography } from '@mui/material';

import * as Controller from './controller';
import {
  BorrowerInformation,
  ButtonWithTooltip,
  ProjectNameLabel,
  ConfirmationModal,
} from '@components';
import { ArrowBackIcon } from '@svgAsComponents';
import { PopupTypeEnum } from '@interfaces';
import { colors } from '@theme';

const AddBorrowerCompany = () => {
  const {
    borrowerDetailsFields,
    borrowerUser,
    isNewBorrower,
    setIsNewBorrower,
    setBorrowerUser,
    handleAddBorrowerCompany,
    goBack,
    project,
    getLeavePageConfirmModal,
    isUpdated,
    loading,
    error,
    setError,
    inviteBorrowers,
    setInviteBorrowers,
  } = Controller.useAddBorrowerCompany();

  return (
    <Stack direction="column" sx={{ height: '100%' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pr: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ px: { md: 3, xs: 2 }, height: 84 }}
        >
          <IconButton onClick={goBack} data-cy="add_borrower_page__back__icon">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h1">Add borrower details</Typography>
        </Stack>
        <ProjectNameLabel project={project} showStatusChip />
      </Stack>

      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: 3 }} spacing={2}>
        <BorrowerInformation
          borrowerDetailsFields={borrowerDetailsFields}
          borrowerUser={borrowerUser}
          setBorrowerUser={setBorrowerUser}
          isNewBorrower={isNewBorrower}
          setIsNewBorrower={setIsNewBorrower}
          showTitle={false}
          inviteBorrowers={inviteBorrowers}
          setInviteBorrowers={setInviteBorrowers}
        />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ p: 2 }}>
        <Button variant="text" onClick={goBack} data-cy="add_borrower_page__cancel__button">
          Cancel
        </Button>
        <ButtonWithTooltip
          sx={{ ml: '0.5rem' }}
          onClick={handleAddBorrowerCompany}
          dataTestName="add_borrower_page__add__button"
          disabled={!isUpdated}
          loading={loading}
          tooltipText="No changes to save"
        >
          Add
        </ButtonWithTooltip>
      </Stack>
      {getLeavePageConfirmModal()}
      <ConfirmationModal
        title="Add borrower company error"
        text={`There was a problem adding borrower to the project. ${error}`}
        hideConfirmButton
        open={Boolean(error)}
        onClose={() => setError(null)}
        type={PopupTypeEnum.ERROR}
      />
    </Stack>
  );
};

export default AddBorrowerCompany;
