import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Typography, Button } from '@mui/material';

import { UpdateIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { useLaunchDarklyFlags } from '@context';

const Wrapper = styled(Stack)(() => ({
  backgroundColor: colors.background.lightest,
  minHeight: 64,
  width: '100%',
}));

const NewAppVersionMessage = () => {
  const flags = useLaunchDarklyFlags();

  if (flags?.['ENG_6686_maintenance_banner']) return null;

  const handleUpdate = () => {
    window.location.reload();
  };

  return (
    <Wrapper
      id="updatePrompt"
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{ px: 1 }}
    >
      <UpdateIcon />
      <Typography variant="body2" sx={{ color: colors.text.heading }}>
        A new version of software is available!
      </Typography>
      <Button variant="text" color="secondary" onClick={handleUpdate}>
        Update now
      </Button>
    </Wrapper>
  );
};

export default React.memo(NewAppVersionMessage);
