import { useLocation, useNavigate } from 'react-router-dom';

interface CustomizedState {
  from: string;
}

export const useNavigateToOriginalUrl = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state as CustomizedState;
  const originalUrl = JSON.parse(sessionStorage.getItem('originalUrl') || 'null');

  const deepLink = originalUrl || state?.from || '/';

  const handleRedirect = () => navigate(deepLink);

  return { handleRedirect };
};
