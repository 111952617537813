import { SettingsContext, useGetData } from '@context';
import { QueryNamesEnums } from '@interfaces';
import { useParams } from 'react-router';
import { useContext, useMemo } from 'react';
import { isLineItemViewByURL, usePHBGrouping } from '@utils';
import { useLocation } from 'react-router-dom';

export const useInfoCredit = ({ requestId, milestoneId }) => {
  const { projectId } = useParams();
  const { isPHBProject } = useContext(SettingsContext);
  const { unitLineItemGrouping, lineItemUnitGrouping } = usePHBGrouping();
  const { settings } = useContext(SettingsContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const isLineItemView = useMemo(
    () => isLineItemViewByURL({ params, settings }),
    [params.get('view'), settings.personal_setting?.PHB_TABLE_VIEW?.view_type],
  );
  const group_by = useMemo(
    () => (isLineItemView ? lineItemUnitGrouping : unitLineItemGrouping),
    [isLineItemView, lineItemUnitGrouping, unitLineItemGrouping],
  );

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name'],
    args: { projectId },
  });

  const milestone = useGetData({
    type: isPHBProject
      ? QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP
      : QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: ['requested_credit_amount', 'approved_credit_amount', 'credit_reason'],
    args: { projectId, drawRequestId: requestId, milestoneId, group_by },
  });

  return {
    name: project.data.name,
    milestone,
  };
};
