import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 21C21.1046 21 22 20.1046 22 19V9C22 8.44409 21.7732 7.94115 21.4071 7.57867C21.3858 7.53305 21.3608 7.48847 21.3321 7.4453L19.6289 4.8906C19.258 4.3342 18.6335 4 17.9648 4H11.2335L11.196 3.94C10.8305 3.35524 10.1896 3 9.5 3H4C2.89543 3 2 3.89543 2 5L2 13.5L2 19C2 19.5304 2.21072 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20ZM12.4835 6L13.1085 7L18.6315 7L17.9648 6H12.4835ZM20 19H18L6 19H4L4 17L4 13.5L4 7L4 5L6 5L9.5 5L11.7063 8.53C11.889 8.82238 12.2095 9 12.5542 9L18 9H20V11V17V19ZM7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H10C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15H7ZM12 16C12 15.4477 12.4477 15 13 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H13C12.4477 17 12 16.5523 12 16Z"
      fill={color}
    />
  </svg>
);

export default Icon;
