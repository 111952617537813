import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z"
      fill={color}
    />
  </svg>
);

export default Icon;
