import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import * as Controller from './controller';

import { HookState } from '@interfaces';
import { CustomAutocomplete, LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import { AnalyticsImage, ArrowBackIcon } from '@svgAsComponents';
import { colors } from '@theme';

const DashboardDetails = () => {
  const { dashboardId } = useParams();
  const controller = Controller.useSupersetDashboard({ dashboardId });

  const { state, dashboardsList, selectedDashboard } = controller;

  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return (
        <StyledBox>
          <ServiceMessage text="analytics dashboard" />
        </StyledBox>
      );
    }

    case HookState.EMPTY: {
      return (
        <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
          <AnalyticsImage />
          <Typography sx={{ mt: 8, textAlign: 'center' }} variant="h2">
            No analytics
          </Typography>
          <Typography sx={{ textAlign: 'center', color: colors.text.heading }} variant="body1">
            Please, ask your account manager for help.
          </Typography>
        </Stack>
      );
    }

    case HookState.SUCCESS: {
      return (
        <Stack
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
            sx={{ m: 2 }}
          >
            <Link to="/analytics" style={{ textDecoration: 'none', cursor: 'pointer' }}>
              <Stack direction="row" alignItems="center">
                <IconButton data-cy="analytics_center__dashboard_details__back__icon">
                  <ArrowBackIcon />
                </IconButton>
                <Typography sx={{ ml: 2 }} variant="body2">
                  Back to analytics center
                </Typography>
              </Stack>
            </Link>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
              {dashboardsList?.length > 1 && (
                <Box sx={{ width: '500px' }}>
                  <CustomAutocomplete
                    field={selectedDashboard}
                    label="Dashboard"
                    options={dashboardsList}
                  />
                </Box>
              )}
            </Stack>
          </Stack>

          <Box
            id="superset-container"
            sx={{
              flex: 1,
              height: '100%',
              width: '100%',
              pb: 2,
              '& iframe': {
                height: '100%',
                width: '100%',
                border: 'none!important',
                minHeight: 'calc(100vh - 200px)',
              },
            }}
          />
        </Stack>
      );
    }

    default: {
      return null;
    }
  }
};

export default DashboardDetails;
