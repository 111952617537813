import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1164 19C10.1164 20.1046 11.0118 21 12.1164 21C13.221 21 14.1164 20.1046 14.1164 19C14.1164 17.8954 13.221 17 12.1164 17C11.0118 17 10.1164 17.8954 10.1164 19ZM10.1164 12C10.1164 13.1046 11.0118 14 12.1164 14C13.221 14 14.1164 13.1046 14.1164 12C14.1164 10.8954 13.221 10 12.1164 10C11.0118 10 10.1164 10.8954 10.1164 12ZM12.1164 7C11.0118 7 10.1164 6.10457 10.1164 5C10.1164 3.89543 11.0118 3 12.1164 3C13.221 3 14.1164 3.89543 14.1164 5C14.1164 6.10457 13.221 7 12.1164 7Z"
      fill={color}
    />
  </svg>
);

export default Icon;
