import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 18 }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 18) * 24}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14969 0H11.1767L17.9759 7.09344V20.8744C17.9759 22.5996 16.5755 24 14.8503 24H3.14969C1.42453 24 0.0241089 22.5996 0.0241089 20.8744V3.12558C0.0241089 1.40042 1.42453 0 3.14969 0V0Z"
      fill="#1F4739"
    />
    <path
      opacity="0.302"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1666 0V7.03255H17.9759L11.1666 0Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00003 12.75C5.00003 12.3358 5.3582 12 5.80003 12H12.2C12.6418 12 13 12.3358 13 12.75V17.25C13 17.6642 12.6418 18 12.2 18H5.80003C5.3582 18 5.00003 17.6642 5.00003 17.25V16.3175C4.99999 16.3144 4.99999 16.3113 5.00003 16.3081V12.75ZM5.80003 16.5055V17.25H6.60002H11.4H12.2V16.5V16.2624C12.1638 16.2428 12.1303 16.2175 12.101 16.1866L10.7584 14.7706L8.65609 16.4131C8.53401 16.5085 8.36327 16.527 8.22113 16.4604L6.83655 15.8114L5.80003 16.5055ZM12.2 15.1621L11.099 14.0009C10.9555 13.8496 10.7098 13.8323 10.5439 13.9619L8.34492 15.6799L6.97891 15.0396C6.84639 14.9775 6.6881 14.9891 6.56753 15.0698L5.80003 15.5838V13.5V12.75H6.60002H11.4H12.2V13.5V15.1621ZM7.60002 14.0625C7.60002 13.7518 7.86865 13.5 8.20002 13.5C8.53139 13.5 8.80002 13.7518 8.80002 14.0625C8.80002 14.3732 8.53139 14.625 8.20002 14.625C7.86865 14.625 7.60002 14.3732 7.60002 14.0625Z"
      fill="white"
    />
  </svg>
);

export default Icon;
