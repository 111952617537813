import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const CompanyAddressIcon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21V4H13V12V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V13H5V21C5 21.5523 4.55228 22 4 22C3.44771 22 3 21.5523 3 21V12C3 11.4477 3.44771 11 4 11H11V3C11 2.44772 11.4477 2 12 2H20ZM16.5 6C17.0523 6 17.5 6.44772 17.5 7V8C17.5 8.55228 17.0523 9 16.5 9H15.5C14.9477 9 14.5 8.55228 14.5 8V7C14.5 6.44772 14.9477 6 15.5 6H16.5ZM17.5 12C17.5 11.4477 17.0523 11 16.5 11H15.5C14.9477 11 14.5 11.4477 14.5 12V13C14.5 13.5523 14.9477 14 15.5 14H16.5C17.0523 14 17.5 13.5523 17.5 13V12ZM16.5 16C17.0523 16 17.5 16.4477 17.5 17V18C17.5 18.5523 17.0523 19 16.5 19H15.5C14.9477 19 14.5 18.5523 14.5 18V17C14.5 16.4477 14.9477 16 15.5 16H16.5ZM8.5 15C9.05228 15 9.5 15.4477 9.5 16V17C9.5 17.5523 9.05228 18 8.5 18H7.5C6.94772 18 6.5 17.5523 6.5 17V16C6.5 15.4477 6.94772 15 7.5 15H8.5Z"
      fill={color}
    />
  </svg>
);

export default CompanyAddressIcon;
