import { useEffect, useState, useContext, useCallback, useRef } from 'react';

import { useUpdateUiSettings } from '@hooks';
import { SettingsContext } from '@context';
import { hints } from '@constants';
import introJs from 'intro.js';

export const useHints = ({
  key,
  customOptions = {},
}: {
  key: string;
  customOptions?: {
    guid?: any;
  };
}) => {
  const ref = useRef(null);
  const { updateSettings } = useUpdateUiSettings();
  const { settings } = useContext(SettingsContext);

  const [enabled, setHintsEnabled] = useState(false); // is used to enable/disable hints
  const [_isHintShown, setIsHintShown] = useState(false); // is used to send info to backend

  const onClick = useCallback(() => {
    setIsHintShown((currentShownState) => {
      if (!currentShownState) {
        updateSettings({
          personal_setting: {
            shownHints: [...(settings.personal_setting?.shownHints || []), key],
          },
        });
        return true;
      }
      return currentShownState;
    });
  }, [key]);

  const onClose = useCallback(() => {
    setHintsEnabled(false);
  }, [key]);

  useEffect(() => {
    if (enabled && customOptions.guid && !window['Cypress']) {
      introJs()
        .oncomplete(() => onClick())
        .setOptions(customOptions.guid)
        .start();
    }
  }, [enabled, customOptions.guid, onClose]);

  useEffect(() => {
    const value = settings.personal_setting?.shownHints?.includes(key);
    setIsHintShown(value);
    setHintsEnabled(!value);
  }, [settings.personal_setting?.shownHints, key]);

  const options = {
    overlayOpacity: 0.5,
    tooltipClass: 'customTooltip',
    ...customOptions,
  };
  return { enabled, options, ref, onClose, hints: hints[key], setHintsEnabled, onClick };
};
