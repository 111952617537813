import React from 'react';
import { ProgressionSum, MilestoneListColumnTypeV2, isFooterRow } from './common';
import { currencyFormatter } from '@utils';
import { Stack } from '@mui/material';

const revisedEstimateInDraft: MilestoneListColumnTypeV2 = {
  field: 'revised_estimate',
  renderCell: ({ row }) => {
    const { requested_adjustments, revised_estimate, requested_budget_change } = row;
    const progress = isFooterRow(row) ? requested_budget_change : requested_adjustments;
    const sum = revised_estimate;

    return (
      <Stack lineHeight="14px" alignItems="flex-end">
        {currencyFormatter(sum, '')}
        <ProgressionSum sum={sum} progress={progress} />
      </Stack>
    );
  },
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Revised scheduled value ($)',
  description: 'Revised construction budget reflecting approved changes',
  editable: false,
  minWidth: 120,
};

export default revisedEstimateInDraft;
