import React, { useContext, useMemo } from 'react';

import { IProjectComment } from '@interfaces';
import { AuthContext } from '@context';
import { EditIcon } from '@svgAsComponents';

interface ControllerInterface {
  items: {
    text: string;
    action: () => void;
    icon: JSX.Element;
    color?: string;
  }[];
}

export const useCommentHeader = ({
  comment,
  projectId,
  handleEditCommentClick,
}: {
  comment: IProjectComment;
  projectId?: string;
  handleEditCommentClick: (comment: IProjectComment) => () => void;
}): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const items = useMemo(() => {
    if (
      user?.id === comment?.created_by?.id &&
      !comment?.is_external_comment &&
      !comment?.has_children &&
      handleEditCommentClick
    ) {
      return [
        {
          text: 'Edit',
          action: handleEditCommentClick(comment),
          icon: <EditIcon size={24} />,
        },
      ];
    }
    return [];
  }, [comment, projectId, user?.id]);

  return {
    items,
  };
};
