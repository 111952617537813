import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 21) * 16}
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M2.11639 0C1.01182 0 0.116394 0.895431 0.116394 2C0.116394 3.10457 1.01182 4 2.11639 4C3.22096 4 4.11639 3.10457 4.11639 2C4.11639 0.895431 3.22096 0 2.11639 0ZM7.11639 1C6.56411 1 6.11639 1.44772 6.11639 2C6.11639 2.55228 6.56411 3 7.11639 3H19.1164C19.6687 3 20.1164 2.55228 20.1164 2C20.1164 1.44772 19.6687 1 19.1164 1H7.11639ZM6.11639 8C6.11639 7.44771 6.56411 7 7.11639 7H19.1164C19.6687 7 20.1164 7.44771 20.1164 8C20.1164 8.55229 19.6687 9 19.1164 9H7.11639C6.56411 9 6.11639 8.55229 6.11639 8ZM2.11639 6C1.01182 6 0.116394 6.89543 0.116394 8C0.116394 9.10457 1.01182 10 2.11639 10C3.22096 10 4.11639 9.10457 4.11639 8C4.11639 6.89543 3.22096 6 2.11639 6ZM6.11639 14C6.11639 13.4477 6.56411 13 7.11639 13H19.1164C19.6687 13 20.1164 13.4477 20.1164 14C20.1164 14.5523 19.6687 15 19.1164 15H7.11639C6.56411 15 6.11639 14.5523 6.11639 14ZM2.11639 12C1.01182 12 0.116394 12.8954 0.116394 14C0.116394 15.1046 1.01182 16 2.11639 16C3.22096 16 4.11639 15.1046 4.11639 14C4.11639 12.8954 3.22096 12 2.11639 12Z"
    />
  </svg>
);

export default Icon;
