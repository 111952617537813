import { useQuery } from 'react-query';
import { useEffect, useContext } from 'react';
import find from 'lodash/find';

import { getHookState } from '@utils';
import { QueryNamesEnums, HookState, IProject } from '@interfaces';
import { getProject } from '@globalService';
import { SettingsContext } from '@context';

const requiredKeys = [
  'estimated_start_date',
  'start_date',
  'original_completion_date',
  'estimated_completion_date',
  'loan',
  'retainage_rate',
] as const;
type ProjectData = Pick<IProject, (typeof requiredKeys)[number]>;

export interface ControllerInterface {
  state: HookState;
  project: ProjectData;
  servicingStatus: string;
  loanType: string;
}

export const useProject = (projectId: string): ControllerInterface => {
  const { settings } = useContext(SettingsContext);
  const { loan_servicing_statuses, loan_types } = settings?.display || {};

  const projectQuery = useQuery<ProjectData, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  // after importing budget and invalidating cache, loan details isn't updated immediately,
  // so we need to refetch project on component mount to have updated data in loan details on url change
  useEffect(() => {
    projectQuery.refetch();
  }, []);

  return {
    project: projectQuery.data,
    state: getHookState(projectQuery),
    servicingStatus: find(loan_servicing_statuses, {
      name: projectQuery.data.loan?.servicing_status,
    })?.name_display,
    loanType: find(loan_types, {
      name: projectQuery.data.loan?.type,
    })?.name_display,
  };
};
