import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const AddDocumentIcon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 22L18 22C19.6569 22 21 20.6569 21 19V12L21 8.37939C21 7.64611 20.7314 6.93822 20.245 6.38947L18.5969 4.53001L17.3143 3.04161C16.7444 2.38028 15.9147 2 15.0417 2H12H6C4.34315 2 3 3.34314 3 5V19C3 20.6569 4.34315 22 6 22ZM16 4.58022L17.0909 5.84616L18.1136 7H16V4.58022ZM14 4V8C14 8.55229 14.4477 9 15 9H19V12L19 19C19 19.5523 18.5523 20 18 20L6 20C5.44772 20 5 19.5523 5 19L5 5C5 4.44772 5.44772 4 6 4L12 4L14 4ZM8.5 13.5C8.5 12.9477 8.94771 12.5 9.5 12.5H11V11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11V12.5H14.5C15.0523 12.5 15.5 12.9477 15.5 13.5C15.5 14.0523 15.0523 14.5 14.5 14.5H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V14.5H9.5C8.94771 14.5 8.5 14.0523 8.5 13.5Z"
      fill={color}
    />
  </svg>
);

export default AddDocumentIcon;
