import React, { memo, FC } from 'react';
import { useParams } from 'react-router-dom';
import { CommonRowType, MilestoneListColumnTypeV2 } from './common';
import { StyledLink } from '@components';
import { Stack } from '@mui/material';

const LineItemName: FC<{ row: CommonRowType }> = ({ row }) => {
  const { projectId } = useParams();
  return (
    <Stack justifyContent="flex-end" flexDirection="row" flexWrap="wrap">
      {Array.isArray(row.draw_requests) &&
        row.draw_requests.map((dr, index) => (
          <span key={index}>
            <StyledLink
              variant="body3SemiBold"
              to={`/projects/${projectId}/draws/draw-requests/${dr.id}`}
            >
              {dr.number}
            </StyledLink>
            {index !== row.draw_requests.length - 1 && <span>, </span>}
          </span>
        ))}
    </Stack>
  );
};

const Memoized = memo(LineItemName);

const drawRequestNumbers: MilestoneListColumnTypeV2 = {
  field: 'draw_requests',
  headerAlign: 'left',
  headerClassName: 'text',
  cellClassName: 'text',
  headerName: 'Draw request #',
  description: 'Draw requests associated with the line item',
  editable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  minWidth: 120,
};

export default drawRequestNumbers;
