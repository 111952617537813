import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M17.6565 6.34233C16.4849 5.17075 14.5854 5.17075 13.4138 6.34233L11.9996 7.75654C11.6091 8.14706 10.9759 8.14706 10.5854 7.75654C10.1949 7.36601 10.1949 6.73285 10.5854 6.34233L11.9996 4.92811C13.9522 2.97549 17.1181 2.97549 19.0707 4.92811C21.0233 6.88073 21.0233 10.0466 19.0707 11.9992L17.6565 13.4134C17.266 13.8039 16.6328 13.8039 16.2423 13.4134C15.8517 13.0229 15.8517 12.3897 16.2423 11.9992L17.6565 10.585C18.828 9.41339 18.828 7.5139 17.6565 6.34233ZM6.34277 17.656C5.1712 16.4845 5.1712 14.585 6.34277 13.4134L7.75698 11.9992C8.14751 11.6087 8.14751 10.9755 7.75698 10.585C7.36646 10.1944 6.73329 10.1944 6.34277 10.585L4.92855 11.9992C2.97593 13.9518 2.97593 17.1176 4.92855 19.0702C6.88118 21.0229 10.047 21.0229 11.9996 19.0702L13.4138 17.656C13.8044 17.2655 13.8044 16.6323 13.4138 16.2418C13.0233 15.8513 12.3901 15.8513 11.9996 16.2418L10.5854 17.656C9.41384 18.8276 7.51434 18.8276 6.34277 17.656ZM15.5352 9.87786C15.9257 9.48734 15.9257 8.85417 15.5352 8.46365C15.1446 8.07312 14.5115 8.07312 14.1209 8.46365L8.46409 14.1205C8.07356 14.511 8.07356 15.1442 8.46409 15.5347C8.85461 15.9252 9.48778 15.9252 9.8783 15.5347L15.5352 9.87786Z"
    />
  </svg>
);

export default Icon;
