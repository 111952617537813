import React, { FC } from 'react';
import { PieChart } from '@mui/x-charts';
import { IPieChartData } from '@interfaces';
import { CardinalDirections } from '@mui/x-charts/models/layout';
import { typography } from '@theme';
import { AnchorPosition, Direction } from '@mui/x-charts/ChartsLegend/legend.types';
import { LegendPerItemProps } from '@mui/x-charts/ChartsLegend/LegendPerItem';

const PieChartItem: FC<{
  data: IPieChartData[];
  legend?: string;
  direction?: Direction;
  padding?: number | Partial<CardinalDirections<number>>;
  height?: number;
  legendPosition?: AnchorPosition;
  margin?: Partial<CardinalDirections<number>>;
  customLabels?: LegendPerItemProps['itemsToDisplay'];
  showTooltip?: boolean;
}> = ({
  data,
  legend,
  padding = 16,
  height = 120,
  legendPosition = {
    vertical: 'middle',
    horizontal: 'left',
  },
  margin,
  direction = 'column',
  customLabels,
  showTooltip = false,
}) => (
  <PieChart
    margin={margin}
    height={height}
    series={[
      {
        data,
        cx: '100%',
        ...(showTooltip ? { valueFormatter: () => '' } : {}),
      },
    ]}
    slotProps={{
      legend: {
        position: legendPosition,
        padding,
        ...(legend ? { labelStyle: { ...typography[legend] } } : {}),
        itemGap: 8,
        markGap: 4,
        direction,
        ...(customLabels ? { seriesToDisplay: customLabels } : {}),
      },
    }}
    tooltip={{
      trigger: showTooltip ? 'item' : 'none',
    }}
  />
);

export default PieChartItem;
