import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { isChangeRequest, isRequestDraftOrInReview } from '@utils';
import { excludeCommentsWithTotalsAllQueryFields } from '@constants';
import { IDocument, IDrawRequest, IProjectDocument, QueryNamesEnums } from '@interfaces';
import { useCommentsAndDocumentsPreview, useImagePicker, useRightMenu } from '@hooks';
import { getDrawRequest } from '@globalService';
import { ControllerInterface, IDocumentsWrapperProps } from '../../interface';
import { useDocumentTableColumns } from '../../useDocumentTableColumns';
import { useLaunchDarklyFlags } from '@context';

export const useDocumentsPanel = ({
  prId: projectId,
  requestId: drawRequestId,
  milestoneId,
  milestoneSubmitId,
  inspectionId,
  serviceOrderId,
  source,
  restoreDocument,
  documents,
}: IDocumentsWrapperProps & {
  documents: IProjectDocument[] | IDocument[];
}): ControllerInterface & { docSectionHeader: string } => {
  const flags = useLaunchDarklyFlags();
  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
    { enabled: Boolean(drawRequestId) },
  );

  const isMilestoneDocs = useMemo(
    () => Boolean(milestoneId) || Boolean(milestoneSubmitId),
    [milestoneId, milestoneSubmitId],
  );

  const { pdf, gallery, open, close } = useImagePicker();

  const [activeDocument, setActiveDocument] = useState<IProjectDocument | IDocument | null>(null);

  const [rightDrawerParams, setRightDrawerParams] = useState<{
    projectId: string;
    documentId: string;
  }>({
    documentId: '',
    projectId,
  });

  const [docSummaryParams, setDocSummaryParams] = useState<{
    projectId: string;
    documentId?: string;
  }>({
    projectId,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    ...(drawRequestId ? { drawRequestId } : {}),
    ...(inspectionId ? { inspectionId } : {}),
    ...(serviceOrderId ? { serviceOrderId } : {}),
    documentId: rightDrawerParams.documentId,
  });

  const updateRightDrawer = (documentId) => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
    setRightDrawerParams({ projectId, documentId });
  };

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const { handleRightDrawerOpenerClick: handleDocSummaryOpenerClick, ...docSummaryMenu } =
    useRightMenu({});

  const openComments = useCallback((row) => {
    updateRightDrawer(row?.original?.id);
  }, []);

  const openDocument = (file) => {
    if (!file) return close();
    open([file]);
  };

  const { name, icons, openAllDocsSummary } = useDocumentTableColumns({
    projectId,
    drawRequestId,
    source,
    open: openDocument,
    setDocSummaryParams,
    handleDocSummaryOpenerClick,
    isMilestoneDocs,
    openComments,
    restoreDocument,
    setActiveDocument,
  });

  const columns = useMemo(() => [name, icons()], [documents, icons]);

  const closeDocumentEditModal = () => {
    setActiveDocument(null);
  };

  const docSectionHeader = useMemo(() => {
    if (!drawRequestId && !inspectionId) return 'Project';

    if (inspectionId) return 'Inspection';

    return `${isChangeRequest(drawRequestQuery?.data) ? 'Change' : 'Draw'} #${drawRequestQuery?.data?.number || ''}`;
  }, [drawRequestId, drawRequestQuery?.data, milestoneId, inspectionId]);

  const isDocumentUploadAllowed = useMemo(
    () =>
      !flags?.['ENG_9182_lock_docs_upload_for_completed_requests'] ||
      isRequestDraftOrInReview(drawRequestQuery?.data?.status) ||
      !drawRequestId,
    [drawRequestQuery?.data, flags, drawRequestId],
  );

  return {
    columns,
    pdf,
    gallery,
    close,
    rightMenu,
    rightDrawerParams,
    docSummaryMenu,
    docSummaryParams,
    openAllDocsSummary,
    openPdfViewer: (file) => openDocument(file),
    activeDocument,
    closeDocumentEditModal,
    docSectionHeader,
    isDocumentUploadAllowed,
  };
};
