import { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { useSafeSnackbar } from '@hooks';
import isEmpty from 'lodash/isEmpty';
import {
  ItemStatusesEnum,
  PermissionNamesEnums,
  QueryNamesEnums,
  UpdateChecklistItemParam,
} from '@interfaces';
import { getDrawRequest, getDrawRequestItemChecklist, updateChecklistItem } from '@globalService';
import {
  getChecklistIdByRole,
  getCheckListItemsByRole,
  getHookState,
  getTeamRole,
  isRestricted,
} from '@utils';
import { AuthContext, PermissionsContext } from '@context';

export const useRequestChecklist = (projectId: string, requestId: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { permissions } = useContext(PermissionsContext);

  const requestedDataQueries = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId: requestId },
        { enabled: Boolean(requestId && projectId) },
      ],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId: requestId }),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
        { projectId, drawRequestId: requestId },
      ],
      queryFn: getDrawRequestItemChecklist.bind(this, projectId, requestId),
    },
  ]);

  const drawRequestData = requestedDataQueries[0].data;
  const DRChecklist = requestedDataQueries[1];

  const updateChecklistItemMutation = useMutation<Response, Error, UpdateChecklistItemParam>(
    updateChecklistItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const checklistItems = useMemo(() => {
    if (!isEmpty(DRChecklist.data) && drawRequestData?.waits_current_user_approval) {
      return getCheckListItemsByRole({
        policies: DRChecklist.data,
        teamRole,
        teamId: user?.active_team?.id,
        itemsCanBeDeleted: !isRestricted(
          PermissionNamesEnums.POLICIES_OWN_ADDITIONAL_ITEMS_EDIT,
          permissions,
        ),
      });
    }

    return [];
  }, [DRChecklist.data, drawRequestData]);

  const showActionButtons = useMemo(
    () => drawRequestData?.waits_current_user_approval,
    [drawRequestData],
  );

  const handleItemClick = useCallback(
    (item) =>
      updateChecklistItemMutation.mutate({
        projectId,
        checklistId: getChecklistIdByRole(DRChecklist.data, teamRole),
        checklistItemId: item.id,
        status: item.checked ? ItemStatusesEnum.COMPLETED : ItemStatusesEnum.NOT_STARTED,
        drawRequestId: requestId,
      }),
    [DRChecklist.data],
  );

  return {
    items: checklistItems,
    state: getHookState(requestedDataQueries),
    handleItemClick,
    showActionButtons,
  };
};
