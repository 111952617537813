import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 18, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z"
      fill={color}
    />
    <path
      d="M15.5588 10.5473C15.8076 10.2929 15.7893 9.89753 15.5179 9.66428C15.2465 9.43104 14.8247 9.44823 14.5759 9.70268L11.4246 12.9256L10.2462 11.5999C10.0105 11.3347 9.59013 11.2989 9.30728 11.5199C9.02443 11.7408 8.98621 12.1349 9.22192 12.4001L10.8886 14.2751C11.0117 14.4137 11.1927 14.4956 11.385 14.4998C11.5773 14.5041 11.7622 14.4303 11.8922 14.2973L15.5588 10.5473Z"
      fill="white"
    />
  </svg>
);

export default Icon;
