import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 14, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33331 0.333344C5.03566 0.333344 4.77407 0.530662 4.6923 0.816863L4.18536 2.59115L2.42759 2.34004C2.12918 2.29741 1.83929 2.46033 1.72055 2.7374L0.720549 5.07073C0.613165 5.32129 0.669148 5.61199 0.861908 5.80475L2.05717 7.00001L0.861908 8.19527C0.649327 8.40785 0.605327 8.73641 0.754483 8.99744L2.08782 11.3308C2.22627 11.5731 2.50098 11.7035 2.77624 11.6576L4.18914 11.4221L4.6923 13.1832C4.77407 13.4694 5.03566 13.6667 5.33331 13.6667H8.66665C8.9643 13.6667 9.22589 13.4694 9.30766 13.1832L9.81081 11.4221L11.2237 11.6576C11.499 11.7035 11.7737 11.5731 11.9121 11.3308L13.2455 8.99744C13.3946 8.73641 13.3506 8.40785 13.1381 8.19527L11.9428 7.00001L13.1381 5.80475C13.3308 5.61199 13.3868 5.32129 13.2794 5.07073L12.2794 2.7374C12.1607 2.46033 11.8708 2.29741 11.5724 2.34004L9.8146 2.59115L9.30766 0.816863C9.22589 0.530662 8.9643 0.333344 8.66665 0.333344H5.33331ZM5.30766 3.51649L5.83618 1.66668H8.16378L8.6923 3.51649C8.78382 3.83683 9.09778 4.04043 9.42759 3.99331L11.2551 3.73223L11.8761 5.1811L10.5286 6.52861C10.2682 6.78896 10.2682 7.21107 10.5286 7.47141L11.8352 8.77803L10.9849 10.2661L9.44291 10.0091C9.10846 9.95334 8.78544 10.1575 8.6923 10.4835L8.16378 12.3333H5.83618L5.30766 10.4835C5.21451 10.1575 4.8915 9.95334 4.55705 10.0091L3.01509 10.2661L2.16477 8.77802L3.47138 7.47141C3.73173 7.21107 3.73173 6.78896 3.47138 6.52861L2.12387 5.1811L2.74481 3.73223L4.57236 3.99331C4.90217 4.04043 5.21614 3.83683 5.30766 3.51649ZM6.99998 8.33334C6.2636 8.33334 5.66665 7.73639 5.66665 7.00001C5.66665 6.26363 6.2636 5.66668 6.99998 5.66668C7.73636 5.66668 8.33331 6.26363 8.33331 7.00001C8.33331 7.73639 7.73636 8.33334 6.99998 8.33334ZM4.33331 7.00001C4.33331 5.52725 5.52722 4.33334 6.99998 4.33334C8.47274 4.33334 9.66665 5.52725 9.66665 7.00001C9.66665 8.47277 8.47274 9.66668 6.99998 9.66668C5.52722 9.66668 4.33331 8.47277 4.33331 7.00001Z"
      fill={color}
    />
  </svg>
);

export default Icon;
