import { useCallback, useState, useEffect, useMemo } from 'react';
import { useSafeSnackbar } from '@hooks';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import { DropdownFieldModel, useDropdownFieldModel, useDebouncedEffect } from '@models';
import { getCompanyUsers, addTeamMember } from '@globalService';
import { QueryNamesEnums, EnumTypeForList, IUser, ITeamMember, ITeam } from '@interfaces';

interface ControllerInterface {
  member: DropdownFieldModel;
  handleAddTeamMember: () => void;
  isSubmitting: boolean;
  users: EnumTypeForList[];
  handleSearch: (value: string) => void;
  optionsLoading: boolean;
}

export const useAddTeamPopup = (onClose: () => void, team: ITeam): ControllerInterface => {
  const { companyId } = useParams();

  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();

  const [search, setSearch] = useState('');

  const [querySearch, setQuerySearch] = useState<string>('');
  useDebouncedEffect(
    () => setQuerySearch(search ? new URLSearchParams({ q: search }).toString() : ''),
    [search],
    500,
  );

  const handleSearch = (value) => setSearch(value);

  const companyUsersQuery = useQuery<{ results: IUser[] }, Error>(
    [QueryNamesEnums.GET_COMPANY_USERS, { companyId, querySearch }],
    getCompanyUsers.bind(this, { companyId, querySearch }),
    { enabled: Boolean(companyId) },
  );

  const member = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
    validateOnChange: true,
  });

  useEffect(() => {
    return () => member.setValue(null);
  }, []);

  const addTeamMemberMutation = useMutation<
    ITeamMember,
    Error,
    { companyId: string; teamId: string; data: { user: string } }
  >(addTeamMember, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }]);
      member.setValue(null);
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleAddTeamMember = useCallback(() => {
    member.validate() &&
      addTeamMemberMutation.mutateAsync({
        companyId: companyId,
        teamId: team?.id,
        data: { user: member.value?.id },
      });
  }, [companyId, member, team?.id]);

  const users = useMemo(() => {
    const users = companyUsersQuery.data?.results
      ?.filter((o) => !team?.members.find((m) => m.user === o.id))
      .map((o) => ({
        id: o.id,
        name_display: o.email,
        name: o.id,
      }));

    return users || [];
  }, [companyUsersQuery.data, team]);

  return {
    member,
    handleAddTeamMember,
    isSubmitting: addTeamMemberMutation.isLoading,
    users,
    handleSearch,
    optionsLoading: companyUsersQuery.isLoading,
  };
};
