import React, { FC, ReactElement } from 'react';
import { Typography, Stack, Button } from '@mui/material';
import { Popup } from '@components';
import { PopupTypeEnum } from '@interfaces';

interface SuccessModalProps {
  onClose?: () => void;
  title?: string;
  text?: string;
  open: boolean;
  buttons?: ReactElement;
  children?: ReactElement;
  dataTestName?: string;
}

const SuccessModal: FC<SuccessModalProps> = ({
  onClose,
  open,
  text,
  title,
  buttons,
  children,
  dataTestName = 'success_modal_confirm',
}) => (
  <Popup open={open} type={PopupTypeEnum.SUCCESS} title={title || 'Success'}>
    <Stack alignItems="center" sx={{ width: '100%' }}>
      <Stack alignItems="center" mb={7.5} sx={{ whiteSpace: 'pre-line' }}>
        {text && (
          <Typography textAlign="center" variant="body2" data-cy={`${dataTestName}_text`}>
            {text}
          </Typography>
        )}
        {children}
      </Stack>
      {buttons ? (
        buttons
      ) : (
        <Button
          variant="new"
          color="secondary"
          onClick={onClose}
          data-cy={`${dataTestName}_button`}
        >
          Got it
        </Button>
      )}
    </Stack>
  </Popup>
);

export default SuccessModal;
