import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

import { InfoCard, LoadingSkeleton, StyledLink } from '@components';
import * as Controller from './controller';
import { HookState, TPolicies } from '@interfaces';

interface ComponentProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  template: TPolicies;
}

const PolicyProjectsListModal = ({ title, open, handleClose, template }: ComponentProps) => {
  const { state, projectsList, tabLink } = Controller.usePolicyProjectsListModal({ template });

  return (
    <InfoCard open={open} handleClose={handleClose} title={title}>
      {state === HookState.FETCHING && <LoadingSkeleton />}
      {state === HookState.SUCCESS && (
        <Stack sx={{ py: 1 }}>
          {(!projectsList.length && (
            <Typography variant="body2SemiBold">
              No projects linked to this policy template
            </Typography>
          )) ||
            projectsList.map((project, index) => (
              <Stack key={project.id}>
                <StyledLink
                  to={`/projects/${project.id}/policies/${tabLink}`}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {project.name}
                </StyledLink>
                {index < projectsList.length - 1 && (
                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Divider />
                  </Box>
                )}
              </Stack>
            ))}
        </Stack>
      )}
    </InfoCard>
  );
};

export default PolicyProjectsListModal;
