import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const UserIcon = ({ size = 20, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.30377 1.99792C4.30379 1.99791 4.30404 1.99817 4.30444 1.99878L4.30377 1.99792ZM4.34683 2.06621L6.3541 5.26003C6.11154 5.48999 5.84697 5.71777 5.56223 5.96291C5.3175 6.17359 5.05788 6.39711 4.78454 6.64585C4.10705 7.26238 3.97619 8.25394 4.41466 9.02561C6.73326 13.1062 9.89567 14.963 11.3107 15.6296C12.1632 16.0312 13.0894 15.7143 13.605 15.058L14.994 13.2899C14.9949 13.2888 14.9948 13.2891 14.9943 13.2894C14.9942 13.2895 14.9952 13.2889 14.9973 13.2885C14.9994 13.2882 15.0015 13.2882 15.0032 13.2885C15.0045 13.2888 15.0062 13.2892 15.0091 13.291C15.8185 13.7906 16.9477 14.4694 17.985 15.0442C17.7987 16.1688 17.3708 16.824 16.8989 17.2246C16.4035 17.6451 15.7628 17.8665 15.0482 17.9464C13.6213 18.106 12.1728 17.6731 11.8516 17.5677C11.4971 17.3864 9.55356 16.3634 7.47717 14.7056C5.33609 12.9961 3.18918 10.7178 2.36809 8.10491C1.768 6.19529 1.99428 4.85151 2.46593 3.92459C2.92188 3.02852 3.65972 2.42034 4.34683 2.06621ZM3.62071 0.175965C4.56664 -0.272689 5.54477 0.195095 6.00037 0.920022L8.10026 4.26123C8.52478 4.9367 8.55389 5.92415 7.86374 6.60058C7.56796 6.89048 7.15645 7.24679 6.77351 7.57836C6.56331 7.76036 6.36173 7.93491 6.19262 8.08692C8.18813 11.5617 10.856 13.1693 12.0694 13.7572L13.4201 12.0378C14.0579 11.226 15.1934 11.0363 16.0581 11.57C16.858 12.0636 17.9638 12.7281 18.966 13.2829C19.6343 13.653 20.1124 14.4244 19.977 15.2954C19.7337 16.8596 19.0982 17.999 18.1926 18.7678C17.3022 19.5237 16.2415 19.8444 15.2698 19.953C13.3585 20.1668 11.5305 19.588 11.1967 19.4763C11.1122 19.4479 11.042 19.4181 10.9666 19.3798C10.6083 19.1977 8.4963 18.0965 6.23016 16.2872C3.98759 14.4967 1.45851 11.9066 0.455138 8.71365C-0.28189 6.36823 -0.0638629 4.46651 0.680113 3.0044C1.41105 1.56791 2.59475 0.662575 3.62071 0.175965Z"
      fill={color}
    />
  </svg>
);

export default UserIcon;
