import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const SiteIcon = ({ size = 20, color = colors.icons.green }: ComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 4C11.8122 4 11.626 4.00647 11.4415 4.01919C11.4073 4.06251 11.3704 4.11028 11.3313 4.1625C11.0818 4.49504 10.7397 5.00672 10.3944 5.69721C10.0839 6.31821 9.77003 7.08553 9.51888 8H14.4811C14.23 7.08553 13.9161 6.31821 13.6056 5.69721C13.2603 5.00672 12.9182 4.49504 12.6687 4.1625C12.6296 4.11028 12.5927 4.06251 12.5585 4.01919C12.374 4.00647 12.1878 4 12 4ZM7 12C7 11.2953 7.03919 10.6286 7.10906 10H4.25204C4.08751 10.6392 4 11.3094 4 12C4 12.6906 4.08751 13.3608 4.25203 14H7.10906C7.03919 13.3714 7 12.7047 7 12ZM7.45471 16H5.07026C5.89252 17.4214 7.13957 18.5664 8.63784 19.2613L8.60557 19.1972C8.17988 18.3458 7.75879 17.2796 7.45471 16ZM9.51888 16L14.4811 16C14.23 16.9145 13.9161 17.6818 13.6056 18.3028C13.2603 18.9933 12.9182 19.505 12.6687 19.8375C12.6296 19.8897 12.5927 19.9375 12.5585 19.9808C12.374 19.9935 12.1878 20 12 20C11.8122 20 11.626 19.9935 11.4415 19.9808C11.4073 19.9375 11.3704 19.8897 11.3313 19.8375C11.0818 19.505 10.7397 18.9933 10.3944 18.3028C10.0839 17.6818 9.77003 16.9145 9.51888 16ZM14.877 14L9.12304 14C9.04487 13.3796 9 12.713 9 12C9 11.287 9.04487 10.6204 9.12304 10H14.877C14.9551 10.6204 15 11.287 15 12C15 12.713 14.9551 13.3796 14.877 14ZM16.5453 16C16.2412 17.2796 15.8201 18.3458 15.3944 19.1972L15.3622 19.2613C16.8604 18.5664 18.1075 17.4214 18.9297 16H16.5453ZM19.748 14H16.8909C16.9608 13.3714 17 12.7047 17 12C17 11.2953 16.9608 10.6286 16.8909 10H19.748C19.9125 10.6392 20 11.3094 20 12C20 12.6906 19.9125 13.3608 19.748 14ZM8.60557 4.80279C8.17988 5.65418 7.75879 6.72036 7.45471 8H5.07026C5.89252 6.57856 7.13957 5.43359 8.63783 4.73869L8.60557 4.80279ZM15.3622 4.73869C16.8604 5.43359 18.1075 6.57856 18.9297 8H16.5453C16.2412 6.72036 15.8201 5.65418 15.3944 4.80279L15.3622 4.73869Z"
      fill={color}
    />
  </svg>
);

export default SiteIcon;
