import React from 'react';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { SxProps, Theme } from '@mui/material/styles';

import { EnumTypeForList } from '@interfaces';
import { DropdownFieldModel } from '@models';
import snakeCase from 'lodash/snakeCase';

interface AutocompleteInterface {
  field: DropdownFieldModel;
  label?: string;
  options: EnumTypeForList[];
  sx?: SxProps<Theme>;
  required?: boolean;
  inputProps?: object;
  isOptionEqualToValue?: (
    option: string | EnumTypeForList,
    value: string | EnumTypeForList,
  ) => boolean;
  disabled?: boolean;
  handleTextFieldChange?: (value: string) => void;
  optionsLoading?: boolean;
  freeSolo?: boolean;
  clearIcon?: React.ReactNode | null;
  disabledTooltipText?: string;
}

const CustomAutocomplete = ({
  field,
  label,
  options,
  required = false,
  inputProps,
  disabled,
  handleTextFieldChange,
  optionsLoading,
  freeSolo,
  clearIcon,
  disabledTooltipText,
  ...props
}: AutocompleteInterface) => (
  <Autocomplete
    loading={optionsLoading}
    value={field.value}
    id={label}
    options={options}
    onChange={(_: React.SyntheticEvent, newValue: string | EnumTypeForList) => {
      if (typeof newValue !== 'string') return field.setValue(newValue);
    }}
    onInputChange={(_, value) => handleTextFieldChange?.(value)}
    disabled={disabled}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        error={!field.isValid}
        helperText={field.isValid ? '' : `${label || 'Value'} is required`}
        size="small"
        required={required}
        inputProps={{ ...params.inputProps, ...inputProps }}
      />
    )}
    isOptionEqualToValue={(option: string | EnumTypeForList, value: string | EnumTypeForList) => {
      if (typeof option !== 'string' && typeof value !== 'string') {
        return option.name_display === value?.name_display;
      }
      return option === value;
    }}
    sx={{ margin: 0, width: '100%' }}
    renderOption={(props, option) => renderOption(props, option, disabledTooltipText)}
    getOptionLabel={(option: string | EnumTypeForList) => {
      if (typeof option !== 'string') return option?.name_display;
    }}
    freeSolo={freeSolo}
    clearIcon={clearIcon ? <ClearIcon fontSize="small" /> : null}
    {...props}
  />
);

export default CustomAutocomplete;

const renderOption = (props, option, disabledTooltipText) => {
  // Check if the option is not a string and has a `disabled` property set to true
  if (typeof option !== 'string' && option.disabled) {
    // Modify the props to prevent selection
    const newProps = {
      ...props,
      style: {
        ...props.style,
        color: 'grey', // Visual cue for disabled option.
      },
      onClick: (event) => {
        // Prevent the selection
        event.stopPropagation();
      },
    };

    return (
      <Tooltip key={option.id} title={disabledTooltipText || 'Default tooltip text'}>
        <li {...newProps} data-cy={`dropdown_option_${snakeCase(option.name_display)}`}>
          {option.name_display}
        </li>
      </Tooltip>
    );
  }

  // Render normal options
  return typeof option !== 'string' ? (
    <li
      {...props}
      data-cy={`dropdown_option_${snakeCase(option.name_display)}`}
      key={option.id || option.name_display}
    >
      {option.name_display}
    </li>
  ) : (
    <li key={option} {...props}>
      {option}
    </li>
  );
};
