import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 65, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 50 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1561 15.1835C16.1929 13.8333 16.8604 11.3185 18.5159 9.18327C20.0931 7.14898 22.522 5.5 26.25 5.5C29.837 5.5 32.24 7.27304 33.8525 9.76631C35.5255 12.3531 36.25 15.6142 36.25 18C36.25 18.6839 36.5302 19.3379 37.0252 19.8097C37.5202 20.2815 38.187 20.53 38.8701 20.4971C41.2539 20.3825 45 22.9803 45 28C45 31.492 43.6327 33.1707 42.0974 34.117C40.3701 35.1815 38.1021 35.5 36.25 35.5H13.657C12.1297 35.5 9.96985 35.2851 8.23531 34.098C6.67808 33.0322 5 30.8595 5 25.8846C5 22.1346 8.50796 17.6154 13.657 17.6154C15.0112 17.6154 16.1192 16.5372 16.1561 15.1835ZM26.25 0.5C20.7734 0.5 16.957 3.03372 14.5644 6.11962C12.9003 8.266 11.9019 10.7008 11.448 12.7917C4.51687 13.9159 0 20.2166 0 25.8846C0 32.1597 2.22688 36.0447 5.41139 38.2242C8.41858 40.2822 11.8373 40.5 13.657 40.5H36.25C38.4888 40.5 41.8458 40.1454 44.7208 38.3734C47.7878 36.4831 50 33.1619 50 28C50 21.6245 45.8202 16.8424 41.1084 15.7361C40.7727 12.96 39.8209 9.78777 38.0509 7.05099C35.7146 3.4385 31.8675 0.5 26.25 0.5ZM18.5 18.5C17.3954 19.3284 17.1716 20.8954 18 22C18.8284 23.1046 20.3954 23.3284 21.5 22.5L22.5 21.75L22.5 28C22.5 29.3807 23.6193 30.5 25 30.5C26.3807 30.5 27.5 29.3807 27.5 28L27.5 21.75L28.5 22.5C29.6046 23.3284 31.1716 23.1046 32 22C32.8284 20.8954 32.6046 19.3284 31.5 18.5L26.5 14.75C25.6111 14.0833 24.3889 14.0833 23.5 14.75L18.5 18.5Z"
      fill={color}
    />
  </svg>
);

export default Icon;
