import React, { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledBox } from '@components';
import * as Controller from './controller';
import { statusMap } from '@constants';
import { SettingsContext } from '@context';
import { colors } from '@theme';

const RequestsSummaryBlock = () => {
  const { stats } = Controller.useRequestsSummaryBlock();

  if (!stats?.length) return null;
  return (
    <Stack direction="row" spacing={1}>
      {stats.map((stat, index) => (
        <StatsBlock key={index} status={stat.label} quantity={stat.value} />
      ))}
    </Stack>
  );
};

export default RequestsSummaryBlock;

interface StatsBlockProps {
  status: string;
  quantity: number;
}
const StatsBlock = ({ status, quantity }: StatsBlockProps) => {
  const { settings } = useContext(SettingsContext);
  const statusValue = statusMap(status, settings.display, 'draw_request');

  return (
    <StyledBox
      sx={{
        flexGrow: 1,
        padding: 1,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <StyledButton color={statusValue.color} />
          <Typography variant="body3SemiBold">{statusValue.text}</Typography>
        </Stack>
        <Typography
          variant="h2"
          sx={{
            color: statusValue.color,
            marginLeft: 2,
          }}
        >
          {quantity}
        </Typography>
      </Stack>
    </StyledBox>
  );
};

const StyledButton = styled('span')(({ color }) => ({
  width: 24,
  height: 24,
  borderRadius: 2,
  backgroundColor: color,
  display: 'block',
  border: color === 'transparent' ? `1px solid ${colors.neutral.light}` : 'none',
}));
