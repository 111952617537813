import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { CustomAutocomplete } from '@components';
import * as Controller from './controller';
import { ICompanyFull } from '@interfaces';

interface ComponentProps {
  company: ICompanyFull;
  isEditAllowed: boolean;
}

const CompanyTimeZone: FC<ComponentProps> = ({ company, isEditAllowed }) => {
  const { timeZoneField, isSubmitting, options } = Controller.useCompanyTimeZone(company?.timezone);

  if (!options?.length) return null;
  return (
    <Stack spacing={2}>
      <Typography variant="h3">Time Zone</Typography>
      <CustomAutocomplete
        field={timeZoneField}
        label="Time zone"
        options={options}
        disabled={!isEditAllowed || isSubmitting}
        required
        inputProps={{
          'data-cy': 'company_info__time_zone__input',
        }}
      />
    </Stack>
  );
};

export default CompanyTimeZone;
