import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.main.primary.main }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3 3H5.7C4.20883 3 3 4.20883 3 5.7V18.3C3 19.7912 4.20883 21 5.7 21H18.3C19.7912 21 21 19.7912 21 18.3V5.7C21 4.20883 19.7912 3 18.3 3Z"
      fill={color}
    />
    <path
      d="M15.5591 10.5473C15.8079 10.2929 15.7895 9.89753 15.5181 9.66428C15.2467 9.43104 14.825 9.44823 14.5762 9.70268L11.4249 12.9256L10.2465 11.5999C10.0107 11.3347 9.59037 11.2989 9.30752 11.5199C9.02467 11.7408 8.98645 12.1349 9.22216 12.4001L10.8888 14.2751C11.012 14.4137 11.1929 14.4956 11.3852 14.4998C11.5775 14.5041 11.7624 14.4303 11.8924 14.2973L15.5591 10.5473Z"
      fill="white"
    />
  </svg>
);

export default Icon;
