import { useQuery, useQueryClient } from 'react-query';

import { getDrawRequest, getDrawRequestMilestones } from '@globalService';
import { IDrawRequest, IMilestone, QueryNamesEnums } from '@interfaces';
import { replaceObjectProperties } from '@utils';
import { drawRequestQueryFields } from '@constants';

export const useRequestTotals = ({
  projectId,
  drawRequestId,
  totalKey,
  filterKey,
  groupByKeys = '',
}) => {
  const queryClient = useQueryClient();

  const queryTotalKeys = {
    all: 'all,current{approved_amount,retainage_release_approved,requested_amount}',
    current: 'all,current',
    custom: `all,current{approved_amount,retainage_release_approved,requested_amount},${totalKey}`,
  };

  const query = `{totals{${
    queryTotalKeys[totalKey] || queryTotalKeys.custom
  }},${drawRequestQueryFields}}`;
  const drawRequestTotalsData = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_TOTALS,
      { projectId, drawRequestId, query, ...(groupByKeys ? { groupByKeys } : {}) },
    ],
    getDrawRequest.bind(this, { projectId, drawRequestId, query, groupByKeys }),
    { enabled: false },
  );

  const drawRequestMilestonesData = useQuery<{ results: IMilestone[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES, { projectId, drawRequestId, filterKey }],
    getDrawRequestMilestones.bind(this, { projectId, drawRequestId, filterKey }),
    { enabled: false },
  );

  const refetchAndReplaceTotals = ({ isMilestonesUpdate = false } = {}) => {
    if (isMilestonesUpdate) {
      drawRequestMilestonesData.refetch().then((res) => {
        queryClient.setQueriesData<IDrawRequest>(
          {
            queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
            exact: false,
          },
          (request) =>
            replaceObjectProperties({
              data: request,
              newData: { milestones: res.data?.results },
            }),
        );
      });
    } else {
      drawRequestTotalsData.refetch().then((result) => {
        queryClient.setQueriesData<IDrawRequest>(
          {
            queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
            exact: false,
          },
          (request) => replaceObjectProperties({ data: request, newData: result.data }),
        );
      });
    }
  };

  return { refetchAndReplaceTotals };
};
