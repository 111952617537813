import { useEffect, useState } from 'react';

interface HookInterface {
  contextMenu: {
    xPos: number;
    yPos: number;
    open: boolean;
    link?: string;
  };
  handleContextMenuClose: () => void;
  handleContextMenuOpen: (event: React.MouseEvent, link?: string) => void;
}

export const useContextMenu = (): HookInterface => {
  const [contextMenu, setContextMenu] = useState({
    open: false,
    xPos: 0,
    yPos: 0,
    link: '',
  });

  useEffect(() => {
    // Function to handle right-click on the document to close previously opened context menu
    const handleDocumentRightClick = (event) => {
      // Stop the default context menu
      event.preventDefault();
      handleContextMenuClose();
    };

    if (contextMenu.open) {
      // Add event listener when the context menu is open
      document.addEventListener('contextmenu', handleDocumentRightClick);
    } else {
      // Remove event listener when the context menu is not open
      document.removeEventListener('contextmenu', handleDocumentRightClick);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleDocumentRightClick);
    };
  }, [contextMenu.open]);

  const handleContextMenuOpen = (event: React.MouseEvent, link?: string) => {
    event.preventDefault();
    event.stopPropagation();

    setTimeout(() => {
      setContextMenu({
        open: true,
        xPos: event.clientX,
        yPos: event.clientY,
        link,
      });
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu({
      open: false,
      xPos: 0,
      yPos: 0,
      link: '',
    });
  };
  return { contextMenu, handleContextMenuClose, handleContextMenuOpen };
};
