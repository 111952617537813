import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 18L3 18C1.34315 18 0 16.6569 0 15V3C0 1.34315 1.34314 0 3 0H13C14.6569 0 16 1.34315 16 3L16 15C16 16.6569 14.6569 18 13 18ZM13 16C13.5523 16 14 15.5523 14 15L14 3C14 2.44771 13.5523 2 13 2L3 2C2.44772 2 2 2.44772 2 3L2 15C2 15.5523 2.44772 16 3 16L13 16ZM3 9C3 8.44771 3.44772 8 4 8H10C10.5523 8 11 8.44771 11 9C11 9.55229 10.5523 10 10 10H4C3.44772 10 3 9.55229 3 9ZM4 12C3.44772 12 3 12.4477 3 13C3 13.5523 3.44772 14 4 14H8C8.55228 14 9 13.5523 9 13C9 12.4477 8.55228 12 8 12H4Z"
      fill={color}
    />
  </svg>
);

export default Icon;
