import React from 'react';
import { ProgressionSum, MilestoneListColumnTypeV2, isFooterRow } from './common';
import { currencyFormatter } from '@utils';
import { Stack } from '@mui/material';

const revisedConstructionBudgetInDraft: MilestoneListColumnTypeV2 = {
  field: 'revised_construction_budget',
  renderCell: ({ row }) => {
    const { requested_adjustments, prefunding_cost, revised_estimate, requested_budget_change } =
      row;
    const progress = isFooterRow(row) ? requested_budget_change : requested_adjustments;
    const sum = prefunding_cost + revised_estimate;

    return (
      <Stack lineHeight="14px" alignItems="flex-end">
        {currencyFormatter(sum, '')}
        <ProgressionSum sum={sum} progress={progress} />
      </Stack>
    );
  },
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Revised construction budget ($)',
  description: '% of cumulative changes to original scheduled value',
  editable: false,
  minWidth: 120,
};

export default revisedConstructionBudgetInDraft;
