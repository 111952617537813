/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

import { AuthContext, PermissionsContext, useLaunchDarklyFlags } from '@context';
import { ConfirmationModal, DotsVerticalMenu, SuccessModal } from '@components';
import { CloseIcon, DeleteIcon, SendIcon, ReturnIcon } from '@svgAsComponents';
import {
  ITeam,
  IUsersListRow,
  PopupTypeEnum,
  UserListModalTypeEnums,
  UserStatusDisplayEnum,
} from '@interfaces';
import { colors } from '@theme';
import * as Controller from './controller';

const UsersListOptionsMenu: FC<{
  companyUser: IUsersListRow;
  isActivationAllowed?: boolean;
  showRemoveFromTeamOption?: boolean;
  team?: ITeam;
}> = ({ companyUser, isActivationAllowed = true, team, showRemoveFromTeamOption }) => {
  const flags = useLaunchDarklyFlags();
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const { companyId: companyIdFromParams } = useParams();

  const companyId =
    companyUser?.company?.id || companyUser?.teams?.[0]?.company?.id || companyIdFromParams;
  const userTeam = team || companyUser?.teams?.[0];
  const {
    activateUserHandler,
    deactivateUserHandler,
    sendInvitationHandler,
    deleteMemberFromTeamHandler,
    cancelInvitationHandler,
    confirmModalType,
    setConfirmModalType,
    closeConfirmModal,
    isDeleteMemberFromTeamLoading,
  } = Controller.useUsersListOptionsMenu({
    companyId,
    userId: companyUser?.user || companyUser?.id,
    memberId: companyUser?.id,
  });

  const fullName = `${companyUser?.first_name} ${companyUser?.last_name}`;

  const confirmationModal = {
    [UserListModalTypeEnums.ACTIVATE]: (
      <ConfirmationModal
        open
        title="User activating"
        fullWidth
        onClose={() => setConfirmModalType('')}
        confirmCallback={activateUserHandler}
      >
        <Typography variant="body2" textAlign="center">
          Are you sure you want to activate{' '}
          <Typography variant="body2SemiBold">{fullName}</Typography>?
        </Typography>
      </ConfirmationModal>
    ),
    [UserListModalTypeEnums.DEACTIVATE]: (
      <ConfirmationModal
        open
        title="User deactivating"
        fullWidth
        onClose={() => setConfirmModalType('')}
        confirmCallback={deactivateUserHandler}
        type={PopupTypeEnum.ERROR}
      >
        <Typography variant="body2" textAlign="center">
          Are you sure you want to deactivate{' '}
          <Typography variant="body2SemiBold">{fullName}</Typography>?
        </Typography>
      </ConfirmationModal>
    ),
    [UserListModalTypeEnums.INVITE_CANCELLED]: (
      <SuccessModal open onClose={closeConfirmModal}>
        <Typography variant="body2" textAlign="center">
          Invite was cancelled.
        </Typography>
      </SuccessModal>
    ),
    [UserListModalTypeEnums.USER_ACTIVATED]: (
      <SuccessModal open onClose={closeConfirmModal}>
        <Typography variant="body2" textAlign="center">
          <Typography variant="body2SemiBold">{fullName}</Typography> was activated.
        </Typography>
      </SuccessModal>
    ),
    [UserListModalTypeEnums.USER_DEACTIVATED]: (
      <SuccessModal open onClose={closeConfirmModal}>
        <Typography variant="body2" textAlign="center">
          <Typography variant="body2SemiBold">{fullName}</Typography> was deactivated.
        </Typography>
      </SuccessModal>
    ),
    [UserListModalTypeEnums.SEND_INVITE]: (
      <ConfirmationModal
        open
        title="Send invite"
        fullWidth
        onClose={() => setConfirmModalType('')}
        confirmCallback={sendInvitationHandler}
      >
        <Typography variant="body2" textAlign="center">
          Are you sure you want to send invite to{' '}
          <Typography variant="body2SemiBold">{companyUser?.email}</Typography>?
        </Typography>
      </ConfirmationModal>
    ),
    [UserListModalTypeEnums.CANCEL_INVITE]: (
      <ConfirmationModal
        open
        title="Cancel invite"
        fullWidth
        onClose={() => setConfirmModalType('')}
        confirmCallback={cancelInvitationHandler}
      >
        <Typography variant="body2" textAlign="center">
          Are you sure you want to cancel invite to{' '}
          <Typography variant="body2SemiBold">{companyUser?.email}</Typography>?
        </Typography>
      </ConfirmationModal>
    ),
    [UserListModalTypeEnums.INVITE_SENT]: (
      <SuccessModal open onClose={closeConfirmModal}>
        <Typography variant="body2" textAlign="center">
          The invite to <Typography variant="body2SemiBold">{companyUser?.email}</Typography> was
          sent.
        </Typography>
      </SuccessModal>
    ),
    [UserListModalTypeEnums.REMOVE_FROM_TEAM]: (
      <ConfirmationModal
        open
        title="Remove user from team"
        fullWidth
        onClose={() => setConfirmModalType('')}
        confirmCallback={() => deleteMemberFromTeamHandler(userTeam?.id)}
        isLoading={isDeleteMemberFromTeamLoading}
      >
        <Typography variant="body2" textAlign="center">
          Are you sure you want to remove user{' '}
          <Typography variant="body2SemiBold">{`${companyUser?.first_name} ${companyUser?.last_name}`}</Typography>{' '}
          from team <Typography variant="body2SemiBold"> {userTeam?.name}</Typography>?
        </Typography>
      </ConfirmationModal>
    ),
  };

  const teamMemberMenuOptionsArray = useMemo(
    () => [
      {
        action() {
          setConfirmModalType(UserListModalTypeEnums.REMOVE_FROM_TEAM);
        },
        text: 'Remove user from team',
        icon: <DeleteIcon color={colors.icons.green} size={13} />,
        dataTestName: 'company_teams__remove_member_from_team_option',
      },
    ],
    [deleteMemberFromTeamHandler],
  );

  const resendInviteOption = useMemo(
    () => ({
      action() {
        sendInvitationHandler();
      },
      text: 'Resend invite',
      icon: <SendIcon color={colors.icons.green} size={13} />,
    }),
    [sendInvitationHandler],
  );

  const cancelInviteOptionArray = useMemo(
    () => [
      {
        action() {
          setConfirmModalType(UserListModalTypeEnums.CANCEL_INVITE);
        },
        text: 'Cancel invite',
        icon: <ReturnIcon color={colors.icons.green} size={13} />,
      },
    ],
    [cancelInvitationHandler],
  );

  const menuOptionsArray = useMemo(
    () => ({
      [UserStatusDisplayEnum.ACTIVE]: isActivationAllowed
        ? companyIdFromParams
          ? [
              {
                action() {
                  setConfirmModalType(UserListModalTypeEnums.DEACTIVATE);
                },
                text: 'Deactivate user',
                icon: <CloseIcon color={colors.icons.green} size={13} />,
              },
            ]
          : teamMemberMenuOptionsArray
        : [],
      [UserStatusDisplayEnum.INACTIVE]: isActivationAllowed
        ? [
            {
              action() {
                setConfirmModalType(UserListModalTypeEnums.ACTIVATE);
              },
              text: 'Activate user',
              icon: <SendIcon color={colors.icons.green} size={13} />,
            },
            ...(userTeam && showRemoveFromTeamOption ? teamMemberMenuOptionsArray : []),
          ]
        : [],
      [UserStatusDisplayEnum.AWAITING_INVITE]: [
        {
          action() {
            setConfirmModalType(UserListModalTypeEnums.SEND_INVITE);
          },
          text: 'Send invite',
          icon: <SendIcon color={colors.icons.green} size={13} />,
        },
        ...(flags?.['ENG_8991_cancel_invite'] && userTeam && showRemoveFromTeamOption
          ? teamMemberMenuOptionsArray
          : []),
      ],
      // remove after ENG_8991_cancel_invite (it's just for backward compatibility)
      ['Awaiting invite']: [
        {
          action() {
            setConfirmModalType(UserListModalTypeEnums.SEND_INVITE);
          },
          text: 'Send invite',
          icon: <SendIcon color={colors.icons.green} size={13} />,
        },
        ...(flags?.['ENG_8991_cancel_invite'] && userTeam && showRemoveFromTeamOption
          ? teamMemberMenuOptionsArray
          : []),
      ],
      [UserStatusDisplayEnum.INVITED]: [
        resendInviteOption,
        ...(flags?.['ENG_8991_cancel_invite'] ? cancelInviteOptionArray : []),
        ...(userTeam && showRemoveFromTeamOption ? teamMemberMenuOptionsArray : []),
      ],
    }),
    [
      permissions,
      resendInviteOption,
      setConfirmModalType,
      isActivationAllowed,
      teamMemberMenuOptionsArray,
      team,
      flags,
    ],
  );

  const teamMemberMenuOptions = useCallback(
    (status: UserStatusDisplayEnum) => [
      ...(status === UserStatusDisplayEnum.INVITED
        ? [
            resendInviteOption,
            ...(flags?.['ENG_8991_cancel_invite'] ? cancelInviteOptionArray : []),
          ]
        : []),
      ,
      ...teamMemberMenuOptionsArray,
    ],
    [teamMemberMenuOptionsArray, resendInviteOption],
  );

  // no actions for the current user or if menuOptionsArray is empty
  if (
    !companyId ||
    user.id === companyUser?.id ||
    (isEmpty(menuOptionsArray[companyUser?.status_display]) && !teamMemberMenuOptionsArray.length)
  )
    return null;

  return (
    <>
      <DotsVerticalMenu
        menuItems={
          team
            ? teamMemberMenuOptions(companyUser?.status_display as UserStatusDisplayEnum)
            : menuOptionsArray[companyUser?.status_display]
        }
        dataTestName="user_options_menu"
      />
      {confirmationModal[confirmModalType]}
    </>
  );
};

export default UsersListOptionsMenu;
