import React, { useContext, useMemo } from 'react';
import { Form, FormikProvider } from 'formik';
import {
  Autocomplete,
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

import { colors } from '@theme';
import { CustomMultiAutocompleteWithTags, InputField, StyledDashboard } from '@components';
import { DeleteIcon, NoTypeIcon, UploadIcon, UploadIconNew } from '@svgAsComponents';
import model from './form-model/model';
import { ComponentProps } from './interface';
import { checkIsInvestor, checkIsLender, getOptionLabel, getTeamRole } from '@utils';
import { IDocumentType } from '@interfaces';
import { AuthContext, SettingsContext } from '@context';
import { fileTypesMap } from '@constants';
import * as Controller from './controller';

const { formId, formField } = model;

const UploaderWithForm = ({
  closeUploader,
  transloaditSignature,
  documentTypes,
  isOpen,
  refetchCallback,
  refetch,
  restrictions,
  showLineItems,
  source,
  drawRequestId,
  milestoneId,
  projectId,
  inspectionId,
  isProjectImage,
  documentTypeId,
}: ComponentProps) => {
  const { user } = useContext(AuthContext);
  const { isPHBProject } = useContext(SettingsContext);
  const teamRole = getTeamRole(user);
  const lineItemsCanBeShown = useMemo(
    () => showLineItems && !isPHBProject,
    [showLineItems, isPHBProject],
  );

  const {
    currentFiles,
    milestoneOptions,
    isDocumentUploader,
    formik,
    uppy,
    showDashboard,
    showDragDrop,
    setDocumentType,
    setMilestone,
    handleGeneralDocumentType,
    generalDocumentType,
    generalLineItems,
    handleGeneralLineItems,
    generalScope,
    updateGeneralScope,
    handleChangeSwitch,
    deleteFile,
    cancelUpload,
    titleText,
    dropPasteFilesText,
  } = Controller.useUploaderWithForm({
    closeUploader,
    transloaditSignature,
    documentTypes,
    refetchCallback,
    refetch,
    restrictions,
    lineItemsCanBeShown,
    drawRequestId,
    milestoneId,
    projectId,
    inspectionId,
    documentTypeId,
    isProjectImage,
  });

  return (
    <Dialog
      aria-labelledby="responsive-dialog"
      open={isOpen}
      sx={{
        width: lineItemsCanBeShown ? '90%' : '60%',
        minWidth: 1200,
        margin: 'auto',
        ...(!showDashboard && lineItemsCanBeShown
          ? { '& .MuiPaper-root': { maxWidth: 'none', width: '100%' } }
          : { '& .MuiPaper-root': { maxWidth: 1000, width: '100%' } }),
      }}
    >
      <Stack sx={{ p: 2, whiteSpace: 'pre-wrap' }}>
        <Stack alignItems="center" sx={{ mb: 3 }}>
          <UploadIcon size={20} />
          <Typography variant="h4" sx={{ pt: '0.5rem' }} data-cy="upload_docs_modal_title">
            {titleText}
          </Typography>
        </Stack>
        {showDashboard && (
          <StyledDashboard
            uppy={uppy}
            hideUploadButton
            isDocumentUploader
            source={source}
            dropPasteFilesText={dropPasteFilesText}
            enabledExtensions={
              isProjectImage ? restrictions.allowedFileTypes : ['png', 'heic', 'jpg', 'jpeg']
            }
          />
        )}
        {currentFiles?.length === 0 && showDragDrop && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              bottom: '65%',
              left: '47%',
              backgroundColor: colors.uploaderBackground,
              zIndex: 5999,
              pointerEvents: 'none',
            }}
          >
            <UploadIconNew />
          </Stack>
        )}
        <FormikProvider value={formik}>
          <Form id={formId}>
            {!showDashboard && currentFiles?.length > 0 && (
              <Paper sx={{ boxShadow: 'none', paddingY: 3, paddingX: 3 }}>
                <Stack justifyContent="space-between" alignItems="center" flexDirection="row">
                  <Stack sx={{ minWidth: 24 }} />
                  <Stack sx={{ minWidth: 280, pl: 2, width: '100%' }}>
                    <Typography variant="body1">
                      {isDocumentUploader ? 'All documents' : 'All photos'}
                      <Typography
                        variant="body1SemiBold"
                        data-cy={`${source}__upload__modal__documents_amount__value`}
                      >{` (${currentFiles?.length || 0})`}</Typography>
                    </Typography>
                  </Stack>
                  {isDocumentUploader && (
                    <Autocomplete
                      size="small"
                      options={documentTypes}
                      getOptionLabel={getOptionLabel((option: IDocumentType) => option.name)}
                      onChange={(_, value) => handleGeneralDocumentType(value)}
                      value={generalDocumentType || null}
                      renderInput={(params) => (
                        <InputField
                          {...params}
                          onChange={() => handleGeneralDocumentType(undefined)}
                          name="general-type"
                          label="All documents type"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            'data-cy': `${source}__upload__modal__general_type__input`,
                          }}
                        />
                      )}
                      sx={{ minWidth: 200, width: '100%', pl: 2 }}
                    />
                  )}
                  {lineItemsCanBeShown && (
                    <Stack sx={{ minWidth: 200, pl: 2, width: '100%', pt: 0.5 }}>
                      <CustomMultiAutocompleteWithTags
                        field={generalLineItems}
                        label={`Line item(s) for all ${isDocumentUploader ? 'documents' : 'photos'}`}
                        options={milestoneOptions}
                        setField={(value) => handleGeneralLineItems(value)}
                        inputProps={{
                          'data-cy': `${source}__upload__modal__general_line_item__input`,
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                      />
                    </Stack>
                  )}
                  {isDocumentUploader && <Stack sx={{ minWidth: 200, pl: 2, width: '100%' }} />}
                  {(checkIsLender(teamRole) || checkIsInvestor(teamRole)) && isDocumentUploader && (
                    <Stack sx={{ pl: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={generalScope}
                            onChange={(_, value) => updateGeneralScope(value)}
                            data-cy={`${source}__upload__modal__general_scope__switch`}
                          />
                        }
                        componentsProps={{ typography: { variant: 'body3' } }}
                        name="general-scope"
                        label={formField.scope.label}
                      />
                    </Stack>
                  )}
                  <Stack sx={{ minWidth: 48, pl: 2 }} />
                </Stack>
                <Divider sx={{ my: 2 }} />
                {currentFiles.map((currentFile) => {
                  const Icon =
                    fileTypesMap[currentFile?.type] ||
                    fileTypesMap[currentFile?.data?.type] ||
                    NoTypeIcon;
                  const fileId = currentFile?.internal_id;
                  return (
                    <Stack
                      justifyContent="space-between"
                      alignItems="center"
                      flexDirection="row"
                      sx={{ mt: 2 }}
                      key={fileId}
                    >
                      <Stack sx={{ width: 24 }}>
                        <Icon />
                      </Stack>
                      <Stack sx={{ minWidth: 280, pl: 2, width: '100%' }}>
                        <InputField
                          size="small"
                          variant="outlined"
                          name={`${fileId}-name`}
                          label={isDocumentUploader ? formField.name.label : 'Photo name'}
                          fullWidth
                          inputProps={{
                            'data-cy': `${source}__upload__modal__document_name__input`,
                          }}
                        />
                      </Stack>
                      {isDocumentUploader && (
                        <Autocomplete
                          size="small"
                          options={documentTypes}
                          getOptionLabel={getOptionLabel((option: IDocumentType) => option.name)}
                          onChange={(_, value) => setDocumentType(value, fileId)}
                          value={formik.getFieldProps(`${fileId}-type`)?.value || null}
                          renderInput={(params) => (
                            <InputField
                              {...params}
                              onChange={() => setDocumentType(undefined, fileId)}
                              name={`${fileId}-type`}
                              label={formField.type.label}
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                'data-cy': `${source}__upload__modal__document_type__input`,
                              }}
                            />
                          )}
                          sx={{ minWidth: 200, width: '100%', pl: 2 }}
                          isOptionEqualToValue={(option, value) => option.name === value}
                        />
                      )}
                      {lineItemsCanBeShown && (
                        <Stack sx={{ minWidth: 200, pl: 2, width: '100%', pt: 0.5 }}>
                          <CustomMultiAutocompleteWithTags
                            label={formField.milestone.label}
                            options={milestoneOptions}
                            field={formik.getFieldProps(`${fileId}-milestone`)?.value || []}
                            setField={(value) => setMilestone(value, fileId)}
                            inputProps={{
                              'data-cy': `${source}__upload__modal__line_item__input`,
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                          />
                        </Stack>
                      )}
                      {isDocumentUploader && (
                        <Stack sx={{ minWidth: 200, pl: 2, width: '100%' }}>
                          <InputField
                            variant="outlined"
                            name={`${fileId}-comment`}
                            label={formField.comment.label}
                            size="small"
                            inputProps={{
                              'data-cy': `${source}__upload__modal__comment__input`,
                            }}
                          />
                        </Stack>
                      )}
                      {(checkIsLender(teamRole) || checkIsInvestor(teamRole)) &&
                        isDocumentUploader && (
                          <Stack sx={{ pl: 2 }}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={Boolean(formik.getFieldProps(`${fileId}-scope`)?.value)}
                                  onChange={() => handleChangeSwitch(fileId)}
                                  data-cy={`${source}__upload__modal__scope__switch`}
                                />
                              }
                              componentsProps={{ typography: { variant: 'body3' } }}
                              name={`${fileId}-scope`}
                              label={formField.scope.label}
                            />
                          </Stack>
                        )}
                      <IconButton
                        sx={{ pl: 2 }}
                        onClick={() => deleteFile(fileId)}
                        data-cy={`${source}__upload__modal__delete__button`}
                      >
                        <DeleteIcon size={24} />
                      </IconButton>
                    </Stack>
                  );
                })}
              </Paper>
            )}

            <Stack
              direction="row"
              flexWrap="wrap"
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 2 }}
            >
              <Button
                variant="text"
                color="secondary"
                onClick={cancelUpload}
                data-cy={`${source}__upload__modal__cancel__button`}
              >
                Cancel
              </Button>
              <Button
                disabled={formik.isSubmitting || currentFiles?.length === 0}
                type="submit"
                data-cy={`${source}__upload__modal__upload__button`}
                data-count={currentFiles?.length}
              >
                {formik.isSubmitting
                  ? 'Uploading...'
                  : `Upload ${currentFiles?.length} ${
                      currentFiles?.length === 1 ? 'file' : 'files'
                    }`}
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Stack>
    </Dialog>
  );
};

export default UploaderWithForm;
