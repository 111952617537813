import { MilestoneListColumnType } from './common';
import { ColumnV2Width } from '@interfaces';

const spaceItem: MilestoneListColumnType = {
  name: 'id',
  columnText: '',
  Header: null,
  noBorder: true,
  noMargin: true,
  isSpacer: true,
  renderCell: () => null,
  minWidth: () => ColumnV2Width.MIN_LINE,
  maxWidth: () => ColumnV2Width.MAX_LINE,
};

export default spaceItem;
