import { useEffect, useMemo, useContext } from 'react';
import { useQuery } from 'react-query';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useSafeSnackbar } from '@hooks';
import find from 'lodash/find';

import { getHookState } from '@utils';
import { HookState, QueryNamesEnums, ISupersetDashboard, EnumTypeForList } from '@interfaces';
import { getCompanyDashboards } from '@globalService';
import { COMPANY_DATA } from '@constants';
import { useDropdownFieldModel, DropdownFieldModel } from '@models';
import { AuthContext } from '@context';

export interface ControllerInterface {
  state: HookState;
  dashboardsList: EnumTypeForList[];
  selectedDashboard: DropdownFieldModel;
}

export const useSupersetDashboard = ({ dashboardId }): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const companyId = useMemo(() => user.company_id, [user]);

  const selectedDashboard = useDropdownFieldModel({
    initValue: null,
  });

  const dashboardQuery = useQuery<ISupersetDashboard[], Error>(
    [QueryNamesEnums.GET_COMPANY_DASHBOARDS, { companyId }],
    getCompanyDashboards.bind(this, companyId),
    {
      enabled: Boolean(companyId),
      onError: (error) => {
        enqueueSnackbar(error?.message, { variant: 'error' });
      },
    },
  );

  const dashboardsList = useMemo(
    () =>
      dashboardQuery.data?.map(({ dashboard_id, dashboard_name, dashboard_description }) => ({
        id: dashboard_id,
        name: dashboard_name || `Dashboard ${dashboard_id}`,
        name_display: dashboard_name || `Dashboard ${dashboard_id}`,
        dashboard_description,
      })) || [],
    [dashboardQuery.data],
  );

  useEffect(() => {
    if (dashboardsList.length && !selectedDashboard.value) {
      selectedDashboard.setValue(find(dashboardsList, { id: dashboardId }) || dashboardsList[0]);
    }
  }, [dashboardsList, dashboardId]);

  useEffect(() => {
    const id = selectedDashboard?.value?.id;

    if (!id) return;

    const fetchToken = async () => {
      const refetchedQuery = await dashboardQuery.refetch();
      return find(refetchedQuery.data, { dashboard_id: id })?.guest_token;
    };

    embedDashboard({
      id,
      supersetDomain: COMPANY_DATA.SUPERSET_DOMAIN,
      mountPoint: document.getElementById('superset-container'),
      fetchGuestToken: fetchToken,
      dashboardUiConfig: {
        hideChartControls: false,
        hideTitle: true,
        filters: {
          expanded: true,
        },
      },
    });
  }, [selectedDashboard?.value?.id]);

  return {
    state: getHookState(dashboardQuery),
    dashboardsList,
    selectedDashboard,
  };
};
