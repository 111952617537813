import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from '../common';
import { TableContext } from '../../controller';
import { PermissionsContext } from '@context';
import { getEllipsisStyle, isRestricted, useBlockerCell } from '@utils';
import { useDebounceInput, useStringFieldModel } from '@models';
import { colors } from '@theme';
import {
  ColumnAlign,
  ColumnV2Width,
  ColumnWidth,
  PermissionNamesEnums,
  ProductionBuildCommonRowType,
} from '@interfaces';
import { TextInputWithTooltip } from '@components';
import { DeleteIcon } from '@svgAsComponents';

const Name: FC<{ row: ProductionBuildCommonRowType }> = ({ row }) => {
  const isDeleting = useBlockerCell({ milestoneId: row.id });
  const { deleteMilestone, apiUpdate, resetMutation, source } = useContext(TableContext);
  const deleteCallback = useCallback(() => deleteMilestone(row.id, row.isModelBuilding), [row.id]);

  const { permissions } = useContext(PermissionsContext);

  const name = useStringFieldModel({
    initValue: (row['name'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'name',
  });

  const isEditable = useMemo(
    () =>
      row.activeToEdit &&
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row],
  );

  const effect = useCallback(() => {
    if (row.name !== name.value) {
      apiUpdate({
        milestoneId: row.id,
        name: 'name',
        value: name.value,
        isNonNumber: true,
        isModelBuilding: row.isModelBuilding,
      });
    }
  }, [row, name]);

  const { onBlur } = useDebounceInput({
    effect,
    handleChange: name.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { name: '' } }),
    afterEffect: () => name.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <Stack flexDirection="row" maxWidth={220} justifyContent="space-between">
      <TextInputWithTooltip
        type="string"
        value={name.value}
        onChange={name.handleChange}
        error={!name.value}
        errorTip={name.value ? '' : 'Please, fill the name or delete the row.'}
        onBlur={onBlur}
        dataTestName={`${source}__body__name__value__index_${row.index}`}
        iconDataTestName={`${source}__body__name__error_icon__index_${row.index}`}
      />
      <IconButton
        disabled={isDeleting}
        onClick={deleteCallback}
        data-cy={`${source}__body__name__delete__icon__index_${row.index}`}
      >
        <DeleteIcon size={20} color={colors.icons.gray} />
      </IconButton>
    </Stack>
  ) : (
    <Box textAlign={ColumnAlign.TEXT}>
      <Tooltip title={row.name?.length > 25 ? row.name : ''}>
        <Typography component="div" sx={getEllipsisStyle('100%')} variant="body3">
          {row.name}
        </Typography>
      </Tooltip>
    </Box>
  );
};

const name: MilestoneListColumnType = {
  name: 'name',
  columnText: 'Name',
  noBorder: true,
  width: ColumnWidth.NAME,
  isEditable: (permissions) =>
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  renderCell: ({ row }) => <Name row={row} />,
  justifyContent: 'flex-start',
  minWidth: () => ColumnV2Width.NAME_MIN,
  maxWidth: () => ColumnV2Width.NAME_MAX,
  Header: (
    <HeaderText alignItems="flex-start" tooltipText="Name of the item">
      <ColumnLabel>Name</ColumnLabel>
    </HeaderText>
  ),
};

export default name;
