import React, { FC, useContext } from 'react';
import { statusMap } from '@constants';
import { StatusChip } from '@components';
import { SettingsContext } from '@context';
import { IUser } from '@interfaces';

const UserStatusChip: FC<{ user: IUser }> = ({ user }) => {
  const {
    settings: { display: staticStatuses },
  } = useContext(SettingsContext);

  const statusValue = statusMap(user.status_display, staticStatuses, 'users');
  return (
    <StatusChip
      color={statusValue.color}
      backgroundColor={statusValue.backgroundColor}
      label={user.status_display || user.status}
    />
  );
};

export default UserStatusChip;
