import React, { memo, FC } from 'react';
import { Typography } from '@mui/material';
import { MilestoneListColumnType, CommonRowType, ColumnLabel, HeaderText } from './common';
import { currencyFormatter } from '@utils';
import { ColumnV2Width } from '@interfaces';

// used only in Budget tab
const LineItemName: FC<{ row: CommonRowType }> = ({ row }) => (
  <Typography variant="body3">{currencyFormatter(row.approved_adjustments, '-')}</Typography>
);

const Memoized = memo(LineItemName);

const adjustmentsTotalApprovedBudget: MilestoneListColumnType = {
  name: 'approved_adjustments',
  columnText: 'Total approved changes ($)',

  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => {
    return (
      <Typography variant="body3SemiBold">
        {currencyFormatter(row.approved_adjustments, '-')}
      </Typography>
    );
  },
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Cumulative approved changes to original scheduled value">
      <ColumnLabel>Total approved</ColumnLabel>
      <ColumnLabel> changes ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default adjustmentsTotalApprovedBudget;
