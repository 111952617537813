import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8415 19.7526C15.2571 20.1162 15.8889 20.0741 16.2526 19.6585L19.7526 15.6585C20.0825 15.2815 20.0825 14.7185 19.7526 14.3415L16.2526 10.3415C15.8889 9.92585 15.2571 9.88373 14.8415 10.2474C14.4259 10.6111 14.3837 11.2429 14.7474 11.6585L16.7962 14L13.5 14C7.14872 14 2 8.85129 2 2.50002L2 0.999987C2 0.447702 1.55228 -1.33514e-05 1 -1.33514e-05C0.447715 -1.33514e-05 0 0.447702 0 0.999987L0 2.50002C0 9.95587 6.04416 16 13.5 16L16.7962 16L14.7474 18.3415C14.3837 18.7571 14.4259 19.3889 14.8415 19.7526Z"
      fill={color}
    />
  </svg>
);

export default Icon;
