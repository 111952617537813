import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import cloneDeep from 'lodash/cloneDeep';
import { getCompanyRole } from '@globalService';
import { QueryNamesEnums, HookState, IPermission, IRoleNew } from '@interfaces';
import { getHookState } from '@utils';

interface ControllerInterface {
  state: HookState;
  goBack: () => void;
  shownPermissions: { [key: string]: IPermission[] };
}

export const useRolesPermission = (): ControllerInterface => {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const [shownPermissions, setShownPermissions] = useState<IPermission[]>([]);

  const roleQuery = useQuery<IRoleNew, Error>(
    [QueryNamesEnums.GET_COMPANY_TEAM_BY_ID, { roleId }],
    getCompanyRole.bind(this, { roleId }),
  );

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (roleQuery.data) setShownPermissions(cloneDeep(roleQuery.data.permissions));
  }, [roleQuery.data]);

  const groupPermissionsByGroup = (permissions) => {
    const groupedPermissions = {};

    permissions.forEach((permission) => {
      if (!permission.group) return;
      const group = permission.group;

      // If the group doesn't exist in the groupedPermissions object, create it as an empty array
      if (!groupedPermissions[group]) {
        groupedPermissions[group] = [];
      }

      // Push the permission into the corresponding group
      groupedPermissions[group].push(permission);
    });

    return groupedPermissions;
  };

  return {
    state: getHookState([roleQuery]),
    shownPermissions: groupPermissionsByGroup(shownPermissions),
    goBack,
  };
};
