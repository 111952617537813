import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useInfoCredit } from './controller';
import { LabelAndValue } from '@components';
import { currencyFormatter } from '@utils';
import { colors } from '@theme';

const InfoCreditPanel: FC<{
  milestoneId?: string;
  requestId?: string;
  source: string;
  withProjectName?: boolean;
}> = ({ milestoneId, requestId, source, withProjectName }) => {
  const { name, milestone } = useInfoCredit({
    requestId,
    milestoneId,
  });
  return (
    <Stack flex={1}>
      {withProjectName && (
        <Typography variant="body2">
          Project:
          <Typography variant="body2SemiBold"> {name}</Typography>
        </Typography>
      )}
      <Stack mt={withProjectName ? 3 : 1} flex={1} spacing={4}>
        <Stack spacing={1}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography variant="h4">Approved credit amount</Typography>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <LabelAndValue
            label="Requested credit amount"
            text={currencyFormatter(+milestone.data?.requested_credit_amount, '-')}
            textDataTestName={`${source}__requested_credit_amount`}
          />
          <LabelAndValue
            label="Approved credit amount"
            text={currencyFormatter(+milestone.data?.approved_credit_amount, '-')}
            textDataTestName={`${source}__approved_credit_amount`}
          />
          <Stack direction="row">
            <Typography color={colors.text.medium} variant="body2">
              Reason:
            </Typography>
            <Typography
              dangerouslySetInnerHTML={{ __html: milestone.data?.credit_reason || '' }}
              variant="body2"
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InfoCreditPanel;
