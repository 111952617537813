import React, { FC, useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { currencyFormatter, isChangeRequest, isDrawRequest } from '@utils';

import {
  ChecklistApproveModal,
  DRApproveConfirmationModal,
  MarkAsPaidConfirmationModal,
  RequestTerminationModal,
  StyledLink,
  SuccessModal,
  SwitchApproverPopup,
} from '@components';
import {
  ChecklistItemLocal,
  PolicyListReasonsTypeEnum,
  RequestOperationsModalTypeEnum,
} from '@interfaces';
import { ControllerInterface } from '../../controller';
import { usePaymentInfo } from './controller';

const ApprovalModals: FC<{
  controller: ControllerInterface;
  checklistItems: ChecklistItemLocal[];
  projectId: string;
  drawRequestId: string;
}> = ({ controller, checklistItems, projectId, drawRequestId }) => {
  const {
    closeModal,
    handleCloseApproveModal,
    handleCloseChecklistApproveModal,
    sendRequestReview,
    nextReviewer,
    isChecklistReasonsRequired,
    modalType,
    approveActionType,
    dataIsLoading,
    isFinalApprove,
    handleTermination,
    isAddReviewMutating,
  } = controller;

  const { amountToDisburse, borrowerEquity, retainageRate, feesAmount, drawRequest } =
    usePaymentInfo({
      projectId,
      drawRequestId,
    });

  const isApproveModalOpened = useMemo(
    () => modalType === RequestOperationsModalTypeEnum.APPROVED,
    [modalType],
  );
  const isChecklistApproveModalOpened = useMemo(
    () => modalType === RequestOperationsModalTypeEnum.CHECKLIST,
    [modalType],
  );

  const isDRApproveModalOpened = useMemo(
    () => modalType === RequestOperationsModalTypeEnum.DRAW_REQUEST,
    [modalType],
  );
  const isSwitchApproverModalOpened = useMemo(
    () => modalType === RequestOperationsModalTypeEnum.SWITCH_APPROVER,
    [modalType],
  );
  const isMarkAsPaidModalOpened = useMemo(
    () => modalType === RequestOperationsModalTypeEnum.MARK_AS_DISBURSED,
    [modalType],
  );
  const isTerminationModalOpened = useMemo(
    () =>
      [
        RequestOperationsModalTypeEnum.REJECT,
        RequestOperationsModalTypeEnum.PUT_ON_HOLD,
        RequestOperationsModalTypeEnum.RELEASE_FROM_HOLD,
      ].includes(modalType),
    [modalType],
  );
  const isTerminationSuccess = useMemo(
    () =>
      [
        RequestOperationsModalTypeEnum.REJECT_SUCCESS,
        RequestOperationsModalTypeEnum.RELEASE_FROM_HOLD_SUCCESS,
        RequestOperationsModalTypeEnum.PUT_ON_HOLD_SUCCESS,
      ].includes(modalType),
    [modalType],
  );
  const successModalTexts = useMemo(
    () => ({
      [RequestOperationsModalTypeEnum.REJECT_SUCCESS]: {
        title: 'Request returned',
        text: `${isChangeRequest(drawRequest) ? 'Change' : 'Draw'} request #${
          drawRequest?.number
        } has been returned to the borrower.`,
      },
      [RequestOperationsModalTypeEnum.RELEASE_FROM_HOLD_SUCCESS]: {
        title: 'Request released from hold',
        text: `${isChangeRequest(drawRequest) ? 'Change' : 'Draw'} request #${
          drawRequest?.number
        } has been released from hold.`,
      },
      [RequestOperationsModalTypeEnum.PUT_ON_HOLD_SUCCESS]: {
        title: 'Request put on hold',
        text: `${isChangeRequest(drawRequest) ? 'Change' : 'Draw'} request #${
          drawRequest?.number
        } has been put on hold.`,
      },
    }),
    [drawRequest],
  );

  return (
    <>
      {isTerminationSuccess && (
        <SuccessModal
          title={successModalTexts[modalType].title}
          open={isTerminationSuccess}
          text={successModalTexts[modalType].text}
          buttons={<ModalBottomButtons onClose={handleCloseApproveModal} />}
        />
      )}
      {!isFinalApprove && (
        <SuccessModal
          title="Approval successful"
          open={isApproveModalOpened}
          text={`${isChangeRequest(drawRequest) ? 'Change' : 'Draw'} request #${
            drawRequest?.number
          } has been sent for the next review.\n \n Next reviewer: ${nextReviewer}`}
          buttons={<ModalBottomButtons onClose={handleCloseApproveModal} />}
        />
      )}
      {isFinalApprove && isChangeRequest(drawRequest) && (
        <SuccessModal
          title="Success!"
          open={isApproveModalOpened}
          text={`Change request #${drawRequest?.number} has been approved.`}
          buttons={<ModalBottomButtons onClose={handleCloseApproveModal} />}
        />
      )}
      {isFinalApprove && isDrawRequest(drawRequest) && (
        <SuccessModal
          title="Draw funding authorized"
          open={isApproveModalOpened}
          buttons={<ModalBottomButtons onClose={handleCloseApproveModal} />}
        >
          <>
            <Typography textAlign="center" variant="body2">
              {`Draw request #${drawRequest?.number} for ${currencyFormatter(
                drawRequest?.totals?.all?.approved_amount || 0,
              )} is approved and authorized for funding.\n You can access the disbursement statement in the `}
            </Typography>
            <StyledLink
              onClick={handleCloseApproveModal}
              to={`/projects/${projectId}/payments/draw-requests/${drawRequest?.id}`}
            >
              payment details.
            </StyledLink>
          </>
        </SuccessModal>
      )}

      <DRApproveConfirmationModal
        open={isDRApproveModalOpened}
        onClose={closeModal}
        confirmCallback={sendRequestReview}
        amountToDisburse={amountToDisburse}
        drNumber={drawRequest?.counter_per_request_type}
        type={isChangeRequest(drawRequest) ? 'Change' : 'Draw'}
        borrowerEquity={borrowerEquity}
        request={drawRequest}
        approveActionType={approveActionType}
        isMutating={dataIsLoading}
        isFinalApprove={isFinalApprove}
        projectId={projectId}
        drawRequestId={drawRequestId}
        retainageRate={retainageRate}
        retainageHeldback={drawRequest?.totals?.all?.retainage_approved_amount_holdback || 0}
        retainageReleased={drawRequest?.totals?.all?.retainage_release_approved || 0}
        feesAmount={feesAmount}
      />

      {isChecklistApproveModalOpened && (
        <ChecklistApproveModal
          open={isChecklistApproveModalOpened}
          onClose={closeModal}
          onApproveClose={handleCloseChecklistApproveModal}
          checklistItems={checklistItems}
          drawRequestId={drawRequest?.id?.toString()}
          projectId={projectId}
          isRequiredReasons={isChecklistReasonsRequired}
          popupText={`Prior to${
            isFinalApprove ? '' : ' sending for'
          } approval, select the reasons for exception to the following requirements (${
            checklistItems.length
          }).`}
          source="requests_tab__approve__exception_reasons_modal"
          checklistType={PolicyListReasonsTypeEnum.REQUEST}
        />
      )}

      {isTerminationModalOpened && (
        <RequestTerminationModal
          drNumber={drawRequest?.counter_per_request_type}
          drType={isChangeRequest(drawRequest) ? 'Change' : 'Draw'}
          open={isTerminationModalOpened}
          onClose={closeModal}
          confirmCallback={handleTermination}
          isMutating={isAddReviewMutating}
          terminationType={modalType}
        />
      )}
      {isMarkAsPaidModalOpened && (
        <MarkAsPaidConfirmationModal onClose={closeModal} drawRequestId={drawRequestId} />
      )}

      <SwitchApproverPopup
        open={isSwitchApproverModalOpened}
        request={drawRequest}
        onClose={closeModal}
        title="Send for approval"
        confirmButtonLabel="Send"
        projectId={projectId}
        source="request__action_popup"
      />
    </>
  );
};

export const ModalBottomButtons: FC<{ onClose: (navigateLink?: string) => void }> = ({
  onClose,
}) => {
  return (
    <Stack direction="row">
      <Button
        variant="new"
        color="secondary"
        onClick={() => onClose()}
        data-cy="draw_request_modal_back_to_project_button"
      >
        Back to project
      </Button>
      <Button
        variant="new"
        color="secondary"
        onClick={() => onClose('/requests/draws-and-changes')}
        sx={{ ml: '8px' }}
        data-cy="draw_request_modal_back_to_requests_button"
      >
        Back to requests
      </Button>
    </Stack>
  );
};

export default ApprovalModals;
