/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Typography, Stack, Box, Divider } from '@mui/material';

import { ComponentProps } from './interface';

export default function PopoverContentList({ data, title }: ComponentProps) {
  return (
    <Stack>
      {title && (
        <Typography variant="body3SemiBold" sx={{ mb: 2 }}>
          {title}
        </Typography>
      )}
      {Boolean(data?.length) &&
        data?.map((item, index) => {
          return (
            <Box key={index}>
              <Typography
                variant="label"
                dangerouslySetInnerHTML={{ __html: item?.text || '' }}
              ></Typography>
              {index !== data?.length - 1 && <Divider sx={{ my: 1 }} />}
            </Box>
          );
        })}
    </Stack>
  );
}
