import React, { FC } from 'react';
import { colors } from '@theme';

import { PaymentGraphs } from '../components';
import PaymentSummary from '../components/PaymentSummary';
import { Stack } from '@mui/material';

const RequestPaymentInfoV2: FC = () => {
  return (
    <Stack
      sx={{
        backgroundColor: colors.background.gray,
      }}
    >
      <PaymentSummary />
      <PaymentGraphs />
    </Stack>
  );
};

export default RequestPaymentInfoV2;
