import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { Field } from 'formik';
import { FormFieldError, PasswordVisibilityIcon } from '@components';

const Input = ({ error, helperText, ...props }) => {
  return (
    <TextField
      margin="normal"
      fullWidth
      error={error}
      helperText={<FormFieldError text={helperText} />}
      {...props}
    />
  );
};

const InputField = ({ name, label, serverError = '', type = '', ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const getInputProps = () => {
    if (type === 'password')
      return {
        endAdornment: (
          <PasswordVisibilityIcon
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
          />
        ),
      };
    return null;
  };

  return (
    <Field name={name}>
      {({ field, form: { touched, errors } }) => {
        return (
          <Input
            name={name}
            label={label}
            type={showPassword ? 'text' : type}
            error={(touched[name] && Boolean(errors[name])) || Boolean(serverError)}
            helperText={(touched[name] && errors[name]) || serverError}
            InputProps={getInputProps()}
            {...field}
            {...props}
            value={field.value || ''}
          />
        );
      }}
    </Field>
  );
};

export default InputField;
