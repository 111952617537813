import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C7.58172 20 4 16.4183 4 12C4 7.92038 7.05369 4.55399 11 4.06189V11.5858C11 12.1162 11.2107 12.6249 11.5858 13L16.9056 18.3199C15.551 19.3729 13.8487 20 12 20ZM18.3199 16.9056C19.3729 15.551 20 13.8487 20 12C20 7.92038 16.9463 4.55399 13 4.06189V11.5858L18.3199 16.9056ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      fill={color}
    />
  </svg>
);

export default Icon;
