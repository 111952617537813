import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 19, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 19) * 18}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8235 1.70711C18.214 1.31658 18.214 0.683417 17.8235 0.292893C17.433 -0.0976311 16.7998 -0.0976311 16.4093 0.292893L9.11639 7.58579L1.8235 0.292893C1.43298 -0.0976311 0.799812 -0.0976311 0.409288 0.292893C0.0187646 0.683417 0.0187646 1.31658 0.409288 1.70711L7.70218 9L0.409287 16.2929C0.018763 16.6834 0.018763 17.3166 0.409287 17.7071C0.799812 18.0976 1.43298 18.0976 1.8235 17.7071L9.11639 10.4142L16.4093 17.7071C16.7998 18.0976 17.433 18.0976 17.8235 17.7071C18.214 17.3166 18.214 16.6834 17.8235 16.2929L10.5306 9L17.8235 1.70711Z"
      fill={color}
    />
  </svg>
);

export default Icon;
