import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8C10 9.10457 9.10457 10 8 10ZM10.7998 10.8568C11.5405 10.1308 12 9.11907 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 9.11907 4.45955 10.1308 5.20022 10.8568C3.87259 11.7557 3 13.2759 3 15V19C3 19.5523 3.44772 20 4 20C4.55228 20 5 19.5523 5 19V15C5 13.3431 6.34315 12 8 12C9.65685 12 11 13.3431 11 15V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V15C13 13.2759 12.1274 11.7557 10.7998 10.8568ZM17.5 4C18.0523 4 18.5 4.44772 18.5 5V6.5H20C20.5523 6.5 21 6.94772 21 7.5C21 8.05228 20.5523 8.5 20 8.5H18.5V10C18.5 10.5523 18.0523 11 17.5 11C16.9477 11 16.5 10.5523 16.5 10V8.5H15C14.4477 8.5 14 8.05228 14 7.5C14 6.94772 14.4477 6.5 15 6.5H16.5V5C16.5 4.44772 16.9477 4 17.5 4Z"
      fill={color}
    />
  </svg>
);

export default Icon;
