import React, { FC } from 'react';
import { ChipProps } from '@mui/material';
import { colors } from '@theme';
import { StatusChip } from '@components';

const BetaChip: FC<{ dataTestName: string; size?: ChipProps['size'] | 'large' }> = ({
  dataTestName,
  size = 'small',
}) => (
  <StatusChip
    color={colors.status.information.medium}
    backgroundColor={colors.status.information.lighter}
    label="Beta"
    size={size}
    dataTestName={dataTestName}
  />
);

export default BetaChip;
