import React, { FC, PropsWithChildren } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { ComponentWithTooltipProps } from './interface';

const IconButtonElement: FC<PropsWithChildren> = ({ children, ...props }) => {
  return <IconButton {...props}>{children}</IconButton>;
};

const IconButtonWithTooltip: FC<ComponentWithTooltipProps> = ({ tooltipText, ...props }) => {
  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <div>
        <IconButtonElement {...props} />
      </div>
    </Tooltip>
  ) : (
    <IconButtonElement {...props} />
  );
};

export default IconButtonWithTooltip;
