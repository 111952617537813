import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useDownloadAllButton } from './controller';
import { IDocument, IProjectDocument } from '@interfaces';

const DownloadAllButton: FC<{
  documents: IProjectDocument[] | IDocument[];
  source: string;
  buttonLabel?: string;
  drawRequestId?: string;
}> = ({ documents, source, buttonLabel = 'Download all', drawRequestId }) => {
  const { downloadDocs } = useDownloadAllButton({ documents, drawRequestId });

  return (
    <Button
      variant="new"
      color="secondary"
      onClick={downloadDocs}
      disabled={!documents?.length}
      data-cy={`${source}__download_all__button`}
      data-count={documents?.length}
    >
      {buttonLabel}
    </Button>
  );
};

export default DownloadAllButton;
