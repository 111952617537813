import React, { FC } from 'react';
import { TextField, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ICustomLink } from '@interfaces';
import * as Controller from './controller';

const CustomNavigationLink: FC<{
  link: ICustomLink;
  saveCallback: (link) => void;
  removeCallback: (link) => void;
  isSubmitting: boolean;
}> = ({ link, saveCallback, removeCallback, isSubmitting }) => {
  const { handleSave, handleRemove, urlField, labelField } = Controller.useCustomNavLink(
    link,
    saveCallback,
    removeCallback,
  );

  return (
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <Stack
        direction="row"
        spacing={3}
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ flex: 1, mr: 1 }}
      >
        <TextField
          fullWidth
          variant="outlined"
          value={urlField.value}
          error={!urlField.isValid}
          helperText={
            urlField.isValid
              ? ''
              : !urlField.value
                ? 'Url is required'
                : 'Invalid url, should be in format https://... or ftp://...'
          }
          label="Url"
          onChange={urlField.handleChange}
          sx={{ maxWidth: '50%' }}
          size="small"
        />
        <TextField
          fullWidth
          variant="outlined"
          value={labelField.value}
          error={!labelField.isValid}
          helperText={
            labelField.isValid
              ? ''
              : !labelField.value
                ? 'Label is required'
                : 'Should be between 3 and 16 characters'
          }
          label="Label"
          onChange={labelField.handleChange}
          sx={{ maxWidth: '50%' }}
          size="small"
        />
      </Stack>

      <LoadingButton
        loading={isSubmitting}
        disabled={link.url === urlField.value && link.label === labelField.value}
        onClick={handleSave}
      >
        Save
      </LoadingButton>
      <LoadingButton
        color="error"
        loading={isSubmitting}
        onClick={handleRemove}
        disabled={!link.id}
      >
        Remove link
      </LoadingButton>
    </Stack>
  );
};

export default CustomNavigationLink;
