import { PermissionNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

const lockedValues = {
  ['Yes']: 'true',
  ['No']: 'false',
};

export const isBudgetLockedFilter: FilterObject = {
  title: 'Locked budget',
  filterKey: 'is_budget_locked',
  permissionKey: [
    PermissionNamesEnums.PROJECT_ONBOARDING,
    PermissionNamesEnums.PROJECT_BUDGET_EDIT,
  ],
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getStaticValues: () =>
    Object.keys(lockedValues).map((label) => ({ label, value: lockedValues[label] })),
  cypressSelector: 'filters__is_budget_locked',
};
