import { Dispatch, useCallback, useMemo, useState, SetStateAction, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { IProject, QueryNamesEnums, IInspectionFields } from '@interfaces';
import { updateProjectFields } from '@globalService';
import { useInspectionFields } from '@hooks';
import { useLaunchDarklyFlags, SettingsContext } from '@context';

interface ControllerInterface {
  handleSubmitClick: () => Promise<boolean>;
  isSubmitting: boolean;
  isUpdated: boolean;
  exitPath: string;
  isOrderAutomatically: boolean;
  setIsOrderAutomatically: Dispatch<SetStateAction<boolean>>;
  inspectionFields: IInspectionFields;
  isSubmitDisabled: boolean;
  isCurrentProjectArchived: boolean;
}

export const useInspectionSettings = ({ project }: { project: IProject }): ControllerInterface => {
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const flags = useLaunchDarklyFlags();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUserNotRequired,
    primaryContactUserList,
    isBorrowerUsersLoading,
    isProjectInspectionSettingsUpdated,
    inspectionCadence,
    primaryContactPhone,
  } = useInspectionFields({
    project,
  });

  const { inspection_is_order_automatic, id } = project || {};

  const initialState = inspection_is_order_automatic ?? false;
  const [isOrderAutomatically, setIsOrderAutomatically] = useState<boolean>(initialState);

  const projectMutation = useMutation<
    Response,
    Error,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId: project.id }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleSubmitClick = useCallback(async () => {
    if (!additionalContactPhone.validate()) return false;

    await projectMutation.mutateAsync({
      projectId: id,
      json: {
        inspection_additional_contact_name: additionalContactName.value,
        inspection_additional_contact_phone: additionalContactPhone.valueToSave,
        inspection_entry_access_code: accessCode.value,
        inspection_is_order_automatic: isOrderAutomatically,
        inspection_primary_contact_user_id: primaryContactUserNotRequired.value?.id || null,
        inspection_cadence: +inspectionCadence.value,
      },
    });
    return true;
  }, [
    additionalContactName.value,
    additionalContactPhone.value,
    accessCode.value,
    primaryContactUserNotRequired.value?.id,
    id,
    isOrderAutomatically,
    inspectionCadence.value,
    flags,
  ]);

  const isUpdated = useMemo(() => {
    return (
      isProjectInspectionSettingsUpdated || isOrderAutomatically !== inspection_is_order_automatic
    );
  }, [isProjectInspectionSettingsUpdated, isOrderAutomatically, inspection_is_order_automatic]);

  const isSubmitDisabled = useMemo(
    () =>
      [additionalContactPhone, primaryContactUserNotRequired].some((field) => !field.validate()),
    [additionalContactPhone?.value, primaryContactUserNotRequired?.value?.id, flags],
  );

  const exitPath = useMemo(() => `/projects/${id}/overview`, [id]);

  return {
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUserNotRequired,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionCadence,
      primaryContactPhone,
    },
    handleSubmitClick,
    isSubmitting: projectMutation.isLoading,
    isUpdated,
    exitPath,
    isOrderAutomatically,
    setIsOrderAutomatically,
    isSubmitDisabled,
    isCurrentProjectArchived,
  };
};
