import React, { FC } from 'react';
import { Popup, InternationalPhoneField, CustomTextField } from '@components';
import { Button, Stack } from '@mui/material';
import * as Controller from './controller';

const PersonalInfoEditPopup: FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { firstNameField, lastNameField, phoneNumberField, submitPersonalInfo } =
    Controller.usePersonalInfoEdit(onClose);

  return (
    <Popup open={open} maxWidth="xs" title="Edit profile">
      <Stack sx={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
        <Stack spacing={2}>
          <CustomTextField
            field={firstNameField}
            label="First name"
            inputProps={{
              'data-cy': 'personal_info__edit_popup__first_name__input',
            }}
            required
          />
          <CustomTextField
            field={lastNameField}
            label="Last name"
            inputProps={{
              'data-cy': 'personal_info__edit_popup__last_name__input',
            }}
            required
          />
          <InternationalPhoneField
            field={phoneNumberField}
            inputProps={{
              'data-cy': 'personal_info__edit_popup__phone__input',
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 4 }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => submitPersonalInfo()}>Save</Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default PersonalInfoEditPopup;
