import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const NavigateIcon = ({ size = 16, color = colors.icons.green }: ComponentProps) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86752 6.90367L5.00001 11.6336L2.1325 6.90367C0.741671 4.60954 2.35763 1.64743 5.00001 1.64743C7.64239 1.64743 9.25835 4.60954 7.86752 6.90367ZM1.03839 7.59527C-0.883113 4.4258 1.34942 0.333496 5.00001 0.333496C8.6506 0.333496 10.8831 4.4258 8.96163 7.59527L5.42847 13.4231C5.23147 13.7481 4.76855 13.7481 4.57155 13.4231L1.03839 7.59527ZM5.00014 3.61834C4.28947 3.61834 3.71335 4.20661 3.71335 4.93228C3.71335 5.65795 4.28947 6.24622 5.00014 6.24622C5.71081 6.24622 6.28692 5.65795 6.28692 4.93228C6.28692 4.20661 5.71081 3.61834 5.00014 3.61834Z"
      fill={color}
    />
  </svg>
);

export default NavigateIcon;
