import { TEAM_ROLES } from '@constants';
import { QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { checkIsLender, getTeamRole } from '@utils';

export const lenderFilter: FilterObject = {
  title: 'Lender',
  filterKey: 'lender',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  userPermission: (user) => !checkIsLender(getTeamRole(user)),
  getDataParamsPaginated: (pagination, q) => ({
    type: QueryNamesEnums.GET_COMPANIES_BY_ROLE,
    keys: ['id', 'name'],
    args: {
      pagination,
      q,
      role: TEAM_ROLES.Lender,
    },
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
    },
  }),
  cypressSelector: 'filters__lender',
  withPagination: true,
};
