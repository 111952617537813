import React from 'react';
import { RadioCheckedIcon, RadioUncheckedIcon } from '@svgAsComponents';
import { colors } from '@theme';

export default function Radio() {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          svg: {
            width: 24,
            height: 24,
          },
          '&.Mui-disabled > svg > path:first-of-type': {
            fill: colors.neutral.lighter,
          },
          '&.Mui-disabled.Mui-checked > svg > path:first-of-type': {
            fill: colors.main.primary.lighter,
          },
          '&.MuiRadio-root': {
            padding: 4,
          },
        },
      },
      defaultProps: {
        icon: <RadioUncheckedIcon />,
        checkedIcon: <RadioCheckedIcon />,
      },
    },
  };
}
