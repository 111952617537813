import { colors, sizes } from '../index';

export default function OutlinedInput() {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: sizes.fontSizes.small,
          fontFamily: 'Inter',
          backgroundColor: colors.background.gray,
        },
        notchedOutline: {
          border: 'none',
        },
      },
    },
  };
}
