import React, { FC } from 'react';
import {
  Divider,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { HookState, IPermission } from '@interfaces';
import { LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';
import { ArrowBackIcon } from '@svgAsComponents';
import snakeCase from 'lodash/snakeCase';

const RolesPermisssion: FC = () => {
  const { state, goBack, shownPermissions } = Controller.useRolesPermission();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox sx={{ flex: 1 }}>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="team permissions" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack spacing={2}>
          <Stack direction="row">
            <IconButton onClick={goBack} data-cy="company_teams__permissions_go_back_button">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h1">Permissions</Typography>
          </Stack>

          {Object.keys(shownPermissions).map((groupName) => (
            <StyledBox sx={{ p: 0 }} key={groupName}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" sx={{ m: 2 }}>
                  {groupName}
                </Typography>
                <Switch
                  sx={{ mr: '15px' }}
                  checked={shownPermissions[groupName].every(
                    (permission: IPermission) => permission.enabled,
                  )}
                  data-cy={`permission_switcher_${snakeCase(groupName)}`}
                  disabled
                />
              </Stack>

              <Divider />

              <TableContainer sx={{ minWidth: '100%' }}>
                <Table>
                  <TableBody>
                    {shownPermissions[groupName]?.map((permission: IPermission) => (
                      <TableRow
                        key={permission.description}
                        sx={{ '&:last-of-type': { '& td': { borderBottom: 'none' } } }}
                      >
                        <TableCell style={{ width: '100%' }}>{permission.description}</TableCell>
                        <TableCell>
                          <Switch
                            checked={permission.enabled}
                            data-cy={`permission_switcher_${permission.name}`}
                            disabled
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledBox>
          ))}
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default RolesPermisssion;
