import { useGetData } from '@context';
import { QueryNamesEnums } from '@interfaces';
import { useParams } from 'react-router';

export const useLineItemDataWrapper = () => {
  const { projectId } = useParams();

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name'],
    args: { projectId },
  });

  return {
    name: project.data.name,
  };
};
