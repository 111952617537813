import { QueryNamesEnums } from '@interfaces';
import {
  getCompaniesListByRoleWithQuery,
  getCompaniesWithQuery,
  getCoordinatorsWithQuery,
  getDrawRequestMilestone,
  getDrawRequestMilestoneGroup,
  getDrawRequestMilestones,
  getInspectionAgenciesWithQuery,
  getProjectMilestone,
  getProjectMilestonesList,
  getProjectPropertWithQuery,
  getProjectsTeamsWithQuery,
  getProjectTeamsWithQuery,
  getProjectTypesWithQuery,
  getProjectWithQuery,
  getUISettingsLoanTypes,
  getUISettingsServicesTypes,
} from '@globalService';

export const dataProviders = {
  [QueryNamesEnums.GET_PROJECT]: getProjectWithQuery,
  [QueryNamesEnums.GET_PROJECT_MILESTONE]: getProjectMilestone,
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE]: getDrawRequestMilestone,
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP]: getDrawRequestMilestoneGroup,
} as const;

export const dataProvidersLists = {
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: getDrawRequestMilestones,
  [QueryNamesEnums.GET_PROJECT_MILESTONES]: getProjectMilestonesList,
  [QueryNamesEnums.GET_COMPANIES_BY_ROLE]: getCompaniesListByRoleWithQuery,
  [QueryNamesEnums.GET_PROJECTS_TEAMS]: getProjectsTeamsWithQuery,
  [QueryNamesEnums.GET_COORDINATORS]: getCoordinatorsWithQuery,
  [QueryNamesEnums.GET_COMPANIES]: getCompaniesWithQuery,
  [QueryNamesEnums.GET_INSPECTION_AGENCIES]: getInspectionAgenciesWithQuery,
  [QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES]: getProjectPropertWithQuery,
  [QueryNamesEnums.GET_PROJECT_TYPES]: getProjectTypesWithQuery,
  [QueryNamesEnums.GET_PROJECT_TEAMS]: getProjectTeamsWithQuery,
  [QueryNamesEnums.GET_UI_SETTINGS_SERVICES_TYPE]: getUISettingsServicesTypes,
  [QueryNamesEnums.GET_UI_SETTINGS_LOANS_TYPE]: getUISettingsLoanTypes,
} as const;
