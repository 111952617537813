import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 200 }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 201 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M125.124 106.833C125.762 106.7 126.322 106.321 126.683 105.779C127.044 105.236 127.178 104.574 127.055 103.934C127.028 103.792 126.988 103.654 126.936 103.519L133.56 97.8087L129.229 96.5835L123.603 102.194C123.011 102.476 122.555 102.979 122.332 103.595C122.109 104.211 122.137 104.89 122.411 105.485C122.643 105.984 123.038 106.389 123.531 106.634C124.024 106.879 124.586 106.949 125.124 106.833L125.124 106.833Z"
      fill="#FAC9AD"
    />
    <path
      d="M168.143 147.331L168.383 141.917C170.893 140.575 173.693 139.866 176.539 139.852C172.622 143.055 173.111 149.228 170.456 153.535C169.617 154.871 168.489 156.002 167.154 156.844C165.818 157.685 164.311 158.215 162.743 158.395L159.465 160.402C159.016 157.894 159.115 155.319 159.756 152.854C160.397 150.388 161.565 148.091 163.18 146.121C164.097 145.024 165.148 144.047 166.309 143.214C167.094 145.284 168.143 147.331 168.143 147.331Z"
      fill="#E8E8E8"
    />
    <path
      d="M159.697 157.616L156.729 157.616L155.317 146.167L159.697 146.167L159.697 157.616Z"
      fill="#FAC9AD"
    />
    <path
      d="M160.454 160.493L150.883 160.493V160.371C150.883 159.384 151.276 158.436 151.974 157.738C152.673 157.039 153.62 156.646 154.608 156.646H154.609L156.357 155.32L159.618 156.647L160.454 156.647L160.454 160.493Z"
      fill="#262626"
    />
    <path
      d="M145.907 157.616L142.939 157.616L141.527 146.167L145.908 146.167L145.907 157.616Z"
      fill="#FAC9AD"
    />
    <path
      d="M146.664 160.493L137.094 160.493V160.371C137.094 159.384 137.486 158.436 138.185 157.738C138.883 157.039 139.831 156.646 140.819 156.646H140.819L142.567 155.32L145.829 156.647L146.664 156.647L146.664 160.493Z"
      fill="#262626"
    />
    <path
      d="M144.623 95.0261L163.929 95.5088C167.595 97.5003 167.651 101.811 166.45 106.928C166.45 106.928 166.855 109.97 166.044 110.781C165.233 111.592 164.827 111.592 165.233 113.012C165.638 114.431 163.496 116.75 163.756 117.01C164.016 117.27 164.624 118.69 164.624 118.69L162.597 130.248C162.597 130.248 161.177 150.324 160.772 150.73C160.366 151.135 159.96 150.73 160.366 151.744C160.772 152.758 161.177 152.352 160.772 152.758C160.412 153.145 160.074 153.551 159.758 153.974H154.855C154.855 153.974 154.485 151.946 154.485 151.744C154.485 151.541 154.08 150.324 154.08 150.121C154.08 149.919 154.438 149.56 154.438 149.56C154.562 149.148 154.646 148.725 154.688 148.296C154.688 147.891 155.905 132.276 155.905 132.276L153.471 111.998L147.591 131.871C147.591 131.871 147.591 149.31 147.185 149.716C146.779 150.121 146.779 149.919 146.982 150.73C147.185 151.541 147.996 151.338 147.388 151.946C146.779 152.555 146.577 151.541 146.779 152.555L146.982 153.569L140.899 153.656C140.899 153.656 140.088 151.946 140.493 151.338C140.899 150.73 140.874 150.896 140.38 149.9C139.885 148.905 139.682 148.702 139.885 148.499C140.088 148.296 140.088 147.218 140.088 147.218L141.102 127.815C141.102 127.815 141.304 107.334 141.304 106.726C141.32 106.412 141.262 106.1 141.136 105.813V104.987L141.913 102.061L144.623 95.0261Z"
      fill="#262626"
    />
    <path
      d="M124.901 100.246L128.164 102.877L145.951 89.0895L144.899 65.0926C143.898 65.8929 143.067 66.8836 142.452 68.0074C141.837 69.1311 141.45 70.3657 141.315 71.6397L138.268 85.1952L124.901 100.246Z"
      fill="#A6BFB6"
    />
    <path
      d="M141.741 101.088C148.037 101.893 153.838 99.4547 159.318 97.6147C161.792 96.784 164.284 97.6512 166.58 96.2464C165.587 86.4652 166.551 75.4475 168.661 63.7062C168.781 63.0416 168.655 62.3558 168.307 61.7769C167.959 61.198 167.412 60.7655 166.769 60.5601L159.972 58.6099L157.879 56.3013L152.474 57.6733L150.582 61.0932L144.08 65.4863C143.701 65.7426 143.403 66.103 143.224 66.5242C143.044 66.9455 142.99 67.4096 143.068 67.8608C145.022 78.903 144.569 90.2367 141.741 101.088V101.088Z"
      fill="#A6BFB6"
    />
    <path
      d="M161.835 104.115C162.413 103.813 162.848 103.296 163.047 102.676C163.247 102.055 163.195 101.381 162.902 100.799C162.837 100.671 162.761 100.548 162.674 100.433L167.489 93.1317L162.988 93.1347L159.106 100.068C158.614 100.5 158.312 101.109 158.266 101.762C158.219 102.415 158.431 103.061 158.857 103.559C159.216 103.975 159.707 104.258 160.249 104.359C160.79 104.46 161.349 104.374 161.835 104.115L161.835 104.115Z"
      fill="#FAC9AD"
    />
    <path
      d="M160.476 97.2989L163.423 101.088L173.737 85.7215L172.845 70.1102C172.731 68.1219 172.117 66.1946 171.058 64.5076C170 62.8207 168.531 61.4289 166.791 60.4617V60.4617L167.001 84.4585L160.476 97.2989Z"
      fill="#A6BFB6"
    />
    <path
      d="M153.07 55.4081C156.713 55.4081 159.666 52.4549 159.666 48.812C159.666 45.1691 156.713 42.2159 153.07 42.2159C149.427 42.2159 146.474 45.1691 146.474 48.812C146.474 52.4549 149.427 55.4081 153.07 55.4081Z"
      fill="#FAC9AD"
    />
    <path
      d="M148.867 44.9409C149.122 44.9429 149.376 44.9763 149.624 45.0402C150.111 45.1573 150.605 45.2403 151.104 45.2885C152.631 45.4321 154.066 46.0833 155.179 47.1381C156.293 48.1929 157.021 49.5904 157.247 51.1073C157.63 50.7732 158.502 50.1585 159.217 50.7298C159.228 50.7388 159.234 50.7435 159.259 50.7316C159.734 50.5064 160.577 47.7343 160.602 45.2935C160.616 43.998 160.412 42.3438 159.364 41.8199L159.309 41.7923L159.295 41.7323C159.225 41.4409 158.442 40.9435 157.083 40.5563C154.613 39.8527 150.931 38.4821 148.858 40.4992C148.701 41.1792 148.19 42.6344 147.674 42.7993C147.103 42.9819 146.61 43.1395 146.532 43.84C146.468 44.8368 146.472 45.8369 146.547 46.8331C146.74 46.2235 147.12 45.69 147.633 45.3076C147.997 45.0622 148.428 44.9343 148.867 44.9409V44.9409Z"
      fill="#262626"
    />
    <path
      d="M191.611 160.534C191.611 160.567 191.605 160.6 191.592 160.63C191.58 160.661 191.561 160.689 191.538 160.712C191.515 160.735 191.487 160.754 191.457 160.766C191.426 160.779 191.394 160.785 191.361 160.785H9.63941C9.57297 160.785 9.50926 160.759 9.46229 160.712C9.41531 160.665 9.38892 160.601 9.38892 160.534C9.38892 160.468 9.41531 160.404 9.46229 160.357C9.50926 160.31 9.57297 160.284 9.63941 160.284H191.361C191.394 160.284 191.426 160.29 191.457 160.303C191.487 160.315 191.515 160.334 191.538 160.357C191.561 160.38 191.58 160.408 191.592 160.438C191.605 160.469 191.611 160.502 191.611 160.534Z"
      fill="#BCBCBC"
    />
    <path
      d="M84.5952 60.7306L59.8352 66.301C59.1718 66.4511 58.5951 66.8582 58.2314 67.4329C57.8678 68.0077 57.747 68.7032 57.8955 69.367L64.6849 99.545L93.7764 93C94.0154 92.947 94.2342 92.8263 94.4064 92.6523L87.6611 62.6703C87.511 62.0069 87.104 61.4302 86.5292 61.0665C85.9544 60.7029 85.2589 60.5821 84.5952 60.7306V60.7306Z"
      fill="#D2D2D2"
    />
    <path
      d="M75.6011 64.886L62.0321 67.9387C61.428 68.0752 60.9027 68.4458 60.5714 68.9691C60.2402 69.4924 60.13 70.1257 60.265 70.7301L66.6484 99.1032L92.4858 93.2903L87.8371 72.6274C87.2382 69.9799 85.6137 67.678 83.3198 66.2267C81.0259 64.7754 78.25 64.2932 75.6011 64.886V64.886Z"
      fill="white"
    />
    <path
      d="M87.5171 91.7608L70.4833 95.5931C70.4103 95.6094 70.3412 95.6399 70.28 95.6829C70.2187 95.7259 70.1666 95.7806 70.1265 95.8438C70.0865 95.907 70.0593 95.9774 70.0465 96.0512C70.0337 96.1249 70.0355 96.2004 70.052 96.2734C70.0684 96.3464 70.099 96.4154 70.1422 96.4766C70.1853 96.5377 70.2401 96.5897 70.3034 96.6297C70.3666 96.6696 70.4371 96.6967 70.5109 96.7093C70.5846 96.722 70.6602 96.72 70.7331 96.7034L87.767 92.8712C87.84 92.8549 87.9092 92.8244 87.9705 92.7814C88.0317 92.7384 88.0839 92.6838 88.124 92.6206C88.1642 92.5573 88.1914 92.4868 88.2042 92.4131C88.217 92.3393 88.2152 92.2638 88.1988 92.1907C88.1823 92.1177 88.1516 92.0486 88.1085 91.9875C88.0653 91.9263 88.0105 91.8743 87.9472 91.8343C87.8838 91.7944 87.8133 91.7673 87.7395 91.7547C87.6657 91.7421 87.5901 91.7442 87.5171 91.7608Z"
      fill="#D2D2D2"
    />
    <path
      d="M87.0231 89.565L69.9893 93.3973C69.8419 93.4305 69.7137 93.5208 69.6329 93.6485C69.5522 93.7762 69.5254 93.9307 69.5586 94.0781C69.5917 94.2255 69.6821 94.3537 69.8098 94.4344C69.9374 94.5152 70.092 94.542 70.2394 94.5088L87.2732 90.6765C87.3462 90.6601 87.4152 90.6295 87.4763 90.5864C87.5375 90.5433 87.5895 90.4886 87.6295 90.4253C87.6695 90.3621 87.6967 90.2917 87.7094 90.2179C87.7222 90.1442 87.7203 90.0687 87.7039 89.9958C87.6875 89.9228 87.6569 89.8537 87.6138 89.7926C87.5707 89.7315 87.5159 89.6794 87.4527 89.6394C87.3895 89.5994 87.319 89.5723 87.2453 89.5595C87.1716 89.5467 87.0961 89.5486 87.0231 89.565H87.0231Z"
      fill="#D2D2D2"
    />
    <path
      d="M76.8793 89.5414L69.4954 91.2026C69.3482 91.2358 69.2201 91.326 69.1394 91.4536C69.0587 91.5811 69.032 91.7355 69.0651 91.8827C69.0983 92.03 69.1885 92.158 69.3161 92.2387C69.4436 92.3194 69.598 92.3461 69.7452 92.313L77.1291 90.6518C77.2022 90.6355 77.2714 90.6051 77.3326 90.562C77.3939 90.519 77.4461 90.4644 77.4862 90.4012C77.5263 90.338 77.5536 90.2674 77.5664 90.1937C77.5792 90.1199 77.5774 90.0444 77.5609 89.9713C77.5445 89.8983 77.5138 89.8292 77.4706 89.7681C77.4275 89.7069 77.3727 89.6549 77.3093 89.6149C77.246 89.575 77.1754 89.548 77.1017 89.5353C77.0279 89.5227 76.9523 89.5248 76.8793 89.5414V89.5414Z"
      fill="#D2D2D2"
    />
    <path
      d="M85.495 82.7726L68.4612 86.6048C68.3882 86.6212 68.3192 86.6519 68.2581 86.695C68.1969 86.7381 68.1448 86.7928 68.1049 86.856C68.0241 86.9837 67.9973 87.1382 68.0305 87.2856C68.0637 87.433 68.154 87.5612 68.2817 87.642C68.4094 87.7227 68.5639 87.7495 68.7113 87.7163L85.7451 83.8841C85.8181 83.8676 85.8871 83.837 85.9483 83.7939C86.0094 83.7508 86.0615 83.6961 86.1015 83.6329C86.1415 83.5696 86.1686 83.4992 86.1814 83.4255C86.1941 83.3518 86.1922 83.2762 86.1758 83.2033C86.1594 83.1303 86.1288 83.0613 86.0857 83.0001C86.0426 82.939 85.9879 82.8869 85.9246 82.8469C85.8614 82.8069 85.7909 82.7798 85.7172 82.767C85.6435 82.7543 85.568 82.7561 85.495 82.7726V82.7726Z"
      fill="#D2D2D2"
    />
    <path
      d="M85.0013 80.5779L67.9674 84.4101C67.8944 84.4264 67.8253 84.457 67.7641 84.5C67.7029 84.543 67.6507 84.5977 67.6107 84.6609C67.5706 84.724 67.5434 84.7945 67.5306 84.8682C67.5178 84.9419 67.5197 85.0175 67.5361 85.0905C67.5525 85.1635 67.5832 85.2325 67.6263 85.2936C67.6694 85.3548 67.7242 85.4068 67.7875 85.4467C67.8508 85.4867 67.9213 85.5138 67.995 85.5264C68.0688 85.5391 68.1443 85.5371 68.2172 85.5205L85.2511 81.6882C85.3241 81.6719 85.3931 81.6413 85.4542 81.5983C85.5154 81.5553 85.5675 81.5006 85.6075 81.4374C85.6475 81.3742 85.6746 81.3038 85.6874 81.2301C85.7002 81.1565 85.6983 81.081 85.6819 81.008C85.6655 80.9351 85.6348 80.8661 85.5918 80.805C85.5487 80.7439 85.494 80.6918 85.4307 80.6519C85.3675 80.6119 85.2971 80.5848 85.2234 80.5721C85.1497 80.5594 85.0742 80.5614 85.0013 80.5779V80.5779Z"
      fill="#D2D2D2"
    />
    <path
      d="M74.8573 80.5531L67.4734 82.2144C67.4004 82.2308 67.3314 82.2614 67.2703 82.3045C67.2091 82.3476 67.1571 82.4023 67.1171 82.4656C67.0771 82.5288 67.0499 82.5992 67.0372 82.673C67.0244 82.7467 67.0263 82.8222 67.0427 82.8951C67.0591 82.9681 67.0898 83.0372 67.1329 83.0983C67.176 83.1594 67.2307 83.2115 67.2939 83.2515C67.3571 83.2915 67.4276 83.3186 67.5013 83.3314C67.575 83.3442 67.6505 83.3423 67.7235 83.3259L75.1074 81.6646C75.1804 81.6482 75.2494 81.6176 75.3105 81.5745C75.3717 81.5314 75.4237 81.4767 75.4637 81.4134C75.5037 81.3502 75.5309 81.2798 75.5436 81.206C75.5564 81.1323 75.5545 81.0568 75.5381 80.9839C75.5217 80.9109 75.491 80.8418 75.4479 80.7807C75.4048 80.7195 75.3501 80.6675 75.2869 80.6275C75.2237 80.5875 75.1532 80.5603 75.0795 80.5476C75.0058 80.5348 74.9303 80.5367 74.8573 80.5531V80.5531Z"
      fill="#D2D2D2"
    />
    <path
      d="M83.4728 73.7843L66.439 77.6166C66.2916 77.6497 66.1634 77.7401 66.0826 77.8678C66.0019 77.9954 65.9751 78.15 66.0083 78.2973C66.0414 78.4447 66.1318 78.5729 66.2595 78.6537C66.3871 78.7345 66.5417 78.7612 66.6891 78.7281L83.7229 74.8958C83.7959 74.8794 83.8649 74.8487 83.9261 74.8056C83.9872 74.7625 84.0392 74.7078 84.0792 74.6446C84.1192 74.5814 84.1464 74.5109 84.1592 74.4372C84.1719 74.3635 84.17 74.288 84.1536 74.215C84.1372 74.142 84.1066 74.073 84.0635 74.0119C84.0204 73.9507 83.9656 73.8987 83.9024 73.8587C83.8392 73.8187 83.7687 73.7915 83.695 73.7788C83.6213 73.766 83.5458 73.7679 83.4728 73.7843V73.7843Z"
      fill="#D2D2D2"
    />
    <path
      d="M82.9789 71.5896L65.9451 75.4219C65.8721 75.4383 65.8031 75.469 65.7419 75.512C65.6808 75.5551 65.6287 75.6099 65.5887 75.6731C65.508 75.8008 65.4812 75.9553 65.5144 76.1027C65.5475 76.2501 65.6379 76.3783 65.7656 76.459C65.8932 76.5398 66.0478 76.5665 66.1952 76.5334L83.229 72.7011C83.302 72.6847 83.371 72.6541 83.4322 72.611C83.4933 72.5679 83.5454 72.5132 83.5853 72.4499C83.6253 72.3867 83.6525 72.3162 83.6653 72.2425C83.678 72.1688 83.6761 72.0933 83.6597 72.0203C83.6433 71.9474 83.6127 71.8783 83.5696 71.8172C83.5265 71.7561 83.4717 71.704 83.4085 71.664C83.3453 71.624 83.2748 71.5968 83.2011 71.5841C83.1274 71.5713 83.0519 71.5732 82.9789 71.5896V71.5896Z"
      fill="#D2D2D2"
    />
    <path
      d="M72.8351 71.5649L65.4512 73.2261C65.3038 73.2593 65.1756 73.3496 65.0948 73.4773C65.0141 73.605 64.9873 73.7595 65.0205 73.9069C65.0536 74.0543 65.144 74.1825 65.2717 74.2632C65.3994 74.344 65.5539 74.3708 65.7013 74.3376L73.0852 72.6764C73.1582 72.66 73.2272 72.6293 73.2883 72.5862C73.3495 72.5431 73.4015 72.4884 73.4415 72.4252C73.4815 72.362 73.5087 72.2915 73.5214 72.2178C73.5342 72.1441 73.5323 72.0686 73.5159 71.9956C73.4995 71.9226 73.4688 71.8536 73.4257 71.7924C73.3826 71.7313 73.3279 71.6792 73.2647 71.6392C73.2015 71.5992 73.131 71.5721 73.0573 71.5593C72.9836 71.5466 72.9081 71.5485 72.8351 71.5649Z"
      fill="#D2D2D2"
    />
    <path
      d="M35.1989 95.745C35.0375 95.7452 34.8827 95.8094 34.7686 95.9235C34.6545 96.0376 34.5903 96.1924 34.5901 96.3538V159.303C34.5903 159.464 34.6545 159.619 34.7686 159.733C34.8827 159.847 35.0375 159.911 35.1989 159.912H114.124C114.286 159.911 114.441 159.847 114.555 159.733C114.669 159.619 114.733 159.464 114.733 159.303V106.458C114.733 106.297 114.669 106.142 114.555 106.028C114.441 105.914 114.286 105.85 114.124 105.85H68.8263C68.3665 105.85 67.9231 105.679 67.583 105.369L57.3993 96.1161C57.1366 95.8771 56.7941 95.7447 56.4389 95.745H35.1989Z"
      fill="#262626"
    />
    <path
      d="M129.389 79.2805L87.1289 61.1121C85.9958 60.6265 84.7163 60.6102 83.5712 61.0668C82.4261 61.5233 81.5088 62.4155 81.0206 63.5475L58.8765 115.055L108.53 136.402C108.937 136.579 109.386 136.634 109.824 136.562L131.825 85.3889C132.31 84.2558 132.326 82.9763 131.87 81.8312C131.413 80.686 130.521 79.7687 129.389 79.2805Z"
      fill="#D2D2D2"
    />
    <path
      d="M111.77 75.8094L88.6103 65.8527C87.5786 65.4103 86.4135 65.3952 85.3707 65.8107C84.3278 66.2261 83.4923 67.0383 83.0475 68.0689L62.2278 116.496L106.327 135.455L121.489 100.188C123.427 95.6655 123.492 90.5591 121.67 85.9889C119.848 81.4187 116.288 77.7579 111.77 75.8094V75.8094Z"
      fill="white"
    />
    <path
      d="M100.663 127.924L71.5898 115.425C71.4653 115.372 71.3314 115.343 71.1959 115.341C71.0603 115.339 70.9256 115.364 70.7997 115.414C70.6737 115.464 70.5588 115.538 70.4616 115.633C70.3644 115.727 70.2868 115.84 70.2333 115.965C70.1797 116.089 70.1513 116.223 70.1495 116.359C70.1477 116.494 70.1727 116.629 70.2231 116.755C70.2734 116.881 70.348 116.996 70.4427 117.093C70.5374 117.19 70.6504 117.267 70.775 117.32L99.8484 129.82C99.9729 129.873 100.107 129.902 100.242 129.904C100.378 129.906 100.513 129.881 100.639 129.831C100.765 129.781 100.879 129.706 100.977 129.612C101.074 129.517 101.151 129.405 101.205 129.28C101.258 129.155 101.287 129.022 101.289 128.886C101.29 128.75 101.265 128.616 101.215 128.49C101.165 128.364 101.09 128.249 100.995 128.152C100.901 128.055 100.788 127.978 100.663 127.924V127.924Z"
      fill="#D2D2D2"
    />
    <path
      d="M102.275 124.177L73.2011 111.677C72.9496 111.569 72.6654 111.566 72.4112 111.667C72.1569 111.769 71.9533 111.967 71.8452 112.218C71.7371 112.47 71.7332 112.754 71.8346 113.008C71.9359 113.263 72.1341 113.466 72.3855 113.575L101.459 126.074C101.584 126.127 101.717 126.156 101.853 126.158C101.989 126.159 102.123 126.134 102.249 126.084C102.375 126.034 102.49 125.959 102.587 125.865C102.684 125.77 102.762 125.658 102.815 125.533C102.869 125.408 102.897 125.274 102.899 125.139C102.901 125.003 102.876 124.869 102.826 124.743C102.776 124.617 102.701 124.502 102.607 124.405C102.512 124.308 102.399 124.23 102.275 124.177H102.275Z"
      fill="#D2D2D2"
    />
    <path
      d="M87.4143 113.35L74.8115 107.932C74.687 107.878 74.5532 107.849 74.4177 107.848C74.2822 107.846 74.1476 107.87 74.0217 107.921C73.8958 107.971 73.781 108.045 73.6839 108.14C73.5868 108.234 73.5092 108.347 73.4557 108.471C73.4022 108.596 73.3737 108.73 73.3719 108.865C73.3701 109.001 73.3951 109.135 73.4453 109.261C73.4956 109.387 73.5701 109.502 73.6647 109.599C73.7594 109.696 73.8722 109.773 73.9967 109.827L86.5996 115.245C86.724 115.299 86.8578 115.327 86.9934 115.329C87.1289 115.331 87.2634 115.306 87.3893 115.256C87.5152 115.206 87.63 115.131 87.7272 115.037C87.8243 114.942 87.9018 114.83 87.9554 114.705C88.0089 114.581 88.0374 114.447 88.0391 114.311C88.0409 114.176 88.0159 114.041 87.9657 113.915C87.9155 113.789 87.8409 113.675 87.7463 113.578C87.6517 113.481 87.5389 113.403 87.4143 113.35V113.35Z"
      fill="#D2D2D2"
    />
    <path
      d="M107.259 112.583L78.1853 100.084C77.9338 99.9763 77.6498 99.9728 77.3957 100.074C77.1416 100.176 76.9381 100.374 76.83 100.625C76.722 100.877 76.7181 101.161 76.8193 101.415C76.9205 101.669 77.1185 101.873 77.3697 101.981L106.443 114.48C106.568 114.534 106.702 114.563 106.837 114.565C106.973 114.567 107.108 114.542 107.234 114.492C107.36 114.442 107.475 114.367 107.572 114.272C107.67 114.178 107.747 114.065 107.801 113.94C107.854 113.815 107.883 113.681 107.885 113.546C107.886 113.41 107.861 113.275 107.811 113.149C107.761 113.023 107.686 112.908 107.591 112.811C107.496 112.714 107.384 112.637 107.259 112.583V112.583Z"
      fill="#D2D2D2"
    />
    <path
      d="M108.869 108.837L79.7958 96.3382C79.6713 96.2845 79.5375 96.2558 79.4019 96.2538C79.2663 96.2519 79.1317 96.2766 79.0057 96.3268C78.8797 96.3769 78.7648 96.4513 78.6677 96.5459C78.5705 96.6404 78.4929 96.7532 78.4393 96.8778C78.3858 97.0024 78.3573 97.1363 78.3556 97.2718C78.3538 97.4074 78.3788 97.542 78.4291 97.6679C78.4794 97.7938 78.5541 97.9086 78.6488 98.0056C78.7435 98.1027 78.8564 98.1801 78.9811 98.2334L108.054 110.733C108.179 110.786 108.313 110.815 108.448 110.817C108.584 110.819 108.719 110.794 108.845 110.744C108.971 110.694 109.085 110.619 109.183 110.525C109.28 110.43 109.357 110.318 109.411 110.193C109.465 110.068 109.493 109.935 109.495 109.799C109.497 109.663 109.472 109.529 109.421 109.403C109.371 109.277 109.296 109.162 109.202 109.065C109.107 108.968 108.994 108.891 108.869 108.837V108.837Z"
      fill="#D2D2D2"
    />
    <path
      d="M94.0098 98.0086L81.4069 92.5904C81.1554 92.4824 80.8712 92.4787 80.617 92.5801C80.3627 92.6816 80.1591 92.8798 80.051 93.1314C79.9429 93.3829 79.939 93.667 80.0404 93.9213C80.1417 94.1756 80.3399 94.3793 80.5914 94.4875L93.1942 99.9057C93.3188 99.9593 93.4527 99.9879 93.5883 99.9897C93.7239 99.9916 93.8586 99.9667 93.9846 99.9165C94.1106 99.8663 94.2254 99.7917 94.3226 99.6971C94.4198 99.6025 94.4974 99.4897 94.5509 99.3651C94.6045 99.2405 94.633 99.1066 94.6348 98.971C94.6366 98.8354 94.6117 98.7007 94.5614 98.5748C94.5112 98.4488 94.4366 98.3339 94.3419 98.2368C94.2473 98.1397 94.1344 98.0621 94.0098 98.0086V98.0086Z"
      fill="#D2D2D2"
    />
    <path
      d="M113.854 97.242L84.7807 84.7429C84.5292 84.6352 84.2453 84.6317 83.9912 84.7332C83.7372 84.8347 83.5338 85.0329 83.4257 85.2842C83.3177 85.5356 83.3138 85.8195 83.4149 86.0737C83.5161 86.3279 83.714 86.5316 83.9651 86.64L113.039 99.1391C113.163 99.1927 113.297 99.2213 113.433 99.2231C113.568 99.225 113.703 99.2001 113.829 99.1499C113.955 99.0997 114.07 99.0252 114.167 98.9306C114.264 98.836 114.342 98.7231 114.395 98.5985C114.449 98.4739 114.477 98.34 114.479 98.2044C114.481 98.0688 114.456 97.9342 114.406 97.8082C114.356 97.6822 114.281 97.5674 114.186 97.4702C114.092 97.3731 113.979 97.2955 113.854 97.242V97.242Z"
      fill="#1F4739"
    />
    <path
      d="M115.465 93.4962L86.3911 80.9971C86.1396 80.8893 85.8556 80.8857 85.6015 80.9872C85.3474 81.0887 85.1439 81.2869 85.0359 81.5383C84.9278 81.7897 84.9239 82.0737 85.0251 82.3279C85.1263 82.5821 85.3243 82.7858 85.5756 82.8942L114.649 95.3933C114.774 95.4473 114.908 95.4762 115.044 95.4783C115.179 95.4804 115.314 95.4557 115.441 95.4056C115.567 95.3555 115.682 95.2809 115.779 95.1862C115.877 95.0915 115.954 94.9786 116.008 94.8538C116.062 94.729 116.09 94.5948 116.092 94.459C116.094 94.3231 116.069 94.1883 116.018 94.0622C115.968 93.9361 115.893 93.8212 115.798 93.724C115.703 93.6269 115.59 93.5495 115.465 93.4962V93.4962Z"
      fill="#1F4739"
    />
    <path
      d="M100.605 82.6674L88.0024 77.2492C87.7509 77.1412 87.4667 77.1375 87.2124 77.239C86.9582 77.3404 86.7546 77.5387 86.6464 77.7902C86.5383 78.0417 86.5345 78.3258 86.6358 78.5801C86.7372 78.8345 86.9354 79.0381 87.1868 79.1463L99.7897 84.5645C99.9143 84.6181 100.048 84.6467 100.184 84.6485C100.319 84.6504 100.454 84.6255 100.58 84.5753C100.706 84.5251 100.821 84.4506 100.918 84.3559C101.015 84.2613 101.093 84.1485 101.146 84.0239C101.2 83.8993 101.228 83.7654 101.23 83.6298C101.232 83.4942 101.207 83.3595 101.157 83.2336C101.107 83.1076 101.032 82.9928 100.937 82.8956C100.843 82.7985 100.73 82.7209 100.605 82.6674V82.6674Z"
      fill="#1F4739"
    />
    <path
      d="M69.2287 97.9888L44.4688 103.559C43.8054 103.709 43.2286 104.116 42.865 104.691C42.5013 105.266 42.3805 105.961 42.529 106.625L49.3185 136.803L78.4099 130.258C78.649 130.205 78.8677 130.085 79.04 129.911L72.2946 99.9286C72.1446 99.2652 71.7375 98.6884 71.1627 98.3247C70.588 97.9611 69.8924 97.8403 69.2287 97.9888Z"
      fill="#D2D2D2"
    />
    <path
      d="M60.2347 102.144L46.6656 105.197C46.0615 105.333 45.5362 105.704 45.205 106.227C44.8737 106.751 44.7635 107.384 44.8986 107.988L51.2819 136.361L77.1194 130.549L72.4706 109.886C71.8718 107.238 70.2472 104.936 67.9533 103.485C65.6595 102.034 62.8836 101.551 60.2347 102.144V102.144Z"
      fill="white"
    />
    <path
      d="M72.1507 129.019L55.1168 132.851C55.0438 132.868 54.9747 132.898 54.9135 132.941C54.8523 132.984 54.8002 133.039 54.7601 133.102C54.72 133.165 54.6928 133.236 54.68 133.309C54.6672 133.383 54.6691 133.459 54.6855 133.532C54.7019 133.605 54.7326 133.674 54.7757 133.735C54.8189 133.796 54.8736 133.848 54.9369 133.888C55.0002 133.928 55.0707 133.955 55.1444 133.968C55.2182 133.98 55.2937 133.978 55.3667 133.962L72.4005 130.129C72.4736 130.113 72.5427 130.083 72.604 130.04C72.6653 129.997 72.7175 129.942 72.7576 129.879C72.7977 129.816 72.825 129.745 72.8378 129.671C72.8506 129.598 72.8487 129.522 72.8323 129.449C72.8159 129.376 72.7852 129.307 72.742 129.246C72.6988 129.185 72.644 129.133 72.5807 129.093C72.5174 129.053 72.4468 129.026 72.373 129.013C72.2992 129 72.2237 129.002 72.1507 129.019V129.019Z"
      fill="#D2D2D2"
    />
    <path
      d="M71.6567 126.823L54.6228 130.656C54.4754 130.689 54.3473 130.779 54.2665 130.907C54.1857 131.034 54.159 131.189 54.1921 131.336C54.2253 131.484 54.3156 131.612 54.4433 131.693C54.571 131.773 54.7255 131.8 54.8729 131.767L71.9067 127.935C72.0541 127.902 72.1823 127.811 72.2631 127.684C72.3439 127.556 72.3706 127.401 72.3374 127.254C72.3043 127.107 72.2139 126.978 72.0863 126.898C71.9586 126.817 71.8041 126.79 71.6567 126.823V126.823Z"
      fill="#D2D2D2"
    />
    <path
      d="M61.5131 126.8L54.1292 128.461C54.0563 128.477 53.9873 128.508 53.9263 128.551C53.8652 128.594 53.8132 128.649 53.7732 128.712C53.7333 128.775 53.7061 128.845 53.6934 128.919C53.6806 128.993 53.6825 129.068 53.6989 129.141C53.7153 129.214 53.7459 129.283 53.789 129.344C53.832 129.405 53.8867 129.457 53.9499 129.497C54.013 129.537 54.0834 129.564 54.1571 129.577C54.2307 129.589 54.3061 129.588 54.379 129.571L61.7629 127.91C61.836 127.894 61.9052 127.863 61.9664 127.82C62.0277 127.777 62.0799 127.723 62.12 127.659C62.1601 127.596 62.1874 127.526 62.2002 127.452C62.213 127.378 62.2111 127.303 62.1947 127.23C62.1783 127.157 62.1476 127.087 62.1044 127.026C62.0613 126.965 62.0064 126.913 61.9431 126.873C61.8798 126.833 61.8092 126.806 61.7354 126.794C61.6616 126.781 61.5861 126.783 61.5131 126.8V126.8Z"
      fill="#D2D2D2"
    />
    <path
      d="M70.1286 120.031L53.0948 123.863C53.0218 123.879 52.9527 123.91 52.8916 123.953C52.8305 123.996 52.7784 124.051 52.7384 124.114C52.6576 124.242 52.6309 124.396 52.664 124.544C52.6972 124.691 52.7876 124.819 52.9152 124.9C53.0429 124.981 53.1974 125.008 53.3448 124.975L70.3787 121.142C70.5261 121.109 70.6542 121.019 70.735 120.891C70.8158 120.763 70.8425 120.609 70.8094 120.462C70.7762 120.314 70.6859 120.186 70.5582 120.105C70.4305 120.024 70.276 119.998 70.1286 120.031V120.031Z"
      fill="#D2D2D2"
    />
    <path
      d="M69.6348 117.836L52.601 121.668C52.528 121.685 52.4589 121.715 52.3976 121.758C52.3364 121.801 52.2843 121.856 52.2442 121.919C52.2042 121.982 52.1769 122.053 52.1641 122.126C52.1513 122.2 52.1532 122.276 52.1696 122.349C52.1861 122.422 52.2167 122.491 52.2599 122.552C52.303 122.613 52.3578 122.665 52.421 122.705C52.4843 122.745 52.5548 122.772 52.6286 122.785C52.7023 122.797 52.7778 122.795 52.8508 122.779L69.8846 118.946C69.9576 118.93 70.0266 118.9 70.0878 118.857C70.1489 118.813 70.201 118.759 70.241 118.696C70.281 118.632 70.3082 118.562 70.321 118.488C70.3337 118.415 70.3318 118.339 70.3154 118.266C70.299 118.193 70.2684 118.124 70.2253 118.063C70.1822 118.002 70.1275 117.95 70.0643 117.91C70.0011 117.87 69.9306 117.843 69.8569 117.83C69.7832 117.818 69.7078 117.82 69.6348 117.836V117.836Z"
      fill="#D2D2D2"
    />
    <path
      d="M59.4909 117.811L52.107 119.473C51.9596 119.506 51.8314 119.596 51.7506 119.724C51.6698 119.851 51.6431 120.006 51.6762 120.153C51.7094 120.301 51.7998 120.429 51.9274 120.51C52.0551 120.59 52.2096 120.617 52.357 120.584L59.7409 118.923C59.8883 118.89 60.0165 118.799 60.0973 118.672C60.178 118.544 60.2048 118.389 60.1716 118.242C60.1385 118.095 60.0481 117.967 59.9204 117.886C59.7928 117.805 59.6382 117.778 59.4909 117.811Z"
      fill="#D2D2D2"
    />
    <path
      d="M68.1064 111.043L51.0725 114.875C50.9996 114.891 50.9305 114.922 50.8694 114.965C50.8082 115.008 50.7562 115.063 50.7162 115.126C50.6354 115.254 50.6087 115.408 50.6418 115.556C50.675 115.703 50.7653 115.831 50.893 115.912C51.0207 115.993 51.1752 116.019 51.3226 115.986L68.3565 112.154C68.4294 112.138 68.4985 112.107 68.5596 112.064C68.6208 112.021 68.6728 111.966 68.7128 111.903C68.7528 111.84 68.78 111.769 68.7927 111.695C68.8055 111.622 68.8036 111.546 68.7872 111.473C68.7707 111.4 68.7401 111.331 68.697 111.27C68.6539 111.209 68.5992 111.157 68.536 111.117C68.4728 111.077 68.4023 111.05 68.3286 111.037C68.2549 111.024 68.1794 111.026 68.1064 111.043H68.1064Z"
      fill="#D2D2D2"
    />
    <path
      d="M67.6127 108.848L50.5789 112.68C50.5059 112.697 50.4369 112.727 50.3757 112.77C50.3146 112.813 50.2625 112.868 50.2225 112.931C50.1418 113.059 50.115 113.214 50.1482 113.361C50.1813 113.508 50.2717 113.636 50.3994 113.717C50.527 113.798 50.6816 113.825 50.829 113.792L67.8628 109.959C68.0102 109.926 68.1384 109.836 68.2191 109.708C68.2999 109.581 68.3267 109.426 68.2935 109.279C68.2603 109.131 68.17 109.003 68.0423 108.922C67.9146 108.841 67.7601 108.815 67.6127 108.848H67.6127Z"
      fill="#D2D2D2"
    />
    <path
      d="M57.4686 108.823L50.0847 110.484C49.9373 110.517 49.8092 110.608 49.7284 110.736C49.6476 110.863 49.6209 111.018 49.654 111.165C49.6872 111.312 49.7776 111.441 49.9052 111.521C50.0329 111.602 50.1874 111.629 50.3348 111.596L57.7187 109.935C57.7917 109.918 57.8607 109.888 57.9219 109.844C57.983 109.801 58.0351 109.747 58.0751 109.683C58.1151 109.62 58.1422 109.55 58.155 109.476C58.1677 109.402 58.1658 109.327 58.1494 109.254C58.133 109.181 58.1024 109.112 58.0593 109.051C58.0162 108.99 57.9614 108.937 57.8982 108.897C57.835 108.857 57.7645 108.83 57.6908 108.818C57.6171 108.805 57.5416 108.807 57.4686 108.823Z"
      fill="#D2D2D2"
    />
    <path
      d="M34.676 112.279C34.596 112.279 34.5169 112.294 34.443 112.325C34.3691 112.356 34.302 112.4 34.2455 112.457C34.189 112.513 34.1441 112.581 34.1135 112.654C34.0829 112.728 34.0672 112.807 34.0671 112.887C34.0671 112.91 34.0859 159.792 34.0859 159.792C34.1025 159.941 34.1735 160.078 34.2851 160.178C34.3967 160.278 34.5412 160.333 34.6908 160.333H119.474C119.624 160.333 119.768 160.278 119.88 160.178C119.991 160.078 120.062 159.941 120.079 159.792L125.356 112.956C125.366 112.871 125.357 112.784 125.331 112.703C125.305 112.621 125.262 112.546 125.205 112.482C125.148 112.418 125.079 112.366 125 112.331C124.922 112.296 124.837 112.278 124.751 112.279L34.676 112.279Z"
      fill="#262626"
    />
    <path
      d="M40.6589 114.874C39.7987 114.877 38.9741 115.218 38.3632 115.823C37.7523 116.429 37.4043 117.25 37.3943 118.11L37.5002 154.444C37.4964 154.875 37.578 155.303 37.7404 155.702C37.9028 156.102 38.1427 156.465 38.4462 156.771C38.7497 157.077 39.1109 157.32 39.5089 157.486C39.9068 157.652 40.3337 157.737 40.7648 157.737H94.5845C100.757 157.741 106.715 155.47 111.317 151.356C115.92 147.243 118.844 141.578 119.531 135.443L121.44 118.505C121.491 118.048 121.446 117.585 121.306 117.148C121.167 116.71 120.936 116.306 120.63 115.963C120.324 115.621 119.949 115.347 119.529 115.159C119.11 114.971 118.655 114.874 118.196 114.874H40.6589ZM123.134 114.874L123.125 114.664H123.134V114.874Z"
      fill="#1F4739"
    />
    <path
      d="M64.168 142.095H43.3189C43.1035 142.095 42.8968 142.01 42.7445 141.857C42.5922 141.705 42.5066 141.498 42.5066 141.283C42.5066 141.068 42.5922 140.861 42.7445 140.709C42.8968 140.556 43.1035 140.471 43.3189 140.471H64.168C65.2155 140.456 65.226 142.11 64.168 142.095Z"
      fill="#D2D2D2"
    />
    <path
      d="M92.254 146.344H43.3189C43.1035 146.344 42.8968 146.259 42.7445 146.107C42.5922 145.954 42.5066 145.748 42.5066 145.532C42.5066 145.317 42.5922 145.11 42.7445 144.958C42.8968 144.805 43.1035 144.72 43.3189 144.72H92.254C93.3016 144.705 93.3121 146.359 92.254 146.344Z"
      fill="#D2D2D2"
    />
  </svg>
);

export default Icon;
