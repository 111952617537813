import React from 'react';

const Icon = () => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M157.723 116.14H40.3611C39.56 116.139 38.7919 115.821 38.2254 115.255C37.6589 114.689 37.3403 113.922 37.3394 113.122V40.8472C37.34 40.2611 37.5735 39.6991 37.9885 39.2846C38.4035 38.8701 38.9662 38.6369 39.5531 38.6362H158.493C159.09 38.6369 159.662 38.8741 160.084 39.2958C160.507 39.7174 160.744 40.2891 160.745 40.8855V113.122C160.744 113.922 160.425 114.689 159.859 115.255C159.292 115.821 158.524 116.139 157.723 116.14Z"
      fill="#A6BFB6"
    />
    <path
      d="M153.442 111.866H44.0635C42.4602 111.866 41.1558 110.646 41.1558 109.147V46.2087C41.1558 45.0967 42.1224 44.1919 43.3105 44.1919H154.159C155.367 44.1919 156.35 45.1116 156.35 46.242V109.147C156.35 110.646 155.045 111.866 153.442 111.866Z"
      fill="white"
    />
    <path
      d="M160.703 40.6746H37.297V39.1381C37.2981 38.3353 37.6181 37.5658 38.1866 36.9983C38.7552 36.4308 39.5259 36.1117 40.3297 36.1111H157.67C158.474 36.1117 159.244 36.4308 159.813 36.9983C160.382 37.5658 160.702 38.3353 160.703 39.1381V40.6746Z"
      fill="#5E8376"
    />
    <path
      d="M42.8086 39.1808C43.2418 39.1808 43.593 38.83 43.593 38.3973C43.593 37.9647 43.2418 37.6139 42.8086 37.6139C42.3753 37.6139 42.0241 37.9647 42.0241 38.3973C42.0241 38.83 42.3753 39.1808 42.8086 39.1808Z"
      fill="white"
    />
    <path
      d="M45.7862 39.1808C46.2194 39.1808 46.5706 38.83 46.5706 38.3973C46.5706 37.9647 46.2194 37.6139 45.7862 37.6139C45.3529 37.6139 45.0017 37.9647 45.0017 38.3973C45.0017 38.83 45.3529 39.1808 45.7862 39.1808Z"
      fill="white"
    />
    <path
      d="M48.7638 39.1808C49.197 39.1808 49.5482 38.83 49.5482 38.3973C49.5482 37.9647 49.197 37.6139 48.7638 37.6139C48.3305 37.6139 47.9793 37.9647 47.9793 38.3973C47.9793 38.83 48.3305 39.1808 48.7638 39.1808Z"
      fill="white"
    />
    <path
      d="M141.402 101.31H56.6392C55.7895 101.309 54.9749 100.972 54.3741 100.372C53.7732 99.7718 53.4353 98.9582 53.4343 98.1096V57.2941C53.435 56.6594 53.6878 56.0509 54.1371 55.6021C54.5865 55.1533 55.1957 54.9009 55.8312 54.9001H142.172C142.818 54.9009 143.437 55.1574 143.893 55.6133C144.35 56.0693 144.607 56.6875 144.607 57.3323V98.1096C144.606 98.9582 144.268 99.7718 143.668 100.372C143.067 100.972 142.252 101.309 141.402 101.31ZM55.8312 55.266C55.2928 55.2666 54.7767 55.4804 54.396 55.8607C54.0153 56.2409 53.8012 56.7564 53.8006 57.2941V98.1096C53.8014 98.8612 54.1008 99.5818 54.6329 100.113C55.1651 100.645 55.8866 100.944 56.6392 100.945H141.402C142.155 100.944 142.877 100.645 143.409 100.113C143.941 99.5818 144.24 98.8612 144.241 98.1096V57.3323C144.241 56.7844 144.022 56.2592 143.634 55.8719C143.247 55.4845 142.721 55.2666 142.172 55.266H55.8312Z"
      fill="#BCBCBC"
    />
    <path
      d="M59.5694 98.1096H138.472C139.249 98.1096 139.995 97.8013 140.544 97.2524C141.094 96.7036 141.402 95.9593 141.402 95.1831V61.0629C141.402 60.2868 141.094 59.5424 140.544 58.9936C139.995 58.4448 139.249 58.1365 138.472 58.1365H59.5694C58.7923 58.1365 58.047 58.4448 57.4975 58.9936C56.9479 59.5424 56.6392 60.2868 56.6392 61.0629V95.1831C56.6392 95.9593 56.9479 96.7036 57.4975 97.2524C58.047 97.8013 58.7923 98.1096 59.5694 98.1096Z"
      fill="#F0F5F3"
    />
    <path
      d="M68.5284 68.0634C69.4387 68.0634 70.1766 67.3264 70.1766 66.4172C70.1766 65.5081 69.4387 64.7711 68.5284 64.7711C67.6181 64.7711 66.8802 65.5081 66.8802 66.4172C66.8802 67.3264 67.6181 68.0634 68.5284 68.0634Z"
      fill="#BCBCBC"
    />
    <path
      d="M68.5284 74.2821C69.4387 74.2821 70.1766 73.5451 70.1766 72.636C70.1766 71.7269 69.4387 70.9899 68.5284 70.9899C67.6181 70.9899 66.8802 71.7269 66.8802 72.636C66.8802 73.5451 67.6181 74.2821 68.5284 74.2821Z"
      fill="#BCBCBC"
    />
    <path
      d="M68.5284 80.5008C69.4387 80.5008 70.1766 79.7638 70.1766 78.8546C70.1766 77.9455 69.4387 77.2085 68.5284 77.2085C67.6181 77.2085 66.8802 77.9455 66.8802 78.8546C66.8802 79.7638 67.6181 80.5008 68.5284 80.5008Z"
      fill="#BCBCBC"
    />
    <path
      d="M68.5284 86.7195C69.4387 86.7195 70.1766 85.9825 70.1766 85.0734C70.1766 84.1642 69.4387 83.4272 68.5284 83.4272C67.6181 83.4272 66.8802 84.1642 66.8802 85.0734C66.8802 85.9825 67.6181 86.7195 68.5284 86.7195Z"
      fill="#BCBCBC"
    />
    <path
      d="M130.978 66.6002H76.7696C76.721 66.6002 76.6744 66.5809 76.6401 66.5466C76.6058 66.5123 76.5865 66.4658 76.5865 66.4173C76.5865 66.3688 76.6058 66.3222 76.6401 66.2879C76.6744 66.2536 76.721 66.2344 76.7696 66.2344H130.978C131.027 66.2344 131.074 66.2536 131.108 66.2879C131.142 66.3222 131.162 66.3688 131.162 66.4173C131.162 66.4658 131.142 66.5123 131.108 66.5466C131.074 66.5809 131.027 66.6002 130.978 66.6002Z"
      fill="#BCBCBC"
    />
    <path
      d="M130.978 72.8188H76.7696C76.721 72.8188 76.6744 72.7995 76.6401 72.7652C76.6058 72.7309 76.5865 72.6844 76.5865 72.6359C76.5865 72.5874 76.6058 72.5409 76.6401 72.5066C76.6744 72.4723 76.721 72.453 76.7696 72.453H130.978C131.027 72.453 131.074 72.4723 131.108 72.5066C131.142 72.5409 131.162 72.5874 131.162 72.6359C131.162 72.6844 131.142 72.7309 131.108 72.7652C131.074 72.7995 131.027 72.8188 130.978 72.8188Z"
      fill="#BCBCBC"
    />
    <path
      d="M130.978 79.0376H76.7696C76.721 79.0376 76.6744 79.0183 76.6401 78.984C76.6058 78.9497 76.5865 78.9032 76.5865 78.8547C76.5865 78.8062 76.6058 78.7596 76.6401 78.7253C76.6744 78.691 76.721 78.6718 76.7696 78.6718H130.978C131.027 78.6718 131.074 78.691 131.108 78.7253C131.142 78.7596 131.162 78.8062 131.162 78.8547C131.162 78.9032 131.142 78.9497 131.108 78.984C131.074 79.0183 131.027 79.0376 130.978 79.0376Z"
      fill="#BCBCBC"
    />
    <path
      d="M130.978 85.2563H76.7696C76.721 85.2563 76.6744 85.237 76.6401 85.2027C76.6058 85.1684 76.5865 85.1219 76.5865 85.0734C76.5865 85.0249 76.6058 84.9784 76.6401 84.9441C76.6744 84.9098 76.721 84.8905 76.7696 84.8905H130.978C131.027 84.8905 131.074 84.9098 131.108 84.9441C131.142 84.9784 131.162 85.0249 131.162 85.0734C131.162 85.1219 131.142 85.1684 131.108 85.2027C131.074 85.237 131.027 85.2563 130.978 85.2563Z"
      fill="#BCBCBC"
    />
    <path
      d="M87.0253 91.4749H81.7143C81.4716 91.4747 81.2388 91.3782 81.0672 91.2068C80.8955 91.0353 80.7989 90.8029 80.7986 90.5604V85.622C80.7989 85.3796 80.8955 85.1471 81.0672 84.9757C81.2388 84.8042 81.4716 84.7078 81.7143 84.7075H87.0253C87.2681 84.7078 87.5009 84.8042 87.6725 84.9757C87.8442 85.1471 87.9408 85.3796 87.941 85.622V90.5604C87.9408 90.8029 87.8442 91.0353 87.6725 91.2068C87.5009 91.3782 87.2681 91.4747 87.0253 91.4749Z"
      fill="#1F4739"
    />
    <path
      d="M98.9293 91.475H93.6183C93.3755 91.4747 93.1428 91.3783 92.9711 91.2068C92.7994 91.0354 92.7029 90.8029 92.7026 90.5605V79.4034C92.7029 79.1609 92.7994 78.9285 92.9711 78.7571C93.1428 78.5856 93.3755 78.4892 93.6183 78.4889H98.9293C99.1721 78.4892 99.4048 78.5856 99.5765 78.7571C99.7481 78.9285 99.8447 79.1609 99.845 79.4034V90.5605C99.8447 90.8029 99.7481 91.0354 99.5765 91.2068C99.4048 91.3783 99.1721 91.4747 98.9293 91.475Z"
      fill="#1F4739"
    />
    <path
      d="M111.016 91.475H105.705C105.463 91.4747 105.23 91.3783 105.058 91.2068C104.887 91.0354 104.79 90.8029 104.79 90.5605V73.1847C104.79 72.9422 104.887 72.7098 105.058 72.5383C105.23 72.3669 105.463 72.2704 105.705 72.2701H111.016C111.259 72.2704 111.492 72.3669 111.664 72.5383C111.835 72.7098 111.932 72.9422 111.932 73.1847V90.5605C111.932 90.8029 111.835 91.0354 111.664 91.2068C111.492 91.3783 111.259 91.4747 111.016 91.475Z"
      fill="#1F4739"
    />
    <path
      d="M123.47 91.475H118.159C117.916 91.4747 117.683 91.3783 117.512 91.2068C117.34 91.0354 117.243 90.8029 117.243 90.5605V67.1489C117.243 66.9064 117.34 66.674 117.512 66.5025C117.683 66.3311 117.916 66.2347 118.159 66.2344H123.47C123.713 66.2347 123.945 66.3311 124.117 66.5025C124.289 66.674 124.385 66.9064 124.385 67.1489V90.5605C124.385 90.8029 124.289 91.0354 124.117 91.2068C123.945 91.3783 123.713 91.4747 123.47 91.475Z"
      fill="#1F4739"
    />
    <path
      d="M130.978 91.4749H76.7696C76.721 91.4749 76.6744 91.4557 76.6401 91.4214C76.6058 91.3871 76.5865 91.3405 76.5865 91.292C76.5865 91.2435 76.6058 91.197 76.6401 91.1627C76.6744 91.1284 76.721 91.1091 76.7696 91.1091H130.978C131.027 91.1091 131.074 91.1284 131.108 91.1627C131.142 91.197 131.162 91.2435 131.162 91.292C131.162 91.3405 131.142 91.3871 131.108 91.4214C131.074 91.4557 131.027 91.4749 130.978 91.4749Z"
      fill="#585858"
    />
    <path
      d="M154.795 125.595C154.943 125.371 155.139 125.182 155.369 125.041C155.598 124.901 155.856 124.812 156.123 124.782C156.391 124.751 156.662 124.78 156.917 124.865C157.172 124.951 157.406 125.091 157.601 125.276L163.416 122.279L163.055 125.656L157.588 127.985C157.254 128.291 156.82 128.465 156.367 128.473C155.913 128.481 155.473 128.323 155.129 128.029C154.784 127.734 154.56 127.324 154.499 126.875C154.437 126.427 154.543 125.971 154.795 125.595Z"
      fill="#FFC9A8"
    />
    <path
      d="M159.799 126.855C159.667 126.751 159.57 126.61 159.52 126.45L159.057 124.958C159.008 124.796 159.009 124.623 159.061 124.462C159.113 124.301 159.213 124.16 159.348 124.058L164.062 120.502L169.167 111.044C169.38 110.769 169.646 110.538 169.949 110.366C170.252 110.194 170.586 110.083 170.932 110.039C171.278 109.996 171.629 110.021 171.965 110.113C172.302 110.205 172.616 110.362 172.892 110.576C173.167 110.789 173.398 111.055 173.57 111.357C173.743 111.66 173.854 111.993 173.898 112.339C173.941 112.684 173.916 113.035 173.824 113.371C173.732 113.707 173.574 114.021 173.36 114.296L170.456 120.418C169.575 122.276 168.048 123.751 166.159 124.569L160.635 126.962C160.529 127.008 160.414 127.031 160.298 127.03C160.183 127.029 160.068 127.003 159.963 126.955C159.905 126.928 159.85 126.894 159.799 126.855Z"
      fill="#0B3325"
    />
    <path
      d="M173.489 107.096C175.973 107.096 177.987 105.085 177.987 102.604C177.987 100.123 175.973 98.1116 173.489 98.1116C171.005 98.1116 168.991 100.123 168.991 102.604C168.991 105.085 171.005 107.096 173.489 107.096Z"
      fill="#FFC9A8"
    />
    <path
      d="M171.664 161.535L169.419 161.534L168.351 152.885L171.665 152.885L171.664 161.535Z"
      fill="#FFC9A8"
    />
    <path
      d="M167.815 160.894H172.145V163.616H165.089C165.089 163.259 165.159 162.905 165.296 162.574C165.433 162.244 165.634 161.944 165.887 161.691C166.14 161.438 166.441 161.238 166.772 161.101C167.103 160.964 167.457 160.894 167.815 160.894Z"
      fill="#262626"
    />
    <path
      d="M178.99 161.535L176.745 161.535L175.677 152.885L178.99 152.886L178.99 161.535Z"
      fill="#FFC9A8"
    />
    <path
      d="M175.141 160.894H179.471V163.617H172.414C172.414 163.259 172.485 162.905 172.622 162.575C172.759 162.245 172.96 161.944 173.213 161.692C173.466 161.439 173.767 161.238 174.097 161.101C174.428 160.964 174.783 160.894 175.141 160.894Z"
      fill="#262626"
    />
    <path
      d="M179.154 159.34L176.523 159.34C176.341 159.34 176.165 159.28 176.022 159.17C175.878 159.06 175.774 158.906 175.727 158.731L171.94 144.702C171.934 144.68 171.92 144.661 171.901 144.649C171.882 144.637 171.859 144.632 171.837 144.636C171.814 144.639 171.794 144.651 171.78 144.669C171.766 144.687 171.758 144.709 171.76 144.731L172.525 158.036C172.532 158.154 172.513 158.272 172.47 158.381C172.428 158.491 172.362 158.591 172.277 158.673C172.193 158.755 172.092 158.818 171.981 158.858C171.869 158.898 171.751 158.913 171.634 158.904L168.676 158.657C168.487 158.641 168.309 158.56 168.172 158.428C168.035 158.296 167.949 158.121 167.926 157.932L165.232 129.995L166.274 130.619L176.041 128.963L179.971 158.408C179.986 158.525 179.977 158.643 179.943 158.755C179.909 158.867 179.851 158.971 179.774 159.059C179.696 159.147 179.601 159.218 179.494 159.267C179.387 159.315 179.271 159.34 179.154 159.34H179.154Z"
      fill="#262626"
    />
    <path
      d="M168.498 132.944C167.421 132.935 166.365 132.638 165.442 132.084C165.299 132 165.186 131.875 165.116 131.725C165.046 131.575 165.023 131.408 165.05 131.245C165.394 129.144 167.19 118.367 168.824 112.492C169.49 110.099 170.962 108.81 173.199 108.661C176.471 108.447 179.38 111.004 179.683 114.368C180.217 120.303 178.047 128.735 177.089 132.085C177.052 132.214 176.983 132.333 176.89 132.43C176.796 132.526 176.68 132.599 176.552 132.64C176.424 132.682 176.288 132.692 176.155 132.668C176.022 132.645 175.897 132.59 175.791 132.507L173.838 130.99C173.718 130.898 173.57 130.852 173.419 130.858C173.268 130.864 173.123 130.923 173.011 131.024C171.366 132.49 169.802 132.944 168.498 132.944Z"
      fill="#0B3325"
    />
    <path
      opacity="0.2"
      d="M174.956 117.272L176.604 124.223L174.223 130.075L175.871 124.406L174.956 117.272Z"
      fill="#262626"
    />
    <path
      d="M176.055 106.298C176.455 106.305 176.853 106.234 177.225 106.087C177.389 106.024 177.559 105.972 177.722 105.909C179.166 105.352 180.117 103.817 180.151 102.272C180.185 100.727 179.384 99.2269 178.194 98.2385C177.005 97.2502 175.464 96.7427 173.921 96.6253C172.259 96.4989 170.387 96.9197 169.459 98.302C169.211 98.67 169.038 99.1164 169.185 99.5607C169.229 99.6947 169.307 99.815 169.412 99.9093C169.827 100.278 170.24 100.001 170.663 99.9763C171.244 99.9427 171.766 100.412 171.954 100.963C172.141 101.513 172.059 102.119 171.907 102.68C171.792 103.018 171.719 103.369 171.691 103.725C171.679 103.903 171.712 104.082 171.788 104.244C171.865 104.405 171.981 104.545 172.126 104.649C172.489 104.869 172.966 104.742 173.33 104.523C173.693 104.304 174.007 104 174.4 103.839C174.792 103.678 175.319 103.713 175.548 104.071C175.62 104.2 175.67 104.342 175.693 104.489C175.898 105.421 175.851 105.366 176.055 106.298Z"
      fill="#262626"
    />
    <path
      d="M169.084 155.26L140.918 145.464C140.434 145.295 140.038 144.942 139.815 144.481C139.592 144.021 139.561 143.491 139.729 143.008L147.048 122.018C147.172 121.665 147.431 121.375 147.769 121.212C148.107 121.049 148.495 121.026 148.85 121.149L177.968 131.276C178.328 131.402 178.623 131.665 178.789 132.008C178.956 132.352 178.979 132.746 178.853 133.106L171.542 154.073C171.373 154.556 171.02 154.952 170.559 155.174C170.098 155.397 169.567 155.428 169.084 155.26Z"
      fill="#D2D2D2"
    />
    <path
      d="M143.321 144.257L167.95 152.824C168.419 152.987 168.934 152.957 169.381 152.741C169.828 152.525 170.171 152.141 170.334 151.672L176.245 134.723C176.408 134.255 176.378 133.741 176.162 133.294C175.946 132.848 175.561 132.505 175.092 132.342L150.463 123.776C149.994 123.613 149.479 123.642 149.032 123.858C148.585 124.074 148.242 124.459 148.079 124.927L142.169 141.876C142.088 142.108 142.053 142.354 142.068 142.599C142.082 142.844 142.144 143.084 142.251 143.305C142.358 143.526 142.508 143.724 142.691 143.887C142.875 144.051 143.089 144.176 143.321 144.257Z"
      fill="white"
    />
    <path
      d="M149.506 132.349L148.286 131.925C148.22 131.902 148.166 131.854 148.135 131.791C148.105 131.728 148.101 131.655 148.124 131.589L148.548 130.371C148.571 130.305 148.62 130.251 148.683 130.22C148.746 130.19 148.818 130.186 148.885 130.208L150.104 130.633C150.171 130.656 150.225 130.704 150.255 130.767C150.286 130.83 150.29 130.903 150.267 130.969L149.842 132.187C149.819 132.253 149.771 132.307 149.708 132.338C149.645 132.368 149.572 132.372 149.506 132.349Z"
      fill="#1F4739"
    />
    <path
      d="M148.405 135.505L147.186 135.081C147.119 135.058 147.065 135.01 147.035 134.947C147.004 134.884 147 134.811 147.023 134.745L147.448 133.527C147.471 133.461 147.519 133.407 147.582 133.376C147.645 133.346 147.718 133.342 147.784 133.364L149.004 133.789C149.07 133.812 149.124 133.86 149.155 133.923C149.185 133.986 149.189 134.059 149.166 134.125L148.742 135.343C148.719 135.409 148.67 135.463 148.607 135.494C148.544 135.524 148.471 135.528 148.405 135.505Z"
      fill="#A6BFB6"
    />
    <path
      d="M147.305 138.661L146.085 138.237C146.019 138.214 145.965 138.166 145.934 138.103C145.904 138.04 145.899 137.967 145.922 137.901L146.347 136.683C146.37 136.617 146.419 136.563 146.482 136.532C146.545 136.502 146.617 136.498 146.683 136.521L147.903 136.945C147.969 136.968 148.024 137.016 148.054 137.079C148.085 137.142 148.089 137.215 148.066 137.281L147.641 138.499C147.618 138.565 147.57 138.619 147.506 138.65C147.443 138.68 147.371 138.684 147.305 138.661Z"
      fill="#BCBCBC"
    />
    <path
      d="M157.702 134.517L151.825 132.473C151.792 132.461 151.762 132.444 151.736 132.421C151.71 132.398 151.689 132.37 151.674 132.338C151.658 132.307 151.65 132.273 151.648 132.239C151.646 132.204 151.65 132.169 151.662 132.137C151.673 132.104 151.691 132.074 151.714 132.048C151.737 132.022 151.765 132.001 151.797 131.986C151.828 131.971 151.862 131.962 151.897 131.96C151.931 131.958 151.966 131.963 151.999 131.974L157.876 134.018C157.909 134.03 157.939 134.047 157.965 134.071C157.991 134.094 158.012 134.121 158.027 134.153C158.042 134.184 158.051 134.218 158.053 134.253C158.055 134.287 158.051 134.322 158.039 134.355C158.028 134.387 158.01 134.418 157.987 134.444C157.964 134.469 157.936 134.491 157.904 134.506C157.873 134.521 157.839 134.529 157.804 134.531C157.77 134.533 157.735 134.528 157.702 134.517Z"
      fill="#BCBCBC"
    />
    <path
      d="M156.601 137.673L150.724 135.629C150.658 135.606 150.604 135.558 150.573 135.495C150.543 135.432 150.539 135.359 150.562 135.293C150.585 135.227 150.633 135.173 150.696 135.142C150.759 135.112 150.832 135.108 150.898 135.131L156.775 137.175C156.808 137.186 156.838 137.204 156.864 137.227C156.89 137.25 156.911 137.278 156.927 137.309C156.942 137.34 156.951 137.374 156.953 137.409C156.955 137.443 156.95 137.478 156.938 137.511C156.927 137.544 156.909 137.574 156.886 137.6C156.863 137.626 156.835 137.647 156.804 137.662C156.772 137.677 156.738 137.686 156.704 137.688C156.669 137.689 156.634 137.685 156.601 137.673Z"
      fill="#BCBCBC"
    />
    <path
      d="M155.501 140.829L149.624 138.785C149.558 138.762 149.503 138.714 149.473 138.651C149.442 138.588 149.438 138.515 149.461 138.449C149.484 138.383 149.533 138.329 149.596 138.299C149.659 138.268 149.732 138.264 149.798 138.287L155.675 140.331C155.741 140.354 155.795 140.402 155.826 140.465C155.856 140.528 155.86 140.601 155.837 140.667C155.814 140.733 155.766 140.787 155.703 140.818C155.64 140.848 155.567 140.852 155.501 140.829Z"
      fill="#BCBCBC"
    />
    <path
      d="M171.141 142.539C171.183 142.437 171.224 142.332 171.261 142.226C171.744 140.841 171.656 139.321 171.017 138C170.378 136.679 169.239 135.667 167.852 135.184L165.921 140.724L171.141 142.539Z"
      fill="#1F4739"
    />
    <path
      d="M165.359 141.061L167.291 135.521C166.27 135.166 165.167 135.117 164.119 135.38C163.07 135.644 162.122 136.207 161.391 137.003C160.66 137.798 160.177 138.789 160.003 139.855C159.829 140.92 159.972 142.013 160.412 142.999C160.853 143.985 161.573 144.82 162.484 145.402C163.394 145.984 164.455 146.287 165.536 146.274C166.617 146.261 167.67 145.933 168.566 145.329C169.463 144.725 170.162 143.872 170.579 142.876L165.359 141.061Z"
      fill="#A6BFB6"
    />
    <path
      d="M172.274 133.114C172.253 132.846 172.29 132.577 172.384 132.325C172.477 132.073 172.625 131.844 172.817 131.655C173.009 131.467 173.239 131.322 173.493 131.232C173.747 131.142 174.017 131.109 174.285 131.134L177.009 125.191L178.811 128.071L175.948 133.273C175.875 133.72 175.641 134.125 175.289 134.411C174.938 134.696 174.493 134.843 174.041 134.824C173.588 134.804 173.158 134.619 172.832 134.304C172.507 133.989 172.308 133.566 172.274 133.114Z"
      fill="#FFC9A8"
    />
    <path
      d="M176.99 131.021C176.822 131.021 176.659 130.97 176.521 130.875L175.235 129.986C175.095 129.889 174.99 129.752 174.931 129.594C174.872 129.435 174.864 129.262 174.907 129.098L176.419 123.395L174.591 112.807C174.589 112.458 174.656 112.113 174.788 111.79C174.919 111.467 175.113 111.174 175.359 110.926C175.604 110.678 175.896 110.481 176.218 110.346C176.539 110.211 176.884 110.141 177.233 110.139C177.582 110.138 177.928 110.205 178.251 110.336C178.574 110.468 178.868 110.662 179.115 110.908C179.363 111.153 179.56 111.445 179.695 111.766C179.83 112.088 179.9 112.432 179.901 112.781L181.398 119.388C181.853 121.393 181.563 123.495 180.582 125.303L177.714 130.59C177.659 130.692 177.583 130.781 177.491 130.851C177.399 130.922 177.294 130.972 177.181 130.999C177.119 131.014 177.055 131.021 176.99 131.021Z"
      fill="#0B3325"
    />
    <path
      d="M190.928 163.719H156.132C156.083 163.719 156.037 163.7 156.002 163.666C155.968 163.631 155.949 163.585 155.949 163.536C155.949 163.488 155.968 163.441 156.002 163.407C156.037 163.373 156.083 163.353 156.132 163.353H190.928C190.977 163.353 191.023 163.373 191.057 163.407C191.092 163.441 191.111 163.488 191.111 163.536C191.111 163.585 191.092 163.631 191.057 163.666C191.023 163.7 190.977 163.719 190.928 163.719Z"
      fill="#BCBCBC"
    />
    <path
      d="M98.7255 161.444L100.971 161.443L102.039 152.794H98.7252L98.7255 161.444Z"
      fill="#FFC9A8"
    />
    <path
      d="M98.1529 160.711L102.575 160.711H102.575C102.945 160.711 103.311 160.784 103.653 160.925C103.995 161.067 104.306 161.274 104.567 161.535C104.829 161.797 105.036 162.107 105.178 162.448C105.32 162.79 105.393 163.156 105.393 163.525V163.617L98.153 163.617L98.1529 160.711Z"
      fill="#262626"
    />
    <path
      d="M89.7518 161.444L91.9971 161.443L93.0651 152.794H89.7514L89.7518 161.444Z"
      fill="#FFC9A8"
    />
    <path
      d="M89.1791 160.711L93.6007 160.711H93.6009C93.971 160.711 94.3374 160.784 94.6793 160.925C95.0212 161.067 95.3318 161.274 95.5935 161.535C95.8551 161.797 96.0627 162.107 96.2043 162.448C96.3459 162.79 96.4188 163.156 96.4188 163.525V163.617L89.1792 163.617L89.1791 160.711Z"
      fill="#262626"
    />
    <path
      d="M99.1737 101.564C101.658 101.564 103.672 99.5532 103.672 97.0721C103.672 94.5911 101.658 92.5798 99.1737 92.5798C96.6895 92.5798 94.6757 94.5911 94.6757 97.0721C94.6757 99.5532 96.6895 101.564 99.1737 101.564Z"
      fill="#FFC9A8"
    />
    <path
      d="M102.198 159.577H98.032C97.9229 159.577 97.8149 159.555 97.7143 159.513C97.6137 159.471 97.5224 159.41 97.4457 159.332C97.3691 159.255 97.3086 159.163 97.2678 159.062C97.227 158.961 97.2066 158.853 97.2079 158.744L97.45 138.984C97.4509 138.914 97.4248 138.846 97.3772 138.795C97.3295 138.743 97.2638 138.712 97.1937 138.707C97.1236 138.703 97.0543 138.725 97.0002 138.77C96.9461 138.814 96.9111 138.878 96.9026 138.948L94.4778 158.853C94.4533 159.053 94.3566 159.236 94.2059 159.37C94.0552 159.503 93.861 159.577 93.6597 159.577H89.4532C89.3395 159.577 89.2271 159.553 89.123 159.508C89.0189 159.462 88.9253 159.396 88.8481 159.313C88.7709 159.229 88.7119 159.131 88.6746 159.024C88.6373 158.916 88.6227 158.803 88.6315 158.69L91.4093 123.193L91.4848 123.184L104.786 121.75L103.022 158.793C103.012 159.004 102.921 159.204 102.767 159.35C102.614 159.496 102.41 159.577 102.198 159.577Z"
      fill="#262626"
    />
    <path
      d="M91.5712 125.372C91.3575 125.371 91.1524 125.288 90.9991 125.139C90.8459 124.99 90.7566 124.788 90.75 124.575C90.6774 122.33 90.553 114.564 91.9994 108.862C92.3941 107.305 93.2802 105.915 94.5266 104.9C95.773 103.884 97.3136 103.295 98.9207 103.222C100.502 103.135 102.071 103.549 103.404 104.403C104.738 105.257 105.768 106.509 106.348 107.981C108.78 114.146 111.077 121.744 108.213 122.936C104.46 124.498 94.5649 125.194 91.6217 125.37C91.6048 125.371 91.588 125.372 91.5712 125.372Z"
      fill="#585858"
    />
    <path
      d="M108.763 92.5318C108.833 92.6043 108.91 92.6712 108.991 92.7317L108.519 101.79L106.555 102.794L107.685 105.953L111.335 104.512C111.629 104.396 111.879 104.187 112.044 103.918C112.21 103.648 112.284 103.331 112.255 103.016L111.299 92.7408C111.612 92.5076 111.846 92.1849 111.971 91.8154C112.096 91.446 112.105 91.0473 111.997 90.6726C111.889 90.2978 111.67 89.9646 111.368 89.7174C111.066 89.4701 110.696 89.3205 110.307 89.2885C109.918 89.2564 109.529 89.3434 109.19 89.5379C108.852 89.7324 108.581 90.0251 108.413 90.3772C108.245 90.7293 108.189 91.124 108.251 91.5089C108.314 91.8938 108.492 92.2506 108.763 92.5318Z"
      fill="#FFC9A8"
    />
    <path
      d="M108.556 102.127L109.937 106.049C109.977 106.16 109.992 106.279 109.981 106.397C109.97 106.515 109.934 106.629 109.875 106.731C109.816 106.834 109.736 106.923 109.639 106.991C109.543 107.06 109.433 107.107 109.317 107.13L105.243 107.918C104.67 108.116 104.042 108.078 103.497 107.814C102.952 107.551 102.533 107.081 102.334 106.51C102.134 105.938 102.17 105.311 102.433 104.766C102.696 104.221 103.164 103.802 103.736 103.601L107.394 101.673C107.499 101.618 107.614 101.586 107.732 101.579C107.851 101.572 107.969 101.591 108.079 101.634C108.189 101.678 108.289 101.744 108.371 101.829C108.454 101.914 108.517 102.016 108.556 102.127V102.127Z"
      fill="#585858"
    />
    <path
      d="M89.2791 92.5318C89.2084 92.6043 89.1322 92.6712 89.051 92.7317L89.5231 101.79L91.4871 102.794L90.357 105.953L86.7072 104.512C86.4123 104.396 86.1632 104.187 85.9972 103.918C85.8313 103.648 85.7574 103.331 85.7867 103.016L86.7429 92.7408C86.43 92.5076 86.1956 92.1849 86.0709 91.8154C85.9462 91.446 85.937 91.0473 86.0446 90.6726C86.1523 90.2978 86.3716 89.9646 86.6735 89.7174C86.9753 89.4701 87.3454 89.3205 87.7344 89.2885C88.1235 89.2564 88.5131 89.3434 88.8515 89.5379C89.1898 89.7324 89.4609 90.0251 89.6286 90.3772C89.7962 90.7293 89.8526 91.124 89.7902 91.5089C89.7278 91.8938 89.5495 92.2506 89.2791 92.5318H89.2791Z"
      fill="#FFC9A8"
    />
    <path
      d="M89.9626 101.634C90.0728 101.591 90.1911 101.572 90.3094 101.579C90.4276 101.585 90.543 101.617 90.6477 101.673L94.3057 103.601C94.8773 103.802 95.346 104.221 95.6088 104.766C95.8716 105.311 95.9072 105.938 95.7078 106.51C95.5083 107.081 95.0901 107.55 94.5448 107.814C93.9995 108.078 93.3716 108.115 92.7989 107.918L88.7249 107.13C88.6086 107.107 88.4986 107.06 88.4023 106.991C88.3059 106.922 88.2256 106.834 88.1667 106.731C88.1078 106.629 88.0717 106.514 88.0609 106.397C88.0502 106.279 88.0649 106.16 88.1042 106.049L89.4859 102.127C89.5251 102.016 89.588 101.914 89.6703 101.829C89.7526 101.744 89.8523 101.677 89.9626 101.634Z"
      fill="#585858"
    />
    <path
      d="M100.921 97.8378C100.897 97.2648 101.041 96.6973 101.335 96.2046C101.629 95.712 102.06 95.3155 102.576 95.0636C103.944 94.3587 104.803 92.8551 104.426 91.482C103.944 89.7245 101.549 88.5847 99.6088 89.1894C99.0835 89.3756 98.577 89.6107 98.0959 89.8916L96.828 90.5703C96.2864 90.8411 95.7642 91.149 95.2651 91.4918C94.5176 92.0419 93.9317 92.7825 93.5686 93.6361C93.2055 94.4896 93.0786 95.4249 93.2011 96.3442C93.4508 98.1309 94.6224 99.7695 96.2261 100.891C96.8394 101.349 97.5478 101.664 98.299 101.812C99.0677 101.938 99.9235 101.794 100.486 101.317C101.364 100.571 101.273 99.3212 100.989 98.2777C100.952 98.1337 100.929 97.9864 100.921 97.8378Z"
      fill="#262626"
    />
    <path
      d="M116.208 163.719H81.4115C81.3629 163.719 81.3164 163.7 81.282 163.666C81.2477 163.631 81.2284 163.585 81.2284 163.536C81.2284 163.488 81.2477 163.441 81.282 163.407C81.3164 163.373 81.3629 163.353 81.4115 163.353H116.208C116.256 163.353 116.303 163.373 116.337 163.407C116.372 163.441 116.391 163.488 116.391 163.536C116.391 163.585 116.372 163.631 116.337 163.666C116.303 163.7 116.256 163.719 116.208 163.719Z"
      fill="#BCBCBC"
    />
    <path
      d="M59.4184 130.353L30.7066 142.116C30.2135 142.318 29.6605 142.316 29.169 142.11C28.6776 141.905 28.2877 141.514 28.0849 141.021L19.2958 119.625C19.1481 119.264 19.1496 118.86 19.3002 118.5C19.4508 118.14 19.738 117.855 20.0989 117.707L49.7814 105.545C50.1489 105.395 50.561 105.397 50.9273 105.55C51.2936 105.703 51.5842 105.995 51.7353 106.361L60.5148 127.734C60.7164 128.227 60.7142 128.779 60.5087 129.27C60.3031 129.761 59.9111 130.15 59.4184 130.353Z"
      fill="#D2D2D2"
    />
    <path
      d="M31.7466 139.521L56.8531 129.235C57.3312 129.039 57.7118 128.661 57.9112 128.185C58.1105 127.709 58.1124 127.173 57.9162 126.696L50.819 109.418C50.7219 109.182 50.5791 108.967 50.3988 108.785C50.2185 108.604 50.0042 108.46 49.7681 108.361C49.5321 108.263 49.2789 108.212 49.023 108.211C48.7671 108.21 48.5136 108.259 48.2768 108.356L23.1703 118.643C22.9336 118.74 22.7183 118.883 22.5368 119.063C22.3552 119.243 22.211 119.457 22.1123 119.693C22.0135 119.928 21.9623 120.181 21.9614 120.437C21.9606 120.692 22.0101 120.945 22.1072 121.182L29.2044 138.459C29.3015 138.696 29.4443 138.911 29.6246 139.092C29.8049 139.274 30.0192 139.418 30.2553 139.516C30.4914 139.615 30.7446 139.666 31.0004 139.667C31.2563 139.668 31.5099 139.618 31.7466 139.521Z"
      fill="white"
    />
    <path
      d="M50.6803 114.13L50.3112 114.281L50.3365 114.342L50.7056 114.191L50.6803 114.13Z"
      fill="#D2D2D2"
    />
    <path
      d="M49.5908 114.648L48.8451 114.954L48.8198 114.892L49.5655 114.587L49.5908 114.648ZM48.0994 115.259L47.3537 115.565L47.3285 115.503L48.0741 115.198L48.0994 115.259ZM46.608 115.87L45.8623 116.176L45.8371 116.114L46.5828 115.809L46.608 115.87ZM45.1166 116.481L44.3709 116.787L44.3457 116.725L45.0914 116.42L45.1166 116.481ZM43.6252 117.092L42.8795 117.398L42.8543 117.336L43.6 117.031L43.6252 117.092ZM42.1338 117.703L41.3882 118.009L41.3629 117.947L42.1086 117.642L42.1338 117.703ZM40.6424 118.314L39.8968 118.62L39.8715 118.559L40.6172 118.253L40.6424 118.314ZM39.1511 118.925L38.4054 119.231L38.3801 119.17L39.1258 118.864L39.1511 118.925ZM37.6597 119.537L36.914 119.842L36.8888 119.781L37.6344 119.475L37.6597 119.537ZM36.1683 120.148L35.4226 120.453L35.3974 120.392L36.1431 120.086L36.1683 120.148ZM34.6769 120.759L33.9312 121.064L33.906 121.003L34.6517 120.697L34.6769 120.759ZM33.1855 121.37L32.4398 121.675L32.4146 121.614L33.1603 121.308L33.1855 121.37ZM31.6941 121.981L30.9484 122.286L30.9232 122.225L31.6689 121.919L31.6941 121.981ZM30.2027 122.592L29.457 122.897L29.4318 122.836L30.1775 122.53L30.2027 122.592ZM28.7114 123.203L27.9657 123.508L27.9404 123.447L28.6861 123.141L28.7114 123.203ZM27.22 123.814L26.4743 124.119L26.449 124.058L27.1947 123.752L27.22 123.814Z"
      fill="#D2D2D2"
    />
    <path
      d="M25.7033 124.363L25.3342 124.515L25.3595 124.576L25.7286 124.425L25.7033 124.363Z"
      fill="#D2D2D2"
    />
    <path
      d="M40.9081 124.809L40.539 124.961L40.5642 125.022L40.9334 124.871L40.9081 124.809Z"
      fill="#D2D2D2"
    />
    <path
      d="M39.8296 125.323L39.095 125.624L39.0698 125.563L39.8044 125.262L39.8296 125.323ZM38.3604 125.925L37.6258 126.226L37.6005 126.165L38.3351 125.864L38.3604 125.925ZM36.8911 126.527L36.1565 126.828L36.1313 126.767L36.8659 126.466L36.8911 126.527ZM35.4219 127.129L34.6873 127.43L34.6621 127.369L35.3967 127.068L35.4219 127.129ZM33.9527 127.731L33.218 128.032L33.1928 127.971L33.9274 127.67L33.9527 127.731ZM32.4834 128.333L31.7488 128.634L31.7236 128.573L32.4582 128.272L32.4834 128.333ZM31.0142 128.935L30.2796 129.236L30.2543 129.175L30.989 128.874L31.0142 128.935ZM29.545 129.537L28.8104 129.838L28.7851 129.777L29.5197 129.476L29.545 129.537Z"
      fill="#D2D2D2"
    />
    <path
      d="M28.0505 130.078L27.6814 130.229L27.7066 130.29L28.0758 130.139L28.0505 130.078Z"
      fill="#D2D2D2"
    />
    <path
      d="M34.8208 130.462L34.4517 130.613L34.4769 130.674L34.846 130.523L34.8208 130.462Z"
      fill="#D2D2D2"
    />
    <path
      d="M33.7211 130.984L32.9653 131.294L32.9401 131.233L33.6959 130.923L33.7211 130.984ZM32.2095 131.604L31.4537 131.913L31.4284 131.852L32.1842 131.542L32.2095 131.604ZM30.6979 132.223L29.942 132.533L29.9168 132.471L30.6726 132.162L30.6979 132.223Z"
      fill="#D2D2D2"
    />
    <path
      d="M29.161 132.781L28.7919 132.932L28.8171 132.994L29.1862 132.842L29.161 132.781Z"
      fill="#D2D2D2"
    />
    <path
      d="M36.7159 135.075L36.3468 135.227L36.372 135.288L36.7411 135.137L36.7159 135.075Z"
      fill="#D2D2D2"
    />
    <path
      d="M35.6162 135.598L34.8604 135.907L34.8351 135.846L35.591 135.536L35.6162 135.598ZM34.1046 136.217L33.3487 136.527L33.3235 136.465L34.0793 136.155L34.1046 136.217ZM32.5929 136.836L31.8371 137.146L31.8119 137.084L32.5677 136.775L32.5929 136.836Z"
      fill="#D2D2D2"
    />
    <path
      d="M31.0561 137.394L30.687 137.545L30.7122 137.607L31.0813 137.456L31.0561 137.394Z"
      fill="#D2D2D2"
    />
    <path
      d="M32.8126 136.801L54.0887 128.084C54.6036 127.873 55.0134 127.466 55.2283 126.953C55.4431 126.439 55.4454 125.862 55.2346 125.348L50.9359 114.883L50.8943 114.901L45.6732 127.369L41.8008 125.752C41.7294 125.722 41.6519 125.709 41.5745 125.714C41.4971 125.719 41.4219 125.741 41.3548 125.78C41.2539 125.841 41.1759 125.933 41.1328 126.042L38.3965 132.576L35.5652 131.394C35.5041 131.368 35.4386 131.355 35.3724 131.355C35.3061 131.354 35.2405 131.367 35.1792 131.392C35.118 131.417 35.0622 131.454 35.0153 131.501C34.9683 131.547 34.931 131.603 34.9054 131.664C34.8873 131.708 33.1416 135.986 32.8126 136.801Z"
      fill="#1F4739"
    />
    <path
      d="M32.7471 136.9L32.6858 136.874L34.8751 131.646C34.9331 131.508 35.0435 131.398 35.1822 131.342C35.3208 131.285 35.4763 131.285 35.6146 131.343L38.39 132.502L41.1107 126.004C41.1687 125.866 41.2791 125.757 41.4177 125.7C41.5564 125.643 41.7119 125.644 41.8502 125.701L45.6667 127.295L50.9147 114.762L50.976 114.788L45.7024 127.382L41.8246 125.763C41.7642 125.737 41.6994 125.724 41.6339 125.724C41.5684 125.724 41.5035 125.737 41.4429 125.761C41.3823 125.786 41.3272 125.823 41.2807 125.869C41.2343 125.915 41.1973 125.97 41.1721 126.03L38.4257 132.589L35.589 131.404C35.4669 131.353 35.3297 131.353 35.2074 131.403C35.0851 131.453 34.9876 131.55 34.9365 131.671L32.7471 136.9Z"
      fill="#585858"
    />
    <path
      d="M34.8334 130.958C35.0905 130.958 35.2989 130.749 35.2989 130.493C35.2989 130.236 35.0905 130.028 34.8334 130.028C34.5763 130.028 34.368 130.236 34.368 130.493C34.368 130.749 34.5763 130.958 34.8334 130.958Z"
      fill="#585858"
    />
    <path
      d="M40.9207 125.305C41.1778 125.305 41.3862 125.097 41.3862 124.84C41.3862 124.583 41.1778 124.375 40.9207 124.375C40.6637 124.375 40.4553 124.583 40.4553 124.84C40.4553 125.097 40.6637 125.305 40.9207 125.305Z"
      fill="#585858"
    />
    <path
      d="M50.3993 114.609C50.6564 114.609 50.8648 114.401 50.8648 114.144C50.8648 113.887 50.6564 113.679 50.3993 113.679C50.1423 113.679 49.9339 113.887 49.9339 114.144C49.9339 114.401 50.1423 114.609 50.3993 114.609Z"
      fill="#585858"
    />
    <path
      d="M24.3459 129.965C24.5879 129.845 24.8005 129.673 24.9688 129.462C25.1371 129.251 25.257 129.005 25.3201 128.743C25.3831 128.48 25.3877 128.207 25.3337 127.943C25.2796 127.678 25.1681 127.429 25.007 127.212L26.3895 113.713H23.4019L22.3032 126.894C21.9565 127.188 21.7299 127.599 21.6665 128.049C21.603 128.499 21.707 128.957 21.9588 129.336C22.2105 129.714 22.5926 129.987 23.0326 130.103C23.4726 130.219 23.9399 130.17 24.3459 129.965Z"
      fill="#FFC9A8"
    />
    <path
      d="M27.0891 106.263C29.4235 106.263 31.3159 104.373 31.3159 102.042C31.3159 99.7103 29.4235 97.8203 27.0891 97.8203C24.7546 97.8203 22.8622 99.7103 22.8622 102.042C22.8622 104.373 24.7546 106.263 27.0891 106.263Z"
      fill="#FFC9A8"
    />
    <path
      d="M27.8848 121.574C27.7156 121.574 27.5511 121.519 27.4165 121.416C27.282 121.314 27.1847 121.17 27.1398 121.007C26.8012 119.778 25.9201 117.701 24.5211 114.832C24.2055 114.183 24.0307 113.475 24.0082 112.755C23.9857 112.034 24.1161 111.317 24.3908 110.65C24.6654 109.983 25.0781 109.381 25.6019 108.885C26.1256 108.389 26.7485 108.009 27.4297 107.77C28.0752 107.542 28.7599 107.446 29.4433 107.487C30.1266 107.528 30.7948 107.706 31.4081 108.01C32.0215 108.313 32.5677 108.737 33.0143 109.255C33.4609 109.773 33.7989 110.376 34.0084 111.027C35.0927 114.422 35.0403 118.059 34.9675 119.465C34.9584 119.634 34.8941 119.796 34.7842 119.925C34.6744 120.054 34.5251 120.144 34.3593 120.18L28.0509 121.556C27.9964 121.568 27.9407 121.574 27.8848 121.574Z"
      fill="#2778C4"
    />
    <path
      d="M33.9938 161.66H36.1036L37.1073 153.533L33.9934 153.533L33.9938 161.66Z"
      fill="#FFC9A8"
    />
    <path
      d="M40.1727 163.617L33.5417 163.617L33.5416 161.058L37.6106 161.058C37.947 161.058 38.2802 161.124 38.591 161.253C38.9019 161.381 39.1843 161.57 39.4222 161.807C39.6601 162.045 39.8488 162.327 39.9776 162.638C40.1064 162.948 40.1727 163.281 40.1727 163.617Z"
      fill="#262626"
    />
    <path
      d="M12.8963 158.93L14.7453 159.945L19.5457 153.306L16.8171 151.807L12.8963 158.93Z"
      fill="#FFC9A8"
    />
    <path
      d="M17.3671 163.617L11.5564 160.427L12.7906 158.185L16.3563 160.142C16.9517 160.469 17.3927 161.019 17.5823 161.671C17.7718 162.322 17.6944 163.023 17.3671 163.617Z"
      fill="#262626"
    />
    <path
      d="M33.8952 157.149C33.7072 157.149 33.5257 157.081 33.3845 156.957C33.2433 156.833 33.1521 156.662 33.1279 156.475L30.4893 136.169C30.4777 136.079 30.4379 135.995 30.3757 135.929C30.3134 135.863 30.232 135.819 30.1429 135.802C30.0538 135.785 29.9616 135.796 29.8795 135.835C29.7975 135.874 29.7297 135.937 29.6859 136.016L18.6893 155.942C18.5985 156.105 18.4523 156.229 18.2773 156.294C18.1023 156.358 17.91 156.357 17.7354 156.292L15.3384 155.379C15.242 155.343 15.1539 155.287 15.0793 155.216C15.0047 155.145 14.9451 155.06 14.9039 154.965C14.8628 154.871 14.841 154.769 14.8398 154.666C14.8386 154.563 14.858 154.461 14.8969 154.365L22.5145 135.676C22.5338 135.628 22.5469 135.579 22.5534 135.528C23.5852 127.552 26.2104 123.497 26.9903 122.443C27.0473 122.366 27.0848 122.276 27.0997 122.181C27.1145 122.086 27.1063 121.989 27.0757 121.898L26.9685 121.577C26.9256 121.449 26.9169 121.313 26.943 121.181C26.9691 121.049 27.0293 120.926 27.1175 120.825C29.928 117.627 34.8267 119.372 34.8758 119.39L34.9004 119.399L34.9158 119.42C40.4086 126.943 38.3777 151.379 37.9148 156.201C37.8976 156.382 37.8167 156.552 37.6865 156.679C37.5563 156.806 37.3851 156.884 37.2034 156.897L33.9553 157.147C33.9352 157.149 33.9151 157.149 33.8952 157.149Z"
      fill="#262626"
    />
    <path
      d="M43.5214 106.945C43.5003 107.038 43.4865 107.132 43.4802 107.227L36.0874 111.487L34.2905 110.454L32.3749 112.958L35.378 115.096C35.6206 115.269 35.9128 115.358 36.2106 115.351C36.5085 115.344 36.7959 115.24 37.0298 115.056L44.6531 109.049C44.9971 109.177 45.3717 109.196 45.7272 109.105C46.0826 109.015 46.402 108.818 46.6428 108.542C46.8837 108.266 47.0346 107.922 47.0754 107.558C47.1163 107.194 47.0452 106.826 46.8716 106.504C46.698 106.181 46.4301 105.919 46.1036 105.752C45.7771 105.585 45.4074 105.521 45.0437 105.569C44.68 105.617 44.3395 105.774 44.0676 106.02C43.7957 106.266 43.6052 106.589 43.5214 106.945Z"
      fill="#FFC9A8"
    />
    <path
      d="M35.8403 111.688L33.451 114.781C33.383 114.869 33.2968 114.941 33.1983 114.993C33.0998 115.045 32.9913 115.075 32.8802 115.081C32.7691 115.087 32.658 115.069 32.5544 115.028C32.4508 114.988 32.3573 114.925 32.2801 114.845L29.5752 112.04C29.1264 111.69 28.8348 111.177 28.7644 110.612C28.694 110.048 28.8506 109.479 29.1997 109.03C29.5489 108.58 30.0621 108.288 30.627 108.216C31.1918 108.144 31.7621 108.299 32.2128 108.646L35.6053 110.541C35.7024 110.595 35.7867 110.67 35.8524 110.759C35.918 110.849 35.9636 110.952 35.9859 111.061C36.0082 111.17 36.0067 111.282 35.9816 111.39C35.9565 111.499 35.9083 111.6 35.8403 111.688Z"
      fill="#2778C4"
    />
    <path
      d="M26.5796 117.062L22.7306 116.368C22.6211 116.348 22.5171 116.305 22.4257 116.241C22.3344 116.178 22.2578 116.095 22.2013 116C22.1447 115.904 22.1094 115.797 22.0979 115.687C22.0864 115.576 22.0988 115.464 22.1345 115.359L23.3822 111.669C23.4866 111.11 23.8086 110.615 24.2778 110.293C24.7469 109.971 25.3249 109.847 25.885 109.95C26.445 110.053 26.9414 110.373 27.2654 110.841C27.5893 111.309 27.7143 111.885 27.613 112.445L27.4913 116.325C27.4877 116.436 27.4603 116.545 27.4108 116.645C27.3613 116.744 27.2908 116.832 27.2043 116.902C27.1178 116.972 27.0172 117.022 26.9094 117.05C26.8016 117.077 26.6891 117.081 26.5796 117.062H26.5796Z"
      fill="#2778C4"
    />
    <path
      d="M27.7581 101.071C28.8445 101.813 30.2709 102.577 31.3805 101.786C31.7276 101.522 31.9797 101.153 32.0992 100.734C32.2186 100.315 32.199 99.8683 32.0432 99.4613C31.5119 97.9439 30.0521 97.2759 28.6562 96.7638C26.8416 96.0981 24.8657 95.5685 22.9924 96.0453C21.1191 96.522 19.4635 98.3334 19.8017 100.234C20.0736 101.763 21.5174 103.035 21.3125 104.574C21.1062 106.123 19.362 106.938 17.8221 107.215C16.2823 107.492 14.5591 107.581 13.4525 108.686C12.0408 110.095 12.3933 112.614 13.7217 114.101C15.05 115.589 17.0546 116.264 18.9971 116.722C21.5707 117.329 24.3542 117.649 26.7985 116.641C29.2428 115.632 31.1449 112.919 30.4368 110.374C30.1377 109.299 29.4332 108.391 28.7522 107.506C28.0713 106.622 27.3818 105.697 27.1221 104.612C26.9056 103.708 27.0658 102.658 27.6662 101.98C27.7754 101.861 27.8441 101.711 27.8625 101.551C27.8809 101.391 27.848 101.229 27.7685 101.089L27.7581 101.071Z"
      fill="#262626"
    />
    <path
      d="M43.8682 163.719H9.07202C9.02345 163.719 8.97687 163.7 8.94253 163.666C8.90818 163.631 8.88889 163.585 8.88889 163.536C8.88889 163.488 8.90818 163.441 8.94253 163.407C8.97687 163.373 9.02345 163.353 9.07202 163.353H43.8682C43.9168 163.353 43.9634 163.373 43.9977 163.407C44.0321 163.441 44.0514 163.488 44.0514 163.536C44.0514 163.585 44.0321 163.631 43.9977 163.666C43.9634 163.7 43.9168 163.719 43.8682 163.719Z"
      fill="#BCBCBC"
    />
  </svg>
);

export default Icon;
