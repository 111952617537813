export const getToken = () => {
  const localTokens = JSON.parse(localStorage.getItem('tokens@store') || 'null');
  return localTokens?.access_token;
};

export const setHeaders = (customHeaders) => (request) => {
  const token = getToken();
  const regex = /login/g;
  if (!regex.test(request.url)) {
    if (token) request.headers.set('Authorization', `Bearer ${token}`);
    if (customHeaders?.length)
      customHeaders.forEach((item) => request.headers.set(item.header, item.value));
  }
};
