import { useEffect, useContext } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';
import {
  PatchCommentPayload,
  PatchDrawRequestParam,
  PaymentConfiguration,
  QueryNamesEnums,
} from '@interfaces';
import { useStringFieldModel } from '@models';
import { AuthContext } from '@context';
import { getBasicUrl, roundToTwoDigits, validationShareRule } from '@utils';
import { getDrawRequest, getProject, patchDrawRequest, postComment } from '@globalService';
import { ComponentProps, ControllerInterface } from './interface';

export const useEditRequestPaymentConfiguration = ({
  drawRequestId,
  onClose,
}: ComponentProps): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId } = useParams();
  const user = useContext(AuthContext);

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId }),
    },
  ]);

  const project = requestedDataQueries[0].data;
  const drawRequest = requestedDataQueries[1].data;

  const constructionHoldback = useStringFieldModel({
    initValue: '0',
    validateOnChange: true,
    validationRule: (value) =>
      roundToTwoDigits(project?.loan.budget_construction_holdback) >=
      roundToTwoDigits(drawRequest.previous_construction_holdback_cumulative + +value),
    initError: 'Construction holdback for the current request should not exceed budget',
  });
  const postFundingConstructionBudget = useStringFieldModel({
    initValue: '0',
    validateOnChange: true,
  });
  const constructionHoldbackFraction = useStringFieldModel({
    initValue: '100',
    validationRule: validationShareRule,
    validateOnChange: true,
  });
  const borrowerEquityFraction = useStringFieldModel({
    initValue: '0',
    validationRule: validationShareRule,
    validateOnChange: true,
  });
  const borrowerEquity = useStringFieldModel({
    initValue: '0',
    validateOnChange: true,
    validationRule: (value) =>
      roundToTwoDigits(project?.loan.budget_borrower_equity) >=
      roundToTwoDigits(drawRequest.previous_borrower_equity_cumulative + +value),
    initError: 'Borrower equity for the current request should not exceed budget',
  });
  const comment = useStringFieldModel({
    initValue: '',
  });

  useEffect(() => {
    if (!drawRequest) return;

    const constructionHoldbackAmount = drawRequest.construction_holdback || 0;
    const borrowerEquityAmount = drawRequest.borrower_equity || 0;
    const constructionHoldbackRate =
      drawRequest.total > 0 ? drawRequest.construction_holdback_rate : 0;
    postFundingConstructionBudget.setValue(drawRequest.total.toString());
    constructionHoldbackFraction.setValue(constructionHoldbackRate.toFixed(2));
    borrowerEquityFraction.setValue((100 - constructionHoldbackRate).toFixed(2));
    constructionHoldback.setValue(constructionHoldbackAmount.toString());
    borrowerEquity.setValue(borrowerEquityAmount.toString());
    comment.setValue(drawRequest.payment_configuration_comment || '');
  }, [drawRequest]);

  const updatePaymentConfiguration = useMutation<Response, Error, PatchDrawRequestParam>(
    patchDrawRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_PROGRESS);
        if (comment.value) {
          const url = getBasicUrl({
            requestType: 'post',
            projectId,
            requestId: drawRequestId,
          });

          postCommentMutation.mutateAsync({
            url,
            value: {
              message: comment.value,
              received_by_company_id: user?.company_id,
            },
          });
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const postCommentMutation = useMutation<
    Response,
    Error,
    { url: string; value: PatchCommentPayload }
  >(postComment, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMMENTS);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleSubmit = () => {
    if (!constructionHoldbackFraction.value && !borrowerEquityFraction.value) return;
    updatePaymentConfiguration.mutateAsync({
      id: projectId,
      drawRequest: drawRequestId,
      construction_holdback_rate: +constructionHoldbackFraction.value,
      borrower_equity_rate: +borrowerEquityFraction.value,
      payment_configuration_type: PaymentConfiguration.PER_DRAW_REQUEST,
      payment_configuration_comment: comment.value,
    });

    onClose();
  };

  return {
    constructionHoldback,
    postFundingConstructionBudget,
    constructionHoldbackFraction,
    borrowerEquityFraction,
    borrowerEquity,
    handleSubmit,
    comment,
    disableSaveButton: [
      constructionHoldback,
      borrowerEquity,
      constructionHoldbackFraction,
      borrowerEquityFraction,
    ].some(({ isValid }) => !isValid),
  };
};
