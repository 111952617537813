import React, { FC } from 'react';
import { Button, ButtonGroup, IconButton, Tooltip } from '@mui/material';
import truncate from 'lodash/truncate';

import { Gallery, PDFViewerNew } from '@components';
import { DeleteIcon } from '@svgAsComponents';
import * as Controller from './controller';
import { colors, typography } from '@theme';

const DocumentButton: FC<Controller.DocumentButtonInterface> = ({
  document,
  projectDocument,
  handleDelete,
  handleDeleteProps,
  buttonName,
  dataTestName,
  size = 'small',
  onButtonClick,
  isActive = false,
  width,
}) => {
  const { text, buttonAction, imageZoom } = Controller.useDocument(document || projectDocument);
  const TEXT_LENGTH = 20;

  if (!document?.id && !projectDocument?.id) return null;
  return (
    <>
      <Tooltip title={text?.length > TEXT_LENGTH ? text : ''}>
        <div>
          <ButtonGroup disableElevation>
            <Button
              variant={isActive ? 'active' : 'new'}
              color="secondary"
              size={size}
              onClick={onButtonClick || buttonAction}
              data-cy={dataTestName}
              sx={{
                fontWeight: typography.labelSemiBold.fontWeight,
                ...(isActive ? {} : { color: colors.text.link }),
                ...(width ? { width } : { color: colors.text.link }),
              }}
            >
              {truncate(buttonName || text, {
                length: TEXT_LENGTH,
              })}
            </Button>
            {handleDelete && (
              <IconButton
                size={size}
                color="primary"
                onClick={() =>
                  handleDelete({
                    documentId: document?.id,
                    ...(handleDeleteProps ? handleDeleteProps : {}),
                  })
                }
                sx={{
                  borderBottomRightRadius: 2,
                  borderTopRightRadius: 2,
                  backgroundColor: isActive
                    ? colors.main.primary.light
                    : colors.main.primary.lighter,
                }}
                data-cy={`${dataTestName}__delete_icon`}
              >
                <DeleteIcon size={size === 'medium' ? 24 : 20} />
              </IconButton>
            )}
          </ButtonGroup>
        </div>
      </Tooltip>

      {imageZoom.gallery && (
        <Gallery startIndex={0} close={imageZoom.close} files={imageZoom.gallery} />
      )}
      {imageZoom.pdf && <PDFViewerNew pdfFile={imageZoom.pdf[0]} close={imageZoom.close} />}
    </>
  );
};

export default DocumentButton;
