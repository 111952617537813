import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { StringFieldModel, useStringFieldModel } from '@models';
import {
  IWorkflowReason,
  ProjectStatusEnum,
  QueryNamesEnums,
  WorkflowReasonEnum,
} from '@interfaces';
import { getWorkflowReasons } from '@globalService';
import { getReasonsList } from '@utils';

export interface ControllerInterface {
  comment: StringFieldModel;
  reasons: string[];
  handleSelectChange: (event) => void;
  reasonsList: string[];
  isSelectedChanged: boolean;
  clearInputs: () => void;
  onConfirm: () => void;
}

export const useDeactivationReasons = (
  newStatus: ProjectStatusEnum,
  onApprove: ({ value, changeReasons }) => void,
): ControllerInterface => {
  const [reasons, setReasons] = useState([]);
  const [isSelectedChanged, setIsSelectedChanged] = useState(false);
  const reasonsQuery = useQuery<IWorkflowReason[], Error>(
    [QueryNamesEnums.GET_WORKFLOW_REASONS],
    getWorkflowReasons.bind(this),
  );

  const reasonsList = useMemo(
    () => getReasonsList(reasonsQuery.data, WorkflowReasonEnum[newStatus]),
    [reasonsQuery.data, newStatus],
  );

  const handleSelectChange = (event) => {
    if (!isSelectedChanged) setIsSelectedChanged(true);
    const {
      target: { value },
    } = event;
    setReasons(value);
  };

  const isOtherReasonSelected = useMemo(() => reasons.includes('Other'), [reasons]);

  const comment = useStringFieldModel({
    initValue: '',
    validationRule: (value) => !isOtherReasonSelected || Boolean(value.trim()),
    validateOnChange: true,
  });

  const clearInputs = () => {
    setReasons([]);
    setIsSelectedChanged(false);
    comment.setValue('');
    comment.setValid(true);
  };

  const isValidForm = () => {
    if (isOtherReasonSelected && !comment.value) {
      setIsSelectedChanged(true);
      return false;
    }
    return true;
  };
  const onConfirm = () => {
    comment.validate();
    if (!isValidForm()) return;
    if (comment.value) reasons.push(comment.value);

    onApprove({
      value: newStatus,
      changeReasons: reasons.join(', '),
    });
    clearInputs();
  };

  return {
    comment,
    reasons,
    handleSelectChange,
    reasonsList,
    isSelectedChanged,
    clearInputs,
    onConfirm,
  };
};
