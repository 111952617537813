import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const ColumnChooserIcon = ({ size = 24, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon / column-chooser">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H9C10.1046 22 11 21.1046 11 20V4C11 2.89543 10.1046 2 9 2H4ZM4 4H9V20H4V4ZM15 2C13.8954 2 13 2.89543 13 4V20C13 21.1046 13.8954 22 15 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H15ZM15 4H20V20H15V4Z"
        fill={color}
      />
    </g>
  </svg>
);

export default ColumnChooserIcon;
