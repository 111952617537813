import React, { FC, PropsWithChildren } from 'react';
import { Box, Dialog, DialogContent, Grid2, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '@svgAsComponents';

export interface ComponentProps {
  title?: string;
  open: boolean;
  handleClose: () => void;
}

const InfoCard: FC<PropsWithChildren<ComponentProps>> = ({
  open,
  handleClose,
  children,
  title = 'Company Info',
}) => {
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          margin: 0,
          maxHeight: '100%',
          borderRadius: { sm: '4px', xs: 0 },
          width: { lg: '75vw', md: '80vw', sm: '95vw', xs: '100vw' },
          height: { sm: 'auto', xs: '100%' },
          maxWidth: '1200px',
        },
      }}
    >
      <DialogContent sx={{ p: 4.5 }}>
        <Grid2 container justifyContent="space-between" alignItems="center">
          <Grid2>
            <Typography variant="h2">{title}</Typography>
          </Grid2>

          <Grid2>
            <IconButton edge="end" size="medium" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid2>
        </Grid2>

        <Box sx={{ mt: { sm: 5, xs: 3 } }}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default InfoCard;
