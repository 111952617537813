import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const DotIcon = ({ size = 6, color = colors.main.primary.light }: ComponentProps) => (
  <svg width={size} height={size} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill={color} />
  </svg>
);

export default DotIcon;
