import { useQueryClient } from 'react-query';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { OktaContext, AuthContext, SSOContext } from '@context';
import { postLogout } from '@globalService';

export const useLogout = () => {
  const { setUser } = useContext(AuthContext);
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  const { clearOktaData } = useContext(OktaContext);
  const { clearSSOData } = useContext(SSOContext);
  const navigate = useNavigate();
  return {
    logout: useCallback(() => {
      postLogout()
        .catch(() => {
          window.location.reload();
        })
        .finally(() => {
          // To remove all queries from cache on logout
          queryClient.removeQueries();
        });
      if (clearOktaData) clearOktaData();
      if (clearSSOData) clearSSOData();
      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
      navigate('/login', { state: {} });
    }, []),
  };
};
