import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.status.warning.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6774 15.6539L13.6804 1.65846C12.5748 -0.552812 9.41924 -0.552827 8.3137 1.65846L1.31663 15.6539C0.319374 17.6486 1.76988 19.9954 3.99997 19.9954H17.9941C20.2242 19.9954 21.6747 17.6486 20.6774 15.6539ZM10.1026 2.55282C10.4711 1.81573 11.523 1.81573 11.8915 2.55282L18.8885 16.5483C19.221 17.2132 18.7375 17.9954 17.9941 17.9954H3.99997C3.2566 17.9954 2.7731 17.2132 3.10552 16.5483L10.1026 2.55282ZM10.9935 4.81836C10.3405 4.81836 9.81868 5.38466 9.84477 6.0649L10.0602 11.6806C10.0803 12.2029 10.4922 12.6155 10.9935 12.6155C11.4948 12.6155 11.9067 12.2029 11.9267 11.6806L12.1422 6.0649C12.1683 5.38467 11.6464 4.81836 10.9935 4.81836ZM9.61759 15.5784C9.61759 14.8033 10.2203 14.1749 10.9637 14.1749C11.7071 14.1749 12.3098 14.8033 12.3098 15.5784C12.3098 16.3535 11.7071 16.9819 10.9637 16.9819C10.2203 16.9819 9.61759 16.3535 9.61759 15.5784Z"
      fill={color}
    />
  </svg>
);

export default Icon;
