import { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { checkIsPHBProject } from '@utils';
import {
  ChangeDrawRequestDocumentParam,
  ChangeProjectDocumentParam,
  DeleteInspectionDocument,
  DocumentActionTypeEnum,
  PopupTypeEnum,
  QueryNamesEnums,
} from '@interfaces';
import { useConfirmationModal, useSafeSnackbar } from '@hooks';
import {
  deleteDrawRequestDocument,
  deleteInspectionDocument,
  deleteProjectDocument,
  restoreDrawRequestDocument,
  restoreProjectDocument,
} from '@globalService';
import { IDocumentProps, WrapperControllerInterface } from './interface';
import { useGetData } from '@context';

export const useDocumentsTableWrapper = ({
  prId: projectId,
  requestId: drawRequestId,
  milestoneId,
  inspectionId,
}: IDocumentProps): WrapperControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'is_advanced_budget_tracking_enabled'],
    args: { projectId },
  });

  // we don't use isPHBProject from SettingsContext because Documents component is used in All Requests and All Projects tabs
  const isPHBProject = useMemo(() => checkIsPHBProject(project.data), [project.data]);

  const { isConfirmModalOpened, askConfirm, closeConfirmModal, confirmCallback } =
    useConfirmationModal();

  const [actionType, setActionType] = useState('');
  const [modalInfo, setModalInfo] = useState({
    title: '',
    text: '',
    confirmButtonLabel: '',
    type: PopupTypeEnum.CONFIRMATION,
  });

  const deleteProjectDocumentation = useMutation<Response, Error, ChangeProjectDocumentParam>(
    deleteProjectDocument,
    {
      onSuccess: () => {
        // Query Invalidations to refetch project documentation list
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DOCUMENTS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS);
        if (milestoneId) {
          if (isPHBProject) {
            queryClient.invalidateQueries([
              QueryNamesEnums.GET_PROJECT_MILESTONE_GROUP_DOCS,
              { projectId, milestoneId },
            ]);
          } else {
            queryClient.invalidateQueries([
              QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
              { projectId, milestoneId },
            ]);
          }
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const restoreProjectDocumentation = useMutation<Response, Error, ChangeProjectDocumentParam>(
    restoreProjectDocument,
    {
      onSuccess: () => {
        // Query Invalidations to refetch project documentation list
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DOCUMENTS);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const deleteDrawDocument = useMutation<Response, Error, ChangeDrawRequestDocumentParam>(
    deleteDrawRequestDocument,
    {
      onSuccess: () => {
        invalidateDrawQueries();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const restoreDrawDocument = useMutation<Response, Error, ChangeDrawRequestDocumentParam>(
    restoreDrawRequestDocument,
    {
      onSuccess: () => {
        invalidateDrawQueries();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const deleteInspectionDocumentMutation = useMutation<Response, Error, DeleteInspectionDocument>(
    deleteInspectionDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
          { projectId, inspectionId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const invalidateDrawQueries = () => {
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DOCUMENTS);
    if (milestoneId) {
      if (isPHBProject) {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONE_GROUP_DOCS,
          { projectId, milestoneId },
        ]);
      } else {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
          { projectId, milestoneId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
          { projectId, milestoneId, drawRequestId },
        ]);
      }
    }
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMMENTS);
  };

  const getConfirmCallback = async ({ documentId }) => {
    if (actionType === DocumentActionTypeEnum.RESTORE_PROJECT_DOCUMENT) {
      await restoreProjectDocumentation.mutateAsync({
        projectId,
        documentId,
      });
    }

    if (actionType === DocumentActionTypeEnum.DELETE_PROJECT_DOCUMENT) {
      await deleteProjectDocumentation.mutateAsync({
        projectId,
        documentId,
      });
    }

    if (actionType === DocumentActionTypeEnum.RESTORE_DR_DOCUMENT) {
      await restoreDrawDocument.mutateAsync({
        projectId,
        drawRequestId,
        documentId,
      });
    }

    if (actionType === DocumentActionTypeEnum.DELETE_DR_DOCUMENT) {
      await deleteDrawDocument.mutateAsync({
        projectId,
        drawRequestId,
        documentId,
      });
    }

    if (actionType === DocumentActionTypeEnum.DELETE_INSPECTION_DOCUMENT) {
      await deleteInspectionDocumentMutation.mutateAsync({
        projectId,
        inspectionId,
        documentId,
      });
    }
    closeConfirmModal();
    setActionType('');
  };

  const deleteDocument = (documentId: string, name: string) => {
    setModalInfo({
      title: 'Delete file',
      text: `Are you sure you want to delete the following file - ${name}?`,
      type: PopupTypeEnum.DELETE,
      confirmButtonLabel: 'Delete',
    });
    setActionType(
      inspectionId
        ? DocumentActionTypeEnum.DELETE_INSPECTION_DOCUMENT
        : drawRequestId
          ? DocumentActionTypeEnum.DELETE_DR_DOCUMENT
          : DocumentActionTypeEnum.DELETE_PROJECT_DOCUMENT,
    );
    askConfirm({ documentId });
  };

  const restoreDocument = (documentId: string, name: string) => {
    setActionType(
      drawRequestId
        ? DocumentActionTypeEnum.RESTORE_DR_DOCUMENT
        : DocumentActionTypeEnum.RESTORE_PROJECT_DOCUMENT,
    );
    setModalInfo({
      title: 'Restore file',
      text: `You are about to restore the file "${name}".`,
      type: PopupTypeEnum.CONFIRMATION,
      confirmButtonLabel: 'Restore',
    });
    askConfirm({ documentId });
  };

  return {
    isConfirmModalOpened,
    modalInfo,
    closeConfirmModal,
    confirmCallback: () => confirmCallback({ action: getConfirmCallback }),
    restoreDocument,
    deleteDocument,
  };
};
