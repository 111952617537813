import React from 'react';
import { ComponentProps } from './interface';

const SortIcon = ({ color = '#4F4F4F', size = 24 }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49982 9.5V7.5H16.4998V9.5H8.49982ZM8.49982 15.5V13.5H12.4998V15.5H8.49982ZM8.49982 3.5V1.5H20.4998V3.5H8.49982ZM4.49982 13.5H6.99982L3.49982 17L-0.000183105 13.5H2.49982V3.5H-0.000183105L3.49982 0L6.99982 3.5H4.49982V13.5Z"
      fill={color}
    />
  </svg>
);

export default SortIcon;
