import React from 'react';
import { styled } from '@mui/material/styles';
import { ArrowBackIcon, ArrowRightIcon } from '@svgAsComponents';

export const DotButton = ({ selected, onClick }) => (
  <Dot className={`${selected ? 'is-selected' : ''}`} type="button" onClick={onClick} />
);

export const PrevButton = ({ className, style, onClick }) => (
  <ButtonPrev style={style} className={className} onClick={onClick}>
    <ArrowBackIcon />
  </ButtonPrev>
);

export const NextButton = ({ className, style, onClick }) => (
  <ButtonNext style={style} className={className} onClick={onClick}>
    <ArrowRightIcon />
  </ButtonNext>
);

const NavigationButton = styled('button')({
  outline: 0,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  touchAction: 'manipulation',
  position: 'absolute',
  zIndex: 1,
  top: '50%',
  transform: 'translateY(-50%)',
  border: 0,
  width: 30,
  height: 30,
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  '&:disabled': {
    cursor: 'default',
    opacity: 0.3,
  },
});

const ButtonPrev = styled(NavigationButton)({
  left: 50,
});
const ButtonNext = styled(NavigationButton)({
  right: 50,
});

const Dot = styled('button')({
  backgroundColor: 'transparent',
  cursor: 'pointer',
  position: 'relative',
  padding: 0,
  outline: 0,
  border: 0,
  width: 30,
  height: 30,
  marginRight: 7.5,
  marginLeft: 7.5,
  display: 'flex',
  alignItems: 'center',
  '&:after': {
    backgroundColor: '#efefef',
    width: '100%',
    height: 4,
    borderRadius: 2,
    content: '""',
  },
  '&.is-selected:after': {
    backgroundColor: '#1f4739',
    opacity: 1,
  },
});
