import React, { createContext, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '@context';
import { getTransloaditTemplate } from '@globalService';
import { ITransloaditTemplate, QueryNamesEnums } from '@interfaces';
import { STALE_TIME_THRESHOLD } from '@constants';

// Transloadit templates context
export const TransloaditContext = createContext({ transloaditTemplates: [] });

export const TransloaditProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const transloaditTemplateQuery = useQuery<ITransloaditTemplate[], Error>(
    [QueryNamesEnums.GET_TRANSLOADIT_TEMPLATE],
    getTransloaditTemplate.bind(this),
    {
      enabled: Boolean(user?.agreed_to_terms),
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME_THRESHOLD,
    },
  );

  const transloaditTemplates = useMemo(
    () => transloaditTemplateQuery.data,
    [transloaditTemplateQuery.data],
  );

  return (
    <TransloaditContext.Provider value={{ transloaditTemplates }}>
      {children}
    </TransloaditContext.Provider>
  );
};
