import React, { CSSProperties, FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Box, Grid2, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from '@theme';

export interface IWidgetPaperCard {
  title?: string | ReactNode;
  style?: CSSProperties;
  lg?: number;
  rightIcons?: Array<{
    Component: ReactElement;
    handle?: () => void;
  }>;
  rightText?: ReactElement;
}
const Root = styled(Grid2)({
  padding: '16px',
  backgroundColor: colors.background.white,
  borderRadius: '4px',
});
const WidgetPaperCard: FC<PropsWithChildren<IWidgetPaperCard>> = ({
  children,
  title,
  rightIcons,
  style,
  rightText,
  ...props
}) => {
  return (
    <Root container flexDirection="column" sx={{ width: '100%' }} style={style} {...props}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
        {title && typeof title === 'string' ? (
          <Typography role="heading" variant="h4">
            {title}
          </Typography>
        ) : (
          title
        )}
        {rightIcons && (
          <Stack direction="row" alignItems="center">
            {rightIcons?.map(({ Component }, index) => <Box key={index}>{Component}</Box>)}
          </Stack>
        )}
        {rightText}
      </Stack>
      {children}
    </Root>
  );
};

export default WidgetPaperCard;
