import React from 'react';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { tabClasses } from '@mui/material/Tab';
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom';
import { PopoverContentList, PopoverTooltip, UserProfileWithMenu } from '@components';

import { colors, fonts } from '@theme';
import { COMPANY_DATA } from '@constants';
import { checkIsLender, checkIsOwner, getTeamRole, handleContactSupport, isCustomer } from '@utils';
import { ComponentProps, NavigationLink } from './interface';
import LogoBox from './LogoBox';
import { ContactSupportIcon, HelpCenterIcon, NotificationIcon } from '@svgAsComponents';
import { useWebSocketsEvents } from '@hooks';
import { useLaunchDarklyFlags } from '@context';
import { config } from '@config';

function AppToolbar({ screensACL, controller }: ComponentProps) {
  const { user, pages, customerLogo } = controller;
  const middleMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { pathname } = useLocation();
  const flags = useLaunchDarklyFlags();
  const isMatchedPath = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  const match = matchPath({ path: '/:tab', end: false }, pathname);

  const teamRole = getTeamRole(user);
  const isUserCustomer = isCustomer(user);

  const { eventMessages: messages } = useWebSocketsEvents(`${config.wssUrl}/${user.id}/`);

  const renderItem = (item: NavigationLink) => {
    if (!item.isAvailable(screensACL, teamRole, item.path)) return null;
    const Icon = item.icon;

    const getCommonProps = (item: NavigationLink): any => ({
      iconPosition: 'start',
      icon: <Icon color={isMatchedPath(item.path) ? colors.icons.green : colors.icons.gray} />,
      label: middleMediaQuery ? item.title : ' ',
      key: item.path,
      sx: { px: { xs: '12px', lg: '24px' } },
    });

    return item.isExternal ? (
      <Tab
        component="a"
        href={item.path}
        target="_blank"
        rel="noopener noreferrer"
        {...getCommonProps(item)}
      />
    ) : (
      <Tab
        value={item.value}
        component={RouterLink}
        to={item.path}
        data-cy={item.dataTestName}
        {...getCommonProps(item)}
      />
    );
  };

  return (
    <AppBar
      position="absolute"
      sx={{
        backgroundColor: colors.background.white,
        boxShadow: 'none',
        borderBottom: `1px solid ${colors.neutral.lighter}`,
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ alignItems: { xs: 'baseline', sm: 'center' } }}>
          <LogoBox customerLogo={customerLogo} />

          <Box
            sx={{
              flexGrow: 1,
              position: 'relative',
              ml: '1rem',
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Tabs
              value={pages.some((x) => x.value === match?.params?.tab) && match?.params?.tab}
              sx={{
                maxHeight: '64px',
                border: 0,
                '& svg': { mr: { xs: '0', md: '10px' } },
                [`& .${tabClasses.root}`]: {
                  textTransform: 'none',
                  fontFamily: fonts.inter,
                  fontWeight: 600,
                  fontSize: '16px',
                  minWidth: 0,
                },
              }}
              TabIndicatorProps={{
                sx: { transition: 'none' },
                children: <span />,
              }}
            >
              {pages.map((item) => renderItem(item))}
            </Tabs>
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="center">
            {!isUserCustomer && window.zE && (
              <Button
                onClick={handleContactSupport}
                sx={{ mx: 1 }}
                data-cy="toolbar__contact_support__icon"
                color="secondary"
                variant="new"
                size="small"
              >
                <Stack direction="row" alignItems="center">
                  <ContactSupportIcon size={16} />
                  <Typography
                    variant="labelSemiBold"
                    sx={{ ml: 1, color: colors.main.primary.main }}
                  >
                    Live chat
                  </Typography>
                </Stack>
              </Button>
            )}
            {flags?.['ENG_7909_notifications_icon_test'] && ( // !! don't remove this flag until notifications are implemented
              <PopoverTooltip
                popoverContent={
                  <PopoverContentList
                    title={`Notifications (${messages.length})`}
                    data={messages}
                  />
                }
              >
                <IconButton data-cy="user_menu__notifications__button">
                  <Badge badgeContent={messages.length} color="primary">
                    <NotificationIcon size={24} />
                  </Badge>
                </IconButton>
              </PopoverTooltip>
            )}
            {(checkIsOwner(teamRole) || checkIsLender(teamRole)) && (
              <IconButton data-cy="user_menu__help_center__button">
                <a
                  href={
                    checkIsOwner(teamRole)
                      ? COMPANY_DATA.HELP_CENTER_LINK
                      : COMPANY_DATA.DOCSEND_LINK
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ height: 24 }}
                >
                  <HelpCenterIcon />
                </a>
              </IconButton>
            )}
            <UserProfileWithMenu />
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default AppToolbar;
