import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 16, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 6.01782C13.3333 6.38522 13.0348 6.68306 12.6666 6.68306H9.99992C9.82308 6.68306 9.65347 6.61294 9.52845 6.48814C9.40341 6.36334 9.3332 6.19408 9.33326 6.01761L9.33411 3.32807C9.33423 2.96067 9.6328 2.66292 10.001 2.66304C10.3692 2.66316 10.6676 2.96109 10.6674 3.32849L10.6671 4.40449L13.5279 1.53023C13.7875 1.26967 14.2096 1.26841 14.4707 1.52743C14.7319 1.78645 14.7331 2.20766 14.4735 2.46822L11.6027 5.35258H12.6666C13.0348 5.35258 13.3333 5.65042 13.3333 6.01782ZM1.52992 14.4739C1.2688 14.2148 1.26754 13.7936 1.52712 13.5331L4.39799 10.6487H3.33405C2.96586 10.6487 2.66738 10.3509 2.66738 9.98348C2.66738 9.61608 2.96586 9.31824 3.33405 9.31824H6.00073C6.17757 9.31824 6.34717 9.38836 6.47221 9.51316C6.59724 9.63796 6.66745 9.80723 6.66739 9.9837L6.66654 12.6732C6.66642 13.0406 6.36785 13.3384 5.99966 13.3383C5.63147 13.3381 5.33309 13.0402 5.3332 12.6728L5.33355 11.5968L2.47272 14.4711C2.21315 14.7316 1.79104 14.7329 1.52992 14.4739Z"
      fill={color}
    />
  </svg>
);

export default Icon;
