import { useState } from 'react';

// we need this hook to show loading skeleton for 500ms between changing the filters,
// so it's obvious to user that the table has rerendered
const useLoadingSkeleton = (): {
  showLoadingSkeleton: boolean;
  showTemporaryLoadingSkeleton: () => void;
} => {
  const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(false);

  const showTemporaryLoadingSkeleton = () => {
    setShowLoadingSkeleton(true);
    setTimeout(() => {
      setShowLoadingSkeleton(false);
    }, 500);
  };

  return { showLoadingSkeleton, showTemporaryLoadingSkeleton };
};

export default useLoadingSkeleton;
