import { sizes } from '../index';

export default function InputLabel() {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: sizes.fontSizes.medium,
        },
      },
    },
  };
}
