import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { ChevronUpIcon } from '@svgAsComponents';

export const TableItem: FC<{ isMainLevel?: boolean; isExpanded?: boolean; name?: string }> = ({
  isMainLevel = true,
  isExpanded = false,
  name = isMainLevel ? 'Main level' : 'Nested level',
}) => (
  <Stack direction="row">
    {(!isMainLevel || isExpanded) && (
      <Typography
        variant="body2"
        px={2}
        py={1}
        sx={{
          border: `1px solid ${colors.neutral.lighter}`,
          backgroundColor:
            isMainLevel && isExpanded
              ? colors.status.information.lighter
              : colors.status.information.lightest,
          width: '56px',
        }}
      >
        {isExpanded && (
          <Stack sx={{ transform: 'rotate(180deg)', mt: 1 }}>
            <ChevronUpIcon />
          </Stack>
        )}
      </Typography>
    )}
    <Typography
      variant="body2"
      px={2}
      py={1}
      sx={{
        border: `1px solid ${colors.neutral.lighter}`,
        backgroundColor:
          isMainLevel && isExpanded
            ? colors.status.information.lighter
            : colors.status.information.lightest,
        width: '100%',
      }}
    >
      {name}
    </Typography>
  </Stack>
);
