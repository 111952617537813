export default function Skeleton() {
  return {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  };
}
