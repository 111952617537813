import React from 'react';
import { ClickAwayListener, styled } from '@mui/material';

import { IMenuItem } from '@interfaces';
import { colors } from '@theme';
import { MenuItemComponent } from '@components';

// Custom styled components to mimic MUI styles but not to have backdrop overlay
// the problem with mui menu is that backdrop overlay catches the next click after context menu is opened
const StyledCustomContextMenu = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.modal,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  border: `0.5px solid ${colors.neutral.lighter}`,
  marginTop: '2px',
  overflow: 'hidden',
  '& ul': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    backgroundColor: colors.neutral.white,
  },
}));

interface CustomContextMenuProps {
  xPos: number;
  yPos: number;
  open: boolean;
  closeMenu: () => void;
  menuItems: IMenuItem[];
}

export const ContextMenu: React.FC<CustomContextMenuProps> = ({
  xPos,
  yPos,
  open,
  closeMenu,
  menuItems,
}) => {
  if (!open || !menuItems?.length) return null;

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <div>
        <StyledCustomContextMenu style={{ top: yPos, left: xPos }}>
          <ul>
            {menuItems.map((item, index) => (
              <MenuItemComponent
                key={index}
                item={item}
                index={index}
                handlePopoverClose={closeMenu}
                divider={index !== menuItems.length - 1}
              />
            ))}
          </ul>
        </StyledCustomContextMenu>
      </div>
    </ClickAwayListener>
  );
};

export default ContextMenu;
