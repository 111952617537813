import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M17 0H3C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V3C20 1.34315 18.6569 0 17 0ZM2 3C2 2.44772 2.44772 2 3 2H17C17.5523 2 18 2.44772 18 3V17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17V3ZM14.5 4C13.6716 4 13 4.67157 13 5.5V14.5C13 15.3284 13.6716 16 14.5 16C15.3284 16 16 15.3284 16 14.5V5.5C16 4.67157 15.3284 4 14.5 4ZM8.5 9.5C8.5 8.67157 9.17157 8 10 8C10.8284 8 11.5 8.67157 11.5 9.5V14.5C11.5 15.3284 10.8284 16 10 16C9.17157 16 8.5 15.3284 8.5 14.5V9.5ZM5.5 12C4.67157 12 4 12.6716 4 13.5V14.5C4 15.3284 4.67157 16 5.5 16C6.32843 16 7 15.3284 7 14.5V13.5C7 12.6716 6.32843 12 5.5 12Z"
    />
  </svg>
);

export default Icon;
