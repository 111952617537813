import React, { FC, useContext, useState } from 'react';
import { AssignCoordinatorPopup, IconButtonWithTooltip, LabelAndValue } from '@components';
import { SettingsContext } from '@context';
import { IDrawRequest, PermissionNamesEnums } from '@interfaces';
import { getTooltipText, getUserFullName, WithPermission } from '@utils';
import { EditIcon } from '@svgAsComponents';
import { colors } from '@theme';

const RequestCoordinator: FC<{ request: IDrawRequest; isCompleted: boolean; source: string }> = ({
  request,
  isCompleted,
  source,
}) => {
  const [coordinatorPopupOpen, setCoordinatorPopupOpen] = useState<boolean>(false);

  const { isCurrentProjectArchived } = useContext(SettingsContext);
  return (
    <>
      <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_VIEW}>
        <LabelAndValue
          label="Coordinator"
          icon={
            !isCompleted && (
              <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT}>
                <IconButtonWithTooltip
                  onClick={() => setCoordinatorPopupOpen(true)}
                  tooltipText={getTooltipText({ isCurrentProjectArchived })}
                  disabled={isCurrentProjectArchived}
                  sx={{ p: 0, pl: 1 }}
                  data-cy={`${source}__draw_coordinator_edit`}
                >
                  <EditIcon size={20} />
                </IconButtonWithTooltip>
              </WithPermission>
            )
          }
          color={request.coordinator ? colors.text.link : colors.status.error.medium}
          text={request.coordinator ? getUserFullName(request.coordinator) : 'Not assigned'}
          textDataTestName="draw_coordinator"
        />
      </WithPermission>
      {coordinatorPopupOpen && (
        <AssignCoordinatorPopup
          open={coordinatorPopupOpen}
          requestId={request.id}
          initCoordinator={request.coordinator}
          onClose={() => setCoordinatorPopupOpen(false)}
        />
      )}
    </>
  );
};

export default RequestCoordinator;
