import React, { FC, memo, useContext } from 'react';
import { currencyFormatter } from '@utils';
import { Typography } from '@mui/material';
import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import { ColumnV2Width, IMilestoneTotal, PermissionNamesEnums } from '@interfaces';
import { TableContext } from '../controller';

const VarianceToLenderAllowance: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__variance_to_lender_allowance__index_${row?.index}`}
    >
      {currencyFormatter(row.variance_to_lender_allowance, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3SemiBold"
      data-cy={`${source}__footer__variance_to_lender_allowance__value`}
    >
      {currencyFormatter(row.variance_to_lender_allowance, '-')}
    </Typography>
  );
};

const Memoized = memo(VarianceToLenderAllowance);

const varianceToLenderAllowance: MilestoneListColumnType = {
  name: 'variance_to_lender_allowance',
  columnText: 'Variance to lender allowance ($)',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Variance between inspection allowance and lender allowance">
      <ColumnLabel>Variance to</ColumnLabel>
      <ColumnLabel> Lender allowance ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default varianceToLenderAllowance;
