import { RequestTableTabsEnum } from '@interfaces';
import { Button } from '@mui/material';
import React, { FC, useContext } from 'react';
import { DrawerContext } from './drawer';

const ReviewCredit: FC = () => {
  const { updateRightDrawer, source } = useContext(DrawerContext);
  return (
    <Button
      size="small"
      onClick={updateRightDrawer({
        milestoneName: 'Credit review',
        tab: RequestTableTabsEnum.CREDIT_APPROVE,
      })}
      data-cy={`${source}__review_credit__button`}
      sx={{ ml: 1 }}
    >
      Review credits
    </Button>
  );
};

export default ReviewCredit;
