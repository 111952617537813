import { useState, useRef, useEffect, useMemo } from 'react';

export const useCollapsibleWidget = ({
  widgetType,
  closeOnClickAway,
  initiallyOpened,
  shouldSaveState,
  isWidgetOpen,
  onWidgetOpened,
  onWidgetClosed,
}: {
  widgetType: string;
  closeOnClickAway: boolean;
  initiallyOpened: boolean;
  shouldSaveState: boolean;
  isWidgetOpen?: boolean;
  onWidgetOpened?: () => void;
  onWidgetClosed?: () => void;
}) => {
  const parentStackRef = useRef(null);
  const collapsedFromLocalStore = localStorage.getItem(widgetType);
  const shouldBeInitiallyOpen = useMemo(
    () => JSON.parse(collapsedFromLocalStore) || (!collapsedFromLocalStore && initiallyOpened),
    [collapsedFromLocalStore, initiallyOpened],
  );
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (shouldBeInitiallyOpen && parentStackRef.current) {
      setAnchorEl(parentStackRef.current);
      if (onWidgetOpened) onWidgetOpened();
    }
  }, [shouldBeInitiallyOpen, parentStackRef.current]);

  useEffect(() => {
    setAnchorEl(isWidgetOpen ? parentStackRef.current : null);
  }, [isWidgetOpen, parentStackRef]);

  useEffect(() => {
    if (JSON.parse(collapsedFromLocalStore) || (!collapsedFromLocalStore && initiallyOpened)) {
      setAnchorEl(parentStackRef.current);
      if (onWidgetOpened) onWidgetOpened();
    }
  }, [collapsedFromLocalStore, initiallyOpened]);

  const handleCollapseClick = (e) => {
    e.stopPropagation();
    if (shouldSaveState) localStorage.setItem(widgetType, JSON.stringify(!anchorEl));
    setAnchorEl(anchorEl ? null : parentStackRef.current);
    if (!anchorEl && onWidgetOpened) onWidgetOpened();
    if (anchorEl && onWidgetClosed) onWidgetClosed();
  };

  const handleClose = () => {
    closeOnClickAway && setAnchorEl(null);
    if (shouldSaveState && closeOnClickAway)
      localStorage.setItem(widgetType, JSON.stringify(false));
    if (closeOnClickAway && onWidgetClosed) onWidgetClosed();
  };

  return {
    open: Boolean(anchorEl),
    handleCollapseClick,
    handleClose,
    anchorEl,
    parentStackRef,
  };
};
