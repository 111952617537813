export const isRestricted = (permissionKey, permissions) => {
  // !permissions means that permissions is not loaded yet and we restrict all components
  if (!permissions?.length) return true;

  if (Array.isArray(permissionKey)) {
    // if permissionKey is array of possible value (in other words we can show some elements based on one of the permissions list),
    // we check if all possible keys are in the list, so it's restricted
    const allKeysPresent = permissionKey.every((key) => permissions.includes(key));
    return allKeysPresent;
  } else {
    return permissions.includes(permissionKey);
  }
};

export const filterObjectKeyByPermission = ({ object, key, isRestricted }) => {
  if (isRestricted && key in object) {
    const { [key]: _, ...rest } = object;
    return rest;
  }
  return object;
};
