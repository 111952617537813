import { useMutation } from 'react-query';

import { resendInvite } from '@globalService';

export interface ControllerInterface {
  showProgress: boolean;
  handleResendInvite: (email: string) => void;
}

export const useResendInvite = (): ControllerInterface => {
  const resendInvitePost = useMutation<Response, Error, { email }>(resendInvite);

  const handleResendInvite = async (email) => {
    await resendInvitePost.mutateAsync(email);
  };

  return {
    showProgress: resendInvitePost.isLoading,
    handleResendInvite,
  };
};
