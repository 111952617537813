import { getYear } from 'date-fns';
import { DateValidationEnum } from '@interfaces';

export const isValidDate = (value: Date) => {
  if (isNaN(Date.parse(value?.toString()))) return { value: false, reason: 'Invalid date' };

  if (getYear(value) <= 1900) return { value: false, reason: 'Please use the format MM/DD/YYYY' };

  return { value: true, reason: '' };
};

export const getDateValidationRule = ({
  value,
  rule,
  required = true,
  minDate,
  maxDate,
  dateDescription = '',
}: {
  value: Date;
  rule: DateValidationEnum;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  dateDescription?: string;
}) => {
  if (!required && !value) {
    return { value: true, reason: '' };
  }

  if (!value) {
    return { value: false, reason: 'Date is required' };
  }

  switch (rule) {
    case DateValidationEnum.MORE:
      if (minDate && compareDates(value, minDate) <= 0) {
        return {
          value: false,
          reason: `Selected date must be more than ${
            dateDescription || minDate?.toLocaleDateString()
          }`,
        };
      }
      break;

    case DateValidationEnum.LESS:
      if (maxDate && compareDates(value, maxDate) >= 0) {
        return {
          value: false,
          reason: `Selected date must be less than ${
            dateDescription || maxDate?.toLocaleDateString()
          }`,
        };
      }
      break;

    case DateValidationEnum.MORE_OR_EQUAL:
      if (minDate && compareDates(value, minDate) === -1) {
        return {
          value: false,
          reason: `Selected date must be more than or equal to ${
            dateDescription || minDate?.toLocaleDateString()
          }`,
        };
      }
      break;

    case DateValidationEnum.LESS_OR_EQUAL:
      if (maxDate && compareDates(value, maxDate) === 1) {
        return {
          value: false,
          reason: `Selected date must be less than or equal to ${
            dateDescription || maxDate?.toLocaleDateString()
          }`,
        };
      }
      break;

    case DateValidationEnum.BETWEEN_INCLUDING:
      if (
        (maxDate && compareDates(value, maxDate) === 1) ||
        (minDate && compareDates(value, minDate) === -1)
      ) {
        return {
          value: false,
          reason: `Selected date must be between ${minDate?.toLocaleDateString()} and ${maxDate?.toLocaleDateString()}`,
        };
      }
      break;

    default:
      break;
  }

  return isValidDate(value);
};

// Creates a Date object representing a local date without time zone conversion issues.
// This example to illustrate the difference between new Date('YYYY-MM-DD') and new Date(YYYY, MM, DD):
// new Date('2024-08-01')
// Wed Jul 31 2024 17:00:00 GMT-0700 (Pacific Daylight Time) - wrong date
// new Date(2024, 07, 01) is the same as new Date('2024-08-01')
// Thu Aug 01 2024 00:00:00 GMT-0700 (Pacific Daylight Time) - correct date
export const createDateAsLocal = (dateString: string) => {
  if (!dateString) return null;
  if (typeof dateString !== 'string') return dateString;
  const dateParts = dateString.split('-'); // YYYY-MM-DD
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const day = parseInt(dateParts[2], 10);

  // Create a new Date object using the parsed year, month, and day.
  // This treats the date as local time zone date without converting it to UTC.
  const date = new Date(year, month - 1, day);
  return date;
};

export const getNextDay = (date): Date => {
  if (!date) return null;
  const nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
};
export const getPrevDay = (date): Date => {
  if (!date) return null;
  const prevDay = new Date(date);
  prevDay.setDate(prevDay.getDate() - 1);
  return prevDay;
};

export const compareDates = (date1: Date, date2: Date) => {
  // Check if either date is missing
  if (!date1 && !date2) {
    return 0; // Both dates are missing and considered equal
  } else if (!date1) {
    return -1; // Only date1 is missing, so date2 is considered greater
  } else if (!date2) {
    return 1; // Only date2 is missing, so date1 is considered greater
  }

  // Convert dates to the start of their respective days
  const dayStart1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const dayStart2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

  // Convert both valid dates to milliseconds since January 1, 1970 (Unix timestamp)
  const timestamp1 = dayStart1.getTime();
  const timestamp2 = dayStart2.getTime();

  // Compare the timestamps
  if (timestamp1 < timestamp2) {
    return -1; // date1 is earlier than date2
  } else if (timestamp1 > timestamp2) {
    return 1; // date1 is later than date2
  } else {
    return 0; // dates are equal
  }
};
