import React from 'react';

import { colors } from '@theme';
import { ComponentProps } from './interface';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4391 2H8.26749C7.47184 2 6.70877 2.31607 6.14616 2.87868L3.73195 5.29289C3.16934 5.8555 2.85327 6.61856 2.85327 7.41421V9V19C2.85327 20.6569 4.19642 22 5.85327 22H19.8533C21.5101 22 22.8533 20.6569 22.8533 19V9V7.41421C22.8533 6.61856 22.5372 5.8555 21.9746 5.29289L19.5604 2.87868C18.9978 2.31607 18.2347 2 17.4391 2ZM19.8533 6L18.1462 4.29289C17.9586 4.10536 17.7043 4 17.4391 4H8.26749C8.00227 4 7.74791 4.10536 7.56038 4.29289L5.85327 6H19.8533ZM4.85327 9V19C4.85327 19.5523 5.30099 20 5.85327 20H19.8533C20.4056 20 20.8533 19.5523 20.8533 19V9C20.8533 8.44772 20.4056 8 19.8533 8H5.85327C5.30099 8 4.85327 8.44771 4.85327 9ZM6.85327 17C6.85327 16.4477 7.30099 16 7.85327 16H9.85327C10.4056 16 10.8533 16.4477 10.8533 17C10.8533 17.5523 10.4056 18 9.85327 18H7.85327C7.30099 18 6.85327 17.5523 6.85327 17ZM7.85327 13C7.30099 13 6.85327 13.4477 6.85327 14C6.85327 14.5523 7.30099 15 7.85327 15H11.8533C12.4056 15 12.8533 14.5523 12.8533 14C12.8533 13.4477 12.4056 13 11.8533 13H7.85327Z"
      fill={color}
    />
  </svg>
);

export default Icon;
