import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ControllerInterface } from '../controller';
import { PoliciesList } from '@components';
import { WrapperPolicy } from '../common';
import { PolicyListSourceEnums } from '@interfaces';

export const Policies: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { policies, borrowerPolicies } = controller;

  return borrowerPolicies?.items.length + policies?.items.length ? (
    <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
      <Typography variant="printH2" data-cy="report_policy_section_title">
        Policy
      </Typography>
      <WrapperPolicy>
        <PoliciesList
          items={borrowerPolicies?.items}
          policiesId={borrowerPolicies?.id}
          isTemplate={false}
          source={PolicyListSourceEnums.SUBMISSION}
        />
        <PoliciesList
          items={policies?.items}
          policiesId={policies?.id}
          isTemplate={false}
          source={PolicyListSourceEnums.APPROVAL}
        />
      </WrapperPolicy>
    </Box>
  ) : null;
};
