import { useSafeSnackbar } from '@hooks';
import { useMutation, useQueryClient } from 'react-query';
import {
  DropdownFieldModel,
  StringFieldModel,
  useDropdownFieldModel,
  useStringFieldModel,
  usePhoneFieldModel,
  PhoneFieldModel,
} from '@models';
import { updateCompany } from '@globalService';
import { ErrorDual, ICompanyFull, ICompanyOverview, QueryNamesEnums } from '@interfaces';
import {
  checkIsFieldsValid,
  checkIsOwner,
  getTeamRole,
  parsePathErrorDual,
  regexValidation,
} from '@utils';
import { useContext } from 'react';
import { AuthContext } from '@context';
import { states } from '@constants';

interface ControllerInterface {
  companyNameField: StringFieldModel;
  emailField: StringFieldModel;
  phoneNumberField: PhoneFieldModel;
  submitCompanyInfo: () => void;
  isSubmitting: boolean;
  address: {
    stateField: DropdownFieldModel;
    address_1: StringFieldModel;
    cityField: StringFieldModel;
    zipCodeField: StringFieldModel;
  };
}

export const useCompanyInfoEdit = (
  onClose: () => void,
  company: ICompanyFull,
): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);

  const companyNameField = useStringFieldModel({
    initValue: company.name,
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const emailField = useStringFieldModel({
    initValue: company.email,
    validationRule: (value) => regexValidation('email', value),
    validateOnChange: true,
  });

  const phoneNumberField = usePhoneFieldModel({
    initValue: company.phone,
  });

  const stateField = useDropdownFieldModel({
    initValue: company.address?.state
      ? states.find((state) => state.name === company.address?.state)
      : null,
    validationRule: (value) => Boolean(value?.name),
  });
  const address_1 = useStringFieldModel({
    initValue: company.address?.address_1 || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });
  const cityField = useStringFieldModel({
    initValue: company.address?.city || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });
  const zipCodeField = useStringFieldModel({
    initValue: company.address?.zip_code || '',
    validationRule: (value) => regexValidation('zip_code', value) && Boolean(value),
    validateOnChange: true,
  });

  const address = { stateField, address_1, cityField, zipCodeField };

  const submitCompanyInfo = () => {
    const isCompanyNameValid = companyNameField.validate();
    const isEmailValid = emailField.validate();
    const isPhoneNumberValid = phoneNumberField.validate();

    if (isCompanyNameValid && isEmailValid && isPhoneNumberValid && checkIsFieldsValid(address)) {
      updateCompanyMutation.mutateAsync({
        company: {
          name: companyNameField.value,
          email: emailField.value,
          phone: phoneNumberField.valueToSave,
          address: {
            ...(stateField.value ? { state: stateField.value?.name } : {}),
            ...(address_1.value ? { address_1: address_1.value } : {}),
            ...(cityField.value ? { city: cityField.value } : {}),
            ...(zipCodeField.value ? { zip_code: zipCodeField.value } : {}),
          },
        },
      });
    }
  };

  const updateCompanyMutation = useMutation<Response, ErrorDual, ICompanyOverview>(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_MY_COMPANY);
      if (!user?.company_information_first_updated_at && checkIsOwner(teamRole))
        queryClient.invalidateQueries(QueryNamesEnums.GET_USER);
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  return {
    companyNameField,
    emailField,
    phoneNumberField,
    address,
    submitCompanyInfo,
    isSubmitting: updateCompanyMutation.isLoading,
  };
};
