import React from 'react';
import { UserListColumnType } from './common';
import { UsersListOptionsMenu } from '@components';

const optionsMenu: UserListColumnType = {
  name: 'optionsMenu',
  columnText: '',
  width: '3%',
  renderCell: ({ row, isActivationAllowed, team, showRemoveFromTeamOption }) => (
    <UsersListOptionsMenu
      companyUser={row}
      isActivationAllowed={isActivationAllowed}
      team={team}
      showRemoveFromTeamOption={showRemoveFromTeamOption}
    />
  ),
};

export default optionsMenu;
