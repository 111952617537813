import { dataProvider } from '@api';
import {
  CompanyPoliciesParam,
  CompanyPoliciesTemplateParam,
  ICompany,
  ICompanyFull,
  ICompanyOverview,
  ICompanySettings,
  IIntegrationData,
  IProject,
  IProjectChecklist,
  IProjectSimple,
  IRoleNew,
  ISupersetDashboard,
  IUser,
  TPolicies,
  UpdateCompanySettingsPayload,
} from '@interfaces';
import {
  companies,
  company,
  companyPoliciesProjectsList,
  companyPoliciesTemplate,
  companyPoliciesTemplates,
  companyProjects,
  companyRoles,
  companySettings,
  companyUser,
  companyUsers,
  integrationData,
  integrationDataByEmail,
  myCompany,
  supersetDashboards,
} from '../paths';

export const getIntegrationData = async (domain, accessType): Promise<IIntegrationData> =>
  dataProvider.get(integrationData(domain, accessType)).json();

export const getIntegrationDataByEmail = async (email): Promise<{ results: IIntegrationData[] }> =>
  dataProvider.get(integrationDataByEmail(email)).json();

export const getCompanyUsers = async ({
  companyId,
  querySearch,
  sorting,
}: {
  companyId: string;
  querySearch?: string;
  sorting?: string;
}): Promise<{ results: IUser[] }> =>
  dataProvider.get(companyUsers(companyId, querySearch, sorting)).json();

export const getCompanyUser = async (companyId: string, userId: string): Promise<IUser> =>
  dataProvider.get(companyUser(companyId, userId)).json();

export const getCompanyProjects = async (
  companyId: string,
): Promise<{ results: IProject[]; count: number }> =>
  dataProvider.get(companyProjects(companyId)).json();

export const getMyCompany = async (): Promise<ICompanyFull> => dataProvider.get(myCompany()).json();
export const getCompany = async (companyId: string): Promise<ICompanyFull> =>
  dataProvider.get(company(companyId)).json();

export const updateCompany = async ({ company }: ICompanyOverview): Promise<Response> =>
  dataProvider.patch(myCompany(), { json: { ...company } }).json();

// TODO no do string params
export const getCompaniesWithQuery = async ({
  restQlParams,
  query,
}: {
  query: string;
  restQlParams: string;
}): Promise<{ results: ICompany[] }> => dataProvider.get(companies(query, restQlParams)).json();

export const getCompanyDashboards = async (companyId: string): Promise<ISupersetDashboard[]> =>
  dataProvider.get(supersetDashboards(companyId)).json();

export const getCompanyPoliciesTemplates = async (
  companyId: string,
): Promise<IProjectChecklist[]> => dataProvider.get(companyPoliciesTemplates(companyId)).json();

export const updateCompanyPoliciesTemplate = async ({
  companyId,
  templateId,
  templateData,
}: CompanyPoliciesTemplateParam): Promise<Response> =>
  dataProvider.put(companyPoliciesTemplate(companyId, templateId), { json: templateData }).json();

export const deleteCompanyPoliciesTemplate = async ({
  companyId,
  templateId,
}: CompanyPoliciesTemplateParam): Promise<Response> =>
  dataProvider.delete(companyPoliciesTemplate(companyId, templateId));

export const createCompanyPoliciesTemplate = async ({
  companyId,
  templateData,
}: CompanyPoliciesParam): Promise<TPolicies> =>
  dataProvider.post(companyPoliciesTemplates(companyId), { json: templateData }).json();

export const getCompanyPoliciesProjectsList = async (
  companyId: string,
  templateId: string,
): Promise<{ results: IProjectSimple[] }> =>
  dataProvider.get(companyPoliciesProjectsList(companyId, templateId)).json();

export const getCompanySettings = async (companyId: string): Promise<ICompanySettings> =>
  dataProvider.get(companySettings(companyId)).json();

export const getCompanyRoles = async (): Promise<IRoleNew[]> =>
  dataProvider.get(companyRoles()).json();

export const updateCompanySettings = async ({
  companyId,
  data,
}: UpdateCompanySettingsPayload): Promise<Response> =>
  dataProvider.patch(companySettings(companyId), { json: data }).json();
