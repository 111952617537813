import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 18 }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 18) * 24}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14572 0H11.1728L17.9751 7.09007V20.8792C17.9751 22.5982 16.5733 24 14.8543 24H3.14572C1.41932 24 0.0249023 22.5982 0.0249023 20.8792V3.12082C0.0249023 1.39442 1.41932 0 3.14572 0V0Z"
      fill="#EBA800"
    />
    <path
      opacity="0.302"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1654 0V7.03106H17.9751L11.1654 0Z"
      fill="white"
    />
    <path
      d="M6.63542 12.4685V10.8306C6.63542 10.6536 6.49523 10.5134 6.31817 10.5134H5.43283C5.25577 10.5134 5.11558 10.6536 5.11558 10.8306V12.4685L4.62127 13.9146C4.48108 14.3203 4.54749 14.7704 4.79833 15.1172C5.0418 15.4639 5.44757 15.6705 5.8755 15.6705C6.30343 15.6705 6.70183 15.4639 6.95267 15.1172C7.20351 14.7704 7.26991 14.3204 7.12973 13.9146L6.63542 12.4685ZM6.42885 14.7409C6.17801 15.1024 5.57302 15.1024 5.31481 14.7409C5.18939 14.5638 5.15249 14.3277 5.22626 14.1211L5.38859 13.6342H6.35507L6.52476 14.1211C6.59117 14.3277 6.56164 14.5638 6.42885 14.7409ZM3.91302 1.71904V2.61914C3.91302 2.79621 4.05321 2.93639 4.23027 2.93639H5.43286V4.27917H4.23027C4.05321 4.27917 3.91302 4.41935 3.91302 4.59642V5.28992C3.91302 5.46699 4.05321 5.61454 4.23027 5.61454H5.43286V6.94992H4.23027C4.05321 6.94992 3.91302 7.09011 3.91302 7.26717V7.96804C3.91302 8.14511 4.05321 8.28529 4.23027 8.28529H5.43286V9.30342C5.43286 9.48048 5.58042 9.62067 5.75748 9.62067H7.52079C7.69048 9.62067 7.83803 9.48048 7.83803 9.30342V8.60254C7.83803 8.43285 7.69048 8.28529 7.52079 8.28529H6.31083V6.94992H7.52079C7.69048 6.94992 7.83803 6.80973 7.83803 6.63267V5.93179C7.83803 5.75473 7.69048 5.61454 7.52079 5.61454H6.31083V4.27917H7.52079C7.69048 4.27917 7.83803 4.13161 7.83803 3.95455V3.26104C7.83803 3.08398 7.69048 2.93642 7.52079 2.93642H6.31083V1.71167C6.3108 1.71167 3.91302 1.71904 3.91302 1.71904Z"
      fill="white"
    />
  </svg>
);

export default Icon;
