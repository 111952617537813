import React from 'react';
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { HookState } from '@interfaces';
import { LabelAndValue, LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';
import { currencyFormatter } from '@utils';
import { EditIcon } from '@svgAsComponents';

const ProjectPaymentSummary = () => {
  const {
    state,
    originalBorrowerEquity,
    constructionHoldback,
    originalEstimate,
    feesAmount,
    revisedEstimate,
    paymentModelText,
    navigateToPaymentSettings,
    canEditFees,
    canEditProjectModel,
  } = Controller.useProjectPaymentSummary();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <>
          <StyledBox>
            <Skeleton />
          </StyledBox>
          <Box mb={2} />
          <LoadingSkeleton type="twoBlocks" />
        </>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="project payment summary" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack direction={{ lg: 'row', xs: 'column' }} spacing={1}>
          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack flex={1} spacing={1}>
              <LabelAndValue
                label="Payment model"
                text={paymentModelText}
                icon={
                  canEditProjectModel ? (
                    <IconButton
                      onClick={navigateToPaymentSettings}
                      sx={{ py: 0 }}
                      data-cy="payments_tab__project__payment_model__edit__icon"
                    >
                      <EditIcon size={24} />
                    </IconButton>
                  ) : null
                }
                iconPosition="right"
              />
              <LabelAndValue
                label="All fees"
                text={currencyFormatter(feesAmount, '-')}
                icon={
                  canEditFees ? (
                    <IconButton
                      sx={{ py: 0 }}
                      onClick={navigateToPaymentSettings}
                      data-cy="payments_tab__project__fees__edit__icon"
                    >
                      <EditIcon size={24} />
                    </IconButton>
                  ) : null
                }
                iconPosition="right"
              />
            </Stack>
          </StyledBox>
          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack flex={1} spacing={1}>
              <Typography variant="labelSemiBold">Overall budget</Typography>
              <LabelAndValue
                label="Revised scheduled value"
                text={currencyFormatter(revisedEstimate, '-')}
                textDataTestName="payments_tab__project__revised_estimate__value"
              />
              <LabelAndValue
                label="Original scheduled value"
                text={currencyFormatter(originalEstimate, '-')}
                textDataTestName="payments_tab__project__original_estimate__value"
              />
            </Stack>
          </StyledBox>
          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack flex={1} spacing={1}>
              <Typography variant="labelSemiBold">Sources</Typography>
              <LabelAndValue
                label="Construction holdback"
                text={currencyFormatter(constructionHoldback, '-')}
                textDataTestName="payments_tab__project__construction_holdback__value"
              />
              <LabelAndValue
                label="Original borrower equity"
                text={currencyFormatter(originalBorrowerEquity, '-')}
                textDataTestName="payments_tab__project__original_borrower_equity__value"
              />
            </Stack>
          </StyledBox>
        </Stack>
      );
    }
    default:
      return null;
  }
};

export default ProjectPaymentSummary;
