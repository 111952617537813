import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AuthContext } from '@context';
import { IPermission, QueryNamesEnums } from '@interfaces';
import { getProjectPermissions } from '@globalService';

export const PermissionsContext = createContext({
  permissions: null,
});

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState<null | string[]>();
  const { user } = useContext(AuthContext);
  const { projectId } = useParams();
  const commonPermissions = user?.permissions;

  const projectPermissionsQuery = useQuery<IPermission[], Error>(
    [QueryNamesEnums.GET_PROJECT_PERMISSIONS, { projectId }],
    getProjectPermissions.bind(this, projectId),
    { enabled: Boolean(projectId) && user?.agreed_to_terms },
  );

  useEffect(() => {
    if (user?.agreed_to_terms && commonPermissions) {
      const restrictions = [];
      const resultPermission =
        projectId && projectPermissionsQuery.data
          ? [
              ...projectPermissionsQuery.data,
              ...commonPermissions.filter(
                (item) => !projectPermissionsQuery.data.find((x) => x.name === item.name),
              ),
            ]
          : [...commonPermissions];
      resultPermission.forEach((item) => {
        if (!item.enabled) restrictions.push(item.name);
      });

      setPermissions(restrictions);
    }
  }, [commonPermissions, projectId, projectPermissionsQuery.data, user?.agreed_to_terms]);

  return (
    <PermissionsContext.Provider value={{ permissions }}>{children}</PermissionsContext.Provider>
  );
};
