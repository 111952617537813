import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { StatusChip } from '@components';
import { SectionWrapper } from '../index';
import { ChecklistItemProps } from '../../main/interfaces';
import { colors } from '@theme';
import { getChecklistEditedByCompany } from '@utils';
import snakeCase from 'lodash/snakeCase';

const AttestationItem: FC<ChecklistItemProps> = ({
  checklistController,
  checklistItem,
  validationController,
  innerRef,
}) => {
  const { handleDone, handleSkip } = checklistController;
  const sectionKey = snakeCase(checklistItem.label);
  const isSectionDone = validationController.validatedChecklist?.[sectionKey]?.isDone;
  const isAdditional = Boolean(checklistItem.created_by_team);
  const isSectionSkipped = validationController.validatedChecklist?.[sectionKey]?.isSkipped;

  return (
    <>
      <SectionWrapper
        title={
          <Stack direction="row" alignItems="center">
            {checklistItem.label}
            <Box component="span" sx={{ ml: 1 }} />
            {isAdditional && validationController.isAdditionalRequirementResubmit && (
              <StatusChip
                color={colors.status.orange.medium}
                backgroundColor={colors.status.orange.lighter}
                label="Additional requirement"
              />
            )}
          </Stack>
        }
        sectionKey={sectionKey}
        controller={validationController}
        handleSkip={() => {
          if (isSectionSkipped) {
            validationController.onSectionButtonsClick({
              sectionKey,
              key: 'isSkipped',
              isChecklist: true,
            });
          }
          handleSkip(checklistItem.id, checklistItem.label, !isSectionSkipped);
        }}
        handleDone={() => {
          handleDone({
            checklistItemId: checklistItem.id,
            shouldComplete: !isSectionDone,
          });
        }}
        isChecklist
        tooltipText={isAdditional ? getChecklistEditedByCompany(checklistItem.historyEvents) : ''}
        innerRef={innerRef}
        itemType={checklistItem.type}
        isEditable={isAdditional}
      >
        {checklistItem.description && (
          <Stack sx={{ ml: 8, mt: 1, mr: 8 }}>
            <Typography
              variant="body1"
              data-cy={`request__submission__policy_items__description`}
              dangerouslySetInnerHTML={{ __html: checklistItem.description }}
            />
          </Stack>
        )}
      </SectionWrapper>
      <Box sx={{ mb: 2 }} />
    </>
  );
};

export default AttestationItem;
