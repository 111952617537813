import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.status.success.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4879 5.88467L13.5121 2.40824C12.5764 1.86392 11.4236 1.86392 10.4879 2.40824L4.51206 5.88467C3.5764 6.42899 3 7.43493 3 8.52357V15.4764C3 16.5651 3.57639 17.571 4.51206 18.1153L10.4879 21.5918C11.4236 22.1361 12.5764 22.1361 13.5121 21.5918L19.4879 18.1153C20.4236 17.571 21 16.5651 21 15.4764V8.52357C21 7.43493 20.4236 6.42899 19.4879 5.88467ZM11.496 4.16751C11.8079 3.98607 12.1921 3.98607 12.504 4.16751L18.4799 7.64394C18.7918 7.82538 18.9839 8.16069 18.9839 8.52357V15.4764C18.9839 15.8393 18.7918 16.1746 18.4799 16.3561L12.504 19.8325C12.1921 20.0139 11.8079 20.0139 11.496 19.8325L5.5201 16.3561C5.20821 16.1746 5.01608 15.8393 5.01608 15.4764V8.52357C5.01608 8.16069 5.20821 7.82538 5.5201 7.64394L11.496 4.16751ZM15.3346 10.567C15.6626 10.1137 15.5639 9.47837 15.114 9.14787C14.6642 8.81737 14.0336 8.91688 13.7056 9.37014L11.547 12.353L10.7743 11.3695C10.4287 10.9297 9.79472 10.8554 9.35822 11.2036C8.92172 11.5518 8.848 12.1906 9.19357 12.6305L10.7896 14.6619C10.9852 14.9108 11.285 15.0532 11.5999 15.0469C11.9149 15.0407 12.2089 14.8863 12.3945 14.6299L15.3346 10.567Z"
      fill={color}
    />
  </svg>
);

export default Icon;
