import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useExportMilestoneListToCSV } from './controller';
import { ComponentProps } from './interface';
import { CSVLink } from 'react-csv';

const ExportMilestoneListToCSV: FC<ComponentProps> = ({ columns, milestones, totals }) => {
  const { csvData, csvHeaders } = useExportMilestoneListToCSV({
    columns,
    milestones,
    totals,
  });

  return (
    <Stack mr={1}>
      <CSVLink
        data={csvData}
        filename={`request-items.csv`}
        headers={csvHeaders}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="body3SemiBold">Export to CSV</Typography>
      </CSVLink>
    </Stack>
  );
};

export default ExportMilestoneListToCSV;
