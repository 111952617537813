import React, { memo, FC } from 'react';
import { Typography, Tooltip, Stack } from '@mui/material';
import { MilestoneListColumnType, CommonRowType, ColumnLabel, PseudoCell } from './common';
import { ColumnWidth, ColumnAlign, ColumnV2Width } from '@interfaces';
import { getEllipsisStyle } from '@utils/decorators';

const LineItemName: FC<{ row: CommonRowType }> = ({ row }) => {
  return (
    <>
      <PseudoCell>
        <Tooltip
          sx={{ cursor: 'pointer' }}
          disableHoverListener={row.external_id?.toString()?.length < 10}
          title={row.external_id}
        >
          <Typography
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow={row.external_id?.toString()?.length > 10 ? 'hidden' : 'visible'}
            variant="body3SemiBold"
          >
            {row.external_id}
          </Typography>
        </Tooltip>
      </PseudoCell>
      <Tooltip title={row.name?.length > 25 ? row.name : ''}>
        <Typography
          component="div"
          align={ColumnAlign.TEXT}
          sx={getEllipsisStyle('100%')}
          variant="body3"
        >
          {row.name}
        </Typography>
      </Tooltip>
    </>
  );
};

const Memoized = memo(LineItemName);

const description: MilestoneListColumnType = {
  name: 'name',
  columnText: 'Line item',
  width: ColumnWidth.NAME,
  justifyContent: 'flex-start',
  Header: (
    <Stack alignItems="center" flexDirection="row">
      <PseudoCell>
        <ColumnLabel>External ID</ColumnLabel>
      </PseudoCell>
      <ColumnLabel align={ColumnAlign.TEXT}>Line item</ColumnLabel>
    </Stack>
  ),
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => (
    <Typography component="div" align={ColumnAlign.TEXT} variant="body3SemiBold">
      {row?.displayAll ? 'TOTAL (ALL)' : 'TOTAL'}
    </Typography>
  ),
  minWidth: () => ColumnV2Width.NAME_MIN,
  maxWidth: () => ColumnV2Width.NAME_MAX,
};

export default description;
