import React from 'react';

const ContactSupportImage = () => (
  <svg width="71" height="50" viewBox="0 0 71 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_25459_287)">
      <path
        d="M31.0501 44.0852L31.1562 41.7212C32.2625 41.1353 33.4963 40.8259 34.7507 40.8197C33.0243 42.2181 33.2401 44.9137 32.0696 46.7942C31.7 47.378 31.2026 47.8719 30.6142 48.2392C30.0258 48.6066 29.3614 48.8381 28.6705 48.9165L27.2258 49.7929C27.0275 48.698 27.0712 47.5735 27.3539 46.4969C27.6365 45.4203 28.1513 44.4173 28.8629 43.5568C29.267 43.078 29.7302 42.6515 30.2418 42.2874C30.5878 43.1913 31.0501 44.0852 31.0501 44.0852Z"
        fill="#F7F7F7"
      />
      <path
        d="M40.2339 11.1943C40.2719 11.7317 40.4317 12.2538 40.7014 12.7216C41.2699 13.7107 42.2447 14.4268 43.2946 14.8525C44.6063 15.3843 46.0685 15.4007 47.4606 15.2821C48.7536 15.172 50.0334 14.9167 51.3311 14.8641C52.3347 14.8322 53.3393 14.856 54.3402 14.9352C56.2822 15.0717 58.216 15.3251 60.1374 15.6323C60.6577 15.7144 61.177 15.8027 61.6952 15.8971C61.7441 15.9014 61.7926 15.9103 61.8399 15.9238C61.8493 15.9276 61.8644 15.9246 61.873 15.93C61.8886 15.9397 61.8097 15.9052 61.8576 15.9444C61.9242 16.0137 61.9837 16.0895 62.035 16.1706L62.7446 17.0753C62.9481 17.3348 63.1369 17.65 63.3999 17.8546C63.5854 17.9965 63.8091 18.0809 64.0429 18.097C64.2767 18.1131 64.51 18.0603 64.7135 17.9453C64.9262 17.8122 65.0957 17.6213 65.202 17.3955C65.3653 17.0989 65.56 16.8204 65.7829 16.5648C66.6271 15.589 67.8005 14.9889 68.7818 14.1678C69.2572 13.7864 69.6629 13.327 69.9812 12.8095C70.2528 12.3412 70.4217 11.8214 70.4768 11.2841C70.5943 10.193 70.2563 9.08019 69.6879 8.15191C69.0696 7.15462 68.1814 6.34915 67.1242 5.82699C65.9365 5.22749 64.6223 4.93548 63.3049 4.78996C61.9062 4.63546 60.4912 4.63594 59.0857 4.65154C57.5083 4.66893 55.9338 4.73766 54.3621 4.85771C52.7883 4.9784 51.2207 5.14996 49.6593 5.37237C48.161 5.58579 46.6445 5.80823 45.1779 6.18586C43.8824 6.51946 42.5904 7.0409 41.623 7.98568C40.7628 8.82572 40.1645 9.97527 40.2339 11.1943Z"
        fill="#F7F7F7"
      />
      <path
        d="M47.079 6.80847C45.6803 7.06413 44.2166 7.37715 43.043 8.13531C41.8824 8.88516 41.1768 10.064 41.2454 11.1387C41.3457 12.7084 43.0301 13.8822 44.5641 14.1897C46.0336 14.4842 47.5642 14.2848 49.1847 14.0735C49.5433 14.0268 49.9141 13.9784 50.281 13.9364C54.27 13.4796 61.7599 14.8867 62.0769 14.9468L62.4062 15.0093L64.1497 17.2322C64.5466 16.3568 65.2366 15.5782 65.9694 14.9981C66.3048 14.7325 66.6548 14.4859 66.9932 14.2475C67.8567 13.6391 68.6723 13.0645 69.1224 12.275C70.1027 10.5558 68.9443 8.15845 67.2916 7.08221C65.4296 5.8697 62.9758 5.67951 60.6016 5.65455C60.3543 5.65195 60.1069 5.65065 59.8594 5.65065C55.5726 5.65129 51.2948 6.03883 47.079 6.80847Z"
        fill="white"
      />
      <path
        d="M45.9631 8.7085C45.963 8.66083 45.9724 8.61363 45.9908 8.56957C46.0092 8.52552 46.0361 8.48548 46.0701 8.45174C46.1041 8.418 46.1444 8.39123 46.1889 8.37294C46.2333 8.35466 46.2809 8.34522 46.329 8.34517H63.166C63.2631 8.34517 63.3563 8.38341 63.425 8.45148C63.4937 8.51954 63.5323 8.61186 63.5323 8.70812C63.5323 8.80438 63.4937 8.8967 63.425 8.96476C63.3563 9.03283 63.2631 9.07107 63.166 9.07107H46.329C46.232 9.07097 46.139 9.03274 46.0704 8.96476C46.0018 8.89679 45.9632 8.80463 45.9631 8.7085Z"
        fill="#F7F7F7"
      />
      <path
        d="M45.9631 10.9363C45.963 10.8887 45.9725 10.8415 45.9908 10.7974C46.0092 10.7534 46.0361 10.7133 46.0701 10.6796C46.1041 10.6458 46.1444 10.6191 46.1889 10.6008C46.2333 10.5825 46.2809 10.5731 46.329 10.573H63.1667C63.2148 10.5731 63.2625 10.5825 63.3069 10.6008C63.3513 10.6191 63.3917 10.6459 63.4257 10.6796C63.4596 10.7133 63.4866 10.7534 63.5049 10.7974C63.5233 10.8415 63.5327 10.8887 63.5327 10.9363C63.5326 10.984 63.5231 11.0312 63.5046 11.0752C63.4862 11.1192 63.4592 11.1592 63.4251 11.1929C63.3911 11.2265 63.3506 11.2532 63.3062 11.2714C63.2617 11.2896 63.2141 11.299 63.166 11.2989H46.329C46.232 11.2988 46.139 11.2606 46.0704 11.1926C46.0018 11.1246 45.9632 11.0325 45.9631 10.9363Z"
        fill="#F7F7F7"
      />
      <path
        d="M60.8379 13.1641C60.8379 13.1165 60.8473 13.0693 60.8657 13.0252C60.884 12.9811 60.911 12.9411 60.9449 12.9074C60.9789 12.8736 61.0193 12.8469 61.0637 12.8286C61.1081 12.8103 61.1558 12.8008 61.2039 12.8008H63.166C63.2631 12.8008 63.3563 12.839 63.425 12.9071C63.4937 12.9752 63.5323 13.0675 63.5323 13.1637C63.5323 13.26 63.4937 13.3523 63.425 13.4204C63.3563 13.4885 63.2631 13.5267 63.166 13.5267H61.2039C61.1068 13.5266 61.0138 13.4884 60.9452 13.4204C60.8766 13.3524 60.838 13.2603 60.8379 13.1641Z"
        fill="#F7F7F7"
      />
      <path
        d="M24.0124 6.93873C23.8373 6.53792 23.5716 6.18233 23.2357 5.89943C22.8999 5.61653 22.5029 5.41389 22.0755 5.30714C21.1264 5.06257 19.7368 5.43513 19.0233 5.97007C17.7183 6.94857 17.3998 10.3101 18.2964 11.3724C18.4748 11.3652 19.0902 11.36 19.2707 11.3549L19.526 10.7147V11.3486C20.9349 11.3158 22.4017 11.3364 23.8693 11.3576C24.0008 10.6055 24.3142 7.66221 24.0124 6.93873Z"
        fill="#1F4739"
      />
      <path
        d="M23.7732 47.4731H25.0583L25.6696 42.5622L23.773 42.5623L23.7732 47.4731Z"
        fill="#FAC9AD"
      />
      <path
        d="M12.524 46.6814L13.7675 47.0024L15.6093 42.4032L13.774 41.9294L12.524 46.6814Z"
        fill="#FAC9AD"
      />
      <path
        d="M19.6056 17.3114L19.3145 18.0614L19.3727 18.8115L25.0713 20.767L24.7885 17.4845L24.1479 16.7343L19.6056 17.3114Z"
        fill="#FAC9AD"
      />
      <path
        d="M22.0504 10.5616C23.1744 10.5616 24.0857 9.65878 24.0857 8.54509C24.0857 7.4314 23.1744 6.52858 22.0504 6.52858C20.9263 6.52858 20.015 7.4314 20.015 8.54509C20.015 9.65878 20.9263 10.5616 22.0504 10.5616Z"
        fill="#FAC9AD"
      />
      <path
        d="M23.4488 5.91925C23.0578 5.68304 22.6098 5.55526 22.1518 5.54928C21.6939 5.5433 21.2427 5.65933 20.8455 5.88524C20.4482 6.11115 20.1196 6.43865 19.8938 6.83349C19.6681 7.22833 19.5535 7.676 19.5622 8.12971C20.6716 8.41065 21.8539 8.52816 23.1068 8.19737L23.3932 7.50291L23.5621 8.19788C23.9454 8.46502 24.3153 8.46454 24.6719 8.19644C24.6871 7.74401 24.5821 7.29558 24.3674 6.89597C24.1528 6.49635 23.836 6.15956 23.4488 5.91925Z"
        fill="#1F4739"
      />
      <path
        d="M23.4545 46.845L25.4859 46.7249V47.5872L27.4172 48.9087C27.5126 48.974 27.5844 49.0677 27.6222 49.1763C27.66 49.2849 27.6618 49.4026 27.6273 49.5122C27.5928 49.6219 27.5238 49.7177 27.4305 49.7858C27.3371 49.8539 27.2242 49.8906 27.1083 49.8906H24.6898L24.273 49.0376L24.1102 49.8906H23.1984L23.4545 46.845Z"
        fill="#1F4739"
      />
      <path
        d="M12.2628 45.9649L14.2591 46.3563L14.0396 47.1907L15.572 48.9521C15.6477 49.0391 15.6933 49.1478 15.7023 49.2623C15.7112 49.3768 15.683 49.4912 15.6217 49.5886C15.5604 49.6861 15.4692 49.7616 15.3616 49.8042C15.2539 49.8467 15.1353 49.854 15.0231 49.825L12.6829 49.2206L12.4966 48.2911L12.122 49.0758L11.2396 48.8479L12.2628 45.9649Z"
        fill="#1F4739"
      />
      <path
        d="M12.6817 43.5931L16.4484 35.9228L17.6309 26.5871C16.8156 25.087 18.3297 22.0346 18.3297 22.0346V20.753L24.1949 21.8493C25.3945 25.091 25.9763 28.5253 25.9105 31.9774L25.6742 44.3871H25.7483C25.8714 44.3871 25.9893 44.4356 26.0763 44.5217C26.1633 44.6079 26.2122 44.7248 26.2122 44.8467C26.2122 44.9686 26.1633 45.0855 26.0763 45.1717C25.9893 45.2579 25.8714 45.3063 25.7483 45.3063H23.429C23.306 45.3063 23.188 45.2579 23.101 45.1717C23.014 45.0855 22.9651 44.9686 22.9651 44.8467C22.9651 44.7248 23.014 44.6079 23.101 44.5217C23.188 44.4356 23.306 44.3871 23.429 44.3871H23.4888L21.8238 28.5998L19.8878 36.3904L15.2092 43.5636C15.3286 43.5728 15.4397 43.6275 15.5192 43.7161C15.5987 43.8048 15.6404 43.9205 15.6356 44.039C15.6308 44.1575 15.5798 44.2696 15.4934 44.3516C15.407 44.4337 15.2918 44.4793 15.1721 44.479H12.8528C12.7449 44.4792 12.6403 44.442 12.5572 44.3739C12.474 44.3058 12.4174 44.2112 12.3971 44.1062C12.3769 44.0012 12.3942 43.8925 12.4461 43.7989C12.4981 43.7052 12.5814 43.6324 12.6817 43.5931Z"
        fill="#1F4739"
      />
      <path
        d="M17.7994 22.8203C17.8187 22.7453 17.8614 22.6783 17.9215 22.6289C17.9816 22.5794 18.056 22.5501 18.1339 22.5451C18.2112 21.6693 18.722 16.0531 18.7415 15.8883L18.7422 15.8813L18.8901 12.7508C18.8955 12.6371 18.943 12.5294 19.0234 12.4482C19.1039 12.367 19.2118 12.318 19.3264 12.3105L20.1076 12.2599C20.1183 12.2596 20.1285 12.2551 20.136 12.2475C20.1434 12.2398 20.1476 12.2296 20.1476 12.219C20.1476 12.1335 20.1646 12.0489 20.1976 11.9699C20.2306 11.8909 20.279 11.8192 20.34 11.7587C20.401 11.6983 20.4734 11.6504 20.5531 11.6176C20.6328 11.5849 20.7183 11.5681 20.8045 11.5681H23.1175C23.2673 11.5682 23.412 11.6224 23.5243 11.7206C23.6366 11.8188 23.7089 11.9543 23.7275 12.1016C23.7286 12.1117 23.7334 12.1211 23.7411 12.1278C23.7488 12.1346 23.7588 12.1382 23.7691 12.138L23.7694 12.138C24.0322 12.1326 24.2883 12.2208 24.4911 12.3865C24.6939 12.5521 24.8301 12.7844 24.8748 13.041C25.191 14.8638 25.8248 19.0009 25.5114 21.3009C25.4024 22.1015 25.4519 23.3057 25.4932 23.9756C25.4973 24.0404 25.4884 24.1053 25.4672 24.1666C25.4459 24.228 25.4126 24.2846 25.3693 24.3332C25.4615 24.3595 25.5398 24.4203 25.5877 24.5027C25.6356 24.5852 25.6493 24.6828 25.6259 24.7751C25.6019 24.8696 25.5409 24.9508 25.4564 25.0008C25.3719 25.0508 25.2708 25.0655 25.1754 25.0417L18.0686 23.2667C17.9731 23.2429 17.8912 23.1825 17.8407 23.0987C17.7902 23.015 17.7754 22.9149 17.7994 22.8203Z"
        fill="#1F4739"
      />
      <path
        d="M39.3326 50H0.610463C0.581167 50 0.55307 49.9885 0.532354 49.9679C0.511638 49.9474 0.5 49.9196 0.5 49.8906C0.5 49.8615 0.511638 49.8337 0.532354 49.8132C0.55307 49.7926 0.581167 49.7811 0.610463 49.7811H39.3326C39.3619 49.7811 39.39 49.7926 39.4107 49.8132C39.4315 49.8337 39.4431 49.8615 39.4431 49.8906C39.4431 49.9196 39.4315 49.9474 39.4107 49.9679C39.39 49.9885 39.3619 50 39.3326 50Z"
        fill="#D2D2D2"
      />
      <path
        d="M18.127 28.1017C18.2204 28.0087 18.292 27.8966 18.3369 27.7732C18.3818 27.6498 18.3989 27.5182 18.3869 27.3876C18.3749 27.257 18.3341 27.1305 18.2674 27.0172C18.2008 26.9039 18.1099 26.8064 18.0011 26.7317L19.4428 19.6369L17.8507 20.1434L16.6967 27.0058C16.5821 27.1952 16.5416 27.4196 16.5828 27.6366C16.6241 27.8536 16.7443 28.0481 16.9206 28.1832C17.0969 28.3183 17.3171 28.3846 17.5394 28.3696C17.7618 28.3545 17.9708 28.2592 18.127 28.1017Z"
        fill="#FAC9AD"
      />
      <path
        d="M20.2558 13.091L19.268 12.3523C19.268 12.3523 18.3965 12.2911 18.2422 13.8481C18.0879 15.4051 17.771 18.4394 17.771 18.4394L16.2594 25.3757L18.3518 25.8307L19.9708 18.3452L20.9421 16.1223L20.2558 13.091Z"
        fill="#1F4739"
      />
      <path
        d="M33.0856 15.0994C32.9623 15.0517 32.8299 15.0314 32.6978 15.0398C32.5657 15.0483 32.4371 15.0853 32.321 15.1483C32.2049 15.2114 32.1042 15.2989 32.0259 15.4046C31.9476 15.5104 31.8937 15.6319 31.868 15.7605L24.7267 17.2822L25.8307 18.5264L32.6422 16.8361C32.8632 16.8647 33.0871 16.8119 33.2714 16.6878C33.4558 16.5637 33.5878 16.377 33.6425 16.1629C33.6971 15.9488 33.6706 15.7223 33.568 15.5263C33.4653 15.3303 33.2937 15.1784 33.0856 15.0994Z"
        fill="#BCBCBC"
      />
      <path
        d="M23.6044 18.9414C23.3762 18.7229 23.2046 18.4531 23.1041 18.1549L21.8001 14.2704C21.7049 13.9866 21.69 13.6824 21.7571 13.3908C21.8242 13.0992 21.9707 12.8315 22.1807 12.6167C22.3907 12.4019 22.6562 12.2482 22.9481 12.1725C23.2401 12.0967 23.5475 12.1017 23.8368 12.1869C24.1635 12.2836 24.452 12.478 24.6629 12.7435C24.8737 13.0089 24.9966 13.3324 25.0146 13.6698L25.1743 16.7267L30.4439 15.9622L31.8136 17.2096L31.7396 17.2346C31.4486 17.3328 24.7882 19.5697 23.6857 18.998C23.656 18.983 23.6286 18.964 23.6044 18.9414Z"
        fill="#1F4739"
      />
      <path
        d="M33.2941 12.6253C33.2849 12.6253 33.2757 12.6251 33.2664 12.6248C33.1581 12.6209 33.0524 12.5911 32.9582 12.5381C32.864 12.485 32.7841 12.4101 32.7254 12.3199C32.2854 11.6684 31.7455 11.089 31.1252 10.6026C30.7497 10.3052 30.3572 10.0287 29.9778 9.76121C28.9987 9.07152 28.0768 8.42195 27.565 7.52441C26.4491 5.56727 27.7632 2.84126 29.6405 1.61874C31.7462 0.24761 34.5158 0.0325377 37.1952 0.00435221C37.4736 0.00143476 37.7523 -1.51994e-05 38.0311 1.20124e-07C42.8587 0.0018341 47.6761 0.438261 52.424 1.3039C54.0028 1.59249 55.6554 1.94602 56.9834 2.80397C58.3035 3.65685 59.1059 4.99993 59.0276 6.22573C58.9133 8.01519 56.9987 9.3523 55.2553 9.70188C53.5935 10.0348 51.8671 9.80982 50.0395 9.57154C49.636 9.51899 49.2189 9.46459 48.8063 9.41733C44.3228 8.90389 35.9003 10.4865 35.544 10.5541L35.1904 10.6211L33.8188 12.37C33.7571 12.4497 33.6776 12.5142 33.5866 12.5585C33.4956 12.6028 33.3955 12.6257 33.2941 12.6253Z"
        fill="#5E8376"
      />
      <path
        d="M53.2596 3.89569H34.3094C34.2002 3.89553 34.0954 3.85241 34.0182 3.77582C33.9411 3.69923 33.8977 3.59542 33.8977 3.48718C33.8977 3.37894 33.9411 3.27513 34.0182 3.19854C34.0954 3.12195 34.2002 3.07884 34.3094 3.07867H53.2596C53.3689 3.07884 53.4736 3.12195 53.5508 3.19854C53.628 3.27513 53.6713 3.37894 53.6713 3.48718C53.6713 3.59542 53.628 3.69923 53.5508 3.77582C53.4736 3.85241 53.3689 3.89553 53.2596 3.89569Z"
        fill="#BCBCBC"
      />
      <path
        d="M53.2596 6.40313H34.3094C34.2552 6.40325 34.2015 6.39278 34.1514 6.37233C34.1013 6.35188 34.0558 6.32184 34.0174 6.28394C33.9791 6.24604 33.9486 6.20102 33.9278 6.15145C33.907 6.10188 33.8963 6.04874 33.8962 5.99505C33.8962 5.94137 33.9068 5.8882 33.9275 5.83859C33.9482 5.78898 33.9785 5.74389 34.0168 5.70591C34.0551 5.66794 34.1006 5.63781 34.1506 5.61725C34.2007 5.5967 34.2544 5.58612 34.3085 5.58612H53.2596C53.3689 5.58628 53.4736 5.62939 53.5508 5.70598C53.628 5.78258 53.6713 5.88639 53.6713 5.99463C53.6713 6.10286 53.628 6.20668 53.5508 6.28327C53.4736 6.35986 53.3689 6.40297 53.2596 6.40313Z"
        fill="#BCBCBC"
      />
      <path
        d="M36.5177 8.91055H34.3094C34.2 8.91055 34.0952 8.86751 34.0178 8.7909C33.9405 8.71429 33.8971 8.61039 33.8971 8.50204C33.8971 8.3937 33.9405 8.28979 34.0178 8.21318C34.0952 8.13657 34.2 8.09354 34.3094 8.09354H36.5177C36.6271 8.09354 36.732 8.13657 36.8093 8.21318C36.8866 8.28979 36.9301 8.3937 36.9301 8.50204C36.9301 8.61039 36.8866 8.71429 36.8093 8.7909C36.732 8.86751 36.6271 8.91055 36.5177 8.91055Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_25459_287">
        <rect width="70" height="50" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default ContactSupportImage;
