import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AuthContext, PermissionsContext } from '@context';
import {
  getActiveDrawRequestFromList,
  getDrawRequestForApproval,
  isCustomer,
  isDrawRequest,
  isInspectionServiceManual,
  isPseudoAutomatedInspection,
  isRequestDraft,
  isRestricted,
  isServiceProviderAutomatedByCS,
  isServiceProviderManual,
  isTruePicInspection,
} from '@utils';
import { PermissionNamesEnums, QueryNamesEnums, ServiceOrderStatusEnum } from '@interfaces';
import {
  getDrawRequest,
  getProjectInspectionsList,
  getProjectServiceOrdersList,
  getWaitingYourApproval,
} from '@globalService';
import memoize from 'lodash/memoize';

export const TABS = {
  OVERVIEW: { label: 'Overview', value: 'overview' },
  BUDGET: { label: 'Budget', value: 'budget' },
  DRAWS: { label: 'Requests', value: 'draws' },
  DOCUMENTS: { label: 'Documents', value: 'documents' },
  INSPECTIONS: { label: 'Inspections', value: 'inspections' },
  SERVICES: { label: 'Services', value: 'services' },
  PAYMENTS: { label: 'Payments', value: 'payments' },
  PHOTOS: { label: 'Photos', value: 'photos' },
  POLICIES: { label: 'Policies', value: 'policies' },
};

export const FIRST_TAB_VALUE_MAP = {
  documents: 'All',
  payments: 'Project',
  inspections: 'All',
  services: 'All',
  photos: 'All',
};

export const getFirstTabText = (tab) => FIRST_TAB_VALUE_MAP[tab];

export const getDrawRequestInProgress = memoize(getActiveDrawRequestFromList);

export const getDrawLabel = (draw, DRList) => {
  const label = isDrawRequest(draw) ? 'Draw' : 'Change';
  const prefix =
    getDrawRequestInProgress(DRList)?.id === draw.id
      ? `Active ${label}`
      : isRequestDraft(draw.status)
        ? draw.is_resubmit
          ? `Returned ${label}`
          : `Draft ${label}`
        : label;
  return `${prefix} #${draw.counter_per_request_type}`;
};

export const getDataTestNameLabel = (draw) => {
  const type = isDrawRequest(draw) ? 'draw' : 'change';
  return `${type}__${draw.counter_per_request_type}`;
};

export const useGetActionTabs = () => {
  const { projectId } = useParams();
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);

  const drawRequestsQuery = useQuery(
    [QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL, { projectId }],
    () => getWaitingYourApproval(projectId),
  );

  const drawRequestForApproval = useMemo(
    () => getDrawRequestForApproval(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const drawRequestData = useQuery(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId: drawRequestForApproval?.id }],
    () => getDrawRequest({ projectId, drawRequestId: drawRequestForApproval?.id }),
    { enabled: Boolean(drawRequestForApproval && projectId) },
  );
  const stringQueryParams = `status=${ServiceOrderStatusEnum.ORDERED},${ServiceOrderStatusEnum.READY_FOR_REVIEW}`;

  const { data: { results: projectInspections } = {} } = useQuery(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId, stringQueryParams }],
    () => getProjectInspectionsList({ projectId, stringQueryParams }),
    { enabled: !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions) },
  );

  const { data: { results: projectServiceOrders } = {} } = useQuery(
    [QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { projectId, stringQueryParams }],
    () => getProjectServiceOrdersList({ projectId, stringQueryParams }),
    { enabled: !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions) },
  );

  const activeManualInspection = useMemo(
    () =>
      projectInspections?.find(({ inspection_agency }) =>
        isInspectionServiceManual(inspection_agency?.service),
      ),
    [projectInspections],
  );

  const activeAutomatedByCSInspection = useMemo(
    () =>
      projectInspections?.find(({ inspection_agency }) =>
        isPseudoAutomatedInspection(inspection_agency?.service),
      ),
    [projectInspections],
  );

  const waitingForReviewInspection = useMemo(
    () =>
      projectInspections?.find(({ inspection_agency }) =>
        isTruePicInspection(inspection_agency?.service),
      ),
    [projectInspections],
  );

  const activeManualService = useMemo(
    () =>
      projectServiceOrders?.find(({ service_agency }) =>
        isServiceProviderManual(service_agency?.service_provider),
      ),
    [projectServiceOrders],
  );

  const activeAutomatedByCSService = useMemo(
    () =>
      projectServiceOrders?.find(({ service_agency }) =>
        isServiceProviderAutomatedByCS(service_agency?.service_provider),
      ),
    [projectServiceOrders],
  );

  return {
    Requests:
      drawRequestForApproval &&
      !drawRequestData.data?.totals?.all?.approved_amount &&
      isCustomer(user),
    Services:
      ((activeAutomatedByCSService ||
        activeAutomatedByCSInspection ||
        waitingForReviewInspection) &&
        !isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)) ||
      ((activeManualService || activeManualInspection || waitingForReviewInspection) &&
        isCustomer(user)),
    Payments: false,
  };
};
