import { useCallback, useEffect, useContext } from 'react';
import { useSafeSnackbar } from '@hooks';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import {
  DropdownFieldModel,
  StringFieldModel,
  useDropdownFieldModel,
  useStringFieldModel,
} from '@models';
import { addCompanyTeam, getRolesPermissionsList } from '@globalService';
import {
  AddTeamPayload,
  QueryNamesEnums,
  ITeam,
  IRolePermissions,
  EnumTypeForList,
} from '@interfaces';
import { getTeamRole, getDisplayRolesList } from '@utils';
import { AuthContext } from '@context';

interface ControllerInterface {
  teamName: StringFieldModel;
  teamPermissionRole: DropdownFieldModel;
  handleAddTeam: () => void;
  isSubmitting: boolean;
  rolesList: EnumTypeForList[];
}

export const useAddTeamPopup = ({
  onClose,
  nextApprovalLevel,
}: {
  onClose: () => void;
  nextApprovalLevel: number;
}): ControllerInterface => {
  const { companyId } = useParams();

  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);

  const rolesPermissionsListQuery = useQuery<IRolePermissions[], Error>(
    [QueryNamesEnums.GET_ROLES_WITH_PERMISSIONS],
    getRolesPermissionsList.bind(this),
  );

  const teamName = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const teamPermissionRole = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
    validateOnChange: true,
  });

  useEffect(() => {
    return () => {
      teamName.setValue('');
      teamPermissionRole.setValue(null);
    };
  }, []);

  const handleAddTeam = useCallback(() => {
    const isTeamNameValid = teamName.validate();
    const isTeamPermissionRoleValid = teamPermissionRole.validate();

    if (isTeamNameValid && isTeamPermissionRoleValid) {
      addTeamMutation.mutateAsync({
        company: companyId,
        name: teamName.value,
        role: teamRole,
        permission_role: teamPermissionRole.value?.id,
        approval_level: nextApprovalLevel || 1,
      });
    }
  }, [companyId, teamName, teamPermissionRole, teamRole]);

  const addTeamMutation = useMutation<ITeam, Error, AddTeamPayload>(addCompanyTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }]);
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return {
    teamName,
    teamPermissionRole,
    handleAddTeam,
    isSubmitting: addTeamMutation.isLoading,
    rolesList: getDisplayRolesList(rolesPermissionsListQuery.data),
  };
};
