import React, { Dispatch, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { createDateAsLocal, getHookState, isCompletedProject } from '@utils';
import {
  HookState,
  IProject,
  IProjectProperty,
  IPropertyDetailLocal,
  QueryNamesEnums,
} from '@interfaces';
import { getProject, getProjectBuildings, getProjectModels } from '@globalService';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { statusMap } from '@constants';
import { useProjectDetailsFields } from '@hooks';

export interface ControllerInterface {
  project: IProject;
  projectProperty: IPropertyDetailLocal[];
  projectType: string;
  exitStrategy: string;
  isLongDescription: boolean;
  state: HookState;
  completion: {
    title: string;
    value: Date | string;
    isCompletedProject: boolean;
  };
  duration: {
    title: string;
    value: number;
  };
  contractual: string | Date;
  setAnchorEl: Dispatch<React.SetStateAction<EventTarget & HTMLSpanElement>>;
  anchorEl: Element;
  statusValue: {
    color: string;
    backgroundColor: string;
    text: string;
  };
  modelsCount: number;
  unitsCount: number;
  navigateToPHBBudget: () => void;
}

export const useProject = (projectId: string): ControllerInterface => {
  const {
    settings: { display: staticStatuses },
  } = useContext(SettingsContext);
  const navigate = useNavigate();
  const flags = useLaunchDarklyFlags();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const { propertyDetails } = useProjectDetailsFields({ project: projectQuery.data });

  const projectUnitsQuery = useQuery<{ results: IProjectProperty[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }],
    getProjectBuildings.bind(this, projectId),
  );

  const projectModelsQuery = useQuery<{ results: IProjectProperty[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }],
    getProjectModels.bind(this, projectId),
  );

  const isLongDescription = useMemo(
    () => projectQuery.data?.scope_of_work?.length > 150,
    [projectQuery.data],
  );

  const isProjectCompleted = useMemo(
    () => isCompletedProject(projectQuery.data?.status),
    [projectQuery.data?.status],
  );

  const completion = useMemo(() => {
    const date =
      projectQuery.data?.estimated_completion_date || projectQuery.data?.original_completion_date;
    return {
      title: isProjectCompleted ? 'Project completion date' : 'Estimated project completion date',
      value: flags?.['ENG_7651_customer_setting_for_local_time_zone']
        ? date
        : createDateAsLocal(date as string),
      isCompletedProject: isProjectCompleted,
    };
  }, [projectQuery.data, isProjectCompleted, flags]);

  const duration = useMemo(() => {
    if (!projectQuery.data) return;
    return {
      title: isProjectCompleted ? 'Project duration' : 'Estimated project duration',
      value: projectQuery.data?.duration,
    };
  }, [projectQuery.data, isProjectCompleted]);

  const statusValue = useMemo(
    () => (staticStatuses ? statusMap(projectQuery.data?.status, staticStatuses, 'project') : {}),
    [projectQuery.data, staticStatuses],
  );

  const modelsCount = useMemo(() => projectModelsQuery.data?.count, [projectModelsQuery.data]);
  const unitsCount = useMemo(() => projectUnitsQuery.data?.count, [projectUnitsQuery.data]);

  const navigateToPHBBudget = () => {
    if (modelsCount) return navigate(`/projects/${projectId}/budget/edit-models`);
    if (unitsCount) return navigate(`/projects/${projectId}/budget/edit-units`);
  };

  return {
    project: projectQuery.data,
    projectProperty: propertyDetails.list,
    projectType: projectQuery.data?.type,
    exitStrategy: projectQuery.data?.exit_strategy,
    isLongDescription,
    state: getHookState([projectQuery]),
    completion,
    contractual: projectQuery.data?.original_completion_date,
    duration,
    statusValue,
    anchorEl,
    setAnchorEl,
    modelsCount,
    unitsCount,
    navigateToPHBBudget,
  };
};
