import React from 'react';
import { IconButton } from '@mui/material';
import { colors } from '@theme';
import { GalleryViewIcon, MenuItemIcon } from '@svgAsComponents';

const ViewSwitcher = ({
  isListView,
  source,
  handleChangeView,
}: {
  isListView: boolean;
  source: string;
  handleChangeView: (listView: boolean) => void;
}) => {
  return (
    <>
      <ViewSwitchIcon
        onClick={() => handleChangeView(false)}
        mainColor={isListView ? colors.neutral.lightest : colors.neutral.darker}
        secondaryColor={isListView ? colors.neutral.darker : colors.neutral.white}
        Icon={GalleryViewIcon}
        dataTestName={`${source}__gallery__button`}
      />
      <ViewSwitchIcon
        onClick={() => handleChangeView(true)}
        mainColor={isListView ? colors.neutral.darker : colors.neutral.lightest}
        secondaryColor={isListView ? colors.neutral.white : colors.neutral.darker}
        Icon={MenuItemIcon}
        dataTestName={`${source}__list__button`}
      />
    </>
  );
};

const ViewSwitchIcon = ({ onClick, mainColor, secondaryColor, Icon, dataTestName }) => (
  <IconButton
    onClick={onClick}
    sx={{
      backgroundColor: mainColor,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: mainColor,
      },
      '& svg path:hover': {
        fill: secondaryColor,
      },
    }}
    data-cy={dataTestName}
  >
    <Icon color={secondaryColor} />
  </IconButton>
);

export default ViewSwitcher;
