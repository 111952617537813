import React from 'react';
import { UserListColumnType } from './common';
import { Typography } from '@mui/material';
import { useDateFormatter } from '@hooks';

const LastOnlineCell = ({ row }: { row: any }) => {
  const { dateFormatter } = useDateFormatter();
  return (
    <Typography variant="body2">
      {dateFormatter({ date: row.last_online, withTime: true })}
    </Typography>
  );
};

const lastOnline: UserListColumnType = {
  name: 'last_online',
  columnText: 'Last online',
  width: '20%',
  renderCell: ({ row }) => <LastOnlineCell row={row} />,
};

export default lastOnline;
