import React, { FC } from 'react';
import { Typography, Stack } from '@mui/material';

import { StyledBox } from '@components';
import * as Controller from './controller';
import { ICustomLink } from '@interfaces';
import { CustomNavigationLink } from '../index';

const CustomNavigationLinksBlock: FC<{
  links: ICustomLink[];
}> = ({ links }) => {
  const { linksToShow, saveCustomLink, removeCustomLink, isSubmitting } =
    Controller.useCompanyCustomLinks(links);

  return (
    <StyledBox sx={{ flex: 1 }}>
      <Typography variant="h3">Custom navigation links</Typography>
      <Typography variant="body1" sx={{ mb: 3, mt: 1 }}>
        Links will be visible to borrowers on the header of project pages.
      </Typography>
      <Stack spacing={2}>
        {linksToShow?.map((link, index) => (
          <CustomNavigationLink
            key={index}
            link={link}
            saveCallback={saveCustomLink}
            removeCallback={removeCustomLink}
            isSubmitting={isSubmitting}
          />
        ))}
      </Stack>
    </StyledBox>
  );
};

export default CustomNavigationLinksBlock;
