import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 21) * 16}
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M1.11639 0C0.564109 0 0.116394 0.447715 0.116394 1V3C0.116394 3.55228 0.564109 4 1.11639 4H3.11639C3.66868 4 4.11639 3.55228 4.11639 3V1C4.11639 0.447715 3.66868 0 3.11639 0H1.11639ZM7.11639 1C6.56411 1 6.11639 1.44772 6.11639 2C6.11639 2.55228 6.56411 3 7.11639 3H19.1164C19.6687 3 20.1164 2.55228 20.1164 2C20.1164 1.44772 19.6687 1 19.1164 1H7.11639ZM6.11639 8C6.11639 7.44772 6.56411 7 7.11639 7H19.1164C19.6687 7 20.1164 7.44772 20.1164 8C20.1164 8.55229 19.6687 9 19.1164 9H7.11639C6.56411 9 6.11639 8.55229 6.11639 8ZM1.11639 6C0.564109 6 0.116394 6.44772 0.116394 7V9C0.116394 9.55228 0.564109 10 1.11639 10H3.11639C3.66868 10 4.11639 9.55228 4.11639 9V7C4.11639 6.44772 3.66868 6 3.11639 6H1.11639ZM6.11639 14C6.11639 13.4477 6.56411 13 7.11639 13H19.1164C19.6687 13 20.1164 13.4477 20.1164 14C20.1164 14.5523 19.6687 15 19.1164 15H7.11639C6.56411 15 6.11639 14.5523 6.11639 14ZM1.11639 12C0.564109 12 0.116394 12.4477 0.116394 13V15C0.116394 15.5523 0.564109 16 1.11639 16H3.11639C3.66868 16 4.11639 15.5523 4.11639 15V13C4.11639 12.4477 3.66868 12 3.11639 12H1.11639Z"
    />
  </svg>
);

export default Icon;
