import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const LogoutIcon = ({ size = 19, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2526 16.6585C16.8889 17.0741 16.2571 17.1163 15.8415 16.7526C15.4259 16.3889 15.3837 15.7571 15.7474 15.3415L17.7962 13L8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11L17.7962 11L15.7474 8.65851C15.3837 8.24287 15.4259 7.61111 15.8415 7.24742C16.2571 6.88374 16.8889 6.92586 17.2526 7.3415L20.7526 11.3415C21.0825 11.7185 21.0825 12.2815 20.7526 12.6585L17.2526 16.6585ZM8 4C8.55229 4 9 3.55229 9 3C9 2.44772 8.55229 2 8 2L5 2C3.89543 2 3 2.89543 3 4L3 20C3 21.1046 3.89543 22 5 22L8 22C8.55229 22 9 21.5523 9 21C9 20.4477 8.55229 20 8 20L5 20L5 4L8 4Z"
      fill={color}
    />
  </svg>
);

export default LogoutIcon;
