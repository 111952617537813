import React from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { ArrowRightIcon, CloseIcon, DotIcon } from '@svgAsComponents';
import { IUndoneSection } from '../../main/interfaces';

interface ComponentProps {
  data: IUndoneSection[];
  handlePopperClose?: () => void;
  handleNavigate?: (id: string) => void;
}

const SubmissionPopoverContent = ({ data, handlePopperClose, handleNavigate }: ComponentProps) => {
  if (!data?.length) return null;

  return (
    <Stack>
      <Stack direction="row" alignItems="center" py={1} justifyContent="space-between">
        <Typography
          variant="h4"
          color={colors.status.error.medium}
          data-cy="submission__missing_requirements__popover__header__value"
        >
          {`Missing requirements (${data.length})`}
        </Typography>
        <IconButton
          sx={{ ml: 1 }}
          onClick={handlePopperClose}
          data-cy="submission__missing_requirements__popover__close__button"
        >
          <CloseIcon size={16} />
        </IconButton>
      </Stack>
      <Typography variant="body2" sx={{ mb: 2 }}>
        To proceed, please fill in the necessary information and check policy items.
      </Typography>
      {data?.map((item, index) => {
        return (
          <Stack direction="row" alignItems="flex-start" py={1} key={item.id}>
            <Stack sx={{ mr: 1, ml: 1 }}>
              <Typography variant="body1">
                <DotIcon size={8} />
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              data-cy={`submission__missing_requirements__popover__item_${index}__title`}
            >
              {item?.title}
              <Button
                size="small"
                variant="new"
                color="secondary"
                sx={{ minWidth: 24, minHeight: 24, p: 1, ml: 1 }}
                onClick={() => handleNavigate(item.id)}
                data-cy={`submission__missing_requirements__popover__navigate_${index}__button`}
              >
                <ArrowRightIcon size={16} />
              </Button>
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default SubmissionPopoverContent;
