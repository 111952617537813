import React, { FC, PropsWithChildren } from 'react';
import { Breakpoint, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';

import { colors } from '@theme';
import { DeleteIcon, EditIcon, ErrorIcon, NoValidationIcon, SuccessIcon } from '@svgAsComponents';
import { PopupTypeEnum } from '@interfaces';

const iconsMap = {
  [PopupTypeEnum.GENERAL]: EditIcon,
  [PopupTypeEnum.SUCCESS]: SuccessIcon,
  [PopupTypeEnum.ERROR]: ErrorIcon,
  [PopupTypeEnum.CONFIRMATION]: NoValidationIcon,
  [PopupTypeEnum.DELETE]: DeleteIcon,
};
const colorsMap = {
  [PopupTypeEnum.GENERAL]: colors.main.primary.main,
  [PopupTypeEnum.SUCCESS]: colors.status.success.medium,
  [PopupTypeEnum.ERROR]: colors.status.error.medium,
  [PopupTypeEnum.CONFIRMATION]: colors.status.orange.medium,
  [PopupTypeEnum.DELETE]: colors.status.error.medium,
};

const Popup: FC<
  PropsWithChildren<{
    open: boolean;
    type?: string;
    title?: string;
    text?: string | React.ReactNode;
    icon?: React.ComponentType<{ size: number; color: string }>;
    maxWidth?: false | Breakpoint;
    sx?: any;
  }>
> = ({ open, type = PopupTypeEnum.GENERAL, title, text, children, icon, maxWidth = 'sm', sx }) => {
  const Icon = icon || iconsMap[type];
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: { md: '4px', xs: 0 },
          minWidth: { md: 500, xs: '100%' },
          minHeight: { md: 'auto', xs: '100%' },
          ...(type === PopupTypeEnum.GENERAL && { width: '50%' }),
          ...sx,
        },
      }}
    >
      <Stack sx={{ p: 3, flex: 1 }}>
        <Stack alignItems="center" sx={{ mb: 3 }}>
          <Icon size={24} color={colorsMap[type]} />
          <Typography
            variant="h4"
            sx={{ color: colorsMap[type], pt: 0.5, textAlign: 'center' }}
            data-cy="popup_title"
          >
            {title}
          </Typography>
          {text && (
            <DialogContent sx={{ justifyContent: 'center' }}>
              <Typography variant="body2" sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                {text}
              </Typography>
            </DialogContent>
          )}
        </Stack>
        <DialogActions sx={{ p: 0, flex: 1, alignItems: 'normal' }}>{children}</DialogActions>
      </Stack>
    </Dialog>
  );
};

export default Popup;
