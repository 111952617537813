import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { getHookState, isAppraisalDocumentType } from '@utils';
import {
  DeleteInspectionDocument,
  DocumentContentTypeEnum,
  DocumentTypeEnum,
  HookState,
  IDocumentType,
  IProjectDocument,
  ITablePagination,
  ITransloaditRestrictions,
  ITransloaditSignature,
  QueryNamesEnums,
  TransloaditTemplateEnum,
  IDocument,
} from '@interfaces';
import {
  ConfirmModalHookInterface,
  useConfirmationModal,
  useFilesUploader,
  useSafeSnackbar,
  useTablePagination,
} from '@hooks';
import {
  deleteInspectionDocument,
  getInspectionDocuments,
  getProjectDocumentTypes,
} from '@globalService';
import { useParams } from 'react-router-dom';

export interface ControllerInterface {
  state: HookState;
  handleOpenDocumentUploader: () => void;
  isFilesUploaderOpened: boolean;
  transloaditSignature: ITransloaditSignature;
  filesUploaderClose: () => void;
  documentTypes: IDocumentType[];
  refetchDocuments: () => Promise<UseQueryResult>;
  restrictions: ITransloaditRestrictions;
  documents: IProjectDocument[] | IDocument[];
  isLoading: boolean;
  setIsAppraisal: Dispatch<SetStateAction<boolean>>;
  handleDelete: ({ documentId }: { documentId: string }) => void;
  deleteModal: ConfirmModalHookInterface;
  handleDeleteIconClick: ({
    fileName,
    documentId,
  }: {
    fileName?: string;
    documentId?: string;
  }) => void;
  selectedFileName: string;
  tablePagination: ITablePagination;
  documentsCount: number;
}

export const useInspectionRelatedDocuments = ({
  drawRequestId,
  inspectionId,
  isInspectionReports,
}): ControllerInterface => {
  const { projectId } = useParams();
  const [isAppraisal, setIsAppraisal] = useState(false);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [selectedFileName, setSelectedFileName] = useState<string>('');

  const deleteModal = useConfirmationModal();

  const tablePagination = useTablePagination({
    initialRowsPerPage: 10,
    rowsPerPageOptions: [],
  });

  const stringQueryParams = useMemo(() => {
    let string = `&offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}&excluded_document_type=${DocumentTypeEnum.APPRAISAL}`;

    if (isInspectionReports) string += `&document_type=${DocumentTypeEnum.INSPECTION_REPORT}`;
    return string;
  }, [tablePagination.page, tablePagination.rowsPerPage, isInspectionReports]);

  const inspectionDocumentsQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_INSPECTION_DOCUMENTS, { projectId, inspectionId, stringQueryParams }],
    getInspectionDocuments.bind(this, { projectId, inspectionId, stringQueryParams }),
    { enabled: Boolean(projectId && inspectionId) },
  );

  const projectDocumentTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TYPES],
    getProjectDocumentTypes.bind(this),
  );

  const {
    isFilesUploaderOpened,
    filesUploaderClose,
    transloaditSignature,
    uploadMedia,
    restrictions,
  } = useFilesUploader();

  const handleOpenDocumentUploader = useCallback(() => {
    const content_type = DocumentContentTypeEnum.INSPECTION;
    const fields = isInspectionReports
      ? { inspection_id: inspectionId }
      : { content_type, object_id: inspectionId };
    const templateType = isInspectionReports
      ? TransloaditTemplateEnum.INSPECTION_DOCUMENTS
      : TransloaditTemplateEnum.DOCUMENTS;

    uploadMedia({
      fields,
      templateType,
    });
  }, [projectId, drawRequestId, uploadMedia, inspectionId, isInspectionReports]);

  const refetchDocuments = useMemo(
    () => inspectionDocumentsQuery.refetch,
    [inspectionDocumentsQuery],
  );

  const documentTypes = useMemo(
    () =>
      projectDocumentTypesQuery.data?.filter((type) =>
        isAppraisal ? isAppraisalDocumentType(type.name) : !isAppraisalDocumentType(type.name),
      ),
    [projectDocumentTypesQuery, isAppraisal],
  );

  const deleteInspectionDocumentMutation = useMutation<Response, Error, DeleteInspectionDocument>(
    deleteInspectionDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
          { projectId, inspectionId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
          { projectId, inspectionId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleDelete = ({ documentId }) =>
    deleteInspectionDocumentMutation.mutateAsync({
      projectId,
      inspectionId,
      documentId,
    });

  const documents = useMemo(
    () => inspectionDocumentsQuery.data?.results || [],
    [inspectionDocumentsQuery.data?.results],
  );

  const handleDeleteIconClick = ({ fileName = '', documentId = '' }) => {
    setSelectedFileName(fileName);
    deleteModal.askConfirm({ documentId });
  };

  const documentsCount = useMemo(
    () => inspectionDocumentsQuery.data?.count,
    [inspectionDocumentsQuery.data?.count],
  );

  return {
    state: getHookState(inspectionDocumentsQuery),
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    refetchDocuments,
    documentTypes,
    restrictions,
    documents,
    isLoading: inspectionDocumentsQuery.isFetching,
    setIsAppraisal,
    handleDelete,
    deleteModal,
    handleDeleteIconClick,
    selectedFileName,
    tablePagination,
    documentsCount,
  };
};
