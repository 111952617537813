import { colors } from '@theme';

export default function Divider() {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.neutral.lighter,
        },
      },
    },
  };
}
