import { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  getDrawRequestForApproval,
  isActiveProject,
  isRequestApproved,
  isRequestInReview,
} from '@utils';
import { IDrawRequest, IProject, IProjectSettings, QueryNamesEnums } from '@interfaces';
import { getProject, getProjectDrawRequestsList, getProjectSettings } from '@globalService';

const requiredKeys = ['status', 'name', 'comments_preview'] as const;
type ProjectData = Pick<IProject, (typeof requiredKeys)[number]>;
const requiredSettingsKeys = ['should_display_create_draw_request'] as const;
type ProjectSettings = Pick<IProjectSettings, (typeof requiredSettingsKeys)[number]>;

export interface ControllerInterface {
  showCreateButton: boolean;
  disabledCreateButton: boolean;
  waitingForApproval: boolean;
}

export const useOverviewTab = (projectId: string): ControllerInterface => {
  const settingsQuery = useQuery<ProjectSettings, Error>(
    [QueryNamesEnums.GET_PROJECT_SETTINGS, { projectId }],
    getProjectSettings.bind(this, projectId),
  );

  const projectQuery = useQuery<ProjectData, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const showCreateButton = useMemo(
    () =>
      settingsQuery.data?.should_display_create_draw_request &&
      isActiveProject(projectQuery.data?.status),
    [settingsQuery, projectQuery],
  );

  const disabledCreateButton = useMemo(() => {
    return drawRequestsQuery.data?.results?.some(
      (dr) => isRequestApproved(dr.status) || isRequestInReview(dr.status),
    );
  }, [drawRequestsQuery.data]);

  const waitingForApproval = useMemo(() => {
    return !!getDrawRequestForApproval(drawRequestsQuery.data?.results);
  }, [drawRequestsQuery.data]);

  return {
    showCreateButton,
    disabledCreateButton,
    waitingForApproval,
  };
};
