import { QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const unitsFilter: FilterObject = {
  title: 'Units',
  filterKey: 'units',
  needToUpdateUrl: false,
  needToUpdateSetting: false,
  getDataParamsPaginated: (pagination, q) => ({
    type: QueryNamesEnums.GET_PROJECT_MILESTONES,
    keys: ['tags', 'name'],
    args: { pagination, q },
    options: {
      strictSerialize: (data) =>
        data?.map((item) => ({
          value: item.tags?.[0]?.id,
          label: item.name,
        })),
    },
  }),
  cypressSelector: 'filters__units',
  withPagination: true,
};
