import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.11639 2H19.1164C20.7732 2 22.1164 3.34315 22.1164 5V19C22.1164 20.6569 20.7732 22 19.1164 22H5.11639C3.45954 22 2.11639 20.6569 2.11639 19V5C2.11639 3.34315 3.45954 2 5.11639 2ZM5.11639 4C4.56411 4 4.11639 4.44772 4.11639 5V19C4.11639 19.5523 4.56411 20 5.11639 20H19.1164C19.6687 20 20.1164 19.5523 20.1164 19V5C20.1164 4.44772 19.6687 4 19.1164 4H5.11639Z"
      fill={color}
    />
    <path
      d="M15.451 10.567C15.779 10.1137 15.6802 9.47837 15.2304 9.14787C14.7806 8.81737 14.15 8.91688 13.822 9.37014L11.6634 12.353L10.8907 11.3695C10.5451 10.9297 9.91111 10.8554 9.47461 11.2036C9.03811 11.5518 8.96439 12.1906 9.30996 12.6305L10.906 14.6619C11.1016 14.9108 11.4014 15.0532 11.7163 15.0469C12.0313 15.0407 12.3253 14.8863 12.5109 14.6299L15.451 10.567Z"
      fill={color}
    />
  </svg>
);

export default Icon;
