import React from 'react';
import { Grid2 } from '@mui/material';
import { colors } from '@theme';
import { PaymentGraphs, ProjectPaymentRequestList, ProjectPaymentSummary } from '../index';

const ProjectPaymentInfoV2 = () => {
  return (
    <Grid2
      container
      alignItems="stretch"
      sx={{
        flexFlow: 'column nowrap',
        backgroundColor: colors.background.gray,
      }}
    >
      <ProjectPaymentSummary />
      <PaymentGraphs />
      <ProjectPaymentRequestList />
    </Grid2>
  );
};

export default ProjectPaymentInfoV2;
