import { ReactElement, useState } from 'react';
import { IHandleRightDrawerOpenerParams, IRightDrawer } from '@interfaces';

type hookProps = {
  onClose?: CallableFunction;
  onOpen?: CallableFunction;
};

export const useRightMenu = (props?: hookProps): IRightDrawer => {
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string | ReactElement>('');

  const handleRightDrawerOpenerClick = ({ title }: IHandleRightDrawerOpenerParams) => {
    setOpen(true);
    setTitle(title);
  };

  const setWithCallbacks = (open) =>
    setOpen((state) => {
      if (state && !open && props?.onClose) props.onClose();
      if (!state && open && props?.onOpen) props.onOpen();
      return open;
    });

  return {
    handleRightDrawerOpenerClick,
    setOpen: setWithCallbacks,
    open,
    title,
  };
};
