import React from 'react';
import { UserListColumnType } from './common';
import { Typography } from '@mui/material';
import { ITeam } from '@interfaces';

const getTeamsText = (teams: ITeam[]) => {
  return teams.map((x: ITeam) => x.name).join(', ');
};

const teams: UserListColumnType = {
  name: 'teams',
  columnText: 'Teams',
  width: '20%',
  renderCell: ({ row }) => <Typography variant="body2">{getTeamsText(row.teams)}</Typography>,
};

export default teams;
