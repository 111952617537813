import React, { FC, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';

import { ComponentProps } from './interface';
import { colors } from '@theme';

const TablePaginationComponent: FC<ComponentProps> = ({
  page,
  rowsPerPage,
  rowsPerPageOptions,
  itemsCount,
  onPageChange,
  onRowsPerPageChange,
  showFirstButton = false,
  showLastButton = false,
  source,
}) => {
  useEffect(() => {
    if (!itemsCount) return;

    // Select the parent element with class 'MuiTablePagination-actions'
    const actionsContainer = document.getElementsByClassName('MuiTablePagination-actions')[0];

    if (actionsContainer) {
      // Select all buttons within this container
      const buttons = actionsContainer.getElementsByTagName('button');

      // Assuming the first button is the "Previous" button and the second is the "Next" button
      if (buttons.length > 0) {
        buttons[0].setAttribute('data-cy', `${source}__pagination__previous_button`);
        buttons[1].setAttribute('data-cy', `${source}__pagination__next_button`);
      }
    }
  }, [itemsCount]);

  if (!itemsCount) return null;

  return (
    <TablePagination
      component="div"
      rowsPerPage={rowsPerPage}
      count={itemsCount}
      page={page}
      onPageChange={onPageChange}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowsPerPageChange={onRowsPerPageChange}
      showFirstButton={showFirstButton}
      showLastButton={showLastButton}
      labelRowsPerPage="Per page:"
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
      sx={{
        '.MuiToolbar-root': { paddingLeft: 2 },
        '.MuiTablePagination-actions': {
          order: -1,
          marginLeft: '4px',
          '& .MuiButtonBase-root': {
            padding: '4px',
          },
          '& .MuiSvgIcon-root': {
            backgroundColor: colors.main.primary.lightest,
            color: colors.icons.green,
          },
          '& .Mui-disabled': {
            '& .MuiSvgIcon-root': {
              backgroundColor: colors.neutral.lightest,
              color: colors.neutral.medium,
            },
          },
        },
        '& .MuiTablePagination-selectLabel': { marginLeft: '8px' },
        '& .MuiTablePagination-displayedRows': { order: -2, fontWeight: 500, margin: 0 },
        '& .MuiTablePagination-select': { paddingLeft: 0, paddingTop: '6px' },
        '& .MuiInputBase-root': { margin: 0 },
        '& .MuiTablePagination-toolbar': {
          minHeight: '24px',
        },
      }}
      disabled={!itemsCount}
    />
  );
};

export default TablePaginationComponent;
