import React from 'react';
import { UserListColumnType } from './common';
import { Typography } from '@mui/material';
import { phoneFormatter } from '@utils';

const phone: UserListColumnType = {
  name: 'phone',
  columnText: 'Phone',
  width: '5%',
  minWidth: '160px',
  renderCell: ({ row }) => <Typography variant="body2">{phoneFormatter(row?.phone)}</Typography>,
};

export default phone;
