import React, { FC } from 'react';
import { Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NoResponsiveImage } from '@svgAsComponents';

const NoMobileContentPage: FC = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
      sx={{ flex: 1, py: 2, px: 4 }}
    >
      <NoResponsiveImage />
      <Typography variant="h4" sx={{ textAlign: 'center', fontSize: '18px' }}>
        Please login from your desktop or laptop computer
      </Typography>
      <Text variant="body2">
        There's so much great information waiting for you we couldn't fit it all on your screen
      </Text>
      <Text variant="body2">Use a desktop computer or laptop for the best experience</Text>
    </Stack>
  );
};

export default NoMobileContentPage;

// as it's only for mobile screens font size is fixed manually
const Text = styled(Typography)({
  textAlign: 'center',
  fontSize: '16px',
});
