import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 16, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3762 5L16.1165 5C16.1165 3.34315 14.7733 2 13.1165 2H11.1165C9.4596 2 8.11646 3.34315 8.11646 5L7.45974 5L7.11645 5C7.11606 5 7.11567 5 7.11527 5L4.11646 5C3.56417 5 3.11646 5.44772 3.11646 6C3.11646 6.55229 3.56417 7 4.11646 7L5.11645 7L5.11646 19C5.11646 20.6569 6.4596 22 8.11646 22H16.1165C17.7733 22 19.1165 20.6569 19.1165 19L19.1165 7L20.1165 7C20.6687 7 21.1165 6.55228 21.1165 6C21.1165 5.44771 20.6687 5 20.1165 5L17.1183 5C17.1177 5 17.1171 5 17.1165 5L16.3762 5ZM17.1165 7H15.1165L14.1165 7L10.1165 7L9.11646 7L7.11645 7L7.11646 9L7.11646 19C7.11646 19.5523 7.56417 20 8.11646 20H16.1165C16.6687 20 17.1165 19.5523 17.1165 19L17.1165 9V7ZM10.1165 5H11.1165H12.1165L13.1165 5H14.1165C14.1165 4.44772 13.6687 4 13.1165 4L11.1165 4C10.5642 4 10.1165 4.44772 10.1165 5ZM14.8236 10.2929C15.2141 10.6834 15.2141 11.3166 14.8236 11.7071L13.5307 13L14.8236 14.2929C15.2141 14.6834 15.2141 15.3166 14.8236 15.7071C14.433 16.0976 13.7999 16.0976 13.4093 15.7071L12.1165 14.4142L10.8236 15.7071C10.433 16.0976 9.79987 16.0976 9.40935 15.7071C9.01882 15.3166 9.01882 14.6834 9.40935 14.2929L10.7022 13L9.40935 11.7071C9.01882 11.3166 9.01882 10.6834 9.40935 10.2929C9.79987 9.90237 10.433 9.90237 10.8236 10.2929L12.1165 11.5858L13.4093 10.2929C13.7999 9.90237 14.433 9.90237 14.8236 10.2929Z"
      fill={color}
    />
  </svg>
);

export default Icon;
