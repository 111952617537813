import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 24 }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0002 12.27C24.0002 11.48 23.9287 10.73 23.8061 10H12.2607V14.51H18.8712C18.5749 15.99 17.7065 17.24 16.4191 18.09V21.09H20.3629C22.672 19 24.0002 15.92 24.0002 12.27Z"
      fill="#4285F4"
    />
    <path
      d="M12.2609 24C15.5712 24 18.3401 22.92 20.3631 21.09L16.4193 18.09C15.3158 18.81 13.9161 19.25 12.2609 19.25C9.06293 19.25 6.3554 17.14 5.38477 14.29H1.31836V17.38C3.33113 21.3 7.46906 24 12.2609 24Z"
      fill="#34A853"
    />
    <path
      d="M5.38442 14.29C5.12899 13.57 4.99617 12.8 4.99617 12C4.99617 11.2 5.13921 10.43 5.38442 9.71V6.62H1.31801C0.480203 8.24 0 10.06 0 12C0 13.94 0.480203 15.76 1.31801 17.38L5.38442 14.29Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2609 4.75C14.0693 4.75 15.6836 5.36 16.9608 6.55L20.455 3.13C18.3401 1.19 15.5712 0 12.2609 0C7.46906 0 3.33113 2.7 1.31836 6.62L5.38477 9.71C6.3554 6.86 9.06293 4.75 12.2609 4.75Z"
      fill="#EA4335"
    />
  </svg>
);

export default Icon;
