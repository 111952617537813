import React, { FC } from 'react';
import { Popup, CustomTextField, ConfirmationModal } from '@components';
import { Button, Stack } from '@mui/material';
import * as Controller from './controller';
import LoadingButton from '@mui/lab/LoadingButton';
import { PopupTypeEnum } from '@interfaces';

const EditTeamPopup: FC<{
  open: boolean;
  onClose: () => void;
  teamId: string;
  initTeamName: string;
}> = ({ open, onClose, teamId, initTeamName }) => {
  const { teamName, handleUpdateTeam, isSubmitting, isDeleting, deleteModal, handleDeleteTeam } =
    Controller.useAddTeamPopup(onClose, teamId, initTeamName);

  return (
    <Popup open={open} maxWidth="sm" title="Edit team">
      <>
        <Stack sx={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
          <Stack spacing={2}>
            <CustomTextField
              field={teamName}
              label="Team name"
              required
              inputProps={{
                'data-cy': 'company_teams__edit_team_name_input',
              }}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ mt: 4 }}
          >
            <Button
              variant="text"
              onClick={onClose}
              data-cy="company_teams__edit_team_cancel_button"
            >
              Cancel
            </Button>
            <Button
              variant="new"
              color="error"
              onClick={deleteModal.askConfirm}
              data-cy="company_teams__edit_team_delete_button"
            >
              Delete
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={() => handleUpdateTeam()}
              data-cy="company_teams__edit_team_save_button"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
        <ConfirmationModal
          open={deleteModal.isConfirmModalOpened}
          title="Delete team"
          text="Are you sure you want to delete this team?"
          onClose={deleteModal.closeConfirmModal}
          confirmCallback={handleDeleteTeam}
          type={PopupTypeEnum.ERROR}
          isLoading={isDeleting}
        />
      </>
    </Popup>
  );
};

export default EditTeamPopup;
