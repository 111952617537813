import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.status.error.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.91665 9.78702L9.78702 2.91665C11.0092 1.69445 12.9908 1.69445 14.213 2.91665L21.0834 9.78702C22.3056 11.0092 22.3055 12.9908 21.0834 14.213L14.213 21.0834C12.9908 22.3056 11.0092 22.3055 9.78702 21.0834L2.91665 14.213C1.69445 12.9908 1.69445 11.0092 2.91665 9.78702ZM4.39197 11.2623C3.98457 11.6697 3.98457 12.3303 4.39197 12.7377L11.2623 19.608C11.6697 20.0154 12.3303 20.0154 12.7377 19.608L19.608 12.7377C20.0154 12.3303 20.0154 11.6697 19.608 11.2623L12.7377 4.39197C12.3303 3.98457 11.6697 3.98457 11.2623 4.39197L4.39197 11.2623ZM12 13.304C11.2798 13.304 10.696 12.7202 10.696 12V7.82716C10.696 7.10697 11.2798 6.52314 12 6.52314C12.7202 6.52314 13.304 7.10697 13.304 7.82716V12C13.304 12.7202 12.7202 13.304 12 13.304ZM12 17.2161C12.7202 17.2161 13.304 16.6322 13.304 15.912C13.304 15.1919 12.7202 14.608 12 14.608C11.2798 14.608 10.696 15.1919 10.696 15.912C10.696 16.6322 11.2798 17.2161 12 17.2161Z"
      fill={color}
    />
  </svg>
);

export default Icon;
