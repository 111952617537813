import { useQuery } from 'react-query';
import { getCompany } from '@globalService';
import { HookState, ICompanyFull, QueryNamesEnums } from '@interfaces';
import { getHookState } from '@utils';

export interface ControllerInterface {
  state: HookState;
  company: ICompanyFull;
}

export const useCompanyModal = (companyId: string): ControllerInterface => {
  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_COMPANY, { companyId }],
    getCompany.bind(this, companyId),
    { enabled: Boolean(companyId) },
  );

  return {
    state: getHookState(companyQuery),
    company: companyQuery.data,
  };
};
