import { colors } from '@theme';

export interface ComponentProps {
  size?: number;
  color?: string;
  hasComments: boolean;
  hasUnreadComments: boolean;
}

export const hasCommentsColor = colors.neutral.dark;
export const hasUnreadCommentsColor = colors.status.information.medium;
