import { QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { isCustomer } from '@utils';

export const currentReviewerTeamFilter: FilterObject = {
  title: 'Current reviewer team',
  filterKey: 'current_reviewer_team',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  userPermission: (user) => isCustomer(user),
  getDataParamsPaginated: (pagination, q) => ({
    type: QueryNamesEnums.GET_PROJECTS_TEAMS,
    keys: ['id', 'name'],
    args: { pagination, q },
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
    },
  }),
  cypressSelector: 'filters__current_reviewer_team',
  withPagination: true,
};
