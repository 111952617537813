import React, { FC } from 'react';
import { useMilestoneListPatch } from './controller';
import { MilestoneListWithPatchInterface } from './interface';
import { MilestoneList, MilestoneListV2 } from '@components';
import { useLaunchDarklyFlags } from '@context';

const MilestoneListWithPatch: FC<MilestoneListWithPatchInterface> = ({
  requestId,
  refetch,
  containerRef,
  onCreditChangeCompleted,
  patchMilestone,
  ...props
}) => {
  const flags = useLaunchDarklyFlags();

  const {
    patchMilestoneDefault,
    resetMutation,
    deleteMilestone,
    updateListData,
    onMilestoneUpdate,
    bulkUpdateApi,
  } = useMilestoneListPatch({
    requestId,
    refetch,
  });

  return flags?.[`ENG_7895_table_v3__${props.tableKey}`] ? (
    <MilestoneListV2
      refetch={refetch}
      deleteMilestone={deleteMilestone}
      bulkUpdateApi={bulkUpdateApi}
      patchMilestone={patchMilestone || patchMilestoneDefault}
      resetMutation={resetMutation}
      updateData={updateListData}
      containerRef={containerRef}
      onCreditChangeCompleted={onCreditChangeCompleted || onMilestoneUpdate}
      {...props}
    />
  ) : (
    <MilestoneList
      refetch={refetch}
      deleteMilestone={deleteMilestone}
      patchMilestone={patchMilestone || patchMilestoneDefault}
      resetMutation={resetMutation}
      updateData={updateListData}
      containerRef={containerRef}
      onCreditChangeCompleted={onCreditChangeCompleted || onMilestoneUpdate}
      {...props}
    />
  );
};

export default MilestoneListWithPatch;
