import React, { FC, memo, useContext } from 'react';
import { Typography } from '@mui/material';
import { currencyFormatter } from '@utils';
import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import { ColumnV2Width, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const RevisedValueDetails: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__revised_milestone_amount__value__index_${row?.index}`}
    >
      {currencyFormatter(row.revised_estimate, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3SemiBold"
      data-cy={`${source}__footer__revised_milestone_amount__value`}
    >
      {currencyFormatter(row.revised_estimate, '-')}
    </Typography>
  );
};

const Memoized = memo(RevisedValueDetails);

const revisedMilestoneAmount: MilestoneListColumnType = {
  name: 'revised_milestone_amount', // name is different from revised_estimate to differentiate between the two columns with the same key revised_estimate
  columnText: 'Revised scheduled value ($)',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText>
      <ColumnLabel>Revised</ColumnLabel>
      <ColumnLabel>scheduled value ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default revisedMilestoneAmount;
