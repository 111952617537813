import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useReviewRequestInvalidation, useSafeSnackbar } from '@hooks';
import { getErrorText } from '@utils';
import { useLaunchDarklyFlags } from '@context';
import { ErrorDual, MarkRequestAsPaidPayload, QueryNamesEnums } from '@interfaces';
import { markRequestAsPaid } from '@globalService';
import { DateFieldModel, StringFieldModel, useDateFieldModel, useStringFieldModel } from '@models';

interface ControllerInterface {
  handleMarkAsPaid: () => void;
  transactionId: StringFieldModel;
  transactionDate: DateFieldModel;
  isSubmitting: boolean;
}

export const useMarkAsPaid = ({ onClose, drawRequestId }): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId } = useParams();
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();
  const flags = useLaunchDarklyFlags();
  const transactionId = useStringFieldModel({ initValue: '' });
  const transactionDate = useDateFieldModel({
    initValue: new Date(),
  });

  const markAsPaidMutation = useMutation<Response, ErrorDual, MarkRequestAsPaidPayload>(
    markRequestAsPaid,
    {
      onSuccess: () => {
        if (flags?.['ENG_7910_updates_based_on_draw_events']) {
          handleRequestReviewInvalidation({ projectId, drawRequestId });
        } else {
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
          queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST);
        }
      },
      onError: (error: ErrorDual) => {
        const errorText = getErrorText(error);
        enqueueSnackbar(errorText, { variant: 'error' });
      },
      onSettled: () => {
        onClose();
      },
    },
  );

  const handleMarkAsPaid = async () => {
    await markAsPaidMutation.mutateAsync({
      project: projectId,
      drawRequest: drawRequestId,
      settled_at: transactionDate.value,
      external_transaction_id: transactionId.value,
    });
  };

  return {
    handleMarkAsPaid,
    transactionId,
    transactionDate,
    isSubmitting: markAsPaidMutation.isLoading,
  };
};
