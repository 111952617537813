import { useQuery } from 'react-query';

import {
  getTooltipText,
  isActionEnabled,
  isActiveProject,
  isRequestApproved,
  isRequestHistorical,
} from '@utils';
import { IDrawRequest, QueryNamesEnums, ProjectStatusEnum } from '@interfaces';
import { getDrawRequest } from '@globalService';
import { excludeCommentsWithTotalsAllQueryFields } from '@constants';

export interface ControllerInterface {
  isRequestOnHold: boolean;
  isHistoricalRequest: boolean;
  isActionButtonDisabled: boolean;
  disableActionButtonTooltip: string;
  isRequestApproved: boolean;
}

export const useRequestInfo = ({
  drawRequest,
  projectId,
  projectStatus,
}: {
  drawRequest: IDrawRequest;
  projectId: string;
  projectStatus: ProjectStatusEnum;
}): ControllerInterface => {
  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId: drawRequest?.id, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId: drawRequest?.id,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
    { enabled: Boolean(drawRequest?.id) },
  );

  return {
    isRequestOnHold: drawRequest?.is_on_hold,
    isHistoricalRequest: isRequestHistorical(drawRequest?.source),
    disableActionButtonTooltip: getTooltipText({
      nonActiveProject: !isActiveProject(projectStatus),
    }),
    isActionButtonDisabled: !isActionEnabled(
      !isActiveProject(projectStatus),
      drawRequestQuery.isLoading,
    ),
    isRequestApproved: isRequestApproved(drawRequest?.status),
  };
};
