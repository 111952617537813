import { colors } from '@theme';

export interface ComponentProps {
  size?: number;
  color?: string;
  hasUnseenImages?: boolean;
  hasImages?: boolean;
}

export const hasUnseenImagesColor = colors.status.information.medium;
export const hasImagesColor = colors.neutral.dark;
