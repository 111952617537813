import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors?.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.85327 4.5C2.85327 3.11929 3.97256 2 5.35327 2H7.85327C8.40556 2 8.85327 2.44772 8.85327 3C8.85327 3.55228 8.40556 4 7.85327 4H5.35327C5.07713 4 4.85327 4.22386 4.85327 4.5V7C4.85327 7.55228 4.40556 8 3.85327 8C3.30099 8 2.85327 7.55228 2.85327 7V4.5ZM18.8533 11C18.8533 13.7614 16.6147 16 13.8533 16C12.9643 16 12.1296 15.768 11.4062 15.3613L9.06038 17.7071C8.66985 18.0976 8.03669 18.0976 7.64616 17.7071C7.25564 17.3166 7.25564 16.6834 7.64616 16.2929L9.89013 14.0489C9.23995 13.205 8.85327 12.1477 8.85327 11C8.85327 8.23858 11.0918 6 13.8533 6C16.6147 6 18.8533 8.23858 18.8533 11ZM16.8533 11C16.8533 9.34315 15.5101 8 13.8533 8C12.1964 8 10.8533 9.34315 10.8533 11C10.8533 12.6569 12.1964 14 13.8533 14C15.5101 14 16.8533 12.6569 16.8533 11ZM20.3533 2C21.734 2 22.8533 3.11929 22.8533 4.5V7C22.8533 7.55228 22.4056 8 21.8533 8C21.301 8 20.8533 7.55228 20.8533 7V4.5C20.8533 4.22386 20.6294 4 20.3533 4H17.8533C17.301 4 16.8533 3.55228 16.8533 3C16.8533 2.44772 17.301 2 17.8533 2H20.3533ZM2.85327 19.5C2.85327 20.8807 3.97256 22 5.35327 22H7.85327C8.40556 22 8.85327 21.5523 8.85327 21C8.85327 20.4477 8.40556 20 7.85327 20H5.35327C5.07713 20 4.85327 19.7761 4.85327 19.5V17C4.85327 16.4477 4.40556 16 3.85327 16C3.30099 16 2.85327 16.4477 2.85327 17V19.5ZM20.3533 22C21.734 22 22.8533 20.8807 22.8533 19.5V17C22.8533 16.4477 22.4056 16 21.8533 16C21.301 16 20.8533 16.4477 20.8533 17V19.5C20.8533 19.7761 20.6294 20 20.3533 20H17.8533C17.301 20 16.8533 20.4477 16.8533 21C16.8533 21.5523 17.301 22 17.8533 22H20.3533Z"
      fill={color}
    />
  </svg>
);

export default Icon;
