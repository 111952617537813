import { useContext, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  checkIsResubmit,
  getChecklistIdByRole,
  getCheckListItemsByRole,
  getHookState,
  getTeamRole,
  sortAdditionalRequirementsFirst,
} from '@utils';
import {
  ChecklistDocumentParam,
  DocumentContentTypeEnum,
  DocumentTypeEnum,
  IDocumentType,
  IDrawRequest,
  IProjectChecklist,
  ItemStatusesEnum,
  QueryNamesEnums,
  TransloaditTemplateEnum,
  UpdateChecklistItemParam,
} from '@interfaces';
import { useFilesUploader, useSafeSnackbar } from '@hooks';
import {
  deleteDrawRequestChecklistItem,
  getDrawRequest,
  getDrawRequestDocumentsTypeList,
  getDrawRequestItemChecklist,
  updateChecklistItem,
} from '@globalService';
import { useParams } from 'react-router-dom';
import { AuthContext } from '@context';
import { IChecklistController, IHandleDoneParams } from '../interfaces';

export const useChecklist = (): IChecklistController => {
  const { projectId, requestId: drawRequestId, action } = useParams();
  const isAdditionalRequirementResubmit = useMemo(() => checkIsResubmit(action), [action]);
  const itemsRef = useRef(null);

  const handleNavigate = (itemId: string) => {
    const map = getMap();
    const node = map.get(itemId);
    if (!node) return;

    node.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  const getMap = () => {
    if (!itemsRef.current) {
      // Initialize the Map on first usage.
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  };

  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const [isChecklistModalShow, setChecklistModalShow] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const fileUploader = useFilesUploader();
  const photoUploader = useFilesUploader();

  const drawRequestData = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
  );

  const DRChecklist = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST, { projectId, drawRequestId }],
    getDrawRequestItemChecklist.bind(this, projectId, drawRequestId),
  );

  const drawRequestDocumentsTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS_TYPES],
    getDrawRequestDocumentsTypeList.bind(this),
  );

  const updateChecklistItemMutation = useMutation<Response, Error, UpdateChecklistItemParam>(
    updateChecklistItem,
    {
      onSuccess: () => {
        if (!isAdditionalRequirementResubmit) {
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const checklistItems = useMemo(() => {
    if (!DRChecklist.data) return [];
    const items = getCheckListItemsByRole({
      policies: DRChecklist.data,
      teamRole,
      teamId: user?.active_team?.id,
      itemsCanBeDeleted: false,
    });
    if (isAdditionalRequirementResubmit) items.sort(sortAdditionalRequirementsFirst);
    return items.map((item) => ({
      ...item,
      itemRef: (node) => {
        const map = getMap();
        if (node) {
          map.set(item.id, node);
        } else {
          map.delete(item.id);
        }
      },
    }));
  }, [DRChecklist.data]);

  const checklistId = useMemo(() => {
    if (!DRChecklist.data) return null;
    return getChecklistIdByRole(DRChecklist.data, teamRole);
  }, [DRChecklist.data]);

  const startUploading = (checklistItemId: string) => {
    const fields = {
      object_id: checklistItemId,
      document_type_id: 'CHECKLIST_ITEM_DOCUMENT',
      user_id: user?.id,
      content_type: DocumentContentTypeEnum.CHECKLIST_ITEM,
    };
    fileUploader.uploadMedia({
      fields,
      templateType: TransloaditTemplateEnum.DOCUMENTS,
    });
  };

  const progressPhotoTypeId = useMemo(() => {
    if (!drawRequestDocumentsTypesQuery.data) return null;
    return drawRequestDocumentsTypesQuery.data.find(
      (docType) => docType.name === DocumentTypeEnum.PROGRESS_PHOTO,
    )?.id;
  }, [drawRequestDocumentsTypesQuery.data]);

  const startPhotoUploading = (checklistItemId: string) => {
    const fields = {
      project_id: projectId,
      draw_request_id: drawRequestId,
      object_id: checklistItemId,
      document_type_id: progressPhotoTypeId,
      user_id: user?.id,
      content_type: DocumentContentTypeEnum.CHECKLIST_ITEM,
    };
    photoUploader.uploadMedia({
      fields,
      templateType: TransloaditTemplateEnum.PROOFPOINTS,
    });
  };

  const deleteDocument = useMutation<Response, Error, ChecklistDocumentParam>(
    deleteDrawRequestChecklistItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
          { projectId, drawRequestId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleDelete = ({ documentId, checklistItemId }) => {
    return deleteDocument.mutateAsync({
      projectId,
      drawRequestId,
      documentId,
      checklistId,
      checklistItemId,
    });
  };

  const handleSkip = (checklistItemId, label, shouldSkip) => {
    if (shouldSkip) {
      setChecklistModalShow(true);
      setSelectedItem(checklistItems.find((item) => item.label === label));
    } else {
      updateChecklistItemMutation.mutate({
        projectId,
        checklistId,
        checklistItemId,
        status: ItemStatusesEnum.SKIPPED,
        drawRequestId,
        exception_reasons: [],
      });
    }
  };

  const handleDone = ({
    checklistItemId,
    comment,
    shouldComplete,
    metadata,
  }: IHandleDoneParams) => {
    updateChecklistItemMutation.mutate({
      projectId,
      checklistId,
      checklistItemId,
      status: shouldComplete ? ItemStatusesEnum.COMPLETED : ItemStatusesEnum.NOT_STARTED,
      drawRequestId,
      note: comment,
      ...(metadata && { metadata }),
    });
  };

  return {
    state: getHookState(DRChecklist),
    handleDelete,
    isLoading: deleteDocument.isLoading || DRChecklist.isFetching,
    checklistItems,
    isChecklistModalShow,
    setChecklistModalShow,
    drawRequest: drawRequestData.data,
    documentTypes: drawRequestDocumentsTypesQuery.data,
    handleSkip,
    selectedItem,
    handleDone,
    fileUploader,
    photoUploader,
    progressPhotoTypeId,
    startUploading,
    startPhotoUploading,
    projectId,
    handleNavigate,
    selectedFileName,
    setSelectedFileName,
  };
};
