import { useQueries } from 'react-query';
import { getHookState } from '@utils';
import { HookState, IDrawRequest, IProject, QueryNamesEnums } from '@interfaces';
import { useParams } from 'react-router-dom';

import { getDrawRequest, getProject } from '@globalService';

export interface ControllerInterface {
  state: HookState;
  data: {
    drawRequestData?: IDrawRequest;
    project?: IProject;
  };
}

export const useDisbursementStatement = (drawRequestId: string): ControllerInterface => {
  const { projectId } = useParams();

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId }),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
  ]);

  return {
    state: getHookState(requestedDataQueries),
    data: {
      drawRequestData: requestedDataQueries[0].data,
      project: requestedDataQueries[1].data,
    },
  };
};
