import React from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import { VisibilityOnIcon, VisibilityOffIcon } from '@svgAsComponents';

const PasswordVisibilityIcon = ({ showPassword, handleClickShowPassword }) => {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        size="large"
      >
        {showPassword ? <VisibilityOffIcon size={16} /> : <VisibilityOnIcon size={16} />}
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordVisibilityIcon;
