import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  ArrowRightIcon,
  CheckboxIconRoundedChecked,
  CheckboxIconRoundedDefault,
} from '@svgAsComponents';
import { colors } from '@theme';
import { Link } from 'react-router-dom';

const Step: FC<{
  title: string;
  isChecked: boolean;
  link: string;
  linkText: string;
  dataTestName: string;
}> = ({ title, link, isChecked, linkText, dataTestName }) => {
  return (
    <Stack direction="row" alignItems="center">
      {isChecked ? (
        <CheckboxIconRoundedChecked size={20} />
      ) : (
        <CheckboxIconRoundedDefault size={20} />
      )}
      <Stack direction="row" alignItems="center" spacing={0.5} sx={{ pl: 1.5 }}>
        <Typography variant="h4">{title}</Typography>
        <ArrowRightIcon size={13} color={colors.text.heading} />
        <Typography
          variant="h4"
          sx={{ color: colors.text.link, textDecoration: 'none' }}
          component={Link}
          to={link}
          data-cy={`onboarding__step__link__${dataTestName}`}
        >
          {linkText}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Step;
