import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const DashboardIcon = ({ color = colors.icons.gray }: ComponentProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H9L7.4 19.2C6.95817 19.5314 6.86863 20.1582 7.2 20.6C7.53137 21.0418 8.15817 21.1314 8.6 20.8L12 18.25L15.4 20.8C15.8418 21.1314 16.4686 21.0418 16.8 20.6C17.1314 20.1582 17.0418 19.5314 16.6 19.2L15 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3H4ZM12.0116 16H18H20V14V7V5H18H6H4V7V14V16H6H11.9884H11.9899C11.9971 15.9999 12.0044 15.9999 12.0116 16ZM9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H9ZM6 9C6 8.44771 6.44772 8 7 8L10.5 8C11.0523 8 11.5 8.44772 11.5 9C11.5 9.55228 11.0523 10 10.5 10L7 10C6.44772 10 6 9.55228 6 9ZM15 7C13.8954 7 13 7.89543 13 9V12C13 13.1046 13.8954 14 15 14H16C17.1046 14 18 13.1046 18 12V9C18 7.89543 17.1046 7 16 7H15ZM16 9H15V10V11V12H16V11V10V9Z"
      fill={color}
    />
  </svg>
);

export default DashboardIcon;
