import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 18 }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 18) * 24}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14994 0H11.1739L17.9745 7.08846V20.8756C17.9745 22.6027 16.5772 24 14.8561 24H3.14994C1.42281 24 0.0255127 22.6027 0.0255127 20.8756V3.12443C0.0254824 1.3973 1.42278 0 3.14994 0V0Z"
      fill="#262626"
    />
    <path
      opacity="0.302"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1679 0V7.03449H17.9745L11.1679 0Z"
      fill="white"
    />
    <path
      d="M4.5592 17.5232V14.111H3.31183V13.1634H6.91604V14.111H5.67467V17.5232H4.5592ZM10.97 17.5232H9.8006L9.00299 16.1859L8.20538 17.5232H7.02997L8.41529 15.1964L7.20391 13.1634H8.37332L9.00299 14.2189L9.6267 13.1634H10.8021L9.59073 15.2024L10.97 17.5232ZM12.3253 17.5232V14.111H11.0839V13.1634H14.6882V14.111H13.4408V17.5232H12.3253Z"
      fill="white"
    />
  </svg>
);

export default Icon;
