import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { StringFieldModel, useStringFieldModel } from '@models';
import { deleteCompanyTeam, updateCompanyTeam } from '@globalService';
import { QueryNamesEnums, DeleteTeamPayload, UpdateTeamPayload } from '@interfaces';
import { useConfirmationModal, ConfirmModalHookInterface, useSafeSnackbar } from '@hooks';

interface ControllerInterface {
  teamName: StringFieldModel;
  handleUpdateTeam: () => void;
  handleDeleteTeam: () => void;
  isSubmitting: boolean;
  isDeleting: boolean;
  deleteModal: ConfirmModalHookInterface;
}

export const useAddTeamPopup = (
  onClose: () => void,
  teamId: string,
  initTeamName: string,
): ControllerInterface => {
  const { companyId } = useParams();

  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const deleteModal = useConfirmationModal();

  const teamName = useStringFieldModel({
    initValue: initTeamName || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  useEffect(() => {
    return () => teamName.setValue('');
  }, []);

  const handleUpdateTeam = useCallback(() => {
    const isTeamNameValid = teamName.validate();

    if (isTeamNameValid) {
      updateTeamMutation.mutateAsync({
        companyId,
        teamId,
        data: {
          name: teamName.value,
        },
      });
    }
  }, [companyId, teamName]);

  const updateTeamMutation = useMutation<Response, Error, UpdateTeamPayload>(updateCompanyTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }]);
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const deleteTeamMutation = useMutation<Response, Error, DeleteTeamPayload>(deleteCompanyTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }]);
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleDeleteTeam = useCallback(async () => {
    await deleteTeamMutation.mutateAsync({
      companyId,
      teamId,
    });
  }, [companyId, teamId]);

  return {
    teamName,
    handleUpdateTeam,
    isSubmitting: updateTeamMutation.isLoading,
    isDeleting: deleteTeamMutation.isLoading,
    handleDeleteTeam,
    deleteModal,
  };
};
