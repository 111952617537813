import React from 'react';

import {
  ChecklistApproveModal,
  LoanErrorsPopup,
  LoanErrorsPopupV2,
  ModalWithRadioChoice,
} from '@components';
import * as Controller from './controller';
import ProjectDeactivationReasonsModal from '../ProjectDeactivationReasonsModal';
import { PolicyListReasonsTypeEnum, ProjectStatusEnum } from '@interfaces';
import { UpdateIcon } from '@svgAsComponents';
import { useLaunchDarklyFlags } from '@context';

export default function UpdateProjectStatus({ open, handleOpen, valuesBasedOnPermissions }) {
  const {
    changeStatusModal,
    confirmModal,
    newStatus,
    inviteBorrowers,
    setInviteBorrowers,
    checklistModalShow,
    setChecklistModalShow,
    projectId,
    policyItems,
    checklistId,
    errors,
    setErrors,
    value,
    setValue,
  } = Controller.useProjectOptions(open, handleOpen);
  const flags = useLaunchDarklyFlags();

  return (
    <>
      <ModalWithRadioChoice
        open={changeStatusModal.open}
        title={valuesBasedOnPermissions.labelText}
        options={valuesBasedOnPermissions.statusesList}
        initialValue={changeStatusModal.initialValue as ProjectStatusEnum}
        value={value}
        setValue={setValue}
        confirmCallback={changeStatusModal.changeStatus}
        closeCallback={() => changeStatusModal.handleOpen(false)}
        inviteBorrowers={inviteBorrowers}
        setInviteBorrowers={setInviteBorrowers}
        icon={UpdateIcon}
        source="project__options_menu__status_update_modal"
        confirmButtonLabel="Update"
      />

      <ProjectDeactivationReasonsModal
        open={confirmModal.isConfirmModalOpened}
        onClose={confirmModal.closeConfirmModal}
        confirmText={confirmModal.confirmText}
        onApprove={({ value, changeReasons }) =>
          confirmModal.confirmCallback({
            action: () => confirmModal.confirmChangeStatus({ value, changeReasons }),
          })
        }
        newStatus={newStatus}
      />

      {checklistModalShow && (
        <ChecklistApproveModal
          open={checklistModalShow}
          onClose={() => setChecklistModalShow(false)}
          onApproveClose={() => {
            setChecklistModalShow(false);
            confirmModal.askConfirm({ value: ProjectStatusEnum.ACTIVE });
          }}
          checklistItems={policyItems}
          checklistId={checklistId}
          projectId={projectId}
          isRequiredReasons
          source="project__options_menu__status__exception_reasons_modal"
          checklistType={PolicyListReasonsTypeEnum.PROJECT}
        />
      )}

      {flags?.['ENG_9199_project_activation_validate_funding_sources'] ? (
        <LoanErrorsPopupV2 errors={errors} setErrors={setErrors} />
      ) : (
        <LoanErrorsPopup projectId={projectId} errors={errors} setErrors={setErrors} />
      )}
    </>
  );
}
