import { useContext } from 'react';
import { useSafeSnackbar } from '@hooks';
import { useMutation, useQueryClient } from 'react-query';
import { AuthContext } from '@context';
import {
  StringFieldModel,
  useStringFieldModel,
  usePhoneFieldModel,
  PhoneFieldModel,
} from '@models';
import { updateUserMe } from '@globalService';
import { IUser, QueryNamesEnums, UpdateUserFields } from '@interfaces';
import { replaceObjectProperties } from '@utils';

interface ControllerInterface {
  firstNameField: StringFieldModel;
  lastNameField: StringFieldModel;
  phoneNumberField: PhoneFieldModel;
  submitPersonalInfo: () => void;
}

export const usePersonalInfoEdit = (onClose: () => void): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();

  const firstNameField = useStringFieldModel({
    initValue: user.first_name,
    validationRule: (value) => Boolean(value.trim()),
    validateOnChange: true,
  });

  const lastNameField = useStringFieldModel({
    initValue: user.last_name,
    validationRule: (value) => Boolean(value.trim()),
    validateOnChange: true,
  });

  const phoneNumberField = usePhoneFieldModel({
    initValue: user.phone,
  });

  const submitPersonalInfo = () => {
    const isFirstNameValid = firstNameField.validate();
    const isLastNameValid = lastNameField.validate();
    const isPhoneNumberValid = phoneNumberField.validate();

    if (isFirstNameValid && isLastNameValid && isPhoneNumberValid) {
      updateUserMutation.mutateAsync({
        first_name: firstNameField.value,
        last_name: lastNameField.value,
        phone: phoneNumberField.value || null,
      });
    }
  };

  const updateUserMutation = useMutation<IUser, Error, UpdateUserFields>(updateUserMe, {
    onSuccess: (data) => {
      const { last_name, first_name, phone } = data;
      queryClient.setQueryData<IUser>([QueryNamesEnums.GET_USER], (request) =>
        replaceObjectProperties({ data: request, newData: { last_name, first_name, phone } }),
      );
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return {
    firstNameField,
    lastNameField,
    phoneNumberField,
    submitPersonalInfo,
  };
};
