import { ServerErrorCodesEnum } from '@interfaces';

const MAX_RETRY = 3;
export const BASE_CONFIG_OLD = {
  refetchOnWindowFocus: !window?.['Cypress'],
  refetchOnMount: true,
  initialStale: [],
  suspense: false,
  enabled: true,
  staleTime: 2 * 60 * 1000,
  retry: (failureCount, error) => {
    return error === 'Not found.' ||
      error?.code === ServerErrorCodesEnum.NOT_FOUND ||
      error?.code === ServerErrorCodesEnum.PERMISSION_DENIED
      ? false
      : failureCount < MAX_RETRY;
  },
};

export const BASE_CONFIG = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  initialStale: [],
  suspense: false,
  enabled: true,
  staleTime: Infinity,
  retry: (failureCount, error) => {
    return error === 'Not found.' ||
      error?.code === ServerErrorCodesEnum.NOT_FOUND ||
      error?.code === ServerErrorCodesEnum.PERMISSION_DENIED
      ? false
      : failureCount < MAX_RETRY;
  },
};
