import { useEffect, useState } from 'react';
import last from 'lodash/last';
import { StringFieldModel, useStringFieldModel } from '@models';

export interface ControllerInterface {
  otherReason: StringFieldModel;
  reasons: string[];
  handleSelectChange: (event) => void;
  handleTextAreaChange: (event) => void;
}

export const useMultiSelect = (
  checklistItem,
  setChecklistReasons,
  reasonsList,
  isMultiSelect,
  defaultReasons = [],
): ControllerInterface => {
  const exceptionReasons =
    checklistItem?.exception_reasons?.length > 0
      ? checklistItem?.exception_reasons?.map((o) => o.exception_reason)
      : defaultReasons;
  const otherReason = useStringFieldModel({
    initValue: '',
    validationRule: (data) => Boolean(data),
  });
  const [reasons, setReason] = useState(exceptionReasons);

  useEffect(() => {
    if (
      exceptionReasons.length === 1 &&
      reasonsList.length > 0 &&
      reasonsList?.every((o) => o.reason !== exceptionReasons[0])
    ) {
      otherReason.setValue(exceptionReasons[0]);
      setReason(['Other']);
      setReasonsToChecklist(exceptionReasons);
    }
  }, [checklistItem, reasonsList]);

  useEffect(() => {
    setReasonsToChecklist(reasons[0] === 'Other' ? [otherReason.value] : reasons);
  }, [reasons]);

  const handleTextAreaChange = (event) => {
    setReasonsToChecklist([event.target.value]);
    otherReason.handleChange(event);
  };

  const setReasonsToChecklist = (reasons) => {
    const newObject = { checklistItemId: checklistItem.id, reasons, label: checklistItem.label };
    setChecklistReasons((prevArray) =>
      prevArray.map((obj) =>
        newObject?.checklistItemId === obj.checklistItemId ? newObject : obj,
      ),
    );
  };

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    isMultiSelect
      ? setReason(
          last(value) === 'Other' ? [last(value)] : value.filter((item) => item !== 'Other'),
        )
      : setReason([value]);
  };

  return {
    otherReason,
    reasons,
    handleSelectChange,
    handleTextAreaChange,
  };
};
