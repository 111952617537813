import React from 'react';

import { Typography, Divider, Stack, Box, Skeleton } from '@mui/material';

import { GoogleLogin, SsoLoginButton } from '../index';
import * as Controller from './controller';

const ThirdPartyLoginButtons = () => {
  const isPartner =
    window.location.href.includes('partner') ||
    window.location.href.includes('galvatron') ||
    window.location.href.includes('localhost:3000');

  const { handleLogin, googleClientId, isSettingsLoading } = Controller.useGoogleLogin();

  return (
    <Stack sx={{ mt: 4 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Divider sx={{ borderBottomWidth: 1, flexGrow: 1 }} />
        <Typography sx={{ mx: 1 }} variant="body3">
          Or log in with
        </Typography>
        <Divider sx={{ borderBottomWidth: 1, flexGrow: 1 }} />
      </Stack>

      <Stack sx={{ mt: 1 }} direction="row" justifyContent="space-between">
        {isPartner && (
          <Box
            sx={{
              minWidth: googleClientId || isSettingsLoading ? '48%' : '100%',
            }}
          >
            <SsoLoginButton />
          </Box>
        )}

        {isSettingsLoading ? (
          <Skeleton variant="rectangular" sx={{ width: '48%', height: '40px' }} />
        ) : (
          <Box sx={{ minWidth: isPartner ? '48%' : '100%' }}>
            <GoogleLogin handleLogin={handleLogin} googleClientId={googleClientId} />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default ThirdPartyLoginButtons;
