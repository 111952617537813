import { useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSafeSnackbar } from '@hooks';
import { ICompanyOverview, QueryNamesEnums } from '@interfaces';
import { StringFieldModel, useDebouncedEffect, useStringFieldModel } from '@models';
import { updateCompany } from '@globalService';
import { checkIsOwner, getTeamRole, regexValidation } from '@utils';
import { AuthContext } from '@context';

export type ControllerInterface = {
  stringField: StringFieldModel;
};

export const useCompanyInfoField = (item: string, value: string): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const queryClient = useQueryClient();

  const stringFieldParams = {
    initValue: value,
    validationRule: (value) => regexValidation(item, value),
    validateOnChange: true,
  };
  const stringField = useStringFieldModel(stringFieldParams);

  const companyUpdate = useCallback(() => {
    if (stringField.isValid) {
      updateCompanyMutation.mutateAsync({ company: { [item]: stringField.value } });
    }
  }, [item, stringField.value]);

  useDebouncedEffect(companyUpdate, [item, stringField.value], 500);

  const updateCompanyMutation = useMutation<Response, Error, ICompanyOverview>(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_MY_COMPANY);
      if (!user?.company_information_first_updated_at && checkIsOwner(teamRole))
        queryClient.invalidateQueries(QueryNamesEnums.GET_USER);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return {
    stringField,
  };
};
