import React from 'react';
import { HookState } from '@interfaces';
import { LoadingSkeleton, ReactTable, ServiceMessage, StyledBox } from '@components';
import { useRequestsList } from './controller';
import { Typography } from '@mui/material';
import { colors } from '@theme';

const ProjectPaymentRequestList = () => {
  const { state, requests, columns } = useRequestsList();

  switch (state) {
    case HookState.FETCHING: {
      return <LoadingSkeleton type="twoBlocks" />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="requests list" />;
    }

    case HookState.SUCCESS: {
      return (
        <StyledBox sx={{ mt: 2, px: 0 }}>
          <Typography variant="h3" sx={{ p: 2, pt: 0 }}>
            Draws to date
          </Typography>
          <ReactTable
            data={requests}
            columns={columns}
            headerBgColor={colors.neutral.lightest}
            showVerticalBorders
            source="project__payment_configuration__table"
          />
        </StyledBox>
      );
    }
    default:
      return null;
  }
};

export default ProjectPaymentRequestList;
