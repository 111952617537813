import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.neutral.light }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2811 9.30413C19.8285 8.74434 19.7882 7.87456 19.1911 7.36142C18.5939 6.84828 17.6662 6.8861 17.1188 7.44589L10.186 14.5364L7.59342 11.6198C7.07486 11.0364 6.15004 10.9576 5.52776 11.4437C4.90549 11.9298 4.82142 12.7969 5.33998 13.3803L9.00663 17.5053C9.27755 17.81 9.67567 17.9903 10.0987 17.9996C10.5218 18.009 10.9286 17.8466 11.2145 17.5541L19.2811 9.30413Z"
      fill={color}
    />
  </svg>
);

export default Icon;
