import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useSubmitCredit } from './controller';
import { CustomSelect, TextInputWithTooltip, WysiwygEditor } from '@components';
import { notAllowNegative } from '@utils';
import LoadingButton from '@mui/lab/LoadingButton';
import { CreditReasonEnum, IMilestone } from '@interfaces';

const SubmitCreditPanel: FC<{
  milestoneId?: string;
  requestId?: string;
  source: string;
  setOpen?: CallableFunction;
  withProjectName?: boolean;
  onCreditChangeCompleted?: (data: IMilestone) => void;
}> = ({ milestoneId, requestId, setOpen, source, withProjectName, onCreditChangeCompleted }) => {
  const {
    name,
    reason,
    setReason,
    commentField,
    requested_credit_amount,
    resetState,
    submit,
    isSubmiting,
  } = useSubmitCredit({
    requestId,
    milestoneId,
    setOpen,
    onCreditChangeCompleted,
  });
  return (
    <Stack flex={1}>
      {withProjectName && (
        <Typography variant="body2">
          Project:
          <Typography variant="body2SemiBold"> {name}</Typography>
        </Typography>
      )}
      <Stack mt={withProjectName ? 3 : 1} flex={1} spacing={4}>
        <Stack spacing={1}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography variant="h4">Submit credit amount</Typography>
          </Stack>
          <Typography variant="body2">
            You can return excess funds or correct a previous draw. The returned funds can also be
            used towards reallocation.
          </Typography>
        </Stack>
        <Stack spacing={4}>
          <TextInputWithTooltip
            label="Credit amount"
            value={requested_credit_amount.value}
            onChange={requested_credit_amount.handleChange}
            type="currency"
            fullWidth={true}
            inputProps={{
              isAllowed: notAllowNegative,
            }}
            dataTestName={`${source}__credit_amount__input`}
          />
          <CustomSelect
            disableMultiple
            label="Reason for update"
            optionsList={Object.values(CreditReasonEnum)}
            selectedOptions={reason}
            handleSelectChange={(e) => setReason([e.target.value] as CreditReasonEnum[])}
            required
            dataTestName={`${source}__credit_reasons__select`}
          />
          {reason.includes(CreditReasonEnum.OTHER) && (
            <WysiwygEditor editField={commentField} source={source} />
          )}
        </Stack>
      </Stack>
      <Stack spacing={2} justifyContent="flex-end" direction="row">
        <Button onClick={resetState} color="error" data-cy={`${source}__reset__button`}>
          Reset
        </Button>
        <LoadingButton
          loading={!!isSubmiting}
          onClick={submit}
          data-cy={`${source}__submit__button`}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default SubmitCreditPanel;
