import React, { PropsWithChildren } from 'react';
import { ClickAwayListener, IconButton, Stack } from '@mui/material';
import { Popper } from '@mui/base';
import { styled } from '@mui/system';

import { CollapseIcon, ExpandIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { ComponentProps, defaultProps } from './interface';
import * as Controller from './controller';

const StyledPopperDiv = styled('div')(
  () => `
  background-color: ${colors.background.white};
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
`,
);

const CollapsibleWidget = ({
  containerStyles = defaultProps.containerStyles,
  children,
  title,
  titleBackgroundColor,
  listStyles = defaultProps.listStyles,
  closeOnClickAway = defaultProps.closeOnClickAway,
  initiallyOpened = defaultProps.initiallyOpened,
  widgetType,
  shouldSaveState = defaultProps.shouldSaveState,
  dataTestNameButton,
  showIcon = defaultProps.showIcon,
  popperWidth = defaultProps.popperWidth,
  isWidgetOpen,
  onWidgetOpened,
  onWidgetClosed,
  placement = defaultProps.placement,
}: PropsWithChildren<ComponentProps>) => {
  const { open, handleCollapseClick, handleClose, parentStackRef, anchorEl } =
    Controller.useCollapsibleWidget({
      widgetType,
      closeOnClickAway,
      initiallyOpened,
      shouldSaveState,
      isWidgetOpen,
      onWidgetOpened,
      onWidgetClosed,
    });

  const id = open ? widgetType : undefined;

  const customModifiers = [
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [0, 8],
      },
    },
  ];

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: titleBackgroundColor,
            borderRadius: '4px',
            overflow: 'auto',
            padding: '8px',
            ...containerStyles,
          }}
          ref={parentStackRef}
          onClick={(e) => {
            !showIcon && handleCollapseClick(e);
          }}
          data-cy={showIcon ? undefined : dataTestNameButton}
        >
          {title}

          {showIcon && (
            <IconButton
              size="small"
              sx={{ ml: 2.5 }}
              onClick={handleCollapseClick}
              data-cy={dataTestNameButton}
            >
              {open ? <CollapseIcon /> : <ExpandIcon />}
            </IconButton>
          )}
        </Stack>
        <Popper
          id={id}
          open={open && isWidgetOpen}
          anchorEl={anchorEl}
          placement={placement}
          modifiers={customModifiers}
          style={{ zIndex: 1001 }}
          disablePortal={true}
        >
          <StyledPopperDiv sx={{ maxWidth: popperWidth, minWidth: popperWidth, ...listStyles }}>
            {children}
          </StyledPopperDiv>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default CollapsibleWidget;
