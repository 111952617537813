import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { ConfirmationModal, LabelAndValue } from '@components';
import { useCreateBudgetTableWrapper } from './controlller';
import CreateByModelsTable from '../CreateByModelsTable';
import CreateByUnitsTable from '../CreateByUnitsTable';
import { CSVButtonTypesEnum, PopupTypeEnum } from '@interfaces';
import { GenerateCSVButton } from '../GenerateCSVButton';

const CreateBudgetTableWrapper: FC = () => {
  const {
    deleteBudget,
    projectName,
    isConfirmOpen,
    setIsConfirmOpen,
    navigateBack,
    isModelCreation,
    csvData,
    transloaditSignature,
    isEditable,
  } = useCreateBudgetTableWrapper();

  return (
    <Stack sx={{ backgroundColor: colors.background.gray }}>
      <Stack
        sx={{ p: 3, backgroundColor: colors.background.white }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row">
          {transloaditSignature?.signature && (
            <GenerateCSVButton
              onClose={navigateBack}
              transloaditSignature={transloaditSignature}
              csvData={csvData}
              type={CSVButtonTypesEnum.BACK_ICON}
              shouldGenerateCSV={isEditable}
            />
          )}
          <Typography sx={{ ml: 2 }} variant="h1">
            Budget naming & Structure
          </Typography>
        </Stack>
        <Stack direction="row">
          <LabelAndValue
            label="Project:"
            text={projectName}
            size="body1"
            marginRightAfterLabel={false}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          flex: 1,
          p: 3,
        }}
      >
        {isModelCreation ? (
          <CreateByModelsTable isEditable={isEditable} />
        ) : (
          <CreateByUnitsTable isEditable={isEditable} />
        )}
      </Box>
      {isEditable && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ position: 'relative', p: 3, backgroundColor: colors.background.white }}
        >
          {transloaditSignature?.signature && (
            <GenerateCSVButton
              onClose={navigateBack}
              transloaditSignature={transloaditSignature}
              csvData={csvData}
              type={CSVButtonTypesEnum.BACK_BUTTON}
            />
          )}
          <Button
            variant="new"
            color="error"
            onClick={() => setIsConfirmOpen(true)}
            sx={{ mr: 1 }}
            data-cy="project__budget__create__structure_table__reset__button"
          >
            Reset
          </Button>
          {transloaditSignature?.signature && (
            <GenerateCSVButton
              onClose={navigateBack}
              transloaditSignature={transloaditSignature}
              csvData={csvData}
            />
          )}
        </Stack>
      )}
      <ConfirmationModal
        open={isConfirmOpen}
        title={`Reset created ${isModelCreation ? 'models' : 'units'}`}
        text={`All ${isModelCreation ? 'models' : 'units'} will be deleted.`}
        onClose={() => setIsConfirmOpen(false)}
        confirmCallback={deleteBudget}
        type={PopupTypeEnum.CONFIRMATION}
      />
    </Stack>
  );
};

export default CreateBudgetTableWrapper;
