import 'react-international-phone/style.css';

import { BaseTextFieldProps, TextField } from '@mui/material';
import React from 'react';
import { defaultCountries, usePhoneInput } from 'react-international-phone';
import { PhoneFieldModel } from '@models';

export interface MUIPhoneProps extends BaseTextFieldProps {
  field: PhoneFieldModel;
}

export const InternationalPhoneField: React.FC<MUIPhoneProps> = ({ field, ...restProps }) => {
  const { inputValue, handlePhoneValueChange, inputRef } = usePhoneInput({
    defaultCountry: 'us',
    value: field?.value,
    countries: defaultCountries,
    onChange: (data) => {
      field?.handleChange(data.phone);
    },
    disableDialCodeAndPrefix: true,
  });

  return (
    <TextField
      fullWidth
      variant="outlined"
      label="Phone number"
      color="primary"
      placeholder="Phone number"
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      size="small"
      error={!field?.isValid}
      helperText={field?.isValid ? '' : 'Please enter a valid phone number'}
      {...restProps}
    />
  );
};
