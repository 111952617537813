import React, { FC, memo, useContext, useMemo } from 'react';
import { Typography, Stack, Skeleton } from '@mui/material';
import {
  currencyFormatter,
  isRequestDraft,
  useBlockerFooter,
  isRequestInReview,
  useBlockerCell,
} from '@utils';
import {
  MilestoneListColumnType,
  CommonRowType,
  ColumnLabel,
  ProgressionSum,
  HeaderText,
} from './common';
import { TableContext } from '../controller';
import { ColumnV2Width, ColumnWidth, IMilestoneTotal } from '@interfaces';

const RevisedValueSubmission: FC<{ row: CommonRowType }> = ({ row }) => {
  const { requested_adjustments, approved_adjustments } = row;
  const { requestStatus, source } = useContext(TableContext);
  const isBlocked = useBlockerCell({ milestoneId: row.id });

  const progress = useMemo(
    () =>
      isRequestDraft(requestStatus)
        ? requested_adjustments || 0
        : isRequestInReview(requestStatus)
          ? approved_adjustments || 0
          : 0,
    [requested_adjustments, approved_adjustments, requestStatus],
  );

  const sum = useMemo(
    () =>
      isRequestDraft(requestStatus)
        ? row.revised_estimate || 0
        : (row.revised_estimate || 0) - progress,
    [row, progress, requestStatus],
  );
  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Stack alignItems="flex-end">
      <Typography
        variant="body3"
        data-cy={`${source}__body__revised_estimate__value__index_${row?.project_milestone?.index}`}
      >
        {currencyFormatter(sum)}
      </Typography>
      <ProgressionSum
        sum={sum}
        progress={progress}
        dataTestName={`${source}__body__revised_estimate__next_value__index_${row?.project_milestone?.index}`}
      />
    </Stack>
  );
};

const Memoized = memo(RevisedValueSubmission);

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { requestStatus, source } = useContext(TableContext);

  const progress = isRequestDraft(requestStatus)
    ? row.requested_budget_change
    : isRequestInReview(requestStatus)
      ? row.approved_budget_change
      : 0;

  const sum = isRequestInReview(requestStatus)
    ? row.revised_estimate - progress
    : row.revised_estimate;

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Stack alignItems="flex-end">
      <Typography variant="body3SemiBold" data-cy={`${source}__footer__revised_estimate__value`}>
        {currencyFormatter(sum)}
      </Typography>
      <ProgressionSum
        sum={sum}
        progress={progress}
        dataTestName={`${source}__footer__revised_estimate__next_value`}
      />
    </Stack>
  );
};

const revisedEstimate: MilestoneListColumnType = {
  name: 'revised_estimate',
  columnText: 'Revised scheduled value ($)',
  width: ColumnWidth.MONEY_WITH_PROGRESS,

  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Revised construction budget reflecting approved changes">
      <ColumnLabel>Revised</ColumnLabel>
      <ColumnLabel> scheduled value($)</ColumnLabel>
    </HeaderText>
  ),
};

export default revisedEstimate;
