import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';
import { HookState, QueryNamesEnums } from '@interfaces';
import { getDrawRequest, getProject } from '@globalService';
import {
  calculateFraction,
  currencyFormatter,
  getHookState,
  isFeesPaidByBorrower,
  percentFormatter,
} from '@utils';

export interface ControllerInterface {
  state: HookState;
  showRetainage: boolean;
  totalAmount: string;
  retainageHeldback: number;
  retainageReleased: string;
  netFundsForDisbursement: string;
  borrowerEquityWithFees: number;
  fees: string;
  borrowerPortionText: { title: string; text: string };
}

export const useDrawPaymentInfo = (): ControllerInterface => {
  const { projectId, drawRequestId } = useParams();

  const requestedProjectQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId }),
      enabled: Boolean(drawRequestId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
      enabled: Boolean(projectId),
    },
  ]);

  const drawRequest = useMemo(
    () => requestedProjectQueries[0]?.data,
    [requestedProjectQueries[0]?.data],
  );
  const retainageRate = useMemo(
    () => requestedProjectQueries[1]?.data?.retainage_rate,
    [requestedProjectQueries[1]?.data?.retainage_rate],
  );

  const revisedValueTotal = useMemo(
    () => drawRequest?.construction_holdback + drawRequest?.borrower_equity || 0,
    [drawRequest?.construction_holdback, drawRequest?.borrower_equity],
  );
  const borrowerFraction = useMemo(
    () =>
      percentFormatter({
        value: calculateFraction(drawRequest?.borrower_equity, revisedValueTotal),
      }),
    [drawRequest?.borrower_equity, revisedValueTotal],
  );

  const borrowerEquityWithFees = useMemo(
    () => drawRequest?.borrower_equity_with_fees || 0,
    [drawRequest?.borrower_equity_with_fees],
  );

  const totalAmount = useMemo(
    () => currencyFormatter(drawRequest?.approved_amount || 0),
    [drawRequest?.approved_amount],
  );

  const retainageHeldback = useMemo(
    () => drawRequest?.totals?.all?.retainage_approved_amount_holdback || 0,
    [drawRequest?.totals?.all?.retainage_approved_amount_holdback],
  );

  const retainageReleased = useMemo(
    () => currencyFormatter(drawRequest?.totals?.all?.retainage_release_approved || 0),
    [drawRequest?.totals?.all?.retainage_release_approved],
  );

  const borrowerPortionText = useMemo(() => {
    let title = `Borrower portion = ${borrowerFraction} from`;
    title += retainageRate
      ? ` (Total draw amount - Retainage heldback + Retainage released)`
      : ` Total draw amount`;
    title += isFeesPaidByBorrower(drawRequest) ? ' - Fees' : '';

    let text = `${currencyFormatter(borrowerEquityWithFees)} = ${borrowerFraction} from`;
    text += retainageRate
      ? ` (${totalAmount} - ${currencyFormatter(retainageHeldback)} + ${retainageReleased})`
      : ` ${totalAmount}`;
    text += isFeesPaidByBorrower(drawRequest)
      ? ` - ${currencyFormatter(drawRequest?.fees_amount)}`
      : '';

    return {
      title,
      text,
    };
  }, [
    borrowerFraction,
    borrowerEquityWithFees,
    totalAmount,
    retainageHeldback,
    retainageReleased,
    retainageRate,
    drawRequest,
  ]);

  return {
    state: getHookState(requestedProjectQueries),
    showRetainage: Boolean(retainageRate),
    totalAmount,
    retainageReleased,
    fees: currencyFormatter(drawRequest?.fees_amount * -1 || 0),
    netFundsForDisbursement: currencyFormatter(drawRequest?.construction_holdback_with_fees || 0),
    retainageHeldback,
    borrowerEquityWithFees,
    borrowerPortionText,
  };
};
