/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

import { colors } from '@theme';

import { CompanyIcon, LogoutIcon, PersonRoundedIcon, SettingsIcon } from '@svgAsComponents';
import * as Controller from './controller';
import { isRestricted } from '@utils';
import { PermissionNamesEnums } from '@interfaces';
import { PermissionsContext } from '@context';
import UserAvatar from '../UserAvatar';

const StyledMenu = styled(Menu)({
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiListItemText-primary': {
    fontSize: '0.75rem',
  },
  '& .MuiListItemText-secondary': {
    fontSize: '0.75rem',
  },
});
const UserProfileWithMenu = () => {
  const { permissions } = useContext(PermissionsContext);
  const { popover, isExternalUser, companyId, handleLogout, user, avatarBackgroundColor } =
    Controller.useUserProfileMenu();

  return (
    <>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={popover.handleOpen}
        size="small"
        data-cy="user_menu"
        tour-step="user_menu"
      >
        <UserAvatar user={user} color={avatarBackgroundColor} />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={popover.anchorEl}
        keepMounted={false}
        open={Boolean(popover.anchorEl)}
        onClose={popover.handleClose}
        PaperProps={{
          style: {
            marginTop: 10,
            minWidth: 260,
            borderRadius: 4,
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
          },
        }}
        sx={{ pt: 0, pb: 0 }}
      >
        <Stack p={2}>
          <Typography variant="body3SemiBold" sx={{ marginBottom: 0.5 }}>
            {user?.first_name} {user?.last_name}
          </Typography>
          <Typography variant="label">{user?.email}</Typography>
        </Stack>
        <Box sx={{ height: '8px', backgroundColor: colors.neutral.lighter }} />
        {isExternalUser && (
          <MenuItemComponent
            onClick={popover.handleClose}
            icon={PersonRoundedIcon}
            text="Personal settings"
            component={NavLink}
            to="/profile/personal"
            tour-step="user_menu_profile"
          />
        )}
        {!isMobileOnly && (
          <MenuItemComponent
            onClick={popover.handleClose}
            icon={CompanyIcon}
            text="Organization settings"
            component={NavLink}
            to={`/company/${companyId}/general`}
            data-cy="company_section_link"
          />
        )}
        {!isMobileOnly &&
          !isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions) && (
            <MenuItemComponent
              onClick={popover.handleClose}
              icon={SettingsIcon}
              text="Platform settings"
              component={NavLink}
              to={`/platform-settings`}
              data-cy="platform_settings_section_link"
            />
          )}

        <Box sx={{ height: '8px', backgroundColor: colors.neutral.lighter }} />
        <MenuItemComponent
          data-cy="user_logout"
          onClick={handleLogout}
          icon={LogoutIcon}
          text="Log out"
        />
      </StyledMenu>
    </>
  );
};

const MenuItemComponent = ({ onClick = null, icon, text, divider = true, ...props }) => {
  const Icon = icon;
  return (
    <MenuItem
      onClick={onClick}
      divider={divider}
      sx={{
        '&.MuiMenuItem-root': { minHeight: '52px' },
        '&.MuiMenuItem-divider': { borderBottomColor: colors.neutral.lighter },
      }}
      {...props}
    >
      <ListItemIcon sx={{ '&.MuiListItemIcon-root': { minWidth: '22px', maxWidth: '22px' } }}>
        <Icon color={colors.icons.green} size={16} />
      </ListItemIcon>
      <ListItemText primary={<Typography variant="body3">{text}</Typography>} />
    </MenuItem>
  );
};

export default UserProfileWithMenu;
