import { colors, typography } from '@theme';

export default function ToggleButtonGroup() {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          selfAlign: 'stretch',
          justifyContent: 'stretch',
        },
        grouped: {
          '& span': {
            ...typography.body2SemiBold,
          },
          '&:not(:last-of-type)': {
            borderRight: `2px solid ${colors.background.white}`,
          },
          flex: 1,
          border: 0,
          borderRadius: 0,
          background: colors.status.information.lighter,
          textTransform: 'none',
          color: colors.status.information.medium,
          flexShrink: 0,
          whiteSpace: 'nowrap',
        },
      },
      defaultProps: {},
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: '4px 24px',
          '&.Mui-selected': {
            background: colors.status.information.medium,
            color: colors.neutral.white,
          },
          '&.MuiToggleButton-sizeLarge': {
            padding: '7px 24px',
          },
          '&:hover': {
            backgroundColor: colors.status.information.light,
            color: colors.status.information.medium,
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.status.information.light,
            color: colors.status.information.medium,
          },
        },
      },
    },
  };
}
