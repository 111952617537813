import React, { CSSProperties, FC, PropsWithChildren, ReactElement } from 'react';
import { Box, Grid2, IconButton, Typography } from '@mui/material';

export interface INodeCard {
  title: string;
  style?: CSSProperties;
  rightIcons?: Array<{
    Component: ReactElement;
    handle?: () => void;
  }>;
}

const NodeCard: FC<PropsWithChildren<INodeCard>> = ({ children, title, rightIcons, style }) => {
  return (
    <Grid2 style={style} container flexDirection="column" sx={{ p: 2 }}>
      <Grid2 container justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
        <Grid2>
          <Typography role="heading" variant="h4">
            {title}
          </Typography>
        </Grid2>
        <Grid2 sx={{ mr: -1 }}>
          {rightIcons?.map(({ Component, handle }, index) =>
            handle ? (
              <IconButton size="small" onClick={handle} key={index}>
                {Component}
              </IconButton>
            ) : (
              Component
            ),
          )}
          <Box sx={{ mr: 1 }} component="span" />
        </Grid2>
      </Grid2>
      {children}
    </Grid2>
  );
};

export default NodeCard;
