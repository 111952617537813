import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  DrawRequestStatus,
  DrawRequestTypeEnum,
  EnumTypeForList,
  IDrawRequest,
  IProjectsPortfolioItem,
  PostDrawRequest,
  ProjectStatusEnum,
  QueryNamesEnums,
} from '@interfaces';
import { getProjectsList, postDrawRequest } from '@globalService';
import { DropdownFieldModel, useDropdownFieldModel } from '@models';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReviewRequestInvalidation, useSafeSnackbar } from '@hooks';

interface IAddRequestPopupController {
  projectField: DropdownFieldModel;
  projectOptions: EnumTypeForList[];
  createModalVisible: boolean;
  setCreateModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  createRequest: (chosenType: DrawRequestTypeEnum) => void;
  onAddRequestClick: () => void;
  projectId: string;
  isLoading: boolean;
}

const createProjectsQuery = ({ drawRequestStatus = null, hasDrawRequests = true }) => {
  // TODO limit=9999 should be solved by implementing pagination to filters ENG-8871
  const baseQuery = `project_status=${ProjectStatusEnum.ACTIVE}&query={id,name}&limit=9999`;
  if (drawRequestStatus) {
    return `${baseQuery}&draw_request_status=${drawRequestStatus}`;
  }
  if (!hasDrawRequests) {
    return `${baseQuery}&has_draw_requests=false`;
  }
  return baseQuery;
};

export const useAddRequestPopup = (onClose: () => void): IAddRequestPopupController => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();
  const completedDRProjectsQuery = createProjectsQuery({
    drawRequestStatus: DrawRequestStatus.COMPLETED,
  });
  const noDRProjectsQuery = createProjectsQuery({ hasDrawRequests: false });

  const projectField = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });

  const { data: completedDRProjects, isLoading } = useQuery<
    { results: IProjectsPortfolioItem[]; count: number },
    Error
  >(
    [QueryNamesEnums.GET_PROJECTS_LIST, { filterStringQuery: completedDRProjectsQuery }],
    getProjectsList.bind(this, {
      filterStringQuery: completedDRProjectsQuery,
    }),
  );

  const { data: noDRProjects, isLoading: isNoDrProjectsLoading } = useQuery<
    { results: IProjectsPortfolioItem[]; count: number },
    Error
  >(
    [QueryNamesEnums.GET_PROJECTS_LIST, { filterStringQuery: noDRProjectsQuery }],
    getProjectsList.bind(this, {
      filterStringQuery: noDRProjectsQuery,
    }),
  );

  const projectId = useMemo(() => projectField.value?.id, [projectField.value]);

  const addRequestMutation = useMutation<IDrawRequest, Error, PostDrawRequest>(postDrawRequest, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
      handleRequestReviewInvalidation({
        projectId,
        drawRequestId: data?.id,
      });
      navigate(`/projects/${projectId}/requests/${data?.id}/submission/`);
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const projectOptions = useMemo(() => {
    if (noDRProjects?.results && completedDRProjects?.results) {
      return [...completedDRProjects.results, ...noDRProjects.results].map(({ id, name }) => ({
        id,
        name,
        name_display: name,
      }));
    }
    return [];
  }, [completedDRProjects?.results, noDRProjects?.results]);

  const createRequest = useCallback(
    (chosenType: DrawRequestTypeEnum) => {
      addRequestMutation.mutateAsync({
        id: projectId,
        type: chosenType,
      });
    },
    [projectId],
  );

  const onAddRequestClick = useCallback(() => {
    if (projectField.validate()) setCreateModalVisible(true);
  }, [projectField]);

  return {
    projectField,
    projectOptions,
    createModalVisible,
    setCreateModalVisible,
    createRequest,
    onAddRequestClick,
    projectId,
    isLoading: isLoading || isNoDrProjectsLoading,
  };
};
