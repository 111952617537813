import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { useMemo } from 'react';
import find from 'lodash/find';

import { ISystemConfig, QueryNamesEnums, SystemConfigKeysEnum } from '@interfaces';
import { getSystemConfig } from '@globalService';

export type ControllerInterface = {
  refetch: () => Promise<UseQueryResult> | void;
  csvBoxSheetId: string;
};

export const useCSVUploader = ({ projectId, sheetKey }): ControllerInterface => {
  const queryClient = useQueryClient();

  const configQuery = useQuery<{ results: ISystemConfig[] }, Error>(
    [QueryNamesEnums.GET_SYSTEM_CONFIG],
    getSystemConfig.bind(this),
  );

  const updateProjectListQueries = () => {
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_LIST);
  };

  const updateProjectQueries = () => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
  };

  const csvBoxSheetId = useMemo(
    () => find(configQuery.data?.results, { key: SystemConfigKeysEnum.CSVBOX })?.value?.[sheetKey],
    [configQuery.data],
  );

  const refetch = useMemo(() => {
    if (projectId) return updateProjectQueries;
    return updateProjectListQueries;
  }, [projectId]);

  return {
    refetch,
    csvBoxSheetId,
  };
};
