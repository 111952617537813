import { ITeam, IDrawRequest } from '@interfaces';

import { useProjectTeamsAndCompanies } from '@hooks';

interface ControllerInterface {
  companyTeamsWithoutCurrentUserTeam: ITeam[];
}
export const useApprovalTeams = (request: IDrawRequest): ControllerInterface => {
  const { companyTeamsWithoutCurrentUserTeam } = useProjectTeamsAndCompanies({
    currentReviewerTeam: request?.current_reviewer_team,
  });

  return {
    companyTeamsWithoutCurrentUserTeam,
  };
};
