import React, { FC, useContext, useMemo } from 'react';
import { IInspection, ServiceOrderStatusEnum } from '@interfaces';
import { isCompletedService } from '@utils';
import { LabelAndValueWithPreload, StatusChip } from '@components';
import { serviceOrderStatusMap, statusMap } from '@constants';
import { SettingsContext } from '@context';
import { useDateFormatter } from '@hooks';

export interface ComponentProps {
  inspection: IInspection;
  label?: string;
  isLoading?: boolean;
  multi?: IInspection[];
}

const InspectionLabelAndValue: FC<ComponentProps> = ({ inspection, label, isLoading, multi }) => {
  const { settings } = useContext(SettingsContext);
  const { dateFormatter } = useDateFormatter();
  const { color, backgroundColor } = useMemo(
    () =>
      statusMap(
        inspection?.status || ServiceOrderStatusEnum.CREATED,
        settings.display,
        'inspection',
      ),
    [inspection],
  );

  return (
    <>
      {isCompletedService(inspection?.status) ? (
        <LabelAndValueWithPreload
          isLoading={isLoading}
          label={label || 'Inspection'}
          text={
            multi
              ? multi.map((item) => dateFormatter({ date: item?.completed_at })).join('|')
              : dateFormatter({ date: inspection?.completed_at })
          }
        />
      ) : (
        <LabelAndValueWithPreload label={label || 'Inspection'} isLoading={isLoading}>
          <StatusChip
            color={color}
            backgroundColor={backgroundColor}
            label={serviceOrderStatusMap[inspection?.status] || 'Not ordered'}
          />
        </LabelAndValueWithPreload>
      )}
    </>
  );
};

export default InspectionLabelAndValue;
