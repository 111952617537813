import { StringFieldModel, useStringFieldModel } from '@models';
import { ICustomLink } from '@interfaces';
import { regexValidation } from '@utils';
import { useEffect } from 'react';

interface ControllerInterface {
  urlField: StringFieldModel;
  labelField: StringFieldModel;
  handleSave: () => void;
  handleRemove: () => void;
}

export const useCustomNavLink = (
  link: ICustomLink,
  saveCallback: (link: ICustomLink) => void,
  removeCallback: (link: ICustomLink) => void,
): ControllerInterface => {
  const urlField = useStringFieldModel({
    initValue: '',
    validationRule: (value) => value && regexValidation('url', value),
    validateOnChange: false,
  });

  const labelField = useStringFieldModel({
    initValue: '',
    validationRule: (value) => value && value.length >= 3 && value.length <= 16,
    validateOnChange: false,
  });

  useEffect(() => {
    urlField.setValue(link.url || '');
    labelField.setValue(link.label || '');
  }, [link]);

  const handleSave = () => {
    urlField.validate();
    labelField.validate();

    if (
      urlField.value &&
      urlField.validationRule(urlField.value) &&
      labelField.value &&
      labelField.validationRule(labelField.value)
    )
      saveCallback({ ...link, url: urlField.value, label: labelField.value });
  };

  const handleRemove = () => removeCallback(link);

  return {
    urlField,
    labelField,
    handleSave,
    handleRemove,
  };
};
