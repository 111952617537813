import { useState } from 'react';
import { EditPaymentConfigurationTypeInterface, PaymentConfiguration } from '@interfaces';
import { useLaunchDarklyFlags } from '@context';

export const useEditPaymentConfigurationType = ({
  initialConfigurationType = PaymentConfiguration.PER_DRAW_REQUEST,
}: {
  initialConfigurationType?: PaymentConfiguration;
}): EditPaymentConfigurationTypeInterface => {
  const flags = useLaunchDarklyFlags();
  const [configurationType, setConfigurationType] = useState(initialConfigurationType);

  const configurationTypeOptions = [
    {
      value: PaymentConfiguration.SEQUENTIAL,
      label: 'Project sequential (Borrower equity first)',
    },
    {
      value: PaymentConfiguration.PER_DRAW_REQUEST,
      label: 'Partial per request (Loan to equity split at each disbursement)',
    },
  ];

  const configurationTypeOptionsV2 = [
    {
      value: PaymentConfiguration.PER_DRAW_REQUEST,
      label: 'Pari Passu funding',
    },
    {
      value: PaymentConfiguration.SEQUENTIAL,
      label: 'Borrower equity first',
    },
    {
      value: PaymentConfiguration.REVERSE_SEQUENTIAL,
      label: 'Construction holdback first',
    },
  ];

  const handleConfigurationTypeChange = (event) => {
    setConfigurationType(event.target.value);
  };

  return {
    configurationType,
    configurationTypeOptions: flags?.['ENG_8716_introduce_funding_sources']
      ? configurationTypeOptionsV2
      : configurationTypeOptions,
    handleConfigurationTypeChange,
    setConfigurationType,
  };
};
