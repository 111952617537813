import { EnumTypeForList, IUISettings } from '@interfaces';
import { uiSettings } from '../paths';
import { dataProvider } from '@api';

export const getUISettings = async (): Promise<IUISettings> =>
  dataProvider.get(uiSettings()).json();

export const getUISettingsServicesTypes = async ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  restQlParams,
}: {
  restQlParams?: string;
}): Promise<{ results: EnumTypeForList[] }> => {
  const data = await getUISettings();
  if (Array.isArray(data.display?.service_types)) return { results: data.display?.service_types };
  return { results: [] };
};

export const getUISettingsLoanTypes = async ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  restQlParams,
}: {
  restQlParams?: string;
}): Promise<{ results: EnumTypeForList[] }> => {
  const data = await getUISettings();
  if (Array.isArray(data.display?.loan_types)) return { results: data.display?.loan_types };
  return { results: [] };
};

export const updateUiSettings = async (settings: Partial<IUISettings>): Promise<IUISettings> =>
  dataProvider
    .patch(uiSettings(), {
      json: settings,
    })
    .json();
