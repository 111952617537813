import React from 'react';
import {
  Link,
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Box, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';

import * as Controller from './controller';
import LoanDetails from './LoanDetails';
import InspectionsSettings from './InspectionsSettings';
import GeneralSettings from './GeneralSettings';
import { colors } from '@theme';
import { HookState } from '@interfaces';
import { LoadingSkeleton, ProjectNameLabel, ServiceMessage, StyledBox } from '@components';
import Payments from './Payments';
import { ArrowBackIcon } from '@svgAsComponents';

const TABS = {
  GENERAL: { label: 'General', value: 'general' },
  LOAN: { label: 'Loan', value: 'loan' },
  PAYMENTS: { label: 'Payments', value: 'payments' },
  INSPECTIONS: { label: 'Inspections', value: 'inspections' },
};

const ProjectSettings = () => {
  const navigate = useNavigate();
  const { pathname, state: locationState } = useLocation();
  const { projectId } = useParams();
  const match = matchPath('/projects/:projectId/settings/:tab/*', pathname);
  const pathBase = `/projects/${projectId}/settings`;
  const controller = Controller.useProjectSettings();
  const { isLoading, project } = controller;

  const {
    isAllProjectDetailsDisabled,
    isLimitedProjectDetailsDisabled,
    hasInspectionsViewPermission,
    hasInspectionsEditPermission,
  } = controller;

  if (isLoading) {
    return (
      <StyledBox sx={{ flex: 1 }}>
        <LoadingSkeleton type="overviewBlock" />
      </StyledBox>
    );
  }

  return match?.params?.tab ? (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: { md: 3, xs: 2 }, pr: 4 }}
      >
        <Stack direction="row" spacing={2} sx={{ px: { md: 3, xs: 2 } }}>
          <IconButton
            onClick={() =>
              navigate(locationState?.['prevUrl'] || `/projects/${project.id}/overview/`)
            }
            data-cy="project__settings__payments__back__icon"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h1">Project settings</Typography>
        </Stack>
        <ProjectNameLabel project={project} showStatusChip />
      </Stack>

      <Box sx={{ mt: { md: 1.5, xs: 0 }, position: 'relative' }}>
        <Tabs
          TabIndicatorProps={{
            style: { transition: 'none' },
            children: <span />,
          }}
          textColor="secondary"
          indicatorColor="secondary"
          value={match?.params.tab || TABS.GENERAL.value}
        >
          <Tab
            label={TABS.GENERAL.label}
            value={TABS.GENERAL.value}
            component={Link}
            to={`${pathBase}/${TABS.GENERAL.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`projects_settings__tab__${TABS.GENERAL.value}`}
          />
          <Tab
            label={TABS.LOAN.label}
            value={TABS.LOAN.value}
            component={Link}
            to={`${pathBase}/${TABS.LOAN.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`projects_settings__tab__${TABS.LOAN.value}`}
          />

          {hasInspectionsViewPermission && (
            <Tab
              label={TABS.INSPECTIONS.label}
              value={TABS.INSPECTIONS.value}
              component={Link}
              to={`${pathBase}/${TABS.INSPECTIONS.value}`}
              sx={{ ml: { md: 3, xs: 2 } }}
              data-cy={`projects_settings__tab__${TABS.INSPECTIONS.value}`}
            />
          )}

          <Tab
            label={TABS.PAYMENTS.label}
            value={TABS.PAYMENTS.value}
            component={Link}
            to={`${pathBase}/${TABS.PAYMENTS.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`projects_settings__tab__${TABS.PAYMENTS.value}`}
          />
        </Tabs>
      </Box>
      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray }}>
        <Routes>
          <Route
            path={TABS.GENERAL.value}
            element={
              <GeneralSettings
                project={project}
                isAllProjectDetailsDisabled={isAllProjectDetailsDisabled}
                isLimitedProjectDetailsDisabled={isLimitedProjectDetailsDisabled}
              />
            }
          />
          <Route
            path={TABS.LOAN.value}
            element={
              <LoanDetails
                project={project}
                isAllProjectDetailsDisabled={isAllProjectDetailsDisabled}
                isLimitedProjectDetailsDisabled={isLimitedProjectDetailsDisabled}
              />
            }
          />
          <Route
            path={TABS.INSPECTIONS.value}
            element={
              <InspectionsSettings
                project={project}
                hasInspectionsEditPermission={hasInspectionsEditPermission}
              />
            }
          />
          <Route path={TABS.PAYMENTS.value} element={<Payments project={project} />} />
        </Routes>
      </Stack>
    </Box>
  ) : (
    <Navigate to={`${pathBase}/${TABS.GENERAL.value}`} replace />
  );
};

export default ProjectSettings;
