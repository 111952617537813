import React, { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { tabClasses } from '@mui/material/Tab';

import { LogoWithName } from '@svgAsComponents';
import { Loader } from '@components';
import { colors, typography } from '@theme';
import { COMPANY_DATA } from '@constants';
import * as Controller from './controller';
import { isMobile } from 'react-device-detect';

const TABS = {
  TOS: {
    label: '1. Terms of Service',
    value: 'tos',
    src: COMPANY_DATA.TERMS_OF_SERVICE_LINK,
  },
  PP: { label: '2. Privacy Policy', value: 'pp', src: COMPANY_DATA.PRIVACY_POLICY_LINK },
};

const TermsOfServiceModal: FC = () => {
  const {
    open,
    handleLogout,
    acceptTerms,
    isTermsRead,
    setTermsRead,
    isLoading,
    handleHideLoader,
    isAccepting,
  } = Controller.useToSModal();

  const smallMediaQuery = useMediaQuery('(max-width:600px)');
  const [tab, setTab] = useState('tos');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const body = () => (
    <Stack
      sx={{
        backgroundColor: colors.background.gray,
        position: 'fixed',
        top: 0,
        height:
          isMobile && document.documentElement.style.getPropertyValue('--app-height')
            ? 'var(--app-height)'
            : '100vh',
        width: '100%',
        left: 0,
        margin: 0,
        zIndex: 1200,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: { md: 3, xs: 2 },
          py: 3,
          backgroundColor: 'white',
        }}
      >
        <Typography variant="h1">
          {smallMediaQuery ? 'Agreements' : 'Terms of service & Privacy policy'}
        </Typography>
        <Button onClick={handleLogout} variant="text">
          Logout
        </Button>
      </Stack>
      <Stack sx={{ backgroundColor: 'white', flexGrow: 1 }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          TabIndicatorProps={{ children: <span /> }}
          sx={{
            [`& .${tabClasses.root}`]: { ...typography.body2SemiBold, textTransform: 'none' },
            pl: { md: 1 },
          }}
        >
          <Tab label={TABS.TOS.label} value={TABS.TOS.value} />
          <Tab label={TABS.PP.label} value={TABS.PP.value} />
        </Tabs>
        <Stack
          sx={{
            backgroundColor: colors.background.gray,
            height: '100%',
          }}
        >
          {isLoading && <Loader />}
          <Stack
            sx={{
              backgroundColor: 'white',
              mx: 3,
              mt: 3,
              mb: 3,
              p: 2,
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <LogoWithName />
            <Box sx={{ height: '100%', pb: 2 }}>
              <iframe
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                onLoad={handleHideLoader}
                src={TABS[tab.toUpperCase()]?.src}
              ></iframe>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Box
        p={3}
        sx={{
          backgroundColor: 'white',
          flexDirestion: 'row',
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-end' },
        }}
      >
        <FormControlLabel
          control={<Checkbox checked={isTermsRead} onChange={(_e, value) => setTermsRead(value)} />}
          label={
            <Typography variant="body3">
              I have read two agreements and fully understand my rights on the platform.
            </Typography>
          }
          sx={{ mr: 3 }}
        />
        <Button
          fullWidth={smallMediaQuery}
          onClick={acceptTerms}
          disabled={!isTermsRead || isAccepting}
        >
          {isAccepting ? 'Accepting...' : 'Agree & Accept'}
        </Button>
      </Box>
    </Stack>
  );
  if (!open) return null;
  return <>{ReactDOM.createPortal(body(), document.body)}</>;
};

export default TermsOfServiceModal;
