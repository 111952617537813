import React, { FC } from 'react';
import { Popup, CustomTextField, CustomAutocomplete } from '@components';
import { Button, Stack } from '@mui/material';
import * as Controller from './controller';
import LoadingButton from '@mui/lab/LoadingButton';

const AddTeamPopup: FC<{
  open: boolean;
  onClose: () => void;
  nextApprovalLevel: number;
}> = ({ open, onClose, nextApprovalLevel }) => {
  const { teamName, teamPermissionRole, handleAddTeam, isSubmitting, rolesList } =
    Controller.useAddTeamPopup({ onClose, nextApprovalLevel });

  return (
    <Popup open={open} maxWidth="sm" title="Add team">
      <Stack sx={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
        <Stack spacing={2}>
          <CustomTextField
            field={teamName}
            label="Team name"
            required
            inputProps={{
              'data-cy': 'company_teams__add_team__team_name__input',
            }}
          />
          <CustomAutocomplete
            field={teamPermissionRole}
            options={rolesList}
            label="Role"
            required
            inputProps={{
              'data-cy': 'company_teams__add_team_permission_role_input',
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 4 }}
          spacing={2}
        >
          <Button variant="text" onClick={onClose} data-cy="company_teams__add_team_cancel_button">
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            onClick={() => handleAddTeam()}
            data-cy="company_teams__add_team_confirm_button"
          >
            Add
          </LoadingButton>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default AddTeamPopup;
