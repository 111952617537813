import React from 'react';
import { Box, Divider, Skeleton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { DotIcon, EmailIcon, PhoneIcon, SiteIcon } from '@svgAsComponents';

import * as Controller from './controller';
import { HookState, ITeam } from '@interfaces';
import { getUserFullName, phoneFormatter } from '@utils';
import { InfoCard, LabelAndValue, StyledLink, UserStatusChip } from '@components';
import { colors } from '@theme';
import { useDateFormatter } from '@hooks';

const UserInfoModal = ({ open, handleClose, companyId, userId }) => {
  const { state, user, projectsQueryState, projects, showEllipsis, companyUrl } =
    Controller.useUserInfoModal(companyId, userId);
  const mdMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { dateFormatter } = useDateFormatter();
  return (
    <InfoCard open={open} handleClose={handleClose} title="User information">
      {state === HookState.FETCHING && (
        <Stack spacing={2} direction="row">
          <Skeleton width={200} height={36} />
          <Skeleton width={200} height={36} />
        </Stack>
      )}
      {state === HookState.SUCCESS && (
        <Stack spacing={1} sx={{ flex: 1, flexDirection: 'column' }}>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="h1" sx={{ mr: 2 }}>
                {getUserFullName(user)}
              </Typography>
              {user?.status_display && <UserStatusChip user={user} />}
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexWrap: 'wrap',
              alignItems: { md: 'flex-end', sm: 'flex-start' },
              justifyContent: 'space-between',
            }}
            direction={mdMediaQuery ? 'row' : 'column'}
          >
            <Stack
              sx={{
                flexWrap: 'wrap',
                alignItems: { md: 'center', sm: 'flex-start' },
                justifyContent: 'flex-start',
              }}
              direction={mdMediaQuery ? 'row' : 'column'}
            >
              {user?.phone && (
                <Stack flexDirection="row" alignItems="center" justifyContent="flex-start" mt={1}>
                  <PhoneIcon />
                  <Typography variant="body1" sx={{ ml: 0.75 }}>
                    {phoneFormatter(user.phone)}
                  </Typography>
                  <Box sx={{ mr: 2, ml: 2 }}>
                    <DotIcon />
                  </Box>
                </Stack>
              )}
              {user?.email && (
                <Stack flexDirection="row" alignItems="center" justifyContent="flex-start" mt={1}>
                  <EmailIcon />
                  <Typography
                    variant="body1"
                    sx={{
                      ml: 0.75,
                      overflowWrap: 'anywhere',
                      whiteSpace: { sm: 'nowrap', xs: 'normal' },
                    }}
                  >
                    {user.email}
                  </Typography>
                  {companyUrl && (
                    <Box sx={{ mr: 2, ml: 2 }}>
                      <DotIcon />
                    </Box>
                  )}
                </Stack>
              )}
              {companyUrl && (
                <Stack flexDirection="row" alignItems="center" justifyContent="flex-start" mt={1}>
                  <SiteIcon />
                  <Typography variant="body1" sx={{ ml: 0.75 }}>
                    {companyUrl}
                  </Typography>
                </Stack>
              )}
            </Stack>
            {user?.last_online && (
              <LabelAndValue
                label="Last online: "
                text={dateFormatter({ date: user.last_online, withTime: true })}
                marginRightAfterLabel={false}
              />
            )}
          </Stack>
          <Stack sx={{ py: 5 }}>
            <Divider />
          </Stack>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
            <LabelAndValue
              size={'body1'}
              label="Company: "
              text={user.teams?.[0]?.company?.name}
              marginRightAfterLabel={false}
            />
            <Box sx={{ mr: 2, ml: 2 }}>
              <DotIcon />
            </Box>
            <LabelAndValue
              size={'body1'}
              label="Team: "
              text={user.teams?.map((x: ITeam) => x.name).join(', ')}
              marginRightAfterLabel={false}
            />
          </Stack>
          <Stack sx={{ py: 5 }}>
            <Divider />
          </Stack>
          <Typography variant="h3">Projects</Typography>
          {projectsQueryState === HookState.FETCHING && (
            <Stack spacing={2} direction="row">
              <Skeleton width={200} height={36} />
              <Skeleton width={200} height={36} />
            </Stack>
          )}
          {projectsQueryState === HookState.SUCCESS && (
            <Stack direction="row" sx={{ flexWrap: 'wrap', py: 2, lineHeight: 2 }}>
              {projects.map((project, index) => (
                <Stack key={project.id} direction="row">
                  <StyledLink
                    to={`/projects/${project.id}/overview/`}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {project.name}
                  </StyledLink>
                  {index < projects.length - 1 && (
                    <Box sx={{ mr: 2, ml: 2 }}>
                      <DotIcon />
                    </Box>
                  )}
                </Stack>
              ))}
              {showEllipsis && (
                <>
                  <Box sx={{ mr: 2, ml: 2 }}>
                    <DotIcon />
                  </Box>
                  <Typography variant="body2SemiBold" color={colors.text.link}>
                    &hellip;
                  </Typography>
                </>
              )}
            </Stack>
          )}
        </Stack>
      )}
    </InfoCard>
  );
};

export default UserInfoModal;
