import React from 'react';

const OnboardingChecklistImage = () => (
  <svg
    width="363"
    height="130"
    viewBox="0 0 363 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M186.807 198.976C181.069 199.818 175.262 200.098 169.469 199.811C155.5 199.108 141.763 195.061 130.023 187.452C124.818 184.078 119.853 179.932 117.158 174.364C113.502 166.802 114.781 157.229 120.003 150.642C121.907 148.267 124.25 146.275 126.905 144.776C127.299 144.554 127.701 144.341 128.107 144.137C132.531 141.921 137.501 141.014 142.426 141.525C150.8 142.437 158.529 147.532 162.994 154.651C166.971 160.999 168.357 168.489 169.174 175.996C169.328 177.41 169.46 178.829 169.588 180.239C168.877 184.325 167.758 188.329 166.248 192.194C166.171 192.382 166.108 192.576 166.06 192.773C166.915 194.622 167.729 196.488 168.502 198.371C169.228 198.764 170.041 198.969 170.867 198.968H186.773L186.807 198.976Z"
      fill="#E8E8E8"
    />
    <path
      d="M168.173 199.339C168.067 199.385 167.953 199.411 167.838 199.414C167.723 199.416 167.608 199.396 167.5 199.354C167.393 199.312 167.295 199.249 167.212 199.169C167.129 199.089 167.063 198.993 167.018 198.887C166.95 198.725 166.885 198.563 166.817 198.401C166.201 196.91 165.555 195.433 164.88 193.97C156.228 175.095 143.324 158.46 127.17 145.36C127.078 145.295 127.004 145.207 126.958 145.104C126.911 145.001 126.893 144.888 126.905 144.776C126.929 144.648 126.981 144.526 127.058 144.421C127.136 144.315 127.236 144.229 127.351 144.167C127.467 144.106 127.595 144.071 127.726 144.066C127.857 144.061 127.987 144.085 128.107 144.137C128.172 144.166 128.232 144.203 128.287 144.248C135.198 149.855 141.539 156.127 147.216 162.972C152.895 169.811 157.895 177.182 162.147 184.981C163.306 187.111 164.406 189.271 165.448 191.461C165.654 191.896 165.859 192.334 166.06 192.773C166.915 194.622 167.729 196.488 168.502 198.371C168.515 198.401 168.528 198.435 168.541 198.465C168.58 198.546 168.602 198.634 168.604 198.723C168.607 198.813 168.59 198.902 168.555 198.985C168.52 199.067 168.468 199.142 168.402 199.203C168.336 199.264 168.258 199.31 168.173 199.339Z"
      fill="white"
    />
    <path
      d="M93.6986 125.681C102.911 125.681 110.378 118.242 110.378 109.066C110.378 99.8891 102.911 92.4501 93.6986 92.4501C84.4867 92.4501 77.0189 99.8891 77.0189 109.066C77.0189 118.242 84.4867 125.681 93.6986 125.681Z"
      fill="#1F4739"
    />
    <path
      d="M92.0452 115.404C91.67 115.405 91.3046 115.284 91.0044 115.059L90.9857 115.046L87.0659 112.056C86.8842 111.917 86.7318 111.745 86.6172 111.547C86.5026 111.35 86.4281 111.132 86.398 110.906C86.368 110.681 86.3829 110.451 86.4419 110.231C86.5009 110.011 86.6029 109.804 86.7421 109.623C86.8812 109.442 87.0547 109.291 87.2528 109.177C87.4508 109.062 87.6695 108.988 87.8963 108.959C88.1231 108.929 88.3536 108.944 88.5746 109.003C88.7956 109.061 89.0027 109.163 89.1843 109.302L91.7232 111.241L97.7233 103.446C97.8624 103.266 98.0359 103.114 98.234 103C98.432 102.886 98.6506 102.812 98.8773 102.782C99.104 102.752 99.3345 102.767 99.5554 102.826C99.7763 102.885 99.9834 102.987 100.165 103.125L100.128 103.176L100.166 103.126C100.532 103.406 100.771 103.82 100.832 104.276C100.892 104.732 100.769 105.193 100.488 105.558L93.4307 114.727C93.2675 114.938 93.0576 115.109 92.8173 115.227C92.5771 115.344 92.3129 115.405 92.0452 115.404Z"
      fill="white"
    />
    <path
      d="M362.492 200H0.544753C0.40995 200 0.280805 199.946 0.185623 199.851C0.0904404 199.756 0.0369873 199.627 0.0369873 199.493C0.0369873 199.358 0.0904404 199.23 0.185623 199.135C0.280805 199.039 0.40995 198.986 0.544753 198.985H362.492C362.627 198.986 362.756 199.039 362.851 199.135C362.947 199.23 363 199.358 363 199.493C363 199.627 362.947 199.756 362.851 199.851C362.756 199.946 362.627 200 362.492 200Z"
      fill="#1F4739"
    />
    <path
      d="M253.013 87.3376C256.083 87.3376 258.573 84.858 258.573 81.7991C258.573 78.7403 256.083 76.2607 253.013 76.2607C249.942 76.2607 247.453 78.7403 247.453 81.7991C247.453 84.858 249.942 87.3376 253.013 87.3376Z"
      fill="#1F4739"
    />
    <path
      d="M252.461 83.912C252.336 83.9122 252.215 83.8719 252.115 83.7972L252.108 83.7925L250.802 82.796C250.741 82.7499 250.69 82.6923 250.652 82.6265C250.613 82.5607 250.588 82.4881 250.578 82.4127C250.568 82.3373 250.573 82.2607 250.593 82.1872C250.612 82.1137 250.646 82.0448 250.693 81.9844C250.739 81.924 250.797 81.8733 250.863 81.8353C250.929 81.7973 251.002 81.7726 251.078 81.7627C251.154 81.7528 251.231 81.7579 251.305 81.7777C251.378 81.7975 251.447 81.8315 251.508 81.8779L252.354 82.5244L254.354 79.9261C254.401 79.8659 254.458 79.8153 254.524 79.7773C254.59 79.7393 254.663 79.7146 254.739 79.7047C254.814 79.6948 254.891 79.6998 254.965 79.7194C255.038 79.739 255.108 79.7729 255.168 79.8191C255.29 79.9125 255.37 80.0506 255.39 80.2026C255.411 80.3546 255.369 80.5083 255.276 80.6301L252.923 83.6863C252.869 83.7567 252.799 83.8137 252.719 83.8528C252.639 83.892 252.551 83.9122 252.461 83.912Z"
      fill="white"
    />
    <path
      d="M253.013 103.527C256.083 103.527 258.573 101.047 258.573 97.9885C258.573 94.9297 256.083 92.4501 253.013 92.4501C249.942 92.4501 247.453 94.9297 247.453 97.9885C247.453 101.047 249.942 103.527 253.013 103.527Z"
      fill="#1F4739"
    />
    <path
      d="M252.461 100.101C252.336 100.102 252.215 100.061 252.115 99.9866L252.108 99.9819L250.802 98.9854C250.741 98.9393 250.69 98.8817 250.652 98.8159C250.613 98.7501 250.588 98.6775 250.578 98.6021C250.568 98.5267 250.573 98.4501 250.593 98.3766C250.612 98.3031 250.646 98.2342 250.693 98.1738C250.739 98.1134 250.797 98.0627 250.863 98.0247C250.929 97.9867 251.002 97.962 251.078 97.9521C251.154 97.9422 251.231 97.9473 251.305 97.9671C251.378 97.9869 251.447 98.0209 251.508 98.0673L252.354 98.7138L254.354 96.1155C254.401 96.0553 254.458 96.0047 254.524 95.9667C254.59 95.9287 254.663 95.904 254.739 95.8941C254.814 95.8842 254.891 95.8892 254.965 95.9088C255.038 95.9284 255.108 95.9623 255.168 96.0085C255.29 96.1019 255.37 96.24 255.39 96.392C255.411 96.544 255.369 96.6977 255.276 96.8195L252.923 99.8757C252.869 99.9461 252.799 100.003 252.719 100.042C252.639 100.081 252.551 100.102 252.461 100.101Z"
      fill="white"
    />
    <path
      d="M256.006 122.273C259.077 122.273 261.566 119.793 261.566 116.734C261.566 113.675 259.077 111.196 256.006 111.196C252.936 111.196 250.447 113.675 250.447 116.734C250.447 119.793 252.936 122.273 256.006 122.273Z"
      fill="#1F4739"
    />
    <path
      d="M255.455 118.847C255.33 118.847 255.209 118.807 255.108 118.732L255.102 118.728L253.796 117.731C253.735 117.685 253.684 117.627 253.646 117.562C253.607 117.496 253.582 117.423 253.572 117.348C253.562 117.272 253.567 117.196 253.587 117.122C253.606 117.049 253.64 116.98 253.687 116.919C253.733 116.859 253.791 116.808 253.857 116.77C253.923 116.732 253.996 116.708 254.072 116.698C254.148 116.688 254.225 116.693 254.298 116.713C254.372 116.732 254.441 116.767 254.502 116.813L255.348 117.459L257.348 114.861C257.394 114.801 257.452 114.75 257.518 114.712C257.584 114.674 257.657 114.65 257.733 114.64C257.808 114.63 257.885 114.635 257.959 114.654C258.032 114.674 258.101 114.708 258.162 114.754C258.284 114.848 258.364 114.986 258.384 115.138C258.404 115.29 258.363 115.443 258.27 115.565L255.917 118.621C255.863 118.692 255.793 118.749 255.713 118.788C255.633 118.827 255.545 118.847 255.455 118.847Z"
      fill="white"
    />
    <path
      d="M309.619 162.976C309.562 162.976 309.525 162.975 309.513 162.974L302.61 162.975V161.961H309.538C309.698 161.966 312.971 162.058 315.986 158.414C320.479 152.982 324.169 139.536 316.231 106.325C305.557 61.6675 314.459 22.2151 318.782 7.32584C318.995 6.58921 319.033 5.81333 318.894 5.0595C318.754 4.30567 318.44 3.59456 317.977 2.98235C317.514 2.37013 316.914 1.87358 316.225 1.53194C315.536 1.19031 314.777 1.01294 314.007 1.01385H210.921C210.137 1.0149 209.364 1.20001 208.666 1.55416C207.967 1.90832 207.363 2.42154 206.901 3.05217C205.069 5.56153 202.556 10.3822 200.984 19.0989L199.982 18.9197C201.589 10.0137 204.183 5.05114 206.077 2.45614C206.634 1.69626 207.363 1.07784 208.204 0.651085C209.046 0.224328 209.976 0.00126218 210.921 2.75928e-06H314.007C314.935 -0.00088624 315.85 0.213053 316.68 0.624919C317.51 1.03679 318.232 1.63529 318.79 2.37316C319.348 3.11102 319.726 3.96802 319.895 4.87646C320.063 5.78489 320.017 6.71987 319.76 7.60752C315.459 22.4196 306.603 61.6673 317.221 106.09C325.271 139.769 321.401 153.506 316.737 159.1C313.661 162.789 310.272 162.976 309.619 162.976Z"
      fill="#D2D2D2"
    />
    <path
      d="M291.045 181.479C290.988 181.479 290.951 181.477 290.939 181.476L284.036 181.477V180.463H290.964C291.125 180.468 294.396 180.561 297.412 176.917C301.905 171.485 305.595 158.039 297.657 124.828C286.983 80.1701 295.885 40.7177 300.208 25.8284C300.421 25.0918 300.459 24.3159 300.319 23.5621C300.18 22.8082 299.866 22.0971 299.403 21.4849C298.939 20.8727 298.34 20.3762 297.651 20.0345C296.962 19.6929 296.203 19.5155 295.433 19.5164H192.346C191.563 19.5175 190.79 19.7026 190.092 20.0567C189.393 20.4109 188.788 20.9241 188.326 21.5547C186.495 24.0641 183.982 28.8848 182.409 37.6014L181.408 37.4222C183.014 28.5162 185.609 23.5537 187.503 20.9587C188.06 20.1988 188.788 19.5804 189.63 19.1536C190.471 18.7269 191.402 18.5038 192.346 18.5026H295.433C296.361 18.5017 297.275 18.7156 298.105 19.1275C298.936 19.5394 299.658 20.1379 300.216 20.8757C300.774 21.6136 301.152 22.4706 301.32 23.379C301.489 24.2875 301.443 25.2224 301.186 26.1101C296.885 40.9222 288.029 80.1698 298.647 124.593C306.696 158.271 302.827 172.009 298.163 177.602C295.087 181.292 291.697 181.479 291.045 181.479Z"
      fill="#D2D2D2"
    />
    <path
      d="M280.072 143.097C269.453 98.6745 278.31 59.4237 282.613 44.6146C282.869 43.7269 282.915 42.7921 282.746 41.8838C282.577 40.9756 282.199 40.1188 281.642 39.3809C281.084 38.6431 280.362 38.0445 279.532 37.6323C278.702 37.2201 277.787 37.0055 276.86 37.0056H173.771C172.827 37.0065 171.897 37.2292 171.056 37.6556C170.215 38.082 169.486 38.7001 168.93 39.4596C164.768 45.1599 156.133 64.0632 165.778 116.368C170.936 144.35 170.859 163.428 169.174 175.996C168.228 183.072 166.77 188.083 165.448 191.461L165.307 191.819C165.029 192.503 164.884 193.233 164.88 193.97C164.87 194.803 165.038 195.629 165.371 196.393C165.705 197.156 166.198 197.841 166.817 198.401C167.221 198.778 167.677 199.094 168.173 199.339C168.584 199.548 169.019 199.706 169.469 199.811C169.926 199.923 170.396 199.979 170.867 199.978H272.365C272.378 199.978 272.412 199.982 272.472 199.982C273.122 199.982 276.514 199.794 279.589 196.105C284.251 190.511 288.121 176.776 280.072 143.097ZM278.836 195.419C275.821 199.066 272.549 198.972 272.391 198.968H170.867C170.054 198.968 169.254 198.769 168.536 198.39C167.818 198.01 167.204 197.461 166.748 196.791C166.347 196.211 166.078 195.552 165.959 194.858C165.84 194.164 165.875 193.453 166.06 192.773C166.108 192.576 166.171 192.382 166.248 192.194C167.758 188.33 168.877 184.325 169.588 180.239C172.001 167.139 172.45 146.923 166.779 116.185C157.207 64.2677 165.671 45.6499 169.751 40.056C170.214 39.4263 170.819 38.9139 171.517 38.5601C172.216 38.2064 172.988 38.0212 173.771 38.0196H276.86C277.629 38.019 278.388 38.1966 279.077 38.5382C279.765 38.8798 280.364 39.3761 280.827 39.988C281.29 40.5999 281.604 41.3106 281.744 42.064C281.884 42.8174 281.846 43.5928 281.633 44.3292C277.309 59.2192 268.409 98.6745 279.084 143.332C287.022 176.541 283.331 189.987 278.836 195.419Z"
      fill="#1F4739"
    />
    <path
      d="M231.098 85.378H177.985C176.906 85.378 175.87 84.9507 175.107 84.1902C174.343 83.4297 173.914 82.3982 173.914 81.3226C173.914 80.2471 174.343 79.2156 175.107 78.4551C175.87 77.6945 176.906 77.2673 177.985 77.2673H231.098C232.178 77.2673 233.213 77.6945 233.976 78.4551C234.74 79.2156 235.169 80.2471 235.169 81.3226C235.169 82.3982 234.74 83.4297 233.976 84.1902C233.213 84.9507 232.178 85.378 231.098 85.378Z"
      fill="#D2D2D2"
    />
    <path
      d="M231.098 102.846H177.985C176.906 102.846 175.87 102.418 175.107 101.658C174.343 100.897 173.914 99.8657 173.914 98.7902C173.914 97.7146 174.343 96.6831 175.107 95.9226C175.87 95.1621 176.906 94.7348 177.985 94.7348H231.098C232.178 94.7348 233.213 95.1621 233.976 95.9226C234.74 96.6831 235.169 97.7146 235.169 98.7902C235.169 99.8657 234.74 100.897 233.976 101.658C233.213 102.418 232.178 102.846 231.098 102.846Z"
      fill="#D2D2D2"
    />
    <path
      d="M235.375 120.739H182.262C181.182 120.739 180.147 120.312 179.383 119.551C178.62 118.791 178.191 117.759 178.191 116.684C178.191 115.608 178.62 114.577 179.383 113.816C180.147 113.056 181.182 112.628 182.262 112.628H235.375C236.454 112.628 237.49 113.056 238.253 113.816C239.017 114.577 239.446 115.608 239.446 116.684C239.446 117.759 239.017 118.791 238.253 119.551C237.49 120.312 236.454 120.739 235.375 120.739Z"
      fill="#D2D2D2"
    />
    <path
      d="M238.368 139.485H185.256C184.176 139.485 183.141 139.057 182.377 138.297C181.614 137.536 181.185 136.505 181.185 135.429C181.185 134.354 181.614 133.322 182.377 132.562C183.141 131.801 184.176 131.374 185.256 131.374H238.368C239.448 131.374 240.484 131.801 241.247 132.562C242.011 133.322 242.44 134.354 242.44 135.429C242.44 136.505 242.011 137.536 241.247 138.297C240.484 139.057 239.448 139.485 238.368 139.485Z"
      fill="#D2D2D2"
    />
    <path
      d="M238.391 53.8897H200.225C199.69 53.8902 199.16 53.7858 198.666 53.5822C198.171 53.3786 197.722 53.08 197.344 52.7034C196.965 52.3268 196.665 51.8795 196.46 51.3872C196.255 50.8949 196.15 50.3672 196.15 49.8343C196.15 49.3014 196.255 48.7738 196.46 48.2815C196.665 47.7892 196.965 47.3419 197.344 46.9653C197.722 46.5887 198.171 46.29 198.666 46.0865C199.16 45.8829 199.69 45.7784 200.225 45.779H238.391C239.471 45.779 240.506 46.2062 241.27 46.9668C242.033 47.7273 242.462 48.7588 242.462 49.8343C242.462 50.9099 242.033 51.9414 241.27 52.7019C240.506 53.4624 239.471 53.8897 238.391 53.8897Z"
      fill="#1F4739"
    />
    <path
      d="M259.428 140.968C262.499 140.968 264.988 138.488 264.988 135.429C264.988 132.371 262.499 129.891 259.428 129.891C256.357 129.891 253.868 132.371 253.868 135.429C253.868 138.488 256.357 140.968 259.428 140.968Z"
      fill="#D2D2D2"
    />
    <path
      d="M240.935 157.804H187.822C186.742 157.804 185.707 157.377 184.943 156.616C184.18 155.856 183.751 154.824 183.751 153.749C183.751 152.673 184.18 151.642 184.943 150.881C185.707 150.121 186.742 149.694 187.822 149.694H240.935C242.014 149.694 243.05 150.121 243.813 150.881C244.577 151.642 245.006 152.673 245.006 153.749C245.006 154.824 244.577 155.856 243.813 156.616C243.05 157.377 242.014 157.804 240.935 157.804Z"
      fill="#D2D2D2"
    />
    <path
      d="M261.994 159.287C265.065 159.287 267.554 156.808 267.554 153.749C267.554 150.69 265.065 148.21 261.994 148.21C258.923 148.21 256.434 150.69 256.434 153.749C256.434 156.808 258.923 159.287 261.994 159.287Z"
      fill="#D2D2D2"
    />
    <path
      d="M82.6903 101.136C82 100.9 81.373 100.523 80.8534 100.033C80.3338 99.5427 79.9342 98.9509 79.683 98.2992C79.4317 97.6474 79.3347 96.9516 79.3989 96.2606C79.4631 95.5696 79.6869 94.9003 80.0545 94.2998L68.3873 81.5397L77.5849 80.4088L87.2542 92.6994C88.281 93.3497 89.0197 94.3332 89.3302 95.4636C89.6407 96.594 89.5015 97.7927 88.939 98.8326C88.3764 99.8726 87.4296 100.681 86.2779 101.106C85.1263 101.53 83.8497 101.541 82.6903 101.136V101.136Z"
      fill="#FAC9AD"
    />
    <path
      d="M18.7249 104.608C18.2503 104.048 17.9002 103.387 17.6992 102.672C17.4982 101.956 17.4512 101.204 17.5615 100.468C17.6717 99.7316 17.9367 99.0294 18.3376 98.4106C18.7384 97.7919 19.2656 97.2716 19.8818 96.8864L16.2459 79.1722L24.7114 82.6075L26.8662 98.9026C27.4227 100.017 27.565 101.304 27.2661 102.519C26.9672 103.735 26.2478 104.795 25.2443 105.498C24.2408 106.201 23.0228 106.499 21.8213 106.335C20.6197 106.171 19.518 105.557 18.7249 104.608V104.608Z"
      fill="#FAC9AD"
    />
    <path
      d="M59.698 194.183L65.5863 194.183L68.3873 170.686L59.6971 170.687L59.698 194.183Z"
      fill="#FAC9AD"
    />
    <path
      d="M58.456 199.13L77.0775 199.129V198.896C77.0776 197.952 76.8901 197.017 76.5259 196.146C76.1616 195.274 75.6277 194.482 74.9547 193.814C74.2816 193.147 73.4826 192.618 72.6032 192.257C71.7238 191.896 70.7813 191.71 69.8295 191.71H69.829L58.4556 191.71L58.456 199.13Z"
      fill="#262626"
    />
    <path
      d="M29.9029 194.183L35.7912 194.183L38.5922 170.686L29.902 170.687L29.9029 194.183Z"
      fill="#FAC9AD"
    />
    <path
      d="M28.6609 199.13L47.2825 199.129V198.896C47.2825 197.952 47.095 197.017 46.7308 196.146C46.3665 195.274 45.8326 194.482 45.1596 193.814C44.4865 193.147 43.6875 192.618 42.8081 192.257C41.9287 191.896 40.9862 191.71 40.0344 191.71H40.034L28.6606 191.71L28.6609 199.13Z"
      fill="#262626"
    />
    <path
      d="M58.9041 181.815L67.4914 181.473C68.0771 181.448 68.6295 181.195 69.0292 180.769C69.4289 180.342 69.644 179.777 69.6279 179.194L67.2086 100.314L67.0506 100.257C52.0003 94.8452 39.5499 95.2544 28.9869 101.506L28.8688 101.576L27.4195 178.403C27.4138 178.697 27.4673 178.989 27.5767 179.263C27.686 179.536 27.8491 179.785 28.0564 179.995C28.2637 180.205 28.511 180.372 28.7839 180.485C29.0568 180.599 29.3498 180.658 29.6457 180.658H36.8868C37.4025 180.657 37.9021 180.478 38.3004 180.153C38.6988 179.827 38.9713 179.374 39.0717 178.872L48.9358 128.994C48.9698 128.822 49.0646 128.667 49.2031 128.558C49.3417 128.449 49.5148 128.393 49.6914 128.399C49.868 128.406 50.0365 128.475 50.1665 128.594C50.2966 128.713 50.3796 128.874 50.4008 129.048L56.6047 179.87C56.673 180.406 56.935 180.899 57.3418 181.257C57.7487 181.615 58.2727 181.814 58.8161 181.816C58.8453 181.816 58.8747 181.816 58.9041 181.815Z"
      fill="#262626"
    />
    <path
      d="M51.0068 30.9417C44.1504 30.9417 38.5922 25.6818 38.5922 19.1933C38.5922 12.7048 44.1504 7.44489 51.0068 7.44489C57.8632 7.44489 63.4214 12.7048 63.4214 19.1933C63.4214 25.6818 57.8632 30.9417 51.0068 30.9417Z"
      fill="#FAC9AD"
    />
    <path
      d="M79.5151 91.3865C79.9382 91.2233 80.3003 90.9378 80.5532 90.5682L82.9098 87.1209C83.1641 86.747 83.2948 86.3059 83.2842 85.8571C83.2736 85.4083 83.1223 84.9735 82.8508 84.6115L73.3504 72.0058L67.3985 44.088C67.0561 43.226 66.5433 42.4385 65.8894 41.7704C65.2355 41.1024 64.4533 40.5668 63.5875 40.1944C62.7217 39.822 61.7894 39.62 60.8438 39.6001C59.8981 39.5801 58.9577 39.7425 58.0763 40.078C57.1949 40.4135 56.3898 40.9156 55.707 41.5554C55.0242 42.1952 54.477 42.9604 54.0968 43.807C53.7167 44.6537 53.5109 45.5653 53.4913 46.4898C53.4717 47.4142 53.6387 48.3334 53.9826 49.1947L56.7956 66.9624C57.6486 72.3551 60.4762 77.2614 64.7526 80.7686L77.2588 91.0279C77.4994 91.2251 77.7797 91.3705 78.0815 91.4548C78.3833 91.539 78.7 91.5603 79.0107 91.5171C79.1835 91.4932 79.3528 91.4493 79.5151 91.3865V91.3865Z"
      fill="#5E8376"
    />
    <path
      d="M18.9575 92.6454C19.3635 92.7969 19.8044 92.8166 20.2215 92.702L24.1102 91.6313C24.531 91.5143 24.9069 91.2664 25.1872 90.921C25.4674 90.5756 25.6385 90.1495 25.6772 89.7002L26.9946 74.0259L40.6574 49.1051C40.9665 48.2326 41.1065 47.3056 41.0693 46.3772C41.0322 45.4487 40.8187 44.5369 40.441 43.6939C40.0633 42.8509 39.5288 42.0932 38.8681 41.4643C38.2074 40.8353 37.4335 40.3473 36.5906 40.0283C35.7476 39.7092 34.8522 39.5653 33.9555 39.6048C33.0589 39.6443 32.1785 39.8665 31.3648 40.2585C30.5511 40.6505 29.82 41.2048 29.2133 41.8896C28.6066 42.5744 28.1362 43.3763 27.829 44.2495L18.4394 59.477C15.5889 64.0982 14.4566 69.6341 15.2531 75.0551L17.5807 90.9105C17.6256 91.2153 17.7319 91.507 17.8929 91.7664C18.0538 92.0259 18.2656 92.2474 18.5146 92.4166C18.653 92.5107 18.8017 92.5875 18.9575 92.6454V92.6454Z"
      fill="#5E8376"
    />
    <path
      d="M64.0366 40.1794C61.9167 37.8856 59.3019 36.0906 56.3851 34.927C53.4683 33.7633 50.3239 33.2607 47.1839 33.4562C46.9776 33.4688 46.772 33.483 46.5673 33.4988H46.5671C43.5911 33.731 40.6996 34.5874 38.086 36.0108C35.4723 37.4342 33.1969 39.3918 31.4116 41.7526C29.5965 44.1465 28.3203 46.8951 27.6682 49.8147C27.0161 52.7343 27.0031 55.7576 27.6302 58.6825C30.0233 69.8605 29.4177 81.4613 25.8732 92.3374L22.5622 102.482C22.456 102.807 22.4269 103.151 22.4771 103.489C22.5273 103.827 22.6555 104.149 22.8517 104.431C23.048 104.712 23.307 104.945 23.609 105.112C23.911 105.279 24.2477 105.375 24.5934 105.393L41.5204 106.291L45.8521 98.9084V106.521L66.4253 107.612C66.4663 107.615 66.507 107.616 66.5475 107.616C66.8597 107.616 67.1685 107.552 67.4544 107.428C67.7403 107.304 67.9972 107.124 68.2089 106.897C68.4206 106.671 68.5824 106.404 68.6843 106.112C68.7861 105.821 68.8258 105.512 68.8007 105.205L66.4754 76.7537L69.392 57.4299C69.8641 54.3423 69.6281 51.1897 68.7012 48.2041C67.7743 45.2186 66.1803 42.4763 64.0366 40.1794V40.1794Z"
      fill="#5E8376"
    />
    <path
      d="M42.2645 30.3171C43.4064 30.9624 47.9198 31.3902 48.2414 30.1355L48.2483 30.1079C48.4721 29.1885 48.2862 28.2285 48.222 27.2854C48.1577 26.3422 48.2517 25.3061 48.9058 24.6127C50.1249 23.32 52.294 24.0052 54.0569 23.6784C55.0895 23.452 56.0028 22.8638 56.6257 22.0239C57.2487 21.1839 57.5387 20.1498 57.4414 19.115C57.4163 18.919 57.3769 18.7228 57.3327 18.5266C57.2225 18.0297 57.231 17.5144 57.3576 17.0212C57.4843 16.528 57.7255 16.0704 58.0626 15.6842C58.3996 15.298 58.8231 14.9938 59.3 14.7955C59.7769 14.5972 60.294 14.5101 60.8109 14.5411C62.5926 14.6411 64.5157 15.1713 65.9898 13.8752C67.1297 12.873 67.4078 10.264 66.907 8.84416C66.0653 6.45801 60.6736 5.20881 58.3195 4.5221C55.4079 3.69587 52.3456 3.51725 49.3549 3.99921C46.3643 4.48117 43.5206 5.6116 41.0306 7.30833C39.5437 8.33772 38.1551 9.63113 37.5213 11.3059C36.8876 12.9807 37.1933 15.0937 38.6224 16.1993C37.5146 17.3512 36.7346 18.7688 36.3604 20.31C35.9862 21.8513 36.0309 23.4621 36.4902 24.981C37.196 27.2149 40.5648 29.3565 42.2645 30.3171Z"
      fill="#262626"
    />
  </svg>
);

export default OnboardingChecklistImage;
