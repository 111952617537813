import { useQuery } from 'react-query';
import { HookState, ICompanyFull, QueryNamesEnums } from '@interfaces';
import { getHookState } from '@utils';
import { getMyCompany } from '@globalService';

export type ControllerInterface = {
  state: HookState;
  data: ICompanyFull;
};

export const useCompanySecurity = (): ControllerInterface => {
  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  return {
    state: getHookState(companyQuery),
    data: companyQuery.data,
  };
};
