import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  CustomAutocomplete,
  CustomMultiAutocompleteWithTags,
  CustomTextField,
  Popup,
} from '@components';
import { EditIcon } from '@svgAsComponents';
import { DocumentEditModalProps } from './interface';
import { useDocumentEditModal } from './controller';

const DocumentEditModal: FC<DocumentEditModalProps> = ({
  document,
  closeDocumentEditModal,
  deleteDocument,
  prId,
}) => {
  const {
    documentName,
    documentType,
    lineItemOptions,
    documentLineItems,
    setDocumentLineItems,
    handleSubmitClick,
    handleDeleteClick,
    isSubmitting,
    documentTypes,
    optionsLoading,
    canBeDeleted,
  } = useDocumentEditModal({ document, closeDocumentEditModal, deleteDocument, prId });

  return (
    <Popup open={Boolean(document)} title="Edit document" icon={EditIcon}>
      <Stack sx={{ width: '100%' }}>
        <Stack sx={{ mt: 4 }} spacing={4}>
          <CustomTextField
            field={documentName}
            label="Document name"
            required
            inputProps={{
              'data-cy': 'edit_document_modal__name_input',
            }}
          />
          <CustomAutocomplete
            field={documentType}
            options={documentTypes || []}
            label="Type"
            required
            inputProps={{
              'data-cy': 'edit_document_modal__document_type_select',
            }}
          />
          {!document?.inspection_id && (
            <CustomMultiAutocompleteWithTags
              field={documentLineItems}
              options={lineItemOptions}
              setField={setDocumentLineItems}
              optionsLoading={optionsLoading}
              label="Line item(s)"
              inputProps={{
                'data-cy': 'edit_document_modal__lineitem_select',
              }}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 7.5 }}
          spacing={1}
        >
          <Button variant="text" onClick={closeDocumentEditModal}>
            Cancel
          </Button>
          {canBeDeleted && (
            <Button
              variant="new"
              color="error"
              onClick={handleDeleteClick}
              data-cy="delete_line_item_modal_button"
            >
              Delete
            </Button>
          )}
          <LoadingButton
            onClick={handleSubmitClick}
            loading={isSubmitting}
            data-cy="edit_document_modal_button"
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default DocumentEditModal;
