import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.main.primary.main }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7328 4.6215C20.9041 4.23889 20.8237 3.78854 20.5309 3.49136C20.2382 3.19418 19.7945 3.11251 19.4177 3.28642L15.7243 4.99067L13.0692 2.29533C12.7852 2.00698 12.358 1.92072 11.9869 2.07678C11.6158 2.23283 11.3738 2.60047 11.3738 3.00826V7.19537L7.8313 9.16748C7.47945 9.36336 7.27828 9.75495 7.32144 10.16C7.36461 10.565 7.64363 10.9038 8.02861 11.0188L11.0178 11.9113L2.31124 20.2588C1.91246 20.6411 1.8945 21.2792 2.27113 21.684C2.64775 22.0889 3.27634 22.1071 3.67511 21.7248L12.3201 13.4363L13.1159 16.1832C13.2292 16.5741 13.5629 16.8573 13.9619 16.9011C14.3609 16.9449 14.7466 16.7407 14.9396 16.3835L16.8822 12.7873H21.0068C21.4085 12.7873 21.7706 12.5417 21.9244 12.1649C22.0781 11.7882 21.9931 11.3545 21.7091 11.0662L19.054 8.37084L20.7328 4.6215ZM15.9197 7.11552L17.8285 6.23471L16.9609 8.1725C16.7896 8.5551 16.87 9.00546 17.1628 9.30264L18.6091 10.7709H16.2942C15.9315 10.7709 15.5977 10.9715 15.4233 11.2943L14.3472 13.2865L13.7125 11.0959C13.6172 10.7671 13.3639 10.5099 13.0399 10.4132L10.8821 9.76888L12.8445 8.67639C13.1625 8.49937 13.3601 8.1605 13.3601 7.79236L13.3601 5.44235L14.8064 6.91059C15.0992 7.20777 15.5428 7.28943 15.9197 7.11552Z"
      fill={color}
    />
  </svg>
);

export default Icon;
