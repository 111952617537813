import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 16, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66671 2.66602C1.93033 2.66602 1.33337 3.26297 1.33337 3.99935C1.33337 4.73573 1.93033 5.33268 2.66671 5.33268C3.40309 5.33268 4.00004 4.73573 4.00004 3.99935C4.00004 3.26297 3.40309 2.66602 2.66671 2.66602ZM6.00004 3.33268C5.63185 3.33268 5.33337 3.63116 5.33337 3.99935C5.33337 4.36754 5.63185 4.66602 6.00004 4.66602H14C14.3682 4.66602 14.6667 4.36754 14.6667 3.99935C14.6667 3.63116 14.3682 3.33268 14 3.33268H6.00004ZM5.33337 7.99935C5.33337 7.63116 5.63185 7.33268 6.00004 7.33268H14C14.3682 7.33268 14.6667 7.63116 14.6667 7.99935C14.6667 8.36754 14.3682 8.66602 14 8.66602H6.00004C5.63185 8.66602 5.33337 8.36754 5.33337 7.99935ZM2.66671 6.66602C1.93033 6.66602 1.33337 7.26297 1.33337 7.99935C1.33337 8.73573 1.93033 9.33268 2.66671 9.33268C3.40309 9.33268 4.00004 8.73573 4.00004 7.99935C4.00004 7.26297 3.40309 6.66602 2.66671 6.66602ZM5.33337 11.9993C5.33337 11.6312 5.63185 11.3327 6.00004 11.3327H14C14.3682 11.3327 14.6667 11.6312 14.6667 11.9993C14.6667 12.3675 14.3682 12.666 14 12.666H6.00004C5.63185 12.666 5.33337 12.3675 5.33337 11.9993ZM2.66671 10.666C1.93033 10.666 1.33337 11.263 1.33337 11.9993C1.33337 12.7357 1.93033 13.3327 2.66671 13.3327C3.40309 13.3327 4.00004 12.7357 4.00004 11.9993C4.00004 11.263 3.40309 10.666 2.66671 10.666Z"
      fill={color}
    />
  </svg>
);

export default Icon;
