import React, { FC, ReactElement } from 'react';
import { ToolTipLine } from '@components';
import { ToolTipLineVariantEnum } from '@interfaces';

const ProjectWarning: FC<{
  innerElement: ReactElement;
  variant?: ToolTipLineVariantEnum;
}> = ({ innerElement, variant = ToolTipLineVariantEnum.INFORMATION }) => {
  return (
    <ToolTipLine
      variant={variant}
      withBackground
      typographyVariant="body2"
      sx={{ minHeight: '56px' }}
    >
      {innerElement}
    </ToolTipLine>
  );
};

export default ProjectWarning;
