import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import {
  IDocument,
  IHandleRightDrawerOpenerParams,
  IRightMenu,
  ITablePagination,
  QueryNamesEnums,
} from '@interfaces';
import { getDrawRequestDocumentsList } from '@globalService';
import { useRightMenu, useTablePagination } from '@hooks';
import { defaultRowsPerPageOptions } from '@constants';

export interface ControllerInterface {
  drawRequestDocuments: IDocument[];
  docSummaryMenu: IRightMenu;
  docSummaryParams: { projectId: string };
  handleSmartSummaryOpenerClick: (params: IHandleRightDrawerOpenerParams) => void;
  setDocSummaryParams: Dispatch<SetStateAction<{ projectId: string; documentId?: string }>>;
  tablePagination: ITablePagination;
  documentsCount: number;
}

export const useSmartSummaryButton = ({ drawRequestId, projectId }): ControllerInterface => {
  const [docSummaryParams, setDocSummaryParams] = useState<{
    projectId: string;
  }>({
    projectId,
  });

  const tablePagination = useTablePagination({
    initialRowsPerPage: defaultRowsPerPageOptions.documents[0],
    rowsPerPageOptions: defaultRowsPerPageOptions.documents,
  });

  // TODO: Add pagination
  const drawRequestDocumentsQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS, { projectId, drawRequestId }],
    getDrawRequestDocumentsList.bind(this, {
      projectId,
      drawRequestId,
      stringQueryParams: 'limit=50',
    }),
    { enabled: Boolean(projectId && drawRequestId) },
  );

  const drawRequestDocuments = useMemo(
    () => drawRequestDocumentsQuery.data?.results || [],
    [drawRequestDocumentsQuery.data?.results],
  );

  const { handleRightDrawerOpenerClick: handleSmartSummaryOpenerClick, ...docSummaryMenu } =
    useRightMenu({});

  return {
    drawRequestDocuments,
    docSummaryMenu,
    docSummaryParams,
    handleSmartSummaryOpenerClick,
    setDocSummaryParams,
    tablePagination,
    documentsCount: drawRequestDocumentsQuery.data?.count || 0,
  };
};
