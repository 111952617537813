import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C7.23061 2 4.42702 5.55539 4.04498 9.15521C2.85635 9.55408 2 10.677 2 12V14C2 15.6569 3.34315 17 5 17C6.65685 17 8 15.6569 8 14V12C8 10.8173 7.31565 9.7945 6.32134 9.30591C6.58547 6.72416 8.33141 4 12 4C15.6686 4 17.4145 6.72416 17.6787 9.30592C16.6844 9.79451 16 10.8173 16 12V14C16 14.8885 16.3863 15.6868 17 16.2361V17.6204C17 18.9346 15.9346 20 14.6204 20H14C13.4477 20 13 20.4477 13 21C13 21.5523 13.4477 22 14 22H14.6204C17.0392 22 19 20.0392 19 17.6204V17C20.6569 17 22 15.6569 22 14V12C22 10.677 21.1436 9.55408 19.955 9.15521C19.573 5.55539 16.7694 2 12 2ZM6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12V14C4 14.5523 4.44772 15 5 15C5.55228 15 6 14.5523 6 14V12ZM19 11C19.5523 11 20 11.4477 20 12V14C20 14.5523 19.5523 15 19 15C18.4477 15 18 14.5523 18 14V12C18 11.4477 18.4477 11 19 11Z"
      fill={color}
    />
  </svg>
);

export default Icon;
