import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { EmailNotifications, Popup, StyledLink } from '@components';
import { PopupTypeEnum } from '@interfaces';
import { NotificationIcon } from '@svgAsComponents';

interface SetupNotificationsPopupProps {
  onClose: () => void;
}

const SetupNotificationsPopup: FC<SetupNotificationsPopupProps> = ({ onClose }) => {
  return (
    <Popup
      open
      type={PopupTypeEnum.GENERAL}
      icon={NotificationIcon}
      title="Setup notifications"
      text={
        <Stack>
          <Typography variant="body2">
            Please, select your email notification preferences.
          </Typography>
          <Typography variant="body2">
            You can modify them later in your
            <StyledLink onClick={onClose} to={`/profile/settings`}>
              {' Personal settings'}
            </StyledLink>
            .
          </Typography>
        </Stack>
      }
    >
      <Stack sx={{ width: '100%' }}>
        <EmailNotifications
          showTitle={false}
          saveDataCategoryClick={false}
          showSetupButtons
          onClose={onClose}
        />
      </Stack>
    </Popup>
  );
};

export default SetupNotificationsPopup;
