import React from 'react';
import { Stack } from '@mui/material';

import { LoadingSkeleton, ServiceMessage, StyledBox, TabSwitcher } from '@components';
import * as Controller from './controller';
import { HookState, PolicyListSourceEnums, PolicyTitleEnum } from '@interfaces';
import PolicyContainer from './PolicyContainer';
import { PoliciesTypeMap, TEAM_ROLES } from '@constants';

const PlatformPolicies = () => {
  const controller = Controller.usePlatformPolicies();
  const {
    switcherTabs,
    activeTab,
    handleTabChange,
    approvalPolicyTemplates,
    submissionPolicyTemplates,
    state,
    isProjectPolicies,
    editMode,
    handleCloseEdit,
    getLeavePageConfirmModal,
  } = controller;

  switch (state) {
    case HookState.FETCHING: {
      return (
        <>
          <StyledBox sx={{ mb: 2 }}>
            <LoadingSkeleton />
          </StyledBox>
          <LoadingSkeleton type="twoBlocks" />
        </>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="platform policies" />;
    }

    case HookState.EMPTY:
    case HookState.SUCCESS: {
      return (
        <>
          <StyledBox sx={{ mb: 2, p: 0 }}>
            <TabSwitcher
              tabs={switcherTabs}
              onTabChange={handleTabChange}
              activeTab={activeTab}
              source="platform_policies__tab_switcher"
            />
          </StyledBox>
          <Stack direction={{ lg: 'row', md: 'column' }} spacing={2} flex={1}>
            <PolicyContainer
              policyLists={submissionPolicyTemplates}
              title={isProjectPolicies ? PolicyTitleEnum.BORROWER : PolicyTitleEnum.SUBMISSION}
              assigneeRole={TEAM_ROLES.Owner}
              type={PoliciesTypeMap[activeTab]}
              source={PolicyListSourceEnums.SUBMISSION}
              editMode={editMode}
              handleCloseEdit={handleCloseEdit}
            />
            <PolicyContainer
              policyLists={approvalPolicyTemplates}
              title={isProjectPolicies ? PolicyTitleEnum.LENDER : PolicyTitleEnum.APPROVAL}
              assigneeRole={TEAM_ROLES.Lender}
              type={PoliciesTypeMap[activeTab]}
              source={PolicyListSourceEnums.APPROVAL}
              editMode={editMode}
              handleCloseEdit={handleCloseEdit}
            />
          </Stack>
          {getLeavePageConfirmModal()}
        </>
      );
    }

    default:
      return null;
  }
};

export default PlatformPolicies;
