export const borderRadius = '12px';
/* 10px = 0.625rem
  12px = 0.75rem
  14px = 0.875rem
  16px = 1rem (base)
  18px = 1.125rem
  20px = 1.25rem
  22px = 1.375rem
  24px = 1.5rem
  30px = 1.875rem
  32px = 2rem
  34px = 2.125rem
  40px = 2.5rem
  44px = 2.75rem */
export const fontSizes = {
  xxxLarge: '30px',
  xxLarge: '26px',
  xLarge: '22px',
  large: '18px',
  medium: '16px',
  small: '14px',
  xSmall: '12px',
  xxSmall: '10px',
};
export const headerHeight = '66px';
