import { Dispatch, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { HookState, ICompanyFull, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { checkIsOwner, getHookState, getTeamRole, isRestricted } from '@utils';
import { getMyCompany } from '@globalService';
import { AuthContext, PermissionsContext } from '@context';

export type ControllerInterface = {
  state: HookState;
  data: ICompanyFull;
  isOpenCompanyInfoPopup: boolean;
  setOpenCompanyInfoPopup: Dispatch<React.SetStateAction<boolean>>;
  isEditAllowed: boolean;
  showLicenses: boolean;
};

export const useCompanyInfo = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);

  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );
  const [isOpenCompanyInfoPopup, setOpenCompanyInfoPopup] = useState(false);

  return {
    state: getHookState(companyQuery),
    data: companyQuery.data,
    isOpenCompanyInfoPopup,
    setOpenCompanyInfoPopup,
    isEditAllowed: !isRestricted(PermissionNamesEnums.COMPANY_EDIT, permissions),
    showLicenses: !isOwner || !isRestricted(PermissionNamesEnums.COMPANY_EDIT, permissions),
  };
};
