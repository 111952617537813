import { useMemo } from 'react';
import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import { getHookState } from '@utils';
import { HookState, IProjectFunds, QueryNamesEnums } from '@interfaces';
import { getProjectFunds } from '@globalService';

export interface ControllerInterface {
  keys: string[];
  values: number[];
  total: number;
  state: HookState;
}

const requiredKeys = ['sources'] as const;
type ProjectSources = Pick<IProjectFunds, (typeof requiredKeys)[number]>;

const getSourcesFromQuery = (query: ProjectSources) => {
  const { items, total } = query?.sources || {};

  if (isEmpty(items)) {
    return {
      keys: [],
      values: [],
      total: 0,
    };
  }
  const filteredItems = items.filter((item) => item.name.key !== 'original_borrower_equity');
  const { keys, values } = filteredItems.reduce(
    (acc, { name, amount }) => ({
      keys: [...acc.keys, name.display],
      values: [...acc.values, amount],
    }),
    { keys: [], values: [] },
  );

  return {
    keys,
    values,
    total,
  };
};

export const useProjectSources = (projectId: string): ControllerInterface => {
  const projectSourcesQuery = useQuery<ProjectSources, Error>(
    [QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }],
    getProjectFunds.bind(this, projectId),
  );

  const sources = useMemo(
    () => getSourcesFromQuery(projectSourcesQuery.data),
    [projectSourcesQuery.data],
  );

  return {
    ...sources,
    state: getHookState(projectSourcesQuery),
  };
};
