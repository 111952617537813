import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.status.orange.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.88366 6.67573C8.25686 6.26861 8.22935 5.63604 7.82223 5.26285C7.41512 4.88966 6.78255 4.91716 6.40936 5.32428L4.43237 7.48099L3.91473 6.85982C3.56117 6.43554 2.9306 6.37822 2.50633 6.73178C2.08205 7.08535 2.02472 7.71591 2.37829 8.14019L3.62829 9.64019C3.813 9.86185 4.08446 9.99292 4.37291 9.99973C4.66136 10.0065 4.93869 9.88842 5.13366 9.67573L7.88366 6.67573ZM11.1465 7.00001C10.5942 7.00001 10.1465 7.44772 10.1465 8.00001C10.1465 8.55229 10.5942 9.00001 11.1465 9.00001H21.1465C21.6988 9.00001 22.1465 8.55229 22.1465 8.00001C22.1465 7.44772 21.6988 7.00001 21.1465 7.00001H11.1465ZM2.14651 16C2.14651 17.6569 3.48966 19 5.14651 19C6.80336 19 8.14651 17.6569 8.14651 16C8.14651 14.3432 6.80336 13 5.14651 13C3.48966 13 2.14651 14.3432 2.14651 16ZM5.14651 17C4.59422 17 4.14651 16.5523 4.14651 16C4.14651 15.4477 4.59422 15 5.14651 15C5.69879 15 6.14651 15.4477 6.14651 16C6.14651 16.5523 5.69879 17 5.14651 17ZM11.1465 15C10.5942 15 10.1465 15.4477 10.1465 16C10.1465 16.5523 10.5942 17 11.1465 17H21.1465C21.6988 17 22.1465 16.5523 22.1465 16C22.1465 15.4477 21.6988 15 21.1465 15H11.1465Z"
      fill={color}
    />
  </svg>
);

export default Icon;
