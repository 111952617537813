import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import WarningIllustration from 'encirclelabs-assets/warning_illustration.svg';

const AutoLogoutMessage: FC = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{ marginTop: { lg: 3 }, marginBottom: { xs: 3, lg: 5 } }}
  >
    <img
      src={WarningIllustration}
      style={{ maxWidth: '100%', width: '80px' }}
      alt="rectricted access"
    />

    <Typography variant="body2SemiBold" align="center" sx={{ mt: 2, mb: 0.5 }}>
      You've been logged out due to session expiration.
    </Typography>
    <Typography variant="body3">For security, please log in again to continue.</Typography>
  </Stack>
);

export default AutoLogoutMessage;
