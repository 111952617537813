import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  IDrawRequest,
  IFilterOption,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  IRightMenu,
  LineItemFilterEnum,
  TableKeyEnum,
} from '@interfaces';
import { useParams } from 'react-router-dom';
import {
  autofillCanBeShown,
  canDeleteRequest,
  checkIfHistoricalRequestEditable,
  checkIsCreator,
  checkIsCustomerSuccess,
  checkIsOwner,
  checkIsTableEdit,
  getActivePHBGrouping,
  getItemLocalHighlight,
  getPHBTableItemsByActiveView,
  getPHBViewTypes,
  getTeamRole,
  getTypeFilterValue,
  isCurrentPHBViewLoading,
  isLineItemView,
  isReallocationEnabled,
  isRequestInReview,
  setDefaultPHBView,
  useExpandCollapseTable,
  useLoadingSkeleton,
  usePHBFilters,
  usePHBGrouping,
  usePHBNaming,
} from '@utils';
import {
  useCommentsAndDocumentsPreview,
  useRightMenu,
  useUpdateUiSettings,
  useUrlParams,
} from '@hooks';
import { AuthContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import { getInitColumns } from './getColumns';
import { useGetQueries } from './queries';
import { LineItemFilterValues } from '@constants';

export type RequestTableControllerInterface = {
  initColumns: string[];
  tableItems: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  filterValue: string;
  handleShowFilterClick: (value: string) => void;
  patchMsGroup: (params) => void;
  isLoading: boolean;
  canDeleteRequest: boolean;
  showDeleteModal: boolean;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  deleteRequest: () => Promise<Response>;
  isDeleting: boolean;
  showAutofillButton: boolean;
  drawRequest: IDrawRequest;
  handleAutofillLenderAllowance: (autofillValue: string) => void;
  isAutofillLoading: boolean;
  totals?: IMilestoneTotal;
  groupByKeys: string;
  updateRightDrawer: () => () => void;
  rightMenu: IRightMenu;
  onMilestoneUpdate: (data: IMilestone) => void;
  activeView: string;
  setActiveView: (state: string) => void;
  isLineItemsView: boolean;
  viewTypes: { label: string; value: string }[];
  typeFilterValues: string[];
  setTypeFilterValues: Dispatch<SetStateAction<string[]>>;
};

export const useRequestTable = (isSubmissionProcess = false): RequestTableControllerInterface => {
  const { projectId, requestId: drawRequestId, action } = useParams();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isEditTable = useMemo(() => checkIsTableEdit(action), [action]);
  const flags = useLaunchDarklyFlags();
  const { settings } = useContext(SettingsContext);
  const { updateSettings } = useUpdateUiSettings();
  const [unitsTableItems, setUnitsTableItems] = useState<IPHBTableItem[]>([]);
  const [lineItemsTableItems, setLineItemsTableItems] = useState<IPHBTableItem[]>([]);
  const [modelsTableItems, setModelsTableItems] = useState<IPHBTableItem[]>([]);
  const [typeFilterValues, setTypeFilterValues] = React.useState<string[]>([
    'is_horizontal=true',
    'is_vertical=true',
  ]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { unitName, modelName } = usePHBNaming();

  const viewTypes = useMemo(
    () => getPHBViewTypes({ unitName, modelName, flags }),
    [unitName, modelName, flags],
  );
  const [activeView, setActiveView] = useUrlParams(
    settings.personal_setting?.PHB_TABLE_VIEW?.view_type || viewTypes[0].value,
    'view',
    (s) => s.toString(),
    (s) => s.toString(),
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  useEffect(() => {
    setDefaultPHBView({
      settings,
      isFirstLoad,
      setIsFirstLoad,
      viewTypes,
      activeView,
      setActiveView,
    });
  }, [settings.personal_setting?.PHB_TABLE_VIEW?.view_type, isFirstLoad, activeView]);
  const isLineItemsView = useMemo(() => isLineItemView(activeView), [activeView]);

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    drawRequestId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const updateRightDrawer = () => () => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
  };

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
    isModelLevel = false,
  }: {
    item: IMilestone;
    isExpanded?: boolean;
    isNested?: boolean;
    index: number | string;
    isModelLevel?: boolean;
  }): IPHBTableItem => ({
    ...item,
    activeToEdit,
    localNew: false,
    canAddPhotos: activeToEdit,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: !isModelLevel && item?.milestone_groups?.length > 0,
    localIsUserCreator: checkIsCreator(drawRequest, teamRole),
    isExpanded,
    isNested,
    index,
    project_milestone: { ...item?.project_milestone, index },
  });

  const unitsNestedTableProps = useExpandCollapseTable({
    setListItems: setUnitsTableItems,
    createTableObject,
  });

  const lineItemsNestedTableProps = useExpandCollapseTable({
    setListItems: setLineItemsTableItems,
    createTableObject,
  });

  const { filterValue, handleFilterClick, filterOptions, filterTotalKey, filterKey } =
    usePHBFilters({
      tableKey: TableKeyEnum.PHB_REQUEST_LINE_ITEMS,
    });

  const {
    requestedDataQueries,
    deleteDrawRequestMutation,
    unitsQuery,
    lineItemsQuery,
    drawRequestsQuery,
    bulkMilestoneMutation,
    onMilestoneUpdate,
    patchMSGroupMutation,
    modelsQuery,
  } = useGetQueries({
    filterTotalKey,
    filterKey,
    updateUnitsListItemsWithMsList: unitsNestedTableProps.updateListItemsWithMsList,
    updateLineItemsListItemsWithMsList: lineItemsNestedTableProps.updateListItemsWithMsList,
    isSubmissionProcess,
    updateUnitsListItemsWithParentGroup: unitsNestedTableProps.updateListItemsWithParentGroup,
    updateLineItemsListItemsWithParentGroup:
      lineItemsNestedTableProps.updateListItemsWithParentGroup,
    typeFilterValue: getTypeFilterValue(typeFilterValues),
    setUnitsTableItems,
    setLineItemsTableItems,
    setModelsTableItems,
    activeView,
  });
  const { unitLineItemGrouping, lineItemUnitGrouping, modelUnitsGrouping } = usePHBGrouping();
  const activeGroupBy = useMemo(
    () =>
      getActivePHBGrouping({
        activeView,
        unitLineItemGrouping,
        lineItemUnitGrouping,
        modelUnitsGrouping,
      }),
    [activeView, unitLineItemGrouping, lineItemUnitGrouping, modelUnitsGrouping],
  );

  const project = requestedDataQueries[0].data;
  const drawRequest = requestedDataQueries[1].data;
  const requestMilestones = unitsQuery.data;

  const retainageRate = useMemo(() => project?.retainage_rate, [project]);

  const isReallocationAllowed = useMemo(
    () => isReallocationEnabled(drawRequest, project),
    [drawRequest, project],
  );

  const isHistoricalRequestEditable = useMemo(
    () =>
      checkIfHistoricalRequestEditable({
        request: drawRequest,
        project: project,
        projectRequestsList: drawRequestsQuery.data?.results,
      }),
    [drawRequest, project, drawRequestsQuery.data?.results],
  );

  const initColumns = useMemo(
    () =>
      getInitColumns({
        isSubmissionProcess,
        isReallocationAllowed,
        drawRequest,
        retainageRate,
        flags,
      }),
    [isSubmissionProcess, retainageRate, isReallocationAllowed, drawRequest, project, flags],
  );

  const activeToEdit = useMemo(
    () =>
      (drawRequest?.waits_current_user_approval && !checkIsOwner(teamRole)) ||
      (checkIsCustomerSuccess(teamRole) && isRequestInReview(drawRequest?.status)) ||
      (flags?.['ENG_9274_edit_delete_historical_draw'] && isHistoricalRequestEditable) ||
      isEditTable,
    [drawRequest, teamRole, isEditTable, isHistoricalRequestEditable, flags],
  );

  useEffect(() => {
    setUnitsTableItems(null);
    setModelsTableItems(null);
  }, [filterValue, drawRequestId]);

  useEffect(() => {
    setLineItemsTableItems(null);
  }, [typeFilterValues, drawRequestId]);

  useEffect(() => {
    if (!requestMilestones?.results || unitsTableItems?.length || !drawRequest) return;
    const clonedMilestones = cloneDeep(requestMilestones.results);
    setUnitsTableItems(
      clonedMilestones.map((item: IMilestone, index: number) => createTableObject({ item, index })),
    );
  }, [requestMilestones?.results, drawRequest, unitsTableItems]);

  useEffect(() => {
    if (!lineItemsQuery.data?.results || lineItemsTableItems?.length || !drawRequest) return;
    const clonedMilestones = cloneDeep(lineItemsQuery.data?.results);
    setLineItemsTableItems(
      clonedMilestones.map((item: IMilestone, index: number) => createTableObject({ item, index })),
    );
  }, [lineItemsQuery.data?.results, drawRequest, lineItemsTableItems]);

  useEffect(() => {
    if (!modelsQuery.data?.results || modelsTableItems?.length || !drawRequest) return;
    const clonedMilestones = cloneDeep(modelsQuery.data?.results);
    setModelsTableItems(
      clonedMilestones.map((item: IMilestone, index: number) =>
        createTableObject({ item, index, isModelLevel: true }),
      ),
    );
  }, [modelsQuery.data?.results, drawRequest, modelsTableItems]);

  const patchMsGroup = useCallback(
    (params) =>
      patchMSGroupMutation.mutate({
        project: projectId,
        drawRequest: drawRequestId,
        milestoneId: params.milestoneId,
        group_by: activeGroupBy,
        json: params.json,
      }),
    [drawRequestId, projectId, activeGroupBy, patchMSGroupMutation],
  );

  const deleteRequest = useCallback(
    () =>
      deleteDrawRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: drawRequestId,
      }),
    [deleteDrawRequestMutation, projectId, drawRequestId],
  );

  const handleAutofillLenderAllowance = (autofillValue) => {
    const json = {
      autofill_key: autofillValue,
    };
    bulkMilestoneMutation.mutate({
      projectId,
      drawRequestId,
      json,
    });
  };

  const showAutofillButton = useMemo(
    () => autofillCanBeShown(drawRequest, teamRole),
    [drawRequest, teamRole],
  );

  const tableItems = useMemo(
    () =>
      getPHBTableItemsByActiveView({
        unitsTableItems,
        modelsTableItems,
        lineItemsTableItems,
        activeView,
      }),
    [lineItemsTableItems, unitsTableItems, modelsTableItems, activeView],
  );

  const isCurrentViewFetching = useMemo(
    () => isCurrentPHBViewLoading({ activeView, lineItemsQuery, unitsQuery, modelsQuery }),
    [activeView, lineItemsQuery, unitsQuery, modelsQuery],
  );

  const isLoading = useMemo(
    () => showLoadingSkeleton || isCurrentViewFetching || unitsQuery.isIdle,
    [showLoadingSkeleton, isCurrentViewFetching, unitsQuery.isIdle],
  );

  return {
    initColumns,
    tableItems,
    onExpandClick: isLineItemsView
      ? lineItemsNestedTableProps.onExpandClick
      : unitsNestedTableProps.onExpandClick,
    filterOptions,
    filterValue,
    handleShowFilterClick: (value: string) => {
      handleFilterClick(value);
      showTemporaryLoadingSkeleton();
    },
    patchMsGroup,
    isLoading,
    canDeleteRequest: canDeleteRequest(drawRequest),
    showDeleteModal,
    setShowDeleteModal,
    deleteRequest,
    isDeleting: deleteDrawRequestMutation.isLoading,
    showAutofillButton,
    drawRequest,
    handleAutofillLenderAllowance,
    isAutofillLoading: bulkMilestoneMutation.isLoading,
    totals: isLineItemsView
      ? undefined
      : drawRequest?.totals?.[LineItemFilterValues[LineItemFilterEnum.VERTICAL_COST].totalKey],
    groupByKeys: unitLineItemGrouping,
    updateRightDrawer,
    rightMenu,
    onMilestoneUpdate,
    activeView,
    setActiveView: (view) => {
      setActiveView(view);
      updateSettings({
        personal_setting: {
          PHB_TABLE_VIEW: { view_type: view },
        },
      });
    },
    isLineItemsView,
    viewTypes,
    typeFilterValues,
    setTypeFilterValues,
  };
};
