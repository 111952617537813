import React, { FC, useCallback, useContext } from 'react';
import { Box, Button, Skeleton, Stack } from '@mui/material';
import {
  ColumnsFilterV2,
  LineItemRightDrawer,
  Loader3DBoxes,
  MilestoneListOptionsMenu,
  RightDrawer,
} from '@components';
import { useNavigationNetBlocker } from '@hooks';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { TableContext, useMilestoneList } from './controller';
import { MilestoneListInterface } from './interface';
import { ColumnIcon } from './ColumnIcon';
import { Grid, ScrollSync, WindowScroller } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { Cell, HeaderCell, HeaderStick, ListWrapper } from './columns/common';
import { RequestTableTabsEnum, RequestTableTabTypesEnum, TableKeyEnum } from '@interfaces';

const MilestoneList: FC<MilestoneListInterface> = ({
  tableKey,
  milestones,
  initColumns,
  totals,
  withColumnIndication,
  headerLeftPart,
  headerRightPart,
  withProgress,
  patchMilestone,
  refetch,
  lockedColumns,
  resetMutation,
  requestStatus,
  deleteMilestone,
  openEditMilestoneModal,
  exportToCSV,
  contingencyMode,
  footerDataTestName = 'milestone_list_table_footer',
  menuItems = [],
  onExpandTable,
  source,
  updateData,
  isLoading,
  containerRef,
  lockSecondColumn = false,
  withCredit,
  groupByFilter,
  withoutStickLeftColumn,
  withoutStickHeader,
  onCreditChangeCompleted,
  commentIconComponent,
}) => {
  useNavigationNetBlocker();
  const {
    sortedMilestones,
    tableContext,
    hiddenColumns,
    isColumnFilterUpdating,
    columns,
    changeFieldVisibility,
    tableRef,
    milestoneColumns,
    rightDrawerParams,
    rightMenu,
    getColumnWidth,
    isCellActive,
    setactiveCell,
    highlightCells,
    renderColumns,
    onPaste,
    containerWidth,
    keyDown,
    project,
    activeCell,
    bottomLine,
    updateRightDrawer,
    showCreditButton,
  } = useMilestoneList({
    tableKey,
    milestones,
    initColumns,
    withProgress,
    patchMilestone,
    refetch,
    withColumnIndication,
    resetMutation,
    requestStatus,
    deleteMilestone,
    openEditMilestoneModal,
    contingencyMode,
    onExpandTable,
    source,
    updateData,
    withCredit,
    groupByFilter,
  });
  const flags = useLaunchDarklyFlags();
  const { isPHBProject } = useContext(SettingsContext);
  const footerCellRender = useCallback(({ key, style }) => {
    return <div style={style} key={key} />;
  }, []);

  const cellRenderer = useCallback(
    ({ columnIndex, key, rowIndex, style }) => {
      const column = renderColumns[columnIndex];
      const row = sortedMilestones[rowIndex];
      const isInFocus = isCellActive(columnIndex, rowIndex);
      const isLastRow = rowIndex == sortedMilestones.length - 1;
      const isNested = row['isNested'];

      if (isLastRow && !totals) return null;

      return (
        <Cell
          data-cell={`${rowIndex}_${columnIndex}`}
          isHightlight={highlightCells?.[rowIndex]?.[columnIndex]}
          noBorder={column.noBorder}
          noMargin={column.noMargin}
          onClick={() =>
            !column.isSpacer &&
            setactiveCell({
              column: columnIndex,
              row: rowIndex,
            })
          }
          isActive={isInFocus}
          isActiveMilestone={row['milestone_is_in_current_draw'] || isNested}
          isComplete={false}
          key={key}
          style={style}
          justifyContent={column.justifyContent || 'flex-end'}
        >
          {isLastRow ? column.Footer && column.Footer(totals) : column.renderCell({ row })}
        </Cell>
      );
    },
    [renderColumns, sortedMilestones, isCellActive, highlightCells, totals, setactiveCell],
  );

  const headerRenderer = useCallback(
    ({ columnIndex, key, style }) => {
      const column = renderColumns[columnIndex];
      return (
        <HeaderCell noBorder={column.noBorder} key={key} style={style}>
          {withColumnIndication && (
            <ColumnIcon columns={milestoneColumns} name={column.name} source={source} />
          )}
          {column.Header instanceof Function ? column.Header({ project }) : column.Header}
        </HeaderCell>
      );
    },
    [renderColumns, withColumnIndication, milestoneColumns, project, source],
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
        {Boolean(headerLeftPart?.length) && (
          <Stack direction="row" alignItems="center">
            {headerLeftPart?.map(({ Component }, index) => (
              <Box key={index.toString()}>{Component}</Box>
            ))}
          </Stack>
        )}
        <Stack direction="row" alignItems="center" sx={{ ml: 'auto' }}>
          {headerRightPart?.map(({ Component }, index) => (
            <Box key={index.toString()}>{Component}</Box>
          ))}
          {showCreditButton && (
            <Button
              size="small"
              onClick={() =>
                flags?.['ENG_7938_breakdown_of_line_item']
                  ? updateRightDrawer({
                      milestoneName: 'Credit review',
                      tab: RequestTableTabsEnum.CREDIT,
                      type: RequestTableTabTypesEnum.APPROVE,
                    })()
                  : updateRightDrawer({
                      milestoneName: 'Credit review',
                      tab: RequestTableTabsEnum.CREDIT_APPROVE,
                    })()
              }
              data-cy={`${source}__review_credit__button`}
              sx={{ ml: 1 }}
            >
              Review credits
            </Button>
          )}
          {commentIconComponent}
          {!lockedColumns && (
            <ColumnsFilterV2
              withLabel={tableKey === TableKeyEnum.SUBMISSION}
              hiddenColumns={hiddenColumns}
              columns={columns}
              milestoneColumns={milestoneColumns}
              changeFieldVisibility={changeFieldVisibility}
              isUpdating={isColumnFilterUpdating}
              source={source.toString()}
            />
          )}
          <MilestoneListOptionsMenu
            menuItems={menuItems}
            totals={totals}
            columns={columns}
            sortedMilestones={sortedMilestones}
            exportToCSV={exportToCSV}
          />
        </Stack>
      </Stack>
      <ListWrapper
        id="listWrapper"
        tabIndex={-1}
        ref={tableRef}
        onPaste={onPaste}
        onKeyDown={keyDown}
      >
        <TableContext.Provider value={tableContext}>
          {isLoading ? (
            isPHBProject ? (
              <Loader3DBoxes />
            ) : (
              <Skeleton variant="rectangular" height={72} />
            )
          ) : (
            <ScrollSync>
              {({ onScroll, scrollLeft: scrollLeftHandle }) => (
                <WindowScroller scrollElement={containerRef?.current}>
                  {({ width, height, isScrolling, scrollTop }) => (
                    <div style={{ overflowX: 'hidden' }}>
                      {!withoutStickHeader && (
                        <HeaderStick
                          isSticky={
                            scrollTop > 0 &&
                            scrollTop < bottomLine + sortedMilestones.length * 56 - 40
                          }
                        >
                          {renderColumns.length > 0 &&
                            headerRenderer({
                              columnIndex: lockSecondColumn ? 1 : 0,
                              key: 'index',
                              style: {
                                zIndex: 1000000,
                                width: getColumnWidth({ index: lockSecondColumn ? 1 : 0 }),
                                minWidth: getColumnWidth({ index: lockSecondColumn ? 1 : 0 }),
                              },
                            })}
                          {renderColumns.length > 0 && (
                            <Box sx={{ transform: `translate(-${scrollLeftHandle}px, 0px);` }}>
                              {renderColumns.map((item, index) => {
                                if (index === (lockSecondColumn ? 1 : 0)) return;
                                return headerRenderer({
                                  columnIndex: index,
                                  key: index,
                                  style: {
                                    width: getColumnWidth({ index }),
                                    minWidth: getColumnWidth({ index }),
                                  },
                                });
                              })}
                            </Box>
                          )}
                        </HeaderStick>
                      )}
                      {!withoutStickLeftColumn && renderColumns.length > 0 && (
                        <Grid
                          className="LeftColumn"
                          cellRenderer={(props) =>
                            props.rowIndex === 0 ? headerRenderer(props) : cellRenderer(props)
                          }
                          style={{ position: 'absolute', zIndex: 1000 }}
                          overscanCellsCount={5}
                          columnCount={lockSecondColumn ? 2 : 1}
                          columnWidth={getColumnWidth}
                          width={
                            getColumnWidth({ index: 0 }) +
                            (lockSecondColumn ? getColumnWidth({ index: 1 }) : 0)
                          }
                          autoHeight
                          height={height}
                          scrollLeft={lockSecondColumn ? null : scrollLeftHandle}
                          scrollTop={scrollTop}
                          isScrolling={isScrolling}
                          rowCount={sortedMilestones.length}
                          rowHeight={({ index }) => (index >= 1 ? 56 : 48)}
                          onScroll={onScroll}
                        />
                      )}
                      <Grid
                        className="Body"
                        cellRenderer={(props) =>
                          props.rowIndex === 0 ? headerRenderer(props) : cellRenderer(props)
                        }
                        overscanCellsCount={5}
                        columnCount={renderColumns.length}
                        columnWidth={getColumnWidth}
                        width={containerWidth}
                        autoHeight
                        height={height}
                        scrollLeft={scrollLeftHandle}
                        scrollTop={scrollTop}
                        isScrolling={isScrolling}
                        rowCount={sortedMilestones.length}
                        rowHeight={({ index }) => (index >= 1 ? 56 : 48)}
                        onScroll={onScroll}
                        scrollToColumn={activeCell.column}
                        scrollToRow={
                          activeCell.row * 60 < scrollTop + 300 &&
                          activeCell.row * 60 > scrollTop - 300
                            ? activeCell.row
                            : undefined
                        }
                      />
                      <Box
                        className={lockedColumns ? 'Footer-locked' : 'Footer'}
                        data-cy={footerDataTestName}
                      >
                        <Grid
                          width={width - 20}
                          height={12}
                          rowHeight={12}
                          onScroll={onScroll}
                          scrollLeft={scrollLeftHandle}
                          columnCount={renderColumns.length}
                          columnWidth={getColumnWidth}
                          overscanCellsCount={5}
                          rowCount={1}
                          cellRenderer={footerCellRender}
                        />
                      </Box>
                    </div>
                  )}
                </WindowScroller>
              )}
            </ScrollSync>
          )}
          {/** TODO: this is not should be part or ML list */}
          <RightDrawer {...rightMenu}>
            <LineItemRightDrawer
              setOpen={rightMenu.setOpen}
              rightDrawerParams={{ ...rightDrawerParams, onCreditChangeCompleted }}
              source={source}
            />
          </RightDrawer>
        </TableContext.Provider>
      </ListWrapper>
    </>
  );
};

export default MilestoneList;
