import React, { useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

import {
  Button,
  Checkbox,
  Chip,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  getInspectionName,
  getInspectionNameByRole,
  getLink,
  getServiceTagByRole,
  getServiceTypeDisplayName,
  getStatusChip,
  isChangeRequest,
  isCompletedService,
  isDrawRequest,
  isInactiveProject,
  isInspectionFailed,
  isInspectionService,
  isInspectionServiceManual,
  isOrderedService,
  isRequestCompleted,
  isRequestInReview,
  isServiceOrderEditableByPermission,
  percentFormatter,
  WithPermission,
  isInspectionEditable,
  isServiceReadyForReview,
  isTruePicInspection,
} from '@utils';

import { documentsTypeMap, scoreColorMap, statusMap } from '@constants';
import { colors } from '@theme';
import { PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import { currencyFormatter } from './index';
import {
  IconWithProjectPermission,
  PopoverContentList,
  PopoverTooltip,
  ServiceCancelButton,
  StatusChip,
  StatusChipWithIconAndTooltip,
  StyledLink,
  VerticalMenu,
} from '@components';
import {
  ChecklistIcon,
  CommentIconWithIndicator,
  DocumentsIconWithIndicator,
  PhotoGalleryIconWithIndicator,
  VisibilityOnIcon,
  WarningIcon,
} from '@svgAsComponents';
import { PermissionNamesEnums, ServiceOrderStatusEnum, TableKeyEnum } from '@interfaces';
import { useDateFormatter } from '@hooks';

type SpanProps = {
  size?: string;
  color: string;
};
const StyledScoreButton = styled('span')<SpanProps>(({ color, size }) => ({
  width: size === 'small' ? 16 : 24,
  height: size === 'small' ? 16 : 24,
  borderRadius: 2,
  backgroundColor: color,
  display: 'block',
}));

const CurrencyCellRenderer =
  ({ isLoading, typoprops }: { isLoading: boolean; typoprops?: TypographyProps }) =>
  ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    return <Typography {...typoprops}>{currencyFormatter(value)}</Typography>;
  };

const DateCellRenderer =
  ({
    isLoading,
    typoprops,
    dataTestName,
  }: {
    isLoading: boolean;
    typoprops?: TypographyProps;
    dataTestName: string;
  }) =>
  ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    return <DateComponent date={value} typoprops={typoprops} dataTestName={dataTestName} />;
  };

const DateComponent = ({ date, typoprops, dataTestName }) => {
  const { dateFormatter } = useDateFormatter();
  return (
    <Typography {...typoprops} data-cy={dataTestName ? `${dataTestName}` : ''}>
      {dateFormatter({ date })}
    </Typography>
  );
};

const DateAndTimeCellRenderer =
  ({
    isLoading,
    typoprops,
    dataTestName,
  }: {
    isLoading: boolean;
    typoprops?: TypographyProps;
    dataTestName: string;
  }) =>
  ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    return <DateAndTimeComponent date={value} typoprops={typoprops} dataTestName={dataTestName} />;
  };

const DateAndTimeComponent = ({ date, typoprops, dataTestName }) => {
  const { dateFormatter } = useDateFormatter();
  return (
    <Typography {...typoprops} data-cy={dataTestName ? `${dataTestName}` : ''}>
      {dateFormatter({ date, withTime: true })}
    </Typography>
  );
};

const ScoreCellRenderer =
  ({ isLoading, size }) =>
  ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;

    const isHasComment = value?.comments?.length;
    const comments = value?.flags?.join(', ');
    const color = scoreColorMap[value?.color || value];

    return (
      <span onClick={(e) => e.stopPropagation()}>
        {isHasComment ? (
          <PopoverTooltip popoverContent={<PopoverContentList title="Comments" data={comments} />}>
            <StyledScoreButton color={color} size={size} />
          </PopoverTooltip>
        ) : (
          <StyledScoreButton color={color} style={{ cursor: 'auto' }} size={size} />
        )}
      </span>
    );
  };

// table headers common for all project tables

export const headerTemplate = ({
  isLoading = false,
  headerName,
  accessor,
  disableSortBy = false,
  placeholder = '',
  minWidth = '',
  tooltipTitle = '',
  dataTestName = '',
  flex = 0,
}) => ({
  Header: tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <span>{headerName}</span>
    </Tooltip>
  ) : (
    headerName
  ),
  headerName,
  accessor,
  disableFilters: true,
  disableSortBy,
  minWidth,
  flex,
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    if (value || value === 0)
      return <span data-cy={dataTestName ? `${dataTestName}` : ''}>{value}</span>;
    if (!value && placeholder)
      return <span data-cy={dataTestName ? `${dataTestName}` : ''}>{placeholder}</span>;
    return null;
  },
});

export const yesNo = ({ accessor, header, isLoading, dataTestName = '' }) => ({
  Header: header,
  accessor,
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    return (
      <Typography data-cy={dataTestName ? `${dataTestName}` : ''} variant="body2">
        {value ? 'Yes' : 'No'}
      </Typography>
    );
  },
});

export const amount = ({
  accessor,
  header,
  isLoading,
  disableSortBy = false,
  minWidth = '',
  typoprops = { variant: 'body3' } as TypographyProps,
  CustomFooter = null,
  footerDataTestName = '',
}) => ({
  Header: header,
  accessor,
  disableFilters: true,
  disableSortBy,
  minWidth,
  Cell: CurrencyCellRenderer({ isLoading, typoprops }),
  Footer: (info) => {
    const sum = useTotalCalculation(info, accessor);
    return CustomFooter ? (
      <CustomFooter amount={sum} dataTestName={footerDataTestName} />
    ) : (
      <>{currencyFormatter(sum)}</>
    );
  },
});

export const amountAndPrecents = ({
  accessor,
  header,
  precentAccessor,
  disableSortBy = false,
  minWidth = '',
  FooterStatic,
}) => ({
  Header: header,
  accessor,
  disableFilters: true,
  disableSortBy,
  minWidth,
  Cell: ({ cell: { value }, row }) => (
    <Stack alignItems="flex-end">
      <Typography variant="printBody1">{currencyFormatter(value, '-')}</Typography>
      <Typography variant="printBody1">
        {' '}
        {percentFormatter({ value: row.original?.[precentAccessor], roundTo: 0 })}
      </Typography>
    </Stack>
  ),
  Footer: (info) => {
    const sum = useTotalCalculation(info, accessor);
    return FooterStatic ? <FooterStatic /> : <>{currencyFormatter(sum)}</>;
  },
});

export const percentage = ({
  accessor,
  header,
  isLoading,
  disableSortBy = false,
  minWidth = '',
}) => ({
  Header: header,
  accessor,
  disableFilters: true,
  disableSortBy,
  minWidth,
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    return <span>{percentFormatter({ value, roundTo: 0 })}</span>;
  },
  Footer: (info) => {
    const sum = useTotalCalculation(info, accessor);
    return <>{percentFormatter({ value: sum, roundTo: 0 })}</>;
  },
});

export const date = ({
  accessor,
  header,
  withTime = false,
  isLoading,
  disableSortBy = false,
  typoprops = { variant: 'body3' } as TypographyProps,
  dataTestName = '',
}) => ({
  Header: header,
  accessor,
  disableFilters: true,
  disableSortBy,
  Cell: withTime
    ? DateAndTimeCellRenderer({ isLoading, typoprops, dataTestName })
    : DateCellRenderer({ isLoading, typoprops, dataTestName }),
});

export const scheduledInspectionDate = ({
  accessor,
  header,
  isLoading,
  disableSortBy = false,
  typoprops = { variant: 'body3' } as TypographyProps,
  dataTestName = '',
}) => ({
  Header: header,
  accessor,
  disableFilters: true,
  disableSortBy,
  Cell: ({ cell: { value }, row }) => {
    const { dateFormatter } = useDateFormatter();
    if (isLoading) return <Skeleton />;
    return (
      <Typography {...typoprops} data-cy={dataTestName ? `${dataTestName}` : ''}>
        {isInspectionServiceManual(row?.original?.inspection_agency?.service)
          ? ''
          : dateFormatter({ date: value })}
      </Typography>
    );
  },
});

export const orderedServiceDate = ({
  header,
  isLoading,
  disableSortBy = false,
  typoprops = { variant: 'body3' } as TypographyProps,
  dataTestName = '',
}) => ({
  Header: header,
  disableFilters: true,
  disableSortBy,
  Cell: ({ row }) => {
    const { dateFormatter } = useDateFormatter();
    if (isLoading) return <Skeleton />;
    return (
      <Typography {...typoprops} data-cy={dataTestName ? `${dataTestName}` : ''}>
        {dateFormatter({ date: row?.original?.ordered_at })}
      </Typography>
    );
  },
});

export const type = ({ showTotal = true, isLoading }) => ({
  Header: 'Type',
  accessor: 'type',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    return <span>{isChangeRequest({ type: value }) ? 'Change Request' : 'Draw Request'}</span>;
  },
  Footer: () => {
    if (!showTotal) return null;
    return <>Total</>;
  },
});

export const projectName = ({ isLoading, minWidth = '', source = '' }) => ({
  Header: 'Project name',
  accessor: 'project.name',
  minWidth,
  Cell: ({ row }) => {
    const { settings } = useContext(SettingsContext);
    const project = row.original?.project;
    const projectStatusValue = statusMap(project?.status, settings.display, 'project');
    if (isLoading) return <Skeleton />;

    return (
      <span data-cy={`${source}`}>
        {project?.name}

        {isInactiveProject(project?.status) ? ` (${projectStatusValue?.text})` : ''}
      </span>
    );
  },
  disableFilters: true,
});

export const loanType = ({ isLoading }) => ({
  Header: 'Loan type',
  accessor: 'loan.type',
  Cell: ({ row }) => {
    const { settings } = useContext(SettingsContext);
    const { loan_types } = settings?.display || {};
    const loanTypeNameDisplay = loan_types?.find(
      (type) => type.name === row.original?.loan?.type,
    )?.name_display;

    if (isLoading) return <Skeleton />;

    return <span data-cy={`projects__list__loan_type`}>{loanTypeNameDisplay}</span>;
  },
  disableFilters: true,
  disableSortBy: true,
});

export const documentType = ({
  isLoading,
  typoprops = {},
  dataTestName = '',
}: {
  isLoading: boolean;
  typoprops?: TypographyProps;
  dataTestName?: string;
}) => ({
  Header: 'Type',
  accessor: 'document_type',
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    return (
      <Typography variant="body3" {...typoprops} data-cy={dataTestName ? `${dataTestName}` : ''}>
        {documentsTypeMap[value] || value}
      </Typography>
    );
  },
});

export const uploadedBy = ({
  isLoading,
  typoprops = {},
  dataTestName = '',
}: {
  isLoading: boolean;
  typoprops?: TypographyProps;
  dataTestName?: string;
}) => ({
  Header: 'Uploaded by',
  accessor: 'created_by.full_name',
  Cell: ({ row, cell: { value } }) => {
    const { created_by_service_name } = row?.original || {};
    if (isLoading) return <Skeleton />;
    return (
      <Typography variant="body3" {...typoprops} data-cy={dataTestName ? `${dataTestName}` : ''}>
        {created_by_service_name || value}
      </Typography>
    );
  },
});

export const simpleText = ({
  header = 'Description',
  accessor = 'description',
  secondLine = '',
  FooterStatic = null,
  cellStyles = {},
}) => ({
  Header: header,
  accessor,
  disableSortBy: true,
  description,
  Cell: ({ cell: { value }, row }) => (
    <>
      <Typography sx={cellStyles} variant="printBody1">
        {value}
      </Typography>
      {secondLine && (
        <Typography color={colors.text.light} variant="printBody1">
          {row.original?.[secondLine]}
        </Typography>
      )}
    </>
  ),
  disableFilters: true,
  Footer: (info) => {
    const sum = useTotalCalculation(info, accessor);
    return FooterStatic ? <FooterStatic /> : <>{currencyFormatter(sum)}</>;
  },
});

export const requestWithNumber = ({ header = 'Name', accessor = 'counter_per_request_type' }) => ({
  Header: header,
  accessor,
  disableSortBy: true,
  disableFilters: true,
  Cell: ({ cell: { value }, row }) => {
    return (
      <Typography variant="printBody1" whiteSpace="nowrap">
        {isChangeRequest({ type: row.original.type }) ? 'Change #' : 'Draw #'}
        {value}
      </Typography>
    );
  },
});

export const description = ({ header = 'Description', isLoading, source = '' }) => ({
  Header: header,
  accessor: 'description',
  disableSortBy: true,
  Cell: ({ row }) => {
    if (isLoading) return <Skeleton />;
    return (
      <span data-cy={`${source}`}>
        {/* DRs don't have name but have number so we can show it instead of name */}
        {row.original.name || row.original.number}
      </span>
    );
  },
  disableFilters: true,
});

export const coordinator = ({
  disableFilters = true,
  header = 'Coordinator',
  accessor = 'coordinator.full_name',
  isLoading,
  updateAssignCoordinatorPopup,
}) => {
  return {
    Header: header,
    accessor,
    disableFilters,
    Cell: ({ cell: { value }, row }) => {
      if (isLoading) return <Skeleton />;

      const openPopupCallback = () => updateAssignCoordinatorPopup(row.original);
      return (
        <Stack flexDirection="row" alignItems="center">
          {value && (
            <Typography variant="body3SemiBold" sx={{ color: colors.text.link }}>
              {value || ''}
            </Typography>
          )}
          {!isRequestCompleted(row.original?.status) && (
            <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT}>
              <IconWithProjectPermission
                permissionKey={PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT}
                projectId={row.original?.project?.id}
                openPopupCallback={openPopupCallback}
                isCurrentProjectArchived={isInactiveProject(row.original?.project?.status)}
                source="requests_queue__draw_coordinator_edit"
              />
            </WithPermission>
          )}
        </Stack>
      );
    },
  };
};

export const approvalTeam = ({
  disableFilters = true,
  header = 'Team',
  accessor = 'current_reviewer_team',
  disableSortBy = true,
  isLoading,
  updateApproverPopup,
}) => {
  return {
    Header: header,
    accessor,
    disableFilters,
    disableSortBy,
    Cell: ({ cell: { value }, row }) => {
      if (isLoading) return <Skeleton />;
      if (!value) return null;

      const openPopupCallback = () => updateApproverPopup(row.original);
      return (
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="body3SemiBold" sx={{ color: colors.text.link }}>
            {value?.name}
          </Typography>
          {isRequestInReview(row.original?.status) && (
            <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_EDIT}>
              <IconWithProjectPermission
                permissionKey={PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_EDIT}
                projectId={row.original?.project?.id}
                openPopupCallback={openPopupCallback}
                isCurrentProjectArchived={isInactiveProject(row.original?.project?.status)}
                source="requests_queue__reviewer_team__edit__icon"
              />
            </WithPermission>
          )}
        </Stack>
      );
    },
  };
};

export const statusWithRole = ({
  disableFilters = true,
  header = 'Status',
  accessor = 'status',
  riskRadar = false,
  isLoading,
  disableSortBy = false,
}) => {
  return {
    Header: header,
    accessor,
    disableFilters,
    disableSortBy,
    Cell: ({ row }) => {
      if (isLoading) return <Skeleton />;

      return riskRadar
        ? getStatusChip(row.original.draw_request, 'small', '', 'column')
        : getStatusChip(row.original);
    },
  };
};

export const projectStatus = ({
  isLoading,
  accessor,
  disableSortBy = false,
  minWidth = '160px',
}) => ({
  Header: 'Project status',
  accessor,
  disableFilters: true,
  disableSortBy,
  minWidth,
  Cell: ({ cell: { value }, row }) => {
    const { settings } = useContext(SettingsContext);
    if (isLoading) return <Skeleton />;
    const projectStatusValue = statusMap(value, settings.display, 'project');
    return (
      <Tooltip
        title={row?.original?.project?.status_change_reason || row?.original?.status_change_reason}
      >
        <span>
          <StatusChip
            color={projectStatusValue.color}
            backgroundColor={projectStatusValue.backgroundColor}
            label={projectStatusValue.text}
          />
        </span>
      </Tooltip>
    );
  },
});

export const serviceType = ({ isLoading, accessor = 'service_type', serviceTypesMap = {} }) => ({
  Header: 'Service type',
  accessor,
  disableFilters: true,
  disableSortBy: true,
  serviceTypesMap,
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;

    const serviceType = getServiceTypeDisplayName({
      serviceTypesMap,
      serviceType: value,
    });

    return (
      <Typography
        variant="body3"
        data-cy="project__services_tab__list__service_type"
        sx={{ textTransform: 'capitalize' }}
      >
        {serviceType}
      </Typography>
    );
  },
});

export const serviceStatus = ({
  isLoading,
  accessor = 'status',
  Header = 'Order status',
  disableSortBy = true,
  minWidth = '150px',
}) => ({
  Header,
  accessor,
  disableFilters: true,
  disableSortBy,
  minWidth,
  Cell: ({ row, cell: { value } }) => {
    const { settings } = useContext(SettingsContext);
    const inspectionStatusValue = statusMap(value, settings.display, 'inspection');

    if (isLoading) return <Skeleton />;
    if (!value || value === ServiceOrderStatusEnum.CREATED) return <span>-</span>;

    const chipProps = {
      color: inspectionStatusValue.color,
      backgroundColor: inspectionStatusValue.backgroundColor,
      label: inspectionStatusValue.text || value,
      dataTestName: 'project__services_tab__list__status_chip',
    };

    if (isInspectionFailed(value))
      return (
        <StatusChipWithIconAndTooltip
          {...chipProps}
          tooltipText={row.original.error_msg}
          icon={<WarningIcon size={14} />}
        />
      );

    return <StatusChip {...chipProps} />;
  },
});
export const inspectionAgencyStatus = ({
  isLoading,
  accessor = 'provider_status_display',
  Header = 'Agency status',
  disableSortBy = true,
}) => ({
  Header,
  accessor,
  disableFilters: true,
  disableSortBy,
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    if (value) return value;
    return <span>-</span>;
  },
});

export const projectScore = ({
  headerName,
  accessor,
  isLoading,
  disableSortBy = true,
  size = 'medium',
}) => ({
  Header: headerName,
  accessor,
  disableFilters: true,
  Cell: ScoreCellRenderer({ isLoading, size }),
  disableSortBy,
});

export const state = ({ isLoading, accessor = 'address', disableSortBy = false }) => ({
  Header: 'State',
  accessor,
  disableFilters: true,
  disableSortBy,
  Cell: ({ cell: { value }, row }) => {
    if (isLoading) return <Skeleton />;
    return <span>{value || row.original?.address?.state}</span>;
  },
});

export const propertyType = ({
  isLoading,
  accessor = 'property_type',
  header = 'Property type',
  valuesList = [],
  disableSortBy = false,
  minWidth = '',
}) => ({
  Header: header,
  accessor,
  disableFilters: true,
  disableSortBy,
  minWidth,
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    return <span>{valuesList?.find((x) => x.type === value)?.name || value}</span>;
  },
});

const menuItems = ({ action, source }) => [
  {
    action: action('documents'),
    text: 'Documents',
    icon: <DocumentsIconWithIndicator />,
    dataTestName: `${source}__body__documents_gallery__menu_item`,
  },
  {
    action: action('photos'),
    text: 'Progress photos',
    icon: <PhotoGalleryIconWithIndicator color={colors.icons.green} />,
    dataTestName: `${source}__body__photos_gallery__menu_item`,
  },
];

export const requestActions = ({ isLoading, updateRightDrawer, source = '', showChecklist }) => ({
  Header: '',
  accessor: 'id',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row }) => {
    if (isLoading) return <Skeleton />;
    const onIconClick = (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      updateRightDrawer({
        title: row.original?.name || row.original?.project?.name || tab,
        projectId: row.original?.project?.id,
        requestId: row.original?.id,
        activeTab: tab.toLowerCase(),
      });
    };

    return (
      <Stack flexDirection="row" alignItems="center">
        {showChecklist && (
          <Chip
            sx={{
              backgroundColor: colors.background.white,
              borderRadius: '2px',
              boxShadow: '0px 4px 8px 0px  rgba(0, 0, 0, 0.15)',
              padding: 1,
              marginRight: 1,
              '& .MuiChip-label': {
                padding: 0,
                paddingLeft: 1,
              },
            }}
            label={
              <Typography
                variant="labelSemiBold"
                sx={{ color: colors.status.information.medium, padding: 0 }}
              >
                Checklist
              </Typography>
            }
            icon={<ChecklistIcon size={16} color={colors.status.information.medium} />}
            onClick={onIconClick('Policies')}
            data-cy={`${source}__policies__button`}
          />
        )}
        <VerticalMenu
          menuItems={menuItems({
            action: (tab: string) => () => {
              updateRightDrawer({
                title: row.original?.name || row.original?.project?.name,
                projectId: row.original?.project?.id,
                requestId: row.original?.id,
                activeTab: tab,
              });
            },
            source,
          })}
        >
          <IconButton data-cy={`${source}__body__documents_gallery__icon`}>
            <DocumentsIconWithIndicator />
          </IconButton>
        </VerticalMenu>
        <IconButton onClick={onIconClick('Comments')} data-cy={`${source}__comments__icon`}>
          <CommentIconWithIndicator
            hasComments={row.original?.comments_preview?.has_comments}
            hasUnreadComments={row.original?.comments_preview?.has_unread_comments}
          />
        </IconButton>
      </Stack>
    );
  },
});

export const serviceActions = ({ isLoading, source, lastCompletedInspection = null }) => ({
  Header: 'Actions',
  accessor: 'actions',
  disableFilters: true,
  disableSortBy: true,
  minWidth: 220,
  flex: 0,
  hidable: false,
  Cell: ({ row }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isCurrentProjectArchived } = useContext(SettingsContext);
    const { permissions } = useContext(PermissionsContext);
    const flags = useLaunchDarklyFlags();
    const { status, project } = row?.original || {};

    const navigateToInspection = () => {
      const link = getLink({ row: row.original, tableKey: TableKeyEnum.SERVICES_LIST });
      link && navigate(link, { state: location.pathname });
    };

    const showEnterResultButton =
      (isTruePicInspection(row?.original?.inspection_agency?.service)
        ? isServiceReadyForReview(status)
        : isOrderedService(status)) &&
      isServiceOrderEditableByPermission(row?.original, permissions) &&
      !isInactiveProject(project?.status);

    const showEditButton = useMemo(() => {
      if (!isCompletedService(status)) return false;
      if (isInspectionService(row?.original?.service_type)) {
        return isInspectionEditable(row?.original, lastCompletedInspection?.id);
      } else {
        return isServiceOrderEditableByPermission(row?.original, permissions);
      }
    }, [row?.original, lastCompletedInspection?.id, permissions]);

    if (isLoading) return <Skeleton />;

    return (
      <Stack spacing={1} direction="row">
        {showEnterResultButton && (
          <Button
            size="small"
            variant="new"
            color="secondary"
            onClick={() => navigateToInspection()}
            disabled={isCurrentProjectArchived}
            data-cy={`${source}__enter_results__button`}
            sx={{ minWidth: '110px' }}
          >
            Enter results
          </Button>
        )}

        {flags?.['ENG_9349_edit_button_for_editable_services'] && showEditButton && (
          <Button
            size="small"
            variant="new"
            color="secondary"
            onClick={() => navigateToInspection()}
            disabled={isCurrentProjectArchived}
            data-cy={`${source}__edit_results__button`}
          >
            Edit
          </Button>
        )}
        <ServiceCancelButton serviceOrder={row.original} size="small" source={source} />
      </Stack>
    );
  },
});

export const serviceDocumentsAndComments = ({
  isLoading,
  updateRightDrawer,
  source = '',
  setSelectedRowId,
  serviceTypesMap = {},
}) => ({
  Header: '',
  accessor: 'document_and_check',
  disableFilters: true,
  disableSortBy: true,
  minWidth: 100,
  flex: 0,
  hidable: false,
  serviceTypesMap,
  Cell: ({ row }) => {
    const { id, project, service_type } = row?.original || {};
    const serviceType = getServiceTypeDisplayName({
      serviceTypesMap,
      serviceType: row.original?.service_type,
    });

    const onIconClick = (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      setSelectedRowId(id);
      event.stopPropagation();
      updateRightDrawer({
        title: serviceType || row?.original?.project?.name || tab,
        projectId: project?.id,
        requestId: row?.original?.draw_request?.id,
        ...(isInspectionService(service_type) ? { inspectionId: id } : { serviceOrderId: id }),
        activeTab: tab.toLowerCase(),
      });
    };

    if (isLoading) return <Skeleton />;

    const { comments_preview, documents_preview } = row?.original || {};

    return (
      <Stack direction="row" spacing={0}>
        <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
          <IconButton onClick={onIconClick('Documents')} data-cy={`${source}__documents__icon`}>
            <DocumentsIconWithIndicator
              hasDocuments={documents_preview?.has_docs || documents_preview?.has_photos}
              hasUnreadDocuments={
                documents_preview?.has_not_seen_docs || documents_preview?.has_not_seen_photos
              }
            />
          </IconButton>

          <IconButton onClick={onIconClick('Comments')} data-cy={`${source}__comments__icon`}>
            <CommentIconWithIndicator
              hasComments={comments_preview?.has_comments}
              hasUnreadComments={comments_preview?.has_unread_comments}
            />
          </IconButton>
        </WithPermission>
      </Stack>
    );
  },
});

export const projectActions = ({ isLoading, updateRightDrawer, source = '' }) => ({
  Header: 'Actions',
  accessor: 'id',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row }) => {
    if (isLoading) return <Skeleton />;
    const onIconClick = (tab: string) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      updateRightDrawer({
        title: row.original?.name,
        projectId: row.original?.id,
        activeTab: tab.toLowerCase(),
      });
    };

    return (
      <Stack flexDirection="row" alignItems="center">
        <VerticalMenu
          menuItems={menuItems({
            action: (tab: string) => () => {
              updateRightDrawer({
                title: row.original?.name,
                projectId: row.original?.id,
                activeTab: tab,
              });
            },
            source,
          })}
        >
          <IconButton data-cy={`${source}__body__documents_gallery__icon`}>
            <DocumentsIconWithIndicator />
          </IconButton>
        </VerticalMenu>
        <IconButton onClick={onIconClick('Comments')} data-cy={`${source}__comments__icon`}>
          <CommentIconWithIndicator
            hasComments={row.original?.comments_preview?.has_comments}
            hasUnreadComments={row.original?.comments_preview?.has_unread_comments}
          />
        </IconButton>
      </Stack>
    );
  },
});

const useTotalCalculation = (info, key) => {
  // Only calculate total if rows change
  // only for top level rows with value
  const totalAmount = React.useMemo(
    () => info.rows.filter((row) => row.values[key]).reduce((sum, row) => row.values[key] + sum, 0),
    [info.rows, key],
  );
  return totalAmount;
};

export const inspectorAllowanceColumn = ({ isLoading }) => ({
  Header: 'Inspection allowance',
  accessor: 'inspector_allowance',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;

    if (!value?.rate || !value?.amount) return null;
    return (
      <span>{`${percentFormatter({ value: value?.rate, roundTo: 0 })} / ${currencyFormatter(
        value?.amount,
      )}`}</span>
    );
  },
});

export const inspectionServiceColumn = ({
  isLoading,
  teamRole,
  disableSortBy = true,
  header = 'Agency',
}) => ({
  Header: header,
  accessor: 'inspection_agency',
  disableFilters: true,
  disableSortBy,
  Cell: ({ cell: { value } }) => {
    if (isLoading) return <Skeleton />;
    return <span>{getInspectionNameByRole({ inspectionAgency: value, teamRole })}</span>;
  },
});
export const serviceAgencyColumn = ({
  isLoading,
  teamRole,
  disableSortBy = true,
  header = 'Agency',
}) => ({
  Header: header,
  accessor: 'service_agency',
  disableFilters: true,
  disableSortBy,
  Cell: ({ cell: { value }, row }) => {
    const serviceType = row.original?.service_type;
    if (isLoading) return <Skeleton />;

    return (
      <span>
        {isInspectionService(serviceType)
          ? getInspectionNameByRole({
              inspectionAgency: row?.original?.inspection_agency,
              teamRole,
            })
          : getServiceTagByRole({ serviceAgency: value, teamRole })}
      </span>
    );
  },
});

export const drNumber = ({
  isLoading,
  projectId,
  disableSortBy = true,
}: {
  isLoading: boolean;
  projectId?: string;
  disableSortBy?: boolean;
}) => ({
  Header: 'Linked request #',
  accessor: 'draw_request',
  disableFilters: true,
  disableSortBy,
  Cell: ({ cell: { value }, row }) => {
    if (isLoading) return <Skeleton />;

    return (
      <span>
        <StyledLink
          onClick={(e) => e.stopPropagation()}
          to={`/projects/${row.original?.project?.id || projectId}/draws/draw-requests/${value?.id}`}
          variant="body3Semibold"
        >
          {value?.number}
        </StyledLink>
      </span>
    );
  },
});

export const inspectionName = ({ isLoading }) => ({
  Header: 'Name',
  accessor: 'name',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row }) => {
    if (isLoading) return <Skeleton />;

    return <InspectionNameComponent serviceOrder={row?.original} />;
  },
});

const InspectionNameComponent = ({ serviceOrder }) => {
  const { dateFormatter } = useDateFormatter();
  const flags = useLaunchDarklyFlags();
  return <span>{getInspectionName({ serviceOrder, dateFormatter, flags })}</span>;
};

export const requestName = ({ isLoading }) => ({
  Header: 'Request',
  accessor: 'draw_request.name',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row }) => {
    if (isLoading) return <Skeleton />;

    if (!row.original.draw_request) return null;
    return (
      <span>{`${isDrawRequest(row.original.draw_request) ? 'Draw' : 'Change'} request #${
        row.original.draw_request.counter_per_request_type || ''
      }`}</span>
    );
  },
});

export const requestNameLink = ({ isLoading, projectId }) => ({
  Header: 'Request',
  accessor: 'draw_request.name',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row }) => {
    if (isLoading) return <Skeleton />;

    return (
      <StyledLink to={`/projects/${projectId}/draws/draw-requests/${row?.original?.id}`}>
        {`${isDrawRequest(row?.original) ? 'Draw' : 'Change'} #${row?.original?.number || ''}`}
      </StyledLink>
    );
  },
  Footer: () => <Typography variant="body2SemiBold">Total</Typography>,
});

export const projectWatchers = ({ isLoading, onIconClick }) => ({
  Header: 'Watch',
  accessor: 'watcher_count',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row }) => {
    if (isLoading) return <Skeleton />;
    const isInactiveProjects = isInactiveProject(row?.original?.status);

    return (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onIconClick(row?.original?.is_watching, row?.original?.id);
        }}
        disabled={isInactiveProjects}
      >
        <VisibilityOnIcon
          size={24}
          color={row?.original?.is_watching ? colors.status.information.medium : colors.icons.gray}
        />
      </IconButton>
    );
  },
});

export const selectProject = ({
  isLoading,
  onCheckboxClick,
  checkIsProjectSelected,
  isAllSelected,
  onSelectAllClick,
}) => ({
  Header: <Checkbox checked={isAllSelected} onClick={onSelectAllClick} />,
  accessor: 'is_project_selected',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row }) => {
    const isProjectSelected = checkIsProjectSelected(row?.original?.id);
    if (isLoading) return <Skeleton />;
    const isInactiveProjects = isInactiveProject(row?.original?.status);

    return (
      <Checkbox
        onClick={(e) => {
          const newValue = !isProjectSelected;
          e.stopPropagation();
          onCheckboxClick(newValue, row?.original?.id);
        }}
        checked={isProjectSelected}
        sx={{ pl: 0.5, pt: 0.5 }}
        disabled={isInactiveProjects}
      />
    );
  },
});
