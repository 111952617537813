import React, { FC, memo, useMemo, useContext } from 'react';
import { Typography, Stack, Skeleton } from '@mui/material';
import {
  MilestoneListColumnType,
  CommonRowType,
  ColumnLabel,
  HeaderText,
  ProgressionSum,
} from './common';
import { currencyFormatter, isRequestDraft, isRequestInReview, useBlockerFooter } from '@utils';
import { TableContext } from '../controller';

import { ColumnV2Width, IMilestoneTotal } from '@interfaces';

const RevisedConstructionBudget: FC<{ row: CommonRowType }> = ({ row }) => {
  const { requested_adjustments, approved_adjustments, prefunding_cost, revised_estimate } = row;

  const { requestStatus, source } = useContext(TableContext);
  const budget = prefunding_cost + revised_estimate || 0;

  const progress = useMemo(
    () =>
      isRequestDraft(requestStatus)
        ? requested_adjustments || 0
        : isRequestInReview(requestStatus)
          ? approved_adjustments || 0
          : 0,
    [requested_adjustments, approved_adjustments, requestStatus],
  );

  const sum = useMemo(
    () => (isRequestDraft(requestStatus) ? budget : budget - progress),
    [row, progress, requestStatus],
  );

  return (
    <Stack alignItems="flex-end">
      <Typography
        variant="body3"
        data-cy={`${source}__body__revised_construction_budget__value__index_${row?.project_milestone?.index}`}
      >
        {currencyFormatter(sum)}
      </Typography>
      <ProgressionSum sum={sum} progress={progress} />
    </Stack>
  );
};

const Memoized = memo(RevisedConstructionBudget);

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { requestStatus, source } = useContext(TableContext);
  const { prefunding_cost, revised_estimate } = row;
  const budget = prefunding_cost + revised_estimate || 0;

  const progress = isRequestDraft(requestStatus)
    ? row.requested_budget_change
    : isRequestInReview(requestStatus)
      ? row.approved_budget_change
      : 0;

  const sum = isRequestInReview(requestStatus) ? budget - progress : budget;

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Stack alignItems="flex-end">
      <Typography
        variant="body3SemiBold"
        data-cy={`${source}__footer__revised_construction_budget__value`}
      >
        {currencyFormatter(sum)}
      </Typography>
      <ProgressionSum sum={sum} progress={progress} />
    </Stack>
  );
};

const revisedConstructionBudget: MilestoneListColumnType = {
  name: 'revised_construction_budget',
  columnText: 'Revised construction budget ($)',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="% of cumulative changes to original scheduled value">
      <ColumnLabel>Revised construction</ColumnLabel>
      <ColumnLabel> budget ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default revisedConstructionBudget;
