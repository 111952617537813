import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.neutral.medium }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3 3H5.7C4.20883 3 3 4.20883 3 5.7V18.3C3 19.7912 4.20883 21 5.7 21H18.3C19.7912 21 21 19.7912 21 18.3V5.7C21 4.20883 19.7912 3 18.3 3ZM4.8 5.7C4.8 5.20294 5.20294 4.8 5.7 4.8H18.3C18.7971 4.8 19.2 5.20294 19.2 5.7V18.3C19.2 18.7971 18.7971 19.2 18.3 19.2H5.7C5.20294 19.2 4.8 18.7971 4.8 18.3V5.7Z"
      fill={color}
    />
  </svg>
);

export default Icon;
