import React from 'react';
import { Skeleton, Typography } from '@mui/material';

const uploadedByColumn = ({ isLoading, source }) => ({
  Header: 'Uploaded by',
  accessor: 'created_by.full_name',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ cell: { value }, row }) => {
    const { created_by_service_name } = row?.original || {};
    if (isLoading) return <Skeleton />;

    return (
      <Typography variant="body3" data-cy={`${source}__uploaded_by__value`}>
        {created_by_service_name || value}
      </Typography>
    );
  },
});

export default uploadedByColumn;
