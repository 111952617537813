import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 18 }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 18) * 24}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14969 0H11.1767L17.9759 7.09344V20.8744C17.9759 22.5996 16.5755 24 14.8503 24H3.14969C1.42453 24 0.0241089 22.5996 0.0241089 20.8744V3.12558C0.0241089 1.40042 1.42453 0 3.14969 0V0Z"
      fill="#1F4739"
    />
    <path
      opacity="0.302"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1666 0V7.03255H17.9759L11.1666 0Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.31122 17.6068L10 17.6068V18.8144L5.31122 18.8144V17.6068ZM5.31122 11.4368L10 11.4368V12.6343L5.31122 12.6343V11.4368ZM5.31122 13.4664H12.6888V14.674H5.31122V13.4664ZM5.31122 15.5772H11.9796V16.7848H5.31122V15.5772Z"
      fill="white"
    />
  </svg>
);

export default Icon;
