import React, { FC } from 'react';
import {
  Box,
  Button,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDRApproveConfirmationModal } from './controller';
import {
  LabelAndValue,
  Popup,
  RequestApproveSummaryBlock,
  ToolTipLine,
  WysiwygEditor,
} from '@components';
import { currencyFormatter, percentFormatter } from '@utils';
import { colors, typography } from '@theme';
import {
  ApproveActionEnum,
  IDrawRequest,
  IRequestReviewParams,
  ToolTipLineVariantEnum,
} from '@interfaces';
import { FinalApproveIcon, ForApprovalIcon } from '@svgAsComponents';
import { useLaunchDarklyFlags } from '@context';

interface ComponentProps {
  open: boolean;
  confirmCallback: (params: IRequestReviewParams) => Promise<void>;
  onClose: () => void;
  amountToDisburse: number;
  drNumber: number;
  type?: string;
  borrowerEquity: number;
  request: IDrawRequest;
  approveActionType?: ApproveActionEnum;
  isMutating?: boolean;
  isFinalApprove?: boolean;
  projectId: string;
  drawRequestId: string;
  retainageRate: number;
  retainageHeldback: number;
  retainageReleased: number;
  feesAmount: number;
}

const DRApproveConfirmationModal: FC<ComponentProps> = ({
  open,
  confirmCallback,
  onClose,
  amountToDisburse,
  drNumber,
  type,
  borrowerEquity,
  request,
  isMutating,
  isFinalApprove,
  projectId,
  drawRequestId,
  approveActionType,
  retainageRate,
  retainageHeldback,
  retainageReleased,
  feesAmount,
}) => {
  const {
    comment,
    nextTeam,
    setNextTeam,
    teamsList,
    findNextTeamName,
    popupText,
    teamsIsLoading,
    approveActionLabel,
  } = useDRApproveConfirmationModal({ request, type, isFinalApprove, approveActionType });
  const flags = useLaunchDarklyFlags();
  const approvedAmount = request?.totals?.current?.approved_amount;

  return (
    <Popup
      open={open}
      text={popupText}
      title={`${approveActionLabel} ${type} #${drNumber}`}
      icon={approveActionType === ApproveActionEnum.FUND ? FinalApproveIcon : ForApprovalIcon}
    >
      <Stack sx={{ width: '100%' }} spacing={1}>
        <RequestApproveSummaryBlock
          showShortVersion
          projectId={projectId}
          drawRequestId={drawRequestId}
          source="request__action_popup__confirmation_modal"
        />

        {type === 'Draw' && (
          <>
            <Box>
              <Divider sx={{ my: '16px' }} />
            </Box>
            <LabelAndValue
              label="Borrower equity contribution"
              text={currencyFormatter(approvedAmount ? borrowerEquity : 0)}
              tooltipText="Portion of draw to be contributed towards total Borrower's Equity"
              textDataTestName="request__action_popup__confirmation_modal__borrower_equity__value"
            />
            <LabelAndValue
              label="Approved construction holdback draw"
              text={currencyFormatter(approvedAmount ? approvedAmount - borrowerEquity : 0)}
              tooltipText="Portion of draw approved to be disbursed from the Construction Holdback"
              textDataTestName="request__action_popup__confirmation_modal__construction_holdback__value"
            />
            {Boolean(retainageRate) && (
              <>
                <LabelAndValue
                  label={`Retainage heldback for this draw (${percentFormatter({
                    value: retainageRate,
                  })})`}
                  text={currencyFormatter(retainageHeldback * -1 || 0)}
                  textDataTestName="request__action_popup__confirmation_modal__retainage_approved_amount_holdback__value"
                />
                <LabelAndValue
                  label="Approved retainage release"
                  text={currencyFormatter(retainageReleased)}
                  textDataTestName="request__action_popup__confirmation_modal__retainage_release_approved__value"
                />
              </>
            )}

            <LabelAndValue
              label="Draw fees"
              text={currencyFormatter(feesAmount * -1 || 0)}
              tooltipText="Fees to be deducted from approved draw"
              textDataTestName="request__action_popup__confirmation_modal__fees__value"
            />
            <LabelAndValue
              label="Estimated net funds to disburse"
              text={currencyFormatter(approvedAmount ? amountToDisburse : 0)}
              tooltipText="Approved construction holdback draw amount minus draw fees"
              textDataTestName="request__action_popup__confirmation_modal__funds_to_disburse__value"
            />
            {Boolean(retainageRate) && (
              <ToolTipLine
                variant={ToolTipLineVariantEnum.INFORMATION}
                typographyVariant="body2"
                text="Retainage based on total draw, excluding new line items. Amounts are subject to
              change prior to disbursement."
              />
            )}
          </>
        )}

        {!isFinalApprove &&
          request?.next_reviewer_team_elevator?.id &&
          !flags?.['ENG_8495_new_approval_flow'] && (
            <>
              <Box>
                <Divider sx={{ my: '16px' }} />
              </Box>
              {Boolean(teamsList?.length) && (
                <>
                  <InputLabel
                    id="next-team"
                    sx={{ ...typography.label, color: colors.text.medium }}
                  >
                    Next reviewer team
                  </InputLabel>
                  <Select
                    labelId="next-team"
                    id="next-team"
                    value={nextTeam}
                    onChange={(event: SelectChangeEvent) => setNextTeam(event.target.value)}
                    sx={{ '&&&': { mb: '16px', mt: 0 } }}
                    size="small"
                    data-cy="request__action_popup__confirmation_modal__next_reviewer_team__input"
                  >
                    {teamsList?.map((team) => (
                      <MenuItem value={team.id} key={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </>
          )}
        <Stack>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <InputLabel id="comment" sx={{ ...typography.label, color: colors.text.medium }}>
            Comment
          </InputLabel>
          <WysiwygEditor
            editField={comment}
            source="request__action_popup__confirmation_modal__comment"
          />
        </Stack>
        <Stack sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: '60px' }}>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={isMutating || teamsIsLoading}
              onClick={
                isFinalApprove
                  ? () => confirmCallback({ notes: comment.value })
                  : () =>
                      confirmCallback({
                        ...(flags?.['ENG_8495_new_approval_flow']
                          ? {}
                          : { teamName: findNextTeamName(nextTeam), teamId: nextTeam }),
                        notes: comment.value,
                      })
              }
              data-cy="draw_request_modal_confirm_button"
            >
              {approveActionLabel}
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default DRApproveConfirmationModal;
