import React, { createContext, useState } from 'react';

const initSSOData = {
  issuer: '',
  clientId: '',
  redirectUri: '',
  setOktaAuth: (_oktaAuth) => {},
};

const SSOContext = createContext(initSSOData);

const SSOProvider = ({ children }) => {
  const localSSOConfig = JSON.parse(sessionStorage.getItem('ssoConfig') || 'null');
  const localSSOType = JSON.parse(sessionStorage.getItem('ssoType') || 'null');
  const [ssoConfig, setSSOConfig] = useState(localSSOConfig);
  const [ssoType, setSSOType] = useState(localSSOType);
  const clearSSOData = () => {
    setSSOConfig(null);
    setSSOType(null);
  };

  return (
    <SSOContext.Provider
      value={{
        ssoConfig,
        ssoType,
        setSSOConfig,
        setSSOType,
        clearSSOData,
      }}
    >
      {children}
    </SSOContext.Provider>
  );
};

export { SSOContext, SSOProvider };
