import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useUrlParams = <T>(
  initialState: T,
  paramsName: string,
  serialize: (state: T) => string,
  deserialize: (state: string) => T,
): [T, (state: T) => void, (state: T, skipSetUrl?: boolean) => void, existingValue: string] => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const existingValue = queryParams.get(paramsName);
  const [state, setState] = useState<T>(existingValue ? deserialize(existingValue) : initialState);

  useEffect(() => {
    // Updates state when user navigates backwards or forwards in browser history
    if (existingValue && deserialize(existingValue) !== state) {
      setState(deserialize(existingValue));
    }
  }, [existingValue]);

  const onChange = (s: T) => {
    setState(s);
    const url = new URL(window.location.href);
    if (!s) {
      url.searchParams.delete(paramsName);
    } else {
      url.searchParams.set(paramsName, serialize(s));
    }
    window.history.pushState({}, '', url);
  };

  const onSetValue = (state: T, skipSetUrl?: boolean) => {
    setState((data) => {
      const newState = { ...data, ...state };
      if (!skipSetUrl) {
        const url = new URL(window.location.href);
        if (!newState) {
          url.searchParams.delete(paramsName);
        } else {
          url.searchParams.set(paramsName, serialize(newState));
        }
        window.history.pushState({}, '', url);
      }
      return newState;
    });
  };

  return [state, onChange, onSetValue, existingValue];
};
