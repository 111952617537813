import React, { FC } from 'react';

import { StatePageLayout } from '../../layouts';
import InactiveUser from 'encirclelabs-assets/inactive_user.svg';

const InactiveUserPage: FC = () => {
  return (
    <StatePageLayout
      text={
        <>
          Your account is currently inactive. <br />
          <br />
          Please contact your lender or your company administrator for the update.
        </>
      }
      image={InactiveUser}
      showLogout
    />
  );
};

export default InactiveUserPage;
