import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 18 }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 18) * 24}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14994 0H11.1739L17.9745 7.08846V20.8756C17.9745 22.6027 16.5772 24 14.8561 24H3.14994C1.42281 24 0.0255127 22.6027 0.0255127 20.8756V3.12443C0.0254824 1.3973 1.42278 0 3.14994 0V0Z"
      fill="#D02C2B"
    />
    <path
      opacity="0.302"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1679 0V7.03449H17.9745L11.1679 0Z"
      fill="white"
    />
    <path
      d="M3.49774 17.907V13.5232H5.36282C5.82459 13.5232 6.19042 13.6492 6.46627 13.907C6.74213 14.1589 6.88007 14.5007 6.88007 14.9265C6.88007 15.3523 6.74213 15.6941 6.46627 15.946C6.19042 16.2039 5.82459 16.3298 5.36282 16.3298H4.61918V17.907H3.49774ZM4.61918 15.3763H5.23688C5.40478 15.3763 5.53672 15.3403 5.62669 15.2564C5.71663 15.1784 5.76464 15.0705 5.76464 14.9266C5.76464 14.7826 5.71667 14.6747 5.62669 14.5967C5.53675 14.5128 5.40481 14.4768 5.23688 14.4768H4.61918V15.3763ZM7.34182 17.907V13.5232H8.89504C9.20089 13.5232 9.48875 13.5652 9.7586 13.6552C10.0285 13.7451 10.2744 13.8711 10.4902 14.045C10.7061 14.2129 10.88 14.4408 11.006 14.7286C11.1259 15.0165 11.1919 15.3463 11.1919 15.7181C11.1919 16.084 11.1259 16.4138 11.006 16.7016C10.88 16.9895 10.7061 17.2174 10.4902 17.3853C10.2743 17.5592 10.0285 17.6851 9.7586 17.7751C9.48875 17.865 9.20089 17.907 8.89504 17.907H7.34182ZM8.43927 16.9535H8.7631C8.93701 16.9535 9.09894 16.9355 9.24886 16.8935C9.39278 16.8516 9.53072 16.7856 9.66266 16.6957C9.78859 16.6057 9.89054 16.4797 9.9625 16.3118C10.0345 16.1439 10.0705 15.946 10.0705 15.7181C10.0705 15.4842 10.0345 15.2863 9.9625 15.1184C9.89054 14.9505 9.78859 14.8246 9.66266 14.7346C9.53072 14.6447 9.39281 14.5787 9.24886 14.5367C9.09894 14.4948 8.93701 14.4768 8.7631 14.4768H8.43927V16.9535ZM11.7556 17.907V13.5232H14.8741V14.4768H12.8771V15.1784H14.4722V16.1259H12.8771V17.907H11.7556Z"
      fill="white"
    />
  </svg>
);

export default Icon;
