import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';

export const useDebouncedEffect = (effect, deps, delay) => {
  const isMounted = useRef(false);

  useEffect(() => {
    let handler;
    if (isMounted.current) {
      handler = setTimeout(() => {
        effect();
      }, delay);
    } else {
      isMounted.current = true;
    }

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [...(deps || []), delay]);
};

interface DebounceInput {
  effect: (options?: Record<string, boolean | string | number>) => void | Promise<void>;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  resetter?: () => void;
  delay?: number;
  autoDebouce?: boolean;
  afterEffect?: () => void;
}

export const useDebounceInput = ({
  effect,
  handleChange,
  resetter,
  delay = 500,
  autoDebouce,
  afterEffect,
}: DebounceInput) => {
  const autoDebouceMemo = useMemo(() => autoDebouce, [autoDebouce]);
  const isEffectDone = useRef<boolean>(false);
  const handler = useRef<NodeJS.Timeout>();

  const onBlur = useCallback(async () => {
    try {
      if (isEffectDone?.current) return;
      if (autoDebouceMemo) clearTimeout(handler.current);
      await effect({ needToCompare: true });
    } finally {
      afterEffect();
    }
  }, [effect]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    resetter?.();
    handleChange(e);
    isEffectDone.current = false;
    if (handler.current) {
      clearTimeout(handler.current);
    }
    if (autoDebouceMemo)
      handler.current = setTimeout(() => {
        effect({ value: parseFloat(e.target.value || '0') });
        isEffectDone.current = true;
      }, delay);
  };

  return {
    onBlur,
    onChange,
  };
};
