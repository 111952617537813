import { FilterObject } from '../interface';

const onHoldValues = {
  ['Yes']: 'true',
  ['No']: 'flase',
};

export const isOnHoldFilter: FilterObject = {
  title: 'On hold',
  filterKey: 'is_on_hold',
  defaultFilters: () => Object.values(onHoldValues),
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getStaticValues: () =>
    Object.keys(onHoldValues).map((label) => ({ label, value: onHoldValues[label] })),
  cypressSelector: 'filters__is_on_hold',
};
