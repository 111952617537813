import { getRequestRetainageRate } from '@utils';
import { IDrawRequest, QueryNamesEnums } from '@interfaces';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { excludeCommentsWithTotalsAllQueryFields } from '@constants';
import { getDrawRequest } from '@globalService';

export interface ControllerInterface {
  amountToDisburse: number;
  borrowerEquity: number;
  retainageRate: number;
  feesAmount: number;
  drawRequest: IDrawRequest;
}

export const usePaymentInfo = ({
  projectId,
  drawRequestId,
}: {
  projectId: string;
  drawRequestId: string;
}): ControllerInterface => {
  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
    { enabled: Boolean(drawRequestId), staleTime: 0 },
  );

  const drawRequest = drawRequestQuery.data;

  const borrowerEquity = useMemo(
    () => drawRequest?.borrower_equity,
    [drawRequest?.borrower_equity],
  );

  const retainageRate = useMemo(() => getRequestRetainageRate(drawRequest), [drawRequest]);

  const amountToDisburse = useMemo(
    () => drawRequest?.construction_holdback_with_fees || 0,
    [drawRequest?.construction_holdback_with_fees],
  );

  const feesAmount = useMemo(() => drawRequest?.fees_amount || 0, [drawRequest?.fees_amount]);

  return {
    amountToDisburse,
    retainageRate,
    borrowerEquity,
    feesAmount,
    drawRequest,
  };
};
