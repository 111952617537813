import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CellProps, UserListColumnType } from './common';
import { AuthContext } from '@context';
import { UserInfoLink } from '@components';

const UserNameCell: FC<CellProps> = ({ row }) => {
  const { user } = useContext(AuthContext);
  const { companyId } = useParams();

  return (
    <UserInfoLink
      name={`${row.first_name || ''} ${row.last_name || ''} ${user.id === row.id ? ' (You)' : ''}`}
      userId={row.user || row.id}
      companyId={companyId || row.teams?.[0]?.company?.id}
    />
  );
};

const name: UserListColumnType = {
  name: 'name',
  columnText: 'Name',
  width: '18%',
  renderCell: ({ row }) => <UserNameCell row={row} />,
};

export default name;
