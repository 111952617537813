import { useContext, Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';

import { QueryNamesEnums, ITokens, IUser, IUISettings } from '@interfaces';
import { getUISettings } from '@globalService';
import { useLogin } from '@hooks';
import { AuthContext } from '@context';

export interface LoginPayload {
  id_token?: string;
}
export interface ControllerInterface {
  handleLogin: (params: LoginPayload) => Promise<ITokens>;
  googleClientId: string;
  setUser: Dispatch<SetStateAction<IUser>>;
  isSettingsLoading: boolean;
}

export const useGoogleLogin = (): ControllerInterface => {
  const { postLoginData } = useLogin({});

  const { setUser } = useContext(AuthContext);

  const settingsQuery = useQuery<IUISettings, Error>(
    [QueryNamesEnums.GET_UI_SETTINGS_INITIAL],
    getUISettings.bind(this),
  );

  const handleLogin = async (params) => postLoginData.mutateAsync(params);

  return {
    handleLogin,
    googleClientId: settingsQuery.data?.google_client_id,
    isSettingsLoading: settingsQuery.isLoading,
    setUser,
  };
};
