import React from 'react';
import { UserListColumnType } from './common';
import { UserStatusChip } from '@components';

const status: UserListColumnType = {
  name: 'status',
  columnText: 'Status',
  width: '8%',
  renderCell: ({ row }) => <UserStatusChip user={row} />,
};

export default status;
