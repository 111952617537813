import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const DensityIcon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon / Density">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 22L5.5 22C4.11929 22 3 20.8807 3 19.5L3 18.5C3 17.1193 4.11929 16 5.5 16L18.5 16C19.8807 16 21 17.1193 21 18.5L21 19.5C21 20.8807 19.8807 22 18.5 22ZM18.5 20C18.7761 20 19 19.7761 19 19.5L19 18.5C19 18.2239 18.7761 18 18.5 18L5.5 18C5.22386 18 5 18.2239 5 18.5L5 19.5C5 19.7761 5.22386 20 5.5 20L18.5 20ZM18.5 15L5.5 15C4.11929 15 3 13.8807 3 12.5L3 11.5C3 10.1193 4.11929 9 5.5 9L18.5 9C19.8807 9 21 10.1193 21 11.5L21 12.5C21 13.8807 19.8807 15 18.5 15ZM18.5 13C18.7761 13 19 12.7761 19 12.5L19 11.5C19 11.2239 18.7761 11 18.5 11L5.5 11C5.22386 11 5 11.2239 5 11.5L5 12.5C5 12.7761 5.22386 13 5.5 13L18.5 13ZM5.5 8L18.5 8C19.8807 8 21 6.88071 21 5.5L21 4.5C21 3.11929 19.8807 2 18.5 2L5.5 2C4.11929 2 3 3.11929 3 4.5L3 5.5C3 6.88071 4.11929 8 5.5 8ZM19 5.5C19 5.77614 18.7761 6 18.5 6L5.5 6C5.22386 6 5 5.77614 5 5.5L5 4.5C5 4.22386 5.22386 4 5.5 4L18.5 4C18.7761 4 19 4.22386 19 4.5L19 5.5Z"
        fill={color}
      />
    </g>
  </svg>
);

export default DensityIcon;
