import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 21, color = colors.icons.gray }: ComponentProps) => (
  <svg width={size} height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3121 11.7007C13.6991 12.0947 14.3322 12.1004 14.7263 11.7134L19.8172 6.71345C20.0086 6.52542 20.1165 6.26834 20.1165 6C20.1165 5.73166 20.0086 5.47458 19.8172 5.28655L14.7263 0.286551C14.3322 -0.100439 13.6991 -0.0947351 13.3121 0.299292C12.9251 0.693318 12.9308 1.32646 13.3248 1.71345L16.6711 5L1.11645 5C0.56417 5 0.116455 5.44771 0.116455 6C0.116455 6.55228 0.56417 7 1.11645 7L16.6711 7L13.3248 10.2866C12.9308 10.6735 12.9251 11.3067 13.3121 11.7007Z"
      fill={color}
    />
  </svg>
);

export default Icon;
