const csvmaker = function (data) {
  const csvRows = [];
  const headers = Object.keys(data);
  const values = Object.values(data).join(',');

  csvRows.push(headers.join(','));
  csvRows.push(values);

  return csvRows.join('\n');
};

export const generateCSV = async function (data: Record<string, string>) {
  const csvdata = csvmaker(data);

  return new Blob([csvdata], { type: 'text/csv;charset=utf-8,' });
};
