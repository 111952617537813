import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M2.5 12C2.5 17.5228 6.97715 22 12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12ZM4.5 12C4.5 7.58172 8.08172 4 12.5 4C16.9183 4 20.5 7.58172 20.5 12C20.5 16.4183 16.9183 20 12.5 20C8.08172 20 4.5 16.4183 4.5 12ZM12.6094 8.3359C11.9957 7.92676 11.2066 7.88861 10.5563 8.23665C9.90597 8.58469 9.5 9.26241 9.5 10V14C9.5 14.7376 9.90597 15.4153 10.5563 15.7633C11.2066 16.1114 11.9957 16.0732 12.6094 15.6641L15.6094 13.6641C16.1658 13.2932 16.5 12.6687 16.5 12C16.5 11.3313 16.1658 10.7068 15.6094 10.3359L12.6094 8.3359ZM12.6972 13.2019L11.5 14V12.4037V11.5963V10L12.6972 10.7981L13.5 11.3333L14.5 12L13.5 12.6667L12.6972 13.2019Z"
    />
  </svg>
);

export default Icon;
