import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 14, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8533 2C7.33042 2 2.85327 6.47715 2.85327 12C2.85327 17.5228 7.33042 22 12.8533 22C18.3761 22 22.8533 17.5228 22.8533 12C22.8533 6.47715 18.3761 2 12.8533 2ZM4.85327 12C4.85327 7.58172 8.43499 4 12.8533 4C17.2715 4 20.8533 7.58172 20.8533 12C20.8533 16.4183 17.2715 20 12.8533 20C8.43499 20 4.85327 16.4183 4.85327 12ZM11.9017 7.30902C12.2011 6.38771 13.5045 6.3877 13.8038 7.30902L14.5631 9.6459H17.0203C17.989 9.6459 18.3918 10.8855 17.608 11.4549L15.6202 12.8992L16.3795 15.2361C16.6788 16.1574 15.6243 16.9235 14.8406 16.3541L12.8528 14.9098L10.8649 16.3541C10.0812 16.9235 9.0267 16.1574 9.32605 15.2361L10.0854 12.8992L8.09748 11.4549C7.31377 10.8855 7.71654 9.6459 8.68527 9.6459H11.1424L11.9017 7.30902Z"
      fill={color}
    />
  </svg>
);

export default Icon;
