import ky from 'ky';

import { debug, parsePathErrorDual, getRefreshTokenExpirationTime } from '@utils';
import { setHeaders } from './auth';
import { ServerErrorCodesEnum } from '@interfaces';
import * as Sentry from '@sentry/react';
import { refreshTokens } from '@globalService';

export default (baseUrl, customHeaders = []) => {
  return ky.create({
    credentials: 'include',
    prefixUrl: `${baseUrl}`,
    timeout: 30000,
    retry: {
      limit: 2,
      methods: ['get', 'post', 'put', 'delete', 'patch'],
      statusCodes: [408, 413, 429, 502, 503, 504, 401],
    },
    hooks: {
      beforeRequest: [setHeaders(customHeaders)],
      afterResponse: [
        async (_request, _options, response) => {
          if (!response.ok) {
            const error = await response.json();
            // sometimes in beforeRetry hook error.response doesn't contain status
            // so we check 401 status here also just to double check
            if (response?.status === 401) {
              // not to ask for refresh token if it's login auth error (wrong credentials)

              if (
                error?.code === ServerErrorCodesEnum.NOT_AUTHENTICATED ||
                error?.code === ServerErrorCodesEnum.TOKEN_NOT_VALID
              ) {
                refreshTokenFunction();
              } else throw error;

              return;
            }
            if (
              response?.status === 403 &&
              error?.code === ServerErrorCodesEnum.PROFILE_DEACTIVATED
            ) {
              localStorage.clear();
              sessionStorage.clear();
              window.location.replace(`${window.location.origin}/deactivated-account`);
              return;
            }

            const errorText = error?.message || parsePathErrorDual(error);
            Sentry.captureException(new Error(errorText));
            throw error;
          }
          return response;
        },
      ],
      beforeRetry: [
        async ({ error }) => {
          if (error?.response?.status === 401) {
            refreshTokenFunction();
            return;
          }
          if (error) debug({ error });
        },
      ],
    },
  });
};

const refreshTokenFunction = () => {
  // not to ask for refresh token if user already on login page
  if (window.location.href.includes('login')) return;

  refreshTokens()
    .then(() => {
      localStorage.setItem('tokenExpirationTime', getRefreshTokenExpirationTime());
    })
    .catch(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload(true);
    });
};
