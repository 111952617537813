import { useGridApiRef } from '@mui/x-data-grid-premium';
import useTableSettings from './useTableSettings';
import useTableScrollPin from './useTableScrollPin';
import useTableState from './useTableState';
import useTableDataUpdate from './useTableDataUpdate';
import { UseTableInterface } from '../interface';

const useDataTable = ({
  withoutAdaptiveScroll,
  tableKey,
  errors,
  rowUpdateApi,
  rows,
  totals,
  columns,
  bulkUpdateApi,
}: UseTableInterface) => {
  const apiRef = useGridApiRef();
  const tableScroll = useTableScrollPin(apiRef, withoutAdaptiveScroll, rows.length);
  const state = useTableState(apiRef, columns, rows, tableScroll.maxRowCount, errors, totals);
  const settings = useTableSettings(
    tableKey,
    apiRef,
    state.setTableLoading,
    state.defaultColumnOrder,
  );
  const update = useTableDataUpdate(state.setTableLoading, rowUpdateApi, bulkUpdateApi);

  return {
    apiRef,
    ...settings,
    ...tableScroll,
    ...state,
    ...update,
  };
};

export default useDataTable;
