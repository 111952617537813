import { IProjectDocument, MediaFile } from '@interfaces';
import { useCallback, useState } from 'react';

export const useImagePicker = (): ImagePicker => {
  const [pdf, setPdf] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [startIndex, setStartIndex] = useState<number>(0);

  return {
    pdf,
    gallery,
    startIndex,
    open: useCallback((payload: IProjectDocument[], startIndex) => {
      const files = payload.map((fileData) => {
        const fileLink = fileData?.file_representations;
        return {
          id: fileData?.id,
          name: fileData?.name,
          document_type: fileData?.document_type,
          file_url: fileLink?.large?.url || fileLink?.original?.url || fileData?.file,
          mime_type:
            fileLink?.large?.mime_type || fileLink?.original?.mime_type || fileData?.mime_type,
          metadata: fileData?.metadata,
          has_smart_summary: fileData?.has_smart_summary,
        };
      });
      if (
        files?.every(
          (file) => file?.mime_type === 'application/pdf' || file?.file_url?.includes('.pdf'),
        )
      ) {
        setPdf(files);
      } else if (files?.length) {
        setGallery(files);
      }
      setStartIndex(startIndex || 0);
    }, []),
    close: useCallback(() => {
      setPdf(null);
      setGallery(null);
    }, []),
  };
};

export interface ImagePicker {
  startIndex: number;
  pdf: MediaFile[];
  gallery: MediaFile[];
  open: (p: any[], startIndex?: number) => void;
  close: () => void;
}
