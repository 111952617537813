import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Typography } from '@mui/material';
import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import { currencyFormatter, isRestricted, notAllowNegative } from '@utils';
import { ColumnV2Width, IMilestoneTotal, PermissionNamesEnums } from '@interfaces';
import { PermissionsContext } from '@context';
import { TableContext } from '../controller';
import { useDebounceInput, useStringFieldModel } from '@models';
import { useParams } from 'react-router-dom';
import { TextInputWithTooltip } from '@components';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  // ENG-5525 edit can be done only for budget so no requestId
  const { requestId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const { apiUpdate, source, getLineItemError } = useContext(TableContext);
  const error = getLineItemError(row.id, 'original_estimate');
  const original_estimate = useStringFieldModel({
    initError: error,
    initValue: (row['original_estimate'] || '').toString(),
    blockNavigationKey: 'original_estimate',
    withProgressCheck: true,
  });

  const isEditable = useMemo(
    () =>
      !requestId &&
      row.activeToEdit &&
      row.localNew &&
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row, requestId],
  );

  const effect = useCallback(
    (props) =>
      apiUpdate({
        milestoneId: row.id,
        value: original_estimate.floatValue,
        name: 'original_estimate',
        ...props,
      }),
    [original_estimate.floatValue],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: original_estimate.handleChange,
    afterEffect: () => original_estimate.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <TextInputWithTooltip
      sx={{ width: '100%', maxWidth: 400 }}
      value={original_estimate.value}
      onBlur={onBlur}
      onChange={onChange}
      type="currency"
      error={Boolean(error)}
      errorTip={error}
      dataTestName={`original_estimate_index_${row.index}`}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
    />
  ) : (
    <Typography
      variant="body3"
      data-cy={`${source}__body__original_estimate__value__index_${row?.project_milestone?.index}`}
    >
      {currencyFormatter(row.original_estimate, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__original_estimate__value`}>
      {currencyFormatter(row.original_estimate)}
    </Typography>
  );
};

const originalEstimate: MilestoneListColumnType = {
  name: 'original_estimate',
  columnText: 'Original scheduled value ($)',
  isEditable: (permissions) =>
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Original construction budget post funding">
      <ColumnLabel>Original</ColumnLabel>
      <ColumnLabel> scheduled value($)</ColumnLabel>
    </HeaderText>
  ),
};

export default originalEstimate;
