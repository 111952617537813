import { SettingsContext, useGetData, useGetListData } from '@context';
import {
  ErrorDual,
  IMilestone,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  PatchDrawRequestMSGroupParam,
  QueryNamesEnums,
} from '@interfaces';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useIsMutating, useMutation } from 'react-query';
import { patchDrawRequestListItem, patchDrawRequestMilestoneGroup } from '@globalService';
import sortBy from 'lodash/sortBy';
import { isLineItemViewByURL, usePHBGrouping } from '@utils';
import { useLocation } from 'react-router-dom';

export const useApproveCredit = ({ requestId, milestoneId, setOpen, onCreditChangeCompleted }) => {
  const { isPHBProject } = useContext(SettingsContext);
  const { unitLineItemGrouping, lineItemUnitGrouping } = usePHBGrouping();
  const { settings } = useContext(SettingsContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const isLineItemView = useMemo(
    () => isLineItemViewByURL({ params, settings }),
    [params.get('view'), settings.personal_setting?.PHB_TABLE_VIEW?.view_type],
  );
  const group_by = useMemo(
    () => (isLineItemView ? lineItemUnitGrouping : unitLineItemGrouping),
    [isLineItemView, lineItemUnitGrouping, unitLineItemGrouping],
  );

  const [updateData, setUpdateData] = useState<Record<string, number>>({});
  const { projectId } = useParams();
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'id'],
    args: { projectId },
  });

  const milestone = useGetData({
    type: isPHBProject
      ? QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP
      : QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: [
      'credit_reason',
      'id',
      'name',
      'approved_credit_amount',
      'requested_credit_amount',
      'tags',
    ],
    args: {
      projectId,
      drawRequestId: requestId,
      milestoneId,
      group_by,
    },
    options: {
      skip: true,
    },
  });

  const milestonesForCredit = useGetListData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
    keys: [
      'requested_credit_amount',
      'credit_reason',
      'id',
      'name',
      'approved_credit_amount',
      'index',
      'tags',
    ],
    args: {
      projectId,
      drawRequestId: requestId,
      groupBy: group_by,
    },
    options: {
      skip: milestoneId,
      strictSerialize: (data) =>
        sortBy(
          data.filter((item) => item.requested_credit_amount || item.approved_credit_amount),
          ['index'],
        ),
    },
  });

  const milestones = useMemo(
    () => (milestoneId ? (milestone.data ? [milestone.data] : []) : milestonesForCredit.serialized),
    [milestone, milestoneId, milestonesForCredit],
  );

  const updateMilestone = (data: IMilestone) => {
    milestone.manualUpdate({
      approved_credit_amount: data.approved_credit_amount,
      name: data.name,
      id: data.id,
    });
    onCreditChangeCompleted && onCreditChangeCompleted(data);
  };

  const patchMilestoneRequestMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      updateMilestone(data);
    },
  });

  const patchDrawRequestProdBuildGroupMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestMSGroupParam
  >(patchDrawRequestMilestoneGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      updateMilestone(data);
    },
  });

  const submit = useCallback(async () => {
    if (Object.keys(updateData).length === 0) return;
    try {
      await Promise.all(
        Object.keys(updateData).map((key) => {
          if (key) {
            if (isPHBProject)
              patchDrawRequestProdBuildGroupMutation.mutate({
                project: projectId,
                drawRequest: requestId,
                group_by,
                milestoneId: key,
                json: {
                  approved_credit_amount: +updateData[key],
                },
              });
            else
              patchMilestoneRequestMutation.mutate({
                project: projectId,
                drawRequest: requestId,
                milestone: key,
                json: {
                  approved_credit_amount: +updateData[key],
                },
              });
          }
        }),
      );
    } catch (e) {
      console.log(e);
    }
    setOpen(false);
  }, [requestId, projectId, milestoneId, updateData, group_by, milestone.data, isPHBProject]);

  const isMutating = useIsMutating();

  useEffect(() => {
    if (milestoneId) milestone.refetch();
  }, [milestoneId]);

  return {
    name: project.data.name,
    submit,
    isSubmiting: false,
    isLoading: milestonesForCredit.isLoading,
    milestones,
    setUpdateData,
    isMutating,
  };
};
