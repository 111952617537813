import React, { FC } from 'react';
import { Stack, Typography, Skeleton } from '@mui/material';

import { ComponentProps, ComponentWithPreloadProps } from './interface';
import { currencyFormatter } from '@utils';
import { colors } from '@theme';

const types = {
  amount: {
    variant: 'h2',
    value: (value) => currencyFormatter(value),
    pb: '3px',
  },
  text: {
    variant: 'h3',
    value: (value) => value,
    pb: '2px',
  },
};
export const ValueAndLabel: FC<ComponentProps> = ({
  value = '',
  label,
  color = colors.text.heading,
  type = 'amount',
}) => {
  return (
    <Stack direction="row" alignItems="flex-end">
      <Typography variant={types[type].variant} sx={{ color }}>
        {types[type].value(value)}
      </Typography>

      <Typography
        variant="labelSemiBold"
        sx={{ pl: '3px', pb: types[type].pb, color: colors.text.medium }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export const ValueAndLabelWithPreload: FC<ComponentWithPreloadProps> = ({
  isLoading,
  ...props
}) => {
  return isLoading ? <Skeleton sx={{ my: 1 }} /> : <ValueAndLabel {...props} />;
};
