import React from 'react';
import Lottie from 'react-lottie';

import animatedRedirect from 'encirclelabs-assets/animated_redirect.json';

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: animatedRedirect,
};

const AnimatedRedirect = () => {
  return <Lottie options={defaultAnimationOptions} width="500px" speed={2} />;
};

export default AnimatedRedirect;
