import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Skeleton, Typography } from '@mui/material';
import {
  approximationEqual,
  canBeEdited,
  checkIsAdmin,
  checkIsInvestor,
  checkIsLender,
  currencyFormatter,
  getMilestoneGroupsTagsIds,
  getTeamRole,
  notAllowNegative,
  useBlockerCell,
  useBlockerFooter,
} from '@utils';
import { TextInputWithTooltip } from '@components';
import { AuthContext, SettingsContext } from '@context';
import { TableContext } from '../controller';
import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import { useDebounceInput, useStringFieldModel } from '@models';
import { ColumnV2Width, IMilestoneTotal, TableKeyEnum } from '@interfaces';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const { apiUpdate, tableKey, resetMutation, contingencyMode, source, getLineItemError } =
    useContext(TableContext);
  const error = getLineItemError(row.id, 'approved_amount_cumulative');

  const approved_amount_cumulative = useStringFieldModel({
    initError: error,
    initValue: (row['approved_amount_cumulative'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'approved_amount_cumulative',
  });
  const { isPHBProject } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const tags = useMemo(() => getMilestoneGroupsTagsIds(row.tags), [row.tags]);
  const isBlocked = useBlockerCell({
    milestoneId: row.id,
    key: 'approved_amount_cumulative',
    tags,
  });

  const isEditable = useMemo(
    () =>
      canBeEdited({ row, contingencyMode, adjustments_field: 'approved_adjustments', error }) &&
      tableKey !== TableKeyEnum.CHANGE_REQUEST &&
      (checkIsLender(teamRole) || checkIsInvestor(teamRole) || checkIsAdmin(teamRole)),
    [teamRole, row, contingencyMode],
  );

  const effect = useCallback(
    (props) =>
      apiUpdate({
        milestoneId: row.id,
        value: approved_amount_cumulative.floatValue,
        needToCompare: false,
        isEqualInit: approximationEqual(
          row.approved_amount_cumulative,
          approved_amount_cumulative.floatValue,
        ),
        name: 'approved_amount_cumulative',
        ...props,
      }),
    [approved_amount_cumulative.floatValue, row.approved_amount_cumulative],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: approved_amount_cumulative.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { approved_amount_cumulative: 0 } }),
    afterEffect: () => approved_amount_cumulative.changeNavigationBlockContext(false),
    delay: isPHBProject ? 1500 : 500,
  });

  if (isBlocked) return <Skeleton width={'100%'} />;
  return isEditable ? (
    <TextInputWithTooltip
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      onBlur={onBlur}
      value={approved_amount_cumulative.value}
      onChange={onChange}
      error={Boolean(error)}
      type="currency"
      tooltipText={row.disabled?.reason}
      disabled={row.disabled?.value}
      errorTip={error}
      dataTestName={`${source}__body__approved_amount_cumulative__value__index_${row?.project_milestone?.index}`}
    />
  ) : (
    <Typography
      variant="body3"
      data-cy={`${source}__body__approved_amount_cumulative__value__index_${row?.project_milestone?.index}`}
    >
      {currencyFormatter(row.approved_amount_cumulative, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography
      variant="body3SemiBold"
      data-cy={`${source}__footer__approved_amount_cumulative__value`}
    >
      {currencyFormatter(row.approved_amount_cumulative, '-')}
    </Typography>
  );
};

const approvedAmountCumulative: MilestoneListColumnType = {
  name: 'approved_amount_cumulative',
  columnText: 'Lender allowance ($)',
  isEditable: true,
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Cumulative amount approved to fund per Lender">
      <ColumnLabel>Lender</ColumnLabel>
      <ColumnLabel> allowance ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default approvedAmountCumulative;
