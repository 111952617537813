import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import {
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Popup } from '@components';
import { ILoanError, PopupTypeEnum } from '@interfaces';
import { useErrorsPopup } from './controller';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

interface LoanErrorsPopupProps {
  errors: ILoanError[];
  setErrors: Dispatch<SetStateAction<ILoanError[]>>;
}

const StyledCell = styled(TableCell)(() => ({
  border: 0,
  padding: '12px 0',
}));

const LoanErrorsPopupV2: FC<LoanErrorsPopupProps> = ({ errors, setErrors }) => {
  const { projectId } = useParams();
  const { budgetAmounts, loanAmounts } = useErrorsPopup();
  const navigate = useNavigate();
  const showTable = useMemo(() => errors?.find((item) => item.loan || item.budget), [errors]);

  if (!errors?.length) return null;

  return (
    <Popup open type={PopupTypeEnum.ERROR} title="Project activation error">
      <Stack sx={{ width: '100%' }}>
        <Stack spacing={2}>
          {errors.map((error, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
            >
              {error.loan_fields ? (
                <>
                  <br />
                  <b>{error.message}:</b> {error.loan_fields}
                </>
              ) : (
                error.message
              )}
            </Typography>
          ))}
        </Stack>
        {showTable && (
          <>
            <Divider sx={{ mt: 3, mb: 1 }} />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledCell />
                    <StyledCell>
                      <Typography variant="body2">Loan details</Typography>
                    </StyledCell>
                    <StyledCell sx={{ textAlign: 'center' }}>
                      <Typography variant="body2">Budget</Typography>
                    </StyledCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledCell>
                      <Typography variant="body2">Prefunding cost</Typography>
                    </StyledCell>
                    <StyledCell>
                      <Typography variant="body2SemiBold">{loanAmounts.prefundingCost}</Typography>
                    </StyledCell>
                    <StyledCell sx={{ textAlign: 'end' }}>
                      <Typography variant="body2SemiBold">
                        {budgetAmounts.prefundingCost}
                      </Typography>
                    </StyledCell>
                  </TableRow>
                  <TableRow>
                    <StyledCell>
                      <Typography variant="body2">Schedule of values</Typography>
                    </StyledCell>
                    <StyledCell>
                      <Typography variant="body2SemiBold">
                        {loanAmounts.scheduleOfValues}
                      </Typography>
                    </StyledCell>
                    <StyledCell sx={{ textAlign: 'end' }}>
                      <Typography variant="body2SemiBold">
                        {budgetAmounts.scheduleOfValues}
                      </Typography>
                    </StyledCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 7.5 }}>
          <Button
            variant="text"
            onClick={() => {
              setErrors(null);
            }}
            data-cy="project_activation_error__confirmation_dialog__cancel__button"
          >
            Cancel
          </Button>
          <Button
            variant="new"
            color="secondary"
            onClick={() => {
              setErrors(null);
              navigate(`/projects/${projectId}/budget`);
            }}
            data-cy="project_activation_error__confirmation_dialog__edit_budget__button"
          >
            Edit budget
          </Button>
          <Button
            onClick={() => navigate(`/projects/${projectId}/settings/loan`)}
            data-cy="project_activation_error__confirmation_dialog__edit_loan__button"
          >
            Edit loan
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default LoanErrorsPopupV2;
