import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.gray }: ComponentProps) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 18C19.1046 18 20 17.1046 20 16V6C20 5.44409 19.7732 4.94115 19.4071 4.57867C19.3858 4.53305 19.3608 4.48847 19.3321 4.4453L17.6289 1.8906C17.258 1.3342 16.6335 0.999999 15.9648 0.999999H9.2335L9.196 0.940001C8.83052 0.355239 8.18958 0 7.5 0H2C0.895432 0 0 0.895431 0 2L3.33387e-06 10.5L1.90735e-06 16C1.95372e-06 16.5304 0.210716 17.0391 0.585789 17.4142C0.960861 17.7893 1.46957 18 2 18H18ZM10.4835 3L11.1085 4L16.6315 4L15.9648 3H10.4835ZM18 16H16L4 16H2L2 14L2 10.5L2 4L2 2L4 2L7.5 2L9.70625 5.53C9.88899 5.82238 10.2095 6 10.5542 6L16 6H18V8V14V16ZM5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14H8C8.55229 14 9 13.5523 9 13C9 12.4477 8.55229 12 8 12H5ZM10 13C10 12.4477 10.4477 12 11 12H12C12.5523 12 13 12.4477 13 13C13 13.5523 12.5523 14 12 14H11C10.4477 14 10 13.5523 10 13Z"
      fill={color}
    />
  </svg>
);

export default Icon;
