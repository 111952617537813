import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.text.heading }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9381 3.76193C12.0739 2.89776 10.5441 2 8.66671 2C6.79445 2 5.29712 2.86011 4.42864 3.7286C3.70338 4.45386 2.95446 5.64795 2.73243 7.12291L2.47145 6.86193C2.2111 6.60158 1.78899 6.60158 1.52864 6.86193C1.26829 7.12228 1.26829 7.54439 1.52864 7.80474L2.86197 9.13807C3.10217 9.37828 3.48458 9.39945 3.74984 9.18725L5.4165 7.85391C5.70401 7.62391 5.75063 7.20438 5.52062 6.91687C5.29061 6.62936 4.87109 6.58275 4.58358 6.81275L4.0665 7.22642C4.25711 6.12011 4.827 5.21585 5.37144 4.6714C6.03376 4.00909 7.20563 3.33333 8.66671 3.33333C10.1226 3.33333 11.3287 4.03811 11.9953 4.70474C12.6576 5.36705 13.3334 6.53892 13.3334 8C13.3334 9.4559 12.6286 10.662 11.962 11.3286C11.2997 11.9909 10.1278 12.6667 8.66671 12.6667C8.29852 12.6667 8.00004 12.9651 8.00004 13.3333C8.00004 13.7015 8.29852 14 8.66671 14C10.539 14 12.0363 13.1399 12.9048 12.2714C13.7689 11.4072 14.6667 9.87744 14.6667 8C14.6667 6.12774 13.8066 4.63042 12.9381 3.76193ZM8.66671 5.66667C8.66671 5.29848 8.36823 5 8.00004 5C7.63185 5 7.33337 5.29848 7.33337 5.66667V8.58333C7.33337 8.77558 7.41636 8.95846 7.56104 9.08505L9.56104 10.8351C9.83813 11.0775 10.2593 11.0494 10.5018 10.7723C10.7442 10.4952 10.7161 10.0741 10.439 9.83162L8.66671 8.28082V5.66667Z"
      fill={color}
    />
  </svg>
);

export default Icon;
