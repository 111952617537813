import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({
  size = 24,
  color = colors.status.success.medium,
  checkMarkColor = colors.neutral.white,
  dataTestName = '',
}: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy={dataTestName}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z"
      fill={color}
    />
    <path
      d="M15.559 10.5473C15.8078 10.2929 15.7895 9.89753 15.518 9.66428C15.2466 9.43104 14.8249 9.44823 14.5761 9.70268L11.4248 12.9256L10.2464 11.5999C10.0107 11.3347 9.59031 11.2989 9.30746 11.5199C9.02461 11.7408 8.98639 12.1349 9.2221 12.4001L10.8888 14.2751C11.0119 14.4137 11.1929 14.4956 11.3852 14.4998C11.5775 14.5041 11.7624 14.4303 11.8923 14.2973L15.559 10.5473Z"
      fill={checkMarkColor}
    />
  </svg>
);

export default Icon;
