import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import find from 'lodash/find';
import map from 'lodash/map';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  DateValidationEnum,
  HookState,
  IInspection,
  IInspectionFields,
  IInspectionTotal,
  IMilestone,
  IPhoto,
  IProject,
  IProjectDocument,
  IRightDrawerParams,
  IRightMenu,
  IStatusChip,
  PermissionNamesEnums,
  QueryNamesEnums,
  RequestTableTabsEnum,
  ServiceActionEnum,
  ServiceOrderStatusEnum,
  TableKeyEnum,
  UpdateInspectionPayload,
} from '@interfaces';
import { DateFieldModel, useDateFieldModel } from '@models';
import {
  getInspectionDocuments,
  getInspectionMilestones,
  getProjectInspectionById,
  patchInspectionToProject,
} from '@globalService';
import {
  getDateValidationRule,
  getHookState,
  getInspectionNameByRole,
  getTeamRole,
  isRestricted,
  isTruePicInspection,
} from '@utils';
import {
  ImagePicker,
  useCommentsAndDocumentsPreview,
  useImagePicker,
  useInspectionFields,
  useLineItemsFilter,
  useRightMenu,
  useSafeSnackbar,
} from '@hooks';
import { AuthContext, PermissionsContext, SettingsContext, useGetData } from '@context';
import { LineItemFilterValues, onlyProgressPhotos, statusMap } from '@constants';

export interface ControllerInterface {
  inspectionCompletedAt: DateFieldModel;
  project: Partial<IProject>;
  state: HookState;
  inspectionRefetch: () => void;
  confirmCallBack: () => void;
  inspection: IInspection;
  showPreview: boolean;
  imagePicker: ImagePicker;
  imageContainer: React.MutableRefObject<HTMLElement>;
  setShowPreview: Dispatch<React.SetStateAction<boolean>>;
  openFile: () => void;
  initColumns: Array<string>;
  isEditable: boolean;
  goBack: () => void;
  inspectionPhotos: IPhoto[] | IProjectDocument[];
  milestones: IMilestone[];
  drawRequestNumber: string;
  inspectionName: string;
  rightMenu: IRightMenu;
  rightDrawerParams: IRightDrawerParams;
  updateRightDrawer: ({ tab }?: { tab?: RequestTableTabsEnum }) => () => void;
  totals: IInspectionTotal;
  filterValue: string;
  handleFiltersChange: (filterValue: string) => void;
  filterOptions: string[];
  isMilestoneMutatingOrFetching: boolean;
  isInspectionLinkedToDR: boolean;
  activeDocumentId: string;
  isApprovalPopupOpen: boolean;
  setIsApprovalPopupOpen: Dispatch<React.SetStateAction<boolean>>;
  statusChipProps: IStatusChip;
  isUpdatedStatusModalOpen: boolean;
  setIsUpdatedStatusModalOpen: Dispatch<React.SetStateAction<boolean>>;
  handleUpdateStatus: (status: ServiceOrderStatusEnum) => Promise<void>;
  isStatusEditable: boolean;
  inspectionFields: IInspectionFields;
  isTruePicInspection: boolean;
}

export const useInspectionEnterResult = (): ControllerInterface => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { inspectionId, action, projectId } = useParams();
  const { user } = useContext(AuthContext);
  const { settings } = useContext(SettingsContext);
  const teamRole = getTeamRole(user);
  const imagePicker = useImagePicker();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'id',
      'name',
      'address',
      'comments_preview',
      'status',
      'status_change_reason',
      'inspection_additional_contact_name',
      'inspection_additional_contact_phone',
      'inspection_entry_access_code',
      'inspection_primary_contact_user_id',
    ],
    args: { projectId },
  });

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUserNotRequired,
    primaryContactUserList,
    isBorrowerUsersLoading,
    inspectionRequestedAt,
    primaryContactPhone,
  } = useInspectionFields({
    project: project.data,
  });

  const inspectionCompletedAt = useDateFieldModel({
    initValue: null,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.LESS_OR_EQUAL,
        maxDate: new Date(),
      }),
  });
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isApprovalPopupOpen, setIsApprovalPopupOpen] = useState<boolean>(false);
  const imageContainer = useRef();
  const {
    filterValue,
    handleFilterClick,
    defaultOptions,
    isMilestoneMutatingOrFetching,
    filterKey,
  } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.INSPECTION_RESULTS,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    inspectionId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({
    projectId,
    inspectionId,
  });

  const updateRightDrawer =
    ({ tab }: { tab?: RequestTableTabsEnum }) =>
    () => {
      handleRightDrawerOpenerClick({
        title: tab ? 'Inspection' : 'Comments',
      });
      setRightDrawerParams({
        tab,
        projectId,
        inspectionId,
      });
    };

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId }],
      queryFn: getProjectInspectionById.bind(this, { projectId, inspectionId }),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
        { projectId, inspectionId, query: onlyProgressPhotos },
      ],
      queryFn: getInspectionDocuments.bind(this, {
        projectId,
        inspectionId,
        query: onlyProgressPhotos,
      }),
    },
  ]);

  const inspectionMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [QueryNamesEnums.GET_INSPECTION_MILESTONES, { projectId, inspectionId, filterKey }],
    getInspectionMilestones.bind(this, { projectId, inspectionId, filterKey }),
    { enabled: Boolean(projectId && inspectionId) },
  );

  const inspection = useMemo(() => requestedDataQueries[0].data, [requestedDataQueries[0].data]);

  const inspectionPhotos = useMemo(
    () => requestedDataQueries[1].data?.results,
    [requestedDataQueries[1].data?.results],
  );
  const inspectionMilestones = useMemo(
    () => inspectionMilestonesQuery.data?.results,
    [inspectionMilestonesQuery.data?.results],
  );

  useEffect(() => {
    inspection?.completed_at && inspectionCompletedAt.setValue(new Date(inspection.completed_at));
  }, [inspection]);

  const updateQueries = () => {
    const params = { projectId, drawRequestId: inspection?.draw_request?.id };
    requestedDataQueries[0].refetch();
    queryClient.invalidateQueries([QueryNamesEnums.GET_SERVICE_ORDERS_LIST]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS, params]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
      { projectId, inspectionId },
      { exact: false },
    ]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      { projectId, inspectionId },
    ]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST, params]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES, params]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
      { projectId, inspectionId, query: onlyProgressPhotos },
    ]);
  };

  const confirmInspection = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: () => {
        updateQueries();
        setIsApprovalPopupOpen(true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS);
      },
    },
  );

  const confirmCallBack = useCallback(async () => {
    if (inspectionCompletedAt.validate()) {
      await confirmInspection.mutateAsync({
        projectId,
        inspectionData: {
          inspectionId,
          status: ServiceOrderStatusEnum.COMPLETED,
          completed_at: inspectionCompletedAt.value,
        },
      });
    }
  }, [projectId, inspectionId, inspectionCompletedAt]);

  const openFile = useCallback(() => {
    if (inspection?.reports?.length) {
      imagePicker.open([inspection.reports?.[0]]);
    }
  }, [inspection?.reports]);

  // update report after uploading
  useEffect(() => {
    imagePicker.close();
    openFile();
  }, [inspection?.reports]);

  const isEditable = useMemo(() => action === ServiceActionEnum.EDIT, [action]);
  const isInspectionLinkedToDR = useMemo(() => Boolean(inspection?.draw_request?.id), [inspection]);

  const initColumns = useMemo(() => {
    return [
      'name',
      ...(isEditable ? ['previousInspectorAllowanceRate'] : []),
      'inspectorAllowanceRate',
      'revisedEstimate',
      ...(isInspectionLinkedToDR ? ['requestedAmount'] : []),
      'inspectionPhotoUploader',
      'inspectionPhotosV2',
      'comments',
    ];
  }, [isEditable, isInspectionLinkedToDR]);

  const goBack = useCallback(
    () => navigate(state || `/projects/${projectId}/services/all/`),
    [state],
  );

  const inspectionName = getInspectionNameByRole({
    teamRole,
    inspectionAgency: inspection?.inspection_agency,
  });

  const totals = useMemo(() => {
    const key = find(defaultOptions, { filterValue })?.totalKey;
    return {
      previous_inspector_allowance_rate:
        inspection?.totals?.[key]?.previous_inspector_allowance_rate,
      inspector_allowance_rate: inspection?.totals?.[key]?.inspector_allowance_rate,
      requested_amount: inspection?.totals?.[key]?.requested_amount,
      revised_estimate: inspection?.totals?.[key]?.revised_estimate,
    };
  }, [inspection, filterValue]);

  const serviceStatusValue = statusMap(inspection?.status, settings.display, 'inspection');
  const statusChipProps = {
    color: serviceStatusValue.color,
    backgroundColor: serviceStatusValue.backgroundColor,
    label: serviceStatusValue.text || inspection?.status,
    dataTestName: 'data-cy="service_results__status_chip"',
  };

  const [isUpdatedStatusModalOpen, setIsUpdatedStatusModalOpen] = useState<boolean>(false);

  const updateInspectionStatus = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: () => {
        setIsUpdatedStatusModalOpen(false);
        updateQueries();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleUpdateStatus = useCallback(
    async (status: ServiceOrderStatusEnum) => {
      let completed_at = inspectionCompletedAt.isValid && inspectionCompletedAt.value;
      if (status !== ServiceOrderStatusEnum.COMPLETED) {
        completed_at = null;
        inspectionCompletedAt.setValue(null);
      }
      await updateInspectionStatus.mutateAsync({
        projectId,
        inspectionData: {
          inspectionId,
          status,
          completed_at,
        },
      });
    },
    [projectId, inspectionId, inspectionCompletedAt],
  );

  const isStatusEditable = useMemo(
    () => !isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions),
    [permissions],
  );

  return {
    state: getHookState(requestedDataQueries),
    inspectionCompletedAt,
    inspection,
    inspectionRefetch: updateQueries,
    project: project.data,
    showPreview,
    setShowPreview,
    confirmCallBack,
    imagePicker,
    imageContainer,
    openFile,
    initColumns,
    isEditable,
    goBack,
    milestones: inspectionMilestones || [],
    drawRequestNumber: inspection?.draw_request?.number,
    inspectionPhotos,
    inspectionName,
    rightDrawerParams,
    rightMenu,
    updateRightDrawer,
    totals,
    filterValue,
    handleFiltersChange: handleFilterClick,
    filterOptions: map(defaultOptions, 'filterValue'),
    isMilestoneMutatingOrFetching,
    isInspectionLinkedToDR,
    activeDocumentId: imagePicker.pdf?.[0]?.id || imagePicker.gallery?.[0]?.id,
    isApprovalPopupOpen,
    setIsApprovalPopupOpen,
    statusChipProps,
    isUpdatedStatusModalOpen,
    setIsUpdatedStatusModalOpen,
    handleUpdateStatus,
    isStatusEditable,
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUserNotRequired,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionRequestedAt,
      primaryContactPhone,
    },
    isTruePicInspection: isTruePicInspection(inspection?.inspection_agency?.service),
  };
};
