import { useEffect, useContext } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import type { History, Transition } from 'history';
import { useIsFetching } from 'react-query';
import { EngineContext } from '@context';

export const useNavigationNetBlocker = () => {
  const isFetching = useIsFetching();
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;
  const { isBlockNavigation } = useContext(EngineContext);

  useEffect(() => {
    let lastNav = () => null;
    const unblock =
      isBlockNavigation || isFetching
        ? navigator.block((tx: Transition) => (lastNav = tx.retry))
        : () => null;

    return () => {
      if (isBlockNavigation || isFetching) {
        unblock();
        lastNav();
      }
    };
  }, [isBlockNavigation, isFetching]);
};
