import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({
  size = 40,
  color = colors.status.orange.medium,
  checkMarkColor = colors.neutral.white,
  dataTestName = '',
}: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy={dataTestName}
  >
    <circle cx="20" cy="20" r="15" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1154 9.69727C19.0272 9.69727 18.1574 10.6411 18.2009 11.7748L18.56 21.1343C18.5934 22.0048 19.2799 22.6925 20.1154 22.6925C20.951 22.6925 21.6375 22.0048 21.6709 21.1343L22.0299 11.7748C22.0734 10.6411 21.2037 9.69727 20.1154 9.69727ZM17.8223 27.6307C17.8223 26.3388 18.8267 25.2915 20.0658 25.2915C21.3048 25.2915 22.3093 26.3388 22.3093 27.6307C22.3093 28.9225 21.3048 29.9698 20.0658 29.9698C18.8267 29.9698 17.8223 28.9225 17.8223 27.6307Z"
      fill={checkMarkColor}
    />
  </svg>
);

export default Icon;
