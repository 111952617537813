import React from 'react';
import { Stack, Typography } from '@mui/material';

import { MaintenanceIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { useLaunchDarklyFlags } from '@context';

const ScheduledMaintenanceBanner = () => {
  const flags = useLaunchDarklyFlags();

  if (!flags?.['ENG_6686_maintenance_banner']) return null;

  return (
    <Stack
      id="maintenanceBanner"
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{
        py: 2,
        pr: 3,
        pl: 1,
        backgroundColor: colors.status.information.lighter,
        minHeight: 64,
        width: '100%',
      }}
    >
      <MaintenanceIcon />
      <Typography variant="body2" sx={{ color: colors.status.information.medium }}>
        <strong>Scheduled Maintenance Alert!</strong>
        <br /> We’re tuning up TrustPoint to serve you better! Certain features may be unavailable
        on Nov 5th, from 10 pm to 5 am PST. We appreciate your patience. Thank you!
      </Typography>
    </Stack>
  );
};

export default React.memo(ScheduledMaintenanceBanner);
