import JSZip from 'jszip';
import { useSafeSnackbar } from '@hooks';

export const useDownloadAllButton = ({ documents, drawRequestId }) => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const downloadDocs = () => {
    const zip = new JSZip();
    const promises = [];

    documents.forEach((file) => {
      promises.push(
        fetch(file.link)
          .then((response) => response.blob())
          .then((blob) => zip.file(file.name, blob)),
      );
    });

    Promise.all(promises)
      .then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const url = URL.createObjectURL(content);
          const a = document.createElement('a');
          a.href = url;
          a.download = drawRequestId ? 'request_files.zip' : 'project_files.zip';
          a.click();
        });
      })
      .catch(() => enqueueSnackbar('Downloading failed', { variant: 'error' }));
  };

  return {
    downloadDocs,
  };
};
