import React, { FC, ReactElement } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { colors } from '@theme';

const StyledLink = styled('a')(() => ({
  textDecoration: 'none',
}));

const SupportButton: FC<{
  image: ReactElement;
  text: string;
  link?: string;
  onClick?: () => void;
  dataTestName: string;
}> = ({ image, text, link, onClick, dataTestName }) => {
  return (
    <StyledLink
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      sx={{ flex: 4 }}
      data-cy={`onboarding__support__button__${dataTestName}`}
    >
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          py: 3,
          mt: 2,
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: '4px',
        }}
        onClick={onClick}
      >
        {image}
        <Typography variant="body1SemiBold" sx={{ pt: 3 }}>
          {text}
        </Typography>
      </Stack>
    </StyledLink>
  );
};
export default SupportButton;
