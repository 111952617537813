import { getArrayFromObject, isCreatedService } from '@utils';
import { FilterObject } from '../interface';
import { serviceOrderStatusMap } from '@constants';
import { PermissionNamesEnums } from '@interfaces';

export const servicesListStatusFilter: FilterObject = {
  title: 'Service status',
  filterKey: 'status',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getStaticValues: () =>
    (
      getArrayFromObject(serviceOrderStatusMap, 'value', 'label') as unknown as Array<{
        label: string;
        value: string;
      }>
    ).filter((o) => !isCreatedService(o.value)),
  cypressSelector: 'filters__services_list_status',
};
