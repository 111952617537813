import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { getItemIndexFromList, parsePathErrorDual } from '@utils';
import {
  DeleteBuildingParam,
  DeleteModelParam,
  ErrorDual,
  IMilestone,
  IProjectProperty,
  MutationKeyEnum,
  PatchBuildingParam,
  PatchModelParam,
  ProjectById,
  QueryNamesEnums,
} from '@interfaces';
import {
  deleteProjectBuilding,
  deleteProjectBuildingModel,
  getProjectModels,
  patchProjectBuilding,
  patchProjectModel,
  postBuildingModelToProject,
} from '@globalService';
import { useSafeSnackbar } from '@hooks';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

export type ControllerInterface = {
  initColumns: string[];
  projectModels: IProjectProperty[];
  addLineList: () => Promise<Response>;
  patchModel: (args: PatchModelParam) => void;
  update: () => void;
  deleteItem: (id: string, isModelBuilding: boolean) => void;
  onExpandClick: (id: string, isExpanded: boolean) => void;
};

export const useModelsTable = ({ isEditable }: { isEditable: boolean }): ControllerInterface => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [listItems, setListItems] = useState([]);

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }],
      queryFn: getProjectModels.bind(this, projectId),
    },
  ]);

  const projectModelsQuery = requestedDataQueries[0];

  const update = () => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }]);
  };

  const addModelMutation = useMutation<Response, Error, ProjectById>(postBuildingModelToProject, {
    mutationKey: MutationKeyEnum.DRAW_REQUEST_ADD_ITEM,
    onSuccess: () => {
      update();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const addLineList = useCallback(
    () => addModelMutation.mutateAsync({ project: projectId }),
    [addModelMutation],
  );

  const patchModelMutation = useMutation<Response, ErrorDual, PatchModelParam>(patchProjectModel, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
    onSuccess: () => {
      update();
    },
  });

  const patchBuildingMutation = useMutation<Response, ErrorDual, PatchBuildingParam>(
    patchProjectBuilding,
    {
      mutationKey: MutationKeyEnum.MILESTONE_PATCH,
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSuccess: () => {
        update();
      },
    },
  );

  const patchModel = useCallback(
    ({ milestone, json, isModelBuilding }: PatchModelParam) => {
      if (isModelBuilding) {
        patchBuildingMutation.mutate({
          project: projectId,
          building: milestone,
          json: json,
        });
      } else {
        patchModelMutation.mutate({
          project: projectId,
          model: milestone,
          json: json,
        });
      }
    },
    [projectId],
  );

  const deleteModelMutation = useMutation<Response, ErrorDual, DeleteModelParam>(
    deleteProjectBuildingModel,
    {
      onSuccess: update,
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
        update();
      },
    },
  );

  const deleteBuildingMutation = useMutation<Response, ErrorDual, DeleteBuildingParam>(
    deleteProjectBuilding,
    {
      onSuccess: update,
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
        update();
      },
    },
  );

  const initColumns = useMemo(
    () => [
      'productionBuildExpand',
      'productionBuildName',
      'productionBuildDescription',
      'productionBuildQuantity',
      'productionBuildSquare',
    ],
    [],
  );

  const getModelFromList = (id) => listItems?.find((item) => item.id === id);

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
  }): {
    item: IMilestone;
    isExpanded: boolean;
    isNested: boolean;
    index: number;
  } => ({
    ...item,
    activeToEdit: isEditable,
    canBeExpanded: item.quantity > 0 && !isEmpty(item.buildings),
    isModelBuilding: Boolean(item.model),
    isExpanded,
    isNested,
    ...(index !== undefined && { index }),
  });

  const updateModelExpandProp = (model, index) => {
    const foundedObj = getModelFromList(model.id);
    return createTableObject({ item: model, isExpanded: foundedObj?.isExpanded, index });
  };

  const addBuildingsToList = (item, fillingArr, index) => {
    if (item.isExpanded) {
      item.buildings.map((building, i) => {
        fillingArr.push(
          createTableObject({ item: building, isNested: true, index: `${index}_${i}` }),
        );
      });
    }
  };

  useEffect(() => {
    if (!projectModelsQuery.data?.results) return;
    const clonedMilestones = cloneDeep(projectModelsQuery.data.results);

    if (listItems.length) {
      const updatedList = getUpdatedListWIthResponse(clonedMilestones);
      setListItems(updatedList);
    } else {
      setListItems(clonedMilestones?.map((item, index) => createTableObject({ item, index })));
    }
  }, [projectModelsQuery.data?.results]);

  const getUpdatedListWIthResponse = (milestones) => {
    const modelsList = milestones?.map((model, index) => updateModelExpandProp(model, index));

    const modelsAndBuildingsList = [];
    modelsList.map((item, index) => {
      modelsAndBuildingsList.push(item);
      addBuildingsToList(item, modelsAndBuildingsList, index);
    });

    return modelsAndBuildingsList;
  };

  const deleteItem = useCallback(
    (id: string, isModelBuilding: boolean) => {
      if (isModelBuilding) {
        deleteBuildingMutation.mutate({
          project: projectId,
          buildingId: id,
        });
      } else {
        deleteModelMutation.mutate({
          project: projectId,
          modelId: id,
        });
      }
    },
    [projectId],
  );

  const expandModel = (model, modelIndex) => {
    setListItems((prevListItems) => {
      const expandedList = [...prevListItems];

      expandedList[modelIndex] = createTableObject({
        item: model,
        isExpanded: true,
        index: modelIndex,
      });

      // Insert buildings after the expanded item
      if (model?.buildings?.length > 0) {
        const milestoneTableObjects = model.buildings.map((building, i) =>
          createTableObject({ item: building, isNested: true, index: `${modelIndex}_${i}` }),
        );

        expandedList.splice(modelIndex + 1, 0, ...milestoneTableObjects);
      }

      return expandedList;
    });
  };

  const collapseModel = (model, modelIndex) => {
    setListItems((prevListItems) => {
      const updatedList = [...prevListItems]?.filter((item) => item.model !== model.id);
      updatedList.splice(modelIndex, 1, createTableObject({ item: model, index: modelIndex }));
      return updatedList;
    });
  };

  const onExpandClick = (id, isExpanded) => {
    const index = getItemIndexFromList(id, listItems);
    const model = listItems[index];

    if (isExpanded) {
      expandModel(model, index);
    } else {
      collapseModel(model, index);
    }
  };

  const projectModels = useMemo(
    () => listItems.map((item, index) => ({ ...item, index })),
    [listItems],
  );

  return {
    initColumns,
    addLineList,
    projectModels,
    patchModel,
    update,
    deleteItem,
    onExpandClick,
  };
};
