import React from 'react';
import { Button } from '@mui/material';

import { CSVLink } from 'react-csv';
import { ComponentProps } from './interface';

const DownloadCSVButton = ({ data, filename, headers }: ComponentProps) => {
  return (
    <CSVLink data={data} filename={filename} headers={headers} style={{ textDecoration: 'none' }}>
      <Button size="small" variant="new" color="secondary" sx={{ width: '120px' }}>
        Export to CSV
      </Button>
    </CSVLink>
  );
};

export default DownloadCSVButton;
