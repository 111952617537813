import { useQuery } from 'react-query';
import { getCompanyPoliciesProjectsList, getMyCompany } from '@globalService';
import { HookState, ICompany, IProjectSimple, QueryNamesEnums, TPolicies } from '@interfaces';
import { getHookState } from '@utils';
import { PoliciesTypeMap } from '@constants';

export interface ControllerInterface {
  state: HookState;
  projectsList: IProjectSimple[];
  tabLink: string;
}

export const usePolicyProjectsListModal = ({
  template,
}: {
  template: TPolicies;
}): ControllerInterface => {
  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const projectsListQuery = useQuery<{ results: IProjectSimple[] }, Error>(
    [
      QueryNamesEnums.GET_COMPANY_POLICIES_PROJECTS_LIST,
      { companyId: companyQuery.data?.id, templateId: template.id },
    ],
    getCompanyPoliciesProjectsList.bind(this, companyQuery.data?.id, template.id),
    { enabled: Boolean(companyQuery.data?.id && template?.id) },
  );

  const tabLink = Object.keys(PoliciesTypeMap).find(
    (key) => PoliciesTypeMap[key] === template.type,
  );

  return {
    state: getHookState(projectsListQuery),
    projectsList: projectsListQuery.data?.results || [],
    tabLink,
  };
};
