import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 240 }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 240 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M229.333 40H172.666V43.6277C172.665 43.8134 172.729 43.9937 172.847 44.1375C172.733 46.973 172.666 50.5928 172.666 54.5264V151.513C172.666 160.603 173.033 168 173.482 168H228.517C228.966 168 229.333 160.603 229.333 151.513V54.5264C229.333 50.5928 229.266 46.973 229.151 44.1375C229.269 43.9937 229.334 43.8134 229.333 43.6277L229.333 40Z"
      fill="white"
    />
    <path
      d="M228.139 168.667H173.859C173.436 168.667 173.157 168.667 172.903 163.489C172.75 160.382 172.666 156.26 172.666 151.883V55.45C172.666 51.6699 172.73 47.9959 172.845 45.1047C172.918 43.3688 173 41.9782 173.091 40.9712C173.27 38.9998 173.44 38.6667 173.859 38.6667H228.139C228.559 38.6667 228.729 38.9998 228.907 40.9712C228.999 41.982 229.081 43.3726 229.154 45.1039L229.154 45.1047C229.269 47.9929 229.333 51.6669 229.333 55.45V151.883C229.333 156.26 229.249 160.382 229.096 163.489C228.842 168.667 228.563 168.667 228.139 168.667ZM174.08 167.887H227.919C228.2 166.821 228.555 161.035 228.555 151.883V55.45C228.555 51.6772 228.492 48.0146 228.377 45.1366H228.377C228.305 43.4177 228.223 42.0399 228.133 41.0416C228.099 40.5054 228.026 39.9724 227.915 39.4466H174.084C173.973 39.9724 173.9 40.5054 173.866 41.0416C173.776 42.0361 173.694 43.4139 173.622 45.1366C173.507 48.0171 173.443 51.68 173.443 55.45V151.883C173.443 161.035 173.799 166.821 174.08 167.887Z"
      fill="#E8E8E8"
    />
    <path
      d="M228.517 38.6667H173.482C173.266 38.6671 173.058 38.7474 172.906 38.8901C172.753 39.0327 172.666 39.2261 172.666 39.4278V43.9057C172.665 44.0799 172.729 44.2488 172.847 44.3837C172.924 44.4718 173.021 44.543 173.13 44.5919C173.24 44.6409 173.36 44.6664 173.482 44.6667H228.517C228.639 44.6664 228.759 44.6409 228.868 44.5919C228.978 44.5429 229.075 44.4718 229.151 44.3837C229.269 44.2488 229.334 44.0798 229.333 43.9057V39.4278C229.332 39.2261 229.246 39.0327 229.093 38.8901C228.94 38.7475 228.733 38.6671 228.517 38.6667Z"
      fill="#D2D2D2"
    />
    <path
      d="M177.666 42.6667C178.218 42.6667 178.666 42.0697 178.666 41.3333C178.666 40.597 178.218 40 177.666 40C177.114 40 176.666 40.597 176.666 41.3333C176.666 42.0697 177.114 42.6667 177.666 42.6667Z"
      fill="white"
    />
    <path
      d="M181.999 42.6667C182.736 42.6667 183.333 42.0697 183.333 41.3333C183.333 40.597 182.736 40 181.999 40C181.263 40 180.666 40.597 180.666 41.3333C180.666 42.0697 181.263 42.6667 181.999 42.6667Z"
      fill="white"
    />
    <path
      d="M186.334 42.6667C186.886 42.6667 187.334 42.0697 187.334 41.3333C187.334 40.597 186.886 40 186.334 40C185.782 40 185.334 40.597 185.334 41.3333C185.334 42.0697 185.782 42.6667 186.334 42.6667Z"
      fill="white"
    />
    <path
      d="M223.656 39.3333H219.01C218.919 39.3344 218.832 39.4051 218.767 39.5298C218.703 39.6546 218.667 39.8235 218.666 39.9999C218.665 40.1004 218.677 40.1997 218.702 40.2876C218.73 40.4006 218.773 40.4962 218.828 40.5633C218.882 40.6303 218.945 40.6662 219.01 40.6666H223.656C223.721 40.6672 223.785 40.6319 223.84 40.5648C223.895 40.4977 223.939 40.4016 223.967 40.2876C223.989 40.1979 224 40.0994 223.999 39.9999C223.999 39.9123 223.99 39.8256 223.973 39.7447C223.956 39.6638 223.931 39.5903 223.899 39.5284C223.867 39.4665 223.829 39.4174 223.787 39.3839C223.746 39.3504 223.701 39.3332 223.656 39.3333Z"
      fill="white"
    />
    <path
      d="M223.656 41.3333H219.01C218.918 41.3333 218.831 41.3684 218.767 41.4309C218.702 41.4934 218.666 41.5782 218.666 41.6666C218.666 41.755 218.702 41.8398 218.767 41.9023C218.831 41.9648 218.918 41.9999 219.01 41.9999H223.656C223.747 41.9999 223.834 41.9648 223.899 41.9023C223.963 41.8398 223.999 41.755 223.999 41.6666C223.999 41.5782 223.963 41.4934 223.899 41.4309C223.834 41.3684 223.747 41.3333 223.656 41.3333Z"
      fill="white"
    />
    <path
      d="M223.656 42.6667H219.01C218.918 42.6667 218.831 42.7019 218.767 42.7644C218.702 42.8269 218.666 42.9117 218.666 43.0001C218.666 43.0885 218.702 43.1733 218.767 43.2358C218.831 43.2983 218.918 43.3334 219.01 43.3334H223.656C223.747 43.3334 223.834 43.2983 223.899 43.2358C223.963 43.1733 223.999 43.0885 223.999 43.0001C223.999 42.9117 223.963 42.8269 223.899 42.7644C223.834 42.7019 223.747 42.6667 223.656 42.6667Z"
      fill="white"
    />
    <path
      d="M206.079 55.3333H195.921C195.412 55.3335 194.923 55.5363 194.563 55.8973C194.203 56.2583 194 56.7478 194 57.2583V66.0749C194.001 66.5852 194.204 67.0743 194.564 67.4351C194.924 67.796 195.412 67.9991 195.921 67.9999H206.079C206.588 67.9984 207.076 67.7951 207.436 67.4344C207.796 67.0738 207.999 66.585 208 66.0749V57.2583C207.999 56.748 207.797 56.2588 207.436 55.898C207.076 55.5372 206.588 55.3341 206.079 55.3333Z"
      fill="#D02C2B"
    />
    <path
      d="M219.108 74.6667H182.892C182.656 74.6667 182.429 74.7721 182.261 74.9596C182.094 75.1472 182 75.4015 182 75.6667C182 75.932 182.094 76.1863 182.261 76.3738C182.429 76.5614 182.656 76.6667 182.892 76.6667H219.108C219.344 76.6667 219.571 76.5614 219.739 76.3738C219.906 76.1863 220 75.932 220 75.6667C220 75.4015 219.906 75.1472 219.739 74.9596C219.571 74.7721 219.344 74.6667 219.108 74.6667Z"
      fill="#E8E8E8"
    />
    <path
      d="M196.453 78H182.88C182.647 78 182.423 78.1054 182.258 78.2929C182.093 78.4804 182 78.7348 182 79C182 79.2652 182.093 79.5196 182.258 79.7071C182.423 79.8946 182.647 80 182.88 80H196.453C196.686 80 196.91 79.8946 197.076 79.7071C197.241 79.5196 197.333 79.2652 197.333 79C197.333 78.7348 197.241 78.4804 197.076 78.2929C196.91 78.1054 196.686 78 196.453 78Z"
      fill="#E8E8E8"
    />
    <path
      d="M206.079 94H195.921C195.412 94.0002 194.923 94.2031 194.563 94.5641C194.203 94.925 194 95.4145 194 95.925V104.742C194.001 105.252 194.204 105.741 194.564 106.102C194.924 106.463 195.412 106.666 195.921 106.667H206.079C206.588 106.665 207.076 106.462 207.436 106.101C207.796 105.74 207.999 105.252 208 104.742V95.925C207.999 95.4147 207.797 94.9256 207.436 94.5648C207.076 94.2039 206.588 94.0008 206.079 94Z"
      fill="#D02C2B"
    />
    <path
      d="M219.108 112.667H182.892C182.656 112.667 182.429 112.772 182.261 112.96C182.094 113.147 182 113.402 182 113.667C182 113.932 182.094 114.186 182.261 114.374C182.429 114.561 182.656 114.667 182.892 114.667H219.108C219.344 114.667 219.571 114.561 219.739 114.374C219.906 114.186 220 113.932 220 113.667C220 113.402 219.906 113.147 219.739 112.96C219.571 112.772 219.344 112.667 219.108 112.667Z"
      fill="#E8E8E8"
    />
    <path
      d="M196.453 116.667H182.88C182.647 116.667 182.423 116.772 182.258 116.96C182.093 117.147 182 117.402 182 117.667C182 117.932 182.093 118.186 182.258 118.374C182.423 118.561 182.647 118.667 182.88 118.667H196.453C196.686 118.667 196.91 118.561 197.076 118.374C197.241 118.186 197.333 117.932 197.333 117.667C197.333 117.402 197.241 117.147 197.076 116.96C196.91 116.772 196.686 116.667 196.453 116.667Z"
      fill="#E8E8E8"
    />
    <path
      d="M206.079 133.333H195.921C195.412 133.333 194.923 133.526 194.563 133.868C194.203 134.21 194 134.673 194 135.157V143.51C194.001 143.993 194.204 144.456 194.564 144.798C194.924 145.14 195.412 145.332 195.921 145.333H206.079C206.588 145.332 207.076 145.139 207.436 144.798C207.796 144.456 207.999 143.993 208 143.51V135.157C207.999 134.674 207.797 134.21 207.436 133.868C207.076 133.526 206.588 133.334 206.079 133.333Z"
      fill="#D02C2B"
    />
    <path
      d="M219.108 152H182.892C182.656 152 182.429 152.07 182.261 152.195C182.094 152.32 182 152.49 182 152.667C182 152.843 182.094 153.013 182.261 153.138C182.429 153.263 182.656 153.333 182.892 153.333H219.108C219.344 153.333 219.571 153.263 219.739 153.138C219.906 153.013 220 152.843 220 152.667C220 152.49 219.906 152.32 219.739 152.195C219.571 152.07 219.344 152 219.108 152Z"
      fill="#E8E8E8"
    />
    <path
      d="M196.453 155.333H182.88C182.647 155.333 182.423 155.439 182.258 155.626C182.093 155.814 182 156.068 182 156.333C182 156.598 182.093 156.853 182.258 157.04C182.423 157.228 182.647 157.333 182.88 157.333H196.453C196.686 157.333 196.91 157.228 197.076 157.04C197.241 156.853 197.333 156.598 197.333 156.333C197.333 156.068 197.241 155.814 197.076 155.626C196.91 155.439 196.686 155.333 196.453 155.333Z"
      fill="#E8E8E8"
    />
    <path
      d="M158.001 36.1462C158 35.9307 157.915 35.7242 157.762 35.5718C157.61 35.4195 157.404 35.3337 157.188 35.3333H12.1463C11.931 35.3337 11.7247 35.4194 11.5724 35.5718C11.4202 35.7242 11.3344 35.9307 11.334 36.1462V40.9294C11.3333 41.1285 11.4076 41.3206 11.5421 41.4674C11.4125 44.7151 11.334 48.9957 11.334 53.6846V161.645C11.334 171.769 11.699 180 12.1463 180H157.188C157.636 180 158.001 171.769 158.001 161.645V53.6846C158.001 48.9957 157.922 44.7151 157.793 41.4674C157.927 41.3206 158.001 41.1285 158.001 40.9294V36.1462Z"
      fill="white"
    />
    <path
      d="M156.796 180H11.8698C11.4267 180 11.1593 180 10.9046 174.248C10.7507 170.774 10.666 166.164 10.666 161.267V53.3955C10.666 48.8802 10.74 44.5393 10.8743 41.1726C11.132 34.6668 11.4255 34.6667 11.8698 34.6667H156.796C157.24 34.6667 157.534 34.6679 157.791 41.1726C157.925 44.5363 157.999 48.8771 157.999 53.3955V161.267C157.999 166.164 157.915 170.774 157.761 174.248C157.506 180 157.239 180 156.796 180ZM12.0986 179.215H156.567C156.888 177.985 157.215 171.077 157.215 161.267V53.3955C157.215 48.8872 157.142 44.5573 157.008 41.204V41.2038C156.845 37.0824 156.654 35.8282 156.563 35.4515H12.1029C12.0117 35.8282 11.8211 37.0824 11.6577 41.2038C11.5238 44.5606 11.4501 48.8903 11.4501 53.3955V161.267C11.4501 171.077 11.7778 177.985 12.0986 179.215Z"
      fill="#E8E8E8"
    />
    <path
      d="M157.189 35.3333H12.1463C11.931 35.3336 11.7247 35.4139 11.5724 35.5566C11.4202 35.6992 11.3344 35.8926 11.334 36.0943V40.5722C11.3333 40.7587 11.4076 40.9385 11.5421 41.0759C11.6175 41.1566 11.7104 41.2213 11.8146 41.2657C11.9188 41.31 12.0319 41.3331 12.1463 41.3333H157.188C157.303 41.3331 157.416 41.31 157.52 41.2657C157.624 41.2213 157.717 41.1566 157.793 41.0759C157.927 40.9385 158.001 40.7586 158.001 40.5722V36.0943C158 35.8926 157.915 35.6992 157.762 35.5566C157.61 35.414 157.404 35.3337 157.189 35.3333Z"
      fill="#D2D2D2"
    />
    <path
      d="M15.666 39.3334C16.2183 39.3334 16.666 38.7365 16.666 38.0001C16.666 37.2637 16.2183 36.6667 15.666 36.6667C15.1137 36.6667 14.666 37.2637 14.666 38.0001C14.666 38.7365 15.1137 39.3334 15.666 39.3334Z"
      fill="white"
    />
    <path
      d="M19.9994 39.3334C20.7357 39.3334 21.3327 38.7365 21.3327 38.0001C21.3327 37.2637 20.7357 36.6667 19.9994 36.6667C19.263 36.6667 18.666 37.2637 18.666 38.0001C18.666 38.7365 19.263 39.3334 19.9994 39.3334Z"
      fill="white"
    />
    <path
      d="M24.334 39.3334C24.8863 39.3334 25.334 38.7365 25.334 38.0001C25.334 37.2637 24.8863 36.6667 24.334 36.6667C23.7817 36.6667 23.334 37.2637 23.334 38.0001C23.334 38.7365 23.7817 39.3334 24.334 39.3334Z"
      fill="white"
    />
    <path
      d="M151.656 36.6667H147.01C146.918 36.6667 146.831 36.7019 146.767 36.7644C146.702 36.8269 146.666 36.9117 146.666 37.0001C146.666 37.0885 146.702 37.1733 146.767 37.2358C146.831 37.2983 146.918 37.3334 147.01 37.3334H151.656C151.747 37.3334 151.834 37.2983 151.899 37.2358C151.963 37.1733 151.999 37.0885 151.999 37.0001C151.999 36.9117 151.963 36.8269 151.899 36.7644C151.834 36.7019 151.747 36.6667 151.656 36.6667Z"
      fill="white"
    />
    <path
      d="M151.656 38H147.01C146.918 38 146.831 38.0351 146.767 38.0976C146.702 38.1601 146.666 38.2449 146.666 38.3333C146.666 38.4217 146.702 38.5065 146.767 38.569C146.831 38.6316 146.918 38.6667 147.01 38.6667H151.656C151.747 38.6667 151.834 38.6316 151.899 38.569C151.963 38.5065 151.999 38.4217 151.999 38.3333C151.999 38.2449 151.963 38.1601 151.899 38.0976C151.834 38.0351 151.747 38 151.656 38Z"
      fill="white"
    />
    <path
      d="M151.656 39.3333H147.01C146.918 39.3333 146.831 39.3684 146.767 39.4309C146.702 39.4934 146.666 39.5782 146.666 39.6666C146.666 39.755 146.702 39.8398 146.767 39.9023C146.831 39.9648 146.918 39.9999 147.01 39.9999H151.656C151.747 39.9999 151.834 39.9648 151.899 39.9023C151.963 39.8398 151.999 39.755 151.999 39.6666C151.999 39.5782 151.963 39.4934 151.899 39.4309C151.834 39.3684 151.747 39.3333 151.656 39.3333Z"
      fill="white"
    />
    <path
      d="M58.7073 58.6667H41.2927C40.4198 58.6678 39.5828 59.0097 38.9656 59.6174C38.3483 60.2252 38.001 61.0491 38 61.9086V76.7583C38.001 77.6177 38.3483 78.4417 38.9656 79.0494C39.5828 79.6571 40.4198 79.999 41.2927 80.0001H58.7073C59.5802 79.999 60.4172 79.6571 61.0344 79.0494C61.6517 78.4417 61.999 77.6177 62 76.7583V61.9086C61.999 61.0491 61.6517 60.2252 61.0344 59.6174C60.4172 59.0097 59.5802 58.6678 58.7073 58.6667Z"
      fill="#28C366"
    />
    <path
      d="M127.893 65.3333H69.4402C69.0582 65.3333 68.6919 65.4737 68.4218 65.7238C68.1517 65.9738 68 66.313 68 66.6666C68 67.0202 68.1517 67.3593 68.4218 67.6094C68.6919 67.8594 69.0582 67.9999 69.4402 67.9999H127.893C128.275 67.9999 128.641 67.8594 128.911 67.6094C129.182 67.3593 129.333 67.0202 129.333 66.6666C129.333 66.313 129.182 65.9738 128.911 65.7238C128.641 65.4737 128.275 65.3333 127.893 65.3333Z"
      fill="#E8E8E8"
    />
    <path
      d="M91.2503 71.3333H69.4163C69.0407 71.3333 68.6804 71.5088 68.4148 71.8214C68.1492 72.134 68 72.5579 68 72.9999C68 73.4419 68.1492 73.8659 68.4148 74.1784C68.6804 74.491 69.0407 74.6666 69.4163 74.6666H91.2503C91.626 74.6666 91.9862 74.491 92.2518 74.1784C92.5174 73.8659 92.6667 73.4419 92.6667 72.9999C92.6667 72.5579 92.5174 72.134 92.2518 71.8214C91.9862 71.5088 91.626 71.3333 91.2503 71.3333Z"
      fill="#E8E8E8"
    />
    <path
      d="M58.7073 98H41.2927C40.4198 98.001 39.5828 98.3322 38.9656 98.921C38.3483 99.5097 38.001 100.308 38 101.141V115.526C38.001 116.359 38.3483 117.157 38.9656 117.746C39.5828 118.334 40.4198 118.666 41.2927 118.667H58.7073C59.5802 118.666 60.4172 118.334 61.0344 117.746C61.6517 117.157 61.999 116.359 62 115.526V101.141C61.999 100.308 61.6517 99.5097 61.0344 98.921C60.4172 98.3322 59.5802 98.001 58.7073 98Z"
      fill="#28C366"
    />
    <path
      d="M127.893 104H69.4402C69.0582 104 68.6919 104.105 68.4218 104.293C68.1517 104.48 68 104.735 68 105C68 105.265 68.1517 105.52 68.4218 105.707C68.6919 105.895 69.0582 106 69.4402 106H127.893C128.275 106 128.641 105.895 128.911 105.707C129.182 105.52 129.333 105.265 129.333 105C129.333 104.735 129.182 104.48 128.911 104.293C128.641 104.105 128.275 104 127.893 104Z"
      fill="#E8E8E8"
    />
    <path
      d="M91.2503 110.667H69.4163C69.0407 110.667 68.6804 110.807 68.4148 111.057C68.1492 111.307 68 111.646 68 112C68 112.354 68.1492 112.693 68.4148 112.943C68.6804 113.193 69.0407 113.333 69.4163 113.333H91.2503C91.626 113.333 91.9862 113.193 92.2518 112.943C92.5174 112.693 92.6667 112.354 92.6667 112C92.6667 111.646 92.5174 111.307 92.2518 111.057C91.9862 110.807 91.626 110.667 91.2503 110.667Z"
      fill="#E8E8E8"
    />
    <path
      d="M58.7073 137.333H41.2927C40.4198 137.334 39.5828 137.665 38.9656 138.254C38.3483 138.843 38.001 139.641 38 140.474V154.859C38.001 155.692 38.3483 156.49 38.9656 157.079C39.5828 157.668 40.4198 157.999 41.2927 158H58.7073C59.5802 157.999 60.4172 157.668 61.0344 157.079C61.6517 156.49 61.999 155.692 62 154.859V140.474C61.999 139.641 61.6517 138.843 61.0344 138.254C60.4172 137.665 59.5802 137.334 58.7073 137.333Z"
      fill="#28C366"
    />
    <path
      d="M127.893 143.333H69.4402C69.0582 143.333 68.6919 143.439 68.4218 143.626C68.1517 143.814 68 144.068 68 144.333C68 144.598 68.1517 144.853 68.4218 145.04C68.6919 145.228 69.0582 145.333 69.4402 145.333H127.893C128.275 145.333 128.641 145.228 128.911 145.04C129.182 144.853 129.333 144.598 129.333 144.333C129.333 144.068 129.182 143.814 128.911 143.626C128.641 143.439 128.275 143.333 127.893 143.333Z"
      fill="#E8E8E8"
    />
    <path
      d="M91.2503 150H69.4163C69.0407 150 68.6804 150.14 68.4148 150.391C68.1492 150.641 68 150.98 68 151.333C68 151.687 68.1492 152.026 68.4148 152.276C68.6804 152.526 69.0407 152.667 69.4163 152.667H91.2503C91.626 152.667 91.9862 152.526 92.2518 152.276C92.5174 152.026 92.6667 151.687 92.6667 151.333C92.6667 150.98 92.5174 150.641 92.2518 150.391C91.9862 150.14 91.626 150 91.2503 150Z"
      fill="#E8E8E8"
    />
    <path
      d="M182.516 205.333H84.8168C84.3664 205.333 84 198.466 84 190.025V99.9754C84 91.5337 84.3664 84.6667 84.8168 84.6667H182.516C182.967 84.6667 183.333 91.5337 183.333 99.9754V190.025C183.333 198.466 182.967 205.333 182.516 205.333Z"
      fill="white"
    />
    <path
      d="M182.79 205.333H85.2099C84.7971 205.333 84.4988 205.333 84.2398 200.479C84.0851 197.58 84 193.734 84 189.648V99.685C84 95.5996 84.0851 91.7533 84.2398 88.8548C84.4988 84 84.7971 84 85.2099 84H182.79C183.203 84 183.501 84 183.76 88.8548C183.915 91.7533 184 95.5996 184 99.685V189.648C184 193.734 183.915 197.58 183.76 200.479C183.501 205.333 183.203 205.333 182.79 205.333ZM85.428 204.551H182.572C182.855 203.539 183.212 198.143 183.212 189.648V99.685C183.212 91.1906 182.855 85.7946 182.572 84.7818H85.428C85.1451 85.7946 84.7881 91.1905 84.7881 99.685V189.648C84.788 198.143 85.1451 203.539 85.428 204.551Z"
      fill="#E8E8E8"
    />
    <path
      d="M182.516 90.6667H84.8168C84.6003 90.6665 84.3926 90.5863 84.2395 90.4435C84.0864 90.3008 84.0002 90.1074 84 89.9055V85.428C84.0002 85.2261 84.0864 85.0327 84.2395 84.8899C84.3926 84.7472 84.6003 84.667 84.8168 84.6667H182.516C182.733 84.667 182.941 84.7472 183.094 84.8899C183.247 85.0327 183.333 85.2261 183.333 85.428V89.9055C183.333 90.1074 183.247 90.3008 183.094 90.4435C182.941 90.5863 182.733 90.6665 182.516 90.6667Z"
      fill="#D2D2D2"
    />
    <path
      d="M89.3333 88.0001C90.0697 88.0001 90.6667 87.7016 90.6667 87.3334C90.6667 86.9652 90.0697 86.6667 89.3333 86.6667C88.5969 86.6667 88 86.9652 88 87.3334C88 87.7016 88.5969 88.0001 89.3333 88.0001Z"
      fill="white"
    />
    <path
      d="M93.3333 88.0001C94.0697 88.0001 94.6667 87.7016 94.6667 87.3334C94.6667 86.9652 94.0697 86.6667 93.3333 86.6667C92.5969 86.6667 92 86.9652 92 87.3334C92 87.7016 92.5969 88.0001 93.3333 88.0001Z"
      fill="white"
    />
    <path
      d="M98.6673 88.0001C99.4037 88.0001 100.001 87.7016 100.001 87.3334C100.001 86.9652 99.4037 86.6667 98.6673 86.6667C97.9309 86.6667 97.334 86.9652 97.334 87.3334C97.334 87.7016 97.9309 88.0001 98.6673 88.0001Z"
      fill="white"
    />
    <path
      d="M177.656 86.6667H173.01C172.919 86.6667 172.831 86.6315 172.767 86.569C172.702 86.5065 172.666 86.4217 172.666 86.3333C172.666 86.2449 172.702 86.1601 172.767 86.0976C172.831 86.0351 172.919 86 173.01 86H177.656C177.747 86.0003 177.835 86.0355 177.899 86.098C177.963 86.1605 177.999 86.2451 177.999 86.3333C177.999 86.4216 177.963 86.5062 177.899 86.5687C177.835 86.6311 177.747 86.6664 177.656 86.6667Z"
      fill="white"
    />
    <path
      d="M177.655 88.0001H173.01C172.919 88.0001 172.831 87.9299 172.767 87.8048C172.702 87.6798 172.666 87.5102 172.666 87.3334C172.666 87.1566 172.702 86.987 172.767 86.862C172.831 86.737 172.919 86.6667 173.01 86.6667H177.655C177.747 86.6667 177.834 86.737 177.899 86.862C177.963 86.987 177.999 87.1566 177.999 87.3334C177.999 87.5102 177.963 87.6798 177.899 87.8048C177.834 87.9299 177.747 88.0001 177.655 88.0001Z"
      fill="white"
    />
    <path
      d="M177.656 89.3334H173.01C172.919 89.3334 172.831 89.2983 172.767 89.2358C172.702 89.1733 172.666 89.0885 172.666 89.0001C172.666 88.9117 172.702 88.8269 172.767 88.7644C172.831 88.7019 172.919 88.6667 173.01 88.6667H177.656C177.747 88.667 177.835 88.7023 177.899 88.7648C177.963 88.8272 177.999 88.9119 177.999 89.0001C177.999 89.0883 177.963 89.1729 177.899 89.2354C177.835 89.2979 177.747 89.3331 177.656 89.3334Z"
      fill="white"
    />
    <path
      d="M115.714 120.667H103.621C103.015 120.666 102.433 120.42 102.005 119.983C101.576 119.546 101.335 118.954 101.334 118.336V107.664C101.335 107.046 101.576 106.453 102.005 106.017C102.433 105.58 103.015 105.334 103.621 105.333H115.714C116.32 105.334 116.901 105.58 117.33 106.017C117.759 106.453 118 107.046 118.001 107.664V118.336C118 118.954 117.759 119.546 117.33 119.983C116.901 120.42 116.32 120.666 115.714 120.667Z"
      fill="#D02C2B"
    />
    <path
      d="M166.269 111.333H123.064C122.782 111.333 122.511 111.193 122.312 110.943C122.112 110.693 122 110.354 122 110C122 109.646 122.112 109.307 122.312 109.057C122.511 108.807 122.782 108.667 123.064 108.667H166.269C166.551 108.667 166.822 108.807 167.022 109.057C167.221 109.307 167.333 109.646 167.333 110C167.333 110.354 167.221 110.693 167.022 110.943C166.822 111.193 166.551 111.333 166.269 111.333Z"
      fill="#E8E8E8"
    />
    <path
      d="M138.965 115.333H123.033C122.759 115.333 122.496 115.263 122.303 115.138C122.109 115.013 122 114.843 122 114.667C122 114.49 122.109 114.32 122.303 114.195C122.496 114.07 122.759 114 123.033 114H138.965C139.101 114 139.235 114.017 139.361 114.05C139.486 114.084 139.6 114.133 139.697 114.195C139.793 114.257 139.869 114.33 139.921 114.411C139.973 114.492 140 114.579 140 114.667C140 114.754 139.973 114.841 139.921 114.922C139.869 115.003 139.793 115.077 139.697 115.139C139.6 115.2 139.486 115.25 139.361 115.283C139.235 115.316 139.101 115.334 138.965 115.333Z"
      fill="#E8E8E8"
    />
    <path
      d="M115.714 152H103.621C103.015 151.999 102.433 151.754 102.005 151.317C101.576 150.88 101.335 150.288 101.334 149.67V138.997C101.335 138.379 101.576 137.787 102.005 137.35C102.433 136.913 103.015 136.667 103.621 136.667H115.714C116.32 136.667 116.901 136.913 117.33 137.35C117.759 137.787 118 138.379 118.001 138.997V149.67C118 150.288 117.759 150.88 117.33 151.317C116.901 151.754 116.32 151.999 115.714 152Z"
      fill="#D02C2B"
    />
    <path
      d="M166.269 143.333H123.064C122.782 143.333 122.511 143.228 122.312 143.04C122.112 142.853 122 142.598 122 142.333C122 142.068 122.112 141.814 122.312 141.626C122.511 141.439 122.782 141.333 123.064 141.333H166.269C166.551 141.333 166.822 141.439 167.022 141.626C167.221 141.814 167.333 142.068 167.333 142.333C167.333 142.598 167.221 142.853 167.022 143.04C166.822 143.228 166.551 143.333 166.269 143.333Z"
      fill="#E8E8E8"
    />
    <path
      d="M138.965 148H123.033C122.759 148 122.496 147.859 122.303 147.609C122.109 147.359 122 147.02 122 146.667C122 146.313 122.109 145.974 122.303 145.724C122.496 145.474 122.759 145.333 123.033 145.333H138.965C139.101 145.333 139.235 145.367 139.361 145.434C139.486 145.501 139.6 145.599 139.697 145.723C139.793 145.847 139.869 145.994 139.921 146.156C139.973 146.318 140 146.491 140 146.667C140 146.842 139.973 147.015 139.921 147.177C139.869 147.339 139.793 147.486 139.697 147.61C139.6 147.734 139.486 147.832 139.361 147.899C139.235 147.966 139.101 148 138.965 148Z"
      fill="#E8E8E8"
    />
    <path
      d="M115.714 184H103.621C103.015 183.999 102.433 183.743 102.005 183.287C101.576 182.831 101.335 182.213 101.334 181.568V170.432C101.335 169.787 101.576 169.169 102.005 168.713C102.433 168.257 103.015 168.001 103.621 168H115.714C116.32 168.001 116.901 168.257 117.33 168.713C117.759 169.169 118 169.787 118.001 170.432V181.568C118 182.213 117.759 182.831 117.33 183.287C116.901 183.743 116.32 183.999 115.714 184Z"
      fill="#D02C2B"
    />
    <path
      d="M166.269 174H123.064C122.782 174 122.511 173.895 122.312 173.707C122.112 173.52 122 173.265 122 173C122 172.735 122.112 172.48 122.312 172.293C122.511 172.105 122.782 172 123.064 172H166.269C166.551 172 166.822 172.105 167.022 172.293C167.221 172.48 167.333 172.735 167.333 173C167.333 173.265 167.221 173.52 167.022 173.707C166.822 173.895 166.551 174 166.269 174Z"
      fill="#E8E8E8"
    />
    <path
      d="M138.965 180H123.033C122.759 180 122.496 179.895 122.303 179.707C122.109 179.52 122 179.265 122 179C122 178.735 122.109 178.48 122.303 178.293C122.496 178.105 122.759 178 123.033 178H138.965C139.101 178 139.235 178.025 139.361 178.076C139.486 178.126 139.6 178.199 139.697 178.292C139.793 178.385 139.869 178.495 139.921 178.617C139.973 178.738 140 178.869 140 179C140 179.131 139.973 179.262 139.921 179.383C139.869 179.505 139.793 179.615 139.697 179.708C139.6 179.801 139.486 179.874 139.361 179.924C139.235 179.975 139.101 180 138.965 180Z"
      fill="#E8E8E8"
    />
  </svg>
);

export default Icon;
