import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.22469 6.01206C4.04246 6.69462 3.87637 8.3348 4.89775 9.24052L7.76414 11.7823C6.69035 13.0983 6.49337 14.9843 7.3832 16.5256L8.29109 18.0981C8.56723 18.5764 9.17882 18.7402 9.65712 18.4641L13.9872 15.9641L15.9872 19.4282C16.2634 19.9065 16.875 20.0704 17.3533 19.7942C17.8316 19.5181 17.9954 18.9065 17.7193 18.4282L15.7193 14.9641L20.0494 12.4641C20.5277 12.188 20.6916 11.5764 20.4154 11.0981L19.5076 9.52556C18.6177 7.98434 16.8859 7.2119 15.2093 7.48383L14.4412 3.73058C14.1676 2.39318 12.6641 1.71693 11.4819 2.39949L5.22469 6.01206ZM13.8533 13.7321L18.1834 11.2321L17.7755 10.5256C17.2016 9.53147 15.9574 9.14936 14.9244 9.64995C14.3186 9.94354 13.5984 9.58776 13.4634 8.92822L12.4819 4.13154L6.22469 7.74411L9.88796 10.9925C10.3916 11.4392 10.3397 12.2407 9.78251 12.6186C8.8325 13.2629 8.54131 14.5315 9.11525 15.5256L9.52314 16.2321L13.8533 13.7321Z"
      fill={color}
    />
  </svg>
);

export default Icon;
