import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon / appraisal">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 22L6 22C4.34315 22 3 20.6569 3 19V5C3 3.34314 4.34315 2 6 2H12H15.0417C15.9147 2 16.7444 2.38028 17.3143 3.04161L18.5969 4.53001L20.245 6.38947C20.7314 6.93822 21 7.64611 21 8.37939L21 12V19C21 20.6569 19.6569 22 18 22ZM17.0909 5.84616L16 4.58022V7H18.1136L17.0909 5.84616ZM14 8V4L12 4L6 4C5.44772 4 5 4.44772 5 5L5 19C5 19.5523 5.44772 20 6 20L18 20C18.5523 20 19 19.5523 19 19L19 12V9H15C14.4477 9 14 8.55229 14 8ZM16.7653 10.222C16.9318 10.3638 17 10.5515 17 10.7369C17 11.1416 16.6417 11.3738 16.2406 11.4526C15.7387 11.5512 15.3638 11.7414 15.119 11.9944C14.897 12.2239 14.7734 12.514 14.7674 12.8559C15.504 13.0547 16.2286 13.6024 16.2286 14.3926V14.4275C16.2286 14.8712 16.0415 15.2659 15.704 15.5502C15.3661 15.8347 14.8876 16 14.3245 16C13.6917 16 13.1759 15.7951 12.8225 15.4202C12.4731 15.0496 12.2953 14.5269 12.2953 13.9052V13.8356C12.2953 12.5344 12.766 11.5546 13.5763 10.8781C14.2333 10.3294 15.2153 10 16.034 10C16.3505 10 16.5976 10.079 16.7653 10.222ZM11.47 10.222C11.6364 10.3638 11.7047 10.5515 11.7047 10.7369C11.7047 11.1416 11.3463 11.3738 10.9453 11.4526C10.4434 11.5512 10.0684 11.7414 9.82365 11.9944C9.60171 12.2239 9.47805 12.514 9.47207 12.8559C10.2086 13.0547 10.9333 13.6024 10.9333 14.3926V14.4275C10.9333 14.8712 10.7462 15.2659 10.4086 15.5502C10.0707 15.8347 9.59229 16 9.02919 16C8.39639 16 7.88052 15.7951 7.52714 15.4202C7.1778 15.0496 7 14.5269 7 13.9052V13.8356C7 12.5344 7.4707 11.5546 8.28091 10.8781C8.93798 10.3294 9.91995 10 10.7387 10C11.0551 10 11.3022 10.079 11.47 10.222Z"
        fill={color}
      />
    </g>
  </svg>
);

export default Icon;
