import { dataProvider } from '@api';
import {
  IProjectComment,
  ICommentType,
  CommonCommentPayload,
  PatchCommentPayload,
} from '@interfaces';
import { commentTypes, projectComment } from '@globalService';

const trimUrl = (url: string) => url?.replace('/api/v1/', '');

export const getCommentsWithPagination = async ({
  url,
}: {
  url: string;
}): Promise<{ results: IProjectComment[]; count: number }> =>
  url ? dataProvider.get(`${trimUrl(url)}`).json() : null;

export const postComment = async ({
  url,
  value,
}: {
  url: string;
  value: PatchCommentPayload;
}): Promise<Response> => dataProvider.post(trimUrl(url), { json: value }).json();

export const patchComment = async ({
  url,
  value,
}: {
  url: string;
  value: { tags?: string[]; is_pinned?: boolean };
}): Promise<Response> => dataProvider.patch(trimUrl(url), { json: value }).json();

export const getCommentTypes = async (): Promise<ICommentType> =>
  dataProvider.get(commentTypes()).json();

export const deleteComment = async ({
  projectId,
  commentId,
}: CommonCommentPayload): Promise<Response> =>
  dataProvider.delete(projectComment({ projectId, commentId })).json();
