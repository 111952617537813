import React from 'react';
import { UserListColumnType } from './common';
import { PersonRoundedIcon } from '@svgAsComponents';

const userIcon: UserListColumnType = {
  name: 'icon',
  columnText: '',
  width: '3%',
  renderCell: () => <PersonRoundedIcon />,
};

export default userIcon;
