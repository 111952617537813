import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 18 }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 18) * 24}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14994 0H11.1739L17.9745 7.08846V20.8756C17.9745 22.6027 16.5772 24 14.8561 24H3.14994C1.42281 24 0.0255127 22.6027 0.0255127 20.8756V3.12443C0.0254824 1.3973 1.42278 0 3.14994 0V0Z"
      fill="#D02C2B"
    />
    <path
      opacity="0.302"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1679 0V7.03449H17.9745L11.1679 0Z"
      fill="white"
    />
    <path
      d="M3.79761 17.1694V12.8096H5.65067C6.10644 12.8096 6.47227 12.9355 6.74813 13.1934C7.01798 13.4453 7.15592 13.7811 7.15592 14.2069C7.15592 14.6267 7.01798 14.9685 6.74813 15.2204C6.47227 15.4723 6.10644 15.5982 5.65067 15.5982H4.90704V17.1694H3.79761ZM4.90707 14.6507H5.52477C5.69267 14.6507 5.81861 14.6147 5.91458 14.5367C6.00452 14.4588 6.04652 14.3448 6.04652 14.2069C6.04652 14.063 6.00455 13.955 5.91458 13.8771C5.81864 13.7991 5.6927 13.7571 5.52477 13.7571H4.90707V14.6507ZM7.6237 17.1694V12.8096H9.47676C9.93253 12.8096 10.2984 12.9355 10.5742 13.1934C10.8501 13.4453 10.982 13.7811 10.982 14.2069C10.982 14.6267 10.8501 14.9685 10.5742 15.2204C10.2984 15.4723 9.93253 15.5982 9.47676 15.5982H8.73913V17.1694H7.6237ZM8.73913 14.6507H9.35083C9.51873 14.6507 9.65067 14.6147 9.74064 14.5367C9.83058 14.4588 9.87859 14.3448 9.87859 14.2069C9.87859 14.063 9.83061 13.955 9.74064 13.8771C9.65067 13.7991 9.51876 13.7571 9.35083 13.7571H8.73913V14.6507ZM12.5832 17.1694V13.7571H11.3418V12.8096H14.946V13.7571H13.6987V17.1694H12.5832Z"
      fill="white"
    />
  </svg>
);

export default Icon;
