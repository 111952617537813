/* eslint-disable no-console */
/* eslint-disable dot-notation */
/* eslint-disable prefer-template */
/* eslint-disable func-names */

import React, { useContext, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';

import {
  AddBorrowerCompany,
  Analytics,
  AppUpdateContext,
  AuthContext,
  CreateBudgetTableWrapper,
  CustomLoginCallback,
  DashboardDetails,
  DraftRequest,
  EmailConfirmation,
  ForgotPassword,
  getTeamRole,
  InactiveUserPage,
  InspectionResults,
  isLocalhost,
  isProduction,
  LoginErrorPage,
  LoginForm,
  LoginLayout,
  LoginWithSSO,
  MyCompany,
  NewProject,
  NoAvailableContentPage,
  OnboardingDashboard,
  OrderService,
  packageJsonVersion,
  PageLayout,
  Profile,
  Project,
  ProjectPeople,
  ProjectSettings,
  Projects,
  Report,
  RequestsQueue,
  ResendInvite,
  ResetPassword,
  ServicesQueue,
  ServiceResults,
  PlatformSettings,
} from 'encirclelabs-shared';

import { screensACL } from '@constants';
import config from '@config';

const { gtmId } = config;
const Router = () => {
  const { user } = useContext(AuthContext);
  const { updateAvailable } = useContext(AppUpdateContext);

  const defaultScreen =
    screensACL[getTeamRole(user) || user?.primary_role]?.default || '/no-content';

  return useRoutes([
    {
      path: '/',
      element: <PageLayout screensACL={screensACL} updateAvailable={updateAvailable} />,
      children: [
        { path: '/', element: <Navigate to={defaultScreen} replace /> },
        {
          path: 'home',
          element: <OnboardingDashboard />,
        },
        {
          path: 'projects',
          element: <Projects />,
        },
        { path: 'projects/add-new', element: <NewProject /> },
        { path: 'projects/:projectId/add-borrower', element: <AddBorrowerCompany /> },
        { path: 'projects/:projectId/order-service', element: <OrderService /> },
        { path: 'projects/:projectId/people', element: <ProjectPeople /> },
        { path: 'projects/:projectId/settings/*', element: <ProjectSettings /> },
        {
          path: 'projects/:projectId/requests/:requestId/:action',
          element: <DraftRequest />,
        },
        {
          path: 'projects/:projectId/inspection/:inspectionId/:action',
          element: <InspectionResults />,
        },
        {
          path: 'projects/:projectId/service/:serviceOrderId',
          element: <ServiceResults />,
        },
        { path: 'projects/:projectId/draws/all/:action', element: <CreateBudgetTableWrapper /> },
        { path: 'projects/:projectId/budget/:action', element: <CreateBudgetTableWrapper /> },
        { path: 'projects/:projectId/*', element: <Project /> },
        { path: 'requests/*', element: <RequestsQueue /> },
        { path: 'services/*', element: <ServicesQueue /> },
        { path: 'profile/*', element: <Profile /> },
        { path: 'company/:companyId/*', element: <MyCompany /> },
        {
          path: 'analytics',
          element: <Analytics />,
        },
        { path: 'platform-settings/*', element: <PlatformSettings /> },
        { path: 'analytics/:dashboardId', element: <DashboardDetails /> },
        { path: '*', element: <Projects /> },
      ],
    },
    {
      path: '/',
      element: <LoginLayout updateAvailable={updateAvailable} />,
      children: [
        { path: 'login', element: <LoginForm /> },
        {
          path: 'login-with-sso/callback',
          element: <CustomLoginCallback errorComponent={LoginErrorPage} />,
        },
        { path: 'login-with-sso', element: <LoginWithSSO /> },
        { path: 'email/confirm', element: <EmailConfirmation /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        { path: 'resend-invite', element: <ResendInvite /> },
      ],
    },
    { path: 'no-content', element: <NoAvailableContentPage /> },
    { path: 'deactivated-account', element: <InactiveUserPage /> },
    { path: 'projects/:projectId/report/:requestId', element: <Report /> },
  ]);
};

export default function WrappedRouter() {
  const { user } = useContext(AuthContext);
  const { id, first_name, last_name, email, active_team, last_login, agreed_to_terms, customers } =
    user || {};
  const gtmParamsProd = {
    gtmId,
    dataLayer: {
      user_id: id,
      email,
      first_name,
      last_name,
      role: active_team?.role,
      last_login,
      tos_agreed: agreed_to_terms,
      customer: active_team?.company?.is_customer ? active_team?.company?.name : '',
      customers,
    },
  };

  useEffect(() => {
    if (isProduction && user?.id) TagManager.initialize(gtmParamsProd);
    if (!isLocalhost && user?.id) {
      Sentry.setUser({ id, username: `${first_name} ${last_name}` });
      if (active_team?.company?.name) Sentry.setTag('user.company', active_team.company.name);
      datadogRum.setUser({
        id,
        email,
        name: `${first_name} ${last_name}`,
        company: active_team?.company?.name,
        role: active_team?.role,
      });
    }
    Sentry.setTag('appVersion', packageJsonVersion);
  }, [user]); // eslint-disable-line

  return <Router />;
}
