import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const ForApprovalIcon = ({ size = 16, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6351 1.72577C11.1117 1.20241 10.2632 1.20241 9.73984 1.72577L2.22683 9.23877C2.04054 9.42506 1.9133 9.66212 1.86099 9.92032L1.35995 12.3937C1.27076 12.834 1.40812 13.2897 1.72579 13.6074C2.04345 13.9251 2.49916 14.0624 2.93947 13.9732L5.41285 13.4722C5.67106 13.4199 5.90811 13.2926 6.0944 13.1063L13.6074 5.59334C14.1308 5.06998 14.1308 4.22146 13.6074 3.69811L11.6351 1.72577ZM3.04841 10.8085L2.96224 11.2339L2.6734 12.6598L4.09926 12.3709L4.52465 12.2848L3.04841 10.8085ZM3.74797 9.61286L5.72031 11.5852L10.8067 6.49885L8.83432 4.52651L3.74797 9.61286ZM12.6598 4.64572L11.7543 5.55124L9.78193 3.5789L10.6874 2.67338L11.6351 3.62099L11.7122 3.69811L12.6598 4.64572ZM14.2053 11.7261C14.4222 11.4286 14.3569 11.0116 14.0594 10.7946C13.7619 10.5777 13.3449 10.643 13.1279 10.9405L11.7003 12.8984L11.1893 12.2528C10.9608 11.9642 10.5415 11.9154 10.2528 12.1439C9.96413 12.3725 9.91538 12.7918 10.1439 13.0804L11.1995 14.4138C11.3288 14.5771 11.5271 14.6706 11.7354 14.6665C11.9437 14.6624 12.1381 14.5611 12.2608 14.3927L14.2053 11.7261Z"
      fill={color}
    />
  </svg>
);

export default ForApprovalIcon;
