import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M12.8533 2C11.7487 2 10.8533 2.89543 10.8533 4V4.34141C8.52289 5.16508 6.85328 7.38756 6.85328 10L6.85328 11.5L6.85328 13.5996L5.23581 15.8237C4.79336 16.432 4.7297 17.2372 5.07105 17.9076C5.4124 18.5779 6.10103 19 6.85328 19H9.85328C9.85328 20.6569 11.1964 22 12.8533 22C14.5101 22 15.8533 20.6569 15.8533 19H18.8533C19.6055 19 20.2942 18.5779 20.6355 17.9076C20.9769 17.2372 20.9132 16.432 20.4708 15.8237L18.8533 13.5996L18.8533 11.5V10C18.8533 7.38756 17.1837 5.16508 14.8533 4.34141V4C14.8533 2.89543 13.9578 2 12.8533 2ZM13.8545 17H13.8533H11.8533L9.32627 17H6.85328L8.30783 15L8.47075 14.776C8.71936 14.4341 8.85328 14.0223 8.85328 13.5996V11.5V10C8.85328 7.79086 10.6441 6 12.8533 6C15.0624 6 16.8533 7.79086 16.8533 10L16.8533 11.5V13.5996C16.8533 14.0223 16.9872 14.4341 17.2358 14.776L17.3987 15L18.8533 17H16.3803H13.8545ZM12.8533 20C12.301 20 11.8533 19.5523 11.8533 19H13.8533C13.8533 19.5523 13.4056 20 12.8533 20Z"
    />
  </svg>
);

export default Icon;
