import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11C10.3431 11 9 12.3431 9 14V16.4286V20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20V16.4286V14L7.00012 13.9645C6.83678 13.9879 6.6698 14 6.5 14C6.38994 14 6.28108 13.9949 6.17361 13.985C6.11723 13.9949 6.05921 14 6 14H5.57143C4.70355 14 4 14.7036 4 15.5714V18C4 18.5523 3.55228 19 3 19C2.44772 19 2 18.5523 2 18V15.5714C2 14.3004 2.66396 13.1845 3.66396 12.5515C3.24626 11.9751 3 11.2663 3 10.5C3 8.567 4.567 7 6.5 7C7.04254 7 7.55625 7.12344 8.01457 7.34378C8.00492 7.23047 8 7.11581 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 7.11581 15.9951 7.23047 15.9854 7.34378C16.4438 7.12345 16.9575 7 17.5 7C19.433 7 21 8.567 21 10.5C21 11.2663 20.7537 11.9751 20.336 12.5515C21.336 13.1845 22 14.3004 22 15.5714V18C22 18.5523 21.5523 19 21 19C20.4477 19 20 18.5523 20 18V15.5714C20 14.7036 19.2964 14 18.4286 14H18C17.9408 14 17.8828 13.9949 17.8264 13.985C17.7189 13.9949 17.6101 14 17.5 14C17.3302 14 17.1632 13.9879 16.9999 13.9645L17 14V16.4286V20C17 20.5523 16.5523 21 16 21C15.4477 21 15 20.5523 15 20V16.4286V14C15 12.3431 13.6569 11 12 11ZM12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7C14 8.10457 13.1046 9 12 9ZM6.5 9C5.67157 9 5 9.67157 5 10.5C5 11.3284 5.67157 12 6.5 12C7.32843 12 8 11.3284 8 10.5C8 9.67157 7.32843 9 6.5 9ZM17.5 9C16.6716 9 16 9.67157 16 10.5C16 11.3284 16.6716 12 17.5 12C18.3284 12 19 11.3284 19 10.5C19 9.67157 18.3284 9 17.5 9Z"
      fill={color}
    />
  </svg>
);

export default Icon;
