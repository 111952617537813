import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useParams } from 'react-router-dom';
import { useQueries, UseQueryResult } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import { AuthContext } from '@context';
import { defaultPersonalSetting } from '@constants';
import { useUpdateUiSettings } from '@hooks';
import { ErrorDual, IProject, IUISettings, QueryNamesEnums } from '@interfaces';
import { getProject, getProjectSettings, getUISettings } from '@globalService';
import { checkIsPHBProject, isActiveProject, isInactiveProject, isQAEnvironment } from '@utils';

interface ISettingsContext {
  settings: Partial<IUISettings>;
  setCurrentProjectArchived: Dispatch<SetStateAction<boolean>>;
  setCurrentProjectActive: Dispatch<SetStateAction<boolean>>;
  isCurrentProjectArchived: boolean;
  isCurrentProjectActive: boolean;
  isPHBProject: boolean;
  setPHBProject: Dispatch<SetStateAction<boolean>>;
}

const initSettings = {
  settings: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentProjectArchived: () => {},
  setCurrentProjectActive: () => {},
  isCurrentProjectArchived: true,
  isCurrentProjectActive: false,
  isPHBProject: true,
  setPHBProject: () => {},
};
export const SettingsContext = createContext<ISettingsContext>(initSettings);

// isQAEnvironment flas is used to set red favicon for test environment
// to clear differentiate it from production sites
const favicon = document.getElementById('favicon') as HTMLAnchorElement | null;

export const SettingsProvider = ({ children }) => {
  const { updateSettings } = useUpdateUiSettings();
  const { user } = useContext(AuthContext);
  const [settings, setSettings] = useState({});
  //TODO rename isCurrentProjectArchived to isCurrentProjectInactive
  const [isCurrentProjectArchived, setCurrentProjectArchived] = useState(false);
  const [isCurrentProjectActive, setCurrentProjectActive] = useState(false);
  const [isPHBProject, setPHBProject] = useState(true);
  const { projectId } = useParams();

  useEffect(() => {
    // red favicon for test environment
    if (isQAEnvironment) favicon.href = `${window.location.origin}/sandbox_favicon.ico`;
  }, []);

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
      enabled: Boolean(projectId && user?.isAllowedToLogin),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_SETTINGS, { projectId }],
      queryFn: getProjectSettings.bind(this, projectId),
      enabled: Boolean(projectId && user?.isAllowedToLogin),
    },
    {
      queryKey: [QueryNamesEnums.GET_UI_SETTINGS],
      queryFn: getUISettings.bind(this),
      enabled: Boolean(user?.agreed_to_terms),
    },
  ]);

  const { data: project } = requestedDataQueries[0] as UseQueryResult<IProject, ErrorDual>;
  const { data: projectSettings } = requestedDataQueries[1] as UseQueryResult<IUISettings, Error>;
  const { data: uiSettings } = requestedDataQueries[2] as UseQueryResult<IUISettings, Error>;

  useEffect(() => {
    if (uiSettings && (projectId ? projectSettings : true)) {
      // if it's new user we save set of initial personal settings (table hidden columns) on BE
      // and it'll act like data migration for existing users
      // run only after user logged in
      if (user?.isAllowedToLogin) {
        if (isEmpty(uiSettings.personal_setting)) {
          updateSettings({ personal_setting: defaultPersonalSetting });
        } else {
          let shouldBeUpdated = false;
          const newPersonalSettings = cloneDeep(uiSettings.personal_setting);
          // check on root key level
          Object.keys(defaultPersonalSetting).forEach((key) => {
            if (!Object.keys(uiSettings.personal_setting).includes(key)) {
              newPersonalSettings[key] = defaultPersonalSetting[key];
              shouldBeUpdated = true;
            }
          });
          // check inside tables level if tables already exist
          if (uiSettings.personal_setting?.tables)
            Object.keys(defaultPersonalSetting.tables).forEach((key) => {
              if (!Object.keys(uiSettings.personal_setting?.tables)?.includes(key)) {
                newPersonalSettings.tables[key] = defaultPersonalSetting.tables[key];
                shouldBeUpdated = true;
              }
            });

          if (shouldBeUpdated)
            updateSettings({
              personal_setting: newPersonalSettings,
            });
        }
      }

      setSettings({
        ...uiSettings,
        ...(projectSettings || {}),
        ...(!uiSettings.personal_setting ? { personal_setting: defaultPersonalSetting } : {}),
        display: {
          ...uiSettings.display,
          ...projectSettings?.display,
        },
      });
    }
  }, [uiSettings, projectId, projectSettings, user?.isAllowedToLogin]);

  useEffect(() => {
    setCurrentProjectArchived(isInactiveProject(project?.status));
    setCurrentProjectActive(isActiveProject(project?.status));
  }, [project?.status]);

  useEffect(() => {
    if (project) setPHBProject(checkIsPHBProject(project));
  }, [project?.is_advanced_budget_tracking_enabled]);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        isCurrentProjectArchived,
        setCurrentProjectArchived,
        isCurrentProjectActive,
        setCurrentProjectActive,
        isPHBProject,
        setPHBProject,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
