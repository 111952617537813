import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.gray, hasValues }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 22.5L18 22.5C19.6569 22.5 21 21.1569 21 19.5L21 8.87939C21 8.14611 20.7314 7.43822 20.245 6.88947L18.5969 5.03001L17.3143 3.54161C16.7444 2.88028 15.9147 2.5 15.0417 2.5H6C4.34315 2.5 3 3.84314 3 5.5V19.5C3 21.1569 4.34315 22.5 6 22.5ZM15.7992 4.8472L17.0909 6.34616L18.7483 8.21608C18.9105 8.399 19 8.63496 19 8.87939L19 19.5C19 20.0523 18.5523 20.5 18 20.5L6 20.5C5.44772 20.5 5 20.0523 5 19.5L5 5.5C5 4.94772 5.44772 4.5 6 4.5L15.0417 4.5C15.3327 4.5 15.6092 4.62676 15.7992 4.8472ZM14.2803 8.71967C13.9874 8.42678 13.5126 8.42678 13.2197 8.71967L8.21967 13.7197C8.07902 13.8603 8 14.0511 8 14.25V15.75C8 16.1642 8.33579 16.5 8.75 16.5H10.25C10.4489 16.5 11.6397 15.421 11.7803 15.2803L15.7803 11.2803C16.0732 10.9874 16.0732 10.5126 15.7803 10.2197L14.2803 8.71967Z"
      fill={color}
    />
    {hasValues && <circle cx="19.8835" cy="5" r="4" fill={colors.status.information.medium} />}
  </svg>
);

export default Icon;
