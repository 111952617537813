import React, { FC, MouseEvent, useContext, useEffect, useMemo } from 'react';
import { useFilters, useSortBy, useTable } from 'react-table';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { SortIcon } from '@svgAsComponents';
import { ComponentProps } from './interface';
import { colors, fonts, sizes } from '@theme';
import { ColumnsFilter, ContextMenu, DataTableV3 } from '@components';
import { AuthContext, useLaunchDarklyFlags } from '@context';
import { useContextMenu } from '@hooks';
import { getLink, getTeamRole } from '@utils';
import { TableKeyEnum } from '@interfaces';

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const ReactTable = ({
  columns,
  hiddenColumns = [''],
  isColumnFilterUpdating,
  changeFieldVisibility,
  data,
  footer = true,
  onRowClick,
  maxHeight,
  minHeight,
  dataTestName,
  initialSortBy = [],
  handleSortClick = () => {},
  manualSortBy = false,
  hideHeader = false,
  source,
  tableKey,
  showContextMenu,
  headerBgColor,
  showVerticalBorders = false,
}: ComponentProps) => {
  const flags = useLaunchDarklyFlags();

  const defaultColumn = React.useMemo(
    () => ({
      width: 0,
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      sortType: 'basic',
    }),
    [],
  );

  const filterTypes = React.useMemo(
    () => ({
      // custom filter method for rows with subrows
      // build-in method has bug to clear subRows when filtering
      // https://github.com/tannerlinsley/react-table/issues/2064
      filterRowsWithSubrows: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];

          return rowValue !== undefined
            ? String(rowValue).toLowerCase() === String(filterValue).toLowerCase()
            : true;
        });
      },
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      manualSortBy,
      manualFilters: true,
      initialState: {
        hiddenColumns,
        sortBy: initialSortBy,
      },
    },
    useFilters,
    useSortBy,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    allColumns,
    state: { sortBy },
    setSortBy,
  } = tableInstance;

  const { contextMenu, handleContextMenuClose, handleContextMenuOpen } = useContextMenu();

  useEffect(() => {
    if (JSON.stringify(initialSortBy) !== JSON.stringify(sortBy)) handleSortClick(sortBy);
  }, [sortBy]);

  useEffect(() => {
    if (JSON.stringify(initialSortBy) !== JSON.stringify(sortBy)) setSortBy(initialSortBy);
  }, [initialSortBy]);

  // Function to open link in new tab
  const openLinkInNewTab = () => {
    if (contextMenu.link) {
      window.open(contextMenu.link, '_blank');
    }
    handleContextMenuClose();
  };

  const contextMenuList = useMemo(
    () => [
      {
        text: 'Open link in new tab',
        action: openLinkInNewTab,
      },
    ],
    [contextMenu],
  );

  const gridColumns = useMemo(
    () =>
      columns.map((col) => ({
        field: col.accessor,
        headerName: col.Header,
        width: 150,
      })),
    [columns],
  );

  const gridRows = useMemo(
    () =>
      (rows || [])
        .map((row) => {
          prepareRow(row);
          return {
            id: row.id || Date.now(),
            ...row.values,
          };
        })
        .filter((i) => i.id),
    [rows, prepareRow],
  );

  return flags?.[`ENG_7895_table_v3_2_${tableKey}`] ? (
    <DataTableV3 tableKey={tableKey} rows={gridRows} columns={gridColumns} />
  ) : (
    <TableContainer data-test-name={dataTestName} sx={{ maxHeight, minHeight }}>
      <Table
        sx={{ maxWidth: '100%', overflowX: 'auto', fontSize: 10 }}
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
      >
        {!hideHeader && (
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, index) => {
                  const isLastColumn = index === headerGroup.headers.length - 1;
                  return (
                    <TableCell
                      sx={[
                        (theme) => ({ padding: theme.spacing(1, 0.5, 1, 2) }),
                        {
                          borderRight: showVerticalBorders
                            ? `1px solid ${colors.neutral.lighter}`
                            : 'none',
                          borderBottom: `1px solid ${colors.neutral.lighter}`,
                          backgroundColor: headerBgColor,
                        },
                        column.minWidth ? { minWidth: column.minWidth } : {},
                      ]}
                      key={column.render('Header')}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({ title: undefined }),
                        )}
                      >
                        <Typography
                          variant="labelSemiBold"
                          color={colors.text.medium}
                          sx={{ whiteSpace: 'normal' }}
                        >
                          {column.render('Header')}
                        </Typography>
                        {/* Add a sort direction indicator
                      only for columns with header */}
                        {!column.disableSortBy && column.render('Header') && (
                          <Box
                            sx={[
                              { display: 'flex', justifyContent: 'center' },
                              (theme) => ({ marginLeft: theme.spacing(1) }),
                              !column.isSorted && { opacity: 0 },
                              !column.isSortedDesc && {
                                transform: 'rotate(180deg)',
                                transition: 'transform .5s',
                              },
                            ]}
                          >
                            <SortIcon />
                          </Box>
                        )}

                        {isLastColumn && changeFieldVisibility !== undefined && (
                          <span>
                            <ColumnsFilter
                              columns={allColumns}
                              changeFieldVisibility={changeFieldVisibility}
                              isUpdating={isColumnFilterUpdating}
                              source={source}
                            />
                          </span>
                        )}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
        )}
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <RowComponent
                row={row}
                index={index}
                key={index.toString()}
                onRowClick={onRowClick}
                handleContextMenuOpen={handleContextMenuOpen}
                tableKey={tableKey}
                showContextMenu={showContextMenu}
                showVerticalBorders={showVerticalBorders}
              />
            );
          })}
        </TableBody>
        {footer && (
          <TableFooter>
            {footerGroups.map((group) => (
              <TableRow {...group.getFooterGroupProps()}>
                {group.headers.map(
                  (column) =>
                    column.Header !== 'Filter' && (
                      <TableCell
                        sx={{
                          borderTop: `1px solid ${colors.neutral.lighter}`,
                          borderRight: showVerticalBorders
                            ? `1px solid ${colors.neutral.lighter}`
                            : 'none',
                        }}
                        {...column.getFooterProps()}
                      >
                        {column.render('Footer')}
                      </TableCell>
                    ),
                )}
              </TableRow>
            ))}
          </TableFooter>
        )}
      </Table>

      <ContextMenu
        xPos={contextMenu.xPos}
        yPos={contextMenu.yPos}
        open={contextMenu.open}
        closeMenu={handleContextMenuClose}
        menuItems={contextMenuList}
      />
    </TableContainer>
  );
};

export default ReactTable;

const RowComponent: FC<{
  row: any;
  index: number;
  onRowClick: (row) => void;
  tableKey: TableKeyEnum;
  handleContextMenuOpen: (event: React.MouseEvent, link?: string) => void;
  showContextMenu: boolean;
  showVerticalBorders: boolean;
}> = ({
  row,
  index,
  onRowClick,
  handleContextMenuOpen,
  showContextMenu,
  tableKey,
  showVerticalBorders,
}) => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const link = getLink({ row: row?.original, tableKey, teamRole });

  const { backgroundColor = 'transparent', borderColor = 'transparent' } =
    row?.original?.colorCodingScheme || {};

  return (
    <TableRow
      key={index.toString()}
      hover
      sx={{ breakInside: 'avoid', '&:last-of-type': { '& td': { borderBottom: 'none' } } }}
      {...row.getRowProps({
        style: {
          cursor: link || onRowClick ? 'pointer' : 'unset',
        },
        onContextMenu: (e) => {
          if (!showContextMenu) return;
          handleContextMenuOpen(e, link);
        },
      })}
      onClick={(event: MouseEvent) => {
        if (link && (event.ctrlKey || event.metaKey)) {
          window.open(link);
          return;
        }
        onRowClick?.(row?.original);
      }}
    >
      {row.cells.map((cell, index) => {
        return (
          <TableCell
            key={index.toString()}
            {...cell.getCellProps({
              style: {
                fontWeight: '400',
                ...row.original.rowStyle,
                backgroundColor,
                borderLeft: index === 0 ? `5px solid ${borderColor}` : 'none',
                borderRight: showVerticalBorders ? `1px solid ${colors.neutral.lighter}` : 'none',
              },
            })}
            sx={{
              fontFamily: fonts.primary,
              fontSize: sizes.fontSizes.small,
              ['@media (max-width: 600px)']: { fontSize: sizes.fontSizes.xSmall },
              color: colors.text.dark,
            }}
          >
            {cell.render('Cell')}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
