import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 17, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7945 7.78071L14.8209 2.71116C13.6668 1.73166 11.9637 1.76547 10.8493 2.79002L4.82283 8.33074C4.20492 8.89884 3.85327 9.69983 3.85327 10.5392V19.0013C3.85327 20.6582 5.19642 22.0013 6.85327 22.0013H18.8533C20.5101 22.0013 21.8533 20.6582 21.8533 19.0013V10.068C21.8533 9.18711 21.4661 8.35072 20.7945 7.78071ZM12.2029 4.26233C12.5744 3.92081 13.1421 3.90954 13.5268 4.23604L19.5003 9.30559C19.7242 9.49559 19.8533 9.77439 19.8533 10.068V19.0013C19.8533 19.5536 19.4056 20.0013 18.8533 20.0013H16.8533C16.8533 19.9988 16.8533 19.9962 16.8533 19.9937V15.0283C16.8533 13.9237 15.9578 13.0283 14.8533 13.0283H10.8533C9.7487 13.0283 8.85327 13.9237 8.85327 15.0283V19.9937C8.85327 19.9962 8.85328 19.9988 8.85329 20.0013H6.85327C6.30099 20.0013 5.85327 19.5536 5.85327 19.0013V10.5392C5.85327 10.2594 5.97049 9.99241 6.17646 9.80304L12.2029 4.26233ZM10.8533 15.0283H14.8533V19.9937H10.8533V15.0283Z"
      fill={color}
    />
  </svg>
);

export default Icon;
