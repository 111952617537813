import { currencyFormatter } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const revisedMilestoneAmount: MilestoneListColumnTypeV2 = {
  field: 'revised_milestone_amount',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Revised scheduled value ($)',
  description: 'Variance between inspection allowance and lender allowance',
  valueFormatter: (_value, row) => currencyFormatter(row.revised_estimate, ''),
  minWidth: 120,
  editable: false,
};

export default revisedMilestoneAmount;
