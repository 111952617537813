import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 200 }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M102.249 17.0855L96.2142 18.6426L21.8679 37.8259L15.8334 39.3829C12.9044 40.1422 10.3959 42.0331 8.85843 44.6406C7.32093 47.2481 6.87995 50.3594 7.63223 53.2918L33.7991 154.819C34.558 157.749 36.4478 160.259 39.0538 161.798C41.6598 163.336 44.7693 163.777 47.7001 163.025L47.7157 163.021L134.1 140.731L134.115 140.727C137.044 139.968 139.553 138.077 141.09 135.47C142.628 132.862 143.069 129.751 142.317 126.818L116.15 25.2915C115.391 22.3607 113.501 19.8508 110.895 18.3125C108.289 16.7741 105.179 16.3328 102.249 17.0855Z"
      fill="#E8E8E8"
    />
    <path
      d="M103.07 20.2693L95.9223 22.1135L23.8017 40.7225L16.6544 42.5667C14.5685 43.1074 12.7822 44.4539 11.6873 46.3108C10.5924 48.1677 10.2784 50.3833 10.8141 52.4715L36.981 153.998C37.5214 156.085 38.8672 157.873 40.723 158.968C42.5788 160.064 44.7932 160.378 46.8802 159.842L46.8958 159.838L133.28 137.549L133.296 137.545C135.381 137.004 137.168 135.657 138.263 133.8C139.357 131.944 139.671 129.728 139.136 127.64L112.969 26.1129C112.428 24.0259 111.083 22.2386 109.227 21.1431C107.371 20.0475 105.157 19.7333 103.07 20.2693Z"
      fill="white"
    />
    <path
      d="M97.6723 53.1449L53.9727 64.4205C53.4847 64.5464 52.9666 64.4732 52.5325 64.217C52.0983 63.9607 51.7837 63.5424 51.6579 63.054C51.532 62.5656 51.6052 62.0473 51.8613 61.6129C52.1174 61.1785 52.5355 60.8637 53.0236 60.7378L96.7232 49.4622C97.2111 49.3367 97.7288 49.4103 98.1626 49.6666C98.5963 49.9229 98.9106 50.341 99.0364 50.8291C99.1622 51.3172 99.0892 51.8352 98.8334 52.2695C98.5776 52.7037 98.16 53.0186 97.6723 53.1449Z"
      fill="#E8E8E8"
    />
    <path
      d="M106.865 57.4011L55.5743 70.6355C55.0862 70.7615 54.5681 70.6882 54.134 70.432C53.6999 70.1757 53.3853 69.7574 53.2594 69.269C53.1336 68.7807 53.2067 68.2623 53.4629 67.8279C53.719 67.3935 54.1371 67.0788 54.6252 66.9528L105.916 53.7184C106.404 53.5925 106.922 53.6657 107.356 53.922C107.79 54.1783 108.105 54.5966 108.231 55.0849C108.357 55.5733 108.284 56.0917 108.027 56.5261C107.771 56.9604 107.353 57.2752 106.865 57.4011Z"
      fill="#E8E8E8"
    />
    <path
      d="M104.85 80.9969L61.1505 92.2725C60.9088 92.3349 60.6572 92.349 60.4101 92.314C60.163 92.2791 59.9251 92.1958 59.7102 92.0689C59.4952 91.9421 59.3074 91.774 59.1573 91.5745C59.0072 91.375 58.8979 91.1478 58.8356 90.906C58.7733 90.6642 58.7592 90.4125 58.7941 90.1652C58.829 89.9179 58.9122 89.68 59.039 89.4649C59.1659 89.2498 59.3338 89.0618 59.5332 88.9117C59.7326 88.7615 59.9597 88.6521 60.2013 88.5898L103.901 77.3141C104.143 77.2518 104.394 77.2377 104.641 77.2726C104.888 77.3075 105.126 77.3908 105.341 77.5177C105.556 77.6446 105.744 77.8126 105.894 78.0121C106.044 78.2117 106.153 78.4388 106.216 78.6807C106.278 78.9225 106.292 79.1742 106.257 79.4215C106.222 79.6687 106.139 79.9067 106.012 80.1218C105.886 80.3368 105.718 80.5248 105.518 80.675C105.319 80.8251 105.092 80.9345 104.85 80.9969Z"
      fill="#E8E8E8"
    />
    <path
      d="M114.043 85.2527L62.752 98.4871C62.5104 98.5495 62.2588 98.5636 62.0117 98.5287C61.7645 98.4937 61.5267 98.4104 61.3118 98.2836C61.0968 98.1567 60.9089 97.9886 60.7589 97.7891C60.6088 97.5896 60.4995 97.3624 60.4372 97.1206C60.3748 96.8788 60.3607 96.6271 60.3956 96.3798C60.4305 96.1325 60.5138 95.8946 60.6406 95.6795C60.7674 95.4644 60.9353 95.2764 61.1348 95.1263C61.3342 94.9761 61.5612 94.8668 61.8029 94.8044L113.094 81.57C113.582 81.4441 114.1 81.5173 114.534 81.7736C114.968 82.0298 115.283 82.4482 115.409 82.9365C115.535 83.4249 115.461 83.9433 115.205 84.3776C114.949 84.812 114.531 85.1268 114.043 85.2527Z"
      fill="#E8E8E8"
    />
    <path
      d="M112.028 108.846L68.3289 120.122C67.841 120.248 67.3232 120.174 66.8893 119.918C66.4555 119.662 66.1411 119.243 66.0153 118.755C65.8895 118.267 65.9625 117.749 66.2184 117.315C66.4743 116.88 66.892 116.566 67.3797 116.439L111.079 105.164C111.567 105.038 112.086 105.111 112.52 105.367C112.954 105.624 113.268 106.042 113.394 106.53C113.52 107.019 113.447 107.537 113.191 107.971C112.935 108.406 112.517 108.721 112.028 108.846Z"
      fill="#E8E8E8"
    />
    <path
      d="M121.22 113.102L69.9295 126.337C69.6877 126.399 69.436 126.414 69.1887 126.379C68.9414 126.344 68.7034 126.261 68.4883 126.134C68.2731 126.007 68.0851 125.839 67.9349 125.639C67.7847 125.44 67.6753 125.213 67.6129 124.971C67.5506 124.729 67.5365 124.477 67.5715 124.229C67.6065 123.982 67.6898 123.744 67.8168 123.529C67.9438 123.314 68.1119 123.126 68.3116 122.976C68.5112 122.825 68.7384 122.716 68.9803 122.654L120.271 109.42C120.759 109.294 121.277 109.367 121.711 109.623C122.146 109.879 122.46 110.298 122.586 110.786C122.712 111.274 122.639 111.793 122.383 112.227C122.127 112.662 121.708 112.976 121.22 113.102Z"
      fill="#E8E8E8"
    />
    <path
      d="M46.5128 75.9823L31.048 79.9726C30.8139 80.0327 30.5656 79.9975 30.3576 79.8747C30.1495 79.7518 29.9986 79.5514 29.938 79.3174L26.388 65.5437C26.328 65.3095 26.3632 65.0611 26.4859 64.8529C26.6087 64.6447 26.809 64.4937 27.0429 64.4331L42.5078 60.4427C42.7418 60.3826 42.9901 60.4179 43.1982 60.5407C43.4063 60.6635 43.5572 60.8639 43.6178 61.098L47.1677 74.8717C47.2278 75.1058 47.1926 75.3543 47.0698 75.5625C46.947 75.7707 46.7467 75.9217 46.5128 75.9823Z"
      fill="#E8E8E8"
    />
    <path
      d="M53.6906 103.834L38.2257 107.824C37.9917 107.884 37.7434 107.849 37.5353 107.726C37.3272 107.603 37.1763 107.403 37.1157 107.169L33.5658 93.3952C33.5057 93.1611 33.5409 92.9127 33.6637 92.7045C33.7864 92.4962 33.9867 92.3453 34.2206 92.2846L49.6855 88.2943C49.9195 88.2342 50.1678 88.2694 50.3759 88.3923C50.584 88.5151 50.7349 88.7155 50.7955 88.9495L54.3454 102.723C54.4055 102.957 54.3703 103.206 54.2475 103.414C54.1248 103.622 53.9245 103.773 53.6906 103.834Z"
      fill="#E8E8E8"
    />
    <path
      d="M60.8683 131.683L45.4034 135.674C45.1694 135.734 44.9211 135.699 44.713 135.576C44.5049 135.453 44.354 135.253 44.2934 135.019L40.7435 121.245C40.6834 121.011 40.7186 120.762 40.8414 120.554C40.9642 120.346 41.1645 120.195 41.3984 120.134L56.8632 116.144C57.0973 116.084 57.3456 116.119 57.5536 116.242C57.7617 116.365 57.9126 116.565 57.9732 116.799L61.5232 130.573C61.5832 130.807 61.548 131.055 61.4253 131.264C61.3025 131.472 61.1022 131.623 60.8683 131.683Z"
      fill="#E8E8E8"
    />
    <path
      d="M162.518 42.8223H73.2759C70.2501 42.8257 67.3493 44.0299 65.2097 46.1706C63.0702 48.3114 61.8667 51.2139 61.8633 54.2414V159.09C61.8667 162.117 63.0702 165.02 65.2097 167.161C67.3493 169.301 70.2501 170.506 73.2759 170.509H162.518C165.544 170.506 168.445 169.301 170.585 167.161C172.724 165.02 173.928 162.117 173.931 159.09V54.2414C173.928 51.2139 172.724 48.3114 170.585 46.1706C168.445 44.0299 165.544 42.8257 162.518 42.8223Z"
      fill="#E8E8E8"
    />
    <path
      d="M162.518 46.1094H73.2757C71.1209 46.1118 69.0551 46.9693 67.5315 48.4938C66.0079 50.0183 65.1509 52.0852 65.1484 54.2412V159.09C65.1509 161.246 66.0079 163.313 67.5315 164.837C69.0551 166.361 71.1209 167.219 73.2757 167.221H162.518C164.673 167.219 166.739 166.361 168.262 164.837C169.786 163.312 170.643 161.246 170.645 159.09V54.2412C170.643 52.0853 169.786 50.0183 168.262 48.4938C166.739 46.9693 164.673 46.1118 162.518 46.1094Z"
      fill="white"
    />
    <path
      d="M171.894 183.265C183.4 183.265 192.727 173.932 192.727 162.419C192.727 150.907 183.4 141.574 171.894 141.574C160.388 141.574 151.061 150.907 151.061 162.419C151.061 173.932 160.388 183.265 171.894 183.265Z"
      fill="#1F4739"
    />
    <path
      d="M149.089 103.723H103.959C103.71 103.723 103.462 103.674 103.231 103.579C103.001 103.484 102.791 103.343 102.614 103.167C102.437 102.99 102.297 102.781 102.202 102.55C102.106 102.319 102.057 102.071 102.057 101.822C102.057 101.572 102.106 101.324 102.202 101.093C102.297 100.862 102.437 100.653 102.614 100.476C102.791 100.3 103.001 100.159 103.231 100.064C103.462 99.9686 103.71 99.9196 103.959 99.9199H149.089C149.592 99.9205 150.075 100.121 150.431 100.478C150.787 100.834 150.987 101.318 150.987 101.822C150.987 102.325 150.787 102.809 150.431 103.165C150.075 103.522 149.592 103.723 149.089 103.723Z"
      fill="#E8E8E8"
    />
    <path
      d="M156.928 110.141H103.959C103.71 110.141 103.462 110.092 103.231 109.997C103.001 109.901 102.791 109.761 102.614 109.585C102.437 109.408 102.297 109.199 102.202 108.968C102.106 108.737 102.057 108.489 102.057 108.239C102.057 107.99 102.106 107.742 102.202 107.511C102.297 107.28 102.437 107.071 102.614 106.894C102.791 106.717 103.001 106.577 103.231 106.482C103.462 106.387 103.71 106.338 103.959 106.338H156.928C157.432 106.338 157.916 106.538 158.272 106.895C158.628 107.251 158.829 107.735 158.829 108.239C158.829 108.744 158.628 109.227 158.272 109.584C157.916 109.941 157.432 110.141 156.928 110.141Z"
      fill="#E8E8E8"
    />
    <path
      d="M149.089 132.485H103.959C103.71 132.485 103.462 132.436 103.231 132.341C103.001 132.245 102.791 132.105 102.614 131.929C102.437 131.752 102.297 131.542 102.202 131.311C102.106 131.081 102.057 130.833 102.057 130.583C102.057 130.333 102.106 130.086 102.202 129.855C102.297 129.624 102.437 129.414 102.614 129.238C102.791 129.061 103.001 128.921 103.231 128.826C103.462 128.73 103.71 128.681 103.959 128.682H149.089C149.593 128.682 150.076 128.882 150.432 129.239C150.789 129.595 150.989 130.079 150.989 130.583C150.989 131.088 150.789 131.571 150.432 131.928C150.076 132.284 149.593 132.485 149.089 132.485Z"
      fill="#E8E8E8"
    />
    <path
      d="M156.928 138.903H103.959C103.71 138.903 103.462 138.854 103.231 138.759C103.001 138.663 102.791 138.523 102.614 138.347C102.437 138.17 102.297 137.96 102.202 137.729C102.106 137.499 102.057 137.251 102.057 137.001C102.057 136.751 102.106 136.504 102.202 136.273C102.297 136.042 102.437 135.832 102.614 135.656C102.791 135.479 103.001 135.339 103.231 135.244C103.462 135.148 103.71 135.099 103.959 135.1H156.928C157.178 135.099 157.425 135.148 157.656 135.244C157.887 135.339 158.097 135.479 158.273 135.656C158.45 135.832 158.59 136.042 158.686 136.273C158.782 136.504 158.831 136.751 158.831 137.001C158.831 137.251 158.782 137.499 158.686 137.729C158.59 137.96 158.45 138.17 158.273 138.347C158.097 138.523 157.887 138.663 157.656 138.759C157.425 138.854 157.178 138.903 156.928 138.903Z"
      fill="#E8E8E8"
    />
    <path
      d="M93.8489 113.054H77.8781C77.6365 113.054 77.4049 112.957 77.234 112.786C77.0632 112.615 76.9671 112.384 76.9668 112.142V97.9177C76.9671 97.6759 77.0632 97.4442 77.234 97.2732C77.4049 97.1023 77.6365 97.0061 77.8781 97.0059H93.8489C94.0905 97.0061 94.3222 97.1023 94.493 97.2732C94.6639 97.4442 94.76 97.6759 94.7602 97.9177V112.142C94.76 112.384 94.6639 112.615 94.493 112.786C94.3222 112.957 94.0905 113.054 93.8489 113.054Z"
      fill="#E8E8E8"
    />
    <path
      d="M93.8489 141.816H77.8781C77.6365 141.815 77.4049 141.719 77.234 141.548C77.0632 141.377 76.9671 141.145 76.9668 140.904V126.679C76.9671 126.438 77.0632 126.206 77.234 126.035C77.4049 125.864 77.6365 125.768 77.8781 125.768H93.8489C94.0905 125.768 94.3222 125.864 94.493 126.035C94.6639 126.206 94.76 126.438 94.7602 126.679V140.904C94.76 141.145 94.6639 141.377 94.493 141.548C94.3222 141.719 94.0905 141.815 93.8489 141.816Z"
      fill="#E8E8E8"
    />
    <path
      d="M149.137 71.7055H115.865C115.361 71.7055 114.878 71.5052 114.521 71.1486C114.165 70.7919 113.965 70.3083 113.965 69.8039C113.965 69.2996 114.165 68.8159 114.521 68.4593C114.878 68.1027 115.361 67.9023 115.865 67.9023H149.137C149.641 67.9023 150.125 68.1027 150.481 68.4593C150.838 68.8159 151.038 69.2996 151.038 69.8039C151.038 70.3083 150.838 70.7919 150.481 71.1486C150.125 71.5052 149.641 71.7055 149.137 71.7055Z"
      fill="#5E8376"
    />
    <path
      d="M156.977 78.1235H115.865C115.616 78.1235 115.369 78.0743 115.138 77.9787C114.907 77.8832 114.698 77.7431 114.521 77.5665C114.345 77.39 114.205 77.1803 114.11 76.9496C114.014 76.7189 113.965 76.4716 113.965 76.2219C113.965 75.9722 114.014 75.7249 114.11 75.4942C114.205 75.2635 114.345 75.0539 114.521 74.8773C114.698 74.7007 114.907 74.5606 115.138 74.4651C115.369 74.3695 115.616 74.3203 115.865 74.3203H156.977C157.481 74.3203 157.964 74.5207 158.321 74.8773C158.677 75.2339 158.877 75.7176 158.877 76.2219C158.877 76.7262 158.677 77.2099 158.321 77.5665C157.964 77.9231 157.481 78.1235 156.977 78.1235Z"
      fill="#5E8376"
    />
    <path
      d="M108.503 85.9014H77.8293C77.5877 85.9012 77.356 85.805 77.1852 85.6341C77.0143 85.4631 76.9182 85.2314 76.918 84.9896V61.0368C76.9182 60.7951 77.0143 60.5633 77.1852 60.3924C77.356 60.2214 77.5877 60.1253 77.8293 60.125H108.503C108.745 60.1253 108.976 60.2214 109.147 60.3924C109.318 60.5633 109.414 60.7951 109.414 61.0368V84.9896C109.414 85.2314 109.318 85.4631 109.147 85.6341C108.976 85.805 108.745 85.9012 108.503 85.9014Z"
      fill="#1F4739"
    />
    <path
      d="M170.072 169.095C169.671 169.096 169.281 168.966 168.96 168.725L168.94 168.71L164.751 165.504C164.36 165.203 164.104 164.759 164.039 164.27C163.975 163.781 164.107 163.285 164.408 162.894C164.708 162.502 165.151 162.245 165.64 162.18C166.13 162.116 166.624 162.248 167.016 162.548L169.729 164.63L176.141 156.26C176.29 156.066 176.475 155.903 176.687 155.781C176.898 155.659 177.132 155.579 177.374 155.547C177.616 155.515 177.862 155.531 178.098 155.594C178.335 155.658 178.556 155.767 178.75 155.915L178.75 155.916L178.711 155.971L178.751 155.916C179.143 156.217 179.399 156.661 179.463 157.15C179.528 157.64 179.395 158.135 179.096 158.527L171.554 168.366C171.38 168.593 171.155 168.777 170.899 168.903C170.642 169.028 170.36 169.094 170.074 169.093L170.072 169.095Z"
      fill="white"
    />
  </svg>
);

export default Icon;
