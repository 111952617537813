import React, { FC, ReactElement } from 'react';
import {
  Box,
  InputAdornment,
  Stack,
  StandardTextFieldProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ComponentWithTooltipProps } from './interface';
import { colors, typography } from '@theme';
import { WarningIcon } from '@svgAsComponents';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { CurrencyInput, DigitInput, PercentsInput } from '@components';

const inputComponents = (type: 'currency' | 'percent' | 'string' | 'number') => {
  if (type === 'currency') return { inputComponent: CurrencyInput as any };
  if (type === 'percent') return { inputComponent: PercentsInput as any };
  if (type === 'number') return { inputComponent: DigitInput as any };
  return {};
};

const inputPropsByType = {
  string: {},
  currency: {},
  number: { min: '0' },
  percent: { step: '.01', min: '0', max: '100' },
};

interface TextInputElementProp extends StandardTextFieldProps {
  errorTip?: string;
  type?: 'currency' | 'percent' | 'string' | 'number';
  dataTestName?: string;
  tooltipActionButtons?: ReactElement;
  tooltipMinWidth?: string;
  hasBorder?: boolean;
  inputProps?: NumericFormatProps<InputAttributes>;
  textDisabled?: boolean;
  iconDataTestName?: string;
  inputWidth?: string;
  fullWidth?: boolean;
}

const TextInputElement: FC<TextInputElementProp> = ({
  type,
  errorTip,
  dataTestName,
  tooltipActionButtons,
  tooltipMinWidth,
  hasBorder,
  inputProps,
  textDisabled,
  fullWidth,
  iconDataTestName = 'input_warning_icon',
  inputWidth = 'auto',
  ...props
}) => {
  const tipProps = errorTip
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    minWidth: tooltipMinWidth,
                    padding: '16px',
                  },
                },
              }}
              title={
                <Stack>
                  <Typography
                    variant="label"
                    dangerouslySetInnerHTML={{ __html: errorTip }}
                    sx={{ whiteSpace: 'pre-line' }}
                  ></Typography>
                  {tooltipActionButtons && <Stack direction="row">{tooltipActionButtons}</Stack>}
                </Stack>
              }
            >
              <Stack data-cy={iconDataTestName} sx={{ cursor: 'pointer', p: 0.5 }}>
                <WarningIcon size={14} />
              </Stack>
            </Tooltip>
          </InputAdornment>
        ),
      }
    : {};

  return (
    <Box sx={{ border: hasBorder ? `2px solid ${colors.main.primary.light}` : 'none' }}>
      <TextField
        size="small"
        variant="outlined"
        autoComplete="off"
        inputProps={inputPropsByType[type]}
        InputProps={{
          inputProps: { ...inputProps, 'data-cy': dataTestName },
          ...inputComponents(type),
          ...tipProps,
        }}
        {...props}
        sx={{
          width: fullWidth ? '100%' : inputWidth,
          input: {
            textAlign: type === 'string' ? 'left' : 'right',
            ...typography.body3,
            ...(textDisabled ? { color: colors.neutral.dark } : {}),
          },
          '& .MuiInputBase-adornedStart': { paddingLeft: 0 },
        }}
      />
    </Box>
  );
};

const TextInputWithTooltip: FC<ComponentWithTooltipProps> = ({
  tooltipText,
  fullWidth,
  open,
  ...props
}) => {
  return tooltipText ? (
    <Tooltip title={tooltipText} {...(open && { open })}>
      <div>
        <TextInputElement {...props} />
      </div>
    </Tooltip>
  ) : (
    <TextInputElement fullWidth={fullWidth} {...props} />
  );
};

export default TextInputWithTooltip;
