export default function Paper() {
  return {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 16px 56px rgba(11, 46, 75, 0.15)',
        },
        rounded: {
          borderRadius: '4px',
        },
      },
    },
  };
}
