import { DrawRequestTypeEnum, TableKeyEnum } from '@interfaces';
import { createColumnVisibleModel, mergeColumnVisibilityModel } from '@utils';
import { useState, useCallback, useRef } from 'react';
import { useUpdateUiSettings } from '@hooks';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { defaultPersonalSetting } from '@constants';
import React from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

const useTableSettings = (
  tableKey: TableKeyEnum | DrawRequestTypeEnum,
  apiRef: React.MutableRefObject<GridApiPremium>,
  setTableLoading: React.Dispatch<React.SetStateAction<boolean>>,
  defaultOrderedFields: string[],
) => {
  const needToUpdateSettings = useRef<boolean>(true);
  const { userSettings, updateTableSettings } = useUpdateUiSettings();

  const [initialSettings, setInitState] = useState<GridInitialStatePremium>(
    mergeColumnVisibilityModel(
      defaultPersonalSetting.tables[tableKey]?.hidden_columns || [],
      userSettings?.tableV3?.[tableKey],
      false,
    ),
  );

  const saveCurrentSet = useCallback(
    async (keepOrder?: boolean) => {
      if (!needToUpdateSettings.current) return;
      apiRef.current.resetRowHeights();
      const { preferencePanel, ...currentState } = apiRef.current.exportState();
      if (!keepOrder) currentState.columns.orderedFields = [];
      setTableLoading(true);
      await updateTableSettings(tableKey as TableKeyEnum, currentState);
      setTableLoading(false);
    },
    [updateTableSettings, apiRef.current],
  );

  const clearSettings = useCallback(async () => {
    const currentState = apiRef.current.exportState();

    const dimensions = Object.keys(currentState.columns.dimensions).reduce((sum, key) => {
      const { width, flex, ...value } = currentState.columns.dimensions[key];
      sum[key] = { ...value, ...(!flex ? { width: value.minWidth } : { flex: 1 }) };
      return sum;
    }, {});

    const clearState: GridInitialStatePremium = {
      columns: {
        columnVisibilityModel: createColumnVisibleModel(
          defaultPersonalSetting.tables[tableKey]?.hidden_columns || [],
        ),
        dimensions,
        orderedFields: defaultOrderedFields,
      },
      filter: {
        filterModel: {
          items: [],
        },
      },
      pinnedColumns: {
        left: [],
        right: [],
      },
      density: 'standard',
      rowGrouping: {
        model: [],
      },
      sorting: {
        sortModel: [],
      },
    };

    setTableLoading(true);
    needToUpdateSettings.current = false;
    apiRef.current.restoreState(clearState);
    setInitState(clearState);

    await updateTableSettings(tableKey as TableKeyEnum, clearState);
    setTableLoading(false);
    needToUpdateSettings.current = true;
  }, [apiRef.current, tableKey, defaultOrderedFields]);

  return {
    initialSettings,
    clearSettings,
    saveCurrentSet,
  };
};

export default useTableSettings;
