import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon / service-price">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2H6C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V5C21 3.34314 19.6569 2 18 2ZM5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V8H5V5ZM5 10V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V10H5ZM7.5 11.5C6.67157 11.5 6 12.1716 6 13C6 13.8284 6.67157 14.5 7.5 14.5C8.32843 14.5 9 13.8284 9 13C9 12.1716 8.32843 11.5 7.5 11.5ZM10.5 13C10.5 12.1716 11.1716 11.5 12 11.5C12.8284 11.5 13.5 12.1716 13.5 13C13.5 13.8284 12.8284 14.5 12 14.5C11.1716 14.5 10.5 13.8284 10.5 13ZM16.5 11.5C15.6716 11.5 15 12.1716 15 13C15 13.8284 15.6716 14.5 16.5 14.5C17.3284 14.5 18 13.8284 18 13C18 12.1716 17.3284 11.5 16.5 11.5ZM15 17C15 16.1716 15.6716 15.5 16.5 15.5C17.3284 15.5 18 16.1716 18 17C18 17.8284 17.3284 18.5 16.5 18.5C15.6716 18.5 15 17.8284 15 17ZM7.5 15.5C6.67157 15.5 6 16.1716 6 17C6 17.8284 6.67157 18.5 7.5 18.5C8.32843 18.5 9 17.8284 9 17C9 16.1716 8.32843 15.5 7.5 15.5ZM10.5 17C10.5 16.1716 11.1716 15.5 12 15.5C12.8284 15.5 13.5 16.1716 13.5 17C13.5 17.8284 12.8284 18.5 12 18.5C11.1716 18.5 10.5 17.8284 10.5 17Z"
        fill={color}
      />
    </g>
  </svg>
);

export default Icon;
