import React from 'react';
import { Stack, Typography } from '@mui/material';
import { WarningIcon } from '@svgAsComponents';

import { colors } from '@theme';
import { ComponentProps } from './interface';

const FormFieldError = ({ text }: ComponentProps) => {
  if (!text) return null;
  return (
    <Stack direction="row" component="span" alignItems="center">
      <WarningIcon size={13} />
      <Typography
        variant="labelSemiBold"
        data-test-name="input-error"
        sx={{ color: colors.status.error.medium, ml: '5px', mt: '2px' }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default FormFieldError;
