import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  Box,
  ButtonBase,
  FormControlLabel,
  Grid2,
  IconButton,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CloseIcon from '@mui/icons-material/Close';
import update from 'immutability-helper';
import find from 'lodash/find';

import { colors, fonts } from '@theme';
import { ComponentProps } from './interface';
import { SortIcon } from '@svgAsComponents';

const StyledPaper = styled(Paper)(() => ({
  borderRadius: 8,
}));

const Header = styled(Stack)(() => ({
  backgroundColor: colors.background.gray,
  borderBottom: `1px solid lightgrey`,
}));

const StyledRadioLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontFamily: fonts.secondary,
    fontSize: '0.75rem',
    fontWeight: 400,
    marginLeft: theme.spacing(1),
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  '&:hover,&:focus': {
    color: theme.palette.secondary.main,
  },
  '& svg': {
    width: 16,
    height: 16,
  },
}));

const SortingWidget = ({ columnsList, sortValue, handleSortClick }: ComponentProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const rootRef = useRef(null);

  const handleClick = () => {
    setAnchorEl(anchorEl ? null : rootRef.current);
  };
  const closeDialog = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'sorting-order-popper' : undefined;

  // handle local sort values array
  const [localSortValues, setLocalSortValues] = useState(sortValue);
  useEffect(() => {
    if (JSON.stringify(sortValue) !== JSON.stringify(localSortValues))
      setLocalSortValues(sortValue);
  }, [sortValue, setLocalSortValues]);

  const handleLocalSortChanges = (value, index) => {
    if (!value) {
      const newState = update(localSortValues, { $splice: [[index, 1]] });
      setLocalSortValues(newState);
      return;
    }
    if (localSortValues.length > index) {
      const newState = update(localSortValues, {
        [index]: {
          id: { $set: value?.id },
          desc: { $set: value?.desc },
        },
      });
      setLocalSortValues(newState);
      return;
    }
    setLocalSortValues((prevState) => [...prevState, value]);
  };

  const changeSortDirection = (value, index) => {
    const newState = update(localSortValues, {
      [index]: {
        desc: { $set: value === 'desc' },
      },
    });
    setLocalSortValues(newState);
  };

  useEffect(() => {
    handleSortClick(localSortValues);
  }, [localSortValues]);

  const stringToShow = localSortValues.map((item) => columnsList[item?.id]).join(', ');

  return (
    <Grid2 aria-describedby={id} ref={rootRef} container alignItems="center">
      <Box sx={{ maxWidth: 221, height: '100%' }}>
        <Button
          disableRipple
          aria-describedby={id}
          onClick={handleClick}
          sx={{ p: 0, height: '32px' }}
        >
          {localSortValues.length ? (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                maxWidth: '280px',
                minWidth: '120px',
                height: '100%',
                backgroundColor: colors.neutral.lightest,
                borderRadius: '2px',
                p: 1,
                '& svg': { width: 16, height: 16 },
                color: colors.text.medium,
              }}
            >
              <Typography variant="body3" sx={{ color: colors.text.medium, whiteSpace: 'nowrap' }}>
                {`Sort by: `}
              </Typography>
              <Typography
                variant="body3SemiBold"
                color={colors.text.medium}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {stringToShow}
              </Typography>
              <KeyboardArrowDownIcon />
            </Stack>
          ) : (
            <SortIcon color={colors.icons.gray} />
          )}
        </Button>
      </Box>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: 100 }}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <StyledPaper>
          <Header direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body3" sx={{ px: 2 }}>
              Sort Order
            </Typography>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Header>
          <Box sx={{ p: 2 }}>
            {[0, 1, 2].map((item, index) => (
              <div key={item}>
                <SortItem
                  value={localSortValues[item] || null}
                  handleSortClick={handleLocalSortChanges}
                  changeSortDirection={changeSortDirection}
                  index={item}
                  columnsList={columnsList}
                  options={Object.keys(columnsList).map((key) => ({ id: key, desc: true }))}
                  disabled={index !== 0 && !localSortValues[index - 1]?.id}
                  localSortValues={localSortValues}
                />
                <Box sx={{ mt: 2 }} />
              </div>
            ))}
          </Box>
        </StyledPaper>
      </Popper>
    </Grid2>
  );
};

const SortItem = ({
  options,
  columnsList,
  index,
  value,
  handleSortClick,
  changeSortDirection,
  disabled,
  localSortValues,
}) => {
  return (
    <Grid2>
      <Stack direction="row">
        <FormControlLabel
          control={
            <Autocomplete
              value={value}
              id="portfolio-sorting"
              options={options}
              getOptionLabel={(option) => columnsList[option.id] || ''}
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                value: { id: string; desc: boolean } | null,
              ) => handleSortClick(value?.id ? { id: value.id, desc: true } : null, index)}
              // choosen option is not available for choice in the next dropdowns
              getOptionDisabled={(option) => !!find(localSortValues, { id: option.id })}
              renderInput={(params) => (
                <TextField color="secondary" {...params} variant="outlined" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                minWidth: 300,
                ml: 8,
                '& .MuiOutlinedInput-root': {
                  height: 38,
                  padding: 0,
                },
              }}
              disabled={disabled}
            />
          }
          label={<Typography variant="body2">{index ? 'Then By' : 'Sort By'}</Typography>}
          labelPlacement="top"
          sx={{
            alignItems: 'flex-start',
            margin: 0,
          }}
        />
        <Box sx={{ ml: 4 }} />
        <RadioGroup
          aria-label="inputType"
          name="inputType"
          value={value?.desc ? 'desc' : 'asc'}
          onChange={(e) => changeSortDirection(e.target.value, index)}
          sx={{ mt: 5 }}
        >
          <Stack>
            <StyledRadioLabel
              value="asc"
              control={<Radio size="small" color="secondary" sx={{ p: 0 }} />}
              label="Ascending"
              disabled={!value?.id}
            />
            <StyledRadioLabel
              value="desc"
              control={<Radio size="small" color="secondary" sx={{ p: 0 }} />}
              label="Descending"
              disabled={!value?.id}
            />
          </Stack>
        </RadioGroup>
      </Stack>
    </Grid2>
  );
};

export default SortingWidget;
