import React, { Dispatch, useContext, useEffect, useMemo, useState } from 'react';
import find from 'lodash/find';
import { SettingsContext } from '@context';

import { ChangeIcon, ForApprovalIcon, InspectionIcon } from '@svgAsComponents';
import { AutoFillModeEnum, IMenuItem } from '@interfaces';
import { getRequestRetainageRate, isChangeRequest } from '@utils';

const AUTOFILL_OPTIONS = {
  [AutoFillModeEnum.LENDER_ALLOWANCE]: [
    {
      value: 'inspector_allowance_rate_limited_by_requested_amount',
      label: 'Match inspection allowance up to the requested amount',
      isDefault: true,
    },
    {
      value: 'inspector_allowance_rate',
      label: 'Match inspection allowance (may exceed borrower request)',
    },
    {
      value: 'requested_amount',
      label: 'Match borrower request (may exceed inspection)',
    },
  ],
  [AutoFillModeEnum.APPROVED_CHANGES]: [
    {
      value: 'requested_changes',
      label: "Borrower's request",
      isDefault: true,
    },
  ],
  [AutoFillModeEnum.APPROVED_RETAINAGE]: [
    {
      value: 'retainage_release_approved',
      label: 'Approved retainage release',
      isDefault: true,
    },
  ],
};

export const title = {
  [AutoFillModeEnum.CREDIT_REVIEW]: 'CreditReview credit ',
  [AutoFillModeEnum.LENDER_ALLOWANCE]: 'Autofill lender allowance',
  [AutoFillModeEnum.APPROVED_CHANGES]: 'Autofill approved changes',
  [AutoFillModeEnum.APPROVED_RETAINAGE]: 'Autofill approved retainage release',
};

export const popupText = {
  [AutoFillModeEnum.CREDIT_REVIEW]:
    "Heads up! You've got credit waiting for your review. Please take a moment to check it out before moving on.",
  [AutoFillModeEnum.LENDER_ALLOWANCE]:
    'Select the method for autofill. Note any existing entries will be overwritten!',
  [AutoFillModeEnum.APPROVED_CHANGES]:
    "Set approved amounts to match Borrower's request.\n Any existing entries will be overwritten!",
  [AutoFillModeEnum.APPROVED_RETAINAGE]:
    "Set approved retainage release to match Borrower's request.\n Any existing entries will be overwritten!",
};

export type ControllerInterface = {
  autofillMenuItems: IMenuItem[];
  autofillOptions: { value: string; label: string }[];
  setAutofillDialogOpened: Dispatch<React.SetStateAction<boolean>>;
  isAutofillDialogOpened: boolean;
  autofillValue: string;
  setAutofillValue: Dispatch<React.SetStateAction<string>>;
  currentMode: AutoFillModeEnum;
  isCurrentProjectArchived: boolean;
};

export const useAutofillAllowance = ({ drawRequest }): ControllerInterface => {
  const { isPHBProject, isCurrentProjectArchived } = useContext(SettingsContext);
  const [isAutofillDialogOpened, setAutofillDialogOpened] = useState(false);
  const retainageRate = useMemo(() => getRequestRetainageRate(drawRequest), [drawRequest]);
  const isChange = isChangeRequest(drawRequest);

  const [autofillValue, setAutofillValue] = useState(
    find(
      AUTOFILL_OPTIONS[
        isChange ? AutoFillModeEnum.APPROVED_CHANGES : AutoFillModeEnum.LENDER_ALLOWANCE
      ],
      { isDefault: true },
    )?.value,
  );

  const autofillOptions = useMemo(
    () =>
      AUTOFILL_OPTIONS[
        isChange ? AutoFillModeEnum.APPROVED_CHANGES : AutoFillModeEnum.LENDER_ALLOWANCE
      ],
    [isChange],
  );

  const [currentMode, setCurrentMode] = useState<AutoFillModeEnum>(null);

  useEffect(() => {
    if (
      currentMode === AutoFillModeEnum.APPROVED_CHANGES ||
      currentMode === AutoFillModeEnum.APPROVED_RETAINAGE
    )
      setAutofillValue(AUTOFILL_OPTIONS[currentMode][0].value);
  }, [currentMode]);

  const autofillMenuItems = useMemo(
    () => [
      {
        action: () => {
          setCurrentMode(AutoFillModeEnum.LENDER_ALLOWANCE);
          setAutofillDialogOpened(true);
        },
        text: AutoFillModeEnum.LENDER_ALLOWANCE,
        icon: <InspectionIcon size={18} />,
        disabled: isChange,
        disabledTooltipText: 'Request has only changes',
        dataTestName: 'draw__autofill__lender_allowance',
      },
      {
        action: () => {
          setCurrentMode(AutoFillModeEnum.APPROVED_CHANGES);
          setAutofillDialogOpened(true);
        },
        text: AutoFillModeEnum.APPROVED_CHANGES,
        icon: <ChangeIcon size={18} />,
        dataTestName: 'draw__autofill__approved_changes',
      },
      ...(retainageRate && !isPHBProject
        ? [
            {
              action: () => {
                setCurrentMode(AutoFillModeEnum.APPROVED_RETAINAGE);
                setAutofillDialogOpened(true);
              },
              text: AutoFillModeEnum.APPROVED_RETAINAGE,
              icon: <ForApprovalIcon />,
              dataTestName: 'draw__autofill__retainage',
            },
          ]
        : []),
    ],
    [isChange, isPHBProject, retainageRate],
  );

  return {
    isCurrentProjectArchived,
    setAutofillDialogOpened,
    isAutofillDialogOpened,
    autofillValue,
    setAutofillValue,
    autofillMenuItems,
    autofillOptions,
    currentMode,
  };
};
