import React from 'react';
import { Box } from '@mui/material';
import { StyledLink } from '@components';

const ForgotPasswordLink = () => {
  return (
    <Box mt={0} mb="16px">
      <StyledLink variant="body3SemiBold" to="/forgot-password">
        Forgot your password?
      </StyledLink>
    </Box>
  );
};

export default ForgotPasswordLink;
