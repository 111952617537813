import React, { FC } from 'react';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { CloseIcon, VisibilityOffIcon, VisibilityOnIcon } from '@svgAsComponents';
import { useWatchProjectsBulkPanel } from './controller';

const WatchProjectsBulkPanel: FC<{
  selectedProjectsIds: string[];
  onClose: () => void;
  onComplete: () => void;
}> = ({ selectedProjectsIds, onClose, onComplete }) => {
  const { startWatchingProjects, unWatchProjects } = useWatchProjectsBulkPanel(
    selectedProjectsIds,
    onClose,
    onComplete,
  );

  return (
    <Stack
      direction="row"
      sx={{
        position: 'fixed',
        backgroundColor: colors.main.primary.main,
        bottom: 24,
        left: '50%',
        transform: 'translate(-50%, 0)',
        px: 3,
        py: 1,
      }}
      alignItems="center"
    >
      <Stack direction="row" alignItems="center">
        <IconButton onClick={onClose} sx={{ p: 0 }}>
          <CloseIcon size={16} color={colors.neutral.white} />
        </IconButton>
        <Typography variant="body2SemiBold" sx={{ ml: 2, color: colors.neutral.white }}>
          {`${selectedProjectsIds.length} projects selected`}
        </Typography>
      </Stack>
      <Divider
        orientation="vertical"
        sx={{ mx: '32px', color: colors.neutral.white, height: '32px' }}
      />
      <Stack direction="row" alignItems="center" spacing="40px">
        <IconButton onClick={startWatchingProjects} sx={{ p: 0 }}>
          <VisibilityOnIcon size={24} color={colors.neutral.white} />
          <Typography variant="body2SemiBold" sx={{ ml: 1, color: colors.neutral.white }}>
            Watch
          </Typography>
        </IconButton>
        <IconButton onClick={unWatchProjects} sx={{ p: 0 }}>
          <VisibilityOffIcon size={24} color={colors.neutral.white} />
          <Typography variant="body2SemiBold" sx={{ ml: 1, color: colors.neutral.white }}>
            Unwatch
          </Typography>
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default WatchProjectsBulkPanel;
