import { MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter } from '@utils';

const adjustmentsTotalApprovedBudget: MilestoneListColumnTypeV2 = {
  field: 'approved_adjustments',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Total approved changes ($)',
  description: 'Cumulative approved changes to original scheduled value',
  editable: false,
  valueFormatter: (value) => currencyFormatter(value, ''),
  minWidth: 120,
};

export default adjustmentsTotalApprovedBudget;
