import { Box, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { getAddress, isChangeRequest } from '@utils';
import React, { FC } from 'react';
import { Logo, Page } from '../common';

import { ControllerInterface } from '../controller';

export const StartPage: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { customerLogo, drawRequestData, borrower, project } = controller;

  return (
    <Page>
      <Stack alignItems="center" direction="column">
        {customerLogo && <Logo height="80px" alt="Customer logo" src={customerLogo} />}
        <Box sx={{ height: '30px' }} />
        <Typography variant="printH1" data-cy="report_title">{`${
          isChangeRequest(drawRequestData) ? 'Change' : 'Draw'
        } # ${drawRequestData.number} intelligence report`}</Typography>
        <Stack direction="row" alignItems="baseline" mt={1}>
          <Typography variant="h1" fontWeight={400} sx={{ color: colors.text.medium }}>
            Borrower:&nbsp;
          </Typography>
          <Typography
            fontWeight={600}
            sx={{ color: colors.text.link }}
            variant="h1"
            data-cy="report_borrower_name"
          >
            {borrower?.company?.name}
          </Typography>
        </Stack>
        <Box
          sx={{
            marginTop: '70px',
            overflow: 'hidden',
            width: '100%',
            height: '300px',
            marginBottom: '25px',
            backgroundColor: colors.main.primary.main,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${project?.thumb_representations?.original?.url})`,
          }}
        />
        <Typography
          fontWeight={600}
          variant="h2"
          sx={{ color: colors.text.dark }}
          data-cy="report_address"
        >
          {getAddress(project.address)}
        </Typography>
      </Stack>
    </Page>
  );
};
