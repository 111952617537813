import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const CalendarIcon = ({ size = 24, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C7.44772 2 7 2.44772 7 3V4H6C4.34315 4 3 5.34315 3 7V9V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9V7C21 5.34315 19.6569 4 18 4H17V3C17 2.44772 16.5523 2 16 2C15.4477 2 15 2.44772 15 3V4H9V3C9 2.44772 8.55228 2 8 2ZM16 6H8H6C5.44772 6 5 6.44772 5 7V8H19V7C19 6.44772 18.5523 6 18 6H16ZM5 19V10L19 10V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19ZM7 13C7 12.4477 7.44772 12 8 12H10C10.5523 12 11 12.4477 11 13V15C11 15.5523 10.5523 16 10 16H8C7.44772 16 7 15.5523 7 15V13Z"
      fill={color}
    />
  </svg>
);

export default CalendarIcon;
