import { Box, Button, Link, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { colors } from '@theme';
import { PlayVideoIcon } from '@svgAsComponents';

const Article: FC<{
  image: ReactElement;
  dataTestName: string;
  link: string;
  title: string;
  buttonText: string;
}> = ({ image, dataTestName, link, title, buttonText }) => {
  return (
    <Stack
      sx={{
        flex: 4,
        backgroundColor: colors.background.gray,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '2px',
        p: 2,
        height: '384px',
      }}
      spacing={2}
    >
      <Stack
        sx={{
          width: '100%',
          height: '192px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {image}
      </Stack>
      <Box>
        <PlayVideoIcon />
      </Box>
      <Typography variant="body1SemiBold" sx={{ mt: 0.5 }}>
        {title}
      </Typography>
      <Link
        target="_blank"
        href={link}
        sx={{ textDecoration: 'none' }}
        data-cy={`onboarding__video_link__button__${dataTestName}`}
      >
        <Button onClick={(e) => e.stopPropagation()} variant="contained" color="primary">
          {buttonText}
        </Button>
      </Link>
    </Stack>
  );
};

export default Article;
