import React, { FC, useMemo } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';

import { colors } from '@theme';

import { PersonRoundedIcon } from '@svgAsComponents';
import { IUser, IWatcher } from '@interfaces';

const UserAvatar: FC<{
  user: Partial<IUser> | IWatcher;
  color?: string;
  boxShadow?: string;
  size?: number;
  withTooltip?: boolean;
}> = ({
  user,
  color = colors.main.primary.main,
  size = 32,
  withTooltip = false,
  boxShadow = '',
}) => {
  const getAbbreviation = (user) => {
    if (!user) return '';
    const { full_name, first_name, last_name } = user;
    if (full_name && !first_name && !last_name) {
      return full_name
        .split(' ')
        .slice(0, 2)
        .map((word) => word.charAt(0).toUpperCase())
        .join('');
    }
    return (first_name?.charAt(0) || '') + (last_name?.charAt(0) || '');
  };

  const userAbbreviation = useMemo(() => getAbbreviation(user), [user]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: '50%',
        height: size,
        width: size,
        backgroundColor: color,
        boxShadow,
      }}
    >
      {userAbbreviation ? (
        <Tooltip title={withTooltip ? user?.full_name : ''} placement="top">
          <Typography variant="labelSemiBold" sx={{ color: colors.background.white }}>
            {userAbbreviation}
          </Typography>
        </Tooltip>
      ) : (
        <PersonRoundedIcon color={colors.background.white} size={16} />
      )}
    </Stack>
  );
};

export default UserAvatar;
